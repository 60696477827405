<template>
  <div :class="$style.base">
    <div :class="$style.toggle" @click="toggleMobileDesktop">
      <BaseIcon :name="item.icon" color="inverted" size="s" />
      <BaseText color="inverted" ml>{{ item.name }}</BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageLayoutStore } from '@/stores/page-layout';
import type { NavItem } from './MobileOverlay.vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const pageLayoutStore = storeToRefs(usePageLayoutStore());
const { t } = useI18n();

const item: NavItem = {
  name: t('nav.desktop'),
  icon: 'monitor',
  routeName: '',
};

const emit = defineEmits(['navItemClick']);

const toggleMobileDesktop = () => {
  pageLayoutStore.isMobileDesktop.value =
    !pageLayoutStore.isMobileDesktop.value;
  emit('navItemClick');
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: start;
  margin-top: $spacing;
}

.toggle {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 1.7vh $spacing;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(191, 191, 191, 0.4);
  }

  @media (max-height: 710px) {
    padding: 1.3vh $spacing;
  }
  @media (max-height: 620px) {
    padding: 1vh $spacing;
  }

  &:focus {
    background: $color-primary-dark;
  }

  @include hover {
    background: $color-primary;
  }
}
</style>
