<template>
  <BaseModal small @close="$emit('close')">
    <BaseText size="l" :mb="2">
      {{
        $t('global.confirmation.confirm_email_amount', {
          item: `${filteredCustomersCount} ${$t(
            'global.items.customer',
            filteredCustomersCount
          )}`
        })
      }}
    </BaseText>
    <BaseRadio
      v-if="hasDeliverySchedule"
      v-model="action"
      :label="$t('marketing.email.send-template-modal.label_when')"
      :options="[
        {
          label: $t('marketing.email.send-template-modal.send_template'),
          value: 'send_template'
        },
        {
          label: $t('marketing.email.send-template-modal.save_template'),
          value: 'save_template'
        }
      ]"
      @update:modelValue="onActionOptionSelect"
      v-test="'radioGroupModal'"
    />
    <BaseInput
      v-if="action === 'save_template'"
      v-model="name"
      :mt="1.5"
      :label="$t('global.name')"
      required
      v-test="'modalNameInput'"
    />
    <template #footer>
      <BaseButton
        color="inverted"
        :disabled="isLoading"
        @click="$emit('close')"
        v-test="'send-template-cancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="isLoading"
        @click="onSubmit"
        v-test="'send-template-confirm'"
      >
        {{
          $t(`global.actions.${action === 'send_template' ? 'send' : 'save'}`)
        }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import eventBus from '@/event-bus';
import { flash } from '@/helpers/ui';
import { useOnboardingStore } from '@/stores/onboarding';
import useVuelidate from '@vuelidate/core';
import { storeToRefs } from 'pinia';
import { computed, ref, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  inject: ['mixpanel'],
  emits: ['close'],
  setup() {
    const store = useStore();
    const action = ref('send_template');
    const { currentOnboardingFlow } = storeToRefs(useOnboardingStore());

    const name = computed({
      get() {
        return store.getters['marketing/name'];
      },
      set(value) {
        store.commit('marketing/SET_NAME', value);
      },
    });

    const filteredCustomersCount = computed(
      () => store.getters['marketing/builder/filteredCustomersCount'],
    );

    const onActionOptionSelect = (value) => {
      action.value = value;
    };

    return {
      action,
      onActionOptionSelect,
      filteredCustomersCount,
      name,
      v$: useVuelidate(),
      currentOnboardingFlow,
    };
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
    };
  },
  computed: {
    hasDeliverySchedule() {
      return this.$store.getters['marketing/hasDeliverySchedule'];
    },
  },
  methods: {
    onSubmit() {
      this.isSubmitting = true;

      if (this.action === 'send_template') {
        this.isLoading = true;
        if (this.$route.params.id) {
          this.$store
            .dispatch('marketing/updateEmailTemplate', 'MARKETING_CAMPAIGN')
            .then(() => {
              this.sendTemplate();
            });
        } else {
          this.sendTemplate();
        }
      }

      this.v$.$touch();

      if (this.action === 'save_template' && !this.v$.$invalid) {
        this.isLoading = true;
        this.mixpanel.track('Newsletter saved');
        const actionName = this.$route.params.id
          ? 'updateEmailTemplate'
          : 'createEmailTemplate';
        this.$store
          .dispatch(`marketing/${actionName}`, 'MARKETING_CAMPAIGN')
          .then(() => {
            this.$router.push({ name: 'marketing-email' });
            flash(this.$t('global.flash.template_saved'));

            if (this.currentOnboardingFlow === 'marketing') {
              eventBus.$emit('open-task-menu');
              this.currentOnboardingFlow = '';
            }
            this.isLoading = false;
          });
      }
    },
    sendTemplate() {
      this.$store.dispatch('marketing/sendMarketingEmail').finally(() => {
        this.$router.push({ name: 'marketing-email' });
        flash(this.$t('global.flash.all_emails_sent'));
        this.$store.commit('marketing/SET_SUBMITTED', false);
        if (this.currentOnboardingFlow === 'marketing') {
          eventBus.$emit('open-task-menu');
          this.currentOnboardingFlow = '';
        }
        this.mixpanel.track('Newsletter sent');
        this.isLoading = false;
      });
    },
  },
});
</script>
