<template>
  <BaseModal
    :heading="$t('admin.booking.custom_widget.label')"
    :parentRoute="parentRouteName"
  >
    <BaseText :mb="1.5">
      {{ $t('custom_booking_widget.sub_title') }}
    </BaseText>
    <BaseRadio
      v-if="multiLocation"
      v-model="selectedLocationId"
      :options="locationOptions"
      :label="$t('custom_booking_widget.select_location')"
      :mb="1.5"
      v-test="'custom-widget-location'"
    />
    <BaseInputLabel
      :text="$t('custom_booking_widget.select_services')"
      :info="$t('custom_booking_widget.select_services_sub')"
    />
    <BaseGroupedCheckboxList
      v-model="selectedServiceIds"
      :data="serviceOptions"
      :label="$t('admin.booking.custom_widget.all_services')"
      :mb="1.5"
      v-test="'custom-widget-services'"
    />
    <BaseInputLabel
      :text="$t('custom_booking_widget.select_employees')"
      :info="$t('custom_booking_widget.select_employees_sub')"
    />
    <BaseGroupedCheckboxList
      v-model="selectedEmployeeIds"
      :data="employeeOptions"
      :label="$t('admin.booking.custom_widget.all_employees')"
      :mb="1.5"
      v-test="'custom-widget-employees'"
    />
    <BaseRadio
      v-model="selectedShareOption"
      :options="[
        {
          value: 'link',
          label: $t('promote.booking_widget.link_view')
        },
        {
          value: 'inline',
          label: $t('promote.booking_widget.widget_view')
        }
      ]"
      :label="$t('promote.booking_widget.share_setting')"
      :mb="1.5"
      v-test="'custom-widget-shareOption'"
    />
    <CustomWidgetSnippet />
  </BaseModal>
</template>

<script lang="ts">
export default {
  name: 'CustomWidgetSettings',
};
</script>

<script setup lang="ts">
import CustomWidgetSnippet from './CustomWidgetSnippet.vue';
import options from './options';
import { useCompanyStore } from '@/stores/company';

defineProps<{
  parentRouteName: string;
}>();

const {
  selectedLocationId,
  locationOptions,
  selectedServiceIds,
  serviceOptions,
  selectedEmployeeIds,
  employeeOptions,
  selectedShareOption
} = options;
const { multiLocation } = useCompanyStore();
</script>
