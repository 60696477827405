<template>
  <BaseModal
    :heading="$t('global.items.category', 1)"
    :loading="$apollo.loading"
    small
    :parentRoute="parentRouteName"
    @close="$emit('close')"
  >
    <BaseForm @submit="submit">
      <BaseGrid container>
        <BaseGrid :size="6" :sSize="12">
          <BaseInput
            v-model="formData.name"
            :label="$t('global.name')"
            required
            mb
            v-test="'category-name'"
          />
        </BaseGrid>
        <BaseGrid :size="6" :sSize="12" alignRight>
          <ColorSelection
            v-model:colorSetting="colorSetting"
            v-model:color="formData.color"
            modelType="category"
            v-test="'category-color'"
          />
        </BaseGrid>
      </BaseGrid>
    </BaseForm>
    <template v-if="serviceCategory" #footerSub>
      <BaseText
        iconBefore="delete"
        link
        color="error"
        @click="deleteCategory"
        v-test="'category-delete'"
      >
        {{ $t('global.actions.delete_category') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="closeModal"
        v-test="'category-close'"
      >
        {{ $t('global.actions.close') }}
      </BaseButton>
      <BaseButton
        :loading="isLoading"
        @click="submit"
        v-test="'category-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { modal } from '@/helpers/ui';
import useVuelidate from '@vuelidate/core';
import { categoryFragment } from '@/graphql-fragments';
import gql from 'graphql-tag';
import ColorSelection from '@/modules/admin/_shared/ColorSelection.vue';
import { useServicesStore } from '@/stores/services';
import { mapState, mapActions } from 'pinia';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ColorSelection,
  },
  inject: ['mixpanel'],
  props: {
    parentRouteName: {
      type: String,
    },
  },
  emits: ['close', 'create'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isLoading: false,
      colorSetting: null,
      formData: {
        name: null,
        color: null,
      },
    };
  },
  apollo: {
    serviceCategory: {
      query: gql`
        query getServiceCategory($id: ID!) {
          serviceCategory(id: $id) {
            ...category
          }
        }
        ${categoryFragment}
      `,
      variables() {
        return {
          id: this.categoryId,
        };
      },
      skip() {
        return !this.categoryId;
      },
      result() {
        Object.keys(this.formData).forEach((key) => {
          this.formData[key] = this.serviceCategory[key];
        });
      },
    },
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    categoryId() {
      return this.$route.params.categoryId;
    },
    colorSettingChanged() {
      return (
        this.colorSetting &&
        this.colorSetting !== this.companySettings.agenda.colour
      );
    },
  },
  methods: {
    ...mapActions(useCompanyStore, ['updateCompany']),
    closeModal() {
      if (this.parentRouteName) {
        this.$router.push({ name: 'admin-services' });
      } else {
        this.$emit('close');
      }
    },
    deleteCategory() {
      const { categoriesWithServices, deleteCategory } = useServicesStore();

      const serviceCount = categoriesWithServices.find(
        (category) => category.id === this.serviceCategory.id,
      ).services.length;
      if (serviceCount > 0) {
        modal('warning', {
          message: this.$t('global.warning.delete', {
            item: this.serviceCategory.name,
          }),
          subMessage: this.$t('category.delete_warning'),
        });
      } else {
        modal('confirmation', {
          type: 'delete',
          item: this.serviceCategory.name,
        }).then(() => {
          deleteCategory(this.serviceCategory.id)
            .then(() => {
              this.closeModal();
            })
            .catch(() => {
              modal('warning', {
                message: this.$t('global.warning.delete', {
                  item: this.serviceCategory.name,
                }),
                subMessage: this.$t('category.delete_warning'),
              });
            });
        });
      }
    },
    submit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;

      (this.serviceCategory ? this.updateCategory : this.createCategory)()
        .then(() => {
          this.updateColorSetting();
          this.closeModal();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createCategory() {
      const { createCategory } = useServicesStore();

      return createCategory(this.formData).then((categoryId) => {
        this.$emit('create', categoryId);
        this.mixpanel.track('Category created');
      });
    },
    updateCategory() {
      const { updateCategory } = useServicesStore();

      return updateCategory({
        ...this.formData,
        id: this.serviceCategory.id,
      });
    },
    updateColorSetting() {
      if (this.colorSettingChanged) {
        this.updateCompany({
          settings: {
            agenda: {
              colour: this.colorSetting,
            },
          },
        });
      }
    },
  },
});
</script>
