<template>
  <div
    :class="[
      $style.base,
      {
        [$style.autoWidth]: !width,
        [$style.right]: right,
        [$style.small]: small,
        [$style.currency]: currency,
        [$style.noWrap]: noWrap,
        [$style.wordBreak]: wordBreak,
        [$style.success]: type === 'success',
        [$style.showOptions]: showOptions,
        [$style.textAlignRight]: textAlign === 'right',
        [$style.textAlignCenter]: textAlign === 'center',
        [$style.textAlignLeft]: textAlign === 'left'
      }
    ]"
    :style="style"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    right: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    currency: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: null,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
    wordBreak: {
      type: Boolean,
      default: false,
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'center', 'right'].indexOf(value) !== -1,
    },
  },
  computed: {
    style() {
      return this.width
        ? {
            width: `${Math.min(this.width, 100)}%`,
          }
        : {};
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: $spacing;

  &.wordBreak:not(.currency) {
    position: relative;
    word-break: break-word;
  }

  &.autoWidth {
    flex: 1;
  }

  &.small {
    flex: 0;
  }
}

.right {
  justify-content: flex-end;
}

.success {
  color: $color-success;
}

.currency {
  justify-content: flex-end;
}

.noWrap {
  flex-wrap: nowrap;
}

.showOptions {
  width: 52px;
  flex: none !important;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

@media print {
  .base {
    padding: 2px;
  }
}
</style>
