<template>
  <div>
    <router-view />
    <BaseSpinner v-show="loading" inline />
    <EmptyPageContent
      v-show="!loading && !items.length"
      imageName="waiting-list"
      v-test="'waiting-list-empty'"
    />
    <BaseTable
      v-show="items.length"
      :headers="
        [
          $t('global.date'),
          $t('global.items.customer', 1),
          $t('global.items.service', 2),
          $t('global.duration'),
          multiLocation ? $t('global.items.location', 1) : null,
          $t('global.sent'),
          $t('global.employee')
        ].filter((item) => !!item)
      "
      :rows="
        items.map((item) => ({
          id: item.id,
          routerLink: `waiting-list/${item.id}${date ? `?date=${date}` : ''}`,
          cells: [
            entryDate(item),
            item.customer.fullName,
            serviceNames(item),
            `${servicesDuration(item)} ${$t('global.minute_short')}`,
            {
              value: item.location.internalName,
              hide: !multiLocation
            },
            {
              value: !!item.lastEmailSentAt,
              tooltip: item.lastEmailSentAt
                ? $t('global.last_sent', {
                    date: filters.dateTime(item.lastEmailSentAt)
                  })
                : null
            },
            item.resource ? item.resource.name : null
          ],
          actions: [
            {
              name: 'view',
              label: $t('global.actions.view_item', {
                item: $t('global.items.customer', 1)
              })
            },
            {
              name: 'create',
              label: $t('global.actions.create_appointment')
            },
            {
              name: 'email',
              hide: !!item.customer.email
            },
            'delete'
          ]
        }))
      "
      @action="onActionClick"
      v-test="'waiting-list-entries'"
    />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import {
  deleteEntry,
  emailCustomer,
  viewCustomer,
  createAppointment,
  serviceNames,
  servicesDuration,
  entryDate,
} from './helpers';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import filters from '@/filters';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    EmptyPageContent,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      serviceNames,
      servicesDuration,
      entryDate,
      filters,
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['multiLocation']),
  },
  methods: {
    onActionClick({ id, option }) {
      const item = this.items.find((item) => item.id === id);

      if (option === 'delete') {
        deleteEntry(id);
      }

      if (option === 'email') {
        emailCustomer(id, this.date);
      }

      if (option === 'view') {
        viewCustomer(item.customer.id);
      }

      if (option === 'create') {
        createAppointment(item);
      }
    },
  },
});
</script>

<style lang="scss" module>
.image {
  align-self: flex-end;
}
</style>
