<template>
  <BaseModal
    :heading="$t('global.actions.merge_customer')"
    small
    testId="merge-customer"
    @close="$emit('close')"
  >
    <BaseGrid container>
      <BaseGrid :size="12">
        <CustomerSearch
          :selectedCustomer="selectedCustomer"
          :showError="isSubmitted && !selectedCustomer"
          :excludedCustomerId="parseInt($route.params.customerId)"
          @select="selectedCustomer = $event"
          v-test="'customerSearch'"
        />
      </BaseGrid>
    </BaseGrid>
    <BaseAlert
      mt
      color="error"
      :text="$t('customers.customer_merge_warning')"
    />
    <template #footer>
      <BaseButton
        :loading="loading"
        @click="submit"
        v-test="'saveMergeCustomer'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { flash } from '@/helpers/ui';
import CustomerSearch from '@/components/CustomerSearch.vue';
import { inject, ref } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const emit = defineEmits(['close', 'updateCustomer']);
const customer = inject<any>('customer');

const isSubmitted = ref(false);
const selectedCustomer = ref<any>(null);

const { t } = useI18n();

const { mutate, loading } = useMutation(gql`
  mutation mergeCustomer($input: MergeCustomerInput!) {
    mergeCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`);

const router = useRouter();

const submit = () => {
  isSubmitted.value = true;

  if (!selectedCustomer.value) {
    return;
  }

  mutate({
    input: {
      id: customer.value.id,
      sourceId: selectedCustomer.value.id,
    },
  }).then(({ data: { mergeCustomer } }) => {
    flash(t('global.flash.customers_merged'));

    if (mergeCustomer.customer.id !== customer.value.id) {
      router.push({
        name: 'customer-overview',
        params: {
          customerId: mergeCustomer.customer.id,
        },
      });
    } else {
      emit('updateCustomer');
    }

    emit('close');
  });
};
</script>
