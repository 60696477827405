<template>
  <ModuleBase noBorder gray :loading="loading">
    <PageWrap wide :heading="$t('admin.nav.links.absences')">
      <template #header>
        <BaseButton
          :routerLink="{ name: 'create-absence' }"
          v-test="'create-absence-button'"
        >
          {{ $t('admin.absences.create') }}
        </BaseButton>
      </template>
      <BaseCard
        v-if="nationalHolidays.length"
        :heading="$t('admin.absences.national_holidays')"
        :mb="2"
      >
        <BaseTable
          :mb="2"
          :headers="[$t('global.description'), $t('global.date')]"
          :rows="nationalHolidays"
          @action="handleAction"
          v-test="'national-holidays-table'"
        />
      </BaseCard>
      <BaseCard :heading="t('admin.absences.unavailable_days')">
        <Flex>
          <BaseDropdown
            v-if="showResourceSelection"
            v-model="resourceId"
            :options="resourcesListOptions"
            hideText
            resources
            mr
            v-test="'resource-selection'"
          />
        </Flex>
        <BaseTable
          v-if="userAbsences.length"
          :headers="[
            $t('global.description'),
            $t('global.applies_to'),
            $t('global.from'),
            $t('global.until')
          ]"
          :rows="userAbsences"
          @action="handleAction"
          v-test="'user-absences-table'"
        />
        <div v-else :class="$style.noResults">
          {{ $t('global.no_results') }}
        </div>
      </BaseCard>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import dayjs from '@/dayjs';
import type { Absence } from '@/types';
import { resourceIcon } from '@/helpers/formatting';
import ModuleBase from '@/modules/ModuleBase.vue';
import { useResourcesStore } from '@/stores/resources';
import filters from '@/filters';
import PageWrap from '../PageWrap.vue';

import { useAbsence } from './absence';
import { useLocationsStore } from '@/stores/locations';

const { t } = useI18n();

const router = useRouter();
const resourceId = ref(null);

const { employees: resources } = storeToRefs(useResourcesStore());
const { locationId } = storeToRefs(useLocationsStore());

watch(locationId, () => {
  resourceId.value = null;
});

const showResourceSelection = computed(() => resources.value.length > 1);
const resourcesListOptions = computed(() => [
  {
    value: null,
    label: t('calendar.show_all'),
    icon: resourceIcon('employee'),
  },
  ...resources.value.map((r) => ({
    value: r.id,
    label: r.name,
  })),
]);

const { absencesData, deleteAbsence, loading } = useAbsence();

const nationalHolidays = computed(() =>
  absencesData.value
    .filter((absence: Absence) => absence.holiday)
    .map((absence: Absence) => ({
      id: absence.id,
      cells: [absence.name, dayjs(absence.startAt).format('DD-MM-YYYY')],
      actions: [{ name: 'delete' }],
    })),
);

const userAbsences = computed(() =>
  absencesData.value
    .filter((absence: Absence) => !absence.holiday)
    .filter(
      (absence: Absence) =>
        resourceId.value === null || absence.resourceId === resourceId.value,
    )
    .map((absence: Absence) => ({
      id: absence.id,
      cells: [
        absence.name,
        resources.value.find((r) => r.id === absence.resourceId)?.name,
        absence.allDay
          ? filters.date(absence.startAt)
          : filters.dateTime(absence.startAt),
        absence.allDay
          ? filters.date(absence.endAt)
          : filters.dateTime(absence.endAt),
      ],
      actions: [{ name: 'edit' }, { name: 'delete' }],
    })),
);

const handleAction = ({
  id,
  option,
}: {
  id: string;
  option: 'edit' | 'delete';
}) => {
  switch (option) {
    case 'edit':
      router.push({ name: 'absence', params: { id } });
      break;
    case 'delete': {
      const absence = absencesData.value.find(
        (absence: Absence) => absence.id === id,
      );
      deleteAbsence(id, {
        holiday: absence.holiday,
      });
      break;
    }
  }
};
</script>

<style lang="scss" module>
.noResults {
  padding: $spacing;
  text-align: center;
}
</style>
