<template>
  <div v-if="emailTemplates?.length">
    <div :class="$style.inner">
      <TemplatePreview
        v-for="(template, index) in emailTemplates"
        :key="index"
        :template="template"
      />
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import TemplatePreview from './TemplatePreview.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EmailTemplatesOverview',
  components: {
    TemplatePreview,
  },
  apollo: {
    emailTemplates: {
      query: gql`
        query getEmailTemplates($emailType: EmailTemplateEnum!) {
          emailTemplates(emailType: $emailType) {
            color
            hasImage
            hasDeliverySchedule
            deliverAt
            id
            imageUrl
            name
            subject
            emailType
          }
        }
      `,
      variables: {
        emailType: 'MARKETING_CAMPAIGN',
      },
      fetchPolicy: 'cache-and-network',
    },
  },
});
</script>

<style lang="scss" module>
.inner {
  background: $grey-light;
  width: calc(100% + #{$spacing * 2});
  padding: $spacing * 0.5;
  margin-top: $spacing;
  margin-left: -$spacing;
  border-bottom: 1px solid $color-border;
  border-top: 1px solid $color-border;

  display: flex;
  flex-wrap: wrap;
}
</style>
