<template>
  <BaseModal
    testId="mollie-error"
    small
    :heading="$t('mollie_error_modal.heading')"
    @close="remindMeLater"
  >
    <div :class="$style.base">
      <img src="/img/billing/mollie-error.svg" />
      <BaseText mb mt>
        {{ $t('mollie_error_modal.description') }}
      </BaseText>
      <BaseText>
        {{ $t('mollie_error_modal.description_2') }}
      </BaseText>
    </div>
    <template #footerSub>
      <BaseText link @click="dismiss" v-test="'mollie-error-dismiss'">
        {{ $t('mollie_error_modal.dismiss') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="remindMeLater"
        v-test="'remind-me-later'"
      >
        {{ $t('mollie_error_modal.remind_me_later') }}
      </BaseButton>
      <BaseButton
        :href="`${config.backendUrl}/auth/mollie`"
        v-test="'reenable-mollie'"
      >
        {{ $t('mollie_error_modal.action') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import config from '@/config';
import { useMutation } from '@vue/apollo-composable';
import { useStorage } from '@vueuse/core';
import gql from 'graphql-tag';

const emits = defineEmits(['close']);

const mollieErrorRemindMeLaterAt = useStorage(
  'mollie_error_remind_me_later_at',
  0,
);
const remindMeLater = () => {
  mollieErrorRemindMeLaterAt.value = Date.now();
  emits('close');
};

const { mutate } = useMutation(
  gql`
    mutation dismissMollieErrors($input: DismissMollieErrorsInput!) {
      dismissMollieErrors(input: $input) {
        errors {
          attribute
          message
          type
        }
      }
    }
  `,
  {
    variables: {
      input: {},
    },
  },
);

const dismiss = () => {
  mutate().then(() => {
    emits('close');
  });
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  img {
    max-width: 278px;
  }
}
</style>
