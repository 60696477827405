<template>
  <BaseForm fullHeight @submit="submit">
    <div
      :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]"
      :style="
        $screen === 's' ? { 'padding-bottom': footerHeight + 'px' } : undefined
      "
    >
      <div :class="$style.main">
        <OrderActions mb />
        <OrderLoyaltyPoints
          v-if="isLoyaltyPointsEnabled && order.customerId"
          v-test="'register-order-loyalty-points'"
        />
        <EmptyState v-if="isEmpty" />
        <Favorites v-if="isEmpty && $screen === 's'" :mt="2" />
        <div v-if="!isEmpty" :class="$style.details">
          <div>
            <OrderItems />
            <BaseButton
              v-if="$screen === 's'"
              fullWidth
              color="inverted"
              :mb="2"
              @click="showMobileBrowser = true"
            >
              {{
                filters.capitalize(
                  $t('global.actions.add_item', {
                    item: $t('global.items.item', 2)
                  })
                )
              }}
            </BaseButton>
          </div>
          <div :class="$style.listBottom">
            <OrderNote v-if="!isEmpty" />
            <div :class="$style.total">
              <BaseHeading size="s">
                {{ $t('global.total') }}
              </BaseHeading>
              <div
                :class="[
                  $style.totalAmount,
                  {
                    [$style.animated]: animateTotal
                  }
                ]"
              >
                <BaseHeading size="xl" v-test="'order-amount'">
                  {{ filters.currency(totalPrice) }}
                </BaseHeading>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        v-if="!isEmpty"
        ref="footer"
        :savingDraft="savingDraft"
        @setSavingDraft="savingDraft = true"
      />
      <OpenDrawerModal
        v-if="showOpenDrawerModal"
        @close="showOpenDrawerModal = false"
        @success="goToPayment"
      />
    </div>
  </BaseForm>
</template>

<script lang="ts">
export default {
  name: 'RegisterBrowser',
  components: { OrderActions },
};
</script>

<script setup lang="ts">
import OrderActions from './OrderActions.vue';
import OrderItems from './items/index.vue';
import Footer from './Footer.vue';
import OpenDrawerModal from '@/modules/register/modals/open-drawer/index.vue';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { useRegisterStore } from '@/modules/register/stores/register';
import {
  useRegisterOrderStore,
  type OrderItem
} from '@/modules/register/stores/order';
import { useCompanyStore } from '@/stores/company';
import { useRouter } from 'vue-router';
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import OrderLoyaltyPoints from './OrderLoyaltyPoints.vue';
import { flash, modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { mutateOrder } from '../actions/order';
import EmptyState from './EmptyState.vue';
import OrderNote from '../OrderNote.vue';
import filters from '@/filters';
import { useRegisterBrowserStore } from '@/modules/register/builder/browser/store';
import Favorites from '@/modules/register/builder/browser/pages/default/favorites/index.vue';
import { useElementBounding } from '@vueuse/core';

const { t } = useI18n();

const { order, reset } = useRegisterOrderStore();
const { isEmpty, totalPrice } = storeToRefs(useRegisterOrderStore());
const { isLoyaltyPointsEnabled } = useCompanyStore();
const { isOpen: isDrawerOpen } = useRegisterDrawerStore();
const { cashupEnabled } = storeToRefs(useRegisterStore());
const { showMobileBrowser } = storeToRefs(useRegisterBrowserStore());
const router = useRouter();
const showOpenDrawerModal = ref(false);
const savingDraft = ref(false);

const footer = ref(null);
const { height: footerHeight } = useElementBounding(footer);

const mixpanel = inject<any>('mixpanel');

const submit = () => {
  if (!order.items.length) {
    return;
  }

  if (savingDraft.value) {
    saveDraft();
  } else {
    checkoutOrder();
  }
};

const checkoutOrder = () => {
  mixpanel.track('Register - Checkout order clicked');

  if (cashupEnabled.value && !isDrawerOpen) {
    showOpenDrawerModal.value = true;
  } else {
    goToPayment();
  }
};

const goToPayment = () => {
  router.push({ name: 'register-payment' });
};

const saveDraft = () => {
  mixpanel.track('Register - Save draft clicked');
  const noCards = !order.items.find(
    (item: OrderItem) => item.giftcard || item.prepaidCard
  );

  if (noCards) {
    if (order.id) {
      callOrderMutation('update');
    } else {
      order.draft = true;
      callOrderMutation('create');
    }
  } else {
    modal('warning', {
      message: t('register.draft_notice')
    });
    savingDraft.value = false;
  }
};

const { call: callOrderMutation, response: mutationResponse } = mutateOrder();

watch(mutationResponse, (value) => {
  if (value) {
    handleDraftResponse(value.createOrder || value.updateOrder);
  }
});

const handleDraftResponse = ({ errors }: any) => {
  if (errors) {
    modal('warning', {
      message: t('register.draft_error')
    });
  } else {
    flash(t('register.saved_as_draft'));
  }
  savingDraft.value = false;
  reset();
};

const animateTotal = ref(false);
watch(totalPrice, () => {
  animateTotal.value = true;
  setTimeout(() => {
    animateTotal.value = false;
  }, 100);
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &:not(.smallScreen) {
    height: 100%;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: $spacing;

  .base:not(.smallScreen) & {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 0;
  }

  .base.smallScreen & {
    .details {
      overflow-y: initial;
    }
  }
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  .base:not(.smallScreen) & {
    overflow-y: auto;
    padding: 0 $spacing * 0.5;
    margin: 0 $spacing * -0.5;
  }
}

.listBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing;

  .base:not(.smallScreen) & {
    padding-bottom: $spacing * 1.5;
  }
}

.total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.totalAmount {
  transition: transform 0.3s ease;
  transform-origin: right center;

  &.animated {
    transform: scale(1.15);
  }
}
</style>
