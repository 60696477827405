<template>
  <div>
    <BaseAlert
      v-show="sendingIsDisabled"
      size="s"
      :text="$t('marketing.email.sections.company_in_trial_cant_schedule')"
      mt
    />
    <div v-show="!sendingIsDisabled" :class="$style.flex">
      <BaseText>
        {{ $t('marketing.email.sections.schedule_send_checkbox') }}
      </BaseText>
      <BaseCheckbox v-model="hasDeliverySchedule" v-test="'toggleSchedule'" />
    </div>

    <div v-show="hasDeliverySchedule" :class="$style.dateTime">
      <BaseDatePicker
        v-model="scheduleDate"
        :label="$t('marketing.email.sections.date_and_time')"
        mt
        mr
        onlyFutureDates
        v-test="'scheduleDate'"
      />
      <BaseTimePicker
        v-model="scheduleTime"
        :minValue="minTime"
        mt
        v-test="'scheduleTime'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import { mapGetters } from 'vuex';
import { combineDateTime } from '@/helpers/dates';

import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';

export default defineComponent({
  inject: ['mixpanel'],
  computed: {
    ...mapGetters('marketing/builder', ['sentDescription']),
    ...mapGetters('marketing', ['deliverAt']),
    ...mapState(useCompanyStore, ['allowedToMassSend']),
    selectedDateTime() {
      return this.deliverAt ? dayjs(this.deliverAt).tz() : this.minDateTime;
    },
    minDateTime() {
      return dayjs().add(5, 'minutes');
    },
    minTime() {
      if (this.selectedDateTime >= this.minDateTime) {
        return null;
      }

      return this.minDateTime.format('HH:mm');
    },
    sendingIsDisabled() {
      return !this.allowedToMassSend;
    },
    hasDeliverySchedule: {
      get() {
        return this.$store.getters['marketing/hasDeliverySchedule'];
      },
      set(value) {
        this.mixpanel.track(`Checkbox: ${value} - Newsletter scheduling`);
        this.$store.commit('marketing/TOGGLE_DELIVERY_SCHEDULE');
      },
    },
    scheduleDate: {
      get() {
        return this.selectedDateTime.format('YYYY-MM-DD');
      },
      set(date) {
        this.mixpanel.track('Date picker - Newsletter scheduling', { date });
        this.$store.commit(
          'marketing/SET_DELIVER_AT',
          combineDateTime(date, this.scheduleTime),
        );
      },
    },
    scheduleTime: {
      get() {
        return this.selectedDateTime.format('HH:mm');
      },
      set(time) {
        this.mixpanel.track('Time picker - Newsletter scheduling', { time });
        this.$store.commit(
          'marketing/SET_DELIVER_AT',
          combineDateTime(this.scheduleDate, time),
        );
      },
    },
  },
});
</script>

<style lang="scss" module>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing;
}

.dateTime {
  display: flex;
  align-items: flex-end;
}
</style>
