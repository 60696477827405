<template>
  <div :class="[$style.base, { [$style.inline]: inline }]" :style="style">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    position: {
      type: Object,
      required: false,
    },
    inline: {
      type: Boolean,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
  },
  computed: {
    style() {
      if (!this.position) {
        return;
      }
      const style = {};
      Object.keys(this.position).forEach((property) => {
        style[property] = `${Math.round(this.position[property])}px`;
      });
      return style;
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: $color-error;
  color: $color-text-inverted;
  font-size: 10px;
  line-height: 11px;
  padding: 2px 4px;
  border-radius: 8px;
  min-width: 15px;
  z-index: 1;

  &:empty {
    min-width: 8px;
    min-height: 8px;
  }
}

.inline {
  position: relative;
}

.inner {
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background: $white;
}
</style>
