<template>
  <div
    :class="[
      $style.base,
      {
        [$style.horizontalContent]: horizontalContent,
        [$style.isSmall]: $screen === 's',
        [$style.isLoading]: loading,
        [$style.headerMargin]: headerMargin,
        [$style.hasMaxHeight]: !!maxContentHeight,
        [$style.hasHeader]: showHeader,
        [$style.greenBorder]: greenBorder,
        [$style.gray]: gray,
        [$style.isSelected]: selected,
        'cy-selected': selected,
        [$style.isClosable]: closable,
        [$style.fullHeight]: fullHeight,
        [$style.fullWidth]: fullWidth,
        [$style.noPadding]: noPadding,
        [$style.hasTabs]: !!tabs
      }
    ]"
  >
    <div v-if="closable" :class="$style.close">
      <BaseIcon
        name="close"
        clickable
        @click="$emit('close')"
        v-test="'btn-close'"
      />
    </div>
    <div v-if="showHeader" :class="$style.header">
      <div :class="$style.headerInner">
        <div v-if="tabs" :class="$style.tabs">
          <div
            v-for="(tab, index) in tabs"
            :key="`tab-${index}`"
            :class="[
              $style.tab,
              {
                [$style.active]: tab.active
              }
            ]"
            @click="onTabClick(tab)"
            v-test="`tab-${tab.id}`"
          >
            {{ tab.label }}
          </div>
        </div>
        <div v-else-if="heading || label" :class="$style.heading">
          <BaseHeading v-if="heading">
            {{ heading }}
          </BaseHeading>
          <div v-if="label" :class="$style.label">
            <BaseLabel :state="label" />
          </div>
        </div>
        <div
          v-if="$slots.header"
          :class="[
            $style.headerSlot,
            {
              [$style.headerRight]: !!heading
            }
          ]"
        >
          <slot name="header" />
        </div>
      </div>
    </div>
    <div
      :class="$style.contentWrap"
      :style="
        maxContentHeight ? { 'max-height': `${maxContentHeight}px` } : null
      "
    >
      <div :class="$style.inner">
        <BaseSpinner v-if="loading" />
        <slot v-else />
      </div>
    </div>
    <div v-if="$slots.footer" :class="$style.footer">
      <slot name="footer" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
    horizontalContent: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
    },
    label: String,
    headerMargin: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      validator: (value) =>
        !value.length ||
        !value.find(
          (tab) =>
            !tab.id ||
            !tab.label ||
            (tab.active !== false && tab.active !== true),
        ),
    },
    maxContentHeight: {
      type: Number,
    },
    greenBorder: {
      type: Boolean,
      default: false,
    },
    gray: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  computed: {
    showHeader() {
      return !!this.heading || !!this.tabs || !!this.$slots.header;
    },
  },
  methods: {
    onTabClick(tab) {
      this.tabs.forEach((tab) => (tab.active = false));
      tab.active = true;
    },
  },
});
</script>
<style lang="scss" module>
$closeButtonSize: 20px + $spacing;

.base {
  position: relative;
  background-color: $white;
  border-radius: $radius;
  border: 1px solid $color-border;

  &.gray {
    background-color: $grey-light;
    border: 1px solid rgba(0, 0, 0, 0.03);
  }

  &.isSelected {
    border-color: $color-primary;
  }

  &.greenBorder {
    border-color: $color-success;
  }

  &.fullHeight {
    height: 100%;
    overflow-y: auto;
  }

  &.fullWidth {
    width: 100%;
  }

  @media print {
    padding: 0 !important;
  }
}

.contentWrap {
  .base.hasMaxHeight & {
    overflow-y: auto;
  }
}

.inner {
  position: relative;
  padding: $spacing;

  .base.isClosable:not(.hasHeader) & {
    padding-right: $closeButtonSize;
  }

  .base.hasHeader:not(.headerMargin) & {
    padding-top: 0;
  }

  .base.isLoading & {
    min-height: 89px;
  }

  .base.horizontalContent & {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: $spacing * -1;

    & > * {
      margin-bottom: $spacing;

      &:not(:last-child) {
        margin-right: $spacing;
      }
    }
  }

  .base.horizontalContent.isSmall & {
    flex-wrap: wrap;

    & > * {
      &:not(:last-child) {
        margin-bottom: $spacing;
      }
    }
  }
}

// Specific selector to fix issue with nested BaseCard components
.base.noPadding > .contentWrap > .inner {
  padding: 0;
}

.header {
  padding: 0 $spacing;

  .base.isClosable & {
    padding-right: $closeButtonSize;
  }
}

.headerInner {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing;
  justify-content: space-between;
  align-items: center;
  padding: $spacing 0;
  border-bottom: 1px solid $color-border;

  .base.hasTabs & {
    align-items: stretch;
  }
}

.headerSlot {
  display: flex;
  align-items: center;
}

.headerRight {
  text-align: right;
}

.footer {
  border-top: 1px solid $color-border;
  padding: $spacing;
}

.tabs {
  display: flex;
  padding-left: $spacing * 0.5;
}

.tab {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: $spacing * -1;
  padding: $spacing;

  &:not(:last-child) {
    margin-right: $spacing;
  }

  &:not(.active) {
    opacity: 0.6;
  }

  &.active {
    &:after {
      content: '';
      position: absolute;
      left: $spacing * 0.5;
      width: calc(100% - #{$spacing});
      bottom: 0;
      height: 2px;
      background-color: $color-primary;
    }
  }
}

.heading {
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
}

.label {
  margin: -2px 0;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  padding: $spacing * 0.25;
  z-index: 1;
  width: $closeButtonSize;
  height: $closeButtonSize;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
