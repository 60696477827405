<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    v-test="'sms-credits-page'"
  >
    <BaseHeading mb>
      {{ $t('sms_credits.heading') }}
    </BaseHeading>

    <BaseCard mb>
      <div :class="$style.currentBalance">
        <div>
          <BaseHeading size="s" :mb="0.5">
            {{ $t('sms_credits.balance') }}
          </BaseHeading>
          <BaseText
            size="l"
            bold
            :color="!currentBalance ? 'error' : 'default'"
            v-test="'credit-balance'"
          >
            {{ currentBalance }} {{ $t('sms_credits.credit', currentBalance) }}
          </BaseText>
          <BaseButton
            v-if="isRouteAllowed('messages-purchase-credits')"
            :routerLink="{
              name: 'messages-purchase-credits'
            }"
            mt
            v-test="'sms-credits-purchase'"
          >
            {{ $t('sms_credits.button_submit') }}
          </BaseButton>
        </div>
        <img src="/img/admin/sms-credits.svg" />
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
export default {
  name: 'SmsCredits',
};
</script>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import { isRouteAllowed } from '@/router/helpers';

const { company } = useCompanyStore();
const currentBalance = ref(company.smsCredits || 0);
</script>

<style lang="scss" module>
.currentBalance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  img {
    width: 100px;
  }
}

.amountsWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: calc(50% - #{$spacing * 0.5});
    margin-bottom: $spacing;
  }
}

.amountPrices {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.customAmount {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: $spacing * 0.5;
}

.customAmountRight {
  text-align: right;
}

.cardWrap {
  cursor: pointer;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: $spacing * 2;
}

.footerAlertContainer {
  align-self: stretch;
}
</style>
