<template>
  <component
    :is="route ? 'router-link' : 'a'"
    :to="route || undefined"
    :href="route ? route.path : `https://help.salonized.com/?q=${query}`"
    :class="[
      $style.base,
      {
        [$style.selected]: isSelected
      }
    ]"
    :target="route ? undefined : '_blank'"
    @click="selectItem"
    v-test="'search-result'"
  >
    <BaseIcon :name="icon" />
    <div>
      <div v-if="label" :class="$style.label">
        {{ label }}
      </div>
      {{ subLabel }}
    </div>
    <BaseLabel v-if="isCurrentRoute">
      {{ $t('search.current_page') }}
    </BaseLabel>
    <div v-if="activity" :class="$style.activity" @click="onActivityClick">
      <BaseIcon
        name="activity"
        :color="activityColor"
        :tooltip="{
          text: $t(`customer.activity.${activity}`),
          touch: true
        }"
        size="s"
      />
    </div>
    <div v-if="history" :class="$style.delete" v-test="'search-result-delete'">
      <BaseIcon name="close" clickable @click.stop.prevent="removeHistory" />
    </div>
  </component>
</template>

<script setup lang="ts">
import type { IconName } from '@/components/base-icon/types';
import { useRouter } from 'vue-router';
import { device } from '@/user-context';

const emit = defineEmits(['updateHistory', 'select']);
const props = defineProps<{
  activity?: string;
  icon: IconName;
  label?: string;
  routerLink?: any;
  subLabel?: string;
  history?: boolean;
  addToHistory?: boolean;
  index?: number;
}>();

const query = inject<any>('query');
const historyItems = inject<any>('history');
const selectedIndex = inject<any>('selectedIndex');

const router = useRouter();
const route = props.routerLink ? router.resolve(props.routerLink) : null;
const isSelected = computed(() => selectedIndex.value === props.index);
const isCurrentRoute = computed(
  () => route && router.currentRoute.value.name === route.name,
);

const selectItem = () => {
  if (props.addToHistory) {
    const existing = historyItems.value.find(
      (item) => item.label === props.label,
    );
    const newItem = {
      icon: props.icon,
      label: props.label,
      routerLink: props.routerLink,
      subLabel: props.subLabel,
    };

    emit('updateHistory', [
      newItem,
      ...(existing
        ? historyItems.value.filter((item) => item.label !== existing.label)
        : historyItems.value.slice(0, 4)),
    ]);
  }

  emit('select');
};

const onActivityClick = (e) => {
  if (device.touch) {
    e.stopPropagation();
    e.preventDefault();
  }
};

const removeHistory = () => {
  const index = historyItems.value.findIndex(
    (item) => item.label === props.label,
  );
  historyItems.value.splice(index, 1);
};

const activityColor = computed(() => {
  switch (props.activity) {
    case 'high':
      return 'success';
    case 'medium':
      return 'default';
    case 'low':
      return 'warning';
    case 'none':
      return 'error';
  }
});

const onKeyPress = (e) => {
  if (isSelected.value && e.key === 'Enter' && route) {
    selectItem();
    router.push(route);
  }
};

watch(isSelected, (value) => {
  if (value) {
    window.addEventListener('keydown', onKeyPress);
  } else {
    window.removeEventListener('keydown', onKeyPress);
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyPress);
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
  padding: $spacing * 0.5;
  cursor: pointer;
  border-radius: $radius;

  @include hover {
    background-color: $color-highlight;
  }

  &.selected {
    background-color: $color-highlight;
  }
}

.label {
  font-weight: bold;
}

.delete,
.activity {
  margin-left: auto;
}

.delete {
  display: flex;
  align-items: center;
}

.activity {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activityIcon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: #8c9a9f;

  &.high {
    background-color: #03cfa5;
  }

  &.medium {
    background-color: #fd832d;
  }

  &.none {
    background-color: #ff6161;
  }
}
</style>
