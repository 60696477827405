<template>
  <div
    ref="el"
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.heading">
      <BaseHeading size="s" center>
        {{ $t('admin.invoices.preview.heading') }}
      </BaseHeading>
    </div>
    <div
      :class="$style.preview"
      :style="{
        width: baseWidth + 'px',
        height: baseHeight + 'px',
        transform: `scale(${useVerticalScale ? scaleVertical : scale})`
      }"
    >
      <div :class="$style.top">
        <div :class="$style.left">
          <img v-if="logo" :src="logo" :class="$style.logo" />
        </div>
        <div
          :class="[$style.right, $style.userContent]"
          v-test="'invoice-preview-header'"
        >
          {{ invoiceHeader }}
          <div v-if="formData.invoiceImage" :class="$style.invoiceImage">
            <img :src="formData.invoiceImage" />
          </div>
        </div>
      </div>
      <div :class="$style.header">
        <div
          :class="[$style.left, $style.userContent]"
          v-test="'invoice-preview-recipient'"
        >
          {{ invoiceRecipientTemplate }}
        </div>
        <div :class="[$style.details, $style.right]">
          <div>
            <div>{{ filters.capitalize($t('global.items.employee', 1)) }}</div>
            <div>
              {{ employeeName }}
            </div>
          </div>
          <div>
            <div>{{ $t('global.date') }}</div>
            <div>{{ date }}</div>
          </div>
          <div>
            <div>{{ $t('admin.invoices.preview.invoice_number') }}</div>
            <div>{{ dayjs().format('YYYY') }}-0021</div>
          </div>
        </div>
      </div>
      <div :class="$style.main">
        <div>
          <div :class="$style.tableRow">
            <div>{{ $t('global.description') }}</div>
            <div>{{ $t('global.quantity') }}</div>
            <div>{{ $t('global.price') }}</div>
            <div>{{ $t('global.total') }}</div>
          </div>
          <div :class="$style.tableRow">
            <div>{{ $t('admin.invoices.preview.item_one') }}</div>
            <div>1</div>
            <div>{{ filters.currency(6000) }}</div>
            <div>{{ filters.currency(6000) }}</div>
          </div>
          <div :class="$style.tableRow">
            <div>{{ $t('admin.invoices.preview.item_two') }}</div>
            <div>1</div>
            <div>{{ filters.currency(4500) }}</div>
            <div>{{ filters.currency(4500) }}</div>
          </div>
          <div :class="$style.tableRow">
            <div>{{ $t('admin.invoices.preview.item_three') }}</div>
            <div>1</div>
            <div>{{ filters.currency(8000) }}</div>
            <div>{{ filters.currency(8000) }}</div>
          </div>
          <div
            v-if="formData.settings.sales.showTreatmentDateOnInvoice"
            :class="[$style.tableRow, $style.treatmentDate]"
            v-test="'invoice-preview-treatmentDate'"
          >
            {{ $t('admin.invoices.preview.treatment_date') }}: {{ date }}
          </div>
        </div>
        <div :class="$style.tableFooter">
          <div
            v-show="showVat"
            :class="$style.tableFooterRow"
            v-test="'invoice-preview-subtotal'"
          >
            <div>
              <div>{{ $t('global.subtotal') }}</div>
            </div>
            <div>
              {{ filters.currency(15290) }}
            </div>
          </div>
          <div
            v-show="showVat"
            :class="$style.tableFooterRow"
            v-test="'invoice-preview-vat'"
          >
            <div>{{ $t('admin.invoices.preview.vat_rate') }}</div>
            <div>
              {{ filters.currency(3210) }}
            </div>
          </div>
          <div :class="$style.tableFooterRow">
            <div>{{ $t('global.total') }}</div>
            <div>
              {{ filters.currency(18500) }}
            </div>
          </div>
          <div :class="$style.tableFooterRow">
            <div>{{ $t('global.payments.cash') }}</div>
            <div>
              {{ filters.currency(18500) }}
            </div>
          </div>
        </div>
      </div>
      <div
        :class="[$style.footer, $style.userContent]"
        v-test="'invoice-preview-footer'"
      >
        {{ invoiceFooter }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useElementSize } from '@vueuse/core';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';
import dayjs from '@/dayjs';
import { useLocationsStore } from '@/stores/locations';

const props = defineProps<{
  formData: any;
}>();

const { company } = useCompanyStore();
const { location } = useLocationsStore();
const logo = company.logo?.small || null;

const { employees } = useResourcesStore();
const employeeName = computed(
  () => employees[Math.floor(Math.random() * employees.length)].name,
);

const formatContent = (content: string) => {
  if (content?.includes('{{company_address}}')) {
    content = content.replace('{{company_address}}', company.fullAddress || '');
  }

  if (content?.includes('{{company_bank_account}}')) {
    content = content.replace(
      '{{company_bank_account}}',
      location?.bankAccount || '',
    );
  }

  return content;
};

const showVat = computed(() => props.formData.settings.sales.showVatOnInvoice);
const date = computed(
  () =>
    `${dayjs().format('D')} ${filters.date(dayjs().format(), { format: 'monthLong' })} ${dayjs().format('YYYY')}`,
);

const invoiceHeader = computed(() =>
  formatContent(props.formData.invoiceHeader),
);
const invoiceRecipientTemplate = computed(() =>
  formatContent(props.formData.invoiceRecipientTemplate),
);
const invoiceFooter = computed(() =>
  formatContent(props.formData.invoiceFooter),
);

const el = ref(null);
const { width, height } = useElementSize(el);

const baseWidth = 800;
const baseHeight = baseWidth * 1.41; // A-paper ratio

const scale = computed(() => width.value / baseWidth);
const useVerticalScale = computed(
  () => baseHeight * scale.value > height.value,
);
const scaleVertical = computed(() => height.value / baseHeight);
</script>

<style lang="scss" module>
.base {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  padding-top: 36px;
}

.heading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
}

.preview {
  position: relative;
  background-color: white;
  font-size: 14px;
  padding: 3rem 4rem;
  flex-shrink: 0;

  .base.smallScreen & {
    margin: -60% 0;
  }
}

.userContent {
  white-space: pre-line;
}

.top,
.header {
  display: flex;
  justify-content: space-between;
  min-height: 18%;
  padding-bottom: 1rem;
}

.top {
  font-size: 12px;
}

.left,
.right {
  width: 40%;
}

.logo {
  max-height: 100%;
  max-width: 100%;
}

.invoiceImage {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0;

  img {
    max-width: 140px;
    max-height: 80px;
  }
}

.details {
  & > * {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 0.7em;
    }

    & > * {
      width: 50%;

      &:first-child {
        font-weight: bold;
      }
    }
  }
}

.tableRow,
.tableFooterRow {
  display: flex;
  padding: 0.5rem 0;
}

$borderColor: #333;

.tableRow {
  &:first-child {
    font-weight: bold;
    border-bottom: 1px solid $borderColor;
  }

  & > * {
    &:first-child {
      width: 40%;
    }

    &:not(:first-child) {
      width: 20%;
    }

    &:nth-child(2) {
      text-align: center;
    }

    &:not(:first-child):not(:nth-child(2)) {
      text-align: right;
    }
  }
}

.treatmentDate {
  font-style: italic;
  margin-top: 0.5rem;
}

.tableFooter {
  border-top: 1px solid $borderColor;
}

.tableFooterRow {
  &:nth-child(2) {
    font-style: italic;
  }

  &:nth-child(3) {
    font-weight: bold;
  }

  & > * {
    text-align: right;

    &:first-child {
      width: 80%;
    }

    &:not(:first-child) {
      width: 20%;
    }
  }

  &:nth-child(4) {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 1px;
      width: 40%;
      background-color: $borderColor;
    }
  }
}

.footer {
  position: absolute;
  left: 0;
  bottom: 3rem;
  width: 100%;
  font-style: italic;
  font-size: 11px;
  text-align: center;
  padding: 2rem;
  max-height: 110px;
  overflow: hidden;
}
</style>
