<template>
  <BaseModalLarge
    :heading="$t('calendar.appointment_requests.heading')"
    @close="$router.push({ name: 'calendar' })"
  >
    <RejectAppointmentRequest
      v-if="appointmentRejectionId"
      :appointmentId="appointmentRejectionId"
      @close="appointmentRejectionId = null"
      @submit="onRejectionSubmit"
    />

    <BaseAlert
      v-if="!companySettings.bookings.appointmentRequiresConfirmation"
      :text="$t('calendar.appointment_requests.disabled_heading')"
      :primaryAction="{
        text: $t('calendar.appointment_requests.disabled_link'),
        routerLink: {
          name: 'admin-online-bookings',
          query: { section: 'main-settings' }
        }
      }"
    />
    <BaseTable
      v-if="bookingRequests.length"
      :headers="[
        $t('global.date'),
        $t('global.items.customer', 1),
        $t('global.items.service', 2),
        $t('global.duration'),
        $t('global.employee')
      ]"
      :rows="
        bookingRequests.map((req) => ({
          id: req.id,
          cells: [
            {
              value: filters.dateTime(req.startAt),
              type: 'date'
            },
            req.customer.fullName,
            req.serviceNames,
            `${req.duration} ${$t('global.minute_short')}`,
            req.employeeNames
          ],
          actions: [
            {
              icon: 'eye',
              name: 'view',
              label: $t('calendar.appointment_requests.view_request')
            },
            {
              icon: 'person',
              name: 'customer',
              label: $t('global.actions.view_customer')
            },
            {
              icon: 'check-square',
              name: 'confirm',
              label: $t('appointment.confirmation.confirm')
            },
            {
              icon: 'close-square',
              name: 'reject',
              label: $t('appointment.confirmation.reject')
            }
          ]
        }))
      "
      @action="onActionClick"
      v-test="'appointment-requests'"
    />
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
    <BaseSpinner v-show="saving" inline mt />

    <EmptyPageContent
      v-if="!loading && !bookingRequests.length"
      imageName="waiting-list"
    />
  </BaseModalLarge>
</template>

<script setup lang="ts">
import { usePagination } from '@/apollo/pagination';
import gql from 'graphql-tag';
import filters from '@/filters';
import LoadMore from '@/components/LoadMore.vue';
import { useRouter } from 'vue-router';
import { useAppointmentConfirmation } from '@/modules/calendar/actions/appointments';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useCompanyStore } from '@/stores/company';
import RejectAppointmentRequest from './RejectAppointmentRequest.vue';
import { ref } from 'vue';

const { companySettings } = useCompanyStore();

const GET_BOOKING_REQUESTS = gql`
  query getBookingRequests(
    $pagination: PaginationAttributes!
    $locationId: ID
    $dataScope: DataScope
  ) {
    bookingRequests(
      pagination: $pagination
      locationId: $locationId
      dataScope: $dataScope
    ) {
      customer {
        fullName
        id
      }
      duration
      employeeNames
      id
      serviceNames
      startAt
    }
  }
`;

const { bookingRequests, allDataFetched, loading, fetchMore, refetch } =
  usePagination({
    fieldName: 'bookingRequests',
    query: GET_BOOKING_REQUESTS,
    options: {
      multiLocation: true,
    },
  });

const router = useRouter();

const { confirm, loading: saving } = useAppointmentConfirmation();

const appointmentRejectionId = ref<number | null>(null);

const onActionClick = ({ id, option }: { id: number; option: string }) => {
  switch (option) {
    case 'view':
      router.push({ name: 'appointment', params: { appointmentId: id } });
      break;
    case 'customer':
      {
        const appointment = bookingRequests.value.find(
          (item) => item.id === id,
        );
        router.push({
          name: 'customer-overview',
          params: { customerId: appointment.customer.id },
        });
      }
      break;
    case 'confirm':
      confirm(id).then(() => {
        refetch();
      });
      break;
    case 'reject':
      appointmentRejectionId.value = id;
      break;
  }
};

const onRejectionSubmit = () => {
  appointmentRejectionId.value = null;
  refetch();
};
</script>
