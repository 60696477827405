<template>
  <Appointment
    v-if="currentStep === 5"
    @submit="handleGoNext"
    @close="handleClose"
  />
  <BaseModal
    v-if="currentStep > 0 && currentStep < 5"
    small
    useForm
    :heading="$t(`onboarding.new_onboarding.${headings}.header`)"
    @submit="handleGoNext"
    @close="handleClose"
  >
    <Category
      v-if="currentStep === 1"
      v-model="categoryName"
      :categories="categories!"
    />
    <Service
      v-if="currentStep === 2"
      :formData="serviceForm"
      :category="categoryName"
    />
    <Employee v-if="currentStep === 3" v-model="employeeName" />
    <Customer v-if="currentStep === 4" :customer="customer" />
    <template #footerSub>
      <BaseText v-if="currentStep === 2" link @click="handleBack">
        {{ $t('onboarding.new_onboarding.back') }}
      </BaseText>
    </template>
    <template #footer>
      <ProgressBar :tasks="tasks" small />
      <BaseButton
        :loading="isLoading"
        submitForm
        v-test="'btn-onboarding-next'"
      >
        {{ $t('onboarding.new_onboarding.next') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { computed, inject, ref, toRefs, reactive, watch } from 'vue';
import Category from './Category.vue';
import Service from './Service.vue';
import Employee from './Employee.vue';
import Customer from './Customer.vue';
import Appointment from './Appointment.vue';
import { useResourcesStore } from '@/stores/resources';
import { useServicesStore } from '@/stores/services';
import { useLocationsStore } from '@/stores/locations';
import ProgressBar from './ProgressBar.vue';
import { usePreCreateStore, Action } from '@/stores/calendar-pre-create';
import { useCreateEventStore } from '@/stores/calendar-create-event';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';
import { categories as categoryData } from './onboarding-utils';
import type { SettingsOnboardingAttribute } from '@/types';
import { useI18n } from 'vue-i18n';
import { useStorage } from '@vueuse/core';
import eventBus from '@/event-bus';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { customerFragment } from '@/graphql-fragments';

const emits = defineEmits(['close']);
const { t } = useI18n();

const { company, updateCompany } = useCompanyStore();
const { createCategory, getServiceCategories, getServices } =
  useServicesStore();
const { services }: any = toRefs(useServicesStore());
const { user } = useUserStore();
const { locationIds } = useLocationsStore();
const { employees, updateResource } = useResourcesStore();
const mixpanel = inject<any>('mixpanel');
const isLoading = ref(false);
const { setState } = usePreCreateStore();
const { setEventData } = useCreateEventStore();
const router = useRouter();
let createdCustomer: any;

const resourceId = ref(employees[0].id);
const storedCustomer = useStorage('onboarding-customer', {});
const currentStep = ref(company.settings.onboarding.flowStep || 1);

const categories = computed(() => {
  const foundCategory = categoryData.find(
    (item: any) =>
      item.name.toLowerCase() === company.companyType?.toLowerCase(),
  )?.service;

  return foundCategory?.map((value: any) => ({
    value,
    label: t(
      `onboarding.new_onboarding.services.${company.companyType}.${value}`,
    ),
  }));
});

const tasks = computed(() =>
  Array.from({ length: 4 }, (_, idx: number) => ({
    status: idx + 2 > currentStep.value ? false : 'completed',
  })),
);

const categoryName = ref(
  company.settings.onboarding.serviceCategoryName || categories.value![0].value,
);

watch(categoryName, () => {
  if (!company.settings.onboarding.serviceName) {
    serviceForm.name = t(
      `onboarding.new_onboarding.services.${company.companyType}.${categoryName.value}`,
    );
  }
});

const serviceForm = reactive({
  name:
    company.settings.onboarding.serviceName ||
    t(
      `onboarding.new_onboarding.services.${company.companyType}.${categoryName.value}`,
    ),
  price: company.settings.onboarding.servicePrice || 6000,
  duration: company.settings.onboarding.serviceDuration || 60,
});

const employeeName = ref(
  company.settings.onboarding.employeeName || employees[0].name,
);

const defaultCustomer = {
  firstName: t('onboarding.new_onboarding.customer.first_name'),
  lastName: t('onboarding.new_onboarding.customer.last_name'),
};

const customer = reactive({
  firstName:
    company.settings.onboarding.customerFirstName || defaultCustomer.firstName,
  lastName:
    company.settings.onboarding.customerLastName || defaultCustomer.lastName,
});

const headings = computed(() => {
  switch (currentStep.value) {
    case 1:
      return 'services';
    case 2:
      return 'service';
    case 3:
      return 'employee';
    case 4:
      return 'customer';
    default:
      return 'services';
  }
});

const handleBack = () => {
  mixpanel.track('Onboarding journey - Back clicked', {
    step: mixpanelTrackStep(),
  });
  currentStep.value -= 1;
  updateStep('flowStep', currentStep.value - 1);
};

const updateStep = (task: keyof SettingsOnboardingAttribute, newValue: any) => {
  updateCompany({
    settings: {
      onboarding: {
        [task]: newValue,
      },
    },
  });
};

const updateServiceSteps = () => {
  updateCompany({
    settings: {
      onboarding: {
        serviceName: serviceForm.name,
        servicePrice: serviceForm.price,
        serviceDuration: serviceForm.duration,
      },
    },
  });
};

const updateCustomerStep = () => {
  updateCompany({
    settings: {
      onboarding: {
        customerFirstName: customer.firstName,
        customerLastName: customer.lastName,
      },
    },
  });
};

const CREATE_SERVICE_GROUP = gql`
  mutation createServiceGroup($input: CreateServiceGroupInput!) {
    createServiceGroup(input: $input) {
      serviceGroup {
        id
        name
      }
      errors {
        type
      }
    }
  }
`;

const createServiceGroup = (data) => {
  const { mutate } = useMutation(CREATE_SERVICE_GROUP, {
    variables: {
      input: data,
    },
  });

  return mutate();
};

const handleClose = () => {
  mixpanel.track('Onboarding journey - Closed', { step: mixpanelTrackStep() });
  emits('close');
  eventBus.$emit('open-task-menu');
  updateStep('flowStep', currentStep.value);
};

const handleGoNext = () => {
  mixpanel.track('Onboarding journey - Next clicked', {
    step: mixpanelTrackStep(),
  });
  switch (currentStep.value) {
    case 1:
      updateStep('serviceCategoryName', categoryName.value);
      currentStep.value += 1;
      break;
    case 2:
      isLoading.value = true;
      createCategory(
        {
          name: t(
            `onboarding.new_onboarding.services.${company.companyType}.${categoryName.value}`,
          ),
          color: '#4c49c5',
        },
        { hideFlashMessage: true },
      ).then((id: any) => {
        updateServiceSteps();
        const service = {
          bookable: true,
          bookableLocationIds: locationIds,
          buffer: 0,
          categoryId: id,
          description: '',
          duration: serviceForm.duration,
          durationFinish: 0,
          durationProcessing: 0,
          durationSetup: 0,
          extraInformation: '',
          medical: false,
          name: serviceForm.name,
          price: serviceForm.price,
          rebookEnabled: false,
          requirementsAttributes: [
            {
              type: 'EMPLOYEE',
              primary: true,
              resourceIds: [resourceId.value],
            },
          ],
          requiresProcessingTime: false,
          rwgName: '',
          vatRateId: null,
        };

        const createData = {
          name: serviceForm.name,
          servicesAttributes: [service],
        };

        createServiceGroup(createData, { hideFlashMessage: true }).then(() => {
          currentStep.value += 1;
          isLoading.value = false;
          getServices();
          getServiceCategories();
        });
      });
      break;
    case 3:
      isLoading.value = true;
      if (employeeName.value === employees[0].name) {
        isLoading.value = false;
        currentStep.value += 1;
      } else {
        updateStep('employeeName', employeeName.value);
        updateResource({
          id: resourceId.value,
          name: employeeName.value,
        }).then(() => {
          isLoading.value = false;
          currentStep.value += 1;
        });
      }
      break;
    case 4:
      {
        isLoading.value = true;
        updateCustomerStep();

        const { mutate } = useMutation(
          gql`
            mutation createCustomer($input: CreateCustomerInput!) {
              createCustomer(input: $input) {
                customer {
                  ...customer
                }
                errors
              }
            }
            ${customerFragment}
          `,
          {
            variables: {
              input: {
                ...customer,
                email: user?.email,
              },
            },
          },
        );

        mutate().then(
          ({
            data: {
              createCustomer: { customer },
            },
          }) => {
            isLoading.value = false;
            createdCustomer = customer;
            localStorage.setItem(
              'onboarding-customer',
              JSON.stringify(customer),
            );
            currentStep.value += 1;
          },
        );
      }
      break;
    case 5:
      setEventData({ resourceId: resourceId.value });

      setState({
        action: Action.Create,
        customer: createdCustomer || storedCustomer.value,
        serviceIds: [services.value[services.value.length - 1].id],
      });
      router.push({ name: 'create-appointment' });
      setTimeout(() => {
        currentStep.value += 1;
        emits('close');
      }, 100);

      eventBus.$emit('open-task-menu');
      break;
    default:
  }
};

const mixpanelTrackStep = () => {
  switch (currentStep.value) {
    case 1:
      return 'Service category';
    case 2:
      return 'Service';
    case 3:
      return 'Employee';
    case 4:
      return 'Customer';
    case 5:
      return 'Success';
    default:
  }
};
</script>
