<template>
  <BaseModal
    :heading="$t('marketing.discounts.enable-last-minute-discount-modal.setup')"
    :loading="isLoading"
    small
    parentRoute="marketing-discounts"
  >
    <BaseText mb>
      {{ $t('marketing.discounts.enable-last-minute-discount-modal.info') }}
    </BaseText>
    <BaseText :mb="2">
      <a
        :class="$style.link"
        href="https://help.salonized.com/articles/5669294"
        target="_blank"
      >
        {{ $t('marketing.discounts.more_tips') }}
      </a>
    </BaseText>
    <BaseGrid container>
      <BaseGrid :size="6" alignBottom>
        <BaseDropdown
          v-model="discountPercentage"
          :label="
            $t(
              'marketing.discounts.enable-last-minute-discount-modal.apply_percentage'
            )
          "
          :options="percentageOptions"
          v-test="'percentage-options'"
        />
      </BaseGrid>
      <BaseGrid :size="6" alignBottom>
        <BaseDropdown
          v-model="discountWindow"
          :label="
            $t(
              'marketing.discounts.enable-last-minute-discount-modal.for_bookings_made'
            )
          "
          :options="discountWindowOptions"
          v-test="'discount-window-options'"
        />
      </BaseGrid>
    </BaseGrid>
    <template #footer>
      <BaseButton color="inverted" @click="close" v-test="'discountCodeCancel'">
        {{ $t('global.actions.cancel') }}
      </BaseButton>

      <BaseButton :loading="isLoading" @click="onSubmit" v-test="'saveButton'">
        {{ $t('global.apply') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isLoading: false,
      discountPercentage: 10,
      discountWindow: 'TWO_HOURS',
    };
  },
  computed: {
    percentageOptions() {
      return [5, 10, 15, 20, 30, 50].map((percentage) => ({
        value: percentage,
        label: `${percentage}%`,
      }));
    },
    discountWindowOptions() {
      return [
        'ONE_HOUR',
        'TWO_HOURS',
        'FOUR_HOURS',
        'ONE_DAY_FROM_NOW',
        'TWO_DAYS_FROM_NOW',
        'SAME_DAY',
      ].map((option) => ({
        value: option,
        label: this.$t(
          `marketing.discounts.booking_range.${option.toLowerCase()}`,
        ),
      }));
    },
    defaultPercentage() {
      return this.percentageOptions.find(
        (item) => item.value === this.discountPercentage,
      ).name;
    },
    defaultDiscountWindow() {
      return this.discountWindowOptions.find(
        (item) => item.value === this.discountWindow,
      ).name;
    },
  },
  methods: {
    close() {
      this.$router.push({
        name: 'marketing-discounts',
      });
    },
    onSelectDiscount({ value }) {
      this.discountPercentage = value;
    },
    onSelectDiscountWindow({ value }) {
      this.discountWindow = value;
    },
    onSubmit() {
      this.isLoading = true;

      const input = {
        discountPercentage: this.discountPercentage,
        discountWindow: this.discountWindow,
        enabled: true,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateLastMinuteDiscounts(
              $input: UpdateLastMinuteDiscountsInput!
            ) {
              updateLastMinuteDiscounts(input: $input) {
                lastMinuteDiscounts {
                  enabled
                  discountPercentage
                  discountWindow
                }
              }
            }
          `,
          variables: {
            input,
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'lastMinuteDiscounts' });
            cache.gc();
          },
        })
        .then(() => {
          flash(this.$t('global.flash.last_minute_discount_enabled'));
          this.close();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  apollo: {
    lastMinuteDiscounts: {
      query: gql`
        query getLastMinuteDiscounts {
          lastMinuteDiscounts {
            enabled
            discountPercentage
            discountWindow
          }
        }
      `,
      result(response) {
        const { discountPercentage, discountWindow } =
          response.data.lastMinuteDiscounts;
        this.discountPercentage = discountPercentage;
        this.discountWindow = discountWindow;
      },
    },
  },
});
</script>

<style lang="scss" module>
.link {
  // because ember
  color: $color-primary !important;
  text-decoration: none;
}
</style>
