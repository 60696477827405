<template>
  <div :class="$style.base">
    <BaseHeading mb size="xl" v-test="'previewTitle'">
      {{ subject ? subject : $t('marketing.email.preview.subject_default') }}
    </BaseHeading>

    <div
      :class="[
        $style.htmlStyle,
        {
          [$style.isSmall]: $screen === 's'
        }
      ]"
      v-html="message ? message : $t('marketing.email.preview.content_default')"
      v-test="'previewContent'"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    ...mapGetters('marketing', ['subject', 'message']),
  },
});
</script>

<style lang="scss" module>
.base {
  align-self: flex-start;
  margin-bottom: $spacing * 2;
}

.htmlStyle {
  a {
    color: #06c;
  }
  &.isSmall {
    img {
      width: 100%;
    }
  }

  img {
    max-width: 100%;
  }
}
</style>
