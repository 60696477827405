import type { CreateServiceGroupInput, UpdateServiceGroupInput } from '@/types';
import type { SortOrder } from './';

import { useMutation } from '@vue/apollo-composable';
import {
  CREATE_SERVICE_GROUP,
  DELETE_SERVICE_GROUP,
  DUPLICATE_SERVICE_GROUP,
  SORT_SERVICE_VARIATION_GROUPS,
  UPDATE_SERVICE_GROUP,
} from './graphql';

export const useServiceVariationGroups = () => {
  const createServiceGroup = (serviceGroupData: CreateServiceGroupInput) => {
    const { mutate } = useMutation(CREATE_SERVICE_GROUP, {
      variables: {
        input: serviceGroupData,
      },
    });

    return mutate();
  };

  const updateServiceGroup = (serviceGroupData: UpdateServiceGroupInput) => {
    const { mutate } = useMutation(UPDATE_SERVICE_GROUP, {
      variables: {
        input: serviceGroupData,
      },
    });

    return mutate();
  };

  const deleteServiceVariationGroup = (id: number) => {
    const { mutate } = useMutation(DELETE_SERVICE_GROUP, {
      variables: {
        input: {
          id,
        },
      },
    });

    return mutate();
  };

  const duplicateServiceVariationGroup = (id: number) => {
    const { mutate } = useMutation(DUPLICATE_SERVICE_GROUP, {
      variables: {
        input: {
          id,
        },
      },
    });

    return mutate();
  };

  const sortServiceVariationGroups = (sortableAttributes: SortOrder[]) => {
    const { mutate } = useMutation(SORT_SERVICE_VARIATION_GROUPS, {
      variables: {
        input: {
          sortableAttributes,
        },
      },
    });

    return mutate();
  };

  return {
    createServiceGroup,
    updateServiceGroup,
    deleteServiceVariationGroup,
    duplicateServiceVariationGroup,
    sortServiceVariationGroups,
  };
};
