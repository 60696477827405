<template>
  <div v-if="groupedTransactions.length" v-test="'invoice-transactions'">
    <BaseHeading>
      {{ filters.capitalize($t('global.items.payment', 2)) }}
    </BaseHeading>
    <BaseTable
      :headers="[
        $t('global.type'),
        {
          value: $t('global.amount_currency'),
          alignRight: true
        }
      ]"
      :rows="
        groupedTransactions.map((transaction) => ({
          cells: [
            transaction.label,
            {
              value: filters.currency(transaction.amount),
              alignRight: true
            }
          ]
        }))
      "
      :footers="[
        {
          value: `${$t('global.total')}: ${filters.currency(totalAmount)}`,
          alignRight: true
        }
      ]"
      disableZebra
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import type { Transaction } from '@/types';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

interface formattedTransaction {
  label: string;
  amount: number;
  type: string;
}

export default defineComponent({
  props: {
    transactions: {
      type: Array as PropType<Transaction[]>,
      required: true,
    },
    totalAmount: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      filters,
    };
  },
  computed: {
    groupedTransactions(): formattedTransaction[] {
      const transactions: formattedTransaction[] = [];

      this.transactions.forEach((transaction: Transaction) => {
        let existing;

        if (
          transaction.type !== 'GIFTCARD' &&
          transaction.type !== 'PREPAID_CARD' &&
          transaction.type !== 'POS'
        ) {
          existing = transactions.find((t) => t.type === transaction.type);
        }

        if (existing) {
          existing.amount += transaction.amount;
        } else if (transaction.amount) {
          transactions.push({
            label:
              transaction.type === 'GIFTCARD'
                ? `${this.filters.capitalize(this.$t('global.items.giftcard', 1))}: ${this.filters.giftCardCode(transaction.giftcard?.code)}`
                : this.$t(`global.payments.${transaction.type.toLowerCase()}`),
            amount: transaction.amount,
            type: transaction.type,
          });
        }
      });

      return transactions;
    },
  },
});
</script>
