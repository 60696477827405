<template>
  <BaseModal
    small
    :heading="$t('admin.general_settings.delete_account_title')"
    @close="$emit('close')"
  >
    <div v-for="(error, index) in formErrors" :key="`form-error-${index}`">
      <BaseAlert
        color="error"
        :text="$t('global.validations.invalid', { value: error.attribute })"
        mb
      />
    </div>

    <BaseAlert
      color="error"
      :text="$t('admin.general_settings.delete_description')"
      :mb="2"
    />

    <BaseCheckbox
      v-model="formData.deleteConfirmation"
      :label="$t('admin.general_settings.delete_confirm')"
      :description="$t('admin.general_settings.delete_confirm_hint')"
      required
      :mb="2"
      v-test="'delete-account-confirm-checkbox'"
    />

    <BaseText mb bold>{{ $t('global.password_confirmation') }}</BaseText>
    <BaseInput
      v-model="formData.password"
      type="password"
      :label="$t('global.password')"
      required
      v-test="'delete-account-password-input'"
    />
    <template #footer>
      <BaseButton
        color="inverted"
        @click="handleAccountDelete"
        v-test="'delete-account-submit-btn'"
      >
        {{ $t('admin.general_settings.delete_btn') }}
      </BaseButton>

      <BaseButton @click="$emit('close')" v-test="'ip-modal-save-button'">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue';
import { flash } from '@/helpers/ui';
import { useVuelidate } from '@vuelidate/core';
import { useCompanyStore } from '@/stores/company';
import type { Error as ErrorType } from '@/types';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineEmits(['close']);

const router = useRouter();
const { deleteCompany } = useCompanyStore();
const formErrors = ref<ErrorType[]>([]);

const isSubmitted = ref(false);
const formData = reactive({
  deleteConfirmation: false,
  password: '',
});

const v$ = useVuelidate();

const handleAccountDelete = () => {
  isSubmitted.value = true;

  v$.value.$touch();

  if (v$.value.$invalid) {
    return;
  }

  deleteCompany({
    password: formData.password,
  }).then(({ errors }) => {
    if (errors?.length) {
      formErrors.value = errors;
    } else {
      flash(t('global.flash.account_deleted'));
      setTimeout(() => {
        router.push({ name: 'logged-out' });
      }, 1000);
    }
  });
};
</script>
