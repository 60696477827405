<template>
  <component
    :is="resource.state !== 'deleted' ? 'router-link' : 'div'"
    :class="$style.base"
    :to="
      resource.state !== 'deleted'
        ? { name: routerLink, params: { id: resource.id } }
        : null
    "
    :style="
      color
        ? {
            'border-color': color,
            'border-left-color': resource.color
          }
        : null
    "
  >
    <div :class="$style.section">
      <BaseAvatar
        :resource="resource"
        size="l"
        v-test="'resource-item-avatar'"
      />
      <div
        :class="$style.name"
        :style="color ? { 'background-color': color } : null"
        v-test="'resource-item-name'"
      >
        {{ resource.name }}
      </div>
    </div>
    <div :class="$style.section">
      <template v-if="isDeleted">
        <div :class="$style.deletedText" v-test="'resource-item-deleted'">
          <BaseText :size="$screen === 's' ? 's' : 'm'">
            {{
              $t('global.deleted_time_ago', {
                time_ago: dayjs(resource.updatedAt).fromNow()
              })
            }}
          </BaseText>
        </div>
        <div
          :class="$style.btnRestore"
          @click="restore"
          v-test="'resource-item-restore'"
        >
          <BaseIcon
            name="undo"
            :tooltip="$t('global.actions.restore_resource')"
          />
        </div>
      </template>
      <template v-else>
        <div
          v-if="!smallScreen"
          :class="$style.services"
          v-test="'resource-item-services'"
        >
          {{ resource.servicesCount }}
          <BaseIcon
            name="scissors"
            size="s"
            :ml="0.25"
            :tooltip="`${resource.servicesCount} ${$t('global.items.service', resource.servicesCount)}`"
          />
        </div>
        <div
          v-if="multiLocation && locations.length && !smallScreen"
          :class="$style.locations"
          v-test="'resource-item-locations'"
        >
          {{ locations.length }}
          <BaseIcon
            name="pin"
            size="s"
            :ml="0.25"
            :tooltip="
              locations.length === 1
                ? locations[0].internalName
                : `${locations.length} ${$t('global.items.location', 2)}`
            "
          />
        </div>
        <div :class="$style.icon">
          <BaseIcon
            v-if="resource.bookable && !smallScreen"
            name="globe"
            size="s"
            :tooltip="{
              text: $t('admin.resources.online_bookable'),
              touch: true
            }"
            v-test="'resource-item-bookable'"
          />
        </div>
        <div v-if="listLength > 1">
          <BaseCounter
            v-model="listIndex"
            :minValue="0"
            :maxValue="listLength - 1"
            hideValue
            reverse
            v-test="'resource-sort-order'"
          />
        </div>
        <div :class="$style.more" v-test="'resource-item-more'">
          <BaseMore
            :options="['edit', 'delete']"
            @select="onOptionSelect($event, resource)"
          />
        </div>
      </template>
    </div>
  </component>
</template>

<script lang="ts">
import { modal } from '@/helpers/ui';
import dayjs from '@/dayjs';
import { lightColor } from '@/helpers/formatting';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    resource: {
      type: Object,
      required: true,
      validator: (data) => !!data.id && !!data.name,
    },
    listLength: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['updateSortOrder'],
  setup() {
    const { deleteResource, restoreResource } = useResourcesStore();
    return {
      deleteResource,
      restoreResource,
      dayjs,
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['multiLocation']),
    ...mapState(useResourcesStore, ['hasSingleEmployee']),
    isDeleted() {
      return this.resource.state === 'deleted';
    },
    color() {
      return this.resource.color ? lightColor(this.resource.color) : null;
    },
    locations() {
      const { locations } = useLocationsStore();
      const locationIds = this.resource.locationIds;

      if (locationIds?.length) {
        return locations.filter((location) =>
          locationIds.includes(location.id),
        );
      } else {
        return [];
      }
    },
    routerLink() {
      return this.isEmployee ? 'admin-employee' : 'admin-resource';
    },
    smallScreen() {
      return this.$screen === 's';
    },
    isEmployee() {
      return this.resource.type === 'employee';
    },
    listIndex: {
      get() {
        return this.index;
      },
      set(value) {
        this.$emit('updateSortOrder', value);
      },
    },
  },
  methods: {
    onOptionSelect(option) {
      if (option === 'edit') {
        this.edit();
      }
      if (option === 'delete') {
        this.delete();
      }
    },
    edit() {
      const type = this.isEmployee ? 'employee' : 'resource';
      this.$router.push({
        name: `admin-${type}`,
        params: { id: this.resource.id },
      });
    },
    delete() {
      if (!this.isEmployee && this.resource.servicesCount > 0) {
        modal('warning', {
          message: this.$t('global.warning.delete', {
            item: this.resource.name,
          }),
          subMessage: this.$t('resource.delete_error'),
        });
      } else if (this.isEmployee && this.hasSingleEmployee) {
        modal('warning', {
          message: this.$t('employee.delete_all'),
          subMessage: this.$t('employee.delete_error'),
        });
      } else {
        this.deleteResource(this.resource);
      }
    },
    restore() {
      this.restoreResource(this.resource);
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-left-width: 3px;
  border-radius: $radius;
  background-color: white;
}

.section {
  display: flex;
  align-items: center;
  padding: $spacing * 0.5;

  & > *:not(:first-child) {
    margin-left: $spacing;
  }
}

.name {
  position: relative;
  padding: $spacing * 0.5;
  font-weight: bold;
  border-radius: 6px;
}

.services,
.locations {
  display: flex;
  align-items: center;
}

.more {
  margin: $spacing * -0.5 $spacing * -0.5 $spacing * -0.5 0 !important;
}

.deletedText {
  text-transform: lowercase;
}

.btnRestore {
  cursor: pointer;
}

.icon {
  width: $spacing;
}

.icon,
.more {
  flex-shrink: 0;
}
</style>
