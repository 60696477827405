<template>
  <BaseModal
    :heading="$t('drawer.new_transaction_heading')"
    small
    useForm
    :parentRoute="{
      name: 'drawer'
    }"
    @submit="submit"
  >
    <BaseText :mb="2">
      {{ $t('drawer.new_transaction_description') }}
    </BaseText>
    <BaseInput
      v-model="description"
      :label="$t('global.description')"
      required
      mb
      v-test="'drawer-new-transaction-description'"
    />
    <BaseGrid container>
      <BaseGrid :size="cashupEnabled ? 12 : 6">
        <BaseInput
          v-model="amount"
          :label="$t('global.amount_currency')"
          type="currency"
          inputModeText
          required
          v-test="'drawer-new-transaction-amount'"
        />
      </BaseGrid>
      <BaseGrid v-if="!cashupEnabled" :size="6">
        <BaseDatePicker
          v-model="date"
          :label="$t('global.date')"
          onlyPastDates
          v-test="'drawer-new-transaction-date'"
        />
      </BaseGrid>
    </BaseGrid>
    <template #footer>
      <BaseButton
        :loading="isLoading"
        submitForm
        v-test="'drawer-new-transaction-submit'"
        >{{ $t('global.actions.submit') }}</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useRegisterStore } from '@/modules/register/stores/register';

import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const { cashupEnabled } = useRegisterStore();
    return {
      cashupEnabled,
    };
  },
  data() {
    return {
      isLoading: false,
      description: '',
      amount: 0,
      date: dayjs().format(),
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId']),
  },
  methods: {
    submit() {
      this.isLoading = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createTransaction($input: CreateTransactionInput!) {
              createTransaction(input: $input) {
                transaction {
                  amount
                  description
                  id
                  orderId
                  orderNumber
                  transactionAt
                }
              }
            }
          `,
          variables: {
            input: {
              description: this.description,
              amount: this.amount,
              transactionAt: this.date,
              locationId: this.locationId,
            },
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'transactions' });
            cache.gc();
          },
        })
        .then(() => {
          this.$router.push({ name: 'drawer' });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>
