<template>
  <div :class="$style.base">
    <SendToSupplier
      v-if="showSendModal"
      :supplierEmail="supplierEmail"
      @close="showSendModal = false"
      @submit="sent = true"
    />
    <BaseButton
      v-if="isOrdered && !sent"
      color="inverted"
      mr
      @click="showSendModal = true"
      v-test="'sendToSupplier'"
    >
      {{ $t('products.send_to_supplier') }}
    </BaseButton>
    <BaseText
      v-else-if="isOrdered && sent"
      :mt="0.5"
      inline
      mr
      iconAfter="check"
      v-test="'sentToSupplier'"
    >
      {{ $t('products.sent_to_supplier') }}
    </BaseText>
    <BaseButton
      v-if="isProcessable"
      :disabled="hasDeletedItems"
      :routerLink="{ name: 'stock-process' }"
      v-test="'processButton'"
    >
      {{ $t('global.actions.process') }}
    </BaseButton>
    <BaseButton v-if="isComplete" @click="onSetPaid" v-test="'paidButton'">
      {{ $t('global.paid') }}
    </BaseButton>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import SendToSupplier from '../modals/SendToSupplier.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    SendToSupplier,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    supplierEmail: {
      type: String,
      required: false,
    },
    stockOrderId: {
      required: true,
    },
    hasDeletedItems: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sent: false,
      showSendModal: false,
    };
  },
  computed: {
    isComplete() {
      return this.status === 'complete';
    },
    isOrdered() {
      return this.status === 'ordered';
    },
    isProcessable() {
      return this.status === 'ordered' || this.status === 'backorder';
    },
  },
  methods: {
    onSetPaid() {
      const id = this.stockOrderId;
      this.$apollo.mutate({
        mutation: gql`
          mutation updateStockOrder($input: UpdateStockOrderInput!) {
            updateStockOrder(input: $input) {
              stockOrder {
                id
                status
              }
            }
          }
        `,
        variables: {
          input: {
            id,
            status: 'paid',
          },
        },
        update(cache) {
          const cacheId = `StockOrder:${id}`;
          cache.evict({ id: cacheId, broadcast: false });
          cache.gc();
        },
      });
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: flex-start;
}
</style>
