<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    v-test="'company-settings-vat-id'"
  >
    <BaseInputLabel :text="$t('company.vat_id.label')" />
    <BaseAlert
      v-if="noVatPreferencesSet"
      color="warning"
      :text="$t('company.vat_id.not_set')"
      :primaryAction="$t('company.vat_id.set_vat_preference')"
      mr
      @primaryAction="showVatIdModal = true"
      v-test="'company-settings-vat-id-not-set'"
    />
    <div v-else :class="[$style.row]">
      <BaseRadio
        v-if="company.billingInfo?.dontHaveVatNumber"
        :value="false"
        :label="$t('company.vat_id.vat_preference_label')"
        :options="[
          {
            label: $t('company.vat_id.salon_has_vat_id'),
            value: true
          },
          {
            label: $t('company.vat_id.salon_has_no_vat_id'),
            value: false
          }
        ]"
        disabled
        mb
        v-test="'company-settings-vat-id-radio-view'"
      />
      <BaseInput
        v-else-if="
          company.billingInfo?.stripeTaxId &&
          company.billingInfo.stripeTaxId.value
        "
        v-model="company.billingInfo.stripeTaxId.value"
        type="text"
        disabled
        :mr="0.5"
        v-test="'company-settings-vat-id-input'"
      />
      <BaseButton
        color="inverted"
        icon="edit"
        @click="showVatIdModal = true"
        v-test="'company-settings-vat-edit-button'"
      />
    </div>
    <BaseAlert
      v-if="
        company.billingInfo?.stripeTaxId?.verification &&
        company.billingInfo.stripeTaxId.verification.status === 'unverified'
      "
      color="error"
      :text="$t('company.vat_id.unverified_vat_id')"
      v-test="'company-settings-vat-id-not-valid'"
    />
    <VatIdModal v-if="showVatIdModal" @close="showVatIdModal = false" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import VatIdModal from '@/modules/admin/_shared/VatIdModal.vue';

const { company } = storeToRefs(useCompanyStore());
const showVatIdModal = ref(false);
const noVatPreferencesSet = computed(
  () =>
    !company.value.billingInfo?.dontHaveVatNumber &&
    !company.value.billingInfo?.stripeTaxId?.value,
);
</script>

<style module lang="scss">
.row {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;
}
</style>
