<template>
  <div
    :class="[
      $style.base,
      {
        [$style.zebra]: zebra,
        [$style.hasActions]: hasActions
      }
    ]"
  >
    <div :class="$style.tableWrap">
      <BaseScroll>
        <div :class="$style.table" v-test="'_base-table'">
          <RowComponent
            v-if="hasHeader"
            :cells="headers"
            :sortOrder="sortOrder"
            :selectable="selectable"
            isHeader
            @sort="$emit('sort', $event)"
            v-test="'_base-table-header'"
          />
          <RowComponent
            v-for="(row, index) in rows"
            :key="`row-${index}`"
            v-bind="row"
            :zebra="zebra"
            :disabled="row.disabled"
            :selectable="selectable"
            :selected="modelValue && row.id && modelValue.includes(row.id)"
            :breakLines="row.breakLines"
            @select="onRowSelect(row.id, $event)"
            @click="$emit('select', row.id)"
            @clickCell="$emit('clickCell', row.id)"
            @valueChange="(value: any) => $emit('valueChange', value)"
            v-test="'_base-table-row'"
          />
          <RowComponent
            v-if="footers.length"
            :cells="footers"
            isFooter
            v-test="'_base-table-footer'"
          />
        </div>
      </BaseScroll>
      <div v-if="hasActions" :class="$style.actions">
        <Actions
          :rows="rows"
          :zebra="zebra"
          :hasHeader="hasHeader"
          :hasFooter="hasFooter"
          @action="$emit('action', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { Row, Cell } from './types';
import RowComponent from './Row.vue';
import Actions from './Actions.vue';

export default defineComponent({
  name: 'BaseTable',
  components: {
    Actions,
    RowComponent,
  },
  inject: ['mixpanel'],
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array as PropType<number[] | string[]>,
    },
    rows: {
      type: Array as PropType<Row[]>,
      default: () => [],
    },
    headers: {
      type: Array as PropType<Cell[] | string[]>,
      default: () => [],
    },
    footers: {
      type: Array as PropType<Cell[] | string[]>,
      default: () => [],
    },
    disableZebra: {
      type: Boolean,
      default: false,
    },
    sortOrder: Object,
  },
  emits: [
    'select',
    'action',
    'valueChange',
    'clickCell',
    'sort',
    'update:modelValue',
  ],
  computed: {
    hasActions() {
      return !!this.rows.find((row) => !!row.actions);
    },
    hasHeader() {
      return !!this.headers.length;
    },
    hasFooter() {
      return !!this.footers.length;
    },
    zebra() {
      return !this.disableZebra;
    },
    selectable() {
      return !!this.modelValue;
    },
  },
  methods: {
    onRowSelect(id, value) {
      let newValue = this.modelValue ? [...this.modelValue] : [];
      if (value) {
        newValue.push(id);
        this.mixpanel.track('table_row_selected', {
          resulting_count: newValue.length,
        });
      } else {
        newValue = newValue.filter((row) => row !== id);
        this.mixpanel.track('table_row_deselected', {
          resulting_count: newValue.length,
        });
      }
      this.$emit('update:modelValue', newValue);
    },
  },
});
</script>

<style lang="scss" module>
$actionsWidth: 52px;

.base {
  position: relative;
}

.tableWrap {
  width: 100%;
}

.table {
  display: table;
  width: 100%;

  .base.hasActions & {
    padding-right: $actionsWidth;
  }
}

.actions {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: $actionsWidth;
  z-index: 1;
}
</style>
