<template>
  <div
    :class="[
      $style.base,
      {
        [$style.hasError]: hasError
      }
    ]"
  >
    <InputElement
      v-model="value"
      v-bind="{ type: inputType, uid, hasError, focus, size, disabled }"
    />
    <div
      :class="$style.btnToggle"
      @click="passwordVisible = !passwordVisible"
      v-test="'_base-input-password-toggle'"
    >
      <BaseIcon :name="passwordVisible ? 'eye-off' : 'eye'" />
    </div>
  </div>
</template>

<script lang="ts">
import InputElement from './_shared/InputElement.vue';
import props from './_shared/props';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    InputElement,
  },
  inheritAttrs: false,
  props: {
    modelValue: String,
    type: String,
    ...props(),
  },
  emits: ['update:modelValue'],
  data() {
    return {
      passwordVisible: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    inputType() {
      return this.passwordVisible ? 'text' : 'password';
    },
  },
});
</script>

<style lang="scss" module>
.base {
  position: relative;
}

.btnToggle {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 $spacing;
  cursor: pointer;

  .base.hasError & {
    right: 20px;
  }
}
</style>
