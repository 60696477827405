import { devWarning } from '@/helpers/dev';
import emitter from 'tiny-emitter/instance';

export default {
  $on: (...args) => {
    if (args[0] === 'submit-modal') {
      devWarning(
        'Please use "$once" instead of "$on" for the submit-modal event.',
      );
    } else {
      return emitter.on(...args);
    }
  },
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};
