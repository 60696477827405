<template>
  <ModuleBase noBorder gray>
    <PageWrap :heading="$t('admin.nav.links.loyalty_points')">
      <BaseText mb>
        {{ $t('loyalty_points.description') }}
      </BaseText>
      <BaseText mb>
        {{ $t('loyalty_points.description_2') }}
      </BaseText>
      <div class="mb-2">
        <BaseText :href="$t('loyalty_points.more_info.url')">
          {{ $t('loyalty_points.more_info.text') }}
        </BaseText>
      </div>
      <BaseCard>
        <BaseText bold mb>
          {{ $t('loyalty_points.types_heading') }}
        </BaseText>
        <div :class="$style.list">
          <Type
            v-for="(type, index) in types"
            :key="index"
            v-model:selectedType="formData.loyaltyPointsType"
            v-model:selectedAmount="formData.loyaltyPointsAmount"
            :type="type"
          />
        </div>
      </BaseCard>
      <template #footer>
        <BaseButton
          :loading="isSaving"
          @click="submit"
          v-test="'lp-btn-submit'"
        >
          {{ $t('global.actions.save') }}
        </BaseButton>
      </template>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import { defineComponent, ref, reactive } from 'vue';
import ModuleBase from '@/modules/ModuleBase.vue';
import Type from './LoyaltyPointsType.vue';
import { useCompanyStore } from '@/stores/company';
import type { LoyaltyPointsType } from '@/types';
import { useLeaveConfirmation } from '@/helpers/routing';
import i18n from '@/i18n';
import PageWrap from '@/modules/admin/PageWrap.vue';

export default defineComponent({
  name: 'LoyaltyPoints',
  components: {
    ModuleBase,
    Type,
    PageWrap,
  },
  setup() {
    const { companySettings } = useCompanyStore();

    const formData = reactive({
      loyaltyPointsType: companySettings.sales.loyaltyPointsType,
      loyaltyPointsAmount: companySettings.sales.loyaltyPointsAmount,
    });

    const { resetConfirmation } = useLeaveConfirmation(formData);

    const isSaving = ref(false);

    const submit = () => {
      const { updateCompany } = useCompanyStore();

      isSaving.value = true;

      updateCompany({
        settings: {
          sales: formData,
        },
      }).then(() => {
        isSaving.value = false;
        resetConfirmation();
        flash(i18n.t('global.flash.settings_updated'));
      });
    };

    return {
      isSaving,
      formData,
      submit,
      types: [
        'DISABLED',
        'SPENT_AMOUNT',
        'SPENT_AMOUNT_PRODUCTS',
        'SPENT_AMOUNT_SERVICES',
        'PER_SERVICE',
        'PER_VISIT',
        'PER_PRODUCT',
      ] as LoyaltyPointsType[],
    };
  },
});
</script>

<style lang="scss" module>
.list {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}
</style>
