import calendarInterface from '@/modules/calendar/calendar-interface';
import { formatEvent } from '@/modules/calendar/helpers';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useLocationsStore } from '@/stores/locations';
import { useResourcesStore } from '@/stores/resources';
import type { EventImpl } from '@fullcalendar/core/internal';

export const createCalendarEvent = (event: any, source = 'default') => {
  const { resourceId, resourceType } = useCalendarFiltersStore();

  const formattedEvent = formatEvent(event);

  if (event.extendedProps.type === 'waiting_list') {
    const calendarEvent = calendarInterface.api?.getEventById(event.id);
    if (calendarEvent) {
      calendarEvent.remove();
    }
    calendarInterface.api?.addEvent(formattedEvent);
  } else {
    if (
      !calendarInterface.api ||
      calendarInterface.api.getEventById(event.id)
    ) {
      return;
    }
    const { resources } = useResourcesStore();
    const eventResources = resources.filter(
      (r) =>
        formattedEvent.resourceId === r.id ||
        formattedEvent.resourceIds?.includes(r.id),
    );

    const { locationId } = useLocationsStore();

    if (
      eventResources.find(
        (r) => r.type === resourceType && (!resourceId || resourceId === r.id),
      ) &&
      (!formattedEvent.extendedProps.locationId ||
        formattedEvent.extendedProps.locationId === locationId)
    ) {
      calendarInterface.api?.addEvent(formattedEvent, source);
    }
  }
};

const cleanupEvent = (event: EventImpl) => {
  if (!calendarInterface.api) {
    return;
  }

  const events = calendarInterface.api.getEvents();
  events.forEach((ev) => {
    if (ev.extendedProps.entryId === event.extendedProps.entryId) {
      ev.remove();
    }
  });
};

export const updateCalendarEvents = (items: EventImpl[]) => {
  cleanupEvent(items[0]);

  items.forEach((item) => {
    updateCalendarEvent(item);
  });
};

export const updateCalendarEvent = (newItem: EventImpl, source = 'default') => {
  if (!calendarInterface.api) {
    return;
  }

  setTimeout(() => {
    const event = calendarInterface.api?.getEventById(newItem.id);

    if (event) {
      event.remove();
    }

    createCalendarEvent(newItem, source);
  }, 50);
};

export const deleteCalendarEvent = (id: string) => {
  if (!calendarInterface.api) {
    return;
  }

  const event = calendarInterface.api.getEventById(id);
  if (event) {
    event.remove();
  }
};

export const removeDeletedAppointmentEvents = (events: any[]) => {
  if (!calendarInterface.api || !events.length) {
    return;
  }

  const eventIds = events.map((event) => event.id);
  const deletedEvents = calendarInterface.api
    .getEvents()
    .filter(
      (event: any) =>
        event.extendedProps.entryId === events[0].extendedProps.entryId &&
        !eventIds.includes(event.id),
    );
  deletedEvents.forEach((event: any) => event.remove());
};
