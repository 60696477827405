<template>
  <div
    :class="[
      $style.base,
      {
        [$style.empty]: isEmpty,
        [$style.smallScreen]: $screen === 's' || $screen === 'm'
      }
    ]"
  >
    <div :class="$style.label">
      <component
        :is="resource ? 'router-link' : 'div'"
        :class="resource ? $style.resourceLink : $style.location"
        :to="
          props.resource
            ? {
                name:
                  props.resource.type === 'employee'
                    ? 'admin-roster-employee'
                    : 'admin-roster-resource',
                params: {
                  id: props.resource.id
                }
              }
            : undefined
        "
      >
        <BaseAvatar v-if="resource" :resource="resource" />
        <BaseText
          oneLine
          v-test="props.resource ? 'roster-resource' : undefined"
        >
          {{ props.location ? props.location.internalName : resource.name }}
        </BaseText>
      </component>

      <div :class="$style.icon">
        <BaseIcon
          v-if="isEmpty"
          name="home"
          size="s"
          :tooltip="{
            text:
              $t('roster.same_hours') +
              (multiLocation ? ` (${location.internalName})` : ''),
            touch: true
          }"
        />
        <Flex v-else align="center" :gap="0">
          <BaseText size="s" v-test="'roster-totalhours'">
            {{ totalHours }}
          </BaseText>
          <BaseIcon
            name="clock"
            size="s"
            :tooltip="{
              text: $t('roster.scheduled_hours'),
              touch: true
            }"
            :ml="0.25"
          />
        </Flex>
      </div>
    </div>
    <div :class="$style.cells">
      <Cell v-for="(entry, index) in entries" :key="index" :entry="entry" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Entry } from './types';
import type { Ref } from 'vue';
import Cell from './Cell.vue';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';

const props = defineProps<{
  location?: any;
  resource?: any;
}>();

const allEntries = inject<Ref<Entry[]>>('entries');
const entries = computed(() =>
  allEntries?.value.filter((entry) =>
    props.location
      ? entry.type === 'location' && entry.location_id === props.location.id
      : entry.resource_id === props.resource.id,
  ),
);

const { location } = useLocationsStore();
const { multiLocation } = useCompanyStore();

const isEmpty = computed(() => !entries.value?.length);
const totalHours = computed(
  () =>
    Math.round(entries.value?.reduce((acc, val) => acc + val.hours, 0) * 100) /
    100,
);
</script>

<style lang="scss" module>
.base {
  &:not(.smallScreen) {
    display: flex;
    height: 50px;
  }

  &.smallScreen {
    &:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}

.label,
.cells {
  height: 100%;
}

.label {
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
  flex-shrink: 0;
  padding-right: $spacing * 0.5;

  .base:not(.smallScreen) & {
    width: 180px;
  }

  .base.smallScreen & {
    margin-bottom: $spacing * 0.5;
  }
}

.icon {
  .base:not(.smallScreen) & {
    margin-left: auto;
  }
}

.cells {
  display: flex;
  gap: 4px;
  width: 100%;

  .base.smallScreen & {
    height: 65px;
  }

  .base:not(.smallScreen) & {
    height: 50px;
  }

  .base.empty & {
    display: none;
  }
}

.resourceLink,
.location {
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: $radius;
  padding: $spacing * 0.5;
  width: 100%;
  margin-left: $spacing * -0.5;
}

.resourceLink {
  @include hover {
    background-color: $color-highlight;
  }
}
</style>
