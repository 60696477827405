<template>
  <BaseModal
    :heading="header"
    small
    testId="sp-transaction"
    :unclosable="status !== 'success' && status !== 'failed'"
    @close="$emit('close')"
  >
    <div :class="$style.base">
      <PaymentTransition>
        <div v-if="status === 'default'" :class="$style.paymentStatus">
          <div v-test="'pos-terminal-loading'">
            <div :class="$style.image">
              <img src="/img/salonized-pay/checkout/awaiting-payment.svg" />
            </div>
          </div>
          <BaseHeading size="s" center mt :mb="0.5">
            {{ $t('register.pos.amount') }}
          </BaseHeading>
          <BaseHeading size="xl" center mb>
            {{ filters.currency(amount) }}
          </BaseHeading>
          <BaseText center v-test="'sp-transaction-loading-terminal'">
            {{ $t('register.pos.loading') }}
          </BaseText>
          <BaseSpinner inline mt />
        </div>
      </PaymentTransition>
      <PaymentTransition>
        <div
          v-if="status === 'pending' || status === 'retry'"
          :class="$style.paymentStatus"
        >
          <img src="/img/salonized-pay/checkout/awaiting-transaction.svg" />
          <BaseHeading size="s" center mt :mb="0.5">
            {{ $t('register.pos.amount') }}
          </BaseHeading>
          <BaseHeading size="xl" center mb>
            {{ filters.currency(amount) }}
          </BaseHeading>
          <BaseText mt v-test="'sp-transaction-awaiting'">
            {{ $t('register.pos_awaiting_transaction.description') }}
          </BaseText>
          <BaseSpinner inline mt />
        </div>
      </PaymentTransition>
      <PaymentTransition>
        <div v-if="status === 'success'" :class="$style.paymentStatus">
          <Confetti />
          <img src="/img/salonized-pay/checkout/successful-payment.svg" />
          <BaseText mt size="l" bold v-test="'sp-transaction-success'">
            {{ $t('register.pos_successful_transaction.description') }}
          </BaseText>
        </div>
      </PaymentTransition>
      <PaymentTransition>
        <div v-if="status === 'failed'" :class="$style.paymentStatus">
          <template v-if="refusalReason === 'CONVERTER_ERROR_REQUEST'">
            <img
              src="/img/salonized-pay/checkout/failed-payment-connection-lost.svg"
            />
            <BaseText mt v-test="'sp-transaction-failed-connection-lost'">
              {{
                $t(
                  'register.pos_failed_transaction.description_connection_lost'
                )
              }}
            </BaseText>
          </template>
          <template v-else-if="refusalReason === 'NOT_ENOUGH_BALANCE'">
            <img
              src="/img/salonized-pay/checkout/failed-payment-card-declined.svg"
            />
            <BaseText mt v-test="'sp-transaction-failed-card-declined'">
              {{
                $t('register.pos_failed_transaction.description_card_declined')
              }}
            </BaseText>
          </template>
          <template v-else-if="refusalReason === 'DECLINED'">
            <img
              src="/img/salonized-pay/checkout/failed-payment-cancelled.svg"
            />
            <BaseText mt v-test="'sp-transaction-failed-cancelled'">
              {{ $t('register.pos_failed_transaction.description_cancelled') }}
            </BaseText>
          </template>
          <template v-else-if="refusalReason === 'CARD_EXPIRED'">
            <img
              src="/img/salonized-pay/checkout/failed-payment-card-declined.svg"
            />
            <BaseText mt v-test="'sp-transaction-failed-card-expired'">
              {{
                $t('register.pos_failed_transaction.description_card_expired')
              }}
            </BaseText>
          </template>
          <template v-else>
            <img src="/img/salonized-pay/checkout/failed-payment.svg" />
            <BaseText mt v-test="'sp-transaction-failed'">
              {{ $t('register.pos_failed_transaction.description') }}
            </BaseText>
          </template>
        </div>
      </PaymentTransition>
    </div>
    <template v-if="status === 'failed'" #footer>
      <PaymentTransition>
        <div>
          <BaseButton color="inverted" mr @click="$emit('close')">
            {{ $t('register.salonized_pay.payment.failed.other') }}
          </BaseButton>
          <BaseButton @click="$emit('retry')" v-test="'sp-transaction-retry'">
            {{ $t('register.pos_failed_transaction.try_again') }}
          </BaseButton>
        </div>
      </PaymentTransition>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import Confetti from '@/components/Confetti.vue';
import filters from '@/filters';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import PaymentTransition from './PaymentTransition.vue';
import type { PosPaymentRefusalReason } from '@/types';

const props = defineProps<{
  amount: number;
  status: string;
  refusalReason: PosPaymentRefusalReason | null;
}>();

const { t } = useI18n();
defineEmits(['close', 'retry']);

const header = computed(() => {
  switch (props.status) {
    case 'pending':
    case 'retry':
      return t('register.salonized_pay.pos.headers.complete');
    case 'success':
      return t('register.salonized_pay.pos.headers.success');
    case 'failed':
      switch (props.refusalReason) {
        case 'CONVERTER_ERROR_REQUEST':
          return t('register.salonized_pay.pos.headers.failed_connection_lost');
        case 'NOT_ENOUGH_BALANCE':
        case 'CARD_EXPIRED':
          return t('register.salonized_pay.pos.headers.failed_card_declined');
        case 'DECLINED':
          return t('register.salonized_pay.pos.headers.failed_cancelled');
        default:
          return t('register.salonized_pay.pos.headers.failed');
      }
    default:
      return t('register.salonized_pay.pos.headers.waiting');
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  height: 350px;
}

.image {
  display: flex;
  justify-content: center;
}

.paymentStatus {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: $spacing;
  text-align: center;

  img {
    max-height: 70%;
    max-width: 50%;
  }
}
</style>
