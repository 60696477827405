<template>
  <div
    :class="[
      $style.base,
      $style[color],
      {
        [$style.inline]: inline
      }
    ]"
  >
    <svg :class="$style.svg" viewBox="0 0 50 50">
      <circle
        :class="$style.circle"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      />
    </svg>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
withDefaults(
  defineProps<{
    color?: 'default' | 'inverted';
    inline?: boolean;
  }>(),
  {
    color: 'default'
  }
);
</script>

<style lang="scss" module>
.base {
  height: 20px;

  &:not(.inline) {
    position: absolute;
    width: 20px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }

  &.inline {
    display: flex;
    justify-content: center;
  }
}

.svg {
  animation: rotate 2s linear infinite;
  width: 20px;
  height: 20px;
}

.circle {
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out -1s infinite;

  .base.default & {
    stroke: #999;
  }

  .base.inverted & {
    stroke: #fff;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
