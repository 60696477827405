<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <BaseHeading mb>
      {{
        $t('global.actions.new_item', { item: $t('global.items.giftcard', 1) })
      }}
    </BaseHeading>
    <BaseAlert
      :text="$t('giftcard.delete_warning')"
      mb
      v-test="'create-giftcard-warning'"
    />
    <BaseForm @submit="submit">
      <div :class="[$style.row, $style.topRow]">
        <div :class="$style.codeInput">
          <BaseInput
            v-model="code"
            mr
            :label="$t('global.number')"
            required
            :error="
              isCodeTaken
                ? $t('global.validations.in_use', {
                    value: $t('global.number')
                  })
                : null
            "
            v-test="'create-giftcard-code'"
          />
        </div>
        <div :class="$style.generateButton">
          <BaseButton
            color="inverted"
            @click="generateCode"
            v-test="'create-giftcard-generate'"
          >
            {{ $t('global.actions.generate') }}
          </BaseButton>
        </div>
      </div>
      <div :class="[$style.row, $style.bottomRow]">
        <BaseInput
          v-model="price"
          mr
          :label="$t('global.amount_currency')"
          type="currency"
          required
          :minValue="1"
          :maxValue="10000000"
          v-test="'create-giftcard-price'"
        />
        <div>
          <BaseDatePicker
            v-show="hasExpirationDate"
            v-model="expirationDate"
            onlyFutureDates
            alignRight
            :label="$t('giftcard.expiration_date')"
            v-test="'create-giftcard-date'"
          />
          <BaseCheckbox
            v-model="hasExpirationDate"
            :mt="0.5"
            :label="$t('giftcard.has_expiration_date')"
            v-test="'create-giftcard-checkbox'"
          />
        </div>
      </div>
      <Flex justify="end" mt>
        <BaseButton
          submitForm
          :loading="fetchingGiftcard"
          v-test="'create-giftcard-save'"
        >
          {{ $t('global.actions.add') }}
        </BaseButton>
      </Flex>
    </BaseForm>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import { useCompanyStore } from '@/stores/company';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import filters from '@/filters';
import dayjs from '@/dayjs';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import gql from 'graphql-tag';
import useVuelidate from '@vuelidate/core';

const { addItem } = useRegisterOrderStore();
const { company } = useCompanyStore();

const price = ref(0);
const code = ref('');

const expirationDate = ref();
switch (company.locale.countryCode) {
  case 'nl':
    expirationDate.value = dayjs().add(2, 'year').format();
    break;
  case 'de':
    expirationDate.value = dayjs().add(3, 'year').format();
    break;
  default:
    expirationDate.value = dayjs().add(1, 'year').format();
}

const hasExpirationDate = ref(true);

const trimmedCode = computed(() => code.value.replace(/\W/g, ''));
const { result, loading: fetchingGiftcard } = useQuery(
  gql`
    query getGiftcard($code: String) {
      giftcard(code: $code) {
        code
      }
    }
  `,
  reactive({ code: trimmedCode }),
  { throttle: 300 },
);
const isCodeTaken = computed(() => !!result.value?.giftcard);
const { t } = useI18n();

const generateCode = (): void => {
  const randomNumber = Math.floor(
    Math.random() * (99999999 - 10000000 + 1) + 10000000,
  ).toString();
  code.value = filters.giftCardCode(randomNumber);
};

const v$ = useVuelidate();

const submit = () => {
  if (isCodeTaken.value) {
    return;
  }

  addItem({
    giftcard: {
      code: code.value,
      totalAmount: price.value,
      expiresAt: hasExpirationDate.value ? expirationDate.value : null,
    },
    price: price.value,
    name: `${t('global.payments.giftcard')}: ${code.value}`,
  });

  price.value = 0;
  code.value = '';
  v$.value.$reset();
};
</script>

<style lang="scss" module>
.row {
  display: flex;
}

.topRow {
  margin-bottom: $spacing;
}

.codeInput {
  flex-grow: 1;
}

.generateButton {
  margin-top: 24px;
}

.bottomRow {
  .base.smallScreen & {
    flex-direction: column;
    align-items: flex-start;
    & > * {
      width: 100%;

      &:not(:last-child) {
        width: 100%;
        margin-bottom: $spacing;
      }
    }
  }
}
</style>
