<template>
  <div :class="$style.base">
    <div
      :class="$style.close"
      @click="$emit('close')"
      v-test="'close-app-banner'"
    >
      <BaseIcon name="close" />
    </div>
    <div :class="$style.inner">
      <svg
        :class="$style.svg"
        width="52"
        height="89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 5.646A5.646 5.646 0 0 1 5.646 0h40.708A5.646 5.646 0 0 1 52 5.646V83.2a5.646 5.646 0 0 1-5.646 5.646H5.646A5.646 5.646 0 0 1 0 83.2V5.646Z"
          fill="#4F4F4F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M46.354.891H5.646A4.754 4.754 0 0 0 .89 5.646V83.2a4.754 4.754 0 0 0 4.755 4.754h40.708A4.754 4.754 0 0 0 51.11 83.2V5.646A4.754 4.754 0 0 0 46.354.89ZM5.646 0A5.646 5.646 0 0 0 0 5.646V83.2a5.646 5.646 0 0 0 5.646 5.646h40.708A5.646 5.646 0 0 0 52 83.2V5.646A5.646 5.646 0 0 0 46.354 0H5.646Z"
          fill="#493943"
        />
        <path
          d="M3.269 6.24A2.971 2.971 0 0 1 6.24 3.269h39.52a2.971 2.971 0 0 1 2.971 2.971v51.703H3.27V6.24Z"
          fill="#D5E3EA"
        />
        <path
          d="M3.269 82.606a2.971 2.971 0 0 0 2.971 2.971h39.52a2.971 2.971 0 0 0 2.971-2.971V57.943H3.27v24.663Z"
          fill="#D5E3EA"
        />
        <path
          d="M14.263 2.674c0-.82.665-1.485 1.486-1.485h20.8c.82 0 1.485.665 1.485 1.485V6.24c0 .82-.665 1.486-1.486 1.486h-20.8c-.82 0-1.485-.665-1.485-1.486V2.674Z"
          fill="#4F4F4F"
        />
        <rect
          x="16.937"
          y="3.566"
          width="15.154"
          height="2.08"
          rx="1.04"
          fill="#493943"
        />
        <circle cx="34.32" cy="4.606" r="1.04" fill="#493943" />
        <path
          d="M8.023 33.28a5.943 5.943 0 0 1 5.943-5.943h24.068a5.943 5.943 0 0 1 5.943 5.943v24.069a5.943 5.943 0 0 1-5.943 5.942H13.966a5.943 5.943 0 0 1-5.943-5.942V33.28Z"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.301 40.113c1.14.01 2.265.257 3.303.726a8.125 8.125 0 0 1 2.719 1.998 8.837 8.837 0 0 1 1.709 3.023c.363 1.11.502 2.28.408 3.444a8.339 8.339 0 0 1-.92 3.253 8.395 8.395 0 0 1-2.138 2.628 9.122 9.122 0 0 1-6.402 1.825 13.872 13.872 0 0 1-6.75-2.428l-.28-.201a.69.69 0 0 1-.2-.893c.488-.864.938-1.69 1.231-2.234a.666.666 0 0 1 .938-.252l.122.074c1.12.705 2.324 1.27 3.583 1.685 1.787.529 3.97.369 5.393-.962a4.75 4.75 0 0 0 1.182-4.634 3.936 3.936 0 0 0-1.239-1.976 5.04 5.04 0 0 0-2.347-1.043 6.898 6.898 0 0 0-.722-.093 2.053 2.053 0 0 1-.993-.286 1.993 1.993 0 0 1-.787-1 1.945 1.945 0 0 1 .02-1.293 1.96 1.96 0 0 1 .826-1c.4-.255.87-.382 1.344-.361Zm-.6 10.402a8.187 8.187 0 0 1-3.303-.727 8.136 8.136 0 0 1-2.72-1.997 8.834 8.834 0 0 1-1.709-3.022 8.796 8.796 0 0 1-.41-3.442 8.344 8.344 0 0 1 .92-3.255 8.397 8.397 0 0 1 2.14-2.63 9.106 9.106 0 0 1 6.4-1.823 13.845 13.845 0 0 1 6.75 2.43c.099.062.194.129.286.2a.677.677 0 0 1 .19.883c-.524.924-1.004 1.81-1.293 2.347a.546.546 0 0 1-.777.208l-.328-.201a16.033 16.033 0 0 0-3.478-1.626c-1.789-.527-3.97-.367-5.393.964a4.755 4.755 0 0 0-1.185 4.634c.209.77.648 1.459 1.26 1.976a5.038 5.038 0 0 0 2.346 1.046c.238.042.48.073.722.093.35.009.692.107.994.285a1.953 1.953 0 0 1 .876 1.666 1.941 1.941 0 0 1-.948 1.627 2.297 2.297 0 0 1-1.34.364"
          fill="#FF6575"
        />
      </svg>
      <div class="ml-1">
        <BaseHeading>
          {{ $t('banner.download_app') }}
        </BaseHeading>
        <BaseText>
          {{ $t('banner.download_app_info') }}
          <div :class="$style.icon">
            <BaseIcon name="pwa" color="primary" />
          </div>
          {{ $t('banner.download_app_info_continuation') }}
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  inject: ['intercom'],
  emits: ['close'],
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing * 0.5;
  position: absolute;
  right: 0;
  width: 100%;
  bottom: 0;
}

.inner {
  display: flex;
  align-items: center;
  padding: $spacing;
  background: #dbf6ff;
  border-radius: $radius;
  box-shadow: $shadow;
}

.icon {
  display: inline-block;
  transform: translate(1px, 5px);
}

.svg {
  min-width: 55px;
}

.close {
  position: absolute;
  right: $spacing * 0.5;
  top: $spacing * 0.5;
  padding: $spacing * 0.5;
  z-index: 1;
  width: 20px + $spacing;
  height: 20px + $spacing;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
