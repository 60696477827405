<template>
  <div>
    <BaseSpinner v-if="loading" inline />
    <div v-else :class="$style.tags">
      <BaseCheckbox
        v-for="(tag, index) in tags"
        :key="index"
        :modelValue="selectedTagIds.includes(tag.id)"
        :label="tag.name"
        @update:modelValue="
          selectedTagIds.includes(tag.id)
            ? (selectedTagIds = selectedTagIds.filter((id) => id !== tag.id))
            : (selectedTagIds = [...selectedTagIds, tag.id])
        "
        v-test="'filter-tag'"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useQuery } from '@vue/apollo-composable';
import { GET_CUSTOMER_TAGS } from '@/modules/customers/graphql';

const emit = defineEmits(['selected']);

const { result, loading } = useQuery(GET_CUSTOMER_TAGS, {
  fetchPolicy: 'cache-first',
});

const selectedTagIds = ref<number[]>([]);
const tags = computed(() =>
  result.value?.customerTags
    ? [
        ...result.value.customerTags.salonizedTags,
        ...result.value.customerTags.companyTags,
      ]
    : [],
);

watch(
  selectedTagIds,
  (values) => {
    emit('selected', {
      filter_name: 'tags',
      values,
    });
  },
  {
    immediate: true,
  },
);
</script>

<style lang="scss" module>
.tags {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}
</style>
