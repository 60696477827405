<template>
  <BaseCard
    :heading="$t('dashboard.occupation.heading')"
    :maxContentHeight="224"
  >
    <div v-if="data.length" :class="$style.inner">
      <OccupationItem
        v-for="(resource, index) in data"
        :key="index"
        :data="resource"
      />
    </div>
    <div v-else>
      {{ $t('dashboard.occupation.empty') }}
    </div>
  </BaseCard>
</template>

<script lang="ts">
import OccupationItem from './OccupationItem.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    OccupationItem,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style lang="scss" module>
.inner {
  margin: $spacing * -0.5;
}
</style>
