<template>
  <div v-if="typeof localValue === 'number'" :class="$style.base">
    <BaseHeading size="l" :mb="2">
      {{ $t('admin.assistant.walkin.heading') }}
    </BaseHeading>
    <div :class="$style.form">
      <BaseDropdown
        v-model="localValue"
        :label="$t('admin.booking.lead_time.label')"
        :options="leadTimeOptions"
        v-test="'booking-settings-wizard-leadTime'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBookingSettingsOptions } from '@/modules/admin/online-bookings/input-options';
const { leadTimeOptions } = useBookingSettingsOptions();

const emit = defineEmits(['update:modelValue']);

const props = defineProps<{
  modelValue?: number | null;
}>();

const localValue = ref(props.modelValue);

watch(
  localValue,
  () => {
    emit('update:modelValue', localValue.value);
  },
  {
    deep: true,
  },
);
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form {
  flex-grow: 1;
}
</style>
