<template>
  <div>
    <BaseButton
      size="l"
      :fullWidth="$screen === 's'"
      :loading="loading"
      @click="onStartClick"
      v-test="'btn-treatwell-start'"
      v-intercom="'treatwell-landing-start'"
    >
      {{ $t('treatwell.landing.button_start') }}
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import { useTreatwellStore } from '@/stores/treatwell';
import { useMutation } from '@vue/apollo-composable';
import { inject } from 'vue';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';
import { useStorage } from '@vueuse/core';

const mixpanel = inject<any>('mixpanel');
const router = useRouter();

const { treatwellStatus } = useTreatwellStore();

const { mutate, loading } = useMutation(
  gql`
    mutation treatwellIntegrationRequestCreate(
      $input: IntegrationRequestCreateInput!
    ) {
      treatwellIntegrationRequestCreate(input: $input) {
        treatwellIntegrationRequest {
          id
        }
      }
    }
  `,
  {
    variables: {
      input: {},
    },
  },
);

const onStartClick = () => {
  mixpanel.track('TWLandingPage-LinkYourAccount');
  const tw_linked_account_at = useStorage('tw_linked_account_at', 0);
  tw_linked_account_at.value = Date.now();
  if (!treatwellStatus.hasRequest) {
    mutate().then(() => {
      treatwellStatus.hasRequest = true;
      router.push({ name: 'treatwell-admin' });
    });
  } else {
    router.push({ name: 'treatwell-admin' });
  }
};
</script>
