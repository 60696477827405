<template>
  <BaseModal
    :heading="
      $route.params.id
        ? $t('global.actions.edit_product')
        : $t('global.actions.create_product')
    "
    :parentRoute="parentRouteName"
    @close="$emit('close')"
  >
    <BaseForm @submit="submit">
      <BaseGrid container>
        <BaseGrid :size="6">
          <BaseInput
            v-model="name"
            :label="$t('global.name')"
            required
            focus
            v-test="'createProductName'"
          />
        </BaseGrid>
        <BaseGrid :size="3">
          <BaseInput
            v-model="price"
            :label="$t('global.sales_price')"
            type="currency"
            v-test="'createProductPrice'"
          />
        </BaseGrid>
        <BaseGrid :size="3">
          <BaseInput
            v-model="costPrice"
            :label="$t('global.cost_price')"
            type="currency"
            v-test="'createProductCostPrice'"
          />
        </BaseGrid>
        <BaseGrid :size="6">
          <BaseInput
            v-model="reference"
            :label="$t('global.ean_code')"
            :info="$t('products.ean_code_info')"
            v-test="'createProductEAN'"
          />
        </BaseGrid>
        <BaseGrid v-if="vatRates && vatRates.length" :size="3">
          <BaseDropdown
            v-model="vatRate"
            :label="$t('global.vat')"
            :options="vatData"
            v-test="'create-product-vat'"
          />
        </BaseGrid>
        <BaseGrid :size="3">
          <BaseInput
            v-model="partNumber"
            :label="$t('global.part_number')"
            v-test="'createProductPartNumber'"
          />
        </BaseGrid>
        <BaseGrid
          v-if="productCategories && productCategories.length"
          :size="6"
        >
          <BaseDropdown
            v-model="category"
            :label="$t('global.category')"
            :options="
              productCategories.map((c) => ({
                value: c.id,
                label: c.name
              }))
            "
            v-test="'create-product-category'"
          />
        </BaseGrid>
        <BaseGrid v-if="suppliers && suppliers.length" :size="6">
          <BaseDropdown
            v-model="supplier"
            :label="$t('global.supplier')"
            :options="
              suppliers.map((c) => ({
                value: c.id,
                label: c.name
              }))
            "
            v-test="'create-product-supplier'"
          />
        </BaseGrid>
        <BaseGrid :size="12">
          <BaseCheckbox
            v-if="company.medical"
            v-model="medical"
            :label="$t('global.medical')"
            v-test="'enableMedical'"
          />
        </BaseGrid>
        <BaseGrid :size="12">
          <BaseCheckbox
            v-model="stockEnabled"
            :label="$t('products.manage_stock_order')"
            :mb="0.5"
            v-test="'enableStockOrder'"
          />
          <BaseText v-if="stockEnabled" color="secondary">
            {{ $t('products.manage_stock_order_info') }}
          </BaseText>
        </BaseGrid>
        <BaseGrid v-if="stockEnabled" :size="4">
          <BaseInput
            v-model="stockMinimum"
            :label="$t('products.minimum_stock')"
            type="number"
            v-test="'createProductMinStock'"
          />
        </BaseGrid>
        <BaseGrid v-if="stockEnabled" :size="4">
          <BaseInput
            v-model="stockMaximum"
            :label="$t('products.maximum_stock')"
            :minValue="stockEnabled ? stockMinimum : 0"
            type="number"
            v-test="'createProductMaxStock'"
          />
        </BaseGrid>
        <BaseGrid v-if="stockEnabled" :size="4">
          <BaseInput
            v-model="stockAmount"
            :label="$t('products.current_stock')"
            type="number"
            v-test="'createProductCurrentStock'"
          />
        </BaseGrid>
      </BaseGrid>
    </BaseForm>
    <template #footer>
      <BaseButton
        :loading="isLoading"
        @click="submit"
        v-test="'createProductSave'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import { productFragment } from '../graphql';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import useVuelidate from '@vuelidate/core';
import { useVatRatesStore } from '@/stores/vat-rates';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    parentRouteName: String,
  },
  emits: ['close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
      price: null,
      costPrice: null,
      reference: '',
      partNumber: '',
      supplier: '',
      vatRate: 'default',
      category: '',
      stockMinimum: 0,
      stockMaximum: 0,
      stockAmount: 0,
      isLoading: false,
      stockEnabled: false,
      medical: false,
    };
  },
  apollo: {
    product: {
      query: gql`
        query getProduct($locationId: ID, $id: Int!) {
          product(locationId: $locationId, id: $id) {
            ...fullProduct
          }
        }
        ${productFragment}
      `,
      skip() {
        return !this.$route.params.id;
      },
      variables() {
        return {
          id: Number.parseInt(this.$route.params.id),
          locationId: this.locationId,
        };
      },
      fetchPolicy: 'cache-and-network',
      result() {
        this.name = this.product.name;
        this.price = this.product.price;
        this.costPrice = this.product.costPrice;
        this.reference = this.product.reference;
        this.partNumber = this.product.partNumber;
        this.supplier = this.product.supplier?.id;
        this.stockEnabled = this.product.stockEnabled;
        this.category = this.product.category?.id;

        if (this.company.medical) {
          this.medical = this.product.medical;
        }

        this.stockMinimum = this.product.stockMinimum;
        this.stockMaximum = this.product.stockMaximum;
        this.stockAmount = this.product.stockAmount;

        if (this.product.vatRate?.selectedByDefault) {
          this.vatRate = 'default';
        } else {
          this.vatRate = this.product.vatRate?.id;
        }
      },
    },
    suppliers: {
      query: gql`
        query getSuppliers($locationId: ID, $pagination: PaginationAttributes) {
          suppliers(locationId: $locationId, pagination: $pagination) {
            id
            name
          }
        }
      `,
      variables() {
        return {
          locationId: this.locationId,
          pagination: {
            currentPage: 1,
            perPage: 1000,
          },
        };
      },
      fetchPolicy: 'cache-and-network',
    },
    productCategories: {
      query: gql`
        query getProductCategories($pagination: PaginationAttributes) {
          productCategories(pagination: $pagination) {
            id
            name
          }
        }
      `,
      variables() {
        return {
          pagination: {
            currentPage: 1,
            perPage: 1000,
          },
        };
      },
      fetchPolicy: 'cache-and-network',
    },
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    ...mapState(useVatRatesStore, ['vatRates']),
    ...mapState(useLocationsStore, ['locationId']),
    vatData() {
      const newVatRates = this.vatRates.map((item) =>
        this.transformVatRate(item),
      );
      newVatRates.unshift({
        value: 'default',
        label: this.$t('products.default_vat'),
      });
      return newVatRates;
    },
  },
  methods: {
    setSupplier(supplier) {
      this.supplier = supplier;
    },
    transformVatRate(item) {
      return {
        value: item.id,
        label:
          item.percentage !== null
            ? `${item.name} [${item.percentage}%]`
            : item.name,
      };
    },
    callback() {
      flash(
        this.$t(
          `global.flash.${this.$route.params.id ? 'product_updated' : 'product_created'}`,
        ),
      );
      if (this.parentRouteName) {
        this.$router.push({ name: this.parentRouteName });
      } else {
        this.$emit('close');
      }
    },
    submit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;

      const input = {
        name: this.name,
        price: this.price,
        costPrice: this.costPrice,
        reference: this.reference,
        partNumber: this.partNumber,
        supplierId: this.supplier ? this.supplier : null,
        vatRateId: this.vatRate !== 'default' ? this.vatRate : null,
        categoryId: this.category ? this.category : null,
        stockEnabled: this.stockEnabled,
        locationId: this.product?.locationId || this.locationId,
      };

      if (this.company.medical) {
        input.medical = this.medical;
      }

      if (this.stockEnabled) {
        input.stockMinimum = this.stockMinimum;
        input.stockMaximum = this.stockMaximum;
        input.stockAmount = this.stockAmount;
      }

      if (this.$route.params.id) {
        input.id = Number.parseInt(this.$route.params.id);
      }

      if (this.$route.params.id) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateProduct($input: UpdateProductInput!) {
                updateProduct(input: $input) {
                  product {
                    ...fullProduct
                  }
                }
              }
              ${productFragment}
            `,
            variables: {
              input,
            },
            update(cache) {
              cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'productCalculations',
              });
              cache.gc();
            },
          })
          .then(() => {
            this.callback();
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createProduct($input: CreateProductInput!) {
                createProduct(input: $input) {
                  errors
                }
              }
            `,
            variables: {
              input,
            },
            update(cache) {
              cache.evict({ id: 'ROOT_QUERY', fieldName: 'products' });
              cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'productCalculations',
              });
              cache.gc();
            },
          })
          .then(() => {
            this.callback();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
});
</script>
