<template>
  <ModuleBase fullHeight>
    <div
      :class="[
        $style.base,
        {
          [$style.isSmall]: $screen === 's',
          [$style.previewLayout]: previewLayoutActive
        }
      ]"
    >
      <div v-if="$screen === 's'" :class="$style.mobileButtons">
        <BaseButton
          :routerLink="{ name: 'create-appointment' }"
          icon="plus"
          size="l"
          v-test="'create-appointment-mobile'"
        />
      </div>

      <transition>
        <div
          v-if="$screen === 's'"
          v-show="isHeaderExpanded"
          :class="$style.mobileOverlay"
          @click="isHeaderExpanded = false"
          v-test="'calendar-overlay-mobile'"
        />
      </transition>

      <div :class="$style.inner">
        <div v-if="$screen === 's'" :class="$style.mobileHeader">
          <BaseDropdown
            v-if="!showResourceSelection"
            v-model="selectedViewType"
            :options="calendarViewsOptions"
            hideText
            mb
          />
          <div :class="$style.dateNav" class="mb-1">
            <BaseButton
              color="inverted"
              icon="arrow-left"
              @click="showPrevDate"
            />
            <BaseDatePicker
              :modelValue="date"
              :hideYear="$screen === 's'"
              @update:modelValue="onDateSelect"
            />
            <BaseButton
              color="inverted"
              icon="arrow-right"
              @click="showNextDate"
            />
          </div>
          <BaseDropdown
            v-if="showResourceSelection"
            v-model="resourceId"
            :options="resourcesListOptions"
            hideText
            :disabled="disableFilters"
            resources
            v-test="'resource-selection-mobile'"
          />
          <BaseButton
            color="inverted"
            :icon="isHeaderExpanded ? 'close' : 'options'"
            mb
            @click="isHeaderExpanded = !isHeaderExpanded"
            v-test="'btn-overlay-toggle'"
          />
        </div>

        <transition>
          <div
            v-show="$screen !== 's' || isHeaderExpanded"
            :class="$style.header"
          >
            <div :class="$style.headerInner">
              <BaseButton
                v-if="$screen !== 's'"
                color="inverted"
                @click="showToday"
                v-test="'select-calendar-date-today'"
              >
                {{ $t('global.today') }}
              </BaseButton>
              <div>
                <BaseText v-if="$screen === 's'" bold :mb="0.5">
                  {{ $t('calendar.view_type') }}
                </BaseText>
                <BaseDropdown
                  v-model="selectedViewType"
                  :options="calendarViewsOptions"
                  :disabled="disableFilters"
                  v-test="'select-calendar-view'"
                />
              </div>
              <div>
                <BaseText v-if="$screen === 's'" bold :mb="0.5">
                  {{ $t('calendar.date_selection') }}
                </BaseText>
                <div :class="$style.dateNav">
                  <BaseButton
                    color="inverted"
                    icon="arrow-left"
                    @click="showPrevDate"
                    v-test="'select-calendar-date-prev'"
                  />
                  <BaseDatePicker
                    :modelValue="date"
                    :range="$screen === 's' ? null : datePickerRange"
                    calendarMenu
                    @update:modelValue="onDateSelect"
                    v-test="'select-calendar-date'"
                  />
                  <BaseButton
                    color="inverted"
                    icon="arrow-right"
                    @click="showNextDate"
                    v-test="'select-calendar-date-next'"
                  />
                  <BaseDropdown
                    v-if="$screen !== 's'"
                    :options="skippableWeeks"
                    placeholderIcon="arrow-double-right"
                    @update:modelValue="skipWeek"
                    v-test="'select-calendar-date-skip'"
                  />
                </div>
              </div>
              <Flex v-if="$screen === 's'">
                <BaseDropdown
                  :options="skippableWeeks"
                  placeholderIcon="arrow-double-right"
                  @update:modelValue="skipWeek"
                  v-test="'select-calendar-date-skip'"
                />

                <BaseButton
                  color="inverted"
                  @click="showToday"
                  v-test="'select-calendar-date-today'"
                >
                  {{
                    viewType === 'WEEK'
                      ? $t('global.this_week')
                      : $t('global.today')
                  }}
                </BaseButton>
              </Flex>
              <DynamicPriceMode
                v-if="$screen === 's' && showDynamicPricingToggle"
              />
              <div
                v-if="showTypeSelection || showResourceSelection"
                v-show="!dynamicPricingMode"
                :class="$style.filters"
                v-test="'calendar-resource-filters'"
              >
                <BaseText v-if="$screen === 's'" bold :mb="0.5">
                  {{ filters.capitalize($t('global.items.filter', 2)) }}
                </BaseText>
                <BaseDropdown
                  v-if="showTypeSelection"
                  v-model="resourceType"
                  :options="
                    availableCalendarTypes.map((type) => ({
                      value: type.name,
                      label: type.label,
                      icon: type.icon
                    }))
                  "
                  :mb="$screen === 's'"
                  :mr="$screen !== 's' ? 0.5 : false"
                  :disabled="disableFilters"
                  v-test="'select-calendar-type'"
                />
                <BaseDropdown
                  v-if="showResourceSelection"
                  v-model="resourceId"
                  :options="resourcesListOptions"
                  :hideText="$screen === 'm'"
                  :disabled="disableFilters"
                  resources
                  :noOverflow="$screen === 's'"
                  v-test="'select-calendar-resource'"
                />
              </div>
            </div>
            <div v-if="$screen !== 's'" v-show="!previewLayoutActive">
              <BaseButton
                v-if="dynamicPricingMode"
                :routerLink="{ name: 'dynamic-price' }"
                ml
                v-test="'create-dynamic-price'"
              >
                {{ $t('dynamic_pricing.create') }}
              </BaseButton>
              <BaseButton
                v-else
                :routerLink="{ name: 'create-appointment' }"
                ml
                v-test="'create-appointment'"
              >
                {{ $t('global.actions.create_appointment') }}
              </BaseButton>
            </div>
            <BaseButton
              v-else
              @click="isHeaderExpanded = false"
              v-test="'btn-overlay-close'"
            >
              {{ $t('calendar.close_filters') }}
            </BaseButton>
          </div>
        </transition>

        <div :class="$style.headerSub">
          <div
            v-show="hasHiddenEvents"
            :class="$style.alert"
            v-test="'appointment-preview-hidden-alert'"
          >
            <BaseAlert
              color="warning"
              :text="$t('calendar.preview.events_hidden')"
            />
          </div>

          <div
            v-if="currentInteractionAction === 'RESCHEDULE'"
            :class="$style.alert"
            v-test="'appointment-reschedule-alert'"
          >
            <BaseAlert
              :text="$t('calendar.appointment_reschedule')"
              :primaryAction="$t('global.actions.cancel')"
              @primaryAction="resetPreCreateStore"
            />
          </div>

          <div
            v-if="dynamicPricingMode"
            :class="$style.alert"
            v-test="'dynamic-pricing-alert'"
          >
            <BaseAlert
              :text="$t('dynamic_pricing.info')"
              :primaryAction="$t('dynamic_pricing.regular_view')"
              @primaryAction="dynamicPricingMode = false"
              v-test="'dynamic-pricing-alert-disable'"
            />
          </div>

          <div
            v-if="currentInteractionAction === 'COPY'"
            :class="$style.alert"
            v-test="'appointment-copy-alert'"
          >
            <BaseAlert
              :text="$t('calendar.appointment_copy')"
              :primaryAction="$t('global.actions.cancel')"
              @primaryAction="resetPreCreateStore"
            />
          </div>

          <div
            v-if="
              currentInteractionAction === 'CREATE' &&
              currentInteractionCustomer
            "
            :class="$style.alert"
            v-test="'appointment-create-alert'"
          >
            <BaseAlert
              :text="
                $t('calendar.appointment_create', {
                  name: currentInteractionCustomer.firstName
                })
              "
              :primaryAction="$t('global.actions.cancel')"
              @primaryAction="
                () => {
                  resetPreCreateStore();
                  mixpanel.track(
                    'Calendar - Cancel customer appointment clicked'
                  );
                }
              "
            />
          </div>
        </div>
        <Calendar
          v-if="resources.length && showCalendar"
          :class="$style.calendar"
        />
      </div>
    </div>
  </ModuleBase>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import ModuleBase from '@/modules/ModuleBase.vue';
import Calendar from './calendar/index.vue';
import calendarInterface from '@/modules/calendar/calendar-interface';
import { useResourcesStore } from '@/stores/resources';
import { mapState, storeToRefs } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';
import DynamicPriceMode from '@/modules/calendar/DynamicPriceMode.vue';
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { resourceIcon } from '@/helpers/formatting';
import { useUserStore } from '@/stores/user';
import { usePageLayoutStore } from '@/stores/page-layout';
import { defineComponent } from 'vue';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import type { Dayjs } from 'dayjs';

export default defineComponent({
  name: 'CalendarPage',
  components: {
    ModuleBase,
    Calendar,
    DynamicPriceMode,
  },
  inject: ['mixpanel'],
  setup() {
    const {
      resourceId,
      resourceType,
      dynamicPricingMode,
      date,
      viewType,
      mobileFullView,
    } = storeToRefs(useCalendarFiltersStore());
    const { previewLayoutActive, hasHiddenEvents } = storeToRefs(
      useCalendarPreviewStore(),
    );

    const store = useCalendarFiltersStore();

    const setDate = (date: Dayjs) => {
      store.date = date.format('YYYY-MM-DD');
    };

    const onDateSelect = (date: string) => {
      setDate(dayjs.tz(date));
    };

    const showPrevDate = () => {
      const { viewType } = useCalendarFiltersStore();
      setDate(
        dayjs.tz(store.date).subtract(1, viewType === 'WEEK' ? 'week' : 'day'),
      );
    };

    const showNextDate = () => {
      const { viewType } = useCalendarFiltersStore();
      setDate(
        dayjs.tz(store.date).add(1, viewType === 'WEEK' ? 'week' : 'day'),
      );
    };

    const showToday = () => {
      setDate(dayjs().tz());
    };

    const skipWeek = (weeks: number) => {
      setDate(dayjs.tz(store.date).add(weeks, 'week'));
    };

    return {
      date,
      resourceId,
      resourceType,
      viewType,
      mobileFullView,
      dynamicPricingMode,
      filters,
      previewLayoutActive,
      hasHiddenEvents,
      onDateSelect,
      showPrevDate,
      showNextDate,
      showToday,
      skipWeek,
    };
  },
  data() {
    return {
      isHeaderExpanded: false,
      showCalendar: false,
      showModal: true,
    };
  },
  watch: {
    '$route.name'(to, from) {
      if (
        to !== 'calendar' &&
        from === 'calendar' &&
        to !== 'create-appointment' &&
        this.currentInteractionAction
      ) {
        this.resetPreCreateStore();
      }
    },
    mobileFullView() {
      this.$nextTick(() => {
        calendarInterface.api?.render();
      });
    },
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    ...mapState(useUserStore, ['user', 'hasFeatureFlag']),
    ...mapState(useLocationsStore, ['locationId']),
    ...mapState(useResourcesStore, [
      'resourcesByLocation',
      'resourcesByType',
      'resourceById',
    ]),
    selectedViewType: {
      get() {
        if (this.mobileFullView) {
          return 'FULL';
        } else {
          return this.viewType;
        }
      },
      set(value) {
        if (value === 'FULL') {
          this.viewType = 'WEEK';
          this.mobileFullView = true;
        } else {
          this.viewType = value;
          this.mobileFullView = false;
        }
      },
    },
    calendarViewsOptions() {
      const { screenSize } = usePageLayoutStore();

      const views = [
        {
          value: 'DAY',
          label: this.$t('calendar.view_day'),
          icon: screenSize === 's' ? 'calendar-day' : null,
        },
        {
          value: 'WEEK',
          label: this.$t('calendar.view_week'),
          icon: screenSize === 's' ? 'calendar-week' : null,
        },
      ];

      if (screenSize === 's') {
        views.push({
          value: 'FULL',
          label: this.$t('calendar.view_week_overview'),
          icon: 'calendar-week',
        });
      }

      return views;
    },
    ...mapState(usePreCreateStore, {
      currentInteractionAction: 'action',
      currentInteractionCustomer: 'customer',
    }),
    resources() {
      const { resourcesByLocation } = useResourcesStore();
      return resourcesByLocation.filter((r) => r.type === this.resourceType);
    },
    resourcesListOptions() {
      return [
        {
          value: null,
          label: this.$t('calendar.show_all'),
          icon: resourceIcon(this.resourceType),
        },
        ...this.resources.map((r) => ({
          value: r.id,
          label: r.name,
        })),
      ];
    },
    skippableWeeks() {
      const weeks = [];
      for (let i = 0; i < 10; i++) {
        const value = i + 1;
        weeks.push({
          value,
          label: `${value} ${this.$t('global.items.week', i + 1)}`,
        });
      }
      return weeks;
    },
    showResourceSelection() {
      return this.resources.length > 1;
    },
    showTypeSelection() {
      return this.availableCalendarTypes.length > 1;
    },
    datePickerRange() {
      if (this.$screen === 'l' || !this.showTypeSelection) {
        return this.viewType.toLowerCase();
      } else {
        return null;
      }
    },
    availableCalendarTypes() {
      const calendarTypes = [
        {
          name: 'employee',
          label: this.$t('calendar.filter_employees'),
          icon: resourceIcon('employee'),
          default: true,
        },
        {
          name: 'room',
          label: this.$t('calendar.filter_rooms'),
          icon: resourceIcon('room'),
        },
        {
          name: 'equipment',
          label: this.$t('calendar.filter_equipment'),
          icon: resourceIcon('equipment'),
        },
      ];

      return calendarTypes.filter(
        (type) =>
          this.resourcesByLocation.filter((r) => r.type === type.name).length >
          0,
      );
    },
    disableFilters() {
      return (
        this.currentInteractionAction === 'RESCHEDULE' ||
        this.currentInteractionAction === 'COPY' ||
        this.dynamicPricingMode
      );
    },
    showDynamicPricingToggle() {
      return !!this.companySettings.bookings?.dynamicPricingEnabled;
    },
  },
  methods: {
    resetPreCreateStore() {
      const preCreateStore = usePreCreateStore();
      preCreateStore.$reset();
    },
  },
  created() {
    setTimeout(() => {
      this.showCalendar = true;
    }, 0);
  },
});
</script>

<style lang="scss" module>
$duration: 0.1s;
$easing: ease-in-out;
$headerMobileWidth: 295px;

.base {
  height: 100%;
  width: 100%;
}

.inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header,
.headerSub,
.calendar {
  width: 100%;

  .base.previewLayout & {
    width: 61.2%;
  }
}

.calendar {
  height: 100%;
}

.header {
  .base:not(.isSmall) & {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
  }

  .base.isSmall & {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: $headerMobileWidth;
    background-color: white;
    z-index: 5;
    padding: $spacing + $header-height $spacing $spacing;
    box-shadow: $shadow;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow-y: auto;

    &:global(.v-enter-active) {
      transition: transform $duration $easing;
    }
    &:global(.v-enter-from) {
      transform: translateX(100%);
    }
  }
}

.headerInner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .base:not(.isSmall) & {
    & > *:not(:last-child) {
      margin-right: $spacing;
    }
  }

  & > * {
    margin-bottom: $spacing;
  }

  .base.isSmall & {
    & > * {
      width: 100%;
    }
  }
}

.mobileHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-shrink: 0;
}

.alert {
  flex-shrink: 0;
  margin-bottom: $spacing;
}

.mobileButtons {
  position: absolute;
  right: $spacing;
  bottom: $spacing;
  z-index: 4;
  display: flex;
  flex-direction: column;
}

.mobileOverlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 3;
  overflow: hidden;

  &:global(.v-enter-active),
  &:global(.v-leave-active) {
    transition: opacity $duration $easing;
  }
  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
  }
}

.dateNav {
  display: flex;

  & > *:not(:last-child) {
    margin-right: $spacing * 0.5;
  }
}

.filters {
  .base:not(.isSmall) & {
    display: flex;
  }
}
</style>
