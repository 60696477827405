/* eslint-disable @typescript-eslint/no-explicit-any */
import type { NormalizedCacheObject } from '@apollo/client/core';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import 'cross-fetch/polyfill';
import { links } from '@/apollo/links';

const initApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const pagination = {
    keyArgs: false,
    merge(existing, incoming: any, { args: { pagination } }: any): any[] {
      if (pagination && pagination.currentPage === 1) {
        return incoming;
      }
      return [...existing, ...incoming];
    },
  };

  return new ApolloClient({
    link: links as any,
    cache: new InMemoryCache({
      typePolicies: {
        Appointment: {
          keyFields: (obj: any) => {
            if (obj.id && obj.startAt && obj.original === false) {
              // When fetching recurring appointments, we'll have multiple objects with the same id
              // So we need to use the "startAt" to differentiate them
              // Only do this for recurring appointments, which is indicated by the "original" field
              return `${obj.id}-${obj.startAt}`;
            }

            return obj.id;
          },
        },
        Query: {
          fields: {
            cashups: {
              ...pagination,
            },
            prepaidCards: {
              ...pagination,
            },
            messages: {
              ...pagination,
            },
            productCategories: {
              ...pagination,
            },
            suppliers: {
              ...pagination,
            },
            orders: {
              ...pagination,
            },
            giftcards: {
              ...pagination,
            },
            orderItems: {
              ...pagination,
            },
            imports: {
              ...pagination,
            },
            stockOrders: {
              ...pagination,
            },
            stockMutations: {
              ...pagination,
            },
            discountCodes: {
              ...pagination,
            },
            discountCodeUsage: {
              ...pagination,
            },
            feedbacks: {
              ...pagination,
            },
            changeLogs: {
              ...pagination,
            },
            businessHours: {
              merge(_existing: any, incoming: any): any[] {
                return incoming;
              },
            },
            calendarLabels: {
              merge(_existing: any, incoming: any): any[] {
                return incoming;
              },
            },
          },
        },
      },
    } as any),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};

export default initApolloClient();
