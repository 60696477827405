import { logValidationError } from '@/helpers/datadog';
import { flash } from '@/helpers/ui';
import i18n from '@/i18n';
import type { UpdateVatRatesInput, VatRate } from '@/types';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';

const vatRateFragment = gql`
  fragment vatRate on VatRate {
    default
    id
    name
    percentage
  }
`;

export const vatRatesFetched = ref(false);

export const useVatRatesStore = defineStore('vatRates', () => {
  const allRates = ref<VatRate[]>([]);

  const { onResult, loading } = useQuery(
    gql`
      query getVatRates {
        vatRates {
          ...vatRate
        }
      }
      ${vatRateFragment}
    `,
    null,
    {
      fetchPolicy: 'cache-first',
    },
  );

  onResult(({ data: { vatRates } }) => {
    allRates.value = vatRates;
    vatRatesFetched.value = true;
  });

  const vatRates = computed(() =>
    allRates.value.map((rate) => ({
      ...rate,
      label:
        rate.percentage !== null && rate.percentage !== undefined
          ? `${rate.name} (${rate.percentage}%)`
          : rate.name,
    })),
  );

  const saving = ref(false);

  const updateVatRates = (vatRates: UpdateVatRatesInput['vatRates']) => {
    const { mutate } = useMutation(gql`
      mutation updateVatRates($input: UpdateVatRatesInput!) {
        updateVatRates(input: $input) {
          vatRates {
            ...vatRate
          }
          errors
        }
      }
      ${vatRateFragment}
    `);

    saving.value = true;

    return mutate({
      input: {
        vatRates,
      },
    }).then(
      ({
        data: {
          updateVatRates: { vatRates, errors },
        },
      }) => {
        if (errors) {
          logValidationError('updateVatRates', errors);
        }

        saving.value = false;
        allRates.value = vatRates;
        flash(i18n.t('global.flash.vat_rates_updated'));
      },
    );
  };

  const deleteVatRate = (id: VatRate['id']) => {
    const { mutate } = useMutation(gql`
      mutation deleteVatRate($input: DeleteVatRateInput!) {
        deleteVatRate(input: $input) {
          vatRate {
            id
          }
        }
      }
    `);

    saving.value = true;

    mutate({
      input: {
        id,
      },
    }).then(() => {
      saving.value = false;
      allRates.value = allRates.value.filter((rate) => rate.id !== id);
      flash(i18n.t('global.flash.vat_rate_deleted'));
    });
  };

  const reset = () => {
    allRates.value = [];
    vatRatesFetched.value = false;
  };

  return {
    vatRates,
    loading,
    saving,
    updateVatRates,
    deleteVatRate,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVatRatesStore, import.meta.hot));
}
