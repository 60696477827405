import apolloClient from '@/apollo/client';
import { useLocationsStore } from '@/stores/locations';
import { useQuery } from '@vue/apollo-composable';
import type { DocumentNode } from 'graphql';
import { computed, reactive, ref, watch } from 'vue';

type Options = {
  multiLocation?: boolean;
  offset?: boolean;
  subFieldName?: string;
};

type Variables = {
  [key: string]: any;
};

interface PaginationInterface {
  fieldName: string;
  query: DocumentNode;
  variables?: Variables;
  options?: Options;
}

export const usePagination = ({
  fieldName,
  query,
  variables,
  options = {},
}: PaginationInterface) => {
  // Current page for pagination. Does not need to be reactive.
  let currentPage = 1;

  // Define offset when using the offset version.
  const useOffset = !!options.offset;
  const offset = ref(0);

  // Wether or not all data is fetched. Needs to be reactive as it is used in the component.
  const allDataFetched = ref(false);

  // Wether or not the first fetch has finished. Can be used to handle loading states in the component.
  const firstFetchFinished = ref(false);

  // The amount of items per page.
  // If needed we can make this variable so we can send a custom value from the component.
  const perPage = 25;

  const _variables = { ...variables };

  if (options?.multiLocation) {
    // Add multilocation variables automatically
    const { locationId, dataScope } = useLocationsStore();

    _variables.locationId = locationId;
    _variables.dataScope = dataScope;
  }

  const getPaginationVars = () =>
    useOffset
      ? {
          offset: offset.value,
          limit: perPage,
        }
      : {
          currentPage,
          perPage,
        };

  const reactiveVars = reactive<Variables>({
    ..._variables,
    pagination: getPaginationVars(),
  });

  // Reset pagination variables when query variables change.
  watch(
    reactiveVars,
    () => {
      currentPage = 1;
      offset.value = 0;
      allDataFetched.value = false;
    },
    {
      deep: true,
    },
  );

  if (options?.multiLocation) {
    // When multilocation data changes in the store, update the query variables (which will trigger a refetch)

    const locationId = computed(() => {
      const { locationId } = useLocationsStore();
      return locationId;
    });
    const dataScope = computed(() => {
      const { dataScope } = useLocationsStore();
      return dataScope;
    });

    watch(
      () => locationId.value,
      (newValue) => {
        reactiveVars.locationId = newValue;
      },
    );

    watch(
      () => dataScope.value,
      (newValue) => {
        reactiveVars.dataScope = newValue;
      },
    );
  }

  const { result, loading, fetchMore, refetch, onResult } = useQuery(
    query,
    reactiveVars,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );

  const _fetchMore = () => {
    if (loading.value || allDataFetched.value) {
      // Current query needs to finish first, or all data has already been fetched.
      return;
    }

    currentPage++;
    offset.value += perPage;

    fetchMore({
      variables: {
        pagination: getPaginationVars(),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const fetchedData = options.subFieldName
          ? fetchMoreResult[fieldName][options.subFieldName]
          : fetchMoreResult[fieldName];

        // When the result of the fetchmore is shorter than the perPage amount, we assume that all items are fetched.
        allDataFetched.value = fetchedData.length < perPage;

        if (options.subFieldName) {
          return {
            ...previousResult,
            [fieldName]: {
              ...previousResult[fieldName],
              [options.subFieldName]: [
                ...previousResult[fieldName][options.subFieldName],
                ...fetchedData,
              ],
            },
          };
        } else {
          return {
            ...previousResult,
            [fieldName]: [...previousResult[fieldName], ...fetchedData],
          };
        }
      },
    });
  };

  const _refetch = () => {
    allDataFetched.value = false;
    currentPage = 1;
    offset.value = 0;

    apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName });
    apolloClient.cache.gc();

    refetch();
  };

  onResult((queryResult) => {
    // This triggers when a new result comes in, either via the first query, or the fetchMore
    // Note that this result is always the entire list, so the length will increase with each fetchMore
    // When doing fetchMore, allDataFetched is set based on the length of the fetchMore result
    // Here we are basically checking if the result of the initial query is shorter than perPage, in which case we don't want to do a fetchMore

    const fetchedData = options.subFieldName
      ? queryResult.data?.[fieldName][options.subFieldName]
      : queryResult.data?.[fieldName];

    if (fetchedData?.length < perPage) {
      allDataFetched.value = true;
    }

    firstFetchFinished.value = true;
  });

  return {
    [fieldName]: computed(() => result.value?.[fieldName] || []),
    loading,
    variables: reactiveVars,
    offset,
    fetchMore: _fetchMore,
    refetch: _refetch,
    allDataFetched,
    firstFetchFinished,
    onResult,
  };
};
