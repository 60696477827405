<template>
  <BaseModal
    :heading="$t('admin.treatwell.roster_modal.heading')"
    :loading="loadingVenue"
    useForm
    small
    testId="task-setup-roster"
    @submit="handleMutate"
    @close="close"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <BaseText :mb="0.5">
        {{ $t('admin.treatwell.roster_modal.description') }}
      </BaseText>
      <BaseAlert
        color="warning"
        :text="$t('admin.treatwell.roster_modal.alert')"
        mb
      />
      <div v-for="day in days" :key="day.name">
        <BaseCard mb gray>
          <div :class="$style.cardContent">
            <div :class="$style.day">
              <BaseCheckbox
                v-model="day.checked"
                :label="$t(`onboarding.new_tasks.roster.${day.name}`)"
                mr
                mb
                v-test="'roster-checkbox'"
              />
            </div>
            <div v-if="day.checked" :class="$style.time">
              <BaseTimePicker
                v-model="day.from"
                :label="$t('onboarding.new_tasks.roster.from')"
                :maxValue="day.to"
                mr
                v-test="'roster-from'"
              />
              <BaseTimePicker
                v-model="day.to"
                :label="$t('onboarding.new_tasks.roster.to')"
                :minValue="day.from"
                mr
                v-test="'roster-to'"
              />
            </div>
            <BaseText v-if="day.checked" link @click="handleApplyAll(day)">
              <div :class="$style.apply" v-test="'rosterApplyAll'">
                {{ $t('onboarding.new_tasks.roster.apply') }}
              </div>
            </BaseText>
          </div>
        </BaseCard>
      </div>
    </div>
    <template #footer>
      <BaseButton color="inverted" @click="close">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton :loading="loading" submitForm v-test="'create-roster-submit'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import type { TreatwellRosterDay } from '@/types';
import { DaysOfWeek } from '@/types';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { GET_TREATWELL_VENUE, UPDATE_TREATWELL_ROSTER } from '../graphql';
const { t } = useI18n();
const router = useRouter();

type Day = {
  name: string;
  from: string;
  to: string;
  checked: boolean;
  value: DaysOfWeek;
};

const days = ref<Day[]>([
  {
    name: 'monday',
    from: '09:00',
    to: '19:00',
    checked: true,
    value: DaysOfWeek.Mo,
  },
  {
    name: 'tuesday',
    from: '09:00',
    to: '19:00',
    checked: true,
    value: DaysOfWeek.Tu,
  },
  {
    name: 'wednesday',
    from: '09:00',
    to: '19:00',
    checked: true,
    value: DaysOfWeek.We,
  },
  {
    name: 'thursday',
    from: '09:00',
    to: '19:00',
    checked: true,
    value: DaysOfWeek.Th,
  },
  {
    name: 'friday',
    from: '09:00',
    to: '19:00',
    checked: true,
    value: DaysOfWeek.Fr,
  },
  {
    name: 'saturday',
    from: '09:00',
    to: '19:00',
    checked: true,
    value: DaysOfWeek.Sa,
  },
  {
    name: 'sunday',
    from: '09:00',
    to: '19:00',
    checked: false,
    value: DaysOfWeek.Su,
  },
]);

const { loading: loadingVenue, onResult: onVenueResult } =
  useQuery(GET_TREATWELL_VENUE);
onVenueResult(({ data: { treatwellVenue } }) => {
  if (treatwellVenue?.treatwellRosterDays?.length) {
    days.value.forEach((day) => (day.checked = false));
    treatwellVenue.treatwellRosterDays.forEach(
      (dayOfWeek: TreatwellRosterDay) => {
        const dayIndex = days.value.findIndex(
          (day: Day) => day.value === dayOfWeek.day,
        );
        days.value[dayIndex] = {
          ...days.value[dayIndex],
          from: dayOfWeek.openingTime,
          to: dayOfWeek.closingTime,
          checked: true,
        };
      },
    );
  }
});

const handleApplyAll = (value: Day) => {
  days.value.forEach((day: Day) => {
    day.from = value.from;
    day.to = value.to;
  });
};

const { mutate: createRoster, loading } = useMutation(UPDATE_TREATWELL_ROSTER);

const handleMutate = () => {
  const rosterDays = days.value
    .filter((day: Day) => day.checked)
    .map((v) => ({ day: v.value, openingTime: v.from, closingTime: v.to }));
  createRoster({ input: { rosterDays } }).then(() => {
    flash(t('global.flash.roster_saved'));
    close();
  });
};

const close = () => {
  router.push({
    name: 'treatwell-admin',
  });
};
</script>

<style lang="scss" module>
.cardContent {
  display: flex;
  align-items: flex-start;

  .smallScreen & {
    flex-direction: column;
  }
}

.day {
  width: 120px;
}
.apply {
  opacity: 0;
}

.cardContent {
  @include hover {
    .apply {
      opacity: 1;
    }
  }
}

.time {
  display: flex;
  align-items: center;
}
</style>
