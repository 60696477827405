<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    v-test="'select-franchise'"
  >
    <BaseDropdown
      v-model="companyId"
      :options="
        franchiseCompanies.map((company) => ({
          value: company.id,
          label: company.internalName
        }))
      "
      :small="$screen !== 's'"
      searchAutoFocus
      :searchable="franchiseCompanies.length > 7"
      :inverted="$screen === 's'"
    />
  </div>
</template>

<script setup lang="ts">
import gql from 'graphql-tag';
import { useCompanyStore } from '@/stores/company';
import { computed } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import type { Company } from '@/types';
import eventBus from '@/event-bus';
import { useRouter } from 'vue-router';

const { result } = useQuery(gql`
  query getFranchiseCompanies {
    franchiseCompanies {
      id
      internalName
      name
      primaryLocationId
    }
  }
`);

const franchiseCompanies = computed<
  {
    id: Company['id'];
    internalName: Company['internalName'];
    name: Company['name'];
    primaryLocationId: Company['primaryLocationId'];
  }[]
>(() => result.value?.franchiseCompanies || []);

const router = useRouter();

const companyId = computed({
  get() {
    const { companyId } = useCompanyStore();
    return companyId;
  },
  set(id) {
    const { mutate, onDone } = useMutation(
      gql`
        mutation becomeFranchise($input: BecomeFranchiseInput!) {
          becomeFranchise(input: $input) {
            status
          }
        }
      `,
      {
        variables: {
          input: {
            id,
          },
        },
      },
    );

    mutate();

    onDone(() => {
      const win: Window = window;
      let url = window.location.origin;

      const newCompany = franchiseCompanies.value.find(
        (company) => company.id === id,
      );

      if (newCompany) {
        const route = router.resolve({
          name: router.currentRoute.name,
          params: {
            companyId: id,
            locationId: newCompany.primaryLocationId,
          },
        });

        if (route) {
          url += route.href;
        }
      }

      win.location = url;
    });
  },
});

eventBus.$on('update-franchise-name', (internalName: string) => {
  const activeFranchise = franchiseCompanies.value.find(
    (company) => company.id === companyId.value,
  );
  if (activeFranchise) {
    activeFranchise.internalName = internalName;
  }
});
</script>

<style lang="scss" module>
.base {
  &:not(.smallScreen) {
    width: 150px;
  }
}
</style>
