<template>
  <div
    :class="[
      $style.base,
      {
        [$style.small]: small,
        [$style.large]: large,
        [$style.isSelected]: selected,
        [$style.custom]: !!colorPicker
      }
    ]"
    :style="modelValue ? { 'background-color': modelValue } : {}"
    @click="$emit('click')"
    v-test="'_base-color-label'"
  >
    <input
      v-if="colorPicker"
      ref="colorInput"
      v-model="inputValue"
      :class="$style.colorInput"
      type="color"
      v-test="'_base-color-input'"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
    },
    small: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
    },
    colorPicker: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'click'],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
});
</script>

<style lang="scss" module>
$largeSize: 32px;
$size: 22px;
$smallSize: 12px;

.base {
  position: relative;
  border-radius: 50%;
  flex-shrink: 0;

  &:not(.small),
  &:not(.large) {
    width: $size;
    height: $size;
  }

  &.small {
    width: $smallSize;
    height: $smallSize;
  }

  &.large {
    width: $largeSize;
    height: $largeSize;
  }

  &.isSelected {
    border: 2px solid white;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.6);
  }

  &.custom:not(.isSelected) {
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.3),
      rgba(255, 255, 255, 0.5)
    );
  }
}

.colorInput {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  cursor: pointer;
}
</style>
