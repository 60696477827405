<template>
  <BaseCard :heading="$t('cashups.counted_cash')">
    <BaseTable
      :headers="[$t('global.items.coin', 2), '', $t('cashups.note', 2), '']"
      :rows="
        cash.map((c) => ({
          cells: [c.coinAmount, c.coinValue, c.paperAmount, c.paperValue]
        }))
      "
      v-test="'counted-cash-rows'"
    />
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { CashCount } from '@/types';

interface CashRow {
  coinAmount: string;
  coinValue: string;
  paperAmount: string;
  paperValue: string;
}

export default defineComponent({
  props: {
    cashCount: {
      type: Array as PropType<CashCount[]>,
      required: true,
    },
  },
  setup() {
    return {
      filters,
    };
  },
  computed: {
    coins(): CashCount[] {
      return this.cashCount.filter((item) => item.moneyType === 'COIN');
    },
    paper(): CashCount[] {
      return this.cashCount.filter((item) => item.moneyType === 'PAPER');
    },
    cash(): CashRow[] {
      return this.formatCash();
    },
  },
  methods: {
    formatCash(arr = [] as CashRow[], index = 0 as number): CashRow[] {
      if (index === Math.max(this.coins.length, this.paper.length)) {
        return arr;
      }

      arr.push({
        coinAmount: this.coins[index]
          ? `${this.coins[index].amount ? this.coins[index].amount : 0}x`
          : '',
        coinValue: this.coins[index]
          ? this.filters.currency(this.coins[index].value)
          : '',
        paperAmount: this.paper[index]
          ? `${this.paper[index].amount ? this.paper[index].amount : 0}x`
          : '',
        paperValue: this.paper[index]
          ? this.filters.currency(this.paper[index].value)
          : '',
      });

      return this.formatCash(arr, index + 1);
    },
  },
});
</script>
