<template>
  <BaseCard gray :closable="deletable" @close="$emit('delete')">
    <slot />
    <BaseInput
      v-model="question"
      :label="$t('form_templates.consultation.question')"
      mb
      required
      :maxLength="255"
      v-test="'form-question-text'"
    />
    <div :class="$style.questionType">
      <BaseDropdown
        v-model="type"
        :options="[
          {
            value: 'TEXT',
            label: $t('form_templates.consultation.question_types.text')
          },
          {
            value: 'BOOLEAN',
            label: $t('form_templates.consultation.question_types.boolean')
          },
          {
            value: 'DROPDOWN',
            label: $t('form_templates.consultation.question_types.dropdown')
          },
          {
            value: 'CHECKBOX',
            label: $t('form_templates.consultation.question_types.checkbox')
          }
        ]"
        :label="$t('form_templates.consultation.question_type')"
        :disabled="disableTypeChange"
        :tooltip="
          disableTypeChange
            ? {
                text: $t('form_templates.consultation.question_type_disabled'),
                touch: true
              }
            : null
        "
        v-test="'form-question-type'"
      />
    </div>
    <div v-if="hasOptions">
      <Draggable
        :list="options"
        handle=".js-drag-handle"
        :class="$style.options"
        item-key="index"
      >
        <template #item="{ element, index }">
          <div :class="$style.option">
            <div :class="$style.optionInput">
              <BaseInput
                :modelValue="element"
                :label="
                  $t('form_templates.consultation.option', {
                    number: index + 1
                  })
                "
                required
                @update:modelValue="updateOption($event, index)"
                v-test="'form-question-option'"
              />
            </div>
            <div
              :class="[$style.optionIcon, $style.optionClose]"
              @click="removeOption(index)"
              v-test="'form-question-option-delete'"
            >
              <BaseIcon name="close" />
            </div>
            <div
              :class="[$style.optionIcon, $style.optionDrag]"
              class="js-drag-handle"
            >
              <BaseIcon name="drag" />
            </div>
          </div>
        </template>
      </Draggable>
      <BaseText
        link
        inline
        @click="addOption"
        v-test="'form-question-add-option'"
      >
        {{ $t('form_templates.consultation.add_option') }}
      </BaseText>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import Draggable from 'vuedraggable';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Draggable,
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (value) =>
        'id' in value &&
        'options' in value &&
        'question' in value &&
        'questionType' in value,
    },
    deletable: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['delete', 'update'],
  computed: {
    question: {
      get() {
        return this.data.question;
      },
      set(value) {
        this.$emit('update', {
          key: 'question',
          value,
        });
      },
    },
    type: {
      get() {
        return this.data.questionType;
      },
      set(value) {
        this.$emit('update', {
          key: 'questionType',
          value,
        });
      },
    },
    hasOptions() {
      return this.type === 'DROPDOWN' || this.type === 'CHECKBOX';
    },
    options: {
      get() {
        return this.data.options || [];
      },
      set(value) {
        this.$emit('update', {
          key: 'options',
          value,
        });
      },
    },
    disableTypeChange() {
      return !!this.data.id;
    },
  },
  methods: {
    addOption() {
      this.options = [...this.options, ''];
    },
    removeOption(index) {
      const arr = [...this.options];
      arr.splice(index, 1);
      this.options = arr;
    },
    updateOption(value, index) {
      const options = [...this.options];
      options[index] = value;
      this.options = options;
    },
  },
});
</script>

<style lang="scss" module>
.questionType {
  display: flex;
}

.options {
  padding: $spacing 0;

  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.option {
  display: flex;
}

.optionInput {
  width: 100%;
}

.optionIcon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;

  margin-top: 22px;
}

.optionClose {
  cursor: pointer;
}

.optionDrag {
  cursor: move;
}
</style>
