<template>
  <div>
    <BaseText :mb="2" center>
      {{ $t('verification.description') }}
    </BaseText>
    <BaseForm @submit="requestCode">
      <BaseDropdown
        v-model="country"
        :options="
          countries.map((country) => ({
            value: country.code,
            label: country.name
          }))
        "
        :label="$t('global.country')"
        searchable
        mb
        v-test="'verification-country'"
      />
      <BaseInput
        v-model="phoneNumber"
        :label="$t('global.mobile_number')"
        type="text"
        name="phoneNumber"
        required
        :mb="0.5"
        :error="phoneNumberError"
        v-test="'verification-number'"
      />
      <BaseText v-if="tokenError" color="error">
        {{ tokenError }}
      </BaseText>
      <div :class="$style.bottom">
        <BaseButton
          :loading="isLoading"
          center
          mt
          submitForm
          v-test="'verification-submit'"
        >
          {{ $t('verification.send_code') }}
        </BaseButton>
      </div>
    </BaseForm>
  </div>
</template>

<script lang="ts">
import prefixes from '@/phone-prefixes';
import useVuelidate from '@vuelidate/core';
import axios from '@/axios';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['textSent'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    const { company } = useCompanyStore();
    return {
      phoneNumber: null,
      country: company?.country || null,
      isLoading: false,
      countries: prefixes,
      phoneNumberError: '',
      tokenError: '',
    };
  },
  computed: {
    phonePrefix() {
      return this.countries.find((country) => country.code === this.country)
        .prefix;
    },
  },
  methods: {
    requestCode() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;
      axios
        .post('/verifications', {
          phone_prefix: this.phonePrefix.toString(),
          phone_number: this.phoneNumber,
        })
        .then(() => {
          this.$emit('textSent');
        })
        .catch((response) => {
          if (response?.errors) {
            this.phoneNumberError = this.$t('verification.phone_error');
          } else {
            this.tokenError = this.$t('verification.token_info');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>

<style lang="scss" module>
.bottom {
  text-align: center;
}
</style>
