<template>
  <ModuleBase noBorder gray>
    <PageWrap wide :heading="$t('admin.nav.links.employees')">
      <template #header>
        <BaseButton
          :routerLink="{ name: 'admin-employees-new' }"
          :fullWidth="$screen === 's'"
          v-test="'resource-header-create'"
        >
          {{ $t('employee.new') }}
        </BaseButton>
      </template>
      <div>
        <ResourceHeader
          v-model:filter="filter"
          v-model:searchQuery="searchQuery"
          :filterOptions="[
            { value: 'all', label: $t('global.all') },
            { value: 'active', label: $t('employee.active') },
            { value: 'deleted', label: $t('employee.deleted') },
            { value: 'bookable', label: $t('resource.bookable_online') }
          ]"
        />
        <ResourceList
          :resources="visibleEmployees"
          :header="showAll ? $t('employee.active') : null"
        />
        <ResourceList
          v-if="showAll"
          :resources="deletedEmployees"
          :header="$t('employee.deleted')"
        />
        <ResourcePrice v-if="showPriceInfo" type="employee" :mt="2" />
        <BaseText v-if="noResults" center :mt="2">
          {{ $t('global.no_results') }}
        </BaseText>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import ResourceHeader from '@/modules/admin/resources/ResourceHeader.vue';
import ResourceList from '@/modules/admin/resources/ResourceList.vue';
import ResourcePrice from '@/modules/admin/resources/ResourcePrice.vue';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import PageWrap from '../../PageWrap.vue';

import { defineComponent } from 'vue';
import { useCompanyStore } from '@/stores/company';

export default defineComponent({
  components: {
    ModuleBase,
    ResourceList,
    ResourceHeader,
    ResourcePrice,
    PageWrap,
  },
  data() {
    return {
      searchQuery: '',
      filter: 'active',
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['isMarketLaunchCountry']),
    ...mapState(useResourcesStore, ['allEmployees']),
    showPriceInfo() {
      return (
        this.allEmployees.filter((employee) => employee.state === 'active')
          .length < 5 && !this.isMarketLaunchCountry
      );
    },
    filteredEmployees() {
      return this.searchQuery
        ? this.allEmployees.filter((employee) =>
            employee.name.match(new RegExp(this.searchQuery, 'gi')),
          )
        : this.allEmployees;
    },
    visibleEmployees() {
      switch (this.filter) {
        case 'all':
        case 'active':
          return this.activeEmployees;
        case 'deleted':
          return this.deletedEmployees;
        case 'bookable':
          return this.bookableEmployees;
        default:
          return this.filteredEmployees;
      }
    },
    activeEmployees() {
      return this.filteredEmployees.filter(
        (employee) => employee.state === 'active',
      );
    },
    deletedEmployees() {
      return this.filteredEmployees.filter(
        (employee) => employee.state === 'deleted',
      );
    },
    bookableEmployees() {
      return this.filteredEmployees.filter(
        (employee) => employee.state === 'active' && employee.bookable,
      );
    },
    showAll() {
      return this.filter === 'all';
    },
    noResults() {
      return this.showAll
        ? !this.visibleEmployees.length && !this.deletedEmployees.length
        : !this.visibleEmployees.length;
    },
  },
});
</script>
