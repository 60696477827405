import { usePagination } from '@/apollo/pagination';
import filters from '@/filters';
import { useStripe } from '@/helpers/stripe';
import { type Invoice, InvoiceStatus, type StripeInvoice } from '@/types';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref } from 'vue';
import { GET_INVOICES_QUERY, GET_STRIPE_INVOICES } from './graphql';

const invoiceStates = [
  'pending',
  'open',
  'scheduled',
  'pending_payment',
  'late',
  'reminded',
  'paid',
  'uncollectible',
] as const;
type InvoiceState = (typeof invoiceStates)[number];

const formatStripeInvoiceToTable = (invoicesData: StripeInvoice[]) =>
  invoicesData.map((invoiceData) => ({
    id: invoiceData.id,
    cells: [
      invoiceData.sealedAt,
      {
        value: filters.currency(
          invoiceData.total as number,
          invoiceData.currency,
        ),
      },
      { label: invoiceData.realStatus.toLowerCase() },
      invoiceData.invoiceNumber,
    ],
    actions:
      invoiceData.realStatus === InvoiceStatus.Paid
        ? [{ name: 'pdf' }]
        : [{ name: 'pdf' }, { name: 'pay' }],
  }));

export const useInvoices = () => {
  const stripeInvoicesData = ref<StripeInvoice[]>([]);
  const { onResult, loading, refetch } = useQuery(GET_STRIPE_INVOICES, {
    limit: 10,
  });

  onResult(({ data }) => {
    if (data?.stripeInvoices) {
      stripeInvoicesData.value = data.stripeInvoices;
    }
  });

  const fetchAmountOfInvoices = (limit: number) => {
    refetch({ limit })?.then(({ data: { stripeInvoices } }) => {
      stripeInvoicesData.value = stripeInvoices;
    });
  };

  const unpaidValidStates = [
    InvoiceStatus.Failed,
    InvoiceStatus.Open,
    InvoiceStatus.Overdue,
  ];

  const unpaidInvoices = computed(() =>
    stripeInvoicesData.value.filter((invoice: StripeInvoice) =>
      unpaidValidStates.includes(invoice.realStatus),
    ),
  );
  const paidInvoices = computed(() =>
    stripeInvoicesData.value.filter(
      (invoice: StripeInvoice) => invoice.realStatus === InvoiceStatus.Paid,
    ),
  );

  const {
    invoices: oldInvoices,
    allDataFetched,
    fetchMore,
  } = usePagination({
    fieldName: 'invoices',
    query: GET_INVOICES_QUERY,
  });

  const tableData = computed(() => [
    ...formatStripeInvoiceToTable(unpaidInvoices.value),
    ...formatStripeInvoiceToTable(paidInvoices.value),
    ...oldInvoices.value.map((invoice: Invoice) => ({
      id: invoice.invoiceNumber,
      cells: [
        invoice.dueDate,
        { value: filters.currency(invoice.total ?? 0, invoice.currency) },
        { label: invoice.state as InvoiceState },
        invoice.invoiceNumber,
      ],
      actions: [{ name: 'pdf' }],
    })),
  ]);

  const openInvoice = (invoiceId: string) => {
    const stripeInvoiceData = stripeInvoicesData.value.find(
      (invoice) => invoice.id === invoiceId,
    );

    if (stripeInvoiceData?.invoicePdf) {
      window.location.href = stripeInvoiceData.invoicePdf;
    } else {
      window.location.href = `/admin/invoices/${invoiceId}.pdf`;
    }
  };

  const payInvoice = (invoiceId: string) => {
    const stripeInvoiceData = stripeInvoicesData.value.find(
      (invoice) => invoice.id === invoiceId,
    );

    if (stripeInvoiceData?.realStatus === InvoiceStatus.Failed) {
      const { goToDisputeCheckoutSession } = useStripe();
      goToDisputeCheckoutSession(invoiceId);
      return;
    }

    if (stripeInvoiceData?.hostedInvoiceUrl) {
      window.location.href = stripeInvoiceData?.hostedInvoiceUrl;
    }
  };

  return {
    unpaidInvoices,
    paidInvoices,
    loading,
    allDataFetched,
    fetchMore,
    fetchAmountOfInvoices,
    tableData,
    openInvoice,
    payInvoice,
  };
};
