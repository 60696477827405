import NewFeatures from '@/modules/new-features/index.vue';
import Hub from '@/modules/support/hub/index.vue';
import Support from '@/modules/support/index.vue';

export default {
  path: 'support',
  component: Support,
  meta: {
    moduleName: 'support',
  },
  redirect: {
    name: 'new-features',
  },
  children: [
    {
      path: '',
      name: 'support-hub',
      component: Hub,
    },
    {
      path: 'new-features',
      name: 'new-features',
      component: NewFeatures,
    },
  ],
};
