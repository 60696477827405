<template>
  <BaseModal
    :heading="$t('appointment.confirmation.reject_modal_header')"
    useForm
    small
    @submit="
      rejectAppointment({
        input: {
          appointmentId,
          message
        }
      })
    "
    @close="$emit('close')"
  >
    <BaseText mb>
      {{ $t('appointment.confirmation.reject_modal_description') }}
    </BaseText>
    <BaseSpinner v-if="loading" inline />
    <BaseEditor v-else v-model="message" v-test="'rejection-message'" />
    <template #footer>
      <BaseButton
        color="inverted"
        @click="$emit('close')"
        v-test="'btn-reject-cancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton submitForm :loading="saving" v-test="'btn-reject-submit'">
        {{ $t('global.actions.send') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { flash } from '@/helpers/ui';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { deleteCalendarEvent } from '../actions/calendar-events';

const emit = defineEmits(['close', 'submit']);
const props = defineProps<{
  appointmentId: number;
}>();

const { t } = useI18n();
const message = ref('');

const { loading, onResult } = useQuery(
  gql`
    query getTemplate($id: String!, $appointmentId: Int) {
      template(id: $id, appointmentId: $appointmentId) {
        populatedContent
      }
    }
  `,
  {
    id: 'appointment_rejection_email',
    appointmentId: props.appointmentId,
  },
);

onResult(
  ({ data: { template } }) => (message.value = template.populatedContent),
);

const {
  mutate: rejectAppointment,
  loading: saving,
  onDone: onRejectionFinish,
} = useMutation(gql`
  mutation rejectAppointment($input: RejectAppointmentInput!) {
    rejectAppointment(input: $input) {
      appointment {
        id
      }
      calendarAppointments {
        id
      }
    }
  }
`);

onRejectionFinish(
  ({
    data: {
      rejectAppointment: { calendarAppointments },
    },
  }) => {
    flash(t('global.flash.appointment_deleted'));

    if (calendarAppointments?.length) {
      calendarAppointments.forEach((event: any) => {
        deleteCalendarEvent(event.id);
      });
    }

    emit('submit');
  },
);
</script>
