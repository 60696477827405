<template>
  <div>
    <BaseHeading mb>{{ $t('service.nav.advanced.title') }}</BaseHeading>
    <div :class="$style.item">
      <BaseIcon
        name="globe"
        size="s"
        :mr="0.25"
        mb
        :color="hasError ? 'warning' : 'default'"
      />
      <BaseText :italic="hasError">
        {{ label }}
      </BaseText>
    </div>
    <div v-if="multiLocation && formData.bookable" :class="$style.item">
      <BaseIcon
        size="s"
        :mr="0.25"
        name="home"
        mb
        :color="
          isEdit && !formData.bookableLocationIds.length ? 'error' : 'default'
        "
      />
      <BaseText :italic="isEdit && !formData.bookableLocationIds.length">
        {{ locationsLabel }}
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ServiceVariationsFormData } from '../types';
import { useI18n } from 'vue-i18n';
import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';
import { useRoute } from 'vue-router';

const { multiLocation } = useCompanyStore();
const { locationIds, locations } = useLocationsStore();
const formData = inject<ServiceVariationsFormData>('serviceFormData')!;

const route = useRoute();

const isEdit = computed(() => !!route.params.serviceId);

const hasError = computed(
  () => isEdit.value && !!formData.variations.find((v) => !v.duration),
);

const { t } = useI18n();

const locationsLabel = computed(() => {
  if (formData.bookableLocationIds.length === locationIds.length) {
    return t('service.resources.location.all');
  }

  if (formData.bookableLocationIds.length === 0) {
    return t('service.resources.location.none');
  }

  return formData.bookableLocationIds
    .map((id) => locations.find((l) => l.id === id)?.name)
    .join(', ');
});

const label = computed(() => {
  if (formData.variations.find((v) => !v.duration)) {
    return formData.variations.filter((variation) => !variation.destroy)
      .length > 1
      ? t('service.nav.advanced.at_least_one_no_duration')
      : t('service.nav.advanced.not_bookable_online');
  }

  if (formData.bookable) {
    return t('service.nav.advanced.bookable_online');
  }

  return t('service.nav.advanced.not_bookable_online');
});
</script>

<style lang="scss" module>
.item {
  display: flex;
}
</style>
