import type { ServerError, ServerParseError } from '@apollo/client/core';
import type { GraphQLError } from 'graphql/error/GraphQLError';

export class GraphqlExecutionError extends Error {
  constructor(error: GraphQLError, operationName: string) {
    super(error.message);
    this.name =
      `GraphqlExecutionError in ${operationName}` || 'GraphqlExecutionError';
    this.stack = error.stack;
  }
}

export class GraphqlNetworkError extends Error {
  constructor({
    error,
    operationName,
  }: {
    error: Error | ServerParseError | ServerError;
    operationName: string;
  }) {
    super(error.message);
    this.name =
      `GraphqlNetworkError in ${operationName}` || 'GraphqlNetworkError';
    if (error.stack) {
      this.stack = error.stack;
    }
  }
}
