<template>
  <div
    v-if="canEnablePreviewMode"
    :class="$style.base"
    @click="previewModeEnabled = !previewModeEnabled"
    v-test="'preview-mode-toggle'"
  >
    <BaseIcon :name="previewModeEnabled ? 'expand' : 'collapse'" />
  </div>
</template>

<script setup lang="ts">
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { storeToRefs } from 'pinia';

const { canEnablePreviewMode, previewModeEnabled } = storeToRefs(
  useCalendarPreviewStore(),
);
</script>

<style lang="scss" module>
$size: 48px;

.base {
  position: relative;
  z-index: 2;
  width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include background-hover;
}
</style>
