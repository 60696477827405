<template>
  <div>
    <div v-if="hasError('required')" v-test="'_form-element-error'">
      <BaseText color="error" :mt="0.5">
        *{{ $t('global.validations.required') }}
      </BaseText>
    </div>
    <div
      v-else
      :class="[
        $style.alert,
        {
          [$style.error]: !!errors.length
        }
      ]"
      mt
      mb
      v-test="'_form-element-error'"
    >
      <BaseText bold :mb="0.5">
        {{ $t('global.password_validation.heading') }}
      </BaseText>
      <div
        v-for="(validation, index) in requiredValidations"
        :key="index"
        :class="$style.passwordItem"
      >
        <BaseText>
          {{ $t(`global.password_validation.${validation}`, { length: 8 }) }}
        </BaseText>
        <BaseIcon
          v-show="!hasSilentError(validation)"
          name="check"
          color="success"
          :ml="0.5"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  errors: any[];
  silentErrors: any[];
}>();

const requiredValidations = [
  'minLength',
  'containsUppercase',
  'containsLowercase',
  'containsNumber',
];
const hasError = (validation: string) =>
  !!props.errors.find((error) => error.$validator === validation);
const hasSilentError = (validation: string) =>
  !!props.silentErrors.find((error) => error.$validator === validation);
</script>

<style lang="scss" module>
.passwordItem {
  display: flex;
}

.alert {
  border-radius: $radius;
  margin-top: $spacing * 0.5;

  &.error {
    color: $color-error;
  }
}
</style>
