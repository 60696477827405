const isSafari =
  !/Chrom(e|ium)/g.test(navigator.userAgent) &&
  navigator.userAgent.indexOf('Safari') !== -1;

const getDevice = () => {
  const touch = 'ontouchstart' in window || navigator.maxTouchPoints > 1;
  const mobile =
    (typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1) &&
    window.screen.width <= 700;
  const tablet = touch && !mobile;
  const desktop = !tablet && !mobile;
  return { touch, mobile, tablet, desktop };
};

const _browser = {
  isIE:
    window.navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > -1,
  isAndroid: /android/g.test(navigator.userAgent.toLowerCase()),
  isFirefox: navigator.userAgent.indexOf('Firefox') !== -1,
  isSafari,
  isSamsungBrowser: !!navigator.userAgent.match(/SamsungBrowser/i),
  isUIWebview:
    getDevice().mobile && !(window.navigator as any).standalone && !isSafari,
};

const isBrowserSupported = () => {
  const neededFeatures = [
    // Needed to format dates correctly
    !!Intl?.DateTimeFormat?.prototype.formatToParts,
    // We don't support IE
    !_browser.isIE,
  ];

  return !!neededFeatures.filter((feature) => !feature).length;
};

export const browser: {
  isIE: boolean;
  isAndroid: boolean;
  isFirefox: boolean;
  isSafari: boolean;
  isSamsungBrowser: boolean;
  isUIWebview: boolean;
  isNotSupported: boolean;
} = {
  ..._browser,
  isNotSupported: isBrowserSupported(),
};

export const device: {
  touch: boolean;
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
} = getDevice();

const getOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/Android/i.test(userAgent)) {
    return 'android';
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'iOS';
  }
  if (/windows/i.test(userAgent)) {
    return 'windows';
  }
  if (/macintosh|mac os x/i.test(userAgent)) {
    return 'OSX';
  }

  return 'unknown';
};

export const OS = getOS();
