<template>
  <BaseModal
    :heading="
      giftcard
        ? `${$t('global.items.giftcard', 1)} ${filters.giftCardCode(giftcard.code)}`
        : ''
    "
    :loading="!giftcard"
    :details="details"
    :label="giftcard ? giftcard.status.toLowerCase() : null"
    :actions="actions"
    :parentRoute="{
      name: 'gift-cards'
    }"
    @action="deleteGiftCard"
  >
    <BaseHeading :mb="0.5">
      {{ filters.capitalize($t('global.items.payment', 2)) }}
    </BaseHeading>
    <div
      v-if="!giftcard.redeemedOrders.length"
      v-test="'giftcard-payments-not-used'"
    >
      {{ $t('giftcard.not_used') }}
    </div>
    <BaseTable
      v-else
      :headers="[$t('global.number'), $t('global.date'), $t('global.state')]"
      :rows="
        giftcard.redeemedOrders.map((order) => ({
          routerLink: {
            name: 'invoice',
            params: {
              id: order.id
            }
          },
          cells: [
            order.number,
            filters.dateTime(order.invoicedAt),
            {
              label: order.state.toLowerCase()
            }
          ]
        }))
      "
      disableZebra
      v-test="'giftcard-payments'"
    />
    <BaseHeading :mt="2" :mb="0.5">
      {{ filters.capitalize($t('global.history')) }}
    </BaseHeading>
    <div v-if="!giftcard.orders.length" v-test="'giftcard-history-not-used'">
      {{ $t('giftcard.no_history') }}
    </div>
    <BaseTable
      v-else
      :headers="[$t('global.number'), $t('global.date'), $t('global.state')]"
      :rows="
        giftcard.orders.map((order) => ({
          routerLink: {
            name: 'invoice',
            params: {
              id: order.id
            }
          },
          cells: [
            order.number,
            filters.dateTime(order.invoicedAt),
            {
              label: order.state.toLowerCase()
            }
          ]
        }))
      "
      disableZebra
      v-test="'giftcard-history'"
    />
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {
      filters,
    };
  },
  apollo: {
    giftcard: {
      query: gql`
        query getGiftcard($id: ID) {
          giftcard(id: $id) {
            code
            createdAt
            expiresAt
            id
            orders {
              id
              invoicedAt
              number
              state
            }
            permissions {
              delete
            }
            redeemedOrders {
              id
              invoicedAt
              number
              state
            }
            remainingAmount
            status
            totalAmount
            usedAmount
          }
        }
      `,
      variables() {
        return {
          id: this.giftcardId,
        };
      },
      skip() {
        return !this.giftcardId;
      },
    },
  },
  computed: {
    ...mapState(useLocationsStore, ['dataScope']),
    giftcardId() {
      return Number.parseInt(this.$route.params.giftcardId);
    },
    actions() {
      return this.giftcard?.permissions.delete
        ? [
            {
              name: 'delete',
              icon: 'delete',
              label: this.filters.capitalize(
                this.$t('global.actions.delete_item', {
                  item: this.$t('global.items.giftcard', 1),
                }),
              ),
            },
          ]
        : [];
    },
    details() {
      if (this.giftcard) {
        const details = [
          {
            label: this.$t('giftcard.amount_remaining'),
            value: `${this.filters.currency(this.giftcard.remainingAmount)} / ${this.filters.currency(this.giftcard.totalAmount)}`,
          },
          {
            label: this.$t('giftcard.expiration_date'),
            value: this.giftcard.expiresAt
              ? this.giftcard.expiresAt
              : this.$t('global.never'),
            type: this.giftcard.expiresAt ? 'date' : 'text',
          },
        ];

        if (this.giftcard.customer) {
          details.splice(2, 0, {
            label: this.$t('global.items.customer', 1),
            value: this.giftcard.customer.fullName,
          });
        }
        return details;
      }
      return null;
    },
  },
  methods: {
    deleteGiftCard() {
      modal('confirmation', {
        type: 'delete',
        item: this.filters.giftCardCode(this.giftcard.code),
      }).then(() => {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation deleteGiftcard($input: DeleteGiftcardInput!) {
                deleteGiftcard(input: $input) {
                  giftcard {
                    id
                  }
                }
              }
            `,
            variables: {
              input: {
                id: this.giftcardId,
              },
            },
            update(cache) {
              cache.evict({ id: 'ROOT_QUERY', fieldName: 'giftcards' });
              cache.gc();
            },
          })
          .then(() => {
            flash(
              this.$t('global.flash.item_deleted', {
                item: this.$t('global.items.giftcard', 1),
              }),
            );
            this.$router.push({ name: 'gift-cards' });
          });
      });
    },
  },
});
</script>
