import { SubscriptionPlan } from '@/types';

export const plansFeaturesKeys = {
  [SubscriptionPlan.Register]: {
    cash: ['products', 'customers', 'register', 'reports_and_insights'],
    scheduleManagement: [],
    overallTools: [],
    marketing: [],
  },
  [SubscriptionPlan.Appointments]: {
    cash: [],
    scheduleManagement: [
      'calendar',
      'online_bookings',
      'customers',
      'reminders',
      'calendar_integration',
      'mini_website',
    ],
    overallTools: [],
    marketing: [],
  },
  [SubscriptionPlan.Basic]: {
    cash: [],
    scheduleManagement: [
      'calendar',
      'online_bookings',
      'customers',
      'reminders',
      'calendar_integration',
      'mini_website',
      'customer_forms',
      'treatment_records',
    ],
    overallTools: [
      'register',
      'products',
      'online_payments',
      'reviews',
      'campaigns',
      'reports_and_insights',
    ],
    marketing: [],
  },
  [SubscriptionPlan.Pro]: {
    cash: [],
    scheduleManagement: [
      'calendar',
      'online_bookings',
      'customers',
      'reminders',
      'calendar_integration',
      'mini_website',
      'customer_forms',
      'treatment_records',
    ],
    overallTools: [
      'register',
      'products',
      'online_payments',
      'reviews',
      'campaigns',
      'reports_and_insights',
      'dynamic_pricing',
    ],
    marketing: [
      'online_discount_codes',
      'marketing_newsletters',
      'last_minute_discounts',
      'waiting_list',
      'rebook_reminders',
      'newsletter_builder',
      'customer_segmentation',
      'campaign_reports',
    ],
  },
};
