<template>
  <div v-if="typeof localValue === 'string'" :class="$style.base">
    <div :class="$style.form">
      <PageSplit :width="40">
        <template #left>
          <BaseInputLabel :text="t('promote.booking_widget.settings.color')" />
          <BaseColorLabel
            v-model="localValue"
            colorPicker
            selected
            mb
            v-test="'booking-settings-wizard-customColor'"
          />
          <BaseInputLabel :text="t('promote.booking_widget.link')" />
          <BaseCodeField
            :code="widgetUrl"
            :loading="loadingMicroSite"
            buttonColor="inverted"
            isUrl
            multiline
            v-test="'widget-styling-link'"
          />
        </template>
        <template #right>
          <div :class="$style.widget">
            <iframe
              ref="widgetPreviewIframe"
              :key="iframeUpdate"
              :class="$style.widgetPreview"
              :src="widgetPreviewUrl"
              v-test="'booking-settings-preview-iframe'"
            />
          </div>
        </template>
      </PageSplit>
    </div>
  </div>
</template>

<script setup lang="ts">
import PageSplit from '@/modules/PageSplit.vue';

import config from '@/config';

import { useI18n } from 'vue-i18n';
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { useCompanyStore } from '@/stores/company';

const { company } = useCompanyStore();
const { t } = useI18n();

const emit = defineEmits(['update:modelValue']);

const props = defineProps<{
  modelValue?: string | null;
}>();

const localValue = ref(props.modelValue);

watch(
  localValue,
  () => {
    emit('update:modelValue', localValue.value);
  },
  {
    deep: true,
  },
);

const microSiteQuery = gql`
  query getMicroSite {
    microSite {
      gaId
      id
      url
    }
  }
`;
const { result, loading: loadingMicroSite } = useQuery(microSiteQuery);
const widgetUrl = computed(() => {
  const microSiteUrl = result.value?.microSite.url || '';
  return microSiteUrl ? `${microSiteUrl}/widget_bookings/new` : '';
});

const widgetPreviewUrl = computed(() => {
  let baseUrl = `${config.bookingWidgetUrl}/?widget=booking&company=${company.publicId}`;
  baseUrl += `&language=${company.languagePublic}`;
  baseUrl += '&inline=true';
  baseUrl += `&color=${localValue.value}`;
  return encodeURI(baseUrl);
});

const widgetPreviewIframe = ref<HTMLIFrameElement | null>(null);
const iframeUpdate = ref(0);

const refreshIframe = () => {
  if (widgetPreviewIframe.value) {
    iframeUpdate.value += 1;
  }
};

watch(widgetPreviewUrl, () => {
  refreshIframe();
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form {
  flex-grow: 1;
}
.widget {
  position: relative;
  background-color: $grey-light;
  border-left: 1px solid $color-border;
  overflow: hidden;
  height: 100%;
  min-height: 600px;

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; // To place it over the text message
  }
}
</style>
