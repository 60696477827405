<template>
  <div :class="$style.wrap" v-intercom="`nav-main-${name}`">
    <component
      :is="link.routeName ? 'router-link' : 'div'"
      :to="link.routeName ? { name: link.routeName } : null"
      :active-class="$style.isActive"
      :class="[
        $style.base,
        {
          [$style.dark]: dark,
          [$style.isNavActive]: showMobileMenu,
          [$style.smallScreen]: $screen === 's',
          [$style.expanded]: isNavExpanded
        }
      ]"
      @click="onClick"
      v-test="`nav-main-${link.routeName || link.name}`"
    >
      <div :class="$style.inner">
        <div v-if="link.icon" :class="$style.icon">
          <BaseIcon :name="link.icon" color="inverted" />
        </div>
        <slot />
        <transition>
          <div v-if="isNavExpanded" :class="$style.label">
            {{ link.tooltip }}
          </div>
        </transition>
        <BaseNotification
          v-if="link.notification && link.notification.show"
          :position="
            isNavExpanded
              ? undefined
              : link.notification.value
                ? { right: 7, top: 7 }
                : { right: 12, top: 12 }
          "
          :inline="isNavExpanded"
          :ml="0.5"
          v-test="`nav-main-notification-${link.routeName}`"
        >
          {{ link.notification.value }}
        </BaseNotification>
      </div>
    </component>
  </div>
</template>

<script lang="ts">
import { usePageLayoutStore } from '@/stores/page-layout';
import { mapState } from 'pinia';
import { useVersionMismatchStore } from '@/stores/version-mismatch';
import { hideTooltip } from '@/helpers/ui';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    link: {
      type: Object,
      required: true,
    },
    dark: {
      type: Boolean,
      required: false,
    },
    premium: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    ...mapState(usePageLayoutStore, ['showMobileMenu', 'isNavExpanded']),
    ...mapState(useVersionMismatchStore, ['versionMismatchReload']),
    name() {
      return this.link.routeName || this.link.icon;
    },
  },
  methods: {
    onClick() {
      const pageLayoutStore = usePageLayoutStore();
      pageLayoutStore.showMobileMenu = false;

      hideTooltip();

      if (this.versionMismatchReload && this.link.routeName) {
        const route = this.$router.resolve({
          name: this.link.routeName,
        });

        if (route) {
          // This triggers a page reload
          window.location.replace(route.fullPath);
        }
      } else {
        this.$emit('click');
      }

      this.mixpanel.track(`Main nav - ${this.name} clicked`);
    },
  },
});
</script>

<style lang="scss" module>
.wrap {
  width: 100%;
}

.base {
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  display: block;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: black;
    opacity: 0.15;

    transition:
      width 0.12s $easeOutBack,
      height 0.12s $easeOutBack,
      opacity 0.12s $easeOutBack,
      border-radius 0.12s;
  }

  &.smallScreen:not(.isNavActive) {
    &:before {
      opacity: 0 !important;
    }
  }

  &.dark {
    &.isActive,
    &:not(.isActive) {
      &:not(.expanded) {
        &:before {
          opacity: 0.45;
        }
      }
    }

    &:not(.isActive) {
      @include hover {
        &:not(.expanded) {
          &:before {
            opacity: 0.6;
          }
        }

        &.expanded {
          &:before {
            opacity: 0.15;
          }
        }
      }
    }

    &.expanded:not(.isActive) {
      &:before {
        opacity: 0;
      }
    }
  }

  &:not(:hover):not(.isActive) {
    &:before {
      transition:
        width 0.15s $easeInBack height 0.15s $easeInBack,
        opacity 0.1s;
    }
  }

  &:not(:hover):not(.isActive):not(.dark),
  &.smallScreen {
    &:before {
      opacity: 0;
    }
  }

  &.isActive {
    &:before {
      width: calc(100% - 14px);
      height: calc(100% - 14px);
      opacity: 0.25;
      border-radius: $radius;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -6px;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid white;

      display: none;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  color: white;
  justify-content: flex-start;
  padding-left: 15px;

  .base:not(.smallScreen) & {
    height: $nav-width;
  }

  .base.smallScreen & {
    height: $nav-width - 4px;
  }
}

.icon {
  display: flex;
  align-items: center;
  transition: transform 0.3s $easeOutExpo;
}

.label {
  font-size: 12px;
  margin-left: 8px;
  white-space: nowrap;

  &:global(.v-enter-active) {
    transition: opacity 0.2s ease;
  }

  &:global(.v-leave-active) {
    transition: opacity 0.3s ease;
  }

  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
  }
}
</style>
