<template>
  <Modal size="medium" @clickBackground="close" @pressEscape="close">
    <div
      :class="[
        $style.base,
        {
          [$style.hasAside]: hasAside,
          [$style.small]: small,
          [$style.grey]: grey,
          [$style.isMobile]: $screen === 's',
          [$style.hasDetails]: !!details
        }
      ]"
      v-test="`modal-${testId || String($route.name)}`"
    >
      <ModalHeader
        :heading="heading"
        :label="label"
        :unclosable="unclosable"
        @close="close"
      />
      <component :is="useForm ? 'BaseForm' : 'div'" @submit="$emit('submit')">
        <transition>
          <div
            v-if="!loading"
            :class="[
              $style.center,
              {
                [$style.addMinHeight]: hasAside,
                [$style.noPadding]: noPadding
              }
            ]"
          >
            <BaseGrid container :noPadding="noPadding">
              <BaseGrid
                :size="hasAside ? 9 : 12"
                :mSize="hasAside ? 8 : 12"
                :noPadding="noPadding"
              >
                <div :class="$style.content">
                  <slot />
                </div>
              </BaseGrid>
              <BaseGrid v-if="hasAside" :size="3" :mSize="4" top>
                <div
                  :class="[$style.aside, { [$style.noFooter]: !$slots.footer }]"
                >
                  <slot name="aside" />
                  <div v-if="customer" :class="$style.customer">
                    <div v-if="appointmentModal" :class="$style.customerHeader">
                      <BaseHeading size="s" :mb="0.5">
                        {{ $t('global.items.customer', 1) }}
                      </BaseHeading>
                    </div>
                    <CustomerDetails
                      :customer="customer"
                      :appointmentStartAt="appointmentStartAt"
                      :class="$style.customerDetails"
                    />
                  </div>
                  <ModalDetails
                    v-if="details && (!appointmentModal || $screen === 's')"
                    :details="details"
                  />
                  <div
                    v-if="actions.length"
                    :class="$style.actions"
                    v-test="'base-modal-actions'"
                  >
                    <Actions
                      :actions="actions"
                      @select="$emit('action', $event)"
                    />
                  </div>
                </div>
              </BaseGrid>
            </BaseGrid>
          </div>
        </transition>

        <div v-show="loading" :class="$style.loader">
          <BaseSpinner />
        </div>

        <ModalFooter v-if="($slots.footer || $slots.footerSub) && !loading">
          <template #footerSub>
            <slot name="footerSub" />
          </template>
          <template #footer>
            <slot name="footer" />
          </template>
        </ModalFooter>
      </component>
    </div>
  </Modal>
</template>

<script lang="ts">
import Modal from '@/components/_shared/Modal.vue';
import Actions from '@/components/_shared/modal-actions/index.vue';
import ModalDetails from '@/components/_shared/ModalDetails.vue';
import CustomerDetails from '@/components/customer-details/index.vue';
import ModalFooter from '@/components/_shared/ModalFooter.vue';
import ModalHeader from '@/components/_shared/ModalHeader.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Modal,
    Actions,
    ModalDetails,
    CustomerDetails,
    ModalFooter,
    ModalHeader,
  },
  inheritAttrs: false,
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    testId: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Array,
    },
    customer: {
      type: Object,
    },
    label: {
      type: [String, Array],
      default: '',
    },
    unclosable: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    appointmentStartAt: {
      type: String,
      required: false,
    },
    parentRoute: {
      type: [String, Object],
      default: null,
    },
    useForm: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['action', 'close', 'submit'],
  computed: {
    hasAside() {
      return !!this.details || !!this.$slots.actions || !!this.customer;
    },
    appointmentModal() {
      return this.$route.name === 'appointment';
    },
  },
  methods: {
    close() {
      if (this.unclosable) {
        return;
      }

      if (this.parentRoute) {
        if (typeof this.parentRoute === 'string') {
          this.$router.push({
            name: this.parentRoute,
          });
        } else {
          this.$router.push(this.parentRoute);
        }
      } else {
        this.$emit('close');
      }
    },
    onBackgroundClick() {
      this.close();
    },
  },
});
</script>

<style lang="scss" module>
$padding: $spacing * 1.5;

.base {
  @include modal;
  width: 900px;

  &.small {
    width: 600px;
  }

  &.grey {
    background-color: $grey-light;
  }
}

$loadingHeight: 150px;
.center {
  position: relative;
  padding: $padding;

  .isMobile & {
    padding: $spacing;
  }

  &.noPadding {
    padding: 0;
  }

  &:global(.v-enter-active) {
    overflow: hidden;
    transition:
      max-height 1s $easeOutCirc,
      opacity 0.2s;
  }

  &:global(.v-enter-from) {
    max-height: $loadingHeight;
    opacity: 0;
  }

  &:global(.v-enter-to) {
    max-height: 1000px;
    opacity: 1;
  }
}

.loader {
  position: relative;
  height: $loadingHeight;
}

.content {
  .base.hasAside:not(.isMobile) & {
    padding-right: $padding;
  }
}

.aside {
  display: flex;
  flex-direction: column;

  .base:not(.isMobile) & {
    height: 100%;
    border-left: 1px solid $color-border;
    margin: $padding * -1 $padding * -1 $padding * -1 $spacing * -1;

    &.noFooter {
      height: calc(100% + #{$padding * 2});
    }
  }

  .base.isMobile & {
    border-bottom: 1px solid $color-border;
    border-radius: 0;
    margin: -$spacing;
    margin-bottom: 0;
  }

  &.noFooter {
    border-bottom-right-radius: $modal-radius;
  }
}

.addMinHeight {
  .base:not(.hasAside) & {
    min-height: 300px;
  }
}

.customer {
  .base.hasDetails & {
    border-bottom: 1px solid $color-border;
  }
}

.customerHeader {
  padding: $spacing;
  padding-bottom: 0;
}

.customerDetails {
  padding: $spacing * 0.5 0;
}

.actions {
  border-bottom-right-radius: $modal-radius;
  border-top: 1px solid $color-border;
  overflow-y: auto;

  .base.isMobile & {
    background-color: $grey-light;
    padding-bottom: $spacing;
  }
}
</style>
