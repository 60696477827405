<template>
  <transition>
    <div
      v-if="message"
      :class="$style.base"
      @click="close"
      v-test="'theFlashMessage'"
    >
      <BaseAlert color="success" size="s" :text="message" closable />
    </div>
  </transition>
</template>

<script lang="ts">
import eventBus from '@/event-bus';

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      message: '',
    };
  },
  watch: {
    message() {
      if (this.message) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.close();
        }, 3000);
      }
    },
  },
  methods: {
    close() {
      this.message = '';
    },
  },
  created() {
    eventBus.$on('flashMessage', (value) => {
      this.message = value;
    });
  },
  beforeUnmount() {
    eventBus.$off('flashMessage');
  },
});
</script>

<style lang="scss" module>
.base {
  position: fixed;
  top: $spacing * 0.5;
  left: 50%;
  display: flex;
  align-items: center;
  box-shadow: $shadow;
  border-radius: $radius;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 99999;

  &:global(.v-enter-active),
  &:global(.v-leave-active) {
    transition:
      transform 0.2s $easeOutBack,
      opacity 0.1s linear;
  }
  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    transform: translateX(-50%) scale(0.5);
    opacity: 0;
  }
}
</style>
