import Become from '@/modules/Become.vue';
import loggedInRoutes from './logged-in';
import loggedOutRoutes from './logged-out';

export default [
  ...loggedOutRoutes,
  ...loggedInRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: {
      template: '<template />',
    },
  },
  {
    path: '/become',
    name: 'become',
    component: Become,
  },
];
