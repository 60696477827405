<template>
  <div>
    <router-view />
    <LastMinuteDiscount mb />
    <DiscoverDynamicPricing mb />
    <BaseHeading mb>
      {{ $t('marketing.discounts.title') }}
    </BaseHeading>
    <PageHeader>
      <template #right>
        <BaseSearch
          v-model="variables.search"
          debounce
          v-test="'discounts-search'"
        />
        <BaseButton
          :routerLink="{ name: 'create-discount-code' }"
          @click="
            mixpanel.track('New discount code clicked', { location: 'actions' })
          "
          v-test="'createDiscountCodeButton'"
        >
          {{ $t('marketing.discounts.new_discount_code') }}
        </BaseButton>
      </template>
    </PageHeader>
    <BaseTable
      v-if="discountCodes.length"
      :headers="[
        $t('global.code'),
        $t('global.type'),
        $t('global.used'),
        $t('global.status')
      ]"
      :rows="
        discountCodes.map((c) => ({
          id: c.id,
          actions: ['edit', 'delete'],
          routerLink: {
            name: 'discount-details',
            params: { discountId: c.id }
          },
          cells: [
            c.code,
            $t(`marketing.discounts.category.${c.category}`),
            `${c.usedCount} ${$t('global.items.customer', c.usedCount === 1 ? 1 : 2)}`,
            {
              label: c.state.toLowerCase()
            }
          ]
        }))
      "
      @action="onOptionClick"
      v-test="'discounts-table'"
    />
    <EmptyPageContent
      v-show="!loading && !discountCodes.length"
      imageName="discounts"
      :text="
        showEmptyPageContent
          ? {
              title: $t('marketing.discounts.title'),
              description: $t('marketing.discounts.empty-list.description'),
              btnPrimary: filters.capitalize(
                $t('marketing.discounts.new_discount_code')
              )
            }
          : null
      "
      @click="onEmptyAction"
      v-test="'discountsOnboarding'"
    />
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import LastMinuteDiscount from './LastMinuteDiscount.vue';
import DiscoverDynamicPricing from './DiscoverDynamicPricing.vue';
import gql from 'graphql-tag';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';
import PageHeader from '@/modules/PageHeader.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LastMinuteDiscount,
    PageHeader,
    EmptyPageContent,
    LoadMore,
    DiscoverDynamicPricing,
  },
  inject: ['mixpanel'],
  setup() {
    const { discountCodes, loading, variables, allDataFetched, fetchMore } =
      usePagination({
        fieldName: 'discountCodes',
        query: gql`
          query getDiscountCodes(
            $search: String
            $pagination: PaginationAttributes
          ) {
            discountCodes(search: $search, pagination: $pagination) {
              id
              code
              category
              usedCount
              state
            }
          }
        `,
        variables: {
          search: '',
        },
      });
    return {
      discountCodes,
      loading,
      variables,
      fetchMore,
      allDataFetched,
      filters,
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    showEmptyPageContent() {
      return !this.variables.search && !this.discountCodes.length;
    },
  },
  methods: {
    onEmptyAction() {
      this.mixpanel.track('New discount code clicked', {
        location: 'empty list',
      });
      this.$router.push({ name: 'create-discount-code' });
    },
    onOptionClick(item) {
      if (item.option === 'edit') {
        this.$router.push({
          name: 'edit-discount-code',
          params: { discountId: item.id },
        });
      }

      if (item.option === 'delete') {
        this.$router.push({
          name: 'delete-discount-code',
          params: { discountId: item.id },
        });
      }
    },
  },
});
</script>
