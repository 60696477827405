import { OS } from '@/user-context';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useNativeAppStore = defineStore('nativeApp', () => {
  const isNativeApp = ref(document.body.classList.contains('is-native-app'));
  const isNativeAppIOS = computed(() => isNativeApp.value && OS === 'iOS');

  return {
    isNativeApp,
    isNativeAppIOS,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNativeAppStore, import.meta.hot));
}
