<template>
  <div>
    <Row
      :label="label"
      :selected="modelValue"
      :items="allItems"
      isHeader
      :expanded="expandedHeader"
      :gray="gray"
      @toggle="toggleAll"
      v-test="'_base-grouped-checkboxes-header'"
    >
      <div v-if="isGrouped">
        <Row
          v-for="(group, index) in data"
          :key="`group-${index}`"
          :label="group.label"
          :color="group.color"
          :selected="modelValue"
          :items="group.items"
          isGroupParent
          @toggle="toggleGroup(group.items, $event)"
          v-test="'_base-grouped-checkboxes-group'"
        >
          <Row
            v-for="(item, index) in group.items"
            :key="`item-${index}`"
            :label="item.label"
            :color="group.color"
            :selected="modelValue"
            :item="item"
            isGroupChild
            :disabled="fixedValue && fixedValue.includes(item.id)"
            @toggle="toggleItem(item.id, $event)"
            v-test="'_base-grouped-checkboxes-item'"
          />
        </Row>
      </div>
      <div v-else>
        <Row
          v-for="(item, index) in allItems"
          :key="`item-ungrouped-${index}`"
          :label="item.label"
          :color="item.color"
          :selected="modelValue"
          :item="item"
          :disabled="fixedValue && fixedValue.includes(item.id)"
          @toggle="toggleItem(item.id, $event)"
          v-test="'_base-grouped-checkboxes-item'"
        />
      </div>
      <Row
        v-if="footer"
        :label="footer.label"
        isFooter
        :gray="gray"
        :icon="footer.icon"
        :iconColor="footer.iconColor"
        @toggle="$emit('footerClick')"
        v-test="'_base-grouped-checkboxes-footer'"
      />
    </Row>
  </div>
</template>

<script lang="ts">
import Row from './Row.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseGroupedCheckboxList',
  components: {
    Row,
  },
  inheritAttrs: false,
  props: {
    gray: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    footer: {
      type: Object,
      default: null,
    },
    expandedHeader: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      required: true,
      /*
        Required value: an array containing numbers.
      */
      validator: (value) =>
        !value.length || !value.find((item) => typeof item !== 'number'),
    },
    fixedValue: {
      type: Array,
      required: false,
      validator: (value) =>
        !value.length || !value.find((item) => typeof item !== 'number'),
    },
    data: {
      type: Array,
      required: true,
      validator: (value) => {
        /*
          Required structure: [{ id: Number, label: String }]
          or  [{ label: String, items: [{ id: Number, label: String }] }]
        */
        let isValid = true;

        value.forEach((item) => {
          if (!item.label) {
            isValid = false;
          }

          if (!item.items && !item.id) {
            isValid = false;
          }

          if (item.items?.find((child) => !child.id || !child.label)) {
            isValid = false;
          }
        });

        return isValid;
      },
    },
  },
  emits: ['update:modelValue', 'footerClick'],
  computed: {
    isGrouped() {
      return !!this.data.find((item) => item.items);
    },
    allItems() {
      return this.isGrouped
        ? this.data
            .map((group) => group.items)
            .reduce((acc, val) => acc.concat(val), [])
        : this.data;
    },
  },
  methods: {
    toggleItem(itemId, isChecked) {
      let newValue;

      if (isChecked) {
        newValue = [...this.modelValue, itemId];
      } else {
        newValue = this.modelValue.filter((id) => id !== itemId);
      }

      this.emit(newValue);
    },
    toggleGroup(items, isChecked) {
      const itemIds = items.map((item) => item.id);
      let newValue = this.modelValue.filter((id) => !itemIds.includes(id));

      if (isChecked) {
        newValue = [...newValue, ...itemIds];
      }

      this.emit(newValue);
    },
    toggleAll(isChecked) {
      this.emit(isChecked ? this.allItems.map((item) => item.id) : []);
    },
    emit(newValue) {
      let emittedValue;

      // Make sure items in fixedValue are always selected
      // Merge selected items with the fixed items, and use Set to remove duplicates

      if (this.fixedValue?.length) {
        emittedValue = Array.from(new Set([...newValue, ...this.fixedValue]));
      } else {
        emittedValue = newValue;
      }
      this.$emit('update:modelValue', emittedValue);
    },
  },
});
</script>
