<template>
  <BaseModal
    :heading="$t('admin.treatwell.address_modal.heading')"
    useForm
    small
    testId="task-provide-company-info"
    @submit="submit"
    @close="close"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <div :class="$style.inputGroup">
        <div :class="$style.name">
          <BaseCard gray mb fullWidth>
            <BaseInput
              v-model="companyInfo.name"
              :label="$t('onboarding.new_tasks.company_info.company_name')"
              required
              :minLength="2"
              v-test="'company-name-input'"
            />
          </BaseCard>
        </div>
        <img src="/img/tasks/setup_company_details.svg" :class="$style.img" />
      </div>
      <BaseCard gray>
        <div :class="$style.inputGroup">
          <BaseInput
            v-model="companyInfo.postalcode"
            :label="$t('onboarding.new_tasks.company_info.postal')"
            required
            v-test="'company-postal-input'"
          />
          <BaseInput
            v-model="companyInfo.address"
            required
            :mt="$screen === 's' ? 1 : 0"
            :label="$t('onboarding.new_tasks.company_info.address')"
            v-test="'company-address-input'"
          />
        </div>
        <div :class="$style.inputGroup">
          <BaseInput
            v-model="companyInfo.city"
            required
            mt
            :label="$t('onboarding.new_tasks.company_info.city')"
            v-test="'company-city-input'"
          />
          <BaseInput
            v-model="companyInfo.phone"
            required
            type="tel"
            :minLength="9"
            :maxLength="16"
            :error="
              isSubmitted && showPhoneError
                ? `*${filters.capitalize($t('global.validations.invalid', { value: $t('global.phone_number') }))}`
                : null
            "
            mt
            :label="$t('onboarding.new_tasks.company_info.phone_number')"
            v-test="'company-phone-input'"
          />
        </div>
      </BaseCard>
    </div>
    <template #footer>
      <BaseButton color="inverted" @click="close">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton :loading="loading" submitForm v-test="'company-info-submit'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import { reactive, ref, watch } from 'vue';
import filters from '@/filters';
import { useRouter } from 'vue-router';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const router = useRouter();

const close = () => {
  router.push({
    name: 'treatwell-admin',
  });
};

const { company, updateCompany } = useCompanyStore();
const loading = ref(false);
const isSubmitted = ref(false);
const showPhoneError = ref(false);

const companyInfo = reactive({
  name: company.name,
  postalcode: company.postalcode,
  city: company.city,
  phone: company.phone,
  address: company.address,
});

watch(
  () => companyInfo.phone,
  () => {
    showPhoneError.value = false;
  },
);

const submit = () => {
  loading.value = true;
  isSubmitted.value = true;

  updateCompany({
    ...companyInfo,
    internalName: companyInfo.name,
  })
    .then(() => {
      loading.value = false;
      flash(t('global.flash.company_updated'));
      close();
    })
    .catch((errors) => {
      if (
        errors?.length &&
        errors.find((error) => error.attribute === 'phone')
      ) {
        showPhoneError.value = true;
      }
      loading.value = false;
    });
};
</script>

<style lang="scss" module>
.inputGroup {
  margin-bottom: $spacing * 0.5;
}

.base.smallScreen {
  img {
    display: none;
  }
}

.base:not(.smallScreen) {
  .inputGroup {
    display: flex;
    & > * {
      &:not(:last-child) {
        margin-right: $spacing;
      }
      width: 50%;
    }
  }
}

.name {
  width: 100%;
  align-self: end;
}

.img {
  height: 150px;
  width: 200px;
  margin-left: $spacing;
}
</style>
