<template>
  <div>
    <CreateFilterGroup
      v-if="showCreateModal"
      @close="showCreateModal = false"
    />
    <FilterLabels :filters="allFilters" @removeFilter="onRemoveFilter" />
    <BaseText link @click="toggleFilters" v-test="'marketingAddFilter'">
      +{{ $t('global.actions.add_filter') }}
    </BaseText>
    <div :class="$style.wrapper">
      <BaseText
        mt
        link
        @click="showCreateModal = true"
        v-test="'onCreateFilterGroup'"
      >
        {{ $t('marketing.email.sections.save_filter_group') }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import FilterLabels from '@/components/customer-filters/FilterLabels.vue';
import CreateFilterGroup from '@/modules/marketing/modals/CreateFilterGroup.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    FilterLabels,
    CreateFilterGroup,
  },
  data() {
    return {
      showCreateModal: false,
    };
  },
  computed: {
    allFilters() {
      return this.$store.getters['marketing/allFilters'];
    },
  },
  methods: {
    toggleFilters() {
      this.$store.commit('marketing/TOGGLE_FILTERS');
    },
    setFilter(filter) {
      if (filter) {
        this.$store.commit('marketing/SET_FILTER', filter);
      }
    },
    onRemoveFilter(filter) {
      this.$store.commit('marketing/REMOVE_FILTER', filter);
    },
  },
});
</script>

<style lang="scss" module>
.wrapper {
  border-top: 1px solid $color-border;
  margin-top: $spacing;
}
</style>
