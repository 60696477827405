<template>
  <BaseModal
    :heading="$t('account.change_password')"
    small
    @close="$emit('close')"
  >
    <BaseInput
      v-model="password"
      required
      :label="$t('account.password_current')"
      type="password"
      name="password"
      mb
      :error="
        wrongPassword
          ? filters.capitalize(
              $t('global.validations.invalid', { value: $t('global.password') })
            )
          : ''
      "
      v-test="'account-password-current'"
    />
    <BaseInput
      v-model="passwordNew"
      newPassword
      :label="$t('account.password_new')"
      type="password"
      mb
      v-test="'account-password-new'"
    />
    <BaseInput
      v-model="passwordConfirm"
      required
      :sameAs="passwordNew"
      :label="$t('account.password_repeat')"
      type="password"
      mb
      v-test="'account-password-repeat'"
    />
    <BaseAlert :text="$t('account.password_hint')" color="warning" />
    <template #footer>
      <BaseButton
        :loading="isLoading"
        @click="submit"
        v-test="'account-password-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import { flash } from '@/helpers/ui';
import useVuelidate from '@vuelidate/core';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';
import { logValidationError } from '@/helpers/datadog';
import { logout } from '@/helpers/session';

export default defineComponent({
  emits: ['close'],
  setup() {
    return { v$: useVuelidate(), filters };
  },
  data() {
    return {
      password: '',
      passwordNew: '',
      passwordConfirm: '',
      isLoading: false,
      wrongPassword: false,
    };
  },
  methods: {
    submit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;

      const { updateUser } = useUserStore();

      const input = {
        currentPassword: this.password,
        password: this.passwordNew,
        passwordConfirmation: this.passwordConfirm,
      };

      updateUser(input)
        .then(() => {
          flash(this.$t('global.flash.password_updated'));
          logout();
        })
        .catch((errors) => {
          this.wrongPassword = errors?.includes('current_password') || false;

          if (!this.wrongPassword) {
            logValidationError('updateUser', errors, input);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>
