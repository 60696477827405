<template>
  <div :class="$style.base">
    <BaseInput
      v-show="discountType === DiscountType.Perc"
      v-model="item.discount"
      :label="$t('global.discount')"
      type="number"
      unitLabel="percentage"
      :decimals="2"
      :maxValue="100"
      :disabled="props.item.originalPrice === 0"
      @focus="percInputFocussed = true"
      @blur="percInputFocussed = false"
      v-test="'register-order-item-discount'"
    />
    <BaseInput
      v-show="discountType === DiscountType.Euro"
      v-model="discountPrice"
      :label="$t('global.discount')"
      type="currency"
      :maxValue="props.item.originalPrice"
      :disabled="props.item.originalPrice === 0"
      @focus="priceInputFocussed = true"
      @blur="priceInputFocussed = false"
      v-test="'register-order-item-discount-price'"
    />
    <div :class="$style.discountType">
      <BaseSwitch
        v-model="discountType"
        :options="[
          {
            value: DiscountType.Perc,
            label: '%'
          },
          {
            value: DiscountType.Euro,
            label: '€'
          }
        ]"
        v-test="'register-order-item-discount-toggle'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OrderItem } from '@/modules/register/stores/order';
import { useStorage } from '@vueuse/core';

const props = defineProps<{
  item: OrderItem;
}>();

enum DiscountType {
  Euro = 'EUR',
  Perc = 'PERC',
}

const discountType = useStorage<DiscountType>(
  'registerDiscountType',
  DiscountType.Perc,
);
const percInputFocussed = ref(false);
const priceInputFocussed = ref(false);

const discountPrice = computed({
  get() {
    return props.item.originalPrice * (props.item.discount / 100);
  },
  set(value) {
    if (priceInputFocussed.value) {
      props.item.discount = (value / props.item.originalPrice) * 100;
    }
  },
});

watch(
  () => props.item.price,
  () => {
    if (percInputFocussed.value || priceInputFocussed.value) {
      return;
    }

    if (props.item.price === 0 || props.item.originalPrice === 0) {
      props.item.discount = 0;
    } else {
      props.item.discount =
        100 - (props.item.price! * 100) / props.item.originalPrice;
    }
  },
);

const updatePrice = () =>
  (props.item.price = Math.round(
    props.item.originalPrice! * (1 - props.item.discount / 100),
  ));

//TODO: Write test for changing the price discount

watch(discountPrice, () => {
  if (!priceInputFocussed.value) {
    return;
  }

  updatePrice();
});

watch(
  () => props.item.discount,
  () => {
    if (!percInputFocussed.value) {
      return;
    }

    updatePrice();
  },
);

if (props.item.discount) {
  updatePrice();
}
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: flex-end;
}

.discountType {
  position: relative;
  z-index: 1;
  margin-left: -11px;
}
</style>
