<template>
  <ModuleBase noBorder gray>
    <PageWrap
      :heading="$t('admin.nav.links.calendar')"
      :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]"
    >
      <BaseCard :mb="2">
        <div :class="$style.fieldsGrid">
          <div :class="$style.fieldsGridSlotsContainer">
            <BaseDropdown
              v-model="formData.settings.agenda.slotInterval"
              :label="$t('admin.calendar.slot_interval')"
              :info="$t('admin.calendar.slot_info')"
              :options="
                slotIntervalOptions.map((value) => ({
                  label: `${value} ${$t('global.items.minute_short', value)}`,
                  value
                }))
              "
              v-test="'slot-interval-select'"
            />
          </div>
          <div :class="$style.fieldsGridAppointmentContainer">
            <BaseDropdown
              v-model="formData.settings.agenda.colour"
              :label="$t('admin.calendar.appointment_color')"
              :info="$t('admin.calendar.appointment_color_description')"
              :options="[
                {
                  label: $t('admin.calendar.per_category'),
                  value: Colour.Category
                },
                {
                  label: $t('admin.calendar.per_resource'),
                  value: Colour.Resource
                }
              ]"
              v-test="'appointment-color-select'"
            />
          </div>
          <div :class="$style.fieldsGridSlotViewer">
            <BaseInputLabel
              :text="$t('admin.calendar.appointmen_slot_viewer_description')"
            />
            <SlotIntervalCalendarExample
              :interval="formData.settings.agenda.slotInterval"
            />
          </div>
        </div>
        <BaseCard mt mb gray>
          <BaseCheckbox
            v-model="formData.settings.agenda.hideClosedResources"
            :label="$t('admin.calendar.hide_employees_schedule')"
            :description="
              $t('admin.calendar.hide_employees_schedule_description')
            "
            v-test="'hide-employees-checkbox'"
          />
        </BaseCard>

        <BaseCard gray>
          <BaseCheckbox
            v-model="formData.settings.agenda.appointmentEndBuffer"
            :label="$t('admin.calendar.gap_buffer')"
            :description="$t('admin.calendar.gap_buffer_description')"
            v-test="'appointment-buffer-checkbox'"
          />
          <BaseText
            inline
            link
            size="s"
            :href="$t('admin.calendar.gap_buffer_description_learn_more_url')"
          >
            {{ $t('admin.calendar.gap_buffer_description_learn_more') }}
          </BaseText>
          <BaseDropdown
            v-if="formData.settings.agenda.appointmentEndBuffer"
            v-model="formData.settings.agenda.appointmentEndBufferTime"
            :label="$t('admin.calendar.gap_buffer_label')"
            :options="
              gapBufferOptions.map((value) => ({
                label: `${value} ${$t('global.items.minute_short', value)}`,
                value
              }))
            "
            mt
            v-test="'appointment-buffer-time-select'"
          />
        </BaseCard>
      </BaseCard>

      <BaseAccordion
        ref="baseAccordion"
        v-model="shareAgendaAccordion"
        :titles="[$t('admin.calendar.share_agenda')]"
        cardStyle
        :mb="2"
      >
        <template #1>
          <BaseAlert
            :text="$t('admin.calendar.share_agenda_alert')"
            color="warning"
            mb
          />
          <BaseText mb>{{
            $t('admin.calendar.share_agenda_description')
          }}</BaseText>
          <BaseCodeField
            v-if="user"
            :code="user.calendarIcsUrl"
            buttonColor="inverted"
            v-test="'share-agenda-url'"
          />
        </template>
      </BaseAccordion>

      <BaseCard :heading="$t('admin.calendar.changes_by_customer')" :mb="2">
        <BaseCard gray mb>
          <BaseCheckbox
            v-model="formData.settings.agenda.allowCancellations"
            :label="$t('admin.calendar.online_cancel_checkbox_label')"
            :description="
              $t('admin.calendar.online_cancel_checkbox_description')
            "
            v-test="'allow-cancelation-checkbox'"
          />
          <BaseDropdown
            v-if="formData.settings.agenda.allowCancellations"
            v-model="formData.settings.agenda.cancellationHours"
            :label="$t('admin.calendar.online_cancel_dropdown_label')"
            :options="
              advanceTime.map((value) => ({
                label: `${value} ${$t(`global.items.hour_short`, value)}`,
                value
              }))
            "
            mt
            v-test="'allow-cancelation-time-select'"
          />
        </BaseCard>
        <BaseCard gray>
          <BaseCheckbox
            v-model="formData.settings.agenda.allowRescheduling"
            :label="$t('admin.calendar.online_reschedule_checkbox_label')"
            :description="
              $t('admin.calendar.online_reschedule_checkbox_description')
            "
            v-test="'allow-reschedule-checkbox'"
          />
          <BaseDropdown
            v-if="formData.settings.agenda.allowRescheduling"
            v-model="formData.settings.agenda.reschedulingHours"
            :label="$t('admin.calendar.online_reschedule_dropdown_label')"
            :options="
              advanceTime.map((value) => ({
                label: `${value} ${$t(`global.items.hour_short`, value)}`,
                value
              }))
            "
            mt
            v-test="'allow-reschedule-time-select'"
          />
        </BaseCard>
      </BaseCard>

      <BaseCard :heading="$t('admin.calendar.no_show')" :mb="2">
        <BaseCheckbox
          v-model="formData.settings.communication.appointmentNoShow"
          :label="$t('admin.calendar.no_show_label')"
          :description="$t('admin.calendar.no_show_description')"
          v-test="'no-show-checkbox'"
        />
      </BaseCard>

      <BaseCard :heading="$t('admin.calendar.reminder_settings')" :mb="2">
        <BaseCard gray mb>
          <BaseCheckbox
            v-model="formData.settings.reminders.emailReminderEnabled"
            :label="$t('admin.calendar.email_reminder_checkbox_label')"
            :description="
              $t('admin.calendar.email_reminder_checkbox_description')
            "
            v-test="'email-reminder-checkbox'"
          />
          <BaseDropdown
            v-if="formData.settings.reminders.emailReminderEnabled"
            v-model="formData.emailReminderHours"
            :label="$t('admin.calendar.email_reminder_dropdown_label')"
            :options="
              reminderFrequencyOptions.map((value) => ({
                label: `${value} ${$t(`global.items.hour_short`, value)}`,
                value
              }))
            "
            mt
            v-test="'email-reminder-time-select'"
          />
        </BaseCard>

        <BaseCard gray>
          <BaseCheckbox
            v-model="formData.settings.reminders.smsReminderEnabled"
            :label="$t('admin.calendar.text_message_reminder_checkbox_label')"
            :description="
              $t('admin.calendar.text_message_reminder_checkbox_description')
            "
            v-test="'sms-reminder-checkbox'"
          />
          <BaseDropdown
            v-if="formData.settings.reminders.smsReminderEnabled"
            v-model="formData.smsReminderHours"
            :label="$t('admin.calendar.text_message_reminder_dropdown_label')"
            :options="
              reminderFrequencyOptions.map((value) => ({
                label: `${value} ${$t(`global.items.hour_short`, value)}`,
                value
              }))
            "
            mt
            v-test="'sms-reminder-time-select'"
          />
        </BaseCard>
      </BaseCard>
      <template #footer>
        <BaseButton
          :loading="isSubmitting"
          @click="submitForm"
          v-test="'calendar-settings-submit-btn'"
        >
          {{ $t('global.actions.save') }}
        </BaseButton>
      </template>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue';
import type {
  SettingsAgenda,
  SettingsCommunication,
  SettingsReminders,
} from '@/types';
import { Colour } from '@/types';
import ModuleBase from '@/modules/ModuleBase.vue';
import PageWrap from '../PageWrap.vue';
import { useCompanyStore } from '@/stores/company';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import SlotIntervalCalendarExample from './SlotIntervalCalendarExample.vue';
import unleash from '@/unleash';

type RemoveNull<T> = NonNullable<T> | undefined;
const slotIntervalOptions = unleash.isEnabled('IntervalSlot25')
  ? [5, 10, 15, 25, 30]
  : ([5, 10, 15, 30] as const);
type SlotIntervalOptions = (typeof slotIntervalOptions)[number];

const { company, updateCompany } = useCompanyStore();
const { user } = useUserStore();
const isSubmitting = ref(false);
const shareAgendaAccordion = ref([]);
const { t } = useI18n();

const gapBufferOptions = [5, 10, 15, 20, 30, 45, 60];
const advanceTime = [0, 1, 3, 6, 12, 24, 48, 72];
const reminderFrequencyOptions = [1, 2, 4, 8, 12, 24, 48, 72];

type FormData = {
  settings: {
    agenda: {
      allowCancellations: SettingsAgenda['allowCancellations'];
      allowRescheduling: SettingsAgenda['allowRescheduling'];
      appointmentEndBuffer: SettingsAgenda['appointmentEndBuffer'];
      appointmentEndBufferTime: SettingsAgenda['appointmentEndBufferTime'];
      cancellationHours: SettingsAgenda['cancellationHours'];
      colour: SettingsAgenda['colour'];
      hideClosedResources: SettingsAgenda['hideClosedResources'];
      reschedulingHours: SettingsAgenda['reschedulingHours'];
      slotInterval: SlotIntervalOptions;
    };
    communication: {
      appointmentNoShow: SettingsCommunication['appointmentNoShow'];
    };
    reminders: {
      emailReminderEnabled: SettingsReminders['emailReminderEnabled'];
      smsReminderEnabled: SettingsReminders['smsReminderEnabled'];
    };
  };
  emailReminderHours?: number;
  smsReminderHours?: number;
};

const formData = reactive<FormData>({
  settings: {
    agenda: {
      allowCancellations: company.settings.agenda.allowCancellations,
      allowRescheduling: company.settings.agenda.allowRescheduling,
      appointmentEndBuffer: company.settings.agenda.appointmentEndBuffer,
      appointmentEndBufferTime:
        company.settings.agenda.appointmentEndBufferTime,
      cancellationHours: company.settings.agenda.cancellationHours,
      colour: company.settings.agenda.colour,
      hideClosedResources: company.settings.agenda.hideClosedResources,
      reschedulingHours: company.settings.agenda.reschedulingHours,
      slotInterval: company.settings.agenda.slotInterval as SlotIntervalOptions,
    },
    communication: {
      appointmentNoShow: company.settings.communication.appointmentNoShow,
    },
    reminders: {
      emailReminderEnabled: company.settings.reminders?.emailReminderEnabled,
      smsReminderEnabled: company.settings.reminders?.smsReminderEnabled,
    },
  },
  emailReminderHours: company.settings.reminders
    ?.emailReminderHours as RemoveNull<SettingsReminders['emailReminderHours']>,
  smsReminderHours: company.settings.reminders?.smsReminderHours as RemoveNull<
    SettingsReminders['smsReminderHours']
  >,
});

const submitForm = () => {
  isSubmitting.value = true;

  updateCompany(formData)
    .then(() => {
      flash(t('global.flash.settings_updated'));
    })
    .finally(() => {
      isSubmitting.value = false;
    });
};
</script>

<style lang="scss" module>
.fieldsGrid {
  display: grid;
  column-gap: $spacing * 2;
  grid-template-columns: 1fr 1fr;
  row-gap: $spacing;
  grid-template-areas:
    'slots slot-interval'
    'appointment slot-interval'
    '. slot-interval';

  .base.smallScreen & {
    grid-template-columns: 1fr;
    grid-template-areas:
      'slots'
      'slot-interval'
      'appointment';
  }
}

.fieldsGridSlotsContainer {
  grid-area: slots;
}

.fieldsGridAppointmentContainer {
  grid-area: appointment;
}

.fieldsGridSlotViewer {
  grid-area: slot-interval;
}
</style>
