<template>
  <div :class="$style.base">
    <div :class="$style.nonPrintable">
      <BaseGrid container mb>
        <BaseGrid :size="$screen === 's' ? 12 : 8">
          <DateSelect
            :label="$t('global.actions.period')"
            @select="onDateSelect"
            v-test="'dateSelect'"
          />
        </BaseGrid>
        <BaseGrid v-if="$screen !== 's'" alignRight alignCenter :size="4">
          <BaseExport :urlTemplate="exportUrl" mr v-test="'exportButton'" />
          <BaseButton @click="onPrint" v-test="'printButton'">
            {{ $t('global.print') }}
          </BaseButton>
        </BaseGrid>
      </BaseGrid>
    </div>
    <BaseCard v-show="!$apollo.loading" mb>
      <TimeKeepingTable :report="employeeReport" />
    </BaseCard>
    <BaseCard v-show="!$apollo.loading" mb>
      <div>
        <SalesTable :report="employeeReport" class="table" />
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import gql from 'graphql-tag';
import DateSelect from '../components/DateSelect.vue';
import TimeKeepingTable from '@/modules/reports/employees/TimeKeepingTable.vue';
import SalesTable from '@/modules/reports/employees/SalesTable.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    SalesTable,
    TimeKeepingTable,
    DateSelect,
  },
  data() {
    return {
      employeeReport: {
        data: [],
        totals: {},
      },
      startDate: dayjs().format(),
      endDate: dayjs().format(),
    };
  },
  apollo: {
    employeeReport: {
      query: gql`
        query employeeReport(
          $startDate: DateTime
          $endDate: DateTime
          $locationId: ID
          $dataScope: DataScope
        ) {
          employeeReport(
            startDate: $startDate
            endDate: $endDate
            locationId: $locationId
            dataScope: $dataScope
          ) {
            data {
              appointments
              averageOrderValueExcl
              averageOrderValueIncl
              bookedHours
              discount
              hours
              id
              name
              orders
              serviceCount
              serviceTotal
              serviceTotalExcl
              servicesPerHour
              servicesPerHourExcl
              productCount
              productTotal
              productTotalExcl
              productsPerHour
              productsPerHourExcl
              utilization
              workedHours
            }
            totals {
              appointments
              averageOrderValueExcl
              averageOrderValueIncl
              bookedHours
              discount
              hours
              id
              orders
              serviceCount
              serviceTotal
              serviceTotalExcl
              servicesPerHour
              servicesPerHourExcl
              productCount
              productTotal
              productTotalExcl
              productsPerHour
              productsPerHourExcl
              utilization
              workedHours
            }
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          locationId: this.locationId,
          dataScope: this.dataScope,
        };
      },
    },
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    exportUrl() {
      return `reports/employee_reports.extension?start=${this.startDate.slice(0, 10)}&end=${this.endDate.slice(0, 10)}}`;
    },
  },
  methods: {
    onDateSelect(dates) {
      this.startDate = dates.startDate;
      this.endDate = dates.endDate;
    },
    onPrint() {
      window.print();
    },
  },
});
</script>

<style lang="scss" module>
.base {
  min-height: calc(100% + 48px);
  padding: $spacing * 1.5;
  margin: -$spacing * 1.5;
}

@media print {
  .nonPrintable {
    display: none;
  }
}
</style>
