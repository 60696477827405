<template>
  <BaseCard
    v-if="result?.dac7Pdfs?.length"
    :heading="$t('admin.billing.dac7')"
    v-test="'dac7pdf-card'"
  >
    <div :class="$style.card">
      <BaseText
        v-for="dac7pdf in result.dac7Pdfs"
        :key="dac7pdf.year"
        :href="dac7pdf.url"
        iconBefore="pdf"
        download
        v-test="'dac7pdf'"
      >
        {{ dac7pdf.year }}
      </BaseText>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

const GET_DAC_7_PDFS = gql`
  query dac7Pdfs {
    dac7Pdfs {
      year
      url
    }
  }
`;

const { result } = useQuery(GET_DAC_7_PDFS);
</script>

<style lang="scss" module>
.card {
  display: flex;
  gap: $spacing;
  flex-wrap: wrap;
}
</style>
