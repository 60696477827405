<template>
  <div
    v-if="groupedLinks.length"
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div
      v-show="$screen === 's'"
      :class="$style.header"
      @click="isExpanded = !isExpanded"
    >
      <BaseText bold>
        {{ activeItem ? activeItem.label : 'Home' }}
      </BaseText>
      <BaseIcon name="chevron-down" />
    </div>

    <transition>
      <div v-show="$screen !== 's' || isExpanded" :class="$style.list">
        <div
          v-for="group in groupedLinks"
          :key="group.name"
          :class="$style.navGroup"
        >
          <div
            v-if="navGroups.find((g) => g.name === group.name)"
            :class="$style.navHeading"
          >
            <BaseHeading size="xs">
              {{ navGroups.find((g) => g.name === group.name).label }}
            </BaseHeading>
          </div>
          <router-link
            v-for="link in group.items"
            :key="`route-${link.name}`"
            :to="{ name: link.name }"
            :class="$style.navItem"
            :active-class="$style.isActive"
            @click="handleRouterClick(link)"
            v-test="`nav-${link.name}`"
            v-intercom="`nav-${link.name}`"
          >
            {{ link.label }}
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { groupBy } from '@/helpers/formatting';
import { isRouteAllowed } from '@/router/helpers';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    navItems: {
      type: Object,
      validator: (options) =>
        options.items &&
        !options.items.find(
          (item) => item.show === undefined || !item.name || !item.label,
        ),
      default: () => ({
        groups: [],
        items: [],
      }),
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  watch: {
    '$route.name'() {
      this.isExpanded = false;
    },
  },
  computed: {
    links() {
      return this.navItems.items.filter((link) => isRouteAllowed(link.name));
    },
    activeItem() {
      return this.links.find((item) => item.name === this.$route.name);
    },
    navGroups() {
      return this.navItems.groups || [];
    },
    groupedLinks() {
      return groupBy(
        this.links.filter((link) => link.show),
        'group',
      );
    },
  },
  methods: {
    handleRouterClick(link) {
      this.isExpanded = false;
      if (link.event) {
        this.mixpanel.track(link.event);
      }
    },
  },
});
</script>

<style lang="scss" module>
.base {
  .base:not(.smallScreen) & {
    height: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: $spacing;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  background-color: white;
}

.list {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: $spacing * 0.5;

  .base:not(.smallScreen) & {
    height: 100%;
  }

  .base.smallScreen & {
    height: calc(100vh - #{$header-height + 52px});

    &:global(.v-enter-active) {
      transition: transform 0.1s;
    }

    &:global(.v-enter-from) {
      transform: translateY(-100%);
    }
  }
}

.navGroup {
  margin-bottom: $spacing;
}

.navHeading {
  padding: $spacing * 0.5;
}

.navItem {
  display: block;
  width: 100%;
  padding: $spacing * 0.5 $spacing;
  border-radius: $radius;

  &.isActive {
    background-color: $color-highlight;
  }

  @include hover {
    background-color: $color-highlight;
  }
}
</style>
