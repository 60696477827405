<template>
  <div :class="[classes]">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    container: {
      type: Boolean,
      default: false,
    },
    innerContainer: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
    },
    sSize: {
      type: Number,
      default: 12,
    },
    mSize: {
      type: Number,
    },
    alignTop: {
      type: Boolean,
      default: false,
    },
    alignBottom: {
      type: Boolean,
      default: false,
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Number,
    },
    fullHeight: {
      type: Boolean,
    },
    top: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const classes = [
        this.container || this.innerContainer
          ? this.$style.container
          : this.$style.item,
      ];
      if (!this.container && this.size) {
        classes.push(this.$style[`item${this.size}`]);
      }
      if (this.innerContainer) {
        classes.push(this.$style.innerContainer);
      }
      if (this.$screen === 's' && !this.container) {
        classes.push(this.$style[`sSize${this.sSize}`]);
      }
      if (this.$screen === 'm' && !this.container) {
        classes.push(this.$style[`mSize${this.mSize}`]);
      }
      if (!this.size && !this.container) {
        classes.push(this.$style.autoFlex);
      }
      if (this.alignTop) {
        classes.push(this.$style.alignTop);
      }
      if (this.alignBottom) {
        classes.push(this.$style.alignBottom);
      }
      if (this.alignRight) {
        classes.push(this.$style.alignRight);
      }
      if (this.alignCenter) {
        classes.push(this.$style.alignCenter);
      }
      if (this.fullHeight) {
        classes.push(this.$style.fullHeight);
      }
      if (this.top && !this.container && this.$screen === 's') {
        classes.push(this.$style.top);
      }
      if (this.noPadding) {
        classes.push(this.$style.noPadding);
      }
      return classes;
    },
  },
});
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + #{$spacing * 2});
  padding: $spacing * 0.5;
  margin: $spacing * -1;

  &.noPadding {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &.innerContainer {
    margin: $spacing * -0.5;
  }

  &.alignTop {
    align-content: flex-start;
  }

  &.alignBottom {
    align-content: flex-end;
  }

  &.alignCenter {
    align-content: center;
  }

  &.alignRight {
    justify-content: flex-end;
  }
}

.item {
  padding: $spacing * 0.5;
  order: 1;

  &.noPadding {
    padding: 0;
  }

  &.alignTop {
    align-self: flex-start;
  }

  &.alignBottom {
    align-self: flex-end;
  }

  &.alignCenter {
    align-self: center;
  }

  &.alignRight {
    //change later
    display: flex;
    justify-content: flex-end;
  }
}

$colWidth: 8.333%;

@for $i from 1 through 12 {
  .item#{$i} {
    width: $colWidth * $i;
  }
}

@for $i from 1 through 12 {
  .sSize#{$i} {
    width: $colWidth * $i;
  }
}

@for $i from 1 through 12 {
  .mSize#{$i} {
    width: $colWidth * $i;
  }
}

.top {
  order: -1;
}

.autoFlex {
  flex: 1;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}
</style>
