<template>
  <BaseModalLarge
    :heading="$t('products.update_product_prices')"
    :parentRoute="parentRouteName"
    testId="update-prices"
  >
    <div>
      <BaseHeading :mb="0.5" :ml="0.5">
        {{ $t('products.select_your_supplier') }}
      </BaseHeading>
    </div>
    <div :class="$style.cards">
      <BaseSpinner v-if="loading" />
      <SupplierCard
        v-for="supplier in suppliersData"
        :key="supplier.id"
        :v-if="loading"
        :supplier="supplier"
        :loading="loading && loading === supplier.id"
        useTotalProducts
        @selectSupplier="handleSelection(supplier)"
        @schedulingCancelled="refetch"
        v-test="'chooseSupplierCard'"
      />
    </div>
    <LoadMore
      v-show="!allDataFetched && suppliersData.length > 0"
      @shown="fetchMore"
    />
  </BaseModalLarge>
</template>

<script lang="ts">
export default {
  name: 'ChooseSupplier',
};
</script>

<script setup lang="ts">
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import SupplierCard from './SupplierCard.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';

defineProps<{
  parentRouteName: string;
}>();

const { locationId } = useLocationsStore();

const router = useRouter();
const suppliersData = ref([]);

const { loading, onResult, allDataFetched, fetchMore } = usePagination({
  fieldName: 'suppliers',
  query: gql`
    query getSuppliers(
      $locationId: ID
      $search: String
      $showNullSupplier: Boolean
      $pagination: PaginationAttributes
    ) {
      suppliers(
        locationId: $locationId
        search: $search
        showNullSupplier: $showNullSupplier
        pagination: $pagination
      ) {
        id
        name
        totalInventory
        amountProducts
        productChangeScheduledAt
        productChangeScheduleId
        productChangeAppliedAt
      }
    }
  `,
  variables: {
    locationId,
    search: '',
    showNullSupplier: true
  }
});

onResult(({ data: { suppliers } }) => {
  suppliersData.value = suppliers;
});

const handleSelection = (supplier: any) => {
  router.push({
    name: 'update-supplier-product-prices',
    params: { supplierId: supplier.id ? supplier.id : 0 }
  });
};
</script>

<style lang="scss" module>
.cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  justify-content: flex-start;
}
</style>
