<template>
  <BaseModal
    :heading="$t('marketing.discounts.enable-last-minute-discount-modal.setup')"
    :loading="isLoading"
    small
    :parentRoute="{ name: 'treatwell-admin' }"
  >
    <BaseText mb>
      {{ $t('marketing.discounts.enable-last-minute-discount-modal.tw_info') }}
    </BaseText>
    <BaseText :mb="2" href="https://help.salonized.com/articles/5669294">
      {{ $t('marketing.discounts.more_tips') }}
    </BaseText>
    <BaseAlert
      v-if="hasFeatureFlag('module-marketing')"
      color="warning"
      :text="
        $t('admin.treatwell.main.getting_seen.also_applies_to_booking_widget')
      "
      mb
    />
    <BaseGrid container>
      <BaseGrid :size="6" alignBottom>
        <BaseDropdown
          v-model="discountPercentage"
          :label="
            $t(
              'marketing.discounts.enable-last-minute-discount-modal.apply_percentage'
            )
          "
          :options="percentageOptions"
          v-test="'percentage-options'"
        />
      </BaseGrid>
      <BaseGrid :size="6" alignBottom>
        <BaseDropdown
          v-model="discountWindow"
          :label="
            $t(
              'marketing.discounts.enable-last-minute-discount-modal.for_bookings_made'
            )
          "
          :options="discountWindowOptions"
          v-test="'discount-window-options'"
        />
      </BaseGrid>
    </BaseGrid>
    <template #footerSub>
      <BaseText
        v-if="discountEnabled"
        :routerLink="{ name: 'treatwell-salon-disable-last-minute-discount' }"
        v-test="'last-minute-discount-disable'"
      >
        {{ $t('global.actions.disable') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton color="inverted" @click="close" v-test="'discountCodeCancel'">
        {{ $t('global.actions.cancel') }}
      </BaseButton>

      <BaseButton :loading="isSaving" @click="onSubmit" v-test="'saveButton'">
        {{ $t('global.apply') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { flash } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/stores/user';
const { hasFeatureFlag } = useUserStore();

import { useRouter } from 'vue-router';
const router = useRouter();

import { useI18n } from 'vue-i18n';
import { useMutation, useQuery } from '@vue/apollo-composable';
import {
  GET_LAST_MINUTE_DISCOUNTS,
  UPDATE_LAST_MINUTE_DISCOUNTS,
} from '../graphql';
const { t } = useI18n();

const { companySettings } = storeToRefs(useCompanyStore());

const mixpanel = inject<any>('mixpanel');

const discountPercentage = ref(10);
const discountWindow = ref('TWO_HOURS');
const discountEnabled = computed(
  () => companySettings.value.bookings.lastMinuteDiscountEnabled,
);
const percentageOptions = [5, 10, 15, 20, 30, 50].map((percentage) => ({
  value: percentage,
  label: `${percentage}%`,
}));

const discountWindowOptions = [
  'ONE_HOUR',
  'TWO_HOURS',
  'FOUR_HOURS',
  'ONE_DAY_FROM_NOW',
  'TWO_DAYS_FROM_NOW',
  'SAME_DAY',
].map((option) => ({
  value: option,
  label: t(`marketing.discounts.booking_range.${option.toLowerCase()}`),
}));

const close = () => {
  router.push({ name: 'treatwell-admin' });
};

const { loading: isLoading, onResult } = useQuery(GET_LAST_MINUTE_DISCOUNTS);
onResult((response) => {
  discountPercentage.value =
    response.data.lastMinuteDiscounts.discountPercentage;
  discountWindow.value = response.data.lastMinuteDiscounts.discountWindow;
});

const { mutate, loading: isSaving } = useMutation(
  UPDATE_LAST_MINUTE_DISCOUNTS,
  {
    update(cache) {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'lastMinuteDiscounts' });
      cache.gc();
    },
  },
);

const onSubmit = () => {
  mutate({
    input: {
      discountPercentage: discountPercentage.value,
      discountWindow: discountWindow.value,
      enabled: true,
    },
  }).then(() => {
    mixpanel.track('Treatwell dashboard - task - last minute discount set');
    companySettings.value.bookings.lastMinuteDiscountEnabled = true;
    flash(t('global.flash.last_minute_discount_enabled'));
    close();
  });
};
</script>
