<template>
  <a :href="buttonUrl" target="_blank" :class="$style.base" :style="style">
    {{
      buttonTitle
        ? buttonTitle
        : $t('marketing.email.preview.button_message_default')
    }}
  </a>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    ...mapGetters('marketing', ['buttonTitle', 'buttonUrl', 'color', 'id']),
    style() {
      let color = '#FD832D';
      if (this.color) {
        color = this.color;
      }
      return `background-color: ${color};`;
    },
  },
  created() {
    if (!this.buttonTitle) {
      this.$store.commit(
        'marketing/SET_BUTTON_TITLE',
        this.$t('marketing.email.preview.button_message_default'),
      );
    }

    if (!this.color && !this.id) {
      this.$store.commit('marketing/SET_COLOR', '#FD832D');
    }
  },
});
</script>

<style lang="scss" module>
.base {
  color: $color-text-inverted !important;
  border-radius: $radius;
  padding: 22px $spacing * 3;
  margin: $spacing 0 0;
  background: $color-warning-light;
}
</style>
