<template>
  <div
    :class="[
      $style.base,
      {
        [$style.mediumScreen]: $screen === 'm'
      }
    ]"
  >
    <PaymentMethodButton
      v-bind="{ tooltip, payment, disabled, icon }"
      :loading="payment === 'pos' && posConnected"
      @click="onSelect"
      v-test="`register-payment-${payment}`"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
import type { PrepaidCard, PosTerminal } from '@/types';
import { useRegisterStore } from '@/modules/register/stores/register';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { useI18n } from 'vue-i18n';
import PaymentMethodButton from './PaymentMethodButton.vue';
import type { IconName } from '@/components/base-icon/types';

const props = defineProps<{
  payment: string;
  amount: number;
  prepaidCards: PrepaidCard[];
  posTerminals: PosTerminal[];
  posConnected: boolean;
}>();

const emit = defineEmits(['addPayment', 'openModal']);

const { cashupEnabled } = storeToRefs(useRegisterStore());
const { currentCashupId } = useRegisterDrawerStore();

const { order } = useRegisterOrderStore();
const selected = ref(false);
const inClosedCashup = computed(
  () =>
    cashupEnabled.value &&
    order.cashupId &&
    order.cashupId !== Number(currentCashupId),
);
const { t } = useI18n();
const mixpanel = inject<any>('mixpanel');

const tooltip = computed(() => {
  if (props.amount === 0 && props.payment !== 'pos') {
    return t('register.disable_payment_tooltip');
  }

  if (props.payment === 'pos' && props.amount < 100) {
    return t('register.pos.min_amount_tooltip');
  }

  if (props.payment === 'prepaid_card' && inClosedCashup.value) {
    return t('register.disable_closed_cashup_tooltip');
  }

  if (disabled.value) {
    return t(`register.${props.payment}.disabled_tooltip`);
  }

  return null;
});

const disabled = computed(() => {
  if (props.amount === 0) {
    return true;
  }

  switch (props.payment) {
    case 'pos': {
      const { order } = useRegisterOrderStore();
      const locationTerminals = props.posTerminals.filter(
        (terminal) => terminal.location?.id === order.locationId,
      );
      return !locationTerminals.length || props.amount < 100;
    }
    case 'prepaid_card':
      if (!order.customerId || props.amount < 0) {
        return true;
      }

      if (inClosedCashup.value) {
        return true;
      }

      // if no services left to select
      if (
        !order.items.find((item) => item.serviceId && !item.usedPrepaidCardId)
      ) {
        return true;
      }

      if (order.customerId && props.prepaidCards.length) {
        return !(props.prepaidCards.length > 0);
      }

      return true;
    case 'giftcard':
      return props.amount < 0;
    default:
      return false;
  }
});

const icon = computed<IconName | undefined>(() => {
  switch (props.payment) {
    case 'pos':
      return props.posTerminals.length ? 'wifi' : 'no-wifi';
    case 'pin':
      return 'credit-card';
    case 'cash':
      return 'file-empty';
    case 'creditcard':
      return 'credit-card';
    case 'bank':
      return 'email-open';
    case 'coupon':
      return 'award';
    case 'giftcard':
      return 'gift';
    case 'prepaid_card':
      return 'button-clicked';
    default:
      return undefined;
  }
});

const onSelect = () => {
  if (disabled.value) {
    return;
  }

  selected.value = true;
  mixpanel.track(
    `Register - Payment method selected, { choice: ${props.payment} }`,
  );

  switch (props.payment) {
    case 'giftcard':
    case 'prepaid_card':
    case 'pos':
      emit('openModal', props.payment);
      break;
    default:
      emit('addPayment', { type: props.payment, amount: props.amount });
      break;
  }
};
</script>

<style lang="scss" module>
.base {
  &:not(.mediumScreen) {
    width: calc(50% - #{$spacing * 0.5});
    margin-top: $spacing;
  }

  &.mediumScreen {
    width: 100%;
    margin-top: $spacing * 0.5;

    &:first-child {
      margin-top: $spacing;
    }
  }
}
</style>
