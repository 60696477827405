<template>
  <NewImport v-if="showNewImport" :importId="importId" @close="close" />
  <BaseModal
    v-else
    small
    :heading="$t('onboarding.new_onboarding.import_customers.header')"
    @close="close"
    v-test="'task-import_customers'"
  >
    <BaseHeading v-if="step === 0" center mb v-test="'titleQuestion'">
      {{ $t(`onboarding.new_onboarding.import_customers.question.title`) }}
    </BaseHeading>
    <div
      v-if="step === 0"
      :class="[
        $style.base,
        {
          [$style.mobile]: $screen === 's'
        }
      ]"
    >
      <div
        :class="$style.imgWrapper"
        @click="handleGoNext('hasNoTemplate')"
        v-test="'has-no-template'"
      >
        <img
          src="/img/empty-states/customers-template.svg"
          :class="$style.img"
        />
        <BaseText mt bold>
          {{ $t(`onboarding.new_onboarding.import_customers.question.no`) }}
        </BaseText>
      </div>
      <div
        :class="$style.imgWrapper"
        @click="handleGoNext('hasTemplate')"
        v-test="'has-template'"
      >
        <img
          src="/img/onboarding/customers-template-own.svg"
          :class="$style.img"
        />
        <BaseText mt bold>
          {{ $t(`onboarding.new_onboarding.import_customers.question.yes`) }}
        </BaseText>
      </div>
    </div>
    <EmptyPageContent
      v-if="step === 1"
      imageName="customers-template"
      iconPrimary="download"
      :hrefLinkPrimary="templateUrl"
      :text="{
        title: $t(`onboarding.new_onboarding.import_customers.download.title`),
        description: $t(
          `onboarding.new_onboarding.import_customers.download.description`
        ),
        btnPrimary: $t(
          `onboarding.new_onboarding.import_customers.download.button`
        )
      }"
      @click="handleGoNext('hasNoTemplate')"
      v-test="'importClientsStep1'"
    />
    <EmptyPageContent
      v-if="step === 2"
      imageName="customers-import"
      iconPrimary="upload"
      :text="{
        title: $t(`onboarding.new_onboarding.import_customers.upload.title`),
        description: $t(
          `onboarding.new_onboarding.import_customers.upload.description`
        ),
        btnPrimary: $t(
          `onboarding.new_onboarding.import_customers.upload.button`
        )
      }"
      :moreLink="{
        text: $t('onboarding.new_onboarding.import_customers.upload.link'),
        url: templateUrl
      }"
      :loading="loading"
      @click="handleGoNext('upload')"
      v-test="'importClientsStep2'"
    />
  </BaseModal>
</template>

<script lang="ts" setup>
import config from '@/config';
import { inject, ref } from 'vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import eventBus from '@/event-bus';
import NewImport from './import/index.vue';

const mixpanel = inject<any>('mixpanel');

const templateUrl =
  'https://salonized-template.s3.eu-central-1.amazonaws.com/Customers_Import_Template.xlsx';
const step = ref(0);
const showNewImport = ref(false);
const importId = ref<number>(0);

const handleGoNext = (button: string) => {
  if (button === 'upload') {
    if (config.isTest) {
      showImport(1);
      return;
    }

    handleUploadFile();
  }

  if (button === 'hasTemplate') {
    step.value = 2;
  }

  if (button === 'hasNoTemplate') {
    switch (step.value) {
      case 0:
        mixpanel.track('Imports V2 - Download file', { step: 'No file' });
        step.value = 1;
        break;
      case 1:
        mixpanel.track('Imports V2 - Download file', { step: 'File' });
        setTimeout(() => {
          step.value = 2;
        }, 100);
        break;
      case 2:
        showImport(importId.value);
        break;
    }
  }
};

const { mutate: uploadFile, loading } = useMutation(gql`
  mutation uploadImport($input: UploadImportInput!) {
    uploadImport(input: $input) {
      import {
        id
      }
    }
  }
`);

const handleUploadFile = () => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute(
    'accept',
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  );

  input.addEventListener('change', async (e: Event) => {
    const target = e.target as HTMLInputElement;
    const file = target.files![0];

    const convertBlobToBase64 = (blob: File) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const base64ToBackend = (blob: File) =>
      convertBlobToBase64(blob).then((text: any) =>
        text.slice(text.indexOf(',')).substring(1),
      );

    const backendReadyFile = await base64ToBackend(file);

    uploadFile({
      input: {
        mimeType: file.type,
        filename: file.name,
        resource: 'customers',
        file: backendReadyFile,
      },
    }).then((res: any) => {
      showImport(res.data.uploadImport.import.id);
    });
  });

  input.click();
};

const showImport = (id: any) => {
  importId.value = id;
  showNewImport.value = true;
};

const close = () => {
  eventBus.$emit('open-task-menu');
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: space-between;

  &.mobile {
    flex-wrap: wrap;
    justify-content: center;
  }

  &.mobile > .imgWrapper {
    margin-bottom: $spacing;
  }
}

.imgWrapper {
  background-color: $color-highlight;
  border: 1px solid $color-border;
  border-radius: $radius;
  padding: 16px;
  width: 265px;
  cursor: pointer;
}

.img {
  width: 236px;
  height: 184px;
}
</style>
