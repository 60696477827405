<template>
  <div>
    <BaseHeading mb>
      {{ $t('marketing.insights.tips.title') }}
    </BaseHeading>
    <Tip
      v-for="(tip, index) in tips"
      :key="index"
      :tip="tip.name"
      :videoId="tip.videoId"
      mb
    />
  </div>
</template>

<script lang="ts">
import Tip from './Tip.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Tip,
  },
  data() {
    return {
      tips: [
        {
          name: 'newsletter',
          videoId: '723809742',
        },
        {
          name: 'rebook',
          videoId: '723735734',
        },
        {
          name: 'filter-groups',
          videoId: '723697477',
        },
        {
          name: 'lmd',
          videoId: '768163252',
        },
        {
          name: 'discount-codes',
          videoId: '723702723',
        },
        {
          name: 'waiting-list',
          videoId: '740006883',
        },
      ],
    };
  },
});
</script>
