<template>
  <div :class="$style.base">
    <BaseGrid container>
      <BaseGrid :size="$screen === 's' ? 12 : 6" alignBottom>
        <BaseDropdown
          v-model="selectedDateRangeFilter"
          :options="filterDateRangeOptions"
          :label="$t('feedback.time_selection')"
          v-test="'feedback-batch-filter-reaction'"
        />
      </BaseGrid>
      <BaseGrid v-if="$screen !== 's'" :size="6">
        <BaseDropdown
          v-model="selectedOrderFilter"
          :options="orderOptions"
          :label="$t('feedback.order_by')"
          v-test="'feedback-batch-filter-publishing-status'"
        />
      </BaseGrid>
    </BaseGrid>
    <div :class="$style.border" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    fromDateFilter: {
      type: Number,
      required: false,
    },
    orderFilter: {
      type: String,
      required: false,
    },
  },
  emits: ['update:fromDateFilter', 'update:orderFilter'],
  computed: {
    selectedDateRangeFilter: {
      get() {
        return this.fromDateFilter;
      },
      set(value: number | null) {
        this.$emit('update:fromDateFilter', value);
      },
    },
    selectedOrderFilter: {
      get() {
        return this.orderFilter;
      },
      set(value: string) {
        this.$emit('update:orderFilter', value);
      },
    },
    filterDateRangeOptions() {
      return [
        {
          value: 7,
          label: this.$t('global.last_x_days', { days: 7 }),
        },
        {
          value: 15,
          label: this.$t('global.last_x_days', { days: 15 }),
        },
        {
          value: 30,
          label: this.$t('global.last_x_days', { days: 30 }),
        },
        {
          value: 60,
          label: this.$t('global.last_x_days', { days: 60 }),
        },
        {
          value: 90,
          label: this.$t('global.last_x_days', { days: 90 }),
        },
        {
          value: 365,
          label: this.$t('global.last_x_days', { days: 365 }),
        },
        {
          value: null,
          label: this.$t('global.all'),
        },
      ];
    },
    orderOptions() {
      return [
        {
          value: 'NEWER_FIRST',
          label: this.$t('feedback.order.newer_first'),
        },
        {
          value: 'OLDER_FIRST',
          label: this.$t('feedback.order.older_first'),
        },
        {
          value: 'POSITIVE_FIRST',
          label: this.$t('feedback.order.positive_first'),
        },
        {
          value: 'NEGATIVE_FIRST',
          label: this.$t('feedback.order.negative_first'),
        },
      ];
    },
  },
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing;
}

.border {
  padding-bottom: $spacing;
  border-bottom: 1px solid $color-border;
}
</style>
