<template>
  <ModuleBase
    :navItems="navItems"
    :disableIntercomPadding="disableIntercomPaddingRoutes"
    :gray="!!$route.matched.find((route) => route.name === 'rebook-reminder')"
  />
</template>

<script lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ModuleBase,
  },
  computed: {
    navItems() {
      const items = [
        {
          text: this.$t('global.pages.insights'),
          name: 'marketing-insights',
        },
        {
          text: this.$t('global.pages.waiting_list'),
          name: 'marketing-waiting-list',
        },
        {
          text: this.$t('global.pages.discounts'),
          name: 'marketing-discounts',
        },
        {
          text: this.$t('global.pages.email'),
          name: 'marketing-email',
        },
        {
          text: this.$t('global.pages.filters'),
          name: 'marketing-filter-groups',
        },
        {
          text: this.$t('global.pages.rebook_reminders'),
          name: 'rebook-reminder',
        },
      ];

      return items;
    },
    ...mapState(useUserStore, ['hasFeatureFlag']),
    disableIntercomPaddingRoutes() {
      return this.$route.fullPath.includes('/builder');
    },
  },
});
</script>
