import config from '@/config';
import { UnleashClient } from 'unleash-proxy-client';

const unleash = new UnleashClient({
  url: config.unleashUrl || '',
  clientKey: config.unleashKey || '',
  appName: 'vue',
  disableRefresh: true,
});

export default unleash;
