<template>
  <div>
    <BaseRadio
      v-model="selectedValue"
      :options="filters"
      mb
      @update:modelValue="emitValue"
    />
    <div v-if="selectedValue === 'appointment_count'">
      <BaseGrid container>
        <BaseGrid :size="2" alignCenter>
          {{ $t('global.from') }}
        </BaseGrid>
        <BaseGrid :size="3" v-test="'inputFrom'">
          <BaseInput
            v-model="from"
            type="number"
            @update:modelValue="emitValue"
          />
        </BaseGrid>
        <BaseGrid :size="2" alignCenter>
          {{ $t('global.to') }}
        </BaseGrid>
        <BaseGrid :size="3" v-test="'inputTo'">
          <BaseInput
            v-model="to"
            type="number"
            @update:modelValue="emitValue"
          />
        </BaseGrid>
      </BaseGrid>
    </div>
    <div v-if="selectedValue === 'appointment_between'" v-test="'filterDates'">
      <BaseDatePicker
        v-model="dateFrom"
        :label="$t('customers.filters.from')"
        mb
        @update:modelValue="emitValue"
      />
      <BaseDatePicker
        v-model="dateTo"
        :label="$t('customers.filters.to')"
        @update:modelValue="emitValue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      selectedValue: 'appointment_today',
      dateFrom: dayjs().format(),
      dateTo: dayjs().format(),
      from: 1,
      to: 100,
      filters: [
        {
          label: this.$t('customers.filters.appointment_today'),
          value: 'appointment_today',
        },
        {
          label: this.$t('customers.filters.appointment_yesterday'),
          value: 'appointment_yesterday',
        },
        {
          label: this.$t('customers.filters.appointment_tomorrow'),
          value: 'appointment_tomorrow',
        },
        {
          label: this.$t('customers.filters.appointment_cancelled'),
          value: 'appointment_cancelled',
        },
        {
          label: this.$t('customers.filters.appointment_count'),
          value: 'appointment_count',
        },
        {
          label: this.$t('customers.filters.next_appointment_empty'),
          value: 'next_appointment_empty',
        },
        {
          label: this.$t('customers.filters.select_time'),
          value: 'appointment_between',
        },
      ],
    };
  },
  methods: {
    emitValue() {
      const dates = {
        from: this.dateFrom,
        to: this.dateTo,
      };

      const range = {
        from: this.from,
        to: this.to,
      };

      let values = null;

      if (this.selectedValue === 'appointment_between') {
        values = dates;
      }

      if (this.selectedValue === 'appointment_count') {
        values = range;
      }

      const obj = {
        filter_name: this.selectedValue,
        values,
      };
      this.$emit('selected', obj);
    },
  },
  created() {
    this.emitValue();
  },
});
</script>
