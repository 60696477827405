<template>
  <div
    v-if="customer"
    :class="[$style.base, { [$style.isSmall]: $screen === 's' }]"
  >
    <MergeCustomer
      v-if="showMergeModal"
      @updateCustomer="refetch"
      @close="showMergeModal = false"
    />
    <DeletedOverlay v-if="customer.state === 'DELETED'" />
    <SafeTeleport v-else-if="!showMobileMenu && customer" to="#header-teleport">
      <div :class="$style.header" v-test="'customer-header'">
        <BaseAvatar
          v-if="$screen !== 's'"
          size="l"
          :picture="customer.picture"
          :name="customer.fullName"
          :mr="0.5"
        />
        {{ customer.fullName }}
        <CustomerActions @merge="showMergeModal = true" />
      </div>
    </SafeTeleport>
    <div :class="$style.content">
      <router-view />
    </div>
  </div>
  <BaseSpinner v-else />
</template>

<script lang="ts">
export default {
  name: 'Customer',
};
</script>

<script lang="ts" setup>
import DeletedOverlay from './DeletedOverlay.vue';
import MergeCustomer from './MergeCustomer.vue';
import CustomerActions from './CustomerActions.vue';

import { computed, ref, provide } from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { GET_CUSTOMER } from './graphql';
import { usePageLayoutStore } from '@/stores/page-layout';
import { storeToRefs } from 'pinia';
const { showMobileMenu } = storeToRefs(usePageLayoutStore());

const route = useRoute();
const customerId = computed(() =>
  route && typeof route.params.customerId === 'string'
    ? parseInt(route.params.customerId)
    : null
);

const { result, refetch } = useQuery(GET_CUSTOMER, () => ({
  id: customerId.value
}));

const customer = computed(() => result.value?.customer);
provide('customer', customer);

const showMergeModal = ref(false);
</script>

<style lang="scss" module>
.base {
  height: 100%;
  min-height: 100%;
}

.header {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
}

.content {
  height: 100%;
}

.customerSection {
  display: flex;
  align-items: center;
  background: $white;
  border-bottom: 1px solid $color-border;
  height: 60px;
}
</style>
