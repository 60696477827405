<template>
  <InvoicesCard
    v-if="unpaidInvoices.length && unpaidInvoices.length > 0"
    :invoices="unpaidInvoices"
    :loading="loading"
    :title="$t('admin.cards.invoices.invoices_to_be_paid')"
    :description="$t('admin.cards.invoices.keep_invoices_up_to_date')"
    @downloadInvoice="(id) => openInvoice(id)"
  />
  <InvoicesCard
    :invoices="paidInvoices.slice(0, 3)"
    :loading="loading"
    :title="$t('admin.cards.invoices.paid_invoices')"
    allInvoicesLink
    @allInvoicesLinkClick="modalOpen = true"
    @downloadInvoice="(id) => openInvoice(id)"
  />
  <BaseModal
    v-if="modalOpen"
    :loading="loading"
    :heading="$t('admin.cards.invoices.paid_invoices')"
    @close="modalOpen = false"
  >
    <BaseTable
      :headers="[
        $t('global.date'),
        $t('global.amount_currency'),
        $t('global.status'),
        $t('global.number')
      ]"
      :rows="tableData"
      @action="handleAction"
      v-test="'invoices-card-modal-table'"
    />
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </BaseModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import LoadMore from '@/components/LoadMore.vue';
import InvoicesCard from './InvoicesCard.vue';
import { useInvoices } from './useInvoices';

const {
  unpaidInvoices,
  paidInvoices,
  tableData,
  loading,
  allDataFetched,
  fetchMore,
  fetchAmountOfInvoices,
  openInvoice,
  payInvoice,
} = useInvoices();

const modalOpen = ref(false);

watch(modalOpen, () => {
  if (modalOpen.value) {
    fetchAmountOfInvoices(100);
  }
});

const handleAction = ({ id, option }: { id: string; option: string }) => {
  if (option === 'pdf') {
    openInvoice(id);
  }

  if (option === 'pay') {
    payInvoice(id);
  }
};
</script>
