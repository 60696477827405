<template>
  <BaseModalLarge
    :heading="
      userId
        ? $t('admin.user_accounts.modal.edit_title')
        : $t('admin.user_accounts.modal.create_title')
    "
    noPadding
    :loading="isQueryLoading"
    parentRoute="admin-accounts"
    useForm
    @submit="submitData"
  >
    <BaseSpinner v-if="isQueryLoading" />
    <PageSplit v-else :width="60">
      <template #left>
        <div
          :class="[
            $style.formRow,
            $style.mobileFull,
            { [$style.smallScreen]: $screen === 's' }
          ]"
        >
          <BaseInput
            v-model="formData.firstName"
            :label="$t('global.first_name')"
            required
            :minLength="2"
            :maxLength="255"
            v-test="'user-accounts-first-name'"
          />
          <BaseInput
            v-model="formData.lastName"
            :label="$t('global.last_name')"
            required
            :minLength="2"
            :maxLength="255"
            v-test="'user-accounts-last-name'"
          />
        </div>
        <BaseInput
          v-model="formData.email"
          mb
          :label="$t('global.email_address')"
          type="email"
          required
          :error="emailErrorString"
          v-test="'user-accounts-email'"
        />
        <div
          v-if="!userId"
          :class="[
            $style.formRow,
            $style.mobileFull,
            { [$style.smallScreen]: $screen === 's' }
          ]"
        >
          <BaseInput
            v-model="password"
            :label="$t('global.password')"
            required
            type="password"
            newPassword
            v-test="'user-accounts-password'"
          />
          <BaseInput
            v-model="passwordConfirmation"
            :label="$t('account.password_repeat')"
            required
            type="password"
            :sameAs="password"
            :minLength="2"
            :maxLength="255"
            v-test="'user-accounts-password-repeat'"
          />
        </div>
        <BaseText mb bold size="l">{{
          $t('admin.user_accounts.modal.security.title')
        }}</BaseText>
        <BaseCheckbox
          v-model="formData.settings.security.autoSessionTimeout"
          mb
          :label="$t('admin.user_accounts.modal.security.checkbox_title')"
          :description="
            $t('admin.user_accounts.modal.security.checkbox_description')
          "
          v-test="'user-accounts-security-checkbox'"
        />
      </template>
      <template #right>
        <BaseText mb bold size="l">{{
          $t('admin.user_accounts.modal.permissions.title')
        }}</BaseText>
        <BaseText mb>{{
          $t('admin.user_accounts.modal.permissions.description')
        }}</BaseText>
        <BaseCheckbox
          v-model="formData.owner"
          mb
          :disabled="
            currentUser?.owner && currentUser.id === parseInt(userId as string)
          "
          :label="$t('admin.user_accounts.modal.permissions.owner.title')"
          :description="
            $t('admin.user_accounts.modal.permissions.owner.description')
          "
          v-test="'user-accounts-permissions-owner'"
        />
        <BaseCheckbox
          v-model="formData.admin"
          mb
          :disabled="formData.owner"
          :label="$t('admin.user_accounts.modal.permissions.admin.title')"
          :description="
            $t('admin.user_accounts.modal.permissions.admin.description')
          "
          v-test="'user-accounts-permissions-admin'"
        />
        <BaseCheckbox
          v-model="formData.manager"
          :disabled="formData.admin"
          mb
          :label="$t('admin.user_accounts.modal.permissions.manager.title')"
          :description="
            $t('admin.user_accounts.modal.permissions.manager.description')
          "
          v-test="'user-accounts-permissions-manager'"
        />
        <BaseCheckbox
          v-model="formData.reports"
          :disabled="formData.admin"
          mb
          :label="$t('admin.user_accounts.modal.permissions.reports.title')"
          :description="
            $t('admin.user_accounts.modal.permissions.reports.description')
          "
          v-test="'user-accounts-permissions-reports'"
        />
        <BaseCheckbox
          v-model="formData.basic"
          :disabled="formData.manager || formData.reports"
          mb
          :label="$t('admin.user_accounts.modal.permissions.basic.title')"
          :description="
            $t('admin.user_accounts.modal.permissions.basic.description')
          "
          v-test="'user-accounts-permissions-basic'"
        />
        <BaseCheckbox
          v-model="calendar"
          mb
          disabled
          :label="$t('admin.user_accounts.modal.permissions.calendar.title')"
          :description="
            $t('admin.user_accounts.modal.permissions.calendar.description')
          "
          v-test="'user-accounts-permissions-calendar'"
        />
      </template>
    </PageSplit>
    <template #footerSub>
      <BaseText
        v-if="
          userId && !isOwner && currentUser?.id !== parseInt(userId as string)
        "
        color="error"
        iconBefore="delete"
        link
        inline
        :loading="userMutationsLoading"
        @click="handleAccountDelete"
        v-test="'user-account-modal-delete'"
      >
        {{ $t('admin.general_settings.delete_account') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton
        color="inverted"
        :routerLink="{ name: 'admin-accounts' }"
        v-test="'user-accounts-modal-cancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        submitForm
        :loading="userMutationsLoading"
        v-test="'user-accounts-modal-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script lang="ts" setup>
import PageSplit from '@/modules/PageSplit.vue';
import { useRouter } from 'vue-router';
import { useUserAccount } from './useUserAccount';
import { flash, modal } from '@/helpers/ui';
import { useUserAccounts } from './useUserAccounts';
import type { User } from '@/types';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';

const { t } = useI18n();
const isQueryLoading = ref(false);
const router = useRouter();
const userId = router.currentRoute.value.params.accountId;

const { user: currentUser } = useUserStore();
const isOwner = ref(false);

const formData = reactive({
  firstName: '',
  lastName: '',
  email: '',
  settings: {
    security: {
      autoSessionTimeout: false,
    },
  },
  owner: false,
  admin: false,
  manager: false,
  reports: false,
  basic: false,
});

const password = ref('');
const passwordConfirmation = ref('');
const calendar = ref(true);

// These are the rules for inheriting permissions
watch(formData, (value) => {
  if (value.owner) {
    formData.admin = true;
    formData.manager = true;
    formData.reports = true;
    formData.basic = true;
    return;
  }

  if (value.admin) {
    formData.manager = true;
    formData.reports = true;
    formData.basic = true;
    return;
  }

  if (value.manager || value.reports) {
    formData.basic = true;
    return;
  }
});

const {
  createUser,
  getUser,
  updateUser,
  deleteUser,
  loading: userMutationsLoading,
} = useUserAccount();

if (typeof userId === 'string') {
  isQueryLoading.value = true;
  const { onResult } = getUser(userId);

  onResult(({ data: { user } }: { data: { user: User } }) => {
    isQueryLoading.value = false;
    formData.firstName = user.firstName;
    formData.lastName = user.lastName as string;
    formData.email = user.email;
    formData.settings.security.autoSessionTimeout =
      user.settings.security.autoSessionTimeout;
    formData.owner = user.owner;
    formData.admin = user.admin;
    formData.manager = user.manager;
    formData.reports = user.reports;
    formData.basic = user.basic;

    isOwner.value = user.owner;
  });
}

const { refetch } = useUserAccounts();

const emailErrorString = ref(null);

watch(
  () => formData.email,
  () => (emailErrorString.value = null),
);

const afterMutation = (message: string, response: any) => {
  if (response?.errors?.email?.length) {
    parseEmailError(response.errors.email[0]);
  } else {
    flash(t(message));
    refetch();
    router.push({ name: 'admin-accounts' });
  }
};

const submitData = () => {
  if (userId) {
    updateUser({
      input: {
        ...formData,
        id: Number.parseInt(userId as string),
      },
    }).then(({ data: { updateUser } }) => {
      afterMutation(t('global.flash.user_account_updated'), updateUser);
    });
  } else {
    createUser({
      input: {
        ...formData,
        password: password.value,
        passwordConfirmation: passwordConfirmation.value,
      },
    }).then(({ data: { createUser } }) => {
      afterMutation(t('global.flash.user_account_created'), createUser);
    });
  }
};

const handleAccountDelete = () => {
  modal('confirmation', {
    type: 'delete',
    item: `${formData.firstName} ${formData.lastName}`,
  }).then(() => {
    deleteUser({
      input: {
        id: Number.parseInt(userId as string),
      },
    }).then(() => {
      afterMutation(
        t('admin.user_accounts.user_delete_success_message', {
          user: `${formData.firstName} ${formData.lastName}`,
        }),
      );
    });
  });
};

const parseEmailError = (error: string) => {
  if (error.includes('is not available')) {
    emailErrorString.value = t('signup.errors.email_in_use');
  } else {
    emailErrorString.value = t('global.validations.invalid', {
      value: t('global.email_address'),
    });
  }
};
</script>

<style lang="scss" module>
.base {
  display: flex;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: calc(50% - #{$spacing * 0.5});
    margin-bottom: $spacing;
  }

  &.mobileFull {
    &.smallScreen {
      margin-bottom: 0;

      & > * {
        padding-right: 0;
        width: 100%;
      }
    }
  }
}
</style>
