<template>
  <div :class="$style.base">
    <DiscountsImage mb />
    <div :class="$style.maxWidth">
      <BaseText>
        {{ $t('marketing.discounts.details.no_customer_have_used') }}
        <router-link :class="$style.link" to="#">
          {{ $t('marketing.discounts.details.email_area') }}
        </router-link>
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import DiscountsImage from './DiscountsImage.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    DiscountsImage,
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maxWidth {
  display: inline-block;
  max-width: 320px;
  text-align: center;
}

.link {
  // because ember
  color: $color-primary !important;
  text-decoration: none;
}
</style>
