<template>
  <BaseModal
    :heading="$t('sms_credits.heading')"
    useForm
    small
    :parentRoute="{
      name: parentRouteName
    }"
    @submit="stripeSmsCheckoutMutation"
    @close="$emit('close')"
  >
    <EmptyPageContent
      v-if="isNativeAppIOS"
      imageName="training-employee"
      :text="{
        title: $t('ios.subscription_blocked.heading'),
        description: $t('ios.subscription_blocked.description')
      }"
    />
    <div v-else>
      <div
        :class="[
          $style.base,
          {
            [$style.smallScreen]: $screen === 's'
          }
        ]"
        v-test="'sms-credits-page'"
      >
        <BaseCard mb>
          <div :class="$style.currentBalance">
            <img src="/img/admin/sms-credits.svg" />
            <div class="ml-1">
              <BaseHeading size="s" :mb="0.5">
                {{ $t('sms_credits.balance') }}
              </BaseHeading>
              <BaseHeading v-test="'credit-balance'">
                {{ currentBalance }}
                {{ $t('sms_credits.credit', currentBalance) }}
              </BaseHeading>
            </div>
          </div>
        </BaseCard>
        <div>
          <BaseText mb>
            {{ $t('sms_credits.description') }}
          </BaseText>
          <div class="mb-1">
            <BaseText
              link
              inline
              :iconAfter="showPricingTable ? 'chevron-up' : 'chevron-down'"
              @click="showPricingTable = !showPricingTable"
              v-test="'toggle-pricing-table'"
            >
              {{ $t('sms_credits.price_table') }}
            </BaseText>
          </div>
          <BaseTable
            v-show="showPricingTable"
            :headers="[
              $t('sms_credits.credits_amount'),
              $t('sms_credits.credits_price')
            ]"
            :rows="[
              {
                cells: ['50 - 999', filters.currency(getCreditPrice(50))]
              },
              {
                cells: ['1000 - 2499', filters.currency(getCreditPrice(1000))]
              },
              {
                cells: ['2500 - 9999', filters.currency(getCreditPrice(2500))]
              }
            ]"
            :mb="2"
            v-test="'pricing-table'"
          />
          <div :class="$style.amountsWrap">
            <div
              v-for="(amount, index) in amounts"
              :key="index"
              :class="$style.cardWrap"
              @click="chosenAmount = amount"
              v-test="'credit-option'"
            >
              <BaseCard
                :gray="chosenAmount === amount"
                :selected="chosenAmount === amount"
              >
                <BaseText bold :mb="0.5" v-test="'credit-amount'">
                  {{ amount }} {{ $t('sms_credits.credit', amount) }}
                </BaseText>
                <div :class="$style.amountPrices">
                  <BaseText mr v-test="'credit-price-total'">
                    {{ filters.currency(amount * getCreditPrice(amount)) }}
                  </BaseText>
                  <BaseText
                    v-if="$screen !== 's'"
                    size="s"
                    v-test="'credit-price-single'"
                  >
                    {{ filters.currency(getCreditPrice(amount)) }} /
                    {{ $t('sms_credits.credit', 1) }}
                  </BaseText>
                </div>
              </BaseCard>
            </div>
          </div>
          <BaseCard v-show="hasCustomAmount" gray>
            <BaseText bold mb>
              {{ $t('sms_credits.custom_amount') }}
            </BaseText>
            <div :class="$style.customAmount">
              <BaseInput
                v-model="customAmount"
                type="number"
                :minValue="50"
                :label="$t('sms_credits.credits_amount')"
                mr
                v-test="'credits-custom-amount'"
              />
              <div :class="$style.customAmountRight">
                <div>
                  {{
                    filters.currency(
                      customAmount * getCreditPrice(customAmount)
                    )
                  }}
                </div>
                <div>
                  <BaseText size="s">
                    {{ filters.currency(getCreditPrice(customAmount)) }} /
                    credit
                  </BaseText>
                </div>
              </div>
            </div>
            <BaseText size="s">
              {{ $t('sms_credits.minimum_amount', { amount: 50 }) }}
            </BaseText>
          </BaseCard>
          <BaseText
            v-show="!hasCustomAmount"
            link
            @click="hasCustomAmount = true"
            v-test="'credits-other-amount'"
          >
            {{ $t('sms_credits.other_amount') }}
          </BaseText>
        </div>
      </div>
      <div :class="$style.footer">
        <div
          v-if="showPaymentProcessingAlert"
          :class="$style.footerAlertContainer"
        >
          <BaseAlert
            color="success"
            :text="$t('sms_credits.payment_processing')"
            mb
            v-test="'sms-credits-payment-processing-alert'"
          />
        </div>
        <div v-if="hasPaymentError" :class="$style.footerAlertContainer">
          <BaseAlert
            color="error"
            :text="$t('sms_credits.payment_error')"
            mb
            v-test="'sms-credits-payment-error-alert'"
          />
        </div>
      </div>
    </div>
    <template v-if="!isNativeAppIOS" #footer>
      <BaseButton
        v-if="isSubscribed"
        :loading="loading"
        submitForm
        v-test="'btn-credits-submit'"
      >
        {{ $t('sms_credits.button_submit') }}
      </BaseButton>
      <div v-else :class="$style.footerAlertContainer">
        <BaseAlert
          color="warning"
          :text="$t('sms_credits.no_subscription')"
          v-test="'credits-no-subscription'"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts">
export default {
  name: 'SmsCredits',
};
</script>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { useCompanyStore } from '@/stores/company';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useStorage } from '@vueuse/core';
import filters from '@/filters';
import { useRoute } from 'vue-router';
import { useNativeAppStore } from '@/stores/native-app';
import EmptyPageContent from '@/components/EmptyPageContent.vue';

defineProps<{
  parentRouteName: string;
}>();
defineEmits(['close']);

const showPricingTable = ref(false);

const { company, isSubscribed } = useCompanyStore();
const { isNativeAppIOS } = useNativeAppStore();
const currentBalance = ref(company.smsCredits || 0);

const showPaymentProcessingAlert = ref(false);
const hasPaymentError = ref(false);

const getCreditPrice = (amount: number) => {
  const creditPrices = company.smsCreditPricing;

  if (amount >= 2500) {
    return creditPrices[2];
  } else if (amount >= 1000) {
    return creditPrices[1];
  } else {
    return creditPrices[0];
  }
};

const amounts = [100, 500, 1000, 2500];
const chosenAmount = useStorage('sms_credit_amount', amounts[0]);
const customAmount = ref(50);
const hasCustomAmount = ref(false);
const route = useRoute();

watch(
  () => chosenAmount.value,
  (newValue) => {
    if (newValue && hasCustomAmount.value) {
      hasCustomAmount.value = false;
    }
  }
);
watch(
  () => hasCustomAmount.value,
  (newValue) => {
    if (newValue) {
      chosenAmount.value = 0;
    }
  }
);

const submittedAmount = computed(() =>
  hasCustomAmount.value ? customAmount.value : chosenAmount.value
);

const {
  mutate: stripeSmsCheckoutMutation,
  onDone: onStripeSmsDone,
  loading
} = useMutation(
  gql`
    mutation createSmsCheckoutSession($input: CreateSmsCheckoutSessionInput!) {
      createSmsCheckoutSession(input: $input) {
        url
        errors {
          message
        }
      }
    }
  `,
  () => ({
    variables: {
      input: {
        amount: submittedAmount.value
      }
    }
  })
);

onStripeSmsDone(
  ({
    data: {
      createSmsCheckoutSession: { url }
    }
  }) => {
    if (url) {
      window.location.href = url;
    }
  }
);

if (route.query.payment === 'failure') {
  hasPaymentError.value = true;
} else if (route.query.payment === 'success') {
  showPaymentProcessingAlert.value = true;
}
</script>

<style lang="scss" module>
.currentBalance {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 220px;
}

.amountsWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: calc(50% - #{$spacing * 0.5});
    margin-bottom: $spacing;
  }
}

.amountPrices {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.customAmount {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: $spacing * 0.5;
}

.customAmountRight {
  text-align: right;
}

.cardWrap {
  cursor: pointer;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: $spacing * 2;

  &:empty {
    display: none;
  }
}

.footerAlertContainer {
  align-self: stretch;
}
</style>
