<template>
  <div :class="$style.base">
    <BaseGrid container>
      <BaseGrid :size="8">
        <BaseHeading :mb="0.5">
          {{ $t('marketing.discounts.dynamic_pricing.title') }}
        </BaseHeading>
        <BaseText>
          {{ $t('marketing.discounts.dynamic_pricing.body') }}
        </BaseText>
        <BaseToggle
          v-model="toggleValue"
          mt
          :label="$t('marketing.discounts.dynamic_pricing.enable')"
          @update:modelValue="track($event)"
          v-test="'enable-dynamic-pricing'"
        />
        <div v-show="toggleValue" class="mt-1">
          <BaseButton
            :routerLink="{ name: 'calendar' }"
            color="inverted"
            @click="calenderBtnCliked"
            v-test="'go-to-calendar'"
          >
            {{ $t('global.actions.go_to_calendar') }}
          </BaseButton>
        </div>
      </BaseGrid>
      <BaseGrid :size="$screen === 'l' ? 4 : 12" alignRight>
        <img src="/img/marketing/dynamic-pricing.svg" />
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';
import { mapState, storeToRefs } from 'pinia';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  setup() {
    const { dynamicPricingMode } = storeToRefs(useCalendarFiltersStore());
    return {
      dynamicPricingMode,
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings', 'updateCompany']),
    toggleValue: {
      get() {
        return this.companySettings.bookings?.dynamicPricingEnabled || false;
      },
      set(value) {
        const input = {
          settings: {
            bookings: {
              dynamicPricingEnabled: value,
            },
          },
        };

        if (!value) {
          this.dynamicPricingMode = false;
        }

        this.updateCompany(input).then(() => {
          flash(
            this.$t(
              `global.flash.dynamic_pricing_${value ? 'enabled' : 'disabled'}`,
            ),
          );
        });
      },
    },
  },
  methods: {
    track(value) {
      this.mixpanel.track(`Dynamic pricing toggle: ${value}`);
    },
    calenderBtnCliked() {
      this.dynamicPricingMode = true;
      this.mixpanel.track('Go to calendar button clicked');
    },
  },
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing 0;
  border-bottom: 1px solid $color-border;
}
</style>
