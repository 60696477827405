import apolloClient from '@/apollo/client';
import { resourceFragment } from '@/graphql-fragments';
import { logValidationError } from '@/helpers/datadog';
import { flash, modal } from '@/helpers/ui';
import i18n from '@/i18n';
import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';
import type { Resource } from '@/types';
import gql from 'graphql-tag';
import { defineStore } from 'pinia';

interface State {
  allResources: Resource[];
}

export const useResourcesStore = defineStore('resources', {
  state: (): State => ({
    allResources: [],
  }),
  getters: {
    resources: (state) =>
      state.allResources.filter((resource) => resource.state === 'active'),
    resourcesByLocation(): Resource[] {
      const { locationId, dataScope } = useLocationsStore();
      const { multiLocation } = useCompanyStore();

      if (locationId && multiLocation && dataScope === 'LOCAL') {
        return this.resources.filter(
          (resource) =>
            !resource.locationIds || resource.locationIds.includes(locationId),
        );
      } else {
        return this.resources;
      }
    },
    resourcesByType() {
      return (type: string): Resource[] =>
        this.resourcesByLocation.filter((resource) => resource.type === type);
    },
    employees(): Resource[] {
      return this.resourcesByType('employee');
    },
    rooms(): Resource[] {
      return this.resourcesByType('room');
    },
    equipment(): Resource[] {
      return this.resourcesByType('equipment');
    },
    resourceById:
      (state) =>
      (id: number): Resource | null =>
        state.allResources.find((r) => r.id === id) || null,
    hasSingleEmployee(): boolean {
      return this.employees.length === 1;
    },
    hasSingleResource(): boolean {
      return this.resourcesByLocation.length === 1;
    },
    allResourcesByLocation(): Resource[] {
      const { locationId, dataScope } = useLocationsStore();
      const { multiLocation } = useCompanyStore();

      if (locationId && multiLocation && dataScope === 'LOCAL') {
        return this.allResources.filter(
          (resource) =>
            !resource.locationIds || resource.locationIds.includes(locationId),
        );
      } else {
        return this.allResources;
      }
    },
    allEmployees(): Resource[] {
      return this.allResourcesByLocation.filter(
        (resource) => resource.type === 'employee',
      );
    },
    allRoomsAndEquipment(): Resource[] {
      return this.allResourcesByLocation.filter(
        (resource) => resource.type === 'room' || resource.type === 'equipment',
      );
    },
  },
  actions: {
    getResources() {
      const { multiLocation } = useCompanyStore();

      return new Promise<void>((resolve) => {
        apolloClient
          .query({
            query: gql`
              query getResources($multiLocation: Boolean!) {
                resources {
                  ...resource
                  locationIds @include(if: $multiLocation)
                  servicesCount
                }
              }
              ${resourceFragment}
            `,
            variables: {
              multiLocation,
            },
          })
          .then(({ data: { resources } }) => {
            this.allResources = resources;
            resolve();
          });
      });
    },
    createResource(input: any) {
      return new Promise((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation createResource($input: CreateResourceInput!) {
                createResource(input: $input) {
                  resource {
                    ...resource
                    locationIds
                    servicesCount
                  }
                  errors
                }
              }
              ${resourceFragment}
            `,
            variables: { input },
          })
          .then(
            ({
              data: {
                createResource: { resource, errors },
              },
            }) => {
              this.allResources.push(resource);

              if (errors) {
                logValidationError('createResource', errors);
              }

              resolve(resource.id);
            },
          );
      });
    },
    updateResource(input: any) {
      return new Promise<void>((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation updateResource($input: UpdateResourceInput!) {
                updateResource(input: $input) {
                  resource {
                    ...resource
                    locationIds
                    servicesCount
                  }
                  errors
                }
              }
              ${resourceFragment}
            `,
            variables: { input },
          })
          .then(
            ({
              data: {
                updateResource: { resource, errors },
              },
            }) => {
              this.allResources = this.allResources.map((r) =>
                r.id === resource.id ? resource : r,
              );

              flash(i18n.t('global.flash.resource_updated'));

              if (errors) {
                logValidationError('updateResource', errors);
              }

              resolve();
            },
          );
      });
    },
    deleteResource(resource: Resource) {
      const type = resource.type === 'employee' ? 'employee' : 'resource';

      modal('confirmation', {
        type: 'delete',
        item: resource.name,
        subMessage: i18n.t(`${type}.delete_warning`),
      }).then(() => {
        this.updateResource({ id: resource.id, state: 'DELETED' });
      });
    },
    restoreResource(resource: Resource) {
      modal('confirmation', {
        message: i18n.t('global.confirmation.confirm_restore', {
          item: resource.name,
        }),
      }).then(() => {
        this.updateResource({ id: resource.id, state: 'ACTIVE' });
      });
    },
    sortResources(sortableAttributes: any) {
      const sortResources = (items: any) => {
        items.forEach((item: any) => {
          if (item.id) {
            const resource = this.resourceById(item.id);
            if (resource) {
              resource.sortOrder = item.sortOrder;
            }
          }
        });

        this.allResources.sort((a, b) => a.sortOrder - b.sortOrder);
      };

      sortResources(sortableAttributes);

      apolloClient
        .mutate({
          mutation: gql`
            mutation sortResources($input: SortResourcesInput!) {
              sortResources(input: $input) {
                resources {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              sortableAttributes,
            },
          },
        })
        .then(() => {
          flash(i18n.t('global.flash.resources_updated'));
        });
    },
  },
});
