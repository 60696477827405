<template>
  <transition
    :appear="appear"
    @enter="enter"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's',
          [$style.autoPosition]: autoPositioning,
          [$style.positionRight]: positionRight,
          [$style.positionBottom]: positionBottom,
          [$style.alignRight]: alignRight,
          [$style.large]: large,
          [$style.autoWidth]: autoWidth
        }
      ]"
      :style="style"
    >
      <div v-if="closeButton" :class="$style.close">
        <BaseIcon
          name="close"
          clickable
          @click="close"
          v-test="'popover-close'"
        />
      </div>
      <div v-if="listItems.length > 0">
        <div
          v-for="item in listItems"
          :key="item.value"
          :class="$style.listItem"
          @click="$emit('select', item.value)"
          v-test="`popover-list-item-${item.value}`"
        >
          <BaseText :iconBefore="item.icon">
            {{ item.label }}
          </BaseText>
        </div>
      </div>
      <div
        v-else
        :class="[
          $style.content,
          {
            [$style.removeBottomPadding]: removeBottomPadding
          }
        ]"
      >
        <slot />
      </div>
      <div v-if="$slots.footer" :class="$style.footer">
        <slot name="footer" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { IconName } from './base-icon/types';
import { usePageLayoutStore } from '@/stores/page-layout';

export default defineComponent({
  //inheritAttrs: false,
  props: {
    position: {
      type: Object,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    keepVisibleOn: {
      type: Element,
    },
    removeBottomPadding: {
      type: Boolean,
      default: false,
    },
    autoPositioning: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    scrollContainer: {
      type: HTMLElement as PropType<HTMLElement>,
    },
    large: {
      type: Boolean,
      default: false,
    },
    listItems: {
      type: Array as PropType<
        { label: string; value: string | number; icon?: IconName }[]
      >,
      default: () => [],
    },
    appear: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'select'],
  data() {
    return {
      isAnimated: false,
      positionRight: false,
      positionBottom: false,
      autoPosition: null,
    };
  },
  watch: {
    isAnimated(value) {
      value ? this.addEventListeners() : this.removeEventListeners();
    },
  },
  computed: {
    style() {
      const style = {};

      const position = this.position || this.autoPosition;
      if (position) {
        Object.keys(position).forEach((property) => {
          style[property] = `${Math.round(position[property])}px`;
        });
      }

      return style;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onDocumentDown(e) {
      if (
        this.isAnimated &&
        !this.$el.contains(e.target) &&
        !e.target.isSameNode(this.keepVisibleOn) &&
        (!this.keepVisibleOn || !this.keepVisibleOn.contains(e.target))
      ) {
        this.close();
      }
    },
    setPosition() {
      // When using automatic positioning and the popover doesn't fit the screen either horizontally or vertically, position it using right and/or bottom
      if (!this.autoPositioning) {
        return;
      }

      const rect = this.$el.getBoundingClientRect();

      // .95 is the transform applied in the css
      // We need to change the values based on that, since we're setting the position while the transform is applied

      const transform = 1 / 0.95;
      const width = rect.width * transform;
      const height = rect.height * transform;
      const x = rect.x - (width - rect.width);
      const y = rect.y - (height - rect.height);

      if (this.$screen === 's') {
        const { headerHeight } = usePageLayoutStore();

        this.autoPosition = {
          left: (window.innerWidth - width) / 2,
          top: (window.innerHeight - headerHeight - height) / 2,
        };
      } else {
        const xBoundary = x + width;
        const yBoundary = y + height;
        let xMax;
        let yMax;

        if (this.scrollContainer) {
          const containerRect = this.scrollContainer.getBoundingClientRect();
          xMax = containerRect.x + Math.round(containerRect.width);
          yMax = containerRect.y + Math.round(containerRect.height);
        } else {
          xMax = window.innerWidth;
          yMax = window.innerHeight;
        }
        if (xBoundary > xMax) {
          this.positionRight = true;
        }
        if (yBoundary > yMax) {
          this.positionBottom = true;
        }
      }
    },
    resetPosition() {
      this.positionRight = false;
      this.positionBottom = false;
      this.autoPosition = null;
    },
    enter() {
      if (this.$screen === 's' && this.autoPosition) {
        return;
      }

      if (this.autoPositioning) {
        this.setPosition();
      }
    },
    afterEnter() {
      this.isAnimated = true;
    },
    afterLeave() {
      this.isAnimated = false;
      this.resetPosition();
    },
    addEventListeners() {
      document.addEventListener('mousedown', this.onDocumentDown);
      document.addEventListener('touchstart', this.onDocumentDown);
    },
    removeEventListeners() {
      document.removeEventListener('mousedown', this.onDocumentDown);
      document.removeEventListener('touchstart', this.onDocumentDown);
    },
  },
  beforeUnmount() {
    this.removeEventListeners();
  },
});
</script>

<style lang="scss" module>
.base {
  position: absolute;
  width: 300px;
  max-width: 300px;
  border-radius: $radius;
  background: $white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  z-index: 230;
  text-align: left;

  &.autoWidth {
    width: auto;
  }

  &:global(.v-enter-active) {
    transition: transform 0.1s $easeOutBack;
  }
  &:global(.v-leave-active) {
    transition: opacity 0.06s;
  }
  &:global(.v-enter-from) {
    transform: scale(
      0.95
    ); // Don't change unless changing the value in the javascript code as well
  }
  &:global(.v-leave-to) {
    opacity: 0;
  }

  &:not(.autoPosition) {
    &.alignRight {
      right: $spacing * 0.5;
    }
  }

  &.large {
    width: 330px;
    max-width: 330px;
  }

  &.autoPosition {
    &.positionRight {
      left: auto;
      right: 0;
    }

    &.positionBottom {
      top: auto;
      bottom: 0;
    }
  }
}

.listItem {
  cursor: pointer;
  padding: $spacing;

  @include hover {
    background-color: $color-highlight;
  }

  &:not(:first-child) {
    border-top: 1px solid $color-border;
  }

  &:first-child {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }

  &:last-child {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  padding: $spacing * 0.5;
  z-index: 1;
}

.content {
  padding: $spacing;
  &.removeBottomPadding {
    padding-bottom: 0px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: $spacing;
  border-top: 1px solid $color-border;
}
</style>
