<template>
  <div :class="$style.printHeader">
    <BaseText bold>
      {{
        filters.date(start, {
          format: 'long',
          addYear: true
        })
      }}
      -
      {{
        filters.date(end, {
          format: 'long',
          addYear: true
        })
      }}
    </BaseText>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
    },
  },
  setup() {
    return {
      filters,
    };
  },
});
</script>

<style lang="scss" module>
.printHeader {
  display: none;
  text-align: right;
}

@media print {
  .printHeader {
    display: block;
  }
}
</style>
