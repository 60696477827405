import apolloClient from '@/apollo/client';
import { calendarNoteFragment, noteFragment } from '@/graphql-fragments';
import { logValidationError } from '@/helpers/datadog';
import { flash } from '@/helpers/ui';
import i18n from '@/i18n';
import gql from 'graphql-tag';
import { isCalendarSaving } from '../calendar-state';
import {
  createCalendarEvent,
  deleteCalendarEvent,
  updateCalendarEvent,
} from './calendar-events';

export const createNote = (input: any) =>
  new Promise((resolve) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation createNote($input: CreateNoteInput!) {
            createNote(input: $input) {
              note {
                ...note
              }
              calendarNote {
                ...calendarNote
              }
              errors
            }
          }
          ${noteFragment}
          ${calendarNoteFragment}
        `,
        variables: {
          input,
        },
      })
      .then(
        ({
          data: {
            createNote: { calendarNote, errors },
          },
        }) => {
          flash(i18n.t('global.flash.note_created'));

          if (errors) {
            logValidationError('createNote', errors, input);
          }

          resolve(calendarNote);
          createCalendarEvent(calendarNote);
        },
      );
  });

export const updateNote = (input: any) =>
  new Promise<void>((resolve) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation updateNote($input: UpdateNoteInput!) {
            updateNote(input: $input) {
              note {
                ...note
              }
              calendarNote {
                ...calendarNote
              }
              errors
            }
          }
          ${noteFragment}
          ${calendarNoteFragment}
        `,
        variables: {
          input,
        },
      })
      .then(
        ({
          data: {
            updateNote: { calendarNote, errors },
          },
        }) => {
          flash(i18n.t('global.flash.note_updated'));

          updateCalendarEvent(calendarNote);

          if (errors) {
            logValidationError('updateNote', errors, input);
          }

          resolve();
        },
      );
  });

export const deleteNote = (id: any) =>
  new Promise<void>((resolve) => {
    isCalendarSaving.value = true;
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteNote($input: DeleteNoteInput!) {
            deleteNote(input: $input) {
              calendarNote {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            id,
          },
        },
      })
      .then(
        ({
          data: {
            deleteNote: { calendarNote },
          },
        }) => {
          flash(i18n.t('global.flash.note_deleted'));

          deleteCalendarEvent(calendarNote.id);
          resolve();
        },
      )
      .finally(() => (isCalendarSaving.value = false));
  });
