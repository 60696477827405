<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <div v-if="!cashupEnabled" :class="$style.dropdown">
      <BaseDatePicker
        v-model="order.invoicedAt"
        v-test="'register-order-cashup-date'"
      />
    </div>
    <div v-if="!hasSingleEmployee" :class="$style.dropdown">
      <BaseDropdown
        v-model="selectedEmployeeId"
        :options="employeeList"
        :searchable="employeeList.length > 6"
        :required="!!order.items.length"
        resources
        @update:modelValue="trackEmployee"
        v-test="'register-order-employee-dropdown'"
      />
    </div>
    <div :class="$style.search">
      <RegisterCustomerSearch />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useResourcesStore } from '@/stores/resources';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useRegisterStore } from '@/modules/register/stores/register';
import RegisterCustomerSearch from './RegisterCustomerSearch.vue';
import { storeToRefs } from 'pinia';

const { employees, hasSingleEmployee } = storeToRefs(useResourcesStore());
const { order } = useRegisterOrderStore();
const { selectedEmployeeId } = storeToRefs(useRegisterOrderStore());
const { cashupEnabled } = storeToRefs(useRegisterStore());
const mixpanel = inject<any>('mixpanel');

const trackEmployee = () => {
  mixpanel.track('Register - Employee changed');
};

const { t } = useI18n();

const employeeList = computed(() => {
  const list = employees.value.map((employee: any) => ({
    value: employee.id,
    label: employee.name,
  }));

  list.unshift({ value: 'multiple', label: t('global.multiple') });
  return list;
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: $spacing;
  position: relative;
  z-index: 2; // Fixes issue with dropdowns/popovers in Safari 18

  &.smallScreen {
    flex-direction: column;
  }
}

.dropdown {
  width: 50%;
  flex: 1;
  min-width: 200px;

  .base.smallScreen & {
    width: 100%;
  }
}

.search {
  flex: 2;
}
</style>
