<template>
  <div />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { browser } from '@/user-context';

export default defineComponent({
  props: {
    url: {
      type: String,
    },
  },
  emits: ['close'],
  methods: {
    closePrint() {
      this.$emit('close');
      const iframe = document.querySelector('.js-print-iframe');
      document.body.removeChild(iframe);
    },
    setPrint() {
      const iframe = document.querySelector('.js-print-iframe').contentWindow;
      iframe.onbeforeunload = this.closePrint;
      iframe.onafterprint = this.closePrint;
      iframe.focus(); // Required for IE
      iframe.print();
    },
  },
  mounted() {
    if (
      browser.isSafari ||
      browser.isFirefox ||
      browser.isSamsungBrowser ||
      browser.isAndroid
    ) {
      setTimeout(() => {
        window.open(this.url);
      }, 0);
    } else {
      const oHiddFrame = document.createElement('iframe');
      oHiddFrame.classList.add('js-print-iframe');
      oHiddFrame.style.position = 'fixed';
      oHiddFrame.style.right = '0';
      oHiddFrame.style.bottom = '0';
      oHiddFrame.style.width = '0';
      oHiddFrame.style.height = '0';
      oHiddFrame.style.border = '0';
      oHiddFrame.src = this.url;
      document.body.appendChild(oHiddFrame);
      this.setPrint();
    }
  },
});
</script>
