export const terminals = [
  {
    id: 1,
    name: 'AMS1',
    key: 'AMS1',
    price: 19900,
    info: ['touchscreen', 'without_print', 'easily_portable'],
    printer: false,
    touchscreen: true,
    wifi: true,
    portable: true,
    '4g': true,
  },
  {
    id: 2,
    name: 'V400c Plus',
    key: 'V400CPLUS',
    price: 35000,
    info: ['keypad', 'countertop', 'receipt'],
    printer: true,
    touchscreen: true,
    wifi: true,
    portable: false,
  },
];
