import {
  AddressFields,
  CompanyPublicLanguage,
  DateOfBirth,
  OnlinePayment,
  PreventGaps,
  ResourceSelection,
} from '@/types';
import { useI18n } from 'vue-i18n';

export const useBookingSettingsOptions = () => {
  const { t } = useI18n();

  const publicLanguages = Object.values(CompanyPublicLanguage).map(
    (language: string) => ({
      value: language,
      label: t(`global.languages.${language.toLowerCase()}`),
    }),
  );

  const preventGapsOptions = Object.values(PreventGaps)
    .filter((value) => value !== 'DISABLED')
    .map((prevent_gaps_option: string) => ({
      value: prevent_gaps_option,
      label: t(
        `admin.booking.prevent_gaps.options.${prevent_gaps_option.toLowerCase()}`,
      ),
    }));

  const resourceSelectionOptions = Object.values(ResourceSelection)
    .filter((value) => value !== 'AUTOMATIC')
    .map((resource_selection_option: string) => ({
      value: resource_selection_option,
      label: t(
        `admin.booking.resource_selection.options.${resource_selection_option.toLowerCase()}`,
      ),
    }));

  const appointmentRequiresConfirmationOptions: {
    value: boolean;
    label: string;
  }[] = [
    {
      value: false,
      label: t('admin.booking.appointment_requests.main.book_online'),
    },
    {
      value: true,
      label: t('admin.booking.appointment_requests.main.make_requests'),
    },
  ];

  const appointmentRequestNewUsersOptions: { value: boolean; label: string }[] =
    [
      {
        value: false,
        label: t('admin.booking.appointment_requests.customers.all'),
      },
      {
        value: true,
        label: t('admin.booking.appointment_requests.customers.new'),
      },
    ];

  const appointmentRequestServiceOptions: { value: string; label: string }[] = [
    {
      value: 'ALL',
      label: t('admin.booking.appointment_requests.services.all'),
    },
    {
      value: 'PRICE',
      label: t('admin.booking.appointment_requests.services.over_price'),
    },
    {
      value: 'DURATION',
      label: t('admin.booking.appointment_requests.services.over_duration'),
    },
  ];

  const newCustomersCanBookOptions: { value: boolean; label: string }[] = [
    {
      value: true,
      label: t('admin.booking.allow_new_customers.new_and_existing'),
    },
    {
      value: false,
      label: t('admin.booking.allow_new_customers.existing_only'),
    },
  ];

  const addressFieldsOptions = Object.values(AddressFields)
    .filter((value) => value !== 'HIDDEN')
    .map((address_fields_option: string) => ({
      value: address_fields_option,
      label: t(
        `admin.booking.address_fields.options.${address_fields_option.toLowerCase()}`,
      ),
    }));

  const dateOfBirthOptions = Object.values(DateOfBirth)
    .filter((value) => value !== 'DISABLED')
    .map((birthdate_option: string) => ({
      value: birthdate_option,
      label: t(
        `admin.booking.birthdate.options.${birthdate_option.toLowerCase()}`,
      ),
    }));

  const onlinePaymentOptions = Object.values(OnlinePayment)
    .filter((value) => value !== 'DISABLED')
    .map((online_payment_option: string) => ({
      value: online_payment_option,
      label: t(
        `admin.booking.online_payment.options.${online_payment_option.toLowerCase()}`,
      ),
    }));

  const leadTimeOptions = [0, 30, 60, 120, 240, 480, 720, 1440, 2880, 4320].map(
    (minutes) => {
      const hours = minutes / 60;
      const label = !minutes
        ? t('global.none')
        : hours >= 1
          ? `${hours} ${t('global.items.hour_short', hours)}`
          : `${minutes} ${t('global.items.minute_short', minutes)}`;
      const value = minutes;

      return {
        value,
        label,
      };
    },
  );

  const maxTimeInAdvanceOptions = [
    1, 2, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48,
  ].map((weeks) => {
    const months = weeks / 4;
    const value = months >= 1 ? `${months}.months` : `${weeks}.weeks`;
    const label =
      months >= 1
        ? `${months} ${t('global.items.month', months)}`
        : `${weeks} ${t('global.items.week', weeks)}`;

    return {
      value,
      label,
    };
  });

  const scheduleViewOptions = [
    {
      value: false,
      label: t('promote.booking_widget.options.schedule_view.week'),
    },
    {
      value: true,
      label: t('promote.booking_widget.options.schedule_view.week_and_month'),
    },
  ];

  const buttonPositionOptions = [
    {
      value: 'RIGHT',
      label: t('promote.booking_widget.options.button_position.right'),
    },
    {
      value: 'LEFT',
      label: t('promote.booking_widget.options.button_position.left'),
    },
  ];

  const widthSizeOptions = [
    { value: true, label: t('promote.booking_widget.options.width.auto') },
    {
      value: false,
      label: t('promote.booking_widget.options.width.specify_width'),
    },
  ];

  enum ShareSettingsType {
    LINK = 'link',
    BUTTON = 'button',
    WIDGET = 'widget',
  }

  const shareSettings = Object.freeze(ShareSettingsType);

  const shareSettingsOptions = [
    {
      value: ShareSettingsType.LINK,
      label: t('promote.booking_widget.link_view'),
    },
    {
      value: ShareSettingsType.BUTTON,
      label: t('promote.booking_widget.button_view'),
    },
    {
      value: ShareSettingsType.WIDGET,
      label: t('promote.booking_widget.widget_view'),
    },
  ];

  return {
    publicLanguages,
    preventGapsOptions,
    appointmentRequiresConfirmationOptions,
    appointmentRequestNewUsersOptions,
    appointmentRequestServiceOptions,
    resourceSelectionOptions,
    addressFieldsOptions,
    dateOfBirthOptions,
    onlinePaymentOptions,
    leadTimeOptions,
    maxTimeInAdvanceOptions,
    newCustomersCanBookOptions,
    scheduleViewOptions,
    buttonPositionOptions,
    widthSizeOptions,
    shareSettings,
    shareSettingsOptions,
  };
};
