<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm'
      }
    ]"
  >
    <div :class="$style.searchHolder">
      <BaseHeading size="xl" mb>
        {{ $t('support.hub.heading') }}
      </BaseHeading>
      <div :class="$style.searchInput">
        <BaseForm @submit="() => search(keyword)">
          <BaseSearch
            ref="searchInputEl"
            v-model="keyword"
            :placeholder="$t('support.hub.search.placeholder')"
          />
        </BaseForm>
        <div
          v-show="isExpanded && !keyword"
          ref="dropdownEl"
          :class="$style.floating"
        >
          <div :class="$style.scrollarea">
            <BaseHeading size="s" ml mt :mb="0.5">
              {{ $t('support.hub.search.quick_links') }}
            </BaseHeading>
            <div
              v-for="(item, index) in popularQuestions"
              :key="index"
              :class="$style.item"
              tabindex="0"
              @click="search(item.query)"
              @keyup.enter="search(item.query)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.main">
      <BaseHeading mb>
        {{ $t('support.hub.topics.heading') }}
      </BaseHeading>
      <div :class="$style.categories">
        <BaseCard
          v-for="topic in supportTopics"
          :key="topic.icon"
          gray
          fullWidth
          noPadding
        >
          <div
            :class="$style.topic"
            :style="{ 'background-image': `url(${topic.imageUrl})` }"
            @click="open(topic.query)"
          >
            <BaseIcon :name="topic.icon" size="l" :mb="0.5" />
            <BaseHeading>
              {{ topic.label }}
            </BaseHeading>
          </div>
        </BaseCard>
      </div>
      <div :class="$style.bottom">
        <BaseCard :heading="$t('support.hub.idea_box.heading')" gray>
          {{ $t('support.hub.idea_box.description') }}
          <BaseButton
            color="inverted"
            fullWidth
            mt
            @click="
              open(
                'https://portal.productboard.com/salonized/1-salonized-ideas-box/tabs/4-ideas'
              )
            "
          >
            {{ $t('support.hub.idea_box.button') }}
          </BaseButton>
        </BaseCard>
        <div :class="$style.popularArticlesWrap">
          <BaseHeading mb>
            {{ $t('support.hub.popular_articles.heading') }}
          </BaseHeading>
          <div :class="$style.popularArticles">
            <div
              v-for="(article, index) in popularArticles"
              :key="`article_${index}`"
              :class="$style.article"
              @click="open(article.link)"
            >
              <BaseText>
                {{ article.heading }}
              </BaseText>
              <BaseIcon name="arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <BaseText :mt="2" :routerLink="{ name: 'admin' }">
        {{ $t('support.hub.banner') }}
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useCompanyStore } from '@/stores/company';
import type { IconName } from '@/components/base-icon/types';
import { onClickOutside } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { company } = useCompanyStore();

const helpUrl = `https://help.salonized.com/${company.language}`;

const isExpanded = ref(false);
const keyword = ref('');
const searchInputEl = ref();
const dropdownEl = ref();

onClickOutside(
  dropdownEl,
  () => {
    isExpanded.value = false;
  },
  {
    ignore: [searchInputEl],
  },
);

const search = (keyword: string) =>
  window.open(`${helpUrl}?q=${encodeURI(keyword)}`, '_blank');
const open = (link: string) => window.open(link, '_blank');

const popularQuestions = [
  {
    text: t('support.hub.search.popular_queries.query_1.text'),
    query: t('support.hub.search.popular_queries.query_1.query'),
  },
  {
    text: t('support.hub.search.popular_queries.query_2.text'),
    query: t('support.hub.search.popular_queries.query_2.query'),
  },
  {
    text: t('support.hub.search.popular_queries.query_3.text'),
    query: t('support.hub.search.popular_queries.query_3.query'),
  },
];

const popularArticles = [
  {
    heading: t('support.hub.popular_articles.article_1.heading'),
    link: t('support.hub.popular_articles.article_1.link'),
  },
  {
    heading: t('support.hub.popular_articles.article_2.heading'),
    link: t('support.hub.popular_articles.article_2.link'),
  },
  {
    heading: t('support.hub.popular_articles.article_3.heading'),
    link: t('support.hub.popular_articles.article_3.link'),
  },
  {
    heading: t('support.hub.popular_articles.article_4.heading'),
    link: t('support.hub.popular_articles.article_4.link'),
  },
  {
    heading: t('support.hub.popular_articles.article_5.heading'),
    link: t('support.hub.popular_articles.article_5.link'),
  },
  {
    heading: t('support.hub.popular_articles.article_6.heading'),
    link: t('support.hub.popular_articles.article_6.link'),
  },
];

const supportTopics: {
  icon: IconName;
  label: string;
  query: string;
  imageUrl: string;
}[] = [
  {
    icon: 'calendar',
    label: t('support.hub.topics.calendar.title'),
    query: t('support.hub.topics.calendar.query'),
    imageUrl: '/img/support/calendar.png',
  },
  {
    icon: 'people',
    label: t('support.hub.topics.customers.title'),
    query: t('support.hub.topics.customers.query'),
    imageUrl: '/img/support/customers.png',
  },
  {
    icon: 'shopping-cart',
    label: t('support.hub.topics.register.title'),
    query: t('support.hub.topics.register.query'),
    imageUrl: '/img/support/register.png',
  },
  {
    icon: 'treatwell',
    label: t('support.hub.topics.treatwell.title'),
    query: t('support.hub.topics.treatwell.query'),
    imageUrl: '/img/support/treatwell.png',
  },
];
</script>

<style lang="scss" module>
.searchHolder {
  background-color: $color-highlight;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $spacing * 3 $spacing;
}
.searchInput {
  position: relative;
  max-width: 100%;
  width: 600px;
}
.searchLabels {
  display: flex;
  margin-top: $spacing;

  & > *:not(:last-child) {
    margin-right: $spacing * 0.5;
  }
}
.floating {
  position: absolute;
  top: calc(100% + $spacing * 0.25);
  left: 0;
  min-width: 180px;
  width: 100%;
  border-radius: $radius;
  background: $white;
  box-shadow: $shadow;
  z-index: 10;
}

.item {
  padding: $spacing;
  cursor: pointer;

  @include hover {
    background-color: $color-highlight;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }
}

.categories {
  .base:not(.smallScreen):not(.mediumScreen) & {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > *:not(:last-child) {
      margin-right: $spacing;
    }
  }

  .base.smallScreen &,
  .base.mediumScreen & {
    & > *:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}

.topic {
  cursor: pointer;
  padding: $spacing * 3 $spacing $spacing;
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: bottom right;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-end;
}

.main {
  padding: 0 $spacing;
  margin: $spacing * 2 auto 0;
  max-width: 1000px;
}

.bottom {
  margin-top: $spacing * 3;

  .base:not(.smallScreen):not(.mediumScreen) & {
    display: flex;
    justify-content: space-between;

    & > * {
      width: calc(50% - #{$spacing * 0.5});
    }
  }
}

.popularArticlesWrap {
  padding: $spacing;
}

.popularArticles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 $spacing * -0.5;
}

.article {
  width: calc(50% - $spacing);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: $spacing * 0.5;
  margin-bottom: $spacing * 0.5;
  margin-right: $spacing * 0.5;

  @include hover {
    background-color: $color-highlight;
  }
}
</style>
