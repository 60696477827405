<template>
  <BaseModalSmall small @close="$emit('close')">
    <BaseText mb>
      {{ $t('customers.export_type') }}
    </BaseText>
    <BaseRadio
      v-model="fileType"
      :options="[
        {
          label: 'CSV',
          value: 'csv'
        },
        {
          label: 'XLS',
          value: 'xls'
        }
      ]"
      v-test="'export-file-type-selection'"
    />
    <BaseAlert
      v-if="unleash.isEnabled('AsyncExports')"
      :text="
        $t('customers.async_export_disclaimer', {
          email: company.notificationEmail
        })
      "
      mt
    />
    <template #footer>
      <BaseButton @click="submit" v-test="'export-file'">
        {{ $t('global.actions.export') }}
      </BaseButton>
    </template>
  </BaseModalSmall>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import unleash from '@/unleash';
const { company } = useCompanyStore();
const fileType = ref('csv');
const emit = defineEmits(['close', 'submit']);

const mixpanel = inject<any>('mixpanel');

const submit = () => {
  emit('submit', fileType.value);
  mixpanel.track('batch_action_done', {
    action_name: 'export',
    file_type: fileType.value,
  });
  emit('close');
};
</script>
