<template>
  <BaseVisualBanner
    :heading="$t('marketing.insights.marketing_area.title')"
    :description="$t('marketing.insights.marketing_area.description')"
    imagePath="/img/marketing/banner.svg"
    variant="dark"
  />
  <div v-if="showRequestTrial" :class="$style.upgrade">
    <div v-test="'request-trial-section'">
      <BaseGrid container>
        <BaseGrid :size="$screen === 'l' ? 6 : 12">
          <div>
            <BaseText bold v-test="'requested-text'">
              {{ bottomHeader }}
            </BaseText>
            <BaseText>
              {{ bottomDesc }}
            </BaseText>
          </div>
        </BaseGrid>
        <BaseGrid
          :size="$screen === 'l' ? 6 : 12"
          alignCenter
          :alignRight="$screen === 'l'"
          v-test="'request-trial-button-section'"
        >
          <div
            :class="[
              $style.request,
              {
                [$style.largeScreen]: $screen === 'l'
              }
            ]"
          >
            <BaseButton
              :loading="isLoading"
              @click="onBtnClick"
              v-test="'request-trial'"
            >
              {{
                marketingTrialEndsAt
                  ? $t('global.actions.upgrade')
                  : $t(
                      'marketing.insights.marketing_area.request_trial.request_free_trial'
                    )
              }}
            </BaseButton>
          </div>
        </BaseGrid>
      </BaseGrid>
      <div class="mt-1">
        <BaseText
          link
          :href="`https://survey.survicate.com/${company.language === 'nl' ? 'e8c6b532a094585d' : '645d6625e4a2ec13'}/?p=intercom&first_name=${user.firstName}&last_name=${user.lastName}&email=${user.email}&uid=${user.id}`"
        >
          {{
            $t(
              'marketing.insights.marketing_area.request_trial.help_us_improve'
            )
          }}
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';
import onUpgradeButtonClick from '@/modules/_shared/subscription/on-upgrade-button-click';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    showRequestTrial: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { handleUpgradeButtonClick } = onUpgradeButtonClick();
    return {
      handleUpgradeButtonClick,
    };
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    ...mapState(useUserStore, ['user', 'hasFeatureFlag']),
    marketingTrialEndsAt() {
      return this.company.marketingTrialEndsAt;
    },
    bottomHeader() {
      return this.$t(
        `marketing.insights.marketing_area.request_trial.${this.marketingTrialEndsAt ? 'title_upgrade' : 'title'}`,
      );
    },
    bottomDesc() {
      return this.$t(
        `marketing.insights.marketing_area.request_trial.${this.marketingTrialEndsAt ? 'description_upgrade' : 'description'}`,
      );
    },
  },
  methods: {
    onBtnClick() {
      if (this.marketingTrialEndsAt) {
        this.handleUpgradeButtonClick();
      } else {
        this.onRequestTrial();
      }
    },
    onRequestTrial() {
      this.isLoading = true;

      const input = {
        moduleName: 'marketing',
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation activateTrial($input: ActivateTrialInput!) {
              activateTrial(input: $input) {
                trialEndsAt
                errors
              }
            }
          `,
          variables: {
            input,
          },
        })
        .then(() => {
          const { getUser } = useUserStore();
          getUser().finally(() => {
            this.isLoading = false;
          });
        })
        .catch((error) => {
          this.isLoading = false;
          return error;
        });
    },
  },
});
</script>

<style lang="scss" module>
.request {
  display: flex;
  align-items: center;

  &:not(.largeScreen) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.upgrade {
  background-color: #cff6ee;
  padding: $spacing * 1.5;
  border-radius: $radius;
  margin-bottom: $spacing * 2;
}
</style>
