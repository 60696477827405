<template>
  <BaseModal
    :heading="$t('register.invoice.details')"
    :loading="!order"
    :details="details"
    :customer="order ? order.customer : null"
    :label="order ? order.state : null"
    :actions="actions"
    :parentRoute="{
      name: parentRouteName
    }"
    @action="onActionClick"
  >
    <div v-if="order.comments">
      <BaseHeading :mb="0.5">
        {{ filters.capitalize($t('global.items.note', 2)) }}
      </BaseHeading>
      <BaseText :mb="1.5">
        <span v-html="filters.formatEnters(order.comments)" />
      </BaseText>
    </div>
    <OrderItems
      :items="order.items"
      :discountCodes="uniqueDiscountCodes"
      :totalAmount="order.total"
      mb
    />
    <Appointments :appointments="order.appointments" mb />
    <Transactions
      :transactions="order.transactions"
      :totalAmount="order.total - order.amountOutstanding"
      mb
    />
    <TimelineItems />
    <PrintUrl v-if="promptPrint" :url="printUrl" />
    <CreateNoteModal
      v-if="showCreateNoteModal"
      :currentNote="order.comments"
      @close="showCreateNoteModal = false"
      @submit="setNote"
    />
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import OrderItems from './OrderItems.vue';
import Appointments from './Appointments.vue';
import Transactions from './Transactions.vue';
import TimelineItems from './TimelineItems.vue';
import { uniqueDiscountCodes } from '@/helpers/formatting';
import gql from 'graphql-tag';
import PrintUrl from '@/components/PrintUrl.vue';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import config from '@/config';
import { defineComponent } from 'vue';
import unleash from '@/unleash';
import CreateNoteModal from '@/modals/CreateNote.vue';

export default defineComponent({
  name: 'Invoice',
  components: {
    OrderItems,
    Appointments,
    Transactions,
    TimelineItems,
    PrintUrl,
    CreateNoteModal,
  },
  props: {
    parentRouteName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      filters,
    };
  },
  data() {
    return {
      promptPrint: false,
      showCreateNoteModal: false,
    };
  },
  apollo: {
    order: {
      query: gql`
        query getOrder($id: ID!) {
          order(id: $id) {
            amountOutstanding
            appointments {
              id
              serviceNames
              startAt
            }
            customer {
              email
              fullName
              id
              mobilePhone
              phone
              picture
            }
            draft
            fiscalizationFinished
            id
            invoicedAt
            items {
              description
              employee {
                id
                name
              }
              id
              price
              quantity
            }
            location {
              internalName
            }
            medical
            number
            permissions {
              credit
              delete
              edit
              editOrderItems
            }
            state
            total
            transactions {
              amount
              deletable
              giftcard {
                code
              }
              type
            }
            comments
          }
        }
      `,
      variables() {
        return {
          id: this.orderId,
        };
      },
      skip() {
        return !this.orderId;
      },
    },
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings', 'multiLocation']),
    orderId() {
      return this.$route.params.id;
    },
    needsFiscalizationData() {
      return (
        this.companySettings.fiscalization.tssId &&
        this.invoice &&
        !this.invoice.fiscalizationFinished
      );
    },
    showPrintReceipt() {
      return (
        this.companySettings.sales.thermalSupport &&
        !this.needsFiscalizationData
      );
    },
    printUrl() {
      return `${config.backendUrl}/orders/${this.order.id}`;
    },
    hasTerminalTransaction() {
      return (
        unleash.isEnabled('SalonizedPay') &&
        !!this.order?.transactions.find(
          (transaction) => transaction.type === 'POS',
        )
      );
    },
    actions() {
      return this.order
        ? [
            {
              name: 'invoice',
              icon: 'pdf',
              label: this.filters.capitalize(
                this.$t('global.actions.view_item', { item: 'PDF' }),
              ),
              href: `${config.backendUrl}/orders/${this.orderId}.pdf`,
              hide: this.needsFiscalizationData,
            },
            {
              name: 'email',
              icon: 'email',
              label: this.filters.capitalize(
                this.$t('global.actions.email_item', {
                  item: this.$t('global.items.invoice', 1),
                }),
              ),
              routerLink: this.$route.params.customerId
                ? {
                    name: 'customer-invoice-email',
                    params: {
                      id: this.orderId,
                      customerId: this.$route.params.customerId,
                    },
                  }
                : {
                    name: 'invoice-email',
                    params: {
                      id: this.orderId,
                    },
                  },
              hide: this.needsFiscalizationData,
            },
            {
              name: 'print',
              icon: 'printer',
              label: this.filters.capitalize(
                this.$t('global.actions.print_receipt'),
              ),
              hide: !this.showPrintReceipt,
            },
            {
              name: 'note',
              icon: 'message-circle',
              label: this.filters.capitalize(
                this.$t(
                  this.order.comments
                    ? 'global.actions.edit_item'
                    : 'global.actions.add_note',
                ),
              ),
            },
            {
              name: 'credit',
              icon: 'copy',
              label: this.filters.capitalize(
                this.$t('global.actions.create_credit_invoice'),
              ),
              hide: !this.order.permissions.credit,
            },
            {
              name: 'pay',
              icon: 'shopping-cart',
              label: this.filters.capitalize(
                this.$t('global.actions.pay_item', {
                  item: this.$t('global.items.invoice', 1),
                }),
              ),
              hide:
                !this.order.permissions.edit ||
                this.order.state !== 'OUTSTANDING',
            },
            {
              name: 'edit',
              icon: 'edit',
              label: this.filters.capitalize(
                this.$t('global.actions.edit_item', {
                  item: this.$t('global.items.invoice', 1),
                }),
              ),
              hide: !this.order.permissions.editOrderItems,
            },
            {
              name: 'delete',
              icon: 'delete',
              label: this.filters.capitalize(
                this.$t('global.actions.delete_item', {
                  item: this.$t('global.items.invoice', 1),
                }),
              ),
              hide:
                !this.order.permissions.delete && !this.hasTerminalTransaction,
              disabled: this.hasTerminalTransaction,
              disabledTooltip: this.$t('register.pos.delete_tooltip'),
            },
          ].filter((item) => !item.hide)
        : [];
    },
    details() {
      if (this.order) {
        const employeeNames = [
          ...new Set(
            this.order.items
              .filter((item) => item.employee)
              .map((item) => item.employee.name),
          ),
        ];
        return [
          {
            label: this.$t('global.number'),
            value: this.order.number,
            hide: !this.order.number,
          },
          {
            label: this.$t('global.date'),
            value: this.order.invoicedAt,
            type: 'dateTime',
          },
          {
            label: this.$t('global.items.employee', 2),
            value: employeeNames.join(', '),
            hide: !employeeNames.length,
          },
          {
            label: this.$t('global.items.location'),
            value: this.order.location.internalName,
            hide: !this.multiLocation,
          },
        ].filter((item) => !item.hide);
      } else {
        return [];
      }
    },
    uniqueDiscountCodes() {
      return this.order?.appointments?.length
        ? uniqueDiscountCodes(this.order.appointments)
        : [];
    },
  },
  methods: {
    onActionClick(action: string) {
      if (['credit', 'pay', 'edit'].includes(action)) {
        this.$router.push({
          name: 'register',
          query: { orderId: this.order.id, action },
        });
      } else {
        switch (action) {
          case 'print':
            this.promptPrint = true;
            break;
          case 'delete':
            this.deleteInvoice();
            break;
          case 'note':
            this.showCreateNoteModal = true;
            break;
        }
      }
    },
    deleteInvoice() {
      modal('confirmation', {
        warning:
          this.order.state !== 'PAID' ? '' : this.$t('register.delete_warning'),
        type: 'delete',
        item: this.$t('global.items.invoice'),
      }).then(() => {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation deleteOrder($input: DeleteOrderInput!) {
                deleteOrder(input: $input) {
                  order {
                    id
                  }
                }
              }
            `,
            variables: {
              input: {
                id: this.orderId,
              },
            },
            update(cache) {
              cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
              cache.gc();
            },
          })
          .then(() => {
            flash(this.$t('register.deleted'));
            this.$router.push({ name: this.parentRouteName });
          });
      });
    },
    setNote(value: string) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateOrder($input: UpdateOrderInput!) {
              updateOrder(input: $input) {
                order {
                  id
                  comments
                }
                errors
              }
            }
          `,
          variables: {
            input: {
              id: this.orderId,
              note: value,
            },
          },
        })
        .then((response) => {
          if (!response.data.updateOrder.errors) {
            flash(this.$t('global.flash.note_updated'));
            this.order.comments = response.data.updateOrder.order.comments;
          }
        });
    },
  },
});
</script>
