<template>
  <ModuleBase noBorder noPadding fullHeight>
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's',
          [$style.mediumScreen]: $screen === 'm'
        }
      ]"
    >
      <Sally
        v-if="showAssistantModal"
        v-model="formData"
        @close="showAssistantModal = false"
      />
      <div v-if="showAssistantEmptyState" :class="$style.emptyPageContent">
        <EmptyPageContent
          imageName="assistant"
          iconPrimary="message-circle"
          :text="{
            title: $t('admin.assistant.heading'),
            description: $t($t('admin.assistant.description')),
            btnPrimary: $t('admin.assistant.empty_state.btn_primary'),
            btnSecondary: $t('admin.assistant.empty_state.btn_secondary')
          }"
          @click="getStartedWizard"
          v-test="'get-started-wizard'"
        />
      </div>
      <div v-else :class="$style.form">
        <BaseForm
          :scrollContainer="scrollContainer"
          fullHeight
          @submit="submitForm"
          @validationError="onValidationError"
        >
          <div ref="scrollContainer" :class="$style.formContent">
            <BaseHeading size="l" mb>
              {{ $t('admin.booking.heading') }}
            </BaseHeading>
            <BaseText :mb="2">
              {{ $t('admin.booking.description') }}
            </BaseText>
            <BaseAccordion
              v-model="activeAccordionItems"
              :titles="[
                $t('admin.booking.main_settings'),
                $t('admin.booking.schedule_management'),
                $t('admin.booking.customer_information'),
                $t('admin.booking.online_payments'),
                $t('admin.booking.style_and_sharing')
              ]"
              :routeQueries="[
                'main-settings',
                'schedule-management',
                'customer-information',
                'online-payments',
                'style-and-sharing'
              ]"
              mb
              @toggle="onSectionToggle"
            >
              <template #1>
                <div :class="$style.wrap">
                  <div :class="$style.settingCategory">
                    <BaseText bold>
                      {{ $t('admin.booking.setting_group.widget') }}
                    </BaseText>
                    <BaseDropdown
                      v-model="formData.languagePublic"
                      :label="
                        $t('admin.general_settings.form.customer_language')
                      "
                      :options="publicLanguages"
                      v-test="'booking-settings-languagePublic'"
                    />

                    <BaseRadio
                      v-model="
                        formData.settings.bookings
                          .appointmentRequiresConfirmation
                      "
                      :label="$t('admin.booking.appointment_requests.heading')"
                      :options="appointmentRequiresConfirmationOptions"
                      v-test="'booking-settings-appointmentRequestConfirmation'"
                    />
                    <BaseAlert
                      v-if="showOnlinePaymentsDisabledByRequestAlert"
                      :text="
                        $t(
                          'admin.booking.appointment_requests.online_payments_alert'
                        )
                      "
                      :primaryAction="$t('global.actions.click_here')"
                      @primaryAction="cancelAppointmentRequests"
                      v-test="'online-payments-disabled-alert'"
                    />

                    <template
                      v-if="
                        !formData.settings.bookings
                          .appointmentRequiresConfirmation
                      "
                    >
                      <BaseRadio
                        v-model="formData.settings.bookings.allowNewCustomers"
                        :options="newCustomersCanBookOptions"
                        :label="$t('admin.booking.allow_new_customers.label')"
                        :info="$t('admin.booking.allow_new_customers.hint')"
                        v-test="'booking-settings-allowNewCustomers'"
                      />

                      <BaseInput
                        v-if="!formData.settings.bookings.allowNewCustomers"
                        v-model="
                          formData.settings.bookings.customCustomerStopMessage
                        "
                        :label="
                          $t('admin.booking.custom_customer_stop_message.label')
                        "
                        :maxLength="80"
                        v-test="'booking-settings-customCustomerStopMessage'"
                      />
                      <ExistingCustomersPerEmployee
                        v-model="
                          formData.settings.bookings
                            .onlyExistingCustomersResourceIds
                        "
                        v-model:enabled="onlyExistingCustomersPerEmployee"
                        :allowNewCustomers="
                          formData.settings.bookings.allowNewCustomers
                        "
                      />
                    </template>
                    <template v-else>
                      <BaseRadio
                        v-model="
                          formData.settings.bookings
                            .appointmentRequestNewCustomers
                        "
                        :label="
                          $t('admin.booking.appointment_requests.apply_to')
                        "
                        :options="appointmentRequestNewUsersOptions"
                        v-test="
                          'booking-settings-appointmentRequestUserSetting'
                        "
                      />
                      <BaseRadio
                        v-model="appointmentRequestServiceRule"
                        :label="
                          $t(
                            'admin.booking.appointment_requests.apply_to_appointments'
                          )
                        "
                        :options="appointmentRequestServiceOptions"
                        v-test="
                          'booking-settings-appointmentRequestServiceSetting'
                        "
                      />
                      <BaseInput
                        v-if="appointmentRequestServiceRule === 'PRICE'"
                        v-model="
                          formData.settings.bookings
                            .appointmentRequestPriceMinimumValue
                        "
                        type="currency"
                        :minValue="0"
                        :label="
                          $t(
                            'admin.booking.appointment_requests.appointments_over'
                          )
                        "
                        v-test="'booking-settings-appointmentRequestPriceValue'"
                      />
                      <BaseInput
                        v-if="appointmentRequestServiceRule === 'DURATION'"
                        v-model="
                          formData.settings.bookings
                            .appointmentRequestDurationMinimumValue
                        "
                        type="number"
                        :minValue="0"
                        :label="
                          $t(
                            'admin.booking.appointment_requests.appointments_over'
                          )
                        "
                        unitLabel="minute"
                        v-test="
                          'booking-settings-appointmentRequestDurationValue'
                        "
                      />
                    </template>

                    <BaseAlert
                      v-if="showBookingOptionsMessage"
                      :text="bookingOptionsMessage"
                    />
                  </div>

                  <div :class="$style.settingCategory">
                    <BaseText bold>
                      {{ $t('admin.booking.setting_group.employees') }}
                    </BaseText>

                    <BaseCheckbox
                      v-model="resourceSelectionEnabled"
                      :label="$t('admin.booking.resource_selection.label')"
                      v-test="'booking-settings-resourceSelectionToggle'"
                    />
                    <BaseRadio
                      v-if="resourceSelectionEnabled"
                      v-model="formData.settings.bookings.resourceSelection"
                      :options="resourceSelectionOptions"
                      inline
                      v-test="'booking-settings-resourceSelectionOptions'"
                    />
                    <BaseAlert
                      v-if="showResourceSelectionWarning"
                      :text="
                        $t(
                          'admin.booking.resource_selection.warning_variable_price'
                        )
                      "
                    />
                    <BaseCheckbox
                      v-if="resourceSelectionEnabled"
                      v-model="
                        formData.settings.bookings.employeeSelectionFirst
                      "
                      :label="
                        $t(
                          'admin.booking.resource_selection.employee_first.label'
                        )
                      "
                      v-test="'booking-settings-employeeSelectionFirstToggle'"
                    />
                  </div>

                  <div :class="$style.settingCategory">
                    <BaseText bold>
                      {{ $t('admin.booking.show_service_prices.heading') }}
                    </BaseText>
                    <BaseCheckbox
                      v-model="formData.settings.bookings.showServicePrices"
                      :label="$t('admin.booking.show_service_prices.label')"
                      v-test="'booking-settings-showServicePrices'"
                    />

                    <BaseCheckbox
                      v-model="formData.settings.bookings.showServiceDuration"
                      :label="$t('admin.booking.show_service_duration.label')"
                      v-test="'booking-settings-showServiceDuration'"
                    />

                    <BaseCheckbox
                      v-model="formData.settings.bookings.allowMultipleServices"
                      :label="$t('admin.booking.allow_multiple_services.label')"
                      v-test="'booking-settings-allowMultipleServices'"
                    />

                    <TopServices
                      v-model="topServiceVariationGroupIds"
                      :settings="formData.settings.bookings"
                      @change="topServicesChanged = true"
                      v-intercom="'online-bookings-top-services'"
                    />
                  </div>

                  <div :class="$style.settingCategory">
                    <BaseText bold>
                      {{ $t('admin.booking.notifications.heading') }}
                    </BaseText>
                    <BaseCheckbox
                      v-model="formData.settings.bookings.smsNotification"
                      :label="$t('admin.booking.sms_notification.label')"
                      v-test="'booking-settings-smsNotification'"
                    />
                    <BaseAlert
                      v-if="noNumberConfigured"
                      color="warning"
                      :text="
                        $t('admin.booking.no_number_configured.description')
                      "
                      v-test="'booking-settings-smsNotificationAlert'"
                    />
                  </div>
                </div>
              </template>
              <template #2>
                <div
                  :class="$style.wrap"
                  v-intercom="'online-bookings-schedule-management'"
                >
                  <div :class="$style.settingCategory">
                    <BaseDropdown
                      v-model="formData.settings.agenda.appointmentInterval"
                      :label="$t('admin.calendar.online_booking_interval')"
                      :options="
                        onlineBookingIntervalOptions.map((value) => ({
                          label: `${value} ${$t('global.items.minute_short', value)}`,
                          value
                        }))
                      "
                      v-test="'appointment-interval-select'"
                    />

                    <BaseDropdown
                      v-model="formData.settings.bookings.leadTime"
                      :label="$t('admin.booking.lead_time.label')"
                      :options="leadTimeOptions"
                      v-test="'booking-settings-leadTime'"
                    />
                    <BaseDropdown
                      v-model="formData.settings.bookings.maxTimeInAdvance"
                      :label="$t('admin.booking.max_time_in_advance.label')"
                      :options="maxTimeInAdvanceOptions"
                      v-test="'booking-settings-maxTimeInAdvance'"
                    />

                    <BaseCheckbox
                      v-model="preventGapsEnabled"
                      :label="$t('admin.booking.prevent_gaps.label')"
                      v-test="'booking-settings-preventGapsToggle'"
                    />
                    <BaseRadio
                      v-if="preventGapsEnabled"
                      v-model="formData.settings.bookings.preventGaps"
                      :options="preventGapsOptions"
                      v-test="'booking-settings-preventGapsOptions'"
                    />

                    <BaseCheckbox
                      v-model="formData.settings.bookings.waitingListEnabled"
                      :disabled="!hasWaitingList"
                      :label="$t('admin.booking.waiting_list.label')"
                      v-test="'booking-settings-waitingListEnabled'"
                    />
                    <BaseText
                      v-if="hasWaitingList"
                      :routerLink="{ name: 'waiting-list-template' }"
                      v-test="'booking-settings-waitingListEmailTemplateLink'"
                    >
                      {{ $t('admin.booking.waiting_list.email_template_link') }}
                    </BaseText>
                    <BaseAlert
                      v-if="!hasWaitingList"
                      color="warning"
                      :text="$t('admin.booking.waiting_list.unavailable')"
                      :primaryAction="{
                        text: $t('global.upgrade_now'),
                        routerLink: {
                          name: 'subscription'
                        }
                      }"
                      v-test="'booking-settings-upgrade'"
                    />
                  </div>
                </div>
              </template>
              <template #3>
                <div :class="$style.wrap">
                  <div :class="$style.settingCategory">
                    <BaseText bold>
                      {{ $t('admin.booking.customer_extra_info.heading') }}
                    </BaseText>
                    <BaseText>
                      {{ $t('admin.booking.customer_extra_info.description') }}
                    </BaseText>
                    <BaseCheckbox
                      v-model="addressFieldsEnabled"
                      :label="$t('admin.booking.address_fields.label')"
                      v-test="'booking-settings-addressFieldsToggle'"
                    />
                    <BaseRadio
                      v-if="addressFieldsEnabled"
                      v-model="formData.settings.bookings.addressFields"
                      :options="addressFieldsOptions"
                      inline
                      v-test="'booking-settings-addressFieldsOptions'"
                    />
                    <BaseCheckbox
                      v-model="dateOfBirthEnabled"
                      :label="$t('admin.booking.birthdate.label')"
                      v-test="'booking-settings-dateOfBirthToggle'"
                    />
                    <BaseRadio
                      v-if="dateOfBirthEnabled"
                      v-model="formData.settings.bookings.dateOfBirth"
                      :options="dateOfBirthOptions"
                      inline
                      v-test="'booking-settings-dateOfBirthOptions'"
                    />
                  </div>

                  <div :class="$style.settingCategory">
                    <BaseText bold>
                      {{ $t('admin.booking.newsletters_and_terms.heading') }}
                    </BaseText>

                    <BaseCheckbox
                      v-model="formData.settings.bookings.showNewsletterOptin"
                      :label="$t('admin.booking.show_newsletter_optin.label')"
                      v-test="'booking-settings-showNewsletterOptin'"
                    />
                    <BaseCheckbox
                      v-model="enableCustomTermsAndConditions"
                      :label="$t('admin.booking.terms_conditions_url.main')"
                      v-test="'booking-settings-customTerms'"
                    />
                    <BaseInput
                      v-if="enableCustomTermsAndConditions"
                      v-model="formData.settings.bookings.termsConditionsUrl"
                      :label="$t('admin.booking.terms_conditions_url.label')"
                      :info="$t('admin.booking.terms_conditions_url.hint')"
                      type="url"
                      v-test="'booking-settings-termsConditionsUrl'"
                    />
                    <BaseCheckbox
                      v-model="enablePrivacyStatementUrl"
                      :label="$t('admin.booking.privacy_statement_url.main')"
                      mt
                      v-test="'booking-settings-customPrivacyStatement'"
                    />
                    <BaseInput
                      v-if="enablePrivacyStatementUrl"
                      v-model="formData.settings.bookings.privacyStatementUrl"
                      :label="$t('admin.booking.privacy_statement_url.label')"
                      :info="$t('admin.booking.privacy_statement_url.hint')"
                      type="url"
                      mt
                      v-test="'booking-settings-privacyStatementUrl'"
                    />
                  </div>
                </div>
              </template>
              <template #4>
                <div :class="$style.wrap">
                  <div :class="$style.settingCategory">
                    <OnlinePaymentsSettings
                      :settings="formData.settings.bookings"
                      v-intercom="'online-bookings-online-payments'"
                    />
                  </div>
                </div>
              </template>
              <template #5>
                <div :class="$style.wrap">
                  <div :class="$style.settingCategory">
                    <BaseText bold>
                      {{ $t('admin.booking.setting_group.visualization') }}
                    </BaseText>
                    <BaseInputLabel
                      :text="t('promote.booking_widget.settings.color')"
                    />
                    <BaseColorLabel
                      v-model="formData.settings.bookingWidget.customColor"
                      colorPicker
                      selected
                      v-test="'booking-settings-customColor'"
                    />
                    <BaseRadio
                      v-model="formData.settings.bookingWidget.allowMonthView"
                      :label="t('promote.booking_widget.schedule_view')"
                      :options="scheduleViewOptions"
                      v-test="'booking-settings-scheduleView'"
                    />
                    <BaseCheckbox
                      v-model="
                        formData.settings.bookingWidget
                          .appointmentOverviewRequest
                      "
                      :label="
                        t('promote.booking_widget.appointment_overview_request')
                      "
                      v-test="'booking-settings-appointmentOverviewRequest'"
                    />

                    <BaseCheckbox
                      v-model="formData.settings.bookings.showCustomAlert"
                      :label="$t('admin.booking.show_custom_alert.main')"
                      v-test="'booking-settings-showCustomAlert'"
                    />
                    <BaseInput
                      v-if="formData.settings.bookings.showCustomAlert"
                      v-model="formData.settings.bookings.customAlert"
                      :label="$t('admin.booking.custom_alert.label')"
                      :maxLength="80"
                      mt
                      v-test="'booking-settings-customAlert'"
                    />
                  </div>

                  <div :class="$style.settingCategory">
                    <BaseText bold>
                      {{ $t('admin.booking.tracking.heading') }}
                    </BaseText>
                    <BaseInput
                      v-model="gaId"
                      :label="$t('admin.micro_site.seo.analytics_id')"
                      prefix="G-"
                      v-test="'booking-settings-gaId'"
                    />
                    <BaseAlert
                      mt
                      :text="$t('admin.booking.tracking.alert')"
                      :primaryAction="{
                        text: $t('promote.nav.links.micro_site'),
                        routerLink: {
                          name: 'admin-micro-site'
                        }
                      }"
                      v-test="'booking-settings-link-microSite'"
                    />
                  </div>

                  <div :class="$style.settingCategory">
                    <BaseText bold>
                      {{ $t('admin.booking.setting_group.sharing') }}
                    </BaseText>
                    <BaseRadio
                      v-model="shareSetting"
                      :label="t('promote.booking_widget.share_setting')"
                      :options="shareSettingsOptions"
                      v-test="'booking-settings-shareSettings'"
                    />

                    <template v-if="shareSetting === shareSettings.BUTTON">
                      <BaseRadio
                        v-model="formData.settings.bookingWidget.position"
                        :label="
                          t('promote.booking_widget.settings.button.position')
                        "
                        :options="buttonPositionOptions"
                        v-test="'booking-settings-position'"
                      />
                    </template>

                    <template v-else-if="shareSetting === shareSettings.WIDGET">
                      <BaseRadio
                        v-model="formData.settings.bookingWidget.autoWidth"
                        :label="
                          t('promote.booking_widget.settings.widget.size')
                        "
                        :options="widthSizeOptions"
                        v-test="'booking-settings-autoWidth'"
                      />
                      <BaseGrid container mb>
                        <BaseGrid
                          :size="
                            formData.settings.bookingWidget.autoWidth ? 12 : 6
                          "
                          :sSize="12"
                        >
                          <BaseInput
                            v-model="formData.settings.bookingWidget.height"
                            :label="
                              t('promote.booking_widget.settings.widget.height')
                            "
                            type="number"
                            v-test="'booking-settings-height'"
                          />
                        </BaseGrid>
                        <BaseGrid
                          v-if="!formData.settings.bookingWidget.autoWidth"
                          :size="6"
                          :sSize="12"
                        >
                          <BaseInput
                            v-model="formData.settings.bookingWidget.width"
                            :label="
                              t('promote.booking_widget.settings.widget.width')
                            "
                            type="number"
                            v-test="'booking-settings-width'"
                          />
                        </BaseGrid>
                      </BaseGrid>
                    </template>

                    <template v-if="shareSetting === shareSettings.LINK">
                      <BaseCard gray>
                        <BaseInputLabel
                          :text="t('promote.booking_widget.link')"
                        />
                        <BaseCodeField
                          :code="widgetUrl"
                          :loading="loadingMicroSite"
                          buttonType="inverted"
                          multiline
                          v-test="'widget-styling-link'"
                        />
                      </BaseCard>
                    </template>

                    <Snippet v-else gray />
                  </div>
                  <div :class="$style.settingCategory">
                    <BaseInputLabel
                      :text="$t('admin.booking.custom_widget.heading')"
                    />
                    <BaseText>
                      {{ $t('admin.booking.custom_widget.description') }}
                    </BaseText>
                    <BaseText
                      :routerLink="{
                        name: 'create-custom-widget'
                      }"
                      v-test="'create-custom-widget-btn'"
                    >
                      {{ $t('admin.booking.custom_widget.link') }}
                    </BaseText>
                  </div>
                </div>
              </template>
            </BaseAccordion>

            <BaseCard
              v-if="hasAssistant"
              :heading="$t('admin.assistant.heading')"
              gray
              mb
              v-test="'booking-settings-wizard'"
            >
              <div :class="$style.assistantCard">
                <BaseText mb>
                  {{ $t('admin.assistant.description') }}
                </BaseText>
                <img
                  src="/img/salonized-assistant/clippy.svg"
                  :class="$style.assistantImage"
                />
              </div>
              <BaseButton
                color="inverted"
                fullWidth
                @click="() => getStartedWizard('primary')"
                v-test="'booking-settings-wizard-start'"
              >
                {{ $t('admin.assistant.start') }}
              </BaseButton>
            </BaseCard>

            <BaseCard
              v-if="showTreatwell && !hasDashboard"
              :heading="$t('admin.booking.treatwell.heading')"
              gray
              mb
            >
              <template #header>
                <img src="/img/admin/treatwell.svg" :class="$style.img" />
              </template>
              <BaseText mb>
                {{ $t('admin.booking.treatwell.description') }}
              </BaseText>
              <BaseButton
                color="inverted"
                fullWidth
                :routerLink="{ name: 'treatwell-landing' }"
                v-test="'booking-settings-treatwell'"
              >
                {{ $t('admin.booking.treatwell.button') }}
              </BaseButton>
            </BaseCard>
            <BaseCard
              v-if="
                isSubscribed &&
                hasFeatureFlag('module-appointments') &&
                !rwgEnabled
              "
              :heading="$t('admin.booking.rwg.heading')"
              gray
            >
              <template #header>
                <img src="/img/admin/rwg.svg" :class="$style.img" />
              </template>
              <BaseText mb>
                {{ $t('admin.booking.rwg.description') }}
              </BaseText>
              <BaseButton
                color="inverted"
                fullWidth
                :routerLink="{ name: 'integration-google-reserve' }"
                v-test="'booking-settings-rwg'"
              >
                {{ $t('admin.booking.rwg.button') }}
              </BaseButton>
            </BaseCard>
          </div>
          <div :class="$style.formBottom">
            <BaseButton
              submitForm
              :loading="saving"
              v-test="'booking-settings-submit'"
            >
              {{ $t('global.actions.save') }}
            </BaseButton>
          </div>
        </BaseForm>
      </div>
      <div
        :class="$style.widget"
        :style="
          $screen == 's' && formData.settings.bookingWidget.inline
            ? {
                // The 32px is the amount of padding used on the widget preview page
                height: formData.settings.bookingWidget.height + 32 + 'px'
              }
            : {}
        "
      >
        <transition>
          <div
            v-show="previewNeedsSave"
            :class="$style.refreshMessage"
            v-test="'booking-settings-update-message'"
          >
            <BaseHeading size="s" :mb="0.5">
              {{ $t('promote.booking_widget.preview_explanation') }}
            </BaseHeading>
          </div>
        </transition>
        <iframe
          ref="widgetPreviewIframe"
          :key="iframeUpdate"
          :class="$style.widgetPreview"
          :src="widgetPreviewUrl"
          :style="
            $screen === 'l'
              ? undefined
              : {
                  height: formData.settings.bookingWidget.height + 'px'
                }
          "
          v-test="'booking-settings-preview-iframe'"
        />
      </div>
    </div>
  </ModuleBase>
</template>

<script lang="ts">
export default {
  name: 'OnlineBookings',
};
</script>

<script setup lang="ts">
import { ref, computed, watch, nextTick, watchEffect } from 'vue';
import { useStorage } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@vue/apollo-composable';

import config from '@/config';
import { flash } from '@/helpers/ui';
import { useLeaveConfirmation } from '@/helpers/routing';
import { useCompanyStore } from '@/stores/company';
import { useServicesStore } from '@/stores/services';
import ModuleBase from '@/modules/ModuleBase.vue';
import { useBookingSettingsData } from './form-data';
import { useBookingSettingsOptions } from './input-options';

import EmptyPageContent from '@/components/EmptyPageContent.vue';
import TopServices from './TopServices.vue';
import { UPDATE_TOP_SERVICE_VARIATION_GROUPS } from './graphql';
import Snippet from './Snippet.vue';
import OnlinePaymentsSettings from './OnlinePaymentsSettings.vue';
import {
  AddressFields,
  PreventGaps,
  DateOfBirth,
  ResourceSelection,
  OnlinePayment
} from '@/types';

import { setScrollOnElement } from '@/helpers/scroll';
import { useUserStore } from '@/stores/user';
import { useTreatwellStore } from '@/stores/treatwell';
import filters from '@/filters';
import unleash from '@/unleash';
import Sally from './salonized-assistant/index.vue';
import ExistingCustomersPerEmployee from './ExistingCustomersPerEmployee.vue';

const mixpanel = inject<any>('mixpanel');

const { t } = useI18n();
const { company, updateCompany, isSubscribed, isTreatwellUser } =
  useCompanyStore();
const { hasFeatureFlag } = useUserStore();
const { formData } = useBookingSettingsData();
const {
  publicLanguages,
  appointmentRequiresConfirmationOptions,
  appointmentRequestNewUsersOptions,
  appointmentRequestServiceOptions,
  preventGapsOptions,
  resourceSelectionOptions,
  addressFieldsOptions,
  dateOfBirthOptions,
  leadTimeOptions,
  maxTimeInAdvanceOptions,
  newCustomersCanBookOptions,
  scheduleViewOptions,
  buttonPositionOptions,
  widthSizeOptions,
  shareSettings,
  shareSettingsOptions
} = useBookingSettingsOptions();
const { showTreatwell, hasDashboard } = useTreatwellStore();

const activeAccordionItems = ref<string[]>([]);

const onSectionToggle = (element: HTMLElement) => {
  nextTick(() => {
    if (activeAccordionItems.value.length && element) {
      setScrollOnElement(element);
    }
  });
};

const hasAssistant = unleash.isEnabled('SalonizedAssistant') && !isSubscribed;
const assistantStep = useStorage('assistant-step-online-bookings', 0);
const assistantDismissed = useStorage(
  'assistant-dismissed-online-bookings',
  false
);
const showAssistantEmptyState = computed(
  () => hasAssistant && !assistantDismissed.value && assistantStep.value === 0
);

const onlineBookingIntervalOptions = [
  5, 10, 15, 20, 25, 30, 45, 60, 75, 90, 120
];

const hasWaitingList = computed(
  () => hasFeatureFlag('waiting-list') || hasFeatureFlag('waiting-list-pro')
);

const noNumberConfigured = computed(
  () =>
    formData.settings.bookings.smsNotification && !company.smsNotificationNumber
);

const appointmentRequestServiceRule = computed({
  get() {
    if (formData.settings.bookings.appointmentRequestPriceMinimum) {
      return 'PRICE';
    } else if (formData.settings.bookings.appointmentRequestDurationMinimum) {
      return 'DURATION';
    }
    return 'ALL';
  },
  set(enabled) {
    formData.settings.bookings.appointmentRequestPriceMinimum =
      enabled === 'PRICE';
    formData.settings.bookings.appointmentRequestDurationMinimum =
      enabled === 'DURATION';
  }
});

const resourceSelectionEnabled = computed({
  get() {
    return (
      formData.settings.bookings.resourceSelection !==
      ResourceSelection.Automatic
    );
  },
  set(enabled) {
    formData.settings.bookings.resourceSelection = enabled
      ? ResourceSelection.Manual
      : ResourceSelection.Automatic;
  }
});

const showBookingOptionsMessage = computed(
  () => bookingOptionsMessage.value?.length
);

const onlyExistingCustomersPerEmployee = ref(
  !!formData.settings.bookings.onlyExistingCustomersResourceIds.length
);

const bookingOptionsMessage = computed(() => {
  if (!formData.settings.bookings.appointmentRequiresConfirmation) {
    if (!formData.settings.bookings.allowNewCustomers) {
      if (onlyExistingCustomersPerEmployee.value) {
        return t(
          'admin.booking.booking_options_disclaimer.no_new_customers_per_employee'
        );
      } else {
        return t('admin.booking.booking_options_disclaimer.no_new_customers');
      }
    }
  } else {
    const customerTarget = formData.settings.bookings
      .appointmentRequestNewCustomers
      ? 'all_customers'
      : 'new_customers';
    switch (appointmentRequestServiceRule.value) {
      case 'PRICE':
        return t(
          `admin.booking.booking_options_disclaimer.appointment_requests.${customerTarget}.over_price`,
          {
            price: filters.currency(
              formData.settings.bookings.appointmentRequestPriceMinimumValue
            )
          }
        );
      case 'DURATION':
        return t(
          `admin.booking.booking_options_disclaimer.appointment_requests.${customerTarget}.over_duration`,
          {
            duration:
              formData.settings.bookings.appointmentRequestDurationMinimumValue
          }
        );
      default:
        return t(
          `admin.booking.booking_options_disclaimer.appointment_requests.${customerTarget}.all_appointments`
        );
    }
  }
  return null;
});

const showResourceSelectionWarning = computed(() => {
  // When there are any services which have a variable price,
  // setting the resourceSelection to "Automatic" or "Manual" will not work in the widget

  const { services } = useServicesStore();
  return (
    !!services.find(
      (service) => service.bookable && service.resourceAdjustments?.length
    ) &&
    formData.settings.bookings.resourceSelection !== ResourceSelection.Required
  );
});

const preventGapsEnabled = computed({
  get() {
    return formData.settings.bookings.preventGaps !== PreventGaps.Disabled;
  },
  set(enabled) {
    formData.settings.bookings.preventGaps = enabled
      ? PreventGaps.Enabled
      : PreventGaps.Disabled;
  }
});

const addressFieldsEnabled = computed({
  get() {
    return formData.settings.bookings.addressFields !== AddressFields.Hidden;
  },
  set(enabled) {
    formData.settings.bookings.addressFields = enabled
      ? AddressFields.Optional
      : AddressFields.Hidden;
  }
});

const dateOfBirthEnabled = computed({
  get() {
    return formData.settings.bookings.dateOfBirth !== DateOfBirth.Disabled;
  },
  set(enabled) {
    formData.settings.bookings.dateOfBirth = enabled
      ? DateOfBirth.Optional
      : DateOfBirth.Disabled;
  }
});

const shareSetting = useStorage('share-setting', shareSettings.LINK);
watch(shareSetting, (newValue) => {
  formData.settings.bookingWidget.inline = newValue !== shareSettings.BUTTON;
});

if (!formData.settings.bookingWidget.inline) {
  shareSetting.value = shareSettings.BUTTON;
}

const rwgEnabled = ref(true);

const { getApp } = useCompanyStore();
getApp('google_reserve').then((app) => {
  rwgEnabled.value = !!app;
});

const microSiteQuery = gql`
  query getMicroSite {
    microSite {
      gaId
      id
      url
    }
  }
`;

const {
  result,
  onResult,
  loading: loadingMicroSite
} = useQuery(microSiteQuery);

const gaId = ref<string>('');
const initialGaId = ref<string>('');

onResult(({ data: { microSite } }) => {
  gaId.value = microSite.gaId || '';
  initialGaId.value = microSite.gaId || '';
});

const widgetUrl = computed(() => {
  const microSiteUrl = result.value?.microSite.url || '';
  return microSiteUrl ? `${microSiteUrl}/widget_bookings/new` : '';
});

const widgetPreviewUrl = computed(() => {
  let baseUrl = `${config.bookingWidgetUrl}/?widget=booking&company=${company.publicId}`;
  baseUrl += `&language=${formData.languagePublic.toLowerCase()}`;
  baseUrl += `&color=${formData.settings.bookingWidget.customColor}`;
  if (!formData.settings.bookingWidget.autoWidth) {
    baseUrl += `&width=${formData.settings.bookingWidget.width}`;
  }
  if (shareSetting.value === shareSettings.WIDGET) {
    baseUrl += `&height=${formData.settings.bookingWidget.height}`;
  }
  baseUrl += `&outline=${formData.settings.bookingWidget.outline.toLowerCase()}`;
  baseUrl += `&position=${formData.settings.bookingWidget.position.toLowerCase()}`;
  baseUrl += `&inline=${formData.settings.bookingWidget.inline}`;
  if (isTreatwellUser) {
    baseUrl += '&treatwell=true';
  }
  return encodeURI(baseUrl);
});

const widgetPreviewIframe = ref<HTMLIFrameElement | null>(null);
const iframeUpdate = ref(0);

const refreshIframe = () => {
  if (widgetPreviewIframe.value) {
    iframeUpdate.value += 1;
  }
};

watch(widgetPreviewUrl, () => {
  refreshIframe();
});

const showOnlinePaymentsDisabledByRequestAlert = ref(false);
const paymentSettingBeforeRequestsEnabled = ref(
  formData.settings.bookings.onlinePayment
);

watch(
  () => formData.settings.bookings.appointmentRequiresConfirmation,
  (newVal, oldVal) => {
    showOnlinePaymentsDisabledByRequestAlert.value = false;
    if (
      !oldVal &&
      newVal &&
      formData.settings.bookings.onlinePayment !== OnlinePayment.Disabled
    ) {
      paymentSettingBeforeRequestsEnabled.value =
        formData.settings.bookings.onlinePayment;
      formData.settings.bookings.onlinePayment = OnlinePayment.Disabled;
      showOnlinePaymentsDisabledByRequestAlert.value = true;
    }
    if (!oldVal && newVal && !formData.settings.bookings.allowNewCustomers) {
      formData.settings.bookings.allowNewCustomers = true;
    }
  }
);

const cancelAppointmentRequests = () => {
  showOnlinePaymentsDisabledByRequestAlert.value = false;
  formData.settings.bookings.onlinePayment =
    paymentSettingBeforeRequestsEnabled.value;
  formData.settings.bookings.appointmentRequiresConfirmation = false;
};

const enableCustomTermsAndConditions = ref(
  formData.settings.bookings.termsConditionsUrl.length > 0
);
const enablePrivacyStatementUrl = ref(
  formData.settings.bookings.privacyStatementUrl.length > 0
);

const previewNeedsSave = ref(false);
watch(
  formData.settings.bookings,
  () => {
    previewNeedsSave.value = true;
  },
  {
    deep: true
  }
);

const saving = ref(false);
const scrollContainer = ref();

const onValidationError = () => {
  activeAccordionItems.value = ['1', '2', '3', '4', '5'];
};

// The services get loaded async, after the page is mounted
// If there are any deleted services, they will get filtered out of the formData
// This will trigger the confirmation, but it shouldn't at this point
// So we disable it by default and enable it after the services are loaded
const disableConfirmation = ref(true);
watchEffect(() => {
  const { services } = useServicesStore();
  nextTick(() => {
    if (services.length) {
      disableConfirmation.value = false;
    }
  });
});

const topServiceVariationGroupIds = ref<string[]>([]);
const topServicesChanged = ref(false);

const { resetConfirmation } = useLeaveConfirmation(
  formData,
  disableConfirmation
);

const submitForm = () => {
  saving.value = true;

  if (
    formData.settings.bookings.showCustomAlert &&
    !formData.settings.bookings.customAlert
  ) {
    formData.settings.bookings.showCustomAlert = false;
  }
  if (
    enableCustomTermsAndConditions.value &&
    !formData.settings.bookings.termsConditionsUrl
  ) {
    enableCustomTermsAndConditions.value = false;
  }

  if (
    !enableCustomTermsAndConditions.value &&
    formData.settings.bookings.termsConditionsUrl
  ) {
    formData.settings.bookings.termsConditionsUrl = '';
  }

  if (
    enablePrivacyStatementUrl.value &&
    !formData.settings.bookings.privacyStatementUrl
  ) {
    enablePrivacyStatementUrl.value = false;
  }

  if (
    !enablePrivacyStatementUrl.value &&
    formData.settings.bookings.privacyStatementUrl
  ) {
    formData.settings.bookings.privacyStatementUrl = '';
  }

  if (!resourceSelectionEnabled.value) {
    formData.settings.bookings.employeeSelectionFirst = false;
  }

  if (gaId.value !== initialGaId.value) {
    // Only perform the mutation if the gaId has actually changed

    if (gaId.value) {
      // Remove the G- prefix from the value if the user entered it
      gaId.value = gaId.value.replace('G-', '');
    }

    const { mutate } = useMutation(gql`
      mutation updateMicroSite($input: UpdateMicroSiteInput!) {
        updateMicroSite(input: $input) {
          microSite {
            id
          }
        }
      }
    `);

    mutate({
      input: {
        gaId: gaId.value
      }
    });
  }

  if (!onlyExistingCustomersPerEmployee.value) {
    formData.settings.bookings.onlyExistingCustomersResourceIds = [];
  }

  updateCompany(formData)
    .then(() => {
      flash(t('global.flash.settings_updated'));
      refreshIframe();
    })
    .finally(() => {
      saving.value = false;
      resetConfirmation();
    });

  if (topServicesChanged.value) {
    const { mutate } = useMutation(UPDATE_TOP_SERVICE_VARIATION_GROUPS);
    mutate({
      input: {
        serviceVariationGroupIds: topServiceVariationGroupIds.value
      }
    });
  }
};

const showAssistantModal = ref(false);
const getStartedWizard = (button: string) => {
  if (button === 'primary') {
    mixpanel.track('assistant-started');
    assistantDismissed.value = true;
    showAssistantModal.value = true;
  } else if (button === 'secondary') {
    mixpanel.track('assistant-dismissed');
    assistantDismissed.value = true;
  }
};
</script>

<style lang="scss" module>
.base {
  &:not(.smallScreen):not(.mediumScreen) {
    display: flex;
    align-items: stretch;
    height: 100%;
  }

  &.mediumScreen {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.formContent {
  padding: $spacing;

  .base:not(.smallScreen):not(.mediumScreen) & {
    height: 100%;
    overflow-y: auto;
  }
}

.emptyPageContent {
  display: flex;
  align-items: center;
  justify-content: center;
  .base:not(.smallScreen):not(.mediumScreen) & {
    height: 100%;
    width: 40%;
    min-width: 250px;
  }
}

.form {
  .base:not(.smallScreen):not(.mediumScreen) & {
    height: 100%;
    width: 40%;
    min-width: 250px;
  }
}

.formBottom {
  padding: $spacing;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  border-top: 1px solid $color-border;
}

.widget {
  position: relative;
  background-color: $grey-light;
  border-left: 1px solid $color-border;

  .base:not(.smallScreen):not(.mediumScreen) & {
    height: 100%;
    width: 60%;
    overflow: hidden;
  }

  .base.smallScreen & {
    height: 500px;
  }

  .base.mediumScreen & {
    height: 100%;
    min-height: 500px;
  }

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; // To place it over the text message
  }
}

.refreshMessage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: $spacing;
  display: flex;
  justify-content: center;

  &:global(.v-enter-active) {
    transition:
      transform 0.2s $easeOutBack 0.1s,
      opacity 0.1s linear 0.1s;
  }

  &:global(.v-leave-active) {
    transition: opacity 0.1s linear;
  }

  &:global(.v-enter-from) {
    transform: scale(0.5);
  }

  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
  }
}

.wrap {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.alertContent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}

.img {
  height: 20px;
}

.settingCategory {
  &:not(:last-child) {
    margin-bottom: $spacing * 2;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}
.assistantCard {
  display: flex;
  height: 80px;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: $spacing;
}
.assistantImage {
  max-height: 100%;
}
</style>
