<script lang="ts">
import filters from '@/filters';
import i18n from '@/i18n';
import { Bar } from 'vue-chartjs';
import { defineComponent, h } from 'vue';

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
);

export default defineComponent({
  name: 'BarLineChart',
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y0: {
          title: {
            display: true,
            text: i18n.t('reports.ranges.orders'),
          },
          type: 'linear',
          display: true,
          position: 'right',
          grid: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            maxTicksLimit: 10,
          },
        },
        y1: {
          title: {
            display: true,
            text: i18n.t('reports.ranges.turnover'),
          },
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            callback: (value) => filters.currency(value * 100),
            beginAtZero: true,
            stepSize: 1,
            maxTicksLimit: 10,
          },
        },
        x0: {
          ticks: {
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
        },
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (tooltipItem) => {
            if (tooltipItem.datasetIndex === 1) {
              return `${i18n.t('reports.ranges.turnover')}: ${filters.currency(tooltipItem.yLabel * 100)}`;
            }
            return `${i18n.t('reports.ranges.orders')}: ${tooltipItem.yLabel}`;
          },
        },
      },
    };

    return () =>
      h(Bar, {
        chartData: props.chartData,
        chartOptions,
      });
  },
});
</script>
