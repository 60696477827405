<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.list">
      <div :class="$style.alerts">
        <BaseAlert
          v-if="totalTwPayments > 0"
          color="warning"
          :text="
            $t('register.tw_payment_info', {
              amount: filters.currency(totalTwPayments)
            })
          "
          v-test="'register-order-tw-payment-alert'"
        />
        <BaseAlert
          v-if="serviceItemMismatch"
          :text="$t('register.service_item_mismatch')"
          v-test="'register-order-service-item-mismatch'"
        />
        <BaseAlert
          v-for="(discountCode, index) in discountCodes"
          :key="index"
          :text="
            $t('appointment.discount_code_used', {
              code: discountCode?.code,
              percentage: discountCode?.discountPercentage
            })
          "
          v-test="'register-order-discount-alert'"
        />
      </div>

      <div v-if="orderAppointments.length" :class="$style.appointments">
        <OrderAppointment
          v-for="(appointment, index) in orderAppointments"
          :key="index"
          :appointment="appointment"
        />
      </div>

      <div :class="$style.items">
        <TransitionGroup
          name="list-pop-slide"
          :appear="order.items.length === 1"
        >
          <OrderItem
            v-for="(item, index) in order.items"
            :key="`${item._uid}-${item.name}`"
            :item="item"
            :index="index"
          />
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'OrderItems',
};
</script>

<script setup lang="ts">
import OrderItem from './OrderItem.vue';
import OrderAppointment from './OrderAppointment.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import type { OrderItem as OrderItemType } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import filters from '@/filters';

const { order } = useRegisterOrderStore();
const { orderAppointments, totalTwPayments } = storeToRefs(
  useRegisterOrderStore()
);

const discountCodes = computed(() =>
  orderAppointments.value
    .map((appointment) => appointment.discountCode)
    .filter((value, index, self) => value && self.indexOf(value) === index)
);

const serviceItemMismatch = computed(() => {
  // for online appointments we create an order with the appointment services and send this to the customer.
  // If the user then changed the appointment's services,
  // we should show a warning that the order's services don't match the original order's services.

  if (order.originalOrderId || !orderAppointments.value.length) {
    // no mismatch if crediting order or if order doesnt have appointments
    return false;
  }

  const orderPaidOnline =
    !!order.transactions.find(
      (transaction) =>
        transaction.type === 'ONLINE' ||
        transaction.type === 'TREATWELL_ONLINE_PAYMENT'
    ) || !!order.items.find((item) => item.transactionCost);

  if (!orderPaidOnline) {
    // no mismatch if order was not paid online
    return false;
  }

  let mismatch = false;
  orderAppointments.value.forEach((appointment) => {
    const appointmentItems = order.items.filter(
      (item: OrderItemType) => item.appointmentId === appointment.id
    );
    if (!appointmentItems.length) {
      // mismatch if the order doesnt show the appointments services at all
      mismatch = true;
      return;
    } else {
      appointment.serviceIds?.forEach((serviceId) => {
        if (
          !appointmentItems
            .map((item) => item.serviceId)
            .flat()
            .includes(serviceId)
        ) {
          // mismatch if the appointment services don't match the order services
          mismatch = true;
          return;
        }
      });
    }
  });

  return mismatch;
});
</script>

<style lang="scss" module>
.list {
  position: relative;
  padding-bottom: $spacing;
}

.appointments {
  margin-bottom: $spacing;
}

.items {
  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}

.appointments {
  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}

.alerts {
  & > * {
    margin-bottom: $spacing * 0.5;
  }
}
</style>

<style lang="scss" scoped>
.list-pop-slide-move {
  &:last-child {
    margin-bottom: 80px; // Add a margin to the bottom to make prevent the overflow on the scroll container from cutting off the content during the transition
  }
}
</style>
