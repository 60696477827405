import { useCompanyStore } from '@/stores/company';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const useTreatwellStoreStore = defineStore('treatwellStore', () => {
  const { company } = useCompanyStore();
  const storeCartItemCount = useStorage(
    `store-cart-item-count-${company.id}`,
    0,
  );

  return {
    storeCartItemCount,
  };
});
