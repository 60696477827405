<template>
  <div
    :class="[
      $style.base,
      {
        [$style.disabled]: !!action.disabled
      }
    ]"
  >
    <component
      :is="action.routerLink ? 'router-link' : action.href ? 'a' : 'div'"
      :to="action.routerLink ? action.routerLink : null"
      :href="action.href || null"
      :target="action.href ? '_blank' : null"
      :class="$style.inner"
      @click="$emit('click')"
      v-test="`modal-action-${action.name}`"
    >
      <BaseIcon v-if="action.icon" :name="action.icon" :mr="0.5" />
      <div :class="$style.label">
        {{ action.label }}
      </div>
    </component>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: ['action'],
  emits: ['click'],
});
</script>

<style lang="scss" module>
.base {
  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }
}

.inner {
  display: flex;
  align-items: center;
  padding: $spacing;
  cursor: pointer;
  background-color: white;

  .base:first-child & {
    border-radius: $radius $radius 0 0;
  }

  .base:last-child & {
    border-radius: 0 0 $radius $radius;
  }

  @include hover {
    background-color: $color-highlight;
  }

  .base.disabled & {
    pointer-events: none;
    opacity: 0.5;
  }

  .base:last-child & {
    border-bottom-right-radius: $radius;
  }
}

.label {
  font-weight: bold;
}
</style>
