<template>
  <div>
    <BaseHeading :mt="0.5" :mb="2">
      {{ $t('account.auth.employee_list.disable_confirm.heading') }}
    </BaseHeading>
    <BaseText mb>
      {{ $t('account.auth.two_factor_settings.send_code_description') }}
    </BaseText>
    <BaseSpinner v-if="loading" inline />
    <BaseButton
      v-else-if="isTwoFactorInSmsMode"
      fullWidth
      color="inverted"
      :mb="0.5"
      @click="sendSms"
      v-test="'two-factor-send-sms'"
    >
      {{ $t('account.auth.two_factor_settings.send_code') }}
    </BaseButton>
    <BaseText
      v-if="otpCodeInvalid"
      mt
      color="error"
      v-test="'two-factor-error-otp'"
    >
      {{ $t('account.auth.two_factor_settings.errors.otp') }}
    </BaseText>
    <BaseText
      v-if="isSmsSubmitted && mobilePhoneErrors.exceeded"
      mt
      color="error"
      v-test="'two-factor-interval-exceed'"
    >
      {{ $t('account.auth.two_factor_settings.errors.time_interval_exceeded') }}
    </BaseText>
    <BaseText
      v-if="isSmsSubmitted && smsSentAt !== null"
      mt
      v-test="'two-factor-sms-sent'"
    >
      <BaseText color="success">
        {{ $t('account.auth.two_factor_settings.info.code_sent') }}
      </BaseText>
      {{ $t('account.auth.two_factor_settings.info.didnt_receive') }}
    </BaseText>
    <BaseInput
      v-model="otp"
      :label="$t('account.auth.two_factor_settings.form.otp')"
      type="text"
      name="otp"
      :info="tooltipForOtp"
      mt
      v-test="'two-factor-otp'"
    />
    <BaseInput
      v-model="password"
      :label="$t('account.auth.employee_list.disable_confirm.password_confirm')"
      type="password"
      name="password"
      mb
      mt
      v-test="'two-factor-user-password'"
    />
    <BaseText
      v-if="passwordInvalid"
      color="error"
      mb
      v-test="'two-factor-error-user-password'"
    >
      {{ $t('account.auth.two_factor_settings.errors.password') }}
    </BaseText>
    <BaseText bold :mt="2" mb>{{
      $t('account.auth.employee_list.disable_confirm.checkbox_header')
    }}</BaseText>
    <BaseCheckbox
      v-model="checkConfirm"
      :label="
        $t('account.auth.employee_list.disable_confirm.checkbox_description')
      "
      v-test="'two-factor-disable-confirm'"
    />
    <div style="display: flex; justify-content: end" class="mt-2">
      <BaseButton
        mr
        color="inverted"
        @click="$router.push({ name: 'account' })"
      >
        {{ $t('account.auth.employee_list.disable_confirm.cancel') }}
      </BaseButton>
      <BaseButton
        :disabled="!checkConfirm || password === ''"
        @click="onDisableClick"
        v-test="'two-factor-disable-button'"
      >
        {{ $t('account.auth.employee_list.disable_confirm.disable') }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    passwordInvalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    otpCodeInvalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedEmployee: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['disable'],
  setup() {
    const { result, loading } = useQuery(gql`
      query getCurrentUser {
        currentUser {
          twoFactorSettings {
            mode
          }
        }
      }
    `);

    const isTwoFactorInSmsMode = computed(
      () => result.value?.currentUser.twoFactorSettings?.mode === 'SMS',
    );

    return {
      loading,
      isTwoFactorInSmsMode,
    };
  },
  data() {
    return {
      password: '',
      otp: '',
      checkConfirm: false,
      smsSentAt: null,
      isSmsSubmitted: false,
      mobilePhoneErrors: {},
    };
  },
  computed: {
    tooltipForOtp() {
      return this.isTwoFactorInSmsMode
        ? this.$t('account.auth.two_factor_settings.form.tooltip_otp_sms')
        : this.$t('account.auth.two_factor_settings.form.tooltip_otp_app');
    },
  },
  methods: {
    onDisableClick() {
      this.$emit('disable', { password: this.password, otp: this.otp });
    },
    sendSms() {
      this.isSmsSubmitted = true;
      this.mobilePhoneErrors = {};
      this.smsSentAt = null;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation sendTwoFactorSms($input: SendTwoFactorSmsInput!) {
              sendTwoFactorSms(input: $input) {
                smsSentAt
                errors
              }
            }
          `,
          variables: {
            input: {},
          },
        })
        .then((response) => {
          response = response.data.sendTwoFactorSms;
          if (response?.smsSentAt) {
            this.smsSentAt = response?.smsSentAt;
          } else {
            if (response?.errors[0]?.timeInterval) {
              this.mobilePhoneErrors = { exceeded: true };
            }
          }
        });
    },
  },
});
</script>
