<template>
  <Modal v-bind="{ parentRouteName, saving, loading }" @submit="submit">
    <SalonizedToGoogle />
    <GoogleToSalonized v-if="unleash.isEnabled('2WayGoogleSync')" />
  </Modal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { flash } from '@/helpers/ui';
import unleash from '@/unleash';

import Modal from '../shared/Modal.vue';
import { useGoogleCalendar } from './use-google-calendar';
import SalonizedToGoogle from './SalonizedToGoogle.vue';
import GoogleToSalonized from './google-to-salonized/index.vue';

const props = defineProps<{
  parentRouteName: string;
}>();

const {
  loading,
  appId,
  salonizedToGoogleSyncMode,
  calendars,
  incomingCalendars,
  companyCalendar,
  writableLists,
  unwritableLists,
  updateGoogleCalendar,
  createIncomingGoogleCalendar,
  saving,
} = useGoogleCalendar();

const router = useRouter();
const { t } = useI18n();

provide('salonized-google-calendar', {
  salonizedToGoogleSyncMode,
  calendars,
  companyCalendar,
  incomingCalendars,
  writableLists,
  unwritableLists,
});

const submit = () => {
  const input: any = {
    id: appId.value,
    mode: salonizedToGoogleSyncMode.value,
  };

  if (salonizedToGoogleSyncMode.value === 'simple') {
    input.companyCalendar = companyCalendar.value;
  } else {
    input.calendars = calendars.value.filter(
      (calendar) => !!calendar.calendarId,
    );
  }

  if (unleash.isEnabled('2WayGoogleSync')) {
    const promises = Promise.all([
      updateGoogleCalendar({
        input,
      }),
      createIncomingGoogleCalendar({
        input: {
          calendars: incomingCalendars.value,
        },
      }),
    ]);

    promises.then(() => {
      flash(t('global.flash.integration_updated'));

      router.push({
        name: props.parentRouteName,
      });
    });
  } else {
    updateGoogleCalendar({
      input,
    }).then(() => {
      flash(t('global.flash.integration_updated'));

      router.push({
        name: props.parentRouteName,
      });
    });
  }
};
</script>
