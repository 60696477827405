import config from '@/config';
import { Cloudinary } from 'cloudinary-core';
const cloudinaryCore = new Cloudinary({
  cloud_name: config.cloudinaryCloudName,
});

export default (resource) => {
  let imageSrc;

  if (resource.picture) {
    imageSrc = cloudinaryCore.url(resource.picture, {
      width: 48,
      height: 48,
      crop: 'fill',
      format: 'jpg',
    });
  }

  return `
    <div 
      class="calendar-resource-avatar"
      style="background-color: ${resource.color}; ${imageSrc ? `background-image: url(${imageSrc});` : ''}"
    >${imageSrc ? '' : resource.initials}</div>`;
};
