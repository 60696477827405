const Accounts = () => import('@/modules/admin/accounts/index.vue');
const Account = () => import('@/modules/admin/accounts/Account.vue');

export default [
  {
    path: 'accounts',
    name: 'admin-accounts',
    meta: {
      featureFlag: 'admin-accounts',
    },
    component: Accounts,
    children: [
      {
        path: 'logs',
        name: 'admin-user-logs',
        redirect: {
          name: 'admin-accounts',
        },
      },
      {
        path: 'product-imports',
        name: 'admin-product-imports',
        redirect: {
          name: 'product-imports',
        },
      },
      {
        path: 'new',
        name: 'admin-account-new',
        component: Account,
      },
      {
        path: ':accountId',
        name: 'admin-account-edit',
        component: Account,
      },
    ],
  },
];
