<template>
  <BaseCard :gray="isEnabled" v-test="'lp-type'">
    <div
      :class="[
        $style.base,
        {
          [$style.hasAmount]: hasAmount
        }
      ]"
    >
      <div :class="$style.wrap">
        <BaseRadio
          v-model="radioValue"
          :options="[
            {
              label: '',
              value: true
            }
          ]"
          v-test="'lp-type-radio'"
        />
      </div>
      <div>
        <div :class="$style.wrap" @click="$emit('update:selectedType', type)">
          <template v-if="hasAmount">
            <div :class="$style.heading">
              {{ $t(`${translationKey}.heading_before`) }}
            </div>
            <div :class="$style.inputWrap">
              <BaseInput
                :modelValue="selectedAmount"
                type="currency"
                @update:modelValue="$emit('update:selectedAmount', $event)"
                v-test="'lp-type-amount'"
              />
            </div>
            <div :class="$style.heading">
              {{ $t(`${translationKey}.heading_after`) }}
            </div>
          </template>
          <div v-else :class="$style.heading">
            {{ $t(`${translationKey}.heading`) }}
          </div>
        </div>
        {{
          $t(`${translationKey}.description`, {
            amount: filters.currency(selectedAmount)
          })
        }}
      </div>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import type { LoyaltyPointsType } from '@/types';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<LoyaltyPointsType>,
      required: true,
    },
    selectedType: {
      type: String as PropType<LoyaltyPointsType>,
      required: true,
    },
    selectedAmount: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  emits: ['update:selectedType', 'update:selectedAmount'],
  setup(props, { emit }) {
    const isEnabled = computed(() => props.selectedType === props.type);

    return {
      isEnabled,
      hasAmount: computed(() =>
        [
          'SPENT_AMOUNT',
          'SPENT_AMOUNT_PRODUCTS',
          'SPENT_AMOUNT_SERVICES',
        ].includes(props.type),
      ),
      radioValue: computed({
        get() {
          return isEnabled.value;
        },
        set() {
          emit('update:selectedType', props.type);
        },
      }),
      translationKey: `loyalty_points.types.${props.type.toLowerCase()}`,
      filters,
    };
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
}

.heading {
  font-weight: bold;
  cursor: default;
}

.wrap {
  margin-bottom: $spacing * 0.5;

  .base.hasAmount & {
    display: flex;
    align-items: center;
    height: $input-height;
  }
}

.inputWrap {
  margin-left: $spacing * 0.5;
  margin-right: $spacing * 0.5;
  width: 90px;
  flex-shrink: 0;
}
</style>
