<template>
  <BaseCard :tabs="tabs" :headerMargin="false">
    <template #header>
      <div v-if="employees.length > 1" :class="$style.resourceSelect">
        <BaseDropdown
          v-show="activeTab === 'schedule'"
          v-model="selectedResourceId"
          :options="[
            {
              label: $t('calendar.show_all'),
              value: 'all',
              icon: 'person'
            },
            ...employees.map((r) => ({
              label: r.name,
              value: r.id
            }))
          ]"
          :hideText="$screen === 's'"
          resources
          v-test="'schedule-resources'"
        />
      </div>
    </template>
    <Schedule
      v-show="activeTab === 'schedule'"
      :isVisible="activeTab === 'schedule'"
      :selectedResourceId="
        selectedResourceId === 'all' ? null : selectedResourceId
      "
    />
    <Activity
      v-if="activeTab === 'activity'"
      :isVisible="activeTab === 'activity'"
    />
  </BaseCard>
</template>

<script lang="ts">
import Schedule from './Schedule.vue';
import Activity from './Activity.vue';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Schedule,
    Activity,
  },
  data() {
    return {
      selectedResourceId: 'all',
      tabs: [
        {
          id: 'schedule',
          label: this.$t('dashboard.schedule.heading'),
          active: true,
        },
        {
          id: 'activity',
          label: this.$t('dashboard.activity.heading'),
          active: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(useResourcesStore, ['employees']),
    activeTab() {
      return this.tabs.find((tab) => tab.active).id;
    },
  },
});
</script>

<style lang="scss" module>
.resourceSelect {
  max-width: 200px;
  min-height: $input-height;
}
</style>
