<template>
  <BaseModal
    :heading="$t('treatwell.update_profile.description.header')"
    useForm
    :parentRoute="parentRouteName"
    :loading="loading"
    @submit="submit"
    @close="close"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <BaseText mb>
        {{ $t('treatwell.update_profile.description.rules.header') }}
      </BaseText>
      <ul class="mb-1">
        <li v-for="i in 4" :key="i">
          <BaseText>
            {{ $t(`treatwell.update_profile.description.rules.${i}`) }}
          </BaseText>
        </li>
      </ul>
      <BaseCard mb>
        <div :class="$style.form">
          <BaseInput
            v-model="formData.descriptionAtmosphere"
            :label="$t('treatwell.status.salon_description_atmosphere')"
            type="textarea"
            :rows="2"
            :disabled="disabled"
            v-test="'input-descriptionAtmosphere'"
          />
          <BaseInput
            v-model="formData.descriptionExperience"
            :label="$t('treatwell.status.salon_description_experience')"
            type="textarea"
            :rows="2"
            :disabled="disabled"
            v-test="'input-descriptionExperience'"
          />
          <BaseInput
            v-model="formData.descriptionSpecialities"
            :label="$t('treatwell.status.salon_description_specialises')"
            type="textarea"
            :rows="2"
            :disabled="disabled"
            v-test="'input-descriptionSpecialities'"
          />
          <BaseInput
            v-model="formData.descriptionBrandsProducts"
            :label="$t('treatwell.status.salon_description_brands')"
            type="textarea"
            :rows="2"
            :disabled="disabled"
            v-test="'input-descriptionBrandsProducts'"
          />
          <BaseInput
            v-model="formData.descriptionTransports"
            :label="$t('treatwell.status.salon_description_public_transport')"
            type="textarea"
            :rows="2"
            :disabled="disabled"
            v-test="'input-descriptionTransports'"
          />
          <BaseInput
            v-model="formData.descriptionExtras"
            :label="$t('treatwell.status.salon_description_extras')"
            type="textarea"
            :rows="2"
            :disabled="disabled"
            v-test="'input-descriptionExtras'"
          />
        </div>
        <BaseAlert
          v-if="disabled"
          color="warning"
          :text="$t('treatwell.status.salon_description_disabled')"
        />
      </BaseCard>
    </div>
    <template #footer>
      <BaseButton
        submitForm
        :disabled="disabled"
        v-test="'salon-description-submit'"
      >
        {{ $t('global.actions.submit') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { flash, modal } from '@/helpers/ui';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { GET_INTEGRATION_REQUEST_DESCRIPTION } from '../graphql';
import { useTreatwellStore } from '@/stores/treatwell';

const props = defineProps<{
  parentRouteName?: string;
}>();

const emit = defineEmits(['close']);

const router = useRouter();

const UPSERT_CHANGE_REQUEST = gql`
  mutation treatwellUpsertChangeRequest($input: UpsertChangeRequestInput!) {
    treatwellUpsertChangeRequest(input: $input) {
      treatwellIntegrationRequest {
        id
      }
    }
  }
`;

const formData = reactive({
  descriptionAtmosphere: '',
  descriptionExperience: '',
  descriptionSpecialities: '',
  descriptionBrandsProducts: '',
  descriptionTransports: '',
  descriptionExtras: '',
});

const { treatwellStatus, hasActiveVenue } = useTreatwellStore();
const disabled = computed(
  () => treatwellStatus.descriptionProvided || hasActiveVenue,
);

const { onResult, loading } = useQuery(GET_INTEGRATION_REQUEST_DESCRIPTION);

onResult(
  ({
    data: {
      treatwellCurrentIntegrationRequest: { changeRequest },
    },
  }) => {
    if (changeRequest) {
      Object.keys(formData).forEach((key) => {
        if (changeRequest[key]) {
          formData[key] = changeRequest[key];
        }
      });
    }
  },
);

const { mutate } = useMutation(UPSERT_CHANGE_REQUEST, {
  variables: {
    input: {
      salonDescription: formData,
    },
  },
});

const close = () => {
  props.parentRouteName
    ? router.push({
        name: props.parentRouteName,
      })
    : emit('close');
};

const { t } = useI18n();

const submit = () => {
  modal('confirmation', {
    message: t('treatwell.update_profile.confirmation'),
  }).then(() => {
    mutate().then(() => {
      flash(t('global.flash.treatwell_integration_updated'));
      treatwellStatus.descriptionProvided = true;
      close();
    });
  });
};
</script>

<style lang="scss" module>
.form {
  .base:not(.smallScreen) & {
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: calc(50% - #{$spacing * 0.5});

      &:nth-child(even) {
        margin-left: $spacing;
      }
    }
  }

  & > * {
    margin-bottom: $spacing;
  }
}
</style>
