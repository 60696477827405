<template>
  <Builder :settings="settings">
    <template #actions>
      <Actions />
    </template>
  </Builder>
</template>

<script lang="ts" setup>
import gql from 'graphql-tag';
import Builder from '@/modules/marketing/email/builder/index.vue';
import Actions from './Actions.vue';
import { computed, reactive, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';

const store = useStore();
const { t } = useI18n();

const allFiltersForGraphql = computed(
  () => store.getters['marketing/allFiltersForGraphql'],
);

const settings = reactive({
  title: t('marketing.email.intro.title'),
  description: t('marketing.email.intro.description'),
  sentDescription: t(
    'marketing.email.sections.when_it_should_be_sent_scheduled',
  ),
  receiveDescription: t(
    'marketing.email.sections.who_is_going_to_receive_it_info',
  ),
  showFilters: true,
  showSendTest: true,
  allowImageUpload: true,
});

const loading = ref(false);

const fetchFilteredCustomersCount = () => {
  loading.value = true;

  const { onResult } = useQuery(
    gql`
      query filteredCustomersCount($filters: [CustomerFilterInput!]!) {
        filteredCustomersCount(filters: $filters)
      }
    `,
    {
      filters: allFiltersForGraphql,
    },
  );

  onResult((value: any) => {
    store.commit(
      'marketing/builder/SET_FILTERED_CUSTOMERS_COUNT',
      value.data.filteredCustomersCount,
    );
    loading.value = false;
  });
};

fetchFilteredCustomersCount();

watch(loading, () => {
  store.commit('marketing/builder/SET_LOADING_CUSTOMERS_COUNT', loading.value);
});

watch(allFiltersForGraphql, () => {
  fetchFilteredCustomersCount();
});
</script>
