<template>
  <a :href="adminUrl">
    <BaseIcon
      :name="user.impersonated ? 'eye-off' : 'eye'"
      :color="
        user.impersonated ? 'error' : showMobileMenu ? 'inverted' : 'default'
      "
      :tooltip="{
        position: 'bottom',
        text: $t(user.impersonated ? 'nav.stop_impersonating' : 'nav.analyze')
      }"
    />
  </a>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';
import config from '@/config';

import { defineComponent } from 'vue';
import { usePageLayoutStore } from '@/stores/page-layout';

export default defineComponent({
  setup() {
    return {
      backendUrl: config.backendUrl,
    };
  },
  computed: {
    ...mapState(useUserStore, ['user']),
    ...mapState(useCompanyStore, ['company']),
    ...mapState(usePageLayoutStore, ['showMobileMenu']),
    adminUrl() {
      if (this.user.treatwellAdmin) {
        return `${this.backendUrl}/${this.user.impersonated ? 'stop_impersonating' : 'treatwell_admin/company'}`;
      } else {
        return `${this.backendUrl}/${this.user.impersonated ? 'stop_impersonating' : 'analyze/companies'}`;
      }
    },
  },
});
</script>
