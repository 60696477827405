<template>
  <div :class="$style.wrapper">
    <div
      ref="base"
      :class="[
        $style.base,
        {
          [$style.multiple]: multiple,
          [$style.small]: small,
          [$style.canEdit]: canEdit,
          [$style.hasExtraInfo]: !!extraInfo,
          [$style.fullWidth]: fullWidth,
          [$style.alignRight]: alignRight,
          [$style.breakLines]: breakLines,
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <div
        ref="scrollContainer"
        :class="[
          $style.inner,
          {
            [$style.fullHeight]: fullHeight
          }
        ]"
        :style="listHeight ? { 'max-height': listHeight + 'px' } : {}"
      >
        <div
          v-if="hasSearch"
          ref="search"
          :class="$style.search"
          v-test="'baseFloatingListSearch'"
        >
          <BaseSearch
            :ml="0.5"
            :mr="0.5"
            :mt="0.5"
            @update:modelValue="onSearch"
          />
        </div>
        <div>
          <div
            v-for="(group, groupIndex) in listData"
            :key="groupIndex"
            :class="$style.group"
          >
            <div
              v-if="group.name"
              :class="$style.groupName"
              v-test="'baseFloatingListGroupName'"
            >
              <BaseHeading size="s" :mb="0.5">
                {{ group.name }}
              </BaseHeading>
            </div>
            <div
              v-for="(item, index) in group.items"
              :key="index"
              :class="$style.item"
              tabindex="0"
            >
              <div
                :class="$style.itemInner"
                @keyup.enter="select(item)"
                @click="select(item)"
                v-test="
                  `baseFloatingList-${groupIndex}${groupBy ? `-${index}` : ''}`
                "
              >
                <div
                  v-if="
                    item.type === 'employee' ||
                    item.type === 'room' ||
                    item.type === 'equipment'
                  "
                  :class="$style.avatar"
                >
                  <BaseAvatar
                    :key="item.id"
                    :resource="item"
                    v-test="'baseFloatingListAvatar'"
                  />
                </div>
                <BaseCheckbox
                  v-if="multiple"
                  :modelValue="
                    !!selectedItems.find((selected) => selected === item.id)
                  "
                  :mr="0.5"
                  v-test="'baseFloatingListCheckbox'"
                />
                <div :class="$style.icon">
                  <BaseIcon
                    v-if="item.icon"
                    :name="item.icon"
                    size="s"
                    v-test="'baseFloatingListIcon'"
                  />
                </div>
                <BaseColorLabel
                  v-if="colorLabels && item.color"
                  :modelValue="item.color"
                  :class="$style.color"
                  small
                  v-test="'baseFloatingListLabel'"
                />
                <BaseTableRow v-if="item.tableData">
                  <BaseTableCell
                    v-for="(value, key) in item.tableData"
                    :key="`cell-${key}`"
                  >
                    <BaseLabel
                      v-if="key === 'state' || key === 'status'"
                      :state="item.tableData[key]"
                    />
                    <template v-else>
                      {{ item.tableData[key] }}
                    </template>
                  </BaseTableCell>
                </BaseTableRow>
                <div
                  v-else
                  :class="$style.text"
                  v-test="'baseFloatingListText'"
                >
                  {{
                    checkCapitalize(
                      typeof item === 'object' ? item[selectKey] : item
                    )
                  }}
                </div>
                <div
                  v-if="extraInfo && item[extraInfo] !== undefined"
                  class="ml-1"
                >
                  <BaseText color="secondary">
                    <template v-if="extraInfo === 'price'">
                      {{ filters.currency(item[extraInfo]) }}
                    </template>
                    <template v-else>
                      {{ item[extraInfo] }}
                    </template>
                  </BaseText>
                </div>
              </div>
              <div
                v-if="canEdit && item.editable"
                :class="$style.btnEdit"
                @click="$emit('edit', item)"
                v-test="'editItem'"
              >
                <BaseIcon name="edit" tooltip="Edit" />
              </div>
            </div>
          </div>
          <div
            v-if="hasSlot"
            :class="$style.item"
            @click="select('custom-slot')"
          >
            <slot />
          </div>
        </div>
      </div>
      <transition name="slide">
        <div v-if="multiple" :class="$style.completeMultiple">
          <BaseButton
            @click.stop="$emit('close')"
            v-test="'baseFloatingListConfirm'"
          >
            OK
          </BaseButton>
        </div>
      </transition>
    </div>
    <div
      v-if="bottomMargin"
      :class="$style.emptySpace"
      class="js-floating-list-empty-space"
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
let observer;

import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    selectKey: {
      type: String,
      default: 'name',
    },
    colorLabels: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      required: false,
    },
    fullHeight: {
      type: Boolean,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
    selectedItems: {
      type: Array,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    searchKey: {
      type: String,
      required: false,
      default: 'name',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
    },
    valueKey: {
      type: String,
    },
    groupBy: {
      type: String,
    },
    sortGroupBy: {
      type: String,
    },
    sortBy: {
      type: String,
    },
    extraInfo: {
      type: String,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    listHeight: {
      type: Number,
      default: null,
    },
    searchAutoFocus: {
      type: Boolean,
      default: false,
    },
    scrollContainer: {
      type: String,
    },
    breakLines: {
      type: Boolean,
      default: false,
    },
    noCaps: {
      type: Boolean,
      default: false,
    },
    bottomMargin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit', 'close', 'update:modelValue', 'select'],
  //inheritAttrs: false,
  setup() {
    return {
      filters,
    };
  },
  data() {
    return {
      query: '',
      alignRight: false,
    };
  },
  computed: {
    resources() {
      return this.data.filter(
        (item) =>
          item.type === 'employee' ||
          item.type === 'room' ||
          item.type === 'equipment',
      );
    },
    hasSearch() {
      return this.enableSearch || this.resources?.length > 6;
    },
    hasSlot() {
      return this.$slots.default;
    },
    filteredData() {
      let data = this.data;

      if (this.query) {
        data = data.filter((item) =>
          item[this.searchKey]
            ?.toLowerCase()
            .includes(this.query.toLowerCase()),
        );
      }

      if (this.sortBy) {
        data = data.sort((a, b) => a[this.sortBy] - b[this.sortBy]);
      }

      return data;
    },
    groupedData() {
      if (!this.groupBy) {
        return null;
      }

      let groupedData = [];
      this.filteredData.forEach((item) => {
        const group = groupedData.find((g) => g.id === item[this.groupBy].id);
        if (group) {
          group.items.push(item);
        } else {
          const newGroup = {
            name: item[this.groupBy].name,
            id: item[this.groupBy].id,
            items: [item],
          };

          if (this.sortGroupBy) {
            newGroup[this.sortGroupBy] = item[this.groupBy][this.sortGroupBy];
          }

          groupedData.push(newGroup);
        }
      });

      if (this.sortGroupBy) {
        groupedData = groupedData.sort(
          (a, b) => a[this.sortGroupBy] - b[this.sortGroupBy],
        );
      }

      if (this.sortBy) {
        groupedData.forEach((group) => {
          // Sort alphabetically first, and then by sortBy prop
          group.items = group.items
            .sort((a, b) =>
              a.name?.localeCompare(b.name, 'en', { sensitivity: 'base' }),
            )
            .sort((a, b) => a[this.sortBy] - b[this.sortBy]);
        });
      }

      return groupedData;
    },
    listData() {
      return this.groupBy
        ? this.groupedData
        : this.filteredData.map((item) => ({ items: [item] }));
    },
  },
  methods: {
    checkCapitalize(value) {
      return this.noCaps ? value : this.filters.capitalize(value);
    },
    select(item) {
      if (this.value && this.valueKey) {
        this.$emit('update:modelValue', item[this.valueKey]);
      } else {
        this.$emit('select', item);
      }
    },
    onSearch(query) {
      this.query = query;
    },
    onShow() {
      this.$refs.scrollContainer.scrollTop = 0;
      if (this.searchAutoFocus && this.hasSearch) {
        this.$nextTick(() => {
          this.$refs.search.querySelector('input').focus();
        });
      }

      this.setPosition();
    },
    onHide() {
      this.resetPosition();
    },
    setPosition() {
      const scrollContainerEl = this.scrollContainer
        ? document.querySelector(this.scrollContainer)
        : document.body;
      if (scrollContainerEl) {
        const rectEl = this.$refs.base.getBoundingClientRect();
        const rectContainer = scrollContainerEl.getBoundingClientRect();

        this.alignRight =
          rectEl.x + rectEl.width > rectContainer.width - rectContainer.x;
      }
    },
    resetPosition() {
      this.alignRight = false;
    },
  },
  mounted() {
    observer = new MutationObserver(() => {
      this.$el.style.display !== 'none' ? this.onShow() : this.onHide();
    });
    observer.observe(this.$el, {
      attributes: true,
      attributeFilter: ['style'],
    });
  },
  beforeUnmount() {
    observer.disconnect();
  },
});
</script>

<style lang="scss" module>
.wrapper {
  position: absolute;
  top: calc(100% + #{$spacing * 0.5});
  left: -1px;
  width: calc(100% + 2px);
  z-index: 10;
}

.base {
  @include floating-list;
  min-width: 180px;

  &.hasExtraInfo {
    &:not(.smallScreen) {
      min-width: 340px;
    }

    &.smallScreen {
      min-width: calc(100vw - #{$spacing * 5});
    }
  }

  &.multiple {
    min-width: 240px;
  }

  &.fullWidth {
    width: min-content;
    min-width: 100%;
  }

  &.alignRight {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
  }

  &.smallScreen {
    max-width: calc(100vw - #{$spacing * 5});
  }
}

.btnEdit {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.inner {
  @include floating-list-inner;

  .multiple & {
    border-radius: 0;
  }

  &.fullHeight,
  &.multiple {
    max-height: none;
  }
}

.search {
  border-bottom: 1px solid $color-border;
  padding-bottom: $spacing * 0.5;
}

.avatar {
  flex-shrink: 0;
  margin-left: $spacing * -0.25;
}

.group:not(:first-child) {
  border-top: 1px solid $color-border;
}

.groupName {
  padding: $spacing * 1.5 $spacing $spacing * 0.5;
}

.item {
  @include floating-list-item;

  .base.canEdit & {
    padding-right: 40px;
  }
}

.itemInner {
  @include floating-list-item-inner;

  .base.small & {
    min-height: 32px;
  }

  .base.canEdit & {
    padding-right: 0;
  }

  .base.hasExtraInfo & {
    justify-content: space-between;
  }
}

.text {
  overflow-y: hidden;
  padding: 2px 0;
  text-align: left; // Needed for Safari for some reason

  .base:not(.breakLines) & {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.completeMultiple {
  display: flex;
  justify-content: flex-end;
  padding: $spacing * 0.5;
  border-top: 1px solid $color-border;
}

.icon {
  display: flex;
  margin-right: $spacing * 0.5;
}

.avatar {
  margin-right: $spacing * 0.5;
  margin-left: $spacing * -0.25;
}

.color {
  margin-right: 7px;
}

.emptySpace {
  height: $spacing;
}
</style>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: margin-top 0.2s ease;
}
.slide-enter,
.slide-leave-to {
  margin-top: -59px;
}
</style>
