<template>
  <ModuleBase noBorder gray :loading="loading">
    <PageWrap :heading="$t('admin.nav.links.vat_rates')">
      <BaseForm @submit="submitForm">
        <div :class="$style.list">
          <VatRate
            v-for="vatRate in vatRates"
            :key="vatRate._uid"
            :vatRate="vatRate"
            @update="onUpdate(vatRate, $event)"
            @delete="onDelete(vatRate)"
            @setDefault="onSetDefault(vatRate)"
            v-test="'vat-rate'"
          />
        </div>
        <BaseText link mt @click="addVatRate" v-test="'vat-rates-add'">
          + {{ $t('vat_rates.add_vat_rate') }}
        </BaseText>
        <div :class="$style.footer">
          <BaseButton :loading="saving" submitForm v-test="'vat-rates-save'">
            {{ $t('global.actions.save') }}
          </BaseButton>
        </div>
      </BaseForm>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts">
import { modal } from '@/helpers/ui';
import ModuleBase from '@/modules/ModuleBase.vue';
import VatRate from './VatRate.vue';
import { useVatRatesStore } from '@/stores/vat-rates';
import { mapState, mapActions } from 'pinia';
import PageWrap from '@/modules/admin/PageWrap.vue';

let _uid = 0;

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VatRates',
  components: {
    ModuleBase,
    VatRate,
    PageWrap,
  },
  data() {
    return {
      vatRates: [],
    };
  },
  watch: {
    vatRatesData: {
      handler(value) {
        this.vatRates = [...value]
          .sort((a, b) => b.percentage - a.percentage)
          .map((rate) => {
            _uid++;
            return {
              ...rate,
              _uid,
            };
          });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState(useVatRatesStore, {
      vatRatesData: 'vatRates',
      loading: 'loading',
      saving: 'saving',
    }),
  },
  methods: {
    ...mapActions(useVatRatesStore, ['deleteVatRate', 'updateVatRates']),
    addVatRate() {
      _uid++;

      this.vatRates.push({
        _uid,
        name: '',
        percentage: 0,
        default: false,
      });
    },
    onDelete(vatRate) {
      modal('confirmation', {
        item: vatRate.name ? vatRate.name : null,
        type: 'delete',
      }).then(() => {
        if (vatRate.id) {
          this.deleteVatRate(vatRate.id);
        } else {
          this.vatRates = this.vatRates.filter(
            (rate) => rate._uid !== vatRate._uid,
          );
        }
      });
    },
    onUpdate(vatRate, props) {
      const rate = this.vatRates.find((rate) => rate._uid === vatRate._uid);
      rate[props.key] = props.value;

      if (props.key === 'default' && props.value) {
        const otherRates = this.vatRates.filter(
          (rate) => rate._uid !== vatRate._uid,
        );
        otherRates.forEach((rate) => {
          rate.default = false;
        });
      }
    },
    submitForm() {
      this.updateVatRates(
        this.vatRates.map((rate) => ({
          id: rate.id || null,
          name: rate.name,
          default: rate.default,
          percentage: rate.percentage,
        })),
      );
    },
  },
});
</script>

<style lang="scss" module>
.list {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: $spacing;
}
</style>
