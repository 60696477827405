<template>
  <div
    class="calendar-wrap"
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.touch]: device.touch,
        [$style.isDragging]: isDragging,
        [$style.isResizing]: isResizing,
        [$style.isInteracting]: !!action,
        [$style.isSafari]: browser.isSafari,
        [$style.mobileFullView]: mobileFullView,
        [$style.scrollable]: !mobileFullView,
        [$style.dynamicPricingEnabled]: dynamicPricingMode,
        [$style.previewMode]: previewModeActive,
        [$style.previewLayout]: previewLayoutActive,
        'calendar-wrap--mobile-full-view': mobileFullView,
        'calendar-wrap--small-screen': $screen === 's',
        'calendar-wrap--preview': previewModeActive
      }
    ]"
  >
    <Popovers v-if="isCalendarMounted" :calendarElement="calendarElement" />
    <div
      id="calendar-container"
      ref="calendarElement"
      :class="$style.calendar"
      class="vue-calendar"
    />
    <BaseSpinner v-show="isLoading" />
  </div>
</template>
<script lang="ts">
export default {
  name: 'Calendar',
};
</script>

<script lang="ts" setup>
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useCalendarSetup } from './calendar-setup';
import { useWatchers as useEventsWatchers } from './calendar-events';
import { useWatchers as useResourcesWatchers } from './calendar-resources';
import { useCalendarScroll } from './calendar-scroll';
import { storeToRefs } from 'pinia';
import { useDragResize } from './calendar-interaction/drag-resize';
import { useSubscription } from './calendar-subscription';
import { device, browser } from '@/user-context';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import Popovers from './popovers/index.vue';
import { ref, onMounted } from 'vue';

const isCalendarMounted = ref(false);
const { renderCalendar, onCalendarMounted, isLoading } = useCalendarSetup();
const { isDragging, isResizing } = useDragResize();
const calendarElement = ref<HTMLElement | null>(null);

onMounted(() => {
  if (calendarElement.value) {
    renderCalendar(calendarElement.value);
  }

  useSubscription();
  useEventsWatchers();
  useResourcesWatchers();
});

onCalendarMounted(() => {
  isCalendarMounted.value = true;

  if (!calendarElement.value) {
    return;
  }

  useCalendarScroll(calendarElement.value);

  const wrapper = calendarElement.value.querySelector('.fc-view-harness');
  if (wrapper) {
    // Add class to tr elements not on the the whole hour
    Array.from(wrapper.querySelectorAll('.fc-timegrid-slot')).forEach((el) => {
      if (/00:00$/.test(el.getAttribute('data-time'))) {
        el.classList.add('fc-whole-hour');
      }
    });
  }
});

const { mobileFullView, dynamicPricingMode } = storeToRefs(
  useCalendarFiltersStore()
);
const { action } = storeToRefs(usePreCreateStore());

const { previewModeActive, previewLayoutActive } = storeToRefs(
  useCalendarPreviewStore()
);
</script>

<style lang="scss">
@import './styles/fullcalendar.css';
@import './styles/fullcalendar-common.css';
@import './styles/fullcalendar-timegrid.css';
@import './styles/fullcalendar-daygrid.css';
</style>

<style lang="scss" module>
@import './styles/custom';
</style>
