<template>
  <div
    :class="[
      $style.base,
      {
        [$style.isSmall]: $screen === 's',
        [$style.isMedium]: $screen === 'm'
      }
    ]"
  >
    <div :class="$style.inner">
      <div :class="$style.header">
        <div :class="$style.headerLeft">
          <DateSelect
            :label="$t('global.actions.period')"
            @select="onDateSelect"
            v-test="'dateSelect'"
          />
        </div>
        <div ref="dropdownContainerEl" :class="$style.headerRight">
          <BaseDropdown
            v-if="employees.length > 1"
            v-model="filters.employeeId"
            :options="[
              {
                value: null,
                label: $t('global.all')
              },
              ...employees.map((employee) => ({
                value: employee.id,
                label: employee.name
              }))
            ]"
            :scrollContainer="dropdownContainerEl"
            :label="$t('global.items.employee', 1)"
            resources
          />

          <BaseDropdown
            v-if="categories.length"
            v-model="filters.categories"
            :options="
              categories.map((category) => ({
                value: category.categoryId,
                label: category.name
              }))
            "
            :placeholder="$t('global.items.category', 2)"
            :label="$t('global.items.category', 2)"
            :scrollContainer="dropdownContainerEl"
            searchable
            v-test="'categorySelect'"
          />

          <BaseExport
            v-if="hasData && $screen !== 's'"
            :urlTemplate="exportUrl"
            v-test="'exportButton'"
          />
        </div>
      </div>

      <transition mode="out-in">
        <div
          v-if="hasData"
          key="data"
          :class="[$style.transition, $style.flex]"
        >
          <BaseCard :mr="$screen !== 's'" :mb="$screen === 's'">
            <BaseDropdown
              v-model="selectedDoughnutType"
              :options="doughnutTypes"
              :label="$t('reports.sales.doughnut_label')"
            />
            <DoughnutChart
              :chartData="chartData"
              :currency="selectedDoughnutType === 'revenueExcl'"
              v-test="'doughnut'"
            />
            <BaseAlert
              v-if="type === 'products'"
              :text="$t('reports.sales.customize_chart')"
              color="warning"
              mt
            />
          </BaseCard>
          <BaseCard>
            <BaseScroll :contentWidth="700">
              <BaseTableRow head>
                <BaseTableCell :width="40">
                  {{ $t('global.name') }}
                </BaseTableCell>
                <BaseTableCell right wordBreak textAlign="right" :width="20">
                  {{ $t('reports.sales.amount_sold') }}
                </BaseTableCell>
                <BaseTableCell
                  v-if="type === 'products'"
                  right
                  wordBreak
                  textAlign="right"
                  :width="20"
                >
                  {{ $t('reports.sales.purchase_value') }}
                </BaseTableCell>
                <BaseTableCell right wordBreak textAlign="right" :width="20">
                  {{ $t('reports.sales.revenue_incl') }}
                </BaseTableCell>
                <BaseTableCell right wordBreak textAlign="right" :width="20">
                  {{ $t('reports.sales.revenue_excl') }}
                </BaseTableCell>
                <BaseTableCell right :width="20">
                  {{ $t('reports.sales.percentage') }}
                </BaseTableCell>
              </BaseTableRow>

              <BaseTableRow
                v-for="(item, index) in rows"
                :key="index"
                :footer="item.isCategory"
                :summaryColor="item.categoryColor"
                v-test="'reportRow'"
              >
                <BaseTableCell :width="40" wordBreak>
                  {{ item.name }}
                </BaseTableCell>
                <BaseTableCell right :width="20">
                  {{ item.amountSold }}
                </BaseTableCell>
                <BaseTableCell v-if="type === 'products'" currency :width="20">
                  {{ currencyFilter(item.purchasePrice) }}
                </BaseTableCell>
                <BaseTableCell currency :width="20">
                  {{ currencyFilter(item.revenueIncl) }}
                </BaseTableCell>
                <BaseTableCell currency :width="20">
                  {{ currencyFilter(item.revenueExcl) }}
                </BaseTableCell>
                <BaseTableCell right :width="20">
                  {{ twoDecimals(item.percentageSold) }}%
                </BaseTableCell>
              </BaseTableRow>

              <BaseTableRow footer border>
                <BaseTableCell :width="40">
                  {{ $t('reports.sales.totals') }}
                </BaseTableCell>
                <BaseTableCell right :width="20">
                  {{ totals.amountSold }}
                </BaseTableCell>
                <BaseTableCell v-if="type === 'products'" currency :width="20">
                  {{ currencyFilter(totals.purchasePrice) }}
                </BaseTableCell>
                <BaseTableCell currency :width="20">
                  {{ currencyFilter(totals.revenueIncl) }}
                </BaseTableCell>
                <BaseTableCell currency :width="20">
                  {{ currencyFilter(totals.revenueExcl) }}
                </BaseTableCell>
                <BaseTableCell right :width="20">
                  {{ normalizePercentage(totals.percentageSold) }}%
                </BaseTableCell>
              </BaseTableRow>
            </BaseScroll>
          </BaseCard>
        </div>

        <div v-else :class="$style.transition" v-test="'noData'">
          <BaseCard key="noData">
            <NoDataImage :class="$style.noDataImage" />

            <div :class="$style.noDataText">
              {{ $t('reports.sales.no_data') }}
            </div>
          </BaseCard>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import filters from '@/filters';
import dayjs from '@/dayjs';
import gql from 'graphql-tag';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { twoDecimals } from '@/helpers/formatting';
import DoughnutChart from './DoughnutChart.vue';
import DateSelect from './DateSelect.vue';
import NoDataImage from './NoDataImage.vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  name: 'SalesReport',

  components: {
    DateSelect,
    DoughnutChart,
    NoDataImage,
    BaseTableRow,
    BaseTableCell,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  setup() {
    const dropdownContainerEl = ref<HTMLElement>();

    return {
      dropdownContainerEl,
      twoDecimals,
      currencyFilter: filters.currency,
    };
  },

  data() {
    return {
      filters: {
        employeeId: null,
        categories: [],
        startDate: dayjs().startOf('day').format(),
        endDate: dayjs().endOf('day').format(),
      },

      salesReport: {
        categories: [],
        data: [],
        total: {},
      },

      selectedDoughnutType: 'amountSold',
      doughnutTypes: [
        {
          label: this.$t('reports.sales.amount_sold'),
          value: 'amountSold',
        },
        {
          label: this.$t('reports.sales.revenue_excl'),
          value: 'revenueExcl',
        },
      ],
    };
  },

  apollo: {
    salesReport: {
      query: gql`
        query salesReport(
          $reportType: String!
          $startDate: DateTime
          $endDate: DateTime
          $employeeId: Int
          $locationId: ID
          $dataScope: DataScope
        ) {
          salesReport(
            reportType: $reportType
            startDate: $startDate
            endDate: $endDate
            employeeId: $employeeId
            locationId: $locationId
            dataScope: $dataScope
          ) {
            categories {
              name
              categoryId
              categoryColor
              categorySortOrder
              percentageSold
              amountSold
              purchasePrice
              revenueIncl
              revenueExcl
            }
            data {
              id
              name
              categoryId
              categoryName
              amountSold
              percentageSold
              purchasePrice
              revenueIncl
              revenueExcl
            }
          }
        }
      `,
      variables() {
        return {
          reportType: this.type,
          startDate: this.filters.startDate,
          endDate: this.filters.endDate,
          employeeId: this.filters.employeeId,
          locationId: this.locationId,
          dataScope: this.dataScope,
        };
      },
      fetchPolicy: 'no-cache',
    },
  },

  computed: {
    ...mapState(useResourcesStore, ['employees']),
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    categories() {
      const sorted = [...this.salesReport.categories].sort((a, b) => {
        if (a.categorySortOrder === b.categorySortOrder) {
          return 0;
        }

        return a.categorySortOrder < b.categorySortOrder ? -1 : 1;
      });

      return sorted.map((category) => {
        if (category.name) {
          const randomColors = [
            '#131447',
            '#CE47CE',
            '#2FBD3B',
            '#0995D2',
            '#262973',
            '#A975E9',
            '#DCED9B',
            '#F06494',
            '#2A892C',
            '#D10159',
            '#A80659',
            '#D33C8D',
            '#10B796',
            '#EEA1A1',
            '#860606',
            '#B384C2',
            '#E2CCF6',
            '#C2BF49',
            '#00C6D0',
            '#F15353',
            '#60D180',
            '#B7049E',
            '#3800C2',
            '#C28C27',
          ];

          category.categoryColor = category.categoryColor
            ? category.categoryColor
            : randomColors[Math.floor(Math.random() * randomColors.length)];
          return category;
        }

        return {
          ...category,
          name: this.$t('reports.sales.no_category'),
          categoryColor: '#e9eff1',
        };
      });
    },

    filteredCategories() {
      if (!this.filters.categories.length) {
        return this.categories;
      }

      return this.categories.filter((category) =>
        this.filters.categories.includes(category.categoryId),
      );
    },

    categoryColors() {
      return this.categories.map((category) => category.categoryColor);
    },

    categoryNames() {
      return this.categories.map((category) => category.name);
    },

    items() {
      return this.salesReport.data;
    },

    filteredItems() {
      const categoryIds = this.filteredCategories.map(
        (category) => category.categoryId,
      );
      return this.items.filter((item) => categoryIds.includes(item.categoryId));
    },

    rows() {
      const rows = [];
      this.filteredCategories.forEach((category) => {
        rows.push({
          ...category,
          isCategory: true,
        });

        this.items
          .filter((item) => item.categoryId === category.categoryId)
          .forEach((item) => {
            rows.push({
              ...item,
              isCategory: false,
            });
          });
      });

      return rows;
    },

    totals() {
      const total = (property) =>
        this.filteredItems.reduce((total, item) => total + item[property], 0);

      return {
        amountSold: total('amountSold'),
        percentageSold: total('percentageSold'),
        purchasePrice: total('purchasePrice'),
        revenueIncl: total('revenueIncl'),
        revenueExcl: total('revenueExcl'),
      };
    },

    chartData() {
      return {
        labels: this.filteredCategories.map((category) => category.name),
        datasets: [
          {
            backgroundColor: this.categoryColors,
            data: this.filteredCategories.map((category) => {
              let data = category[this.selectedDoughnutType];
              if (
                ['purchasePrice', 'revenueIncl', 'revenueExcl'].includes(
                  this.selectedDoughnutType,
                )
              ) {
                // Devide prices by 100 so data in the chart will be shown in euros instead of cents
                // Chartjs requires the data to be a number, so unfortunately we cannot use the currency filter to show the correct formatting
                data = data / 100;
              }
              return data;
            }),
          },
        ],
      };
    },

    hasData() {
      return !!this.rows.length;
    },

    colSize() {
      switch (this.$screen) {
        case 's':
          return 12;
        case 'm':
          return 6;
        default:
          return 3;
      }
    },

    exportUrl() {
      return `reports/${this.type.slice(0, -1)}_reports.extension?start=${this.filters.startDate.slice(0, 10)}&end=${this.filters.endDate.slice(0, 10)}&employee_id=${this.filters.employeeId || ''}`;
    },
  },

  methods: {
    normalizePercentage(value) {
      return value.toFixed(2).replace(/\.?0+$/, '');
    },

    onDateSelect({ startDate, endDate }) {
      this.filters.startDate = startDate;
      this.filters.endDate = endDate;
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: calc(100% + 48px);
  background: $grey-light;
}

.inner {
  width: 100%;
}

.noDataImage,
.noDataText {
  display: block;
  margin: $spacing auto;
}

.noDataText {
  text-align: center;
}

.transition {
  &:global(.v-enter-active),
  &:global(.v-leave-active) {
    transition: opacity 200ms ease-in-out;
  }

  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
  }
}

.flex {
  display: flex;

  .isSmall & {
    flex-direction: column;
  }

  .isMedium & {
    & > * {
      min-width: 50%;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing;
}

.headerLeft,
.headerRight {
  display: flex;
  align-items: flex-end;
}

.headerLeft {
  & > * {
    margin-right: $spacing;
  }
}

.headerRight {
  & > * {
    margin-left: $spacing;
  }
}
</style>
