import type { AllowedModalNames } from '@/app/logged-in/global-modals/index.vue';
import eventBus from '@/event-bus';

export const flash = (message: string) => {
  eventBus.$emit(
    'flashMessage',
    message.charAt(0).toUpperCase() + message.slice(1).toLowerCase(),
  );
};

const confirmationModals: AllowedModalNames[] = [
  'confirmation',
  'recurring-confirmation',
  'recurring-edit-options',
];

export const modal = (name: AllowedModalNames, options?: any) => {
  eventBus.$emit('open-modal', { name, options });

  if (confirmationModals.includes(name)) {
    return new Promise((resolve, reject) => {
      eventBus.$once('submit-confirmation-modal', (value: any) => {
        if (value) {
          setTimeout(() => {
            resolve(value);
          }, 0);
        } else if (options?.catch) {
          setTimeout(() => {
            reject(value);
          }, 0);
        }
      });
    });
  } else {
    return new Promise<void>((resolve) => resolve());
  }
};

interface Notification {
  name: string;
}

export const notification = (data: Notification) => {
  eventBus.$emit('notification', data);
};

export const showTooltip = (data: any, el: HTMLElement) => {
  if (!data || !el) {
    return;
  }

  const position = typeof data === 'object' ? data.position || 'top' : 'top';
  if (!['top', 'right', 'bottom', 'left'].includes(position)) {
    return;
  }

  const rect = el.getBoundingClientRect();
  let left =
    position === 'top' || position === 'bottom'
      ? Math.round(rect.left + rect.width / 2)
      : Math.round(rect.left);
  let top =
    position === 'left' || position === 'right'
      ? Math.round(rect.top + rect.height / 2)
      : Math.round(rect.top);
  const right =
    position === 'left' ? window.innerWidth - Math.round(rect.left) : null;

  if (position === 'bottom') {
    top += rect.height;
  }

  if (position === 'right') {
    left += rect.width;
  }

  const text = typeof data === 'object' ? data.text : data;

  const tooltip = {
    left,
    right,
    top,
    text,
    position,
    width: data.width || null,
  };

  eventBus.$emit('show-tooltip', tooltip);
};

export const hideTooltip = () => {
  eventBus.$emit('hide-tooltip');
};
