<template>
  <div :class="$style.base">
    <Button
      @click="
        () => {
          isActive = true;
          mixpanel.track('search_icon_clicked');
        }
      "
      v-test="'search-button'"
    />
    <Teleport to="#search">
      <transition>
        <div v-if="isActive" :class="$style.main">
          <div ref="container" :class="$style.container">
            <BaseSearch
              v-model="query"
              debounce
              focus
              :placeholder="$t('search.input_placeholder')"
              :mb="0.5"
              v-test="'search-input'"
            />
            <div @mousemove="selectedIndex = -1">
              <Results
                v-if="query"
                @updateHistory="history = $event"
                @select="onSelect"
                @selectIndex="onSelectIndex"
              />
              <Default v-else @select="onSelect" @selectIndex="onSelectIndex" />
            </div>
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Search',
};
</script>

<script setup lang="ts">
import Button from './Button.vue';
import { onClickOutside, useStorage } from '@vueuse/core';
import Results from './Results.vue';
import Default from './Default.vue';
import { useI18n } from 'vue-i18n';
import { filterRoutes } from './helpers';
import { useCompanyStore } from '@/stores/company';
import { usePageLayoutStore } from '@/stores/page-layout';
import { storeToRefs } from 'pinia';

const mixpanel = inject<any>('mixpanel');

const isActive = ref(false);
const query = ref('');
provide('query', query);

const container = ref();
onClickOutside(container, () => {
  isActive.value = false;
});

watch(isActive, (value) => {
  if (!value) {
    query.value = '';
    selectedIndex.value = -1;
  }
});

watch(query, () => {
  selectedIndex.value = -1;
});

const { companyId } = useCompanyStore();

const history = useStorage(`main_search_history_${companyId}`, []);
provide('history', history);

const oldHistory = useStorage('main_search_history', []);
if (oldHistory.value.length) {
  history.value = oldHistory.value;
  oldHistory.value = [];
}

const { t } = useI18n();

const popularPages = ref(
  filterRoutes([
    {
      name: t('search.popular_pages.services'),
      path: 'admin/services'
    },
    {
      name: t('search.popular_pages.online_bookings'),
      path: 'admin/online-bookings'
    },
    {
      name: t('search.popular_pages.roster'),
      path: 'admin/roster'
    },
    {
      name: t('search.popular_pages.billing'),
      path: 'admin/billing'
    },
    {
      name: t('search.popular_pages.calendar_settings'),
      path: 'admin/calendar'
    },
    {
      name: `${t('nav.calendar')} > ${t('global.actions.create_appointment')}`,
      path: 'calendar/create-event/appointment'
    },
    {
      name: t('nav.register'),
      path: 'register/checkout'
    },
    {
      name: `${t('nav.messages')} > ${t('messages.nav.email_templates')}`,
      path: 'messages/emails'
    },
    {
      name: `${t('nav.calendar')} > ${t('global.actions.create_note')}`,
      path: 'calendar/create-event/note'
    },
    {
      name: t('nav.reports'),
      path: 'reports/totals'
    }
  ]).slice(0, 3)
);

provide('popularPages', popularPages);

const selectedIndex = ref(-1);
provide('selectedIndex', selectedIndex);

const { showMobileMenu } = storeToRefs(usePageLayoutStore());

const onSelect = () => {
  isActive.value = false;
  showMobileMenu.value = false;
};

const onSelectIndex = (newIndex) => {
  selectedIndex.value = newIndex;
};

const onKeyPress = (e) => {
  if (e.key === 'Escape') {
    isActive.value = false;
  } else if (e.metaKey && e.key === 'k') {
    isActive.value = true;
    mixpanel.track('search_shortcut_used');
  }
};

window.addEventListener('keydown', onKeyPress);

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyPress);
});
</script>

<style lang="scss" module>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: $header-height $spacing $spacing;
  overflow-y: auto;

  &:global(.v-enter-from) {
    opacity: 0;

    .container {
      transform: scale(0.9);
    }
  }

  &:global(.v-enter-active) {
    transition: opacity 0.15s;

    .container {
      transition: transform 0.15s $easeOutBack;
    }
  }
}

.container {
  width: 500px;
  max-width: 100%;
  border-radius: $radius;
  background-color: white;
  padding: $spacing * 0.5;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
</style>
