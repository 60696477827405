<template>
  <transition appear>
    <div
      v-if="isActive"
      v-show="!isDismissed"
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
      :style="
        $screen !== 's'
          ? {
              width: `calc(100% - ${navbarWidth + intercomSpacing}px)`,
              left: navbarWidth + 'px'
            }
          : {}
      "
      v-test="`banner-${data.name}`"
    >
      <NpsBanner v-if="data.name === 'nps'" @close="isDismissed = true" />
      <AppBanner
        v-else-if="data.name === 'pwa'"
        @close="isDismissed = true"
        v-test="'app-banner'"
      />
      <div v-else :class="$style.inner">
        <div :class="$style.message">
          <BaseIcon :name="data.icon || 'alert'" :mr="0.5" color="warning" />
          <div>
            <BaseText v-if="data.title" v-test="'banner-title'">
              {{ data.title }}
            </BaseText>
            <BaseText v-test="'banner-message'">
              {{ data.message }}
            </BaseText>
          </div>
        </div>
        <div :class="$style.buttons">
          <BaseText
            v-if="data.dismissable"
            color="warning"
            link
            @click="dismiss"
            v-test="'banner-dismiss'"
          >
            {{
              data.remindLater
                ? $t('banner.remind_later')
                : $t('banner.dismiss')
            }}
          </BaseText>
          <BaseText
            v-if="data.buttonText && data.name === 'mismatch'"
            color="warning"
            link
            @click="$emit('buttonClick')"
            v-test="'banner-submit'"
          >
            {{ data.buttonText }}
          </BaseText>
          <BaseButton
            v-else-if="data.buttonText"
            color="warning"
            @click="$emit('buttonClick')"
            v-test="'banner-submit'"
          >
            {{ data.buttonText }}
          </BaseButton>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import AppBanner from './AppBanner.vue';
import NpsBanner from './NpsBanner.vue';
import { mapState } from 'pinia';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useStorage } from '@vueuse/core';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    AppBanner,
    NpsBanner,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['buttonClick', 'dismissClick'],
  setup(props) {
    const intercomSpacing = 70;

    return {
      intercomSpacing,
      dismissedAt: useStorage(`${props.data.name}_banner_dismissed_at`, null),
    };
  },
  data() {
    return {
      isDismissed: false,
      dismissInterval: 0,
    };
  },
  computed: {
    ...mapState(usePageLayoutStore, ['navbarWidth']),
  },
  methods: {
    dismiss() {
      this.isDismissed = true;
      if (this.data.customHandleDismiss) {
        this.$emit('dismissClick');
      } else {
        this.dismissedAt = dayjs().format();
        if (this.data.remindLater) {
          this.checkDismissedStatus();
        }
      }
    },
    checkDismissedStatus() {
      this.dismissInterval = setInterval(
        () => {
          if (dayjs().diff(this.dismissedAt, 'day') >= 1) {
            this.isDismissed = false;
            clearInterval(this.dismissInterval);
            this.dismissedAt = null;
          }
        },
        1000 * 60 * 5,
      );
    },
  },
  created() {
    if (this.data.dismissable) {
      if (this.dismissedAt && dayjs(this.dismissedAt).isValid()) {
        this.isDismissed = true;
        this.checkDismissedStatus();
      }
    }
  },
  beforeUnmount() {
    if (this.dismissInterval) {
      clearInterval(this.dismissInterval);
    }
  },
});
</script>

<style lang="scss" module>
.base {
  position: absolute;
  right: 0;
  width: 100%;
  bottom: 0;

  &:not(.smallScreen) {
    padding: $spacing;
  }

  &.smallScreen {
    padding: $spacing * 0.5;
  }

  &:global(.v-enter-active),
  &:global(.v-leave-active) {
    transition:
      opacity 0.05s linear,
      transform 0.1s $easeOutBack;
  }
  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
  }
  &:global(.v-enter-from) {
    transform: scale(0.95);
  }
}

.inner {
  padding: $spacing;
  background-color: white;
  border-radius: $radius;
  box-shadow: $shadow;

  .base:not(.smallScreen) & {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.message {
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: $spacing;
  flex-shrink: 0;

  & > *:not(:first-child) {
    margin-left: $spacing;
  }

  .base.smallScreen & {
    justify-content: flex-end;
    margin-top: $spacing;
  }
}
</style>
