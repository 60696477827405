<template>
  <div :class="$style.base">
    <BaseText bold>
      {{ $t('global.footer') }}
    </BaseText>
    <BaseToggle v-model="hasFooter" v-test="'toggleFooter'" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    hasFooter: {
      get() {
        return this.$store.getters['marketing/hasFooter'];
      },
      set() {
        this.$store.commit('marketing/TOGGLE_FOOTER');
      },
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $color-border;
  padding-top: $spacing;
  margin-top: $spacing;
}
</style>
