<template>
  <div
    :class="[
      $style.base,
      {
        [$style.disabled]: isDisabled,
        [$style.inverted]: inverted,
        [$style.padding1]: padding === 1,
        [$style.padding15]: padding === 1.5
      }
    ]"
  >
    <div
      :class="$style.left"
      :style="
        isDisabled
          ? {}
          : {
              width: width + '%'
            }
      "
    >
      <slot name="left" />
    </div>
    <div
      :class="$style.right"
      :style="
        isDisabled
          ? {}
          : {
              width: 100 - width + '%'
            }
      "
    >
      <slot name="right" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageLayoutStore } from '@/stores/page-layout';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    inverted?: boolean;
    padding?: 0 | 1 | 1.5;
    width?: number;
  }>(),
  {
    disabled: false,
    inverted: false,
    padding: 1,
    width: 50,
  },
);

const isDisabled = computed(() => {
  const { screenSize } = usePageLayoutStore();
  return props.disabled || screenSize === 's';
});
</script>

<style lang="scss" module>
.base {
  &:not(.disabled) {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
}

.left,
.right {
  .base:not(.disabled) & {
    width: 50%;
    overflow-y: auto;
  }

  .base.padding1 & {
    padding: $spacing;
  }

  .base.padding15 & {
    padding: $spacing * 1.5;
  }
}

.left {
  .base:not(.disabled) & {
    background-color: white;
  }

  .base.inverted & {
    background-color: $color-body-bg;
  }

  .base:not(.inverted) & {
    background-color: white;
  }
}

.right {
  .base:not(.disabled) & {
    border-left: 1px solid $color-border;
  }

  .base.inverted & {
    background-color: white;
  }

  .base:not(.inverted) & {
    background-color: $color-body-bg;
  }
}
</style>
