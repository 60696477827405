<template>
  <div :class="$style.base">
    <div :class="$style.inner">
      <BaseGrid container mb :class="$style.nonPrintable">
        <BaseGrid>
          <DateManipulator year @change="onYearSelect" />
        </BaseGrid>
        <BaseGrid alignCenter alignRight>
          <BaseButton @click="onPrint" v-test="'printButton'">
            {{ $t('global.print') }}
          </BaseButton>
        </BaseGrid>
      </BaseGrid>

      <BaseCard :loading="$apollo.loading" mb>
        <MonthsTable
          :title="$t('reports.vat_overview.turnover_month_table')"
          :noVat="$t('reports.vat_overview.vat_exempt')"
          attribute="monthlyTurnover"
          :rows="vatReport.percentages"
          :totals="vatReport.monthlyTurnoverTotals"
          v-test="'monthlyTurnover'"
        />
      </BaseCard>

      <BaseCard :loading="$apollo.loading" mb>
        <MonthsTable
          :title="$t('reports.vat_overview.vat_month_table')"
          :noVat="$t('reports.vat_overview.no_vat')"
          attribute="monthlyVat"
          :rows="vatReport.percentages"
          :totals="vatReport.monthlyVatTotals"
          v-test="'monthlyVat'"
        />
      </BaseCard>

      <BaseGrid container mb>
        <BaseGrid :size="$screen === 'l' ? 6 : 12">
          <BaseCard :loading="$apollo.loading">
            <QuartersTable
              :title="$t('reports.vat_overview.quarterly_turnover_table')"
              :noVat="$t('reports.vat_overview.vat_exempt')"
              attribute="quarterlyTurnover"
              :rows="vatReport.percentages"
              :totals="vatReport.quarterlyTurnoverTotals"
              v-test="'quarterlyTurnover'"
            />
          </BaseCard>
        </BaseGrid>

        <BaseGrid :size="$screen === 'l' ? 6 : 12">
          <BaseCard :loading="$apollo.loading">
            <QuartersTable
              :title="$t('reports.vat_overview.quarterly_vat_table')"
              :noVat="$t('reports.vat_overview.no_vat')"
              attribute="quarterlyVat"
              :rows="vatReport.percentages"
              :totals="vatReport.quarterlyVatTotals"
              v-test="'quarterlyVat'"
            />
          </BaseCard>
        </BaseGrid>
      </BaseGrid>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import gql from 'graphql-tag';

import DateManipulator from '../components/DateManipulator.vue';
import MonthsTable from './MonthsTable.vue';
import QuartersTable from './QuartersTable.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VatReport',

  components: {
    DateManipulator,
    MonthsTable,
    QuartersTable,
  },
  data() {
    return {
      filters: {
        startDate: dayjs(),
      },

      vatReport: {
        year: null,
        monthlyVatTotals: [],
        monthlyTurnoverTotals: [],
        quarterlyVatTotals: [],
        quarterlyTurnoverTotals: [],
        percentages: [],
      },
    };
  },

  computed: {
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),

    currentYear() {
      return dayjs().year();
    },

    disableYearButton() {
      return this.currentYear === this.filters.year;
    },
  },

  methods: {
    onYearSelect(date) {
      this.filters.startDate = date;
    },

    onPrint() {
      window.print();
    },
  },

  apollo: {
    vatReport: {
      query: gql`
        query vatReport(
          $startDate: DateTime
          $locationId: ID
          $dataScope: DataScope
        ) {
          vatReport(
            startDate: $startDate
            locationId: $locationId
            dataScope: $dataScope
          ) {
            year
            monthlyVatTotals
            monthlyTurnoverTotals
            quarterlyTurnoverTotals
            quarterlyVatTotals
            percentages {
              monthlyVat
              monthlyTurnover
              percentage
              quarterlyTurnover
              quarterlyVat
            }
          }
        }
      `,
      variables() {
        return {
          startDate: this.filters.startDate.format(),
          locationId: this.locationId,
          dataScope: this.dataScope,
        };
      },
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: calc(100% + 48px);
  background: $grey-light;
}

.inner {
  width: 100%;
}

.buttons {
  display: flex;
}

@media print {
  .nonPrintable {
    display: none;
  }
}
</style>
