<template>
  <div
    :class="[
      $style.base,
      {
        [$style.completed]: completed,
        [$style.required]: state === 'required',
        [$style.attention]: state === 'attention',
        [$style.blocked]: state === 'blocked',
        [$style.clickable]: clickable
      }
    ]"
    @click="clickable ? $emit('click') : null"
  >
    <div :class="$style.content" v-test="`_task-state-${state}`">
      <div v-if="label" :class="$style.label">
        <BaseLabel :color="label.color">
          {{ label.text }}
        </BaseLabel>
      </div>
      <div :class="$style.main">
        <BaseIcon
          v-if="state === 'pending'"
          name="clock"
          color="primary"
          :mr="0.5"
        />
        <BaseCheck
          v-else
          :color="checkColor"
          :checked="completed"
          :mr="0.5"
          v-test="completed ? '_task-check-checked' : '_task-check-unchecked'"
        />
        <div>
          <BaseText v-if="heading" bold>
            {{ heading }}
          </BaseText>
          <BaseText>
            {{ text }}
          </BaseText>
        </div>
      </div>
    </div>
    <div v-if="showVisual" :class="$style.visual">
      <img :src="visual" :class="$style.visualImage" />
    </div>
    <div v-else-if="buttonText || buttonIcon" :class="$style.buttonIcon">
      <BaseButton
        color="inverted"
        :loading="loading"
        :icon="buttonIcon"
        @click="$emit('buttonClick')"
        v-test="'_base-task-button'"
      >
        {{ buttonIcon ? '' : buttonText }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import { computed } from 'vue';
import type { IconName } from '@/components/base-icon/types';
import { useI18n } from 'vue-i18n';

const props = withDefaults(
  defineProps<{
    buttonIcon?: IconName;
    buttonText?: string;
    heading?: string;
    loading?: boolean;
    state?:
      | 'default'
      | 'required'
      | 'attention'
      | 'completed'
      | 'pending'
      | 'blocked';
    text: string;
    visual?: string;
  }>(),
  {
    loading: false,
    state: 'default'
  }
);

defineEmits(['click', 'buttonClick']);

const completed = computed(() => props.state === 'completed');
const showVisual = computed(
  () => !!props.visual && !completed.value && props.state !== 'blocked'
);
const clickable = computed(
  () => !['completed', 'pending', 'blocked'].includes(props.state)
);

const checkColor = computed(() => {
  switch (props.state) {
    case 'attention':
      return 'warning';
    case 'blocked':
      return 'default';
    default:
      return 'primary';
  }
});

const { t } = useI18n();

const label = computed<{
  color: string;
  text: string;
} | null>(() => {
  switch (props.state) {
    case 'attention':
      return {
        color: 'warning',
        text: t('global.attention')
      };
    case 'required':
      return {
        color: 'primary',
        text: t('global.required')
      };
    default:
      return null;
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing $spacing * 0.5;
  border-radius: $radius;
  background-color: white;
  border: 1px solid $color-border;

  &.clickable {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.1s;
    cursor: pointer;

    @include hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
    }
  }

  &.required {
    border-color: $color-primary;
  }

  &.attention {
    border-color: $color-warning;
  }

  &.completed {
    border-color: $color-border;
  }
}

.content {
  .base.blocked & {
    opacity: 0.5;
  }
}

.label {
  padding-left: 2px;
  flex-shrink: 0;
  margin-bottom: 5px;
}

.main {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  padding: $spacing * 0.5;
}

.visual {
  position: relative;
  width: 80px;
  height: 70px;
  margin: $spacing * -0.5 0 $spacing * -0.5 $spacing;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 10px);
    height: 100%;
    background-color: #f5f6fa;
    border-radius: 8px;
  }
}

.visualImage {
  position: relative;
  max-width: 100%;
  z-index: 1;
}

.buttonIcon {
  padding-left: $spacing * 0.5;
}
</style>
