<template>
  <div>
    <BaseGroupedCheckboxList
      v-if="listData.length"
      v-model="listValue"
      :data="listData"
      :label="$t('resource.all_treatments')"
      :fixedValue="fixedValue"
      expandedHeader
      gray
    />
  </div>
</template>

<script lang="ts">
import { useServicesStore } from '@/stores/services';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResourceServices',
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    disableDeselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    return {
      fixedValue: props.disableDeselect ? props.modelValue : null,
    };
  },
  computed: {
    listValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    listData() {
      const { categoriesWithServices } = useServicesStore();
      const _categoriesWithServices = categoriesWithServices.filter(
        (category) => category.services.length,
      );
      return _categoriesWithServices.length
        ? _categoriesWithServices.map((category) => ({
            label: category.name,
            color: category.color,
            items: category.services.map((service) => ({
              label: service.name,
              id: service.id,
            })),
          }))
        : [];
    },
  },
});
</script>
