<template>
  <BaseModal
    :heading="
      outstandingAmount > 0
        ? t('register.saved_as_outstanding')
        : t('register.saved_as_paid')
    "
    testId="register-overview"
    noPadding
    @close="close"
  >
    <PageSplit>
      <template #left>
        <div v-if="customer" v-test="'register-overview-customer'">
          <div class="mb-1">
            <BaseText
              link
              bold
              iconBefore="person"
              :routerLink="{
                name: 'customer',
                params: { customerId: customer.id }
              }"
              v-test="'register-overview-view-customer'"
            >
              {{ customer.fullName }}
            </BaseText>
          </div>
          <div :class="$style.customerDetails">
            <BaseText
              v-if="customer.mobilePhone"
              :href="`tel:${customer.mobilePhone}`"
              v-test="'register-overview-mobile'"
            >
              {{ customer.mobilePhone }}
            </BaseText>
            <div v-if="customer.phone" v-test="'register-overview-phone'">
              <BaseText :href="`tel:${customer.phone}`">
                {{ customer.phone }}
              </BaseText>
            </div>
          </div>
          <BaseAlert
            v-if="isLoyaltyPointsEnabled && customer.loyaltyPoints"
            :text="
              $t(
                'register.loyalty_points',
                {
                  customer: customer.firstName,
                  amount: customer.loyaltyPoints
                },
                customer.loyaltyPoints
              )
            "
            mb
            v-test="'register-overview-loyalty'"
          />
        </div>
        <div v-else v-test="'register-overview-no-customer'">
          <BaseText mb iconBefore="person">
            {{ $t('register.no_customer') }}
          </BaseText>
        </div>
        <BaseInput
          v-model="email"
          :label="$t('global.email_address')"
          type="email"
          mb
          v-test="'register-overview-email'"
        />
        <Flex wrap>
          <BaseButton
            :disabled="emailInvalid || emailSent"
            color="inverted"
            :loading="sendingEmail"
            @click="sendInvoice"
            v-test="'register-overview-send-email'"
          >
            {{
              emailSent
                ? $t('register.email_sent')
                : $t('register.send_digital_invoice')
            }}
          </BaseButton>
          <BaseButton
            v-if="hasFeatureFlag('module-appointments')"
            color="inverted"
            @click="bookNextAppointment"
            v-test="'register-overview-next-appointment'"
          >
            {{ $t('register.book_next_appointment') }}
          </BaseButton>
        </Flex>
        <BaseAlert
          v-if="delayPrinting"
          :text="$t('register.print_warning')"
          mt
          icon="printer"
          color="warning"
          v-test="'register-overview-delayed-printing'"
        />
      </template>
      <template #right>
        <BaseCard mb>
          <Flex justify="between">
            <Flex align="center">
              <BaseText bold>
                {{ order.number }}
              </BaseText>
              <BaseLabel v-if="outstandingAmount > 0" state="outstanding" />
            </Flex>
            <BaseText
              v-if="showPdf"
              :href="pdfUrl"
              v-test="'register-overview-pdf'"
            >
              {{ $t('global.actions.pdf') }}
            </BaseText>
          </Flex>
        </BaseCard>
        <InvoicePreview />
      </template>
    </PageSplit>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="close"
        v-test="'register-overview-close'"
      >
        {{ $t('global.actions.close') }}
      </BaseButton>
      <BaseButton
        :routerLink="{
          name: 'calendar'
        }"
        v-test="'register-overview-calendar'"
      >
        {{ $t('global.actions.go_to_calendar') }}
      </BaseButton>
    </template>
    <PrintUrl v-if="promptPrint" :url="printUrl" />
  </BaseModal>
</template>

<script setup lang="ts">
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { ref, computed, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { MessageTypeEnum } from '@/types';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import config from '@/config';
import { Action, usePreCreateStore } from '@/stores/calendar-pre-create';
import { useRouter } from 'vue-router';
import { CREATE_MESSAGE } from './graphql';
import { useMutation } from '@vue/apollo-composable';
import PrintUrl from '@/components/PrintUrl.vue';
import { useFiscalizationStore } from '@/modules/register/stores/fiscalization';
import PageSplit from '@/modules/PageSplit.vue';
import InvoicePreview from './invoice-preview/index.vue';

const mixpanel = inject<any>('mixpanel');

const { companySettings, isLoyaltyPointsEnabled } = useCompanyStore();
const { hasFeatureFlag } = useUserStore();
const { reset } = useRegisterOrderStore();
const {
  order,
  selectedCustomer: customer,
  outstandingAmount,
} = useRegisterOrderStore();
const { t } = useI18n();
const router = useRouter();
const { setState } = usePreCreateStore();
const { addOrderToQueue } = useFiscalizationStore();

const close = () => router.push({ name: 'register' });

const email = ref(customer?.email || '');
const emailInvalid = computed(() => !/.+@.+\..+/.test(email.value));
const emailSent = ref(false);

const promptPrint = ref(false);
const printingEnabled = computed(() => companySettings.sales.thermalSupport);
const fiscalizationEnabled = computed(
  () =>
    !!companySettings.fiscalization.tssId ||
    hasFeatureFlag('fiskaly-v2-enabled'),
);
const showPdf = computed(
  () => !fiscalizationEnabled.value || order.fiscalizationFinished,
);
const delayPrinting = computed(
  () => printingEnabled.value && fiscalizationEnabled.value,
);
const printUrl = computed(() => `${config.backendUrl}/orders/${order.id}`);
const pdfUrl = computed(() => `${printUrl.value}.pdf`);

const { mutate: sendEmail, loading: sendingEmail } =
  useMutation(CREATE_MESSAGE);

const bookNextAppointment = () => {
  if (customer) {
    setState({ action: Action.Create, customer });
  }
  router.push({ name: 'calendar' });
  mixpanel.track('Register - Book next appointment clicked', {
    customer: !!customer,
  });
};

const sendInvoice = () => {
  sendEmail({
    input: {
      destination: email.value,
      messageType: MessageTypeEnum.OrderInvoice,
      orderId: order.id,
    },
  }).then(({ data: { createMessage } }: any) => {
    if (!createMessage.errors) {
      emailSent.value = true;
    }
  });
};

onUnmounted(() => reset());

const listenToOrderFiscalization = () => {
  if (order.id) {
    addOrderToQueue(order.id);
  }
};

if (delayPrinting.value) {
  listenToOrderFiscalization();
} else if (printingEnabled.value) {
  promptPrint.value = true;
}
</script>

<style lang="scss" module>
.customerDetails {
  margin-bottom: $spacing;

  &:empty {
    display: none;
  }
}
</style>
