<template>
  <div>
    <BaseRadio
      v-model="selectedValue"
      :options="filters"
      mb
      @update:modelValue="emitValue"
    />
    <div v-if="selectedValue === 'created_at'" v-test="'filterDates'">
      <BaseDatePicker
        v-model="dateFrom"
        :label="$t('customers.filters.from')"
        mb
        @update:modelValue="emitValue"
      />
      <BaseDatePicker
        v-model="dateTo"
        :label="$t('customers.filters.to')"
        @update:modelValue="emitValue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      selectedValue: 'best',
      dateFrom: dayjs().format(),
      dateTo: dayjs().format(),
      filters: [
        {
          label: this.$t('customers.filters.best'),
          value: 'best',
        },
        {
          label: this.$t('customers.filters.debtors'),
          value: 'debtors',
        },
        {
          label: this.$t('customers.filters.blocked'),
          value: 'blocked',
        },
        {
          label: this.$t('customers.filters.deleted'),
          value: 'deleted',
        },
        {
          label: this.$t('customers.filters.created_at'),
          value: 'created_at',
        },
      ],
    };
  },
  methods: {
    emitValue() {
      let values;
      const dates = {
        from: this.dateFrom,
        to: this.dateTo,
      };

      this.selectedValue === 'created_at' ? (values = dates) : (values = null);

      const obj = {
        filter_name: this.selectedValue,
        values,
      };

      this.$emit('selected', obj);
    },
  },
  created() {
    this.emitValue();
  },
});
</script>
