<template>
  <div>
    <EmptyResults v-if="items.length < 1 && !loading" />

    <BrowserItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      showFavorite
      indented
      @click="onSelect(item.id)"
      @refetch="refetch"
    />
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>

<script setup lang="ts">
import EmptyResults from '../../EmptyResults.vue';
import BrowserItem from '../../BrowserItem.vue';
import { usePagination } from '@/apollo/pagination';
import { GET_PRODUCTS } from '../../graphql';
import LoadMore from '@/components/LoadMore.vue';
import { computed } from 'vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';

const props = defineProps<{
  categoryId: number | null;
}>();

const { products, allDataFetched, fetchMore, loading, refetch } = usePagination(
  {
    fieldName: 'products',
    query: GET_PRODUCTS,
    variables: {
      categoryIds: [props.categoryId],
    },
  },
);

const items = computed(() =>
  products.value.map((product: any) => ({
    label: product.name,
    id: product.id,
    type: product.type,
    favorite: product.favorite,
    price: product.price,
  })),
);

const onSelect = (id: number) => {
  const product = products.value.find((product: any) => product.id === id);
  if (product) {
    const { addItem } = useRegisterOrderStore();

    addItem({
      name: product.name,
      price: product.price,
      productId: product.id,
    });
  }
};
</script>
