<template>
  <BaseModalSmall testId="warning" @close="close">
    <BaseText size="l">
      {{ data.message }}
    </BaseText>
    <template #center>
      <BaseText v-if="data.subMessage">
        {{ data.subMessage }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton color="inverted" @click="close">{{
        $t('global.actions.close')
      }}</BaseButton>
    </template>
  </BaseModalSmall>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
</script>
