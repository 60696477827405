<template>
  <BaseCard :heading="$t('dashboard.utilisation.heading')">
    <div :class="$style.inner">
      <div :class="$style.progress">
        <BaseProgress
          :percentage="
            Math.round(
              (utilizationToday.occupied /
                (utilizationToday.timePresent || 1)) *
                100
            )
          "
          :label="$t('dashboard.utilisation.today')"
          v-test="'utilisation-today'"
        />
      </div>
      <div :class="$style.progress">
        <BaseProgress
          :percentage="
            Math.round(
              (utilizationSevenDays.occupied /
                (utilizationSevenDays.timePresent || 1)) *
                100
            )
          "
          :label="$t('dashboard.utilisation.week')"
          v-test="'utilisation-week'"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    utilizationToday: {
      type: Object,
      required: true,
    },
    utilizationSevenDays: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss" module>
.inner {
  display: flex;
  justify-content: space-between;
  padding: 0 12%;
}

.progress {
  width: 45%;
}
</style>
