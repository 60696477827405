<template>
  <Main v-if="activeList && renderComponent" :listName="activeList.name" />
</template>

<script lang="ts">
export default {
  name: 'TrialTasks',
};
</script>

<script setup lang="ts">
import Main from './Main.vue';
import { ref, nextTick } from 'vue';
import { useCompanyStore } from '@/stores/company';
import { useTreatwellStore } from '@/stores/treatwell';
import { useI18n } from 'vue-i18n';

import { ListName } from './types';
import dayjs from '@/dayjs';

const { showOnboarding, company, isSubscribed } = useCompanyStore();
const { treatwellStatus, showTreatwell, hasActiveVenue } = useTreatwellStore();

const lists: {
  name: ListName;
  show: boolean;
}[] = [
  {
    name: ListName.Onboarding,
    show: showOnboarding
  },
  {
    name: ListName.Treatwell,
    show: showTreatwell && treatwellStatus.optOutCampaign && !!hasActiveVenue
  },
  {
    name: ListName.Marketing,
    show:
      isSubscribed &&
      dayjs(company.marketingTrialEndsAt).diff(dayjs(), 'day') >= -14 &&
      company.activeSubscription?.plan !== 'pro'
  }
];

const activeList = ref(lists.find((list) => list.show));

const { locale } = useI18n();

const renderComponent = ref(true);
const forceRender = async () => {
  renderComponent.value = false;
  await nextTick();
  renderComponent.value = true;
};

watch(locale, () => {
  // When the locale changes, re-render the component. This is needed because translations used in the data are not reactive.
  forceRender();
});
</script>
