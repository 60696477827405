<template>
  <div :class="$style.base" v-test="'app-scrollcontainer'">
    <BaseSpinner v-if="loading" />
    <div v-else-if="newCompany" :class="$style.content">
      <img src="/img/empty-states/history.svg" :class="$style.visual" />
      <BaseHeading icon="alert" size="xl"> Company ID mismatch </BaseHeading>
      <BaseText>
        You are using a link with a different company id than the one you are
        signed in with.
      </BaseText>
      <div :class="$style.cards">
        <BaseCard v-test="'current-company'">
          <BaseLabel color="warning" mb> Logged in </BaseLabel>
          <BaseHeading :mb="0.5">
            {{ company.name }}
          </BaseHeading>
          <div>Country: {{ company.country }}</div>
          <div>Language: {{ company.localeCode }}</div>
        </BaseCard>
        <BaseIcon name="arrow-right" />
        <BaseCard v-test="'new-company'">
          <BaseLabel color="success" mb> Visiting </BaseLabel>
          <BaseHeading :mb="0.5">
            {{ newCompany.name }}
          </BaseHeading>
          <div>Country: {{ newCompany.country }}</div>
          <div>Language: {{ newCompany.localeCode }}</div>
        </BaseCard>
      </div>
      <BaseHeading>
        {{
          sameFranchise
            ? 'Do you want to log in as the visited company?'
            : 'Do you want to impersonate the visited company?'
        }}
      </BaseHeading>
      <BaseButton :loading="submitting" @click="useNew" v-test="'impersonate'">
        {{ sameFranchise ? 'Log in as' : 'Impersonate' }} {{ newCompany.name }}
      </BaseButton>
      <BaseText link @click="redirect" v-test="'continue'">
        Continue with current account
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { redirectRoute } from '@/router/helpers';
import { useRouter } from 'vue-router';
import { useSessionStore } from '@/stores/session';
import { useUserStore } from '@/stores/user';

const { company } = useCompanyStore();
const { user } = useUserStore();

const router = useRouter();
const redirect = () => {
  router.push(redirectRoute());
};

const { initialRoute } = useSessionStore();
const newCompanyId = initialRoute?.params?.companyId;
if (!newCompanyId || !user?.salonizedAdmin) {
  redirect();
}

const { result, loading, onError } = useQuery(
  gql`
    query getCompany($id: ID!) {
      company(id: $id) {
        id
        name
        country
        localeCode
        franchiseId
      }
    }
  `,
  {
    id: newCompanyId,
  },
);

const newCompany = computed(() => result.value?.company);

onError(() => {
  redirect();
});

const sameFranchise = computed(
  () =>
    company.franchiseId && company.franchiseId === newCompany.value.franchiseId,
);

const submitting = ref(false);
const useNew = () => {
  const { mutate } = useMutation(gql`
    mutation become($input: BecomeInput!) {
      become(input: $input) {
        status
      }
    }
  `);

  submitting.value = true;

  mutate({
    input: {
      companyId: newCompanyId,
    },
  }).then(() => {
    const { fetchAllData } = useSessionStore();
    fetchAllData().then(() => {
      redirect();
    });
  });
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: $spacing;
  overflow-y: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing;
}

.visual {
  max-width: 200px;
  margin-bottom: $spacing;
}

.cards {
  display: flex;
  align-items: center;
  gap: $spacing;
  margin: $spacing 0;
  text-align: left;
}
</style>
