<template>
  <div :class="$style.base">
    <BaseAvatar :resource="resource" size="xl" mr />
    <BaseUpload
      :hasImage="!!resource.picture"
      withDelete
      preset="resource-picture"
      :text="$t('resource.change_photo')"
      @uploadResults="$emit('setPicture', $event)"
      @delete="$emit('setPicture', '')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    resource: {
      type: Object,
    },
  },
  emits: ['setPicture'],
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
}
</style>
