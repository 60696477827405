<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: smallScreen
      }
    ]"
  >
    <div :class="$style.formFieldsWrap">
      <div :class="$style.formSection">
        <div v-if="hasDeletedService" v-test="'part-service-disabled'">
          <div>
            <BaseInputLabel :text="$t('global.items.service', 1)" />
          </div>
          <div :class="$style.disabledService">
            {{ selectedService.name }}
          </div>
        </div>
        <BaseDropdown
          v-else-if="services.length"
          v-model="part.serviceId"
          :options="
            services
              .filter((service) => !service.deleted)
              .map((service) => ({
                value: service.id,
                label: service.name,
                labelExtra: filters.currency(service.price),
                groupValue: service.category.id
              }))
          "
          :warning="
            unavailableService
              ? {
                  icon: 'calendar',
                  color: 'warning',
                  tooltip:
                    unavailableService.reason === 'MAX'
                      ? $t('service.availability.calendar_tooltip_max')
                      : $t('service.availability.calendar_tooltip_times')
                }
              : null
          "
          :groups="
            serviceCategories.map((category) => ({
              value: category.id,
              label: category.name
            }))
          "
          :label="$t('global.items.service', 1)"
          searchable
          searchAutoFocus
          breakLines
          required
          :disabled="hasDeletedService || onlyResource"
          @update:modelValue="setPartData"
          v-test="'part-service'"
        />
        <BaseSpinner v-else line />
      </div>
      <div
        v-if="selectedService && selectedService.requiresProcessingTime"
        :class="$style.formSection"
      >
        <BaseInputLabel
          :text="$t('global.duration')"
          :info="$t('appointment.create.processing_time_hint')"
        />
        <div :class="$style.multipleDurationsWrap">
          <div>
            <BaseInput
              v-model="part.durationSetup"
              type="number"
              unitLabel="minute"
              :minValue="0"
              :disabled="onlyResource"
            />
          </div>
          <div>
            <BaseInput
              v-model="part.durationProcessing"
              type="number"
              unitLabel="minute"
              :minValue="0"
              :disabled="onlyResource"
            />
          </div>
          <div>
            <BaseInput
              v-model="part.durationFinish"
              type="number"
              unitLabel="minute"
              :minValue="0"
              :disabled="onlyResource"
            />
          </div>
        </div>
      </div>
      <div v-else :class="[$style.formSection, $style.isSmall]">
        <BaseInput
          v-model="part.duration"
          :label="$t('global.duration')"
          type="number"
          unitLabel="minute"
          :minValue="0"
          :disabled="onlyResource"
          v-test="'part-duration'"
        />
      </div>
      <div
        v-for="(allocation, index) in part.allocationsAttributes"
        :key="`resource-${index}`"
        :class="$style.formSection"
      >
        <DisabledResource
          v-if="hasDeletedService && !hasSingleResource"
          :allocation="allocation"
          v-test="'part-resource-disabled'"
        />
        <ResourceSelect
          v-else-if="!hasSingleResource"
          :allocation="allocation"
          :partIndex="partIndex"
          :allocationIndex="index"
          :disabled="hasDeletedService"
          :allAllocations="part.allocationsAttributes"
        />
      </div>
    </div>
    <div v-if="hasDeletedService" :class="$style.subSection">
      <BaseAlert
        color="warning"
        :text="$t('appointment.service_deleted_message')"
        inline
        v-test="'service-deleted-message'"
      />
    </div>
    <div v-if="!part.serviceId" :class="$style.subSection">
      <ServiceSuggestions :part="part" @select="selectSuggestedService" />
    </div>
    <div
      v-show="partAmount > 1"
      :class="$style.dragIcon"
      class="js-drag-handle"
      v-test="'appointment-part-draghandle'"
    >
      <BaseIcon name="drag" />
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { useServicesStore } from '@/stores/services';
import { mapState, storeToRefs } from 'pinia';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import ResourceSelect from './ResourceSelect.vue';
import DisabledResource from './DisabledResource.vue';
import ServiceSuggestions from './ServiceSuggestions.vue';
import { useResourcesStore } from '@/stores/resources';
import type { AppointmentPartAttributes, Service } from '@/types';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';

export default defineComponent({
  name: 'AppointmentPartContent',
  components: {
    ResourceSelect,
    ServiceSuggestions,
    DisabledResource,
  },
  props: {
    part: {
      type: Object as PropType<AppointmentPartAttributes>,
      required: true,
    },
    partIndex: {
      type: Number as PropType<number>,
      required: true,
    },
    onlyResource: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const { formData, existingAppointmentData, loadAllocations, services } =
      useCreateAppointmentStore();
    const { unavailableServices } = storeToRefs(useCreateAppointmentStore());
    const unavailableService = computed(() =>
      unavailableServices.value.find((s) => s.id === props.part.serviceId),
    );

    const smallScreen = computed(() => {
      const { screenSize } = usePageLayoutStore();
      const { previewModeActive } = useCalendarPreviewStore();

      return screenSize === 's' || (screenSize === 'm' && previewModeActive);
    });

    return {
      formData,
      services,
      existingAppointmentData,
      loadAllocations,
      filters,
      unavailableService,
      smallScreen,
    };
  },
  computed: {
    ...mapState(useServicesStore, ['serviceCategories']),
    ...mapState(useCreateAppointmentStore, ['services']),
    ...mapState(useResourcesStore, ['hasSingleResource']),
    hasDeletedService() {
      return !!this.selectedService?.deleted;
    },
    selectedService(): Service {
      return this.services.find(
        (service: Service) => service.id === this.part.serviceId,
      );
    },
    partAmount() {
      return this.formData.partsAttributes.length;
    },
  },
  methods: {
    selectSuggestedService(serviceId: Service['id']) {
      this.part.serviceId = serviceId;
      this.setPartData();
    },
    setPartData() {
      // Set allocations and part durations based on the service
      this.loadAllocations(this.part);

      const { serviceById } = useServicesStore();
      const service = this.part.serviceId
        ? serviceById(this.part.serviceId)
        : null;

      if (!service) {
        return;
      }

      // When a new service is selected, we update some of the part properties to equal the value of the service props
      // Based on the requiresProcessingTime we reset the durations to 0 that are not being used

      if (service.requiresProcessingTime) {
        this.part.durationFinish = service.durationFinish || 0;
        this.part.durationProcessing = service.durationProcessing || 0;
        this.part.durationSetup = service.durationSetup || 0;
        this.part.duration = 0;
      } else {
        this.part.durationFinish = 0;
        this.part.durationProcessing = 0;
        this.part.durationSetup = 0;
        this.part.duration = (service.duration || 0) + (service.buffer || 0);
      }
    },
  },
  created() {
    if (this.part.serviceId && !this.existingAppointmentData.id) {
      // When creating a new appointment, it is possible that the serviceIds on allocations are already set
      // This happens for example when creating an appointment from the waiting list
      // In this case, we need to load the correct allocations
      // Don't do this when editing an existing appointment

      this.setPartData();
    }
  },
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing * 0.5;
}

.formFieldsWrap {
  display: flex;
  flex-wrap: wrap;
}

.formSection {
  padding: $spacing * 0.5;

  .base:not(.smallScreen) & {
    &:not(.isSmall) {
      width: 40%;
    }

    &.isSmall {
      width: 20%;
    }
  }

  .base.smallScreen & {
    width: 100%;
  }
}

.multipleDurationsWrap {
  display: flex;
  justify-content: space-between;

  & > * {
    width: 31%;
  }
}

.subSection {
  padding: $spacing * 0.5;
}

.disabledService {
  display: flex;
  align-items: center;
  height: $input-height;
}

.dragIcon {
  position: absolute;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  cursor: move;
}
</style>
