import { useCompanyStore } from '@/stores/company';
import { redirectRoute } from '../helpers';

const redirectIfNotContactEmailVerified = (to) => {
  const { company } = useCompanyStore();

  if (!company?.contactEmailVerified) {
    return redirectRoute({
      name:
        to.name === 'rebook-reminder-template'
          ? 'rebook-reminder-dashboard'
          : 'marketing-email',
      query: to.query,
    });
  }
};
const Marketing = () => import('@/modules/marketing/index.vue');
const MarketingViews = () => import('@/modules/marketing/MarketingViews.vue');
const MarketingInsights = () =>
  import('@/modules/marketing/insights/index.vue');

const NewsletterTemplate = () =>
  import('@/modules/marketing/email/templates/newsletter/index.vue');
const RebookReminderTemplate = () =>
  import('@/modules/marketing/email/templates/rebook-reminder/index.vue');
const BirthdayTemplate = () =>
  import('@/modules/marketing/email/templates/birthday/index.vue');
const OnlineForms = () =>
  import('@/modules/marketing/email/templates/online-forms/index.vue');
const WaitingListTemplate = () =>
  import('@/modules/marketing/email/templates/waiting-list/index.vue');
const WaitingListEntry = () =>
  import('@/modules/_shared/waiting-list/Entry.vue');

const CreateDiscountCode = () =>
  import('@/modules/marketing/modals/CreateDiscountCode.vue');
const DeleteDiscountCodeConfirmation = () =>
  import('@/modules/marketing/modals/DeleteDiscountCodeConfirmation.vue');
const EnableLastMinuteDiscount = () =>
  import('@/modules/marketing/modals/EnableLastMinuteDiscount.vue');
const DisableLastMinuteDiscount = () =>
  import('@/modules/marketing/modals/DisableLastMinuteDiscount.vue');
const DiscountDetails = () =>
  import('@/modules/marketing/discounts/DiscountDetails.vue');
const CreateFilterGroup = () =>
  import('@/modules/marketing/modals/CreateFilterGroup.vue');

const DefaultRebookSettings = () =>
  import('@/modules/marketing/modals/rebook-setup/DefaultRebookSettings.vue');
const RebookSetup = () =>
  import('@/modules/marketing/modals/rebook-setup/index.vue');
const RebookReminderDashboard = () =>
  import('@/modules/marketing/rebook-reminder/dashboard/index.vue');
const CreateWaitingListEntry = () =>
  import('@/modules/_shared/waiting-list/CreateEntry.vue');

export default {
  path: 'marketing',
  name: 'marketing',
  component: Marketing,
  redirect: {
    name: 'marketing-insights',
  },
  meta: {
    moduleName: 'marketing',
    role: 'manager',
  },
  children: [
    {
      path: 'insights',
      name: 'marketing-insights',
      component: MarketingInsights,
      meta: {
        mixpanelName: 'Marketing - Insights',
      },
    },
    {
      path: 'waiting-list',
      name: 'marketing-waiting-list',
      component: MarketingViews,
      props: { route: 'waiting-list' },
      meta: {
        mixpanelName: 'Marketing - Waiting list',
      },
      children: [
        {
          path: 'new',
          component: CreateWaitingListEntry,
          props: {
            parentRouteName: 'marketing-waiting-list',
          },
        },
        {
          path: ':waitingListEntryId',
          component: WaitingListEntry,
          props: {
            parentRouteName: 'marketing-waiting-list',
          },
        },
      ],
    },
    {
      path: 'discounts',
      name: 'marketing-discounts',
      component: MarketingViews,
      props: { route: 'discounts' },
      meta: {
        mixpanelName: 'Marketing - Discounts',
      },
      children: [
        {
          path: 'new',
          name: 'create-discount-code',
          component: CreateDiscountCode,
          props: {
            parentRouteName: 'marketing-discounts',
          },
        },
        {
          path: ':discountId/edit',
          name: 'edit-discount-code',
          component: CreateDiscountCode,
          props: {
            parentRouteName: 'marketing-discounts',
          },
        },
        {
          path: ':discountId/delete',
          name: 'delete-discount-code',
          component: DeleteDiscountCodeConfirmation,
          props: {
            parentRouteName: 'marketing-discounts',
          },
        },
        {
          path: 'enable',
          name: 'enable-last-minute-discount',
          component: EnableLastMinuteDiscount,
        },
        {
          path: 'disable',
          name: 'disable-last-minute-discount',
          component: DisableLastMinuteDiscount,
          props: {
            parentRouteName: 'marketing-discounts',
          },
        },
        {
          path: ':discountId',
          name: 'discount-details',
          component: DiscountDetails,
          redirect: { name: 'discount-details-overview' },
          children: [
            {
              path: 'details',
              name: 'discount-details-overview',
              component: {
                template: '<template />',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'email',
      name: 'marketing-email',
      component: MarketingViews,
      props: { route: 'email' },
      meta: {
        mixpanelName: 'Marketing - Newsletter',
      },
    },
    {
      path: 'filter-groups',
      name: 'marketing-filter-groups',
      component: MarketingViews,
      props: { route: 'filter' },
      meta: {
        mixpanelName: 'Marketing - Filter groups',
      },
      children: [
        {
          path: ':filterGroupId/edit',
          name: 'edit-filter-group',
          component: CreateFilterGroup,
          props: {
            parentRouteName: 'marketing-filter-groups',
          },
        },
      ],
    },
    {
      path: 'builder/newsletter',
      name: 'newsletter-template',
      component: NewsletterTemplate,
      children: [
        {
          path: ':id',
          name: 'newsletter-template-edit',
          component: NewsletterTemplate,
        },
      ],
      beforeEnter(to) {
        return redirectIfNotContactEmailVerified(to);
      },
    },
    {
      path: 'rebook-reminder',
      name: 'rebook-reminder',
      component: MarketingViews,
      props: { route: 'rebook-reminder' },
      meta: {
        hideIntercomButton: true,
        mixpanelName: 'Marketing - Rebook reminder',
      },
      redirect: {
        name: 'rebook-reminder-dashboard',
      },
      children: [
        {
          path: '',
          name: 'rebook-reminder-dashboard',
          component: RebookReminderDashboard,
          children: [
            {
              path: 'rebook-default',
              name: 'default-rebook-reminder-services',
              component: DefaultRebookSettings,
            },
            {
              path: 'select-services',
              name: 'rebook-reminder-services',
              component: RebookSetup,
            },
          ],
        },
        {
          path: 'builder',
          name: 'rebook-reminder-template',
          component: RebookReminderTemplate,
          meta: {
            hideIntercomButton: true,
          },
          beforeEnter(to) {
            return redirectIfNotContactEmailVerified(to);
          },
        },
      ],
    },
    {
      path: 'builder/waiting-list',
      name: 'waiting-list-template',
      component: WaitingListTemplate,
      meta: {
        hideIntercomButton: true,
      },
      beforeEnter(to) {
        return redirectIfNotContactEmailVerified(to);
      },
    },
    {
      path: 'builder/birthday',
      name: 'birthday-template',
      component: BirthdayTemplate,
      meta: {
        hideIntercomButton: true,
      },
      beforeEnter(to) {
        return redirectIfNotContactEmailVerified(to);
      },
    },
    {
      path: 'builder/online-forms',
      name: 'online-forms',
      component: OnlineForms,
      meta: {
        hideIntercomButton: true,
      },
      beforeEnter(to) {
        return redirectIfNotContactEmailVerified(to);
      },
    },
  ],
};
