<template>
  <div :class="$style.base">
    <BaseHeading border>
      {{ $t('global.sales') }}
    </BaseHeading>
    <BaseScroll v-if="orderItems && orderItems.length" :contentWidth="700">
      <BaseTableRow head>
        <BaseTableCell>
          {{ $t('global.date') }}
        </BaseTableCell>
        <BaseTableCell>
          {{ $t('global.order') }}
        </BaseTableCell>
        <BaseTableCell>
          {{ $t('global.customer') }}
        </BaseTableCell>
        <BaseTableCell right>
          {{ $t('global.price') }}
        </BaseTableCell>
        <BaseTableCell right>
          {{ $t('global.quantity') }}
        </BaseTableCell>
      </BaseTableRow>

      <BaseTableRow
        v-for="item in orderItems"
        :key="item.id"
        v-test="'orderItems'"
      >
        <BaseTableCell v-if="item.order">
          {{ filters.date(item.order.invoicedAt) }}
        </BaseTableCell>
        <BaseTableCell v-else> - </BaseTableCell>
        <BaseTableCell>
          <a
            :href="`${backendUrl}/orders/${item.order.id}.pdf`"
            target="_blank"
            :class="$style.link"
          >
            <BaseIcon color="primary" name="pdf" :mr="0.5" />
            {{ item.order.number }}
          </a>
        </BaseTableCell>
        <BaseTableCell>
          <BaseText
            v-if="item.order.customer"
            link
            @click="onCustomerClick(item.order.customer.id)"
            v-test="'orderCustomer'"
          >
            {{ item.order.customer.fullName }}
          </BaseText>
          <BaseText v-else> - </BaseText>
        </BaseTableCell>
        <BaseTableCell right>
          {{ filters.currency(item.price) }}
        </BaseTableCell>
        <BaseTableCell right>
          {{ item.quantity }}
        </BaseTableCell>
      </BaseTableRow>
    </BaseScroll>
    <NoResults v-if="hasNoResults" />
    <Pagination
      :items="orderItems"
      :loading="$apollo.loading"
      fieldName="orderItems"
      :apolloQuery="$apollo.queries.orderItems"
      :extraVariables="salesVariables"
      :mb="hasNoResults ? 2 : null"
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import Pagination from '@/components/_deprecated/Pagination.vue';
import NoResults from '../NoResults.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';
import config from '@/config';

export default defineComponent({
  components: {
    Pagination,
    NoResults,
    BaseTableRow,
    BaseTableCell,
  },
  setup() {
    return {
      backendUrl: config.backendUrl,
      filters,
    };
  },
  data() {
    return {
      orderItems: [],
    };
  },
  apollo: {
    orderItems() {
      return {
        query: gql`
          query getOrderItems(
            $locationId: Int
            $productId: Int!
            $pagination: PaginationAttributes
          ) {
            orderItems(
              locationId: $locationId
              productId: $productId
              pagination: $pagination
            ) {
              id
              price
              quantity
              order {
                id
                number
                invoicedAt
                customer {
                  id
                  fullName
                }
              }
            }
          }
        `,
        skip() {
          return !this.$route.params.id || !this.locationId;
        },
        variables: {
          pagination: {
            currentPage: 1,
            perPage: 25,
          },
          ...this.salesVariables,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      };
    },
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId']),
    hasNoResults() {
      return !this.$apollo.loading && !this.orderItems.length;
    },
    salesVariables() {
      return {
        locationId: Number.parseInt(this.locationId),
        productId: Number.parseInt(this.$route.params.id),
      };
    },
  },
  methods: {
    onCustomerClick(id) {
      this.$router.push({
        name: 'customer-overview',
        params: { customerId: id },
      });
    },
  },
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  min-height: 200px;
}

.link {
  display: flex;
  align-items: center;
  color: $color-primary !important;
}
</style>
