<template>
  <div
    :class="[
      $style.base,
      { [$style.isSmall]: $screen === 's' || $screen === 'm' }
    ]"
  >
    <div :class="$style.inner">
      <NoResults v-if="$screen === 'l'" :class="$style.noResults" />
      <BaseText :mb="2">
        {{ $t('products.select_products') }}
      </BaseText>
      <div v-test="'autoButton'">
        <BaseButton
          mb
          color="inverted"
          :loading="$apollo.loading"
          :disabled="autoStockOrderDisabled"
          @click="onCreateAuto"
          v-test="'onCreateAuto'"
        >
          {{ $t('products.create_automatically') }}
        </BaseButton>
        <BaseText v-if="!$apollo.loading" size="s" v-test="'noSetupExplained'">
          {{
            $t(
              `products.${autoStockOrderDisabled ? 'automatically_disabled' : 'automatically_explained'}`
            )
          }}
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NoResults from '../NoResults.vue';
import gql from 'graphql-tag';
import { productFragment } from '@/graphql-fragments';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import type { SelectedProduct } from './types';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    NoResults,
  },
  props: {
    supplier: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: false,
    },
  },
  emits: ['addSelectedProducts'],
  apollo: {
    products: {
      query: gql`
        query getOutOfStockProducts(
          $filter: String
          $locationId: ID
          $pagination: PaginationAttributes
          $categoryIds: [Int!]
          $supplierIds: [Int!]
        ) {
          products(
            filter: $filter
            locationId: $locationId
            pagination: $pagination
            categoryIds: $categoryIds
            supplierIds: $supplierIds
          ) {
            ...product
          }
        }
        ${productFragment}
      `,
      variables() {
        return {
          locationId: this.locationId,
          pagination: {
            currentPage: 1,
            perPage: 1000,
          },
          categoryIds: [],
          supplierIds: [this.supplier.id],
          filter: 'out_of_stock',
        };
      },
      fetchPolicy: 'no-cache',
    },
  },
  computed: {
    autoStockOrderDisabled() {
      return !this.products?.length;
    },
    ...mapState(useLocationsStore, ['locationId']),
  },
  methods: {
    onCreateAuto() {
      if (!this.products?.length) {
        return;
      }

      const selectedProducts: SelectedProduct[] = [];

      this.products.forEach((product) => {
        const mutableProduct = {
          ...product,
          id: undefined,
          productId: product.id,
        };

        if (mutableProduct.stockAmount !== undefined) {
          let targetStock;

          if (mutableProduct.stockMaximum !== undefined) {
            targetStock = mutableProduct.stockMaximum;
          } else {
            targetStock = mutableProduct.stockMinimum;
          }

          if (targetStock !== undefined) {
            mutableProduct.quantity = targetStock - mutableProduct.stockAmount;
            selectedProducts.push(mutableProduct);
          }
        }
      });

      this.$emit('addSelectedProducts', selectedProducts);
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing * 2;
}

.inner {
  max-width: 240px;
  text-align: center;
}

.noResults {
  margin-top: -$spacing * 3;
  margin-bottom: $spacing * 4;
}
</style>
