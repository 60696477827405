<template>
  <transition name="pop" @enter="onEnter" @after-leave="onAfterLeave">
    <div
      v-show="visible"
      ref="el"
      :class="[
        $style.base,
        {
          [$style.alignLeft]: alignX === 'left',
          [$style.alignRight]: alignX === 'right',
          [$style.alignBottom]: alignY === 'bottom',
          [$style.alignTop]: alignY === 'top'
        }
      ]"
      v-test="'_base-button-popover'"
    >
      <div :class="$style.inner">
        <component
          :is="
            option.routerLink
              ? 'router-link'
              : option.submitForm
                ? 'button'
                : 'div'
          "
          v-for="(option, index) in options"
          :key="index"
          :to="option.routerLink"
          :class="$style.option"
          @click="$emit('select', option.value)"
          v-test="'_base-button-popover-option'"
        >
          <BaseIcon v-if="option.icon" :name="option.icon" />
          {{ option.label }}
        </component>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import type { Option } from './types';

const props = defineProps<{
  visible: boolean;
  options: Option[];
  parentEl: any;
}>();

const emit = defineEmits(['close', 'select']);

const el = ref();
onClickOutside(
  el,
  () => {
    emit('close');
  },
  {
    ignore: [props.parentEl],
  },
);

const alignX = ref<'left' | 'right'>('left');
const alignY = ref<'bottom' | 'top'>('bottom');

const onEnter = () => {
  const rect = el.value.getBoundingClientRect();
  if (rect.height + rect.y > window.innerHeight) {
    alignY.value = 'top';
  } else {
    alignY.value = 'bottom';
  }

  if (rect.width + rect.x > window.innerWidth) {
    alignX.value = 'right';
  } else {
    alignX.value = 'left';
  }
};

const onAfterLeave = () => {
  // Reset positioning to that the correct calculation happens when showing again
  alignX.value = 'left';
  alignY.value = 'bottom';
};
</script>

<style lang="scss" module>
.base {
  position: absolute;
  max-width: calc(100vw - #{$spacing * 2});
  text-align: left;
  padding: 4px 0;
  z-index: 2;

  &:global(.v-enter-active) {
    transition:
      transform 0.1s $easeOutBack,
      opacity 0.1s $easeOutBack;
  }

  &:global(.v-enter-from) {
    transform: scale(0.5);
    opacity: 0;
  }

  &.alignLeft {
    left: 0;
  }

  &.alignRight {
    right: 0;
  }

  &.alignBottom {
    top: 100%;
  }

  &.alignTop {
    bottom: 100%;
  }
}

.inner {
  border-radius: $radius;
  background: $white;
  box-shadow: $shadow;
  overflow: hidden;
}

.option {
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
  padding: $spacing;
  cursor: pointer;
  background-color: white;

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }

  @include hover {
    background-color: $color-highlight;
  }
}
</style>
