<template>
  <div :class="[$style.base, $style[size]]">
    <div :class="$style.inner">
      <div
        v-if="resourcePicture"
        :class="[$style.circle, $style.circleImage]"
        v-test="'_base-avatar-picture'"
      >
        <BaseImage
          :cloudinaryId="resourcePicture"
          :width="imageSize"
          :height="imageSize"
          crop="fill"
        />
      </div>
      <div v-else :class="$style.circle" :style="avatarStyle">
        <div
          v-if="resourceName"
          :class="$style.text"
          v-test="'_base-avatar-name'"
        >
          {{ resourceInitials }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    picture: {
      type: String,
    },
    name: {
      type: String,
    },
    color: {
      type: String,
    },
    size: {
      type: String as PropType<'s' | 'm' | 'l' | 'xl'>,
      default: 'm',
    },
    resource: {
      type: Object,
    },
  },
  computed: {
    avatarStyle() {
      return this.resourceColor && !this.resourcePicture
        ? {
            'background-color': this.resourceColor,
          }
        : {};
    },
    resourceName() {
      return this.resource?.name || this.name;
    },
    resourcePicture() {
      return this.resource?.picture || this.picture;
    },
    resourceInitials() {
      return (
        this.resource?.initials ||
        this.resourceName.charAt(0) + this.resourceName.charAt(1)
      );
    },
    resourceColor() {
      return this.resource?.color || this.color;
    },
    imageSize() {
      // Use sizes twice the width of the container, so the image looks good on retina displays
      return {
        s: 40,
        m: 48,
        l: 64,
        xl: 128,
      }[this.size];
    },
  },
});
</script>

<style lang="scss" module>
.base {
  user-select: none;
  flex-shrink: 0;

  &.s {
    width: 20px;
  }

  &.m {
    width: 24px;
  }

  &.l {
    width: 32px;
  }

  &.xl {
    width: 64px;
  }
}

.inner {
  position: relative;
  padding-bottom: 100%;
}

.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;

  &:not(.circleImage) {
    background-color: $color-primary;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.circleImage {
  font-size: 0;
}

.text {
  color: white;
  font-size: 11px;
  font-weight: bold;
  text-transform: capitalize;

  .base.s & {
    font-size: 10px;
  }

  .base.l & {
    font-size: 14px;
  }

  .base.xl & {
    font-size: 28px;
  }
}
</style>
