<template>
  <div>
    <component
      :is="openOnClick ? 'a' : 'div'"
      :href="openOnClick ? linkUrl : null"
      :target="openOnClick ? '_blank' : null"
    >
      <div
        v-if="showPlaceholder"
        :class="$style.placeholder"
        :style="{ width: width + 'px' }"
      />
      <img
        v-else-if="imageSrc"
        :class="[
          $style.image,
          {
            [$style.cover]: cover
          }
        ]"
        :src="imageSrc"
        :width="width"
        :height="height"
      />
    </component>
  </div>
</template>

<script lang="ts">
import { Cloudinary } from 'cloudinary-core';
import config from '@/config';

import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    cloudinaryId: {
      type: String,
    },
    width: {
      type: [Number, String],
    },
    height: {
      type: [Number, String],
    },
    openOnClick: {
      type: Boolean,
      default: false,
    },
    crop: {
      type: String,
      default: 'scale',
      validator: (value) => ['scale', 'pad', 'fill'].indexOf(value) !== -1,
    },
    cover: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: '',
      validator: (value) => !value || ['jpg', 'png'].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      linkUrl: null,
      imageSrc: null,
      showPlaceholder: !config.cloudinaryCloudName,
    };
  },
  watch: {
    cloudinaryId: {
      handler() {
        if (!this.showPlaceholder && this.cloudinaryId) {
          const cloudinaryCore = new Cloudinary({
            cloud_name: config.cloudinaryCloudName,
          });
          this.linkUrl = cloudinaryCore.url(this.cloudinaryId);
          this.imageSrc = cloudinaryCore.url(this.cloudinaryId, {
            width: this.width,
            height: this.height,
            crop: this.crop,
            format: this.format,
          });
        }
      },
      immediate: true,
    },
  },
});
</script>

<style module lang="scss">
.placeholder {
  height: 200px;
  background-color: $color-highlight;
}

.image {
  &.cover {
    width: 100%;
    object-fit: cover;
  }

  &:not(.cover) {
    max-width: 100%;
    height: auto;
  }
}
</style>
