<template>
  <div>
    <div>
      <BaseButton
        color="inverted"
        mr
        @click="$emit('close')"
        v-test="'resource-close'"
      >
        {{ $t('global.actions.close') }}
      </BaseButton>
      <BaseButton
        :loading="isLoading"
        @click="$emit('onSubmit')"
        v-test="'resource-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    resource: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
  },
  emits: ['onSubmit', 'close'],
});
</script>
