<template>
  <BaseModalLarge
    :heading="
      locationId
        ? $t('locations.update_location')
        : $t('locations.create_location')
    "
    :loading="loading"
    noPadding
    parentRoute="admin-locations"
  >
    <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
      <div ref="scrollContainer" :class="$style.main">
        <BaseForm
          :scrollContainer="$screen !== 's' ? scrollContainer : null"
          @submit="submitForm"
        >
          <div :class="$style.name">
            <BaseInput
              v-model="location.internalName"
              :label="$t('locations.fields.internal_name')"
              mb
              mr
              required
              v-test="'location-internal-name'"
            />
            <div :class="$style.sortOrder">
              <BaseHeading size="s" :mb="0.5">
                {{ $t('locations.fields.sort_order') }}
              </BaseHeading>
              <BaseCounter
                v-model="location.sortOrder"
                :minValue="0"
                mb
                v-test="'location-sort'"
              />
            </div>
          </div>
          <BaseInput
            v-model="location.name"
            :label="$t('locations.fields.name')"
            required
            mb
            v-test="'location-name'"
          />
          <BaseInput
            v-model="location.address"
            :label="$t('locations.fields.address')"
            mb
            v-test="'location-address'"
          />
          <div :class="$style.grouped">
            <BaseInput
              v-model="location.postalcode"
              :label="$t('locations.fields.postal_code')"
              v-test="'location-postcode'"
            />
            <BaseInput
              v-model="location.city"
              :label="$t('locations.fields.city')"
              ml
              mb
              v-test="'location-city'"
            />
          </div>
          <BaseInput
            v-model="location.phone"
            :label="$t('locations.fields.phone_number')"
            mb
            v-test="'location-phone'"
          />
          <BaseInput
            v-model="location.bankAccount"
            :label="$t('locations.fields.bank_account')"
            mb
            v-test="'location-bank'"
          />

          <BaseHeading size="s" mb>
            {{ $t('locations.image') }}
          </BaseHeading>
          <BaseUpload
            preset="location-picture"
            withImagePreview
            withDelete
            :image="location.picture"
            @uploadResults="handleLogoUpload"
            @delete="deletePicture"
          />
        </BaseForm>
      </div>

      <div :class="$style.side">
        <BaseHeading>
          {{ $t('locations.extra_settings') }}
        </BaseHeading>
        <BaseCard>
          <BaseCheckbox
            v-model="location.bookable"
            :label="$t('locations.fields.services')"
            :description="$t('locations.fields.services_description')"
            v-test="'location-bookable'"
          />
        </BaseCard>
      </div>
    </div>

    <template #footer>
      <BaseButton
        color="inverted"
        :disabled="updateMutationLoading || createMutationLoading"
        @click="goBack"
        v-test="'location-close'"
      >
        {{ $t('global.actions.close') }}
      </BaseButton>
      <BaseButton
        :loading="updateMutationLoading || createMutationLoading"
        @click="submitForm"
        v-test="'location-submit'"
      >
        {{ $t(`global.actions.${locationId ? 'update' : 'create'}`) }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script lang="ts" setup>
import type { CloudinaryResponse } from '@/components/base-upload/index.vue';
import { GET_LOCATION, CREATE_LOCATION, UPDATE_LOCATION } from '../graphql';
import { ref, reactive } from 'vue';
import { useMutation, useLazyQuery } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';
import { useLocationsStore } from '@/stores/locations';
import type { Location } from '@/types';
import { storeToRefs } from 'pinia';
import useVuelidate from '@vuelidate/core';
import { modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const v$ = useVuelidate();
const scrollContainer = ref();
const { t } = useI18n();

const route = useRoute();
const router = useRouter();
const location = reactive({
  name: '',
  internalName: '',
  address: '',
  postalcode: '',
  city: '',
  bookable: false,
  picture: '',
  phone: '',
  bankAccount: '',
  sortOrder: 1,
});
const locationId = ref(Number.parseInt(route.params.id as string));

const {
  load: findLocation,
  onResult,
  loading,
} = useLazyQuery(GET_LOCATION, { id: locationId.value });
const { mutate: updateLocation, loading: updateMutationLoading } =
  useMutation(UPDATE_LOCATION);
const { mutate: createLocation, loading: createMutationLoading } =
  useMutation(CREATE_LOCATION);

const { locations } = storeToRefs(useLocationsStore());

if (locationId.value) {
  findLocation();
}

onResult(({ data }) => {
  Object.assign(location, {
    id: data.location.id,
    name: data.location.name,
    internalName: data.location.internalName,
    address: data.location.address,
    postalcode: data.location.postalcode,
    city: data.location.city,
    bookable: data.location.bookable,
    picture: data.location.picture,
    phone: data.location.phone,
    bankAccount: data.location.bankAccount,
    sortOrder: data.location.sortOrder,
  });
});

const deletePicture = () => {
  location.picture = '';
};

const handleLogoUpload = (uploadResults: CloudinaryResponse) => {
  location.picture = uploadResults.secure_url;
};

const submitForm = () => {
  v$.value.$touch();

  if (v$.value.$invalid) {
    return;
  }

  if (locationId.value) {
    handleUpdateLocation();
  } else {
    handleCreateLocation();
  }
};

const handleUpdateLocation = () => {
  updateLocation({ input: location }).then(
    ({ data: { updateLocation } }: any) => {
      if (updateLocation.location) {
        editLocation(updateLocation.location);
        goBack();
      }
    },
  );
};

const handleCreateLocation = () => {
  modal('confirmation', { message: t('locations.banner.confirmation') }).then(
    () => {
      createLocation({ input: location }).then(
        ({ data: { createLocation } }: any) => {
          if (createLocation.location) {
            addLocation(createLocation.location);
            goBack();
          }
        },
      );
    },
  );
};

const editLocation = (location: Location) => {
  removeLocation(location.id);
  addLocation(location);
};

const removeLocation = (id: number) => {
  locations.value = locations.value.filter(
    (location: Location) => location.id !== id,
  );
};

const addLocation = (location: Location) => {
  locations.value.push(location);
};

const goBack = () => {
  router.push({ name: 'admin-locations' });
};
</script>

<style lang="scss" module>
.base {
  height: 100%;

  &:not(.smallScreen) {
    display: flex;
    align-items: stretch;
  }
}

.grouped {
  display: flex;

  & > * {
    width: 100%;
  }
}

.main {
  background-color: white;

  .base:not(.smallScreen) & {
    width: 65%;
    padding: $spacing * 1.5;
    overflow-y: auto;
  }

  .base.smallScreen & {
    padding: $spacing;
  }
}

.side {
  .base:not(.smallScreen) & {
    border-left: 1px solid $color-border;
    padding: $spacing * 1.5;
    width: 35%;
    overflow-y: auto;
  }

  .base.smallScreen & {
    padding: $spacing;
    border-top: 1px solid $color-border;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}

.name {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    &:first-child {
      flex-grow: 1;
    }
  }
}

.sortOrder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
