<template>
  <BaseModal :loading="loading" testId="welcome" @close="$emit('close')">
    <Confetti v-test="'confetti'" />
    <div :class="$style.base">
      <div :class="$style.visual">
        <img src="/img/visual-welcome.svg" />
      </div>
      <BaseHeading size="xl" mb>
        {{ $t('subscription.welcome.heading') }}
      </BaseHeading>
      <BaseText mb>
        {{ $t('subscription.welcome.description_1') }}
      </BaseText>
      <BaseText :mb="2">
        {{
          isSubscribed
            ? $t('subscription.welcome.description_2')
            : $t('subscription.welcome.description_3')
        }}
      </BaseText>
      <BaseButton @click="handleSubmitButton" v-test="'welcome-submit'">
        {{
          $t(
            hasSubscriptionIntention
              ? 'subscription.welcome.set_treatwell'
              : 'subscription.welcome.button'
          )
        }}
      </BaseButton>
      <BaseText
        v-if="showTreatwell && !hasSubscriptionIntention"
        link
        mt
        @click="onTwLinkClick"
        v-test="'welcome-treatwell'"
      >
        {{ $t('subscription.treatwell_link') }}
      </BaseText>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import { useTreatwellStore } from '@/stores/treatwell';
import Confetti from '@/components/Confetti.vue';

import { computed, defineEmits } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';

const emit = defineEmits(['close']);
const router = useRouter();

const mixpanel = inject<any>('mixpanel');

const { showTreatwell } = useTreatwellStore();
const { isSubscribed } = useCompanyStore();

const { result, loading } = useQuery(gql`
  query getCurrentCompany {
    currentCompany {
      settings {
        treatwell {
          hasSubscriptionIntention
        }
      }
    }
  }
`);

const hasSubscriptionIntention = computed(
  () =>
    result.value?.currentCompany.settings.treatwell.hasSubscriptionIntention,
);

const onTwLinkClick = () => {
  emit('close');
  mixpanel.track('Welcome modal - Treatwell button clicked');
  router.push({ name: 'treatwell-landing' });
};

const handleSubmitButton = () => {
  emit('close');
  if (hasSubscriptionIntention.value) {
    mixpanel.track('Welcome modal - Treatwell start button clicked');
    router.push({ name: 'treatwell-landing' });
  }
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 450px;
  margin: 0 auto;
  padding: $spacing * 2 $spacing;
}

.confetti {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.visual {
  margin-bottom: $spacing * 2;
  padding: 0 $spacing;

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
