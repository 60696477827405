<template>
  <div :class="$style.emptyPageWrapper">
    <EmptyPageContent
      :loading="loading"
      :text="{
        title: $t('rebook_reminder.empty_state.title'),
        description: $t('rebook_reminder.empty_state.description'),
        btnPrimary: $t('rebook_reminder.empty_state.enable')
      }"
      :moreLink="{
        url:
          company.language === 'nl' || company.language === 'de'
            ? `https://help.salonized.com/${company.language}/articles/4582588`
            : 'https://help.salonized.com/en/articles/4582588',
        text: $t('rebook_reminder.empty_state.more_info')
      }"
      @click="enableTemplate"
      v-test="'rebook-reminder-empty-state'"
    >
      <template #visual>
        <RebookDisabledVisual />
      </template>
    </EmptyPageContent>
  </div>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';

import EmptyPageContent from '@/components/EmptyPageContent.vue';
import RebookDisabledVisual from '@/modules/marketing/email/templates/rebook-reminder/RebookDisabledVisual.vue';
import { useStore } from 'vuex';
import { useServicesStore } from '@/stores/services';
import { useRouter } from 'vue-router';
import type { Service, ServiceCategory } from '@/types';
import eventBus from '@/event-bus';
import { useMutation } from '@vue/apollo-composable';

const { company } = useCompanyStore();
const { currentOnboardingFlow } = storeToRefs(useOnboardingStore());

const loading = ref(false);

import { flash } from '@/helpers/ui';
const { updateCompany } = useCompanyStore();
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useOnboardingStore } from '@/stores/onboarding';
import { UPDATE_SERVICES } from '../../modals/rebook-setup/graphql';

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const mixpanel = inject<any>('mixpanel');

const enableTemplate = () => {
  loading.value = true;

  const { rebookEnabledServicesCount } = useServicesStore();
  if (rebookEnabledServicesCount === 0) {
    mixpanel.track('Rebook reminder feature enable click default settings');
    setAllServicesEnabled();
    router.push({ name: 'default-rebook-reminder-services' });
  } else {
    mixpanel.track('Rebook reminder feature enable click');
    enableSetting();
  }
};

const enableSetting = (redirectToDashboard = true) => {
  const input = {
    settings: {
      bookings: {
        rebookReminderEnabled: true,
      },
    },
  };

  updateCompany(input).then(() => {
    store.commit('marketing/builder/SET_ENABLED', true);
    if (redirectToDashboard) {
      router.push({ name: 'rebook-reminder-dashboard' });
    }
    flash(t('global.flash.rebook_reminder_enabled'));

    if (currentOnboardingFlow.value === 'marketing') {
      eventBus.$emit('open-task-menu');
      currentOnboardingFlow.value = '';
    }
    loading.value = false;
  });
};

const { mutate } = useMutation(UPDATE_SERVICES);
const setAllServicesEnabled = () => {
  const { categoriesWithServices } = useServicesStore();
  const bulkUpdateServiceAttributes = categoriesWithServices.flatMap(
    (category: ServiceCategory) =>
      category.services
        ? category.services.map((service: Service) => ({
            id: service.id,
            rebookEnabled: true,
            rebookPeriod: 30,
          }))
        : [],
  );

  mutate({
    input: {
      bulkUpdateServiceAttributes,
    },
  }).then(() => {
    const { getServices } = useServicesStore();
    getServices();
    flash(t('global.flash.services_updated'));
    enableSetting(false);
  });
};
</script>

<style lang="scss" module>
.emptyPageWrapper {
  padding: 0.5 * $spacing;
}
</style>
