<template>
  <div :class="$style.base">
    <div :class="$style.progress">
      <BaseProgress :percentage="percentage" />
    </div>
    <BaseText ml>
      {{ $t(`marketing.insights.stats.${text}`, { amount: subValue }) }}
    </BaseText>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    mainValue: {
      type: Number,
      required: false,
    },
    subValue: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    percentage() {
      return this.mainValue
        ? Math.round((this.subValue / this.mainValue) * 100)
        : 0;
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin: $spacing 0;
  }
}

.progress {
  width: 60px;
}
</style>
