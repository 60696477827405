<template>
  <div v-if="multiLocation" class="mb-1">
    <BaseHeading mb>{{
      $t('register.salonized_pay.terminal_checkout.assign_location')
    }}</BaseHeading>
    <BaseDropdown
      v-model="data.locationId"
      :options="
        locations.map((location) => ({
          value: location.id,
          label: location.internalName
        }))
      "
      v-test="'sp-order-terminal-location'"
    />
  </div>

  <BaseHeading mb>{{
    $t('register.salonized_pay.terminal_checkout.contact')
  }}</BaseHeading>
  <div :class="$style.inputWrapper">
    <BaseInput
      v-model="data.firstName"
      mb
      :label="$t('global.first_name')"
      required
      v-test="'sp-order-terminal-first-name'"
    />
    <BaseInput
      v-model="data.lastName"
      mb
      :label="$t('global.last_name')"
      required
      v-test="'sp-order-terminal-last-name'"
    />
  </div>
  <BaseInput
    v-model="data.email"
    mb
    :label="$t('global.email')"
    type="email"
    required
    v-test="'sp-order-terminal-email'"
  />

  <BaseHeading mt mb>{{
    $t('register.salonized_pay.terminal_checkout.delivery_address')
  }}</BaseHeading>
  <BaseText mb>{{
    $t('register.salonized_pay.terminal_checkout.delivery_info')
  }}</BaseText>
  <BaseInput
    v-model="data.addressLine"
    mb
    :label="$t('global.address')"
    required
    v-test="'sp-order-terminal-address'"
  />
  <div :class="$style.inputWrapper">
    <BaseInput
      v-model="data.postalCode"
      type="postalcode"
      mb
      :label="$t('global.postal_code')"
      required
      v-test="'sp-order-terminal-postalcode'"
    />
    <BaseInput
      v-model="data.city"
      mb
      :label="$t('global.city')"
      required
      v-test="'sp-order-terminal-city'"
    />
  </div>

  <BaseHeading mt mb>{{
    $t('register.salonized_pay.terminal_checkout.terms')
  }}</BaseHeading>
  <div :class="$style.checkboxWrapper">
    <BaseCheckbox
      v-model="data.termsAndConditions"
      :label="$t('register.salonized_pay.terminal_checkout.terms_read')"
      required
      v-test="'sp-order-terminal-terms-checkbox'"
    />
    <div :class="$style.termsLink">
      <BaseText link inline underline @click="openTermsAndConditions">
        {{ $t('register.salonized_pay.terminal_checkout.terms') }}
      </BaseText>
    </div>
  </div>

  <BaseText
    v-if="unleash.isEnabled('SalonizedPayFreeTerminalDisclaimer')"
    color="secondary"
  >
    {{ $t('register.salonized_pay.terminal_checkout.terms_info') }}
  </BaseText>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';
import unleash from '@/unleash';

interface DataProps {
  firstName: string;
  lastName: string;
  email: string;
  addressLine: string;
  postalCode: string;
  city: string;
  termsAndConditions: boolean;
  locationId: number;
}

const props = defineProps<{ data: DataProps }>();

const { multiLocation } = useCompanyStore();
const { locations } = useLocationsStore();

watch(
  () => props.data.locationId,
  (locationId) => {
    const location = locations.find((location) => location.id === locationId);
    if (location) {
      props.data.addressLine = location.address || '';
      props.data.city = location.city || '';
      props.data.postalCode = location.postalcode || '';
    }
  },
);

const openTermsAndConditions = () => {
  window.open('https://salonized.com/en/terms-and-conditions', '_blank');
};
</script>

<style lang="scss" module>
.inputWrapper {
  width: 100%;
  display: flex;
  gap: $spacing;

  & > * {
    flex: 1;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.termsLink {
  margin-left: 5px;
  margin-top: -1px;
}
</style>
