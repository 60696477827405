<template>
  <ItemWrap
    :id="data.id"
    :data="data"
    :class="[
      $style.base,
      {
        [$style.isAbsence]: !!data.name
      }
    ]"
  >
    <div :class="$style.hours">
      <div
        v-if="data.allDay"
        :class="$style.allDay"
        v-test="'schedule-item-allday'"
      >
        {{ $t('global.all_day') }}
      </div>
      <div v-else>
        <BaseText bold>
          {{ filters.time(data.startAt) }}
        </BaseText>
        <BaseText>
          {{ filters.time(data.endAt) }}
        </BaseText>
      </div>
    </div>
    <div v-if="!hasSingleEmployee" :class="$style.resources">
      <div v-if="resources.length" :class="$style.avatars">
        <div
          v-for="(resource, index) in resources"
          :key="`avatar-${index}`"
          :class="$style.avatar"
          v-test="'schedule-item-resource'"
        >
          <div :class="$style.avatarInner">
            <BaseAvatar
              :resource="resource"
              :tooltip="resource ? resource.name : null"
            />
          </div>
          <div
            v-if="isActive"
            :class="$style.avatarHighlight"
            :style="resource.color ? { 'border-color': resource.color } : {}"
          />
        </div>
      </div>
      <div v-else :class="$style.entireCompany">
        <BaseIcon name="people" :tooltip="$t('global.entire_company')" />
      </div>
    </div>
    <div :class="$style.main">
      <div>
        <BaseText v-if="data.customer" bold v-test="'schedule-item-customer'">
          {{ data.customer.fullName }}
        </BaseText>
        <BaseText v-if="data.serviceNames">
          {{ data.serviceNames }}
        </BaseText>
        <BaseText v-else-if="data.name">
          {{ data.name }}
        </BaseText>
      </div>
      <div :class="$style.mainRight">
        <BaseIcon
          v-if="data.name"
          :name="data.chore ? 'check-square' : 'slash'"
          :tooltip="
            filters.capitalize(
              data.chore
                ? $t('global.items.chore', 1)
                : $t('global.items.absence', 1)
            )
          "
          v-test="`schedule-item-icon-${data.chore ? 'chore' : 'absence'}`"
        />
        <BaseLabel v-if="isNew" state="new" />
      </div>
    </div>
  </ItemWrap>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import ItemWrap from './ItemWrap.vue';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ItemWrap,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      filters,
    };
  },
  data() {
    return {
      isNew: false,
      statusInterval: null,
    };
  },
  computed: {
    resources() {
      if (this.data.parts) {
        const resources = this.data.parts
          .map((p) => p.resources)
          .reduce((acc, val) => acc.concat(val), []);
        const resourceIds = resources.map((r) => r.id).filter((id) => !!id);
        return this.employees.filter((employee) =>
          resourceIds.includes(employee.id),
        );
      } else if (this.data.resourceId) {
        const resource = this.employees.find(
          (employee) => employee.id === this.data.resourceId,
        );
        return resource ? [resource] : [];
      } else {
        return [];
      }
    },
    isActive() {
      if (this.data.allDay) {
        return false;
      } else {
        return (
          dayjs().isAfter(this.data.startAt) &&
          dayjs().isBefore(this.data.endAt)
        );
      }
    },
    ...mapState(useResourcesStore, ['employees', 'hasSingleEmployee']),
  },
  methods: {
    checkStatus() {
      this.isNew =
        this.data.createdAt && this.data.online
          ? dayjs().diff(this.data.createdAt, 'minute') < 60
          : false;

      if (!this.isNew) {
        clearInterval(this.statusInterval);
      }
    },
  },
  created() {
    this.checkStatus();

    if (this.isNew) {
      this.statusInterval = setInterval(() => {
        this.checkStatus();
      }, 1000);
    }
  },
  beforeUnmount() {
    if (this.statusInterval) {
      clearInterval(this.statusInterval);
    }
  },
});
</script>

<style lang="scss" module>
.base {
  &.isAbsence {
    @include bg-striped(rgba(0, 0, 0, 0.08));
  }
}

.hours,
.resources,
.main,
.avatars,
.entireCompany {
  display: flex;
  align-items: center;
}

.hours {
  min-width: 60px;
  margin-right: $spacing;
}

.allDay {
  width: 32px;
  font-weight: bold;
  text-align: center;
}

.main {
  width: 100%;
  justify-content: space-between;
}

.mainRight {
  display: flex;
  justify-content: space-between;

  & > *:not(:first-child) {
    margin-left: $spacing * 0.5;
  }
}

.resources {
  margin-right: $spacing;
}

.entireCompany {
  width: 24px;
  justify-content: center;
}

.avatar {
  position: relative;

  &:not(:last-child) {
    margin-right: $spacing * 0.25;
  }
}

.avatarHighlight {
  position: absolute;
  left: calc(-10% - 4px);
  top: calc(-10% - 4px);
  width: calc(120% + 8px);
  height: calc(120% + 8px);
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: $color-primary;
  animation: pulse 2s ease-out 0s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  0%,
  50% {
    opacity: 0.3;
  }
  70%,
  100% {
    transform: none;
  }
  100% {
    opacity: 0;
  }
}

.avatarInner {
  position: relative;
  z-index: 1;
}
</style>
