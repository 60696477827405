<template>
  <div
    v-if="canGetBoost && unleash.isEnabled('SubscriptionBoost')"
    :class="[$style.base, { [$style.rounded]: rounded }]"
    v-test="'boost-banner'"
  >
    <BaseText color="inverted" mr>
      {{ $t('boost.description') }}
    </BaseText>
    <BaseButton @click="onModalOpen" v-test="'btn-get-boost'">
      {{ $t('boost.btn_text') }}
    </BaseButton>
  </div>
  <BoostModal
    v-if="showModal"
    :showErrorMessage="showErrorMessage"
    :isSubmitting="isSubmitting"
    @submit="getBoost"
    @close="onModalClose"
  />
</template>

<script lang="ts" setup>
import { useMutation } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import gql from 'graphql-tag';

import { flash } from '@/helpers/ui';
import BoostModal from './BoostModal.vue';
import { useCompanyStore } from '@/stores/company';
import unleash from '@/unleash';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

defineProps<{
  rounded?: boolean;
}>();
const emit = defineEmits(['success']);

const { t } = useI18n();

const mixpanel = inject<any>('mixpanel');
const { canGetBoost } = storeToRefs(useCompanyStore());

const showModal = ref(false);
const showErrorMessage = ref(false);
const isSubmitting = ref(false);

const CREATE_BOOST_EXTENSION = gql`
  mutation createBoostExtension($input: CreateBoostExtensionInput!) {
    createBoostExtension(input: $input) {
      errors {
        type
      }
    }
  }
`;

const { mutate } = useMutation(CREATE_BOOST_EXTENSION, () => ({
  variables: {
    input: {},
  },
}));

const onModalOpen = () => {
  showModal.value = true;
  mixpanel.track('Boost - get boost modal open');
};

const onModalClose = () => {
  showModal.value = false;
  mixpanel.track('Boost - get boost modal closed');
};

const onBoostSuccess = () => {
  mixpanel.track('Boost - get boost completed');
  const { getUser } = useUserStore();
  getUser().then(() => {
    emit('success');
  });
};

const getBoost = () => {
  mixpanel.track('Boost - get boost button clicked');
  isSubmitting.value = true;
  showErrorMessage.value = false;
  mutate()
    .then(
      ({
        data: {
          createBoostExtension: { errors },
        },
      }) => {
        if (errors) {
          showErrorMessage.value = true;
          mixpanel.track('Boost - get boost error');
        } else {
          showModal.value = false;
          flash(t('boost.success_message'));
          onBoostSuccess();
        }
      },
    )
    .catch(() => {
      showErrorMessage.value = true;
    })
    .finally(() => {
      isSubmitting.value = false;
    });
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: $white;
  background: linear-gradient(
    90deg,
    rgba(38, 37, 99, 1) 0%,
    rgba(76, 73, 197, 1) 50%,
    rgba(204, 255, 244, 1) 100%
  );
  padding: $spacing;

  border-radius: 0 0 $radius $radius;

  &.rounded {
    border-radius: $radius;
  }
}
</style>
