<template>
  <BaseModal
    :heading="$t('customers.ipl_treatment')"
    small
    useForm
    @submit="submit"
    @close="$emit('close')"
  >
    <BaseGrid container>
      <BaseGrid :size="6">
        <BaseDropdown
          v-model="report.employeeId"
          :label="$t('global.items.employee', 1)"
          :options="
            employees.map((e) => ({
              value: e.id,
              label: e.name
            }))
          "
          resources
          required
          v-test="'ipl-report-employee'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseDatePicker
          v-model="report.date"
          :label="$t('global.date')"
          v-test="'ipl-report-date'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseInput
          v-model="report.amount"
          :label="$t('global.amount')"
          type="number"
          required
          v-test="'ipl-report-amount'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseInput
          v-model="report.power"
          :label="$t('customers.power')"
          type="number"
          required
          :decimals="10"
          v-test="'ipl-report-power'"
        />
      </BaseGrid>
      <BaseGrid :size="12">
        <BaseInput
          v-model="report.notes"
          :label="$t('global.items.note', 2)"
          type="textarea"
          v-test="'ipl-report-notes'"
        />
      </BaseGrid>
    </BaseGrid>
    <template #footer>
      <BaseButton
        v-if="report.id"
        color="inverted"
        @click="deleteReport"
        v-test="'ipl-report-delete'"
      >
        {{ $t('global.actions.delete') }}
      </BaseButton>
      <BaseButton submitForm :loading="saving" v-test="'ipl-report-submit'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useResourcesStore } from '@/stores/resources';
import dayjs from '@/dayjs';
import { useMutation } from '@vue/apollo-composable';
import {
  CREATE_IPL_REPORT,
  UPDATE_IPL_REPORT,
  DELETE_IPL_REPORT,
} from './graphql';
import type { IplTreatmentReport } from './types';
import { modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const emit = defineEmits(['close', 'submitted', 'deleted']);

const props = defineProps<{
  treatmentId: string;
  treatmentNumber?: number;
  report?: IplTreatmentReport;
}>();

const { employees, hasSingleEmployee } = useResourcesStore();

const report = ref(
  props.report || {
    amount: 1,
    date: dayjs().format(),
    employeeId: hasSingleEmployee ? employees[0].id : null,
    id: null,
    notes: '',
    power: 0,
  },
);

const { mutate: createReport, loading: creating } =
  useMutation(CREATE_IPL_REPORT);
const { mutate: updateReport, loading: updating } =
  useMutation(UPDATE_IPL_REPORT);
const { mutate: _deleteReport, loading: deleting } =
  useMutation(DELETE_IPL_REPORT);

const saving = computed(
  () => creating.value || updating.value || deleting.value,
);

const submit = () => {
  const { id, amount, date, employeeId, notes, power } = report.value;

  if (!id) {
    createReport({
      input: {
        amount,
        date,
        employeeId,
        iplTreatmentId: props.treatmentId,
        notes,
        power,
        treatment: props.treatmentNumber,
      },
    }).then(
      ({
        data: {
          createIplTreatmentReport: { iplTreatmentReport },
        },
      }) => {
        afterSave(iplTreatmentReport);
      },
    );
  } else {
    updateReport({
      input: {
        amount,
        date,
        employeeId,
        id,
        notes,
        power,
      },
    }).then(
      ({
        data: {
          updateIplTreatmentReport: { iplTreatmentReport },
        },
      }) => {
        afterSave(iplTreatmentReport);
      },
    );
  }
};

const afterSave = (report: IplTreatmentReport) => {
  emit('submitted', report);
  emit('close');
};

const deleteReport = () => {
  modal('confirmation', {
    type: 'delete',
    item: t('global.items.report'),
  }).then(() => {
    _deleteReport({
      input: {
        id: report.value.id,
      },
    }).then(() => {
      emit('deleted', report.value.id);
      emit('close');
    });
  });
};
</script>
