const Locations = () => import('@/modules/admin/locations/index.vue');
const Location = () => import('@/modules/admin/locations/location/index.vue');

export default [
  {
    path: 'locations',
    name: 'admin-locations',
    component: Locations,
    meta: {
      featureFlag: 'admin-locations',
    },
    children: [
      {
        path: 'new',
        name: 'admin-location-new',
        component: Location,
      },
      {
        path: ':id',
        name: 'admin-location',
        component: Location,
      },
    ],
  },
];
