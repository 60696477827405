<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-products')"
    :text="{
      title: $t('upgrade_subscription_page.products.stock_orders.title'),
      description: $t(
        'upgrade_subscription_page.products.stock_orders.description'
      ),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    :moreLink="{
      text: $t('upgrade_subscription_page.products.stock_orders.learn_more'),
      url: $t('upgrade_subscription_page.products.stock_orders.learn_more_url')
    }"
    imageName="products"
  />

  <div v-else>
    <router-view />
    <Actions
      v-show="!showEmptyPageContent && firstFetchFinished"
      v-model="status"
      mb
      @search="search = $event"
    />
    <StockOrdersList
      v-if="stockOrders && stockOrders.length"
      :stockOrders="stockOrders"
    />
    <Pagination
      ref="pagination"
      :items="stockOrders"
      :loading="$apollo.loading"
      fieldName="stockOrders"
      :apolloQuery="$apollo.queries.stockOrders"
      :search="search"
      :extraVariables="{
        status: statusFormatted,
        locationId: locationId
      }"
      :showNoResults="false"
    />
    <EmptyPageContent
      v-show="showEmptyPageContent || noResults"
      imageName="products"
      :text="
        showEmptyPageContent
          ? {
              title: $t('products.empty_state.stock_orders.title'),
              description: $t('products.empty_state.stock_orders.description'),
              btnPrimary: $t('global.actions.create_stock_order')
            }
          : null
      "
      :moreLink="
        showEmptyPageContent
          ? {
              text: $t('products.empty_state.stock_orders.more_text'),
              url: $t('products.empty_state.stock_orders.more_url')
            }
          : null
      "
      @click="onEmptyPageClick"
    />
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import Actions from './Actions.vue';
import StockOrdersList from './StockOrdersList.vue';
import Pagination from '@/components/_deprecated/Pagination.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';

export default defineComponent({
  components: {
    Actions,
    StockOrdersList,
    Pagination,
    EmptyPageContent,
    EmptySubscriptionPage,
  },
  data() {
    return {
      stockOrders: [],
      search: '',
      status: 'all',
      firstFetchFinished: false,
    };
  },
  watch: {
    status: {
      handler: 'refetchProducts',
      deep: true,
    },
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useLocationsStore, ['locationId']),
    noResults() {
      return !this.$apollo.loading && !this.stockOrders.length;
    },
    showEmptyPageContent() {
      return (
        this.noResults &&
        this.firstFetchFinished &&
        !this.search &&
        !this.statusFormatted
      );
    },
    statusFormatted() {
      return this.status !== 'all' ? this.status : null;
    },
  },
  methods: {
    onEmptyPageClick() {
      this.$router.push({ name: 'products-order-new' });
    },
    refetchProducts() {
      this.$nextTick(() => {
        this.$refs.pagination.refetchQuery();
      });
    },
  },
  apollo: {
    stockOrders() {
      return {
        query: gql`
          query getStockOrders(
            $search: String
            $pagination: PaginationAttributes
            $status: String
            $locationId: ID
          ) {
            stockOrders(
              search: $search
              pagination: $pagination
              status: $status
              locationId: $locationId
            ) {
              id
              cid
              createdAt
              quantity
              status
              supplier {
                id
                name
              }
            }
          }
        `,
        variables: {
          search: '',
          status: '',
          pagination: {
            currentPage: 1,
            perPage: 25,
          },
          locationId: this.locationId,
        },
        result() {
          this.firstFetchFinished = true;
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      };
    },
  },
});
</script>
