<template>
  <div :class="$style.wrapper">
    <Confetti v-test="'confetti'" />
    <BaseModal
      :heading="$t('subscription.welcome.heading')"
      :loading="loading"
      testId="welcome-address"
      unclosable
      useForm
      @submit="handleSubmit"
      @close="$emit('close')"
    >
      <Confetti v-if="!formData.address" v-test="'confetti'" />
      <BaseText mb>
        {{ $t('subscription.welcome.address_description') }}
      </BaseText>
      <div>
        <BaseInput
          v-model="formData.address"
          :label="$t('global.address')"
          autoFocus
          :required="!noAddress"
          mb
          v-test="'welcome-address'"
        />
        <BaseGrid container>
          <BaseGrid :size="6">
            <BaseInput
              v-model="formData.postalcode"
              :label="$t('global.postal_code')"
              :required="!noAddress"
              mb
              v-test="'welcome-postalcode'"
            />
          </BaseGrid>
          <BaseGrid :size="6">
            <BaseInput
              v-model="formData.city"
              :label="$t('global.city')"
              :required="!noAddress"
              mb
              v-test="'welcome-city'"
            />
          </BaseGrid>
        </BaseGrid>
        <BaseInput
          v-model="countryName"
          disabled
          :label="$t('global.country')"
          mb
          v-test="'welcome-country'"
        />
      </div>
      <BaseCheckbox
        v-model="noAddress"
        :label="$t('subscription.welcome.no_address')"
      />
      <template #footer>
        <BaseButton
          :disabled="isDisabled"
          submitForm
          v-test="'welcome-address-submit'"
        >
          {{ $t('subscription.welcome.button') }}
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script setup lang="ts">
import Confetti from '@/components/Confetti.vue';

import { useCompanyStore } from '@/stores/company';

const noAddress = ref(false);

const { company, updateCompany } = useCompanyStore();
const loading = ref(false);
const emit = defineEmits(['close']);

const formData = ref({
  address: company.address,
  postalcode: company.postalcode,
  city: company.city,
});

const isDisabled = computed(
  () =>
    !noAddress.value &&
    (!formData.value.address ||
      !formData.value.postalcode ||
      !formData.value.city),
);

const handleSubmit = () => {
  loading.value = true;

  updateCompany({
    address: `${formData.value.address}`,
    postalcode: formData.value.postalcode,
    city: formData.value.city,
  }).then(() => {
    emit('close');
  });

  loading.value = false;
};

const regionNames = new Intl.DisplayNames([company.language], {
  type: 'region',
});

const countryName = regionNames.of(company.country.toUpperCase());
</script>

<style lang="scss" module>
.wrapper {
  height: 100vh;
  width: 100vw;
}
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 450px;
  margin: 0 auto;
  padding: $spacing * 2 $spacing;
}

.confetti {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.visual {
  margin-bottom: $spacing * 2;
  padding: 0 $spacing;

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
