import { type Message, Transporter } from './transporter';

export class StoreIframeTransporter extends Transporter {
  constructor(private iframe: HTMLIFrameElement) {
    super();
  }

  _send(data: Message) {
    this.iframe.contentWindow?.postMessage(
      { type: 'SalonizedStoreIframe', data },
      '*',
    );
  }

  _listen() {
    window.addEventListener('message', ({ data }) => {
      if (data.type === 'SalonizedStoreIframe') {
        this._onMessage(data.data);
      }
    });
  }
}
