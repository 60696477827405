<template>
  <Modal :saving="loading" @submit="submit" @close="$emit('close')">
    <BaseText mb>
      {{ $t('integrations.fiskaly.modal.description') }}
    </BaseText>
    <BaseText :href="$t('integrations.fiskaly.modal.helpcenter_link_url')" mb>
      {{ $t('integrations.fiskaly.modal.helpcenter_link_text') }}
    </BaseText>
    <BaseAlert
      :text="$t('integrations.fiskaly.modal.warning')"
      color="warning"
    />
  </Modal>
</template>

<script setup lang="ts">
import Modal from './settings/shared/Modal.vue';
import { ENABLE_FISKALY } from './graphql';
import { useMutation } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { flash } from '@/helpers/ui';

const emit = defineEmits(['close']);

const { t } = useI18n();
const apps = inject<any>('apps');

const { mutate, loading } = useMutation(ENABLE_FISKALY);

const submit = () => {
  mutate({
    input: {},
  }).then(() => {
    const fiskalyApp = apps.value.find((app) => app.key === 'fiscalization');
    fiskalyApp.enabled = true;
    flash(t('global.flash.integration_enabled'));
    emit('close');
  });
};
</script>
