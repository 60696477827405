<template>
  <div :class="[$style.base, { [$style.expanded]: isListVisible }]">
    <BaseInputLabel v-if="label" :text="label" :uniqueLabelId="uniqueId" />
    <input
      :id="uniqueId"
      ref="input"
      v-model="localValue"
      type="text"
      :class="$style.input"
      maxlength="5"
      inputmode="numeric"
      @focus="isListVisible = true"
      @blur="onBlur"
      v-test="'_timepicker-input'"
    />
    <BasePopover
      v-show="isListVisible"
      :class="$style.list"
      :hasClose="false"
      :mt="0.5"
      :keepVisibleOn="$refs.input"
      @close="isListVisible = false"
      v-test="'_timepicker-popover'"
    >
      <div :class="$style.listInner">
        <div ref="hourList" :class="$style.listSegment">
          <div
            v-for="(hour, index) in hours"
            :key="`hour-${index}`"
            :ref="hourSegmentsWrap.el"
            :class="[
              $style.timeSegment,
              { [$style.isActive]: hour === hourValue }
            ]"
            @click="
              () => {
                hourValue = hour;
                hourClicked = true;
              }
            "
          >
            <div :class="$style.timeSegmentInner" v-test="'_timepicker-hour'">
              {{ addLeadingZero(hour) }}
            </div>
          </div>
        </div>
        <div ref="minuteList" :class="$style.listSegment">
          <div
            v-for="(minute, index) in minutes"
            :key="`minute-${index}`"
            :ref="minuteSegmentsWrap.el"
            :class="[
              $style.timeSegment,
              { [$style.isActive]: minute === minuteValue }
            ]"
            @click="
              () => {
                minuteValue = minute;
                minuteClicked = true;
              }
            "
          >
            <div :class="$style.timeSegmentInner" v-test="'_timepicker-minute'">
              {{ addLeadingZero(minute) }}
            </div>
          </div>
        </div>
      </div>
    </BasePopover>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, nextTick, watchEffect } from 'vue';

const addLeadingZero = (value: number | string): string => {
  const stringValue = value.toString();
  return stringValue.length === 1 ? `0${stringValue}` : stringValue;
};

const isTimeString = (value: string) =>
  (value.length === 5 &&
    value.charAt(2) === ':' &&
    !Number.isNaN(value.slice(0, 2)) &&
    !Number.isNaN(value.slice(3, 5))) ||
  (value.length === 4 &&
    value.charAt(1) === ':' &&
    !Number.isNaN(value.slice(0, 1)) &&
    !Number.isNaN(value.slice(2, 4)));

const containsOnlyNumbers = (value: string) => /^\d+$/.test(value);

const props = defineProps<{
  label?: string;
  modelValue: string;
  minValue?: string;
  maxValue?: string;
}>();

const emit = defineEmits(['update:modelValue']);

const isListVisible = ref(false);
const uniqueId = `input-${Math.round(Math.random() * 100000).toString()}`;

const localValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (isTimeString(value)) {
      emit('update:modelValue', value);
    } else if (value.length === 4 && containsOnlyNumbers(value)) {
      // The user has entered a value without the colon, eg 1200
      // We need to add the colon when emitting the value
      emit('update:modelValue', `${value.slice(0, 2)}:${value.slice(2)}`);
    }
  },
});

const minuteInterval = 5;

// The selected hour value and minute value
const hourValue = computed({
  get() {
    return Number.parseInt(localValue.value.slice(0, 2).replace(':', ''));
  },
  set(value: number) {
    localValue.value = `${addLeadingZero(value)}:${addLeadingZero(minuteValue.value)}`;
  },
});
const minuteValue = computed({
  get() {
    return Number.parseInt(localValue.value.slice(3, 5).replace(':', ''));
  },
  set(value: number) {
    localValue.value = `${addLeadingZero(hourValue.value)}:${addLeadingZero(value)}`;
  },
});

// Set default minium and maximum values
const minValue = computed(() => props.minValue || '00:00');
const maxValue = computed(() => props.maxValue || '23:59');

// The start and end hours and inside the list
const hourStart = computed(() =>
  Number.parseInt(minValue.value.slice(0, 2).replace(':', '')),
);
const hourEnd = computed(() =>
  Number.parseInt(maxValue.value.slice(0, 2).replace(':', '')),
);
const minuteStart = computed(() =>
  hourValue.value === hourStart.value
    ? Number.parseInt(minValue.value.slice(3, 5).replace(':', ''))
    : 0,
);
const minuteEnd = computed(() =>
  hourValue.value === hourEnd.value
    ? Number.parseInt(maxValue.value.slice(3, 5).replace(':', ''))
    : 60,
);

// All the hours and minutes in the list
const hours = computed(() => {
  const length = hourEnd.value - hourStart.value + 1;
  return Array.from({ length }, (x, i) => hourStart.value + i);
});
const minutes = computed(() => {
  const length = (minuteEnd.value - minuteStart.value + 1) / minuteInterval;
  return Array.from(
    { length },
    (x, i) => minuteStart.value + i * minuteInterval,
  );
});

// If the first item in the list becomes higher then the selected value, update the selected value
// Also do this when blurring the input
const setMinHourValue = () => {
  if (hours.value[0] > hourValue.value) {
    hourValue.value = hours.value[0];
  }
};

watch(
  () => hours.value.length,
  () => {
    setMinHourValue();
  },
);

const setMinMinuteValue = () => {
  if (minutes.value[0] > minuteValue.value) {
    minuteValue.value = minutes.value[0];
  }
};

watch(
  () => minutes.value.length,
  () => {
    setMinMinuteValue();
  },
);

const setMaxValues = () => {
  const maxHour = hours.value[hours.value.length - 1];
  if (hourValue.value > maxHour) {
    hourValue.value = maxHour;
  }

  const maxMinute = minutes.value[minutes.value.length - 1] + 4; // Add 4 to get the actual max value, since when the last minute value is 55, we still want to allow a manual input of 59
  if (minuteValue.value > maxMinute) {
    minuteValue.value = maxMinute;
  }
};

const onBlur = () => {
  setMinHourValue();
  setMinMinuteValue();
  setMaxValues();
};

// Wether or not the user has clicked on the hours and minutes
// When both get set to true, close the list
const hourClicked = ref(false);
const minuteClicked = ref(false);

watchEffect(() => {
  if (hourClicked.value && minuteClicked.value) {
    isListVisible.value = false;
    hourClicked.value = false;
    minuteClicked.value = false;
  }
});

// Set correct scroll positions when opening the list and when selecting items
const hourList = ref();
const hourSegmentsWrap = { el: ref([]) };
const hourSegments = computed(() => hourSegmentsWrap.el.value);

const minuteList = ref();
const minuteSegmentsWrap = { el: ref([]) };
const minuteSegments = computed(() => minuteSegmentsWrap.el.value);

const setScrollPosition = (animated = false) => {
  if (
    !hourList.value ||
    !hourSegments.value ||
    !minuteList.value ||
    !minuteSegments.value
  ) {
    return;
  }

  const hourSegment = hourSegments.value[hours.value.indexOf(hourValue.value)];

  if (hourSegment) {
    const hourScrollTo = {
      top:
        hourSegment.offsetTop -
        hourList.value.offsetHeight / 2 +
        hourSegment.offsetHeight / 2,
    };
    if (animated) {
      hourScrollTo.behavior = 'smooth';
    }
    hourList.value.scrollTo(hourScrollTo);
  }

  const minuteSegment =
    minuteSegments.value[minutes.value.indexOf(minuteValue.value)];
  if (minuteSegment) {
    const minuteScrollTo = {
      top:
        minuteSegment.offsetTop -
        minuteList.value.offsetHeight / 2 +
        minuteSegment.offsetHeight / 2,
    };
    if (animated) {
      minuteScrollTo.behavior = 'smooth';
    }
    minuteList.value.scrollTo(minuteScrollTo);
  }
};

watch(isListVisible, (isVisible) => {
  if (isVisible) {
    nextTick(() => {
      setScrollPosition();
    });
  }
});
watch(
  () => localValue.value,
  () => setScrollPosition(true),
);
</script>

<style lang="scss" module>
.base {
  position: relative;
  width: 62px;
  flex-shrink: 0;
}

.input {
  @include input;

  .base.expanded & {
    border-color: $color-primary;
  }
}

.list {
  position: absolute;
  left: 0;
  top: 100%;
  max-width: 110px !important;
  overflow: hidden;
}

.listInner {
  display: flex;
  margin: $spacing * -1;
}

.listSegment {
  width: 50%;
  height: 120px;
  overflow-y: auto;
}

$duration: 0.07s;
$easing: ease-out;

.timeSegment {
  position: relative;
  text-align: center;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:not(.isActive) {
    @include hover {
      &:after {
        background-color: $color-highlight;
      }
    }
  }

  &.isActive {
    color: $color-text-inverted;

    &:after {
      background-color: $color-primary;
      transform: scale(0.7);
      border-radius: $radius;
    }
  }
}

.timeSegmentInner {
  position: relative;
  padding: $spacing;
  z-index: 1;
}
</style>
