<template>
  <div
    :class="[
      $style.base,
      {
        [$style.zebra]: zebra,
        [$style.hasFooter]: hasFooter
      }
    ]"
  >
    <div v-if="hasHeader" :class="$style.action" />
    <div
      v-for="(row, index) in rows"
      :key="`action-${index}`"
      :class="[
        $style.action,
        {
          [$style.isEmpty]: !row.actions || !row.actions.length
        }
      ]"
    >
      <BaseMore
        v-if="row.actions && row.actions.length"
        :options="row.actions"
        @select="$emit('action', { id: row.id, option: $event })"
        v-test="'_base-table-more'"
      />
    </div>
    <div v-if="hasFooter" :class="$style.action" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    rows: {
      type: Array,
      required: true,
    },
    zebra: {
      type: Boolean,
      default: false,
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['action'],
});
</script>

<style lang="scss" module>
$cellHeight: 55px;

@mixin white {
  background-color: white;

  &:before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
}

@mixin gray {
  background-color: $grey-light;

  &:before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), $grey-light);
  }
}
.action {
  position: relative;
  height: $cellHeight;
  display: flex;
  align-items: center;

  .base.zebra & {
    &:nth-child(odd) {
      @include white;
    }

    &:nth-child(even) {
      @include gray;
    }
  }

  .base.hasFooter & {
    &:last-child {
      @include gray;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    width: $spacing;
  }
}
</style>
