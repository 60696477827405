<template>
  <BaseModal
    :heading="$t('waiting_list.disable_waiting_list')"
    small
    @close="$emit('close')"
  >
    <BaseText bold>
      {{ $t('waiting_list.disable_modal.heading') }}
    </BaseText>
    <BaseText>
      {{ $t('waiting_list.disable_modal.info') }}
    </BaseText>
    <template #footer>
      <BaseButton color="inverted" @click="$emit('close')">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton @click="onDisable" v-test="'disableWaitingList'">
        {{ $t('waiting_list.disable_waiting_list') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';
import { mapActions } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  emits: ['close'],
  methods: {
    ...mapActions(useCompanyStore, ['updateCompany']),
    onDisable() {
      const input = {
        settings: {
          bookings: {
            waitingListEnabled: false,
          },
        },
      };

      const text = this.$t('global.flash.waiting_list_disabled');

      this.updateCompany(input).then(() => {
        this.mixpanel.track('Waiting list disabled');
        flash(text);
        this.$emit('close');
      });
    },
  },
});
</script>
