<template>
  <VariablesModal
    :loading="loading"
    :variables="variables"
    @close="$emit('close')"
  />
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import VariablesModal from '@/modules/_shared/VariablesModal.vue';

defineEmits(['close']);

const templateId = inject<any>('templateId');

const { result, loading } = useQuery(
  gql`
    query getCompanyEmailTemplate($id: ID!) {
      companyEmailTemplate(id: $id) {
        id
        variables
      }
    }
  `,
  {
    id: templateId.value,
  },
);

const variables = computed(
  () => result.value?.companyEmailTemplate?.variables || [],
);
</script>
