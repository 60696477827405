<template>
  <BaseCard gray v-test="'feedback-reply-new'">
    <BaseForm @submit="submit">
      <BaseHeading mb>
        {{ filters.capitalize($t('global.items.reply', 1)) }}
      </BaseHeading>
      <BaseInput
        v-model="message"
        type="textarea"
        :placeholder="$t('feedback.reply_placeholder')"
        required
        mb
        v-test="'feedback-reply-message'"
      />
      <div :class="$style.bottom">
        <div>
          <BaseCheckbox
            v-if="allowPublish"
            v-model="isPublic"
            :disabled="selected.source === FeedbackSource.Treatwell"
            :label="$t('feedback.public_reply')"
            :info="$t('feedback.public_reply_description')"
            :tooltip="
              selected.source === FeedbackSource.Treatwell
                ? {
                    position: 'right',
                    text: $t('feedback.tw_reviews_always_public')
                  }
                : null
            "
            v-test="'feedback-reply-public'"
          />
        </div>
        <div :class="$style.buttons">
          <BaseButton
            color="inverted"
            @click="emit('close')"
            v-test="'feedback-reply-cancel'"
          >
            {{ $t('global.actions.cancel') }}
          </BaseButton>
          <BaseButton
            submitForm
            :loading="loading"
            v-test="'feedback-reply-submit'"
          >
            {{ $t('global.actions.reply') }}
          </BaseButton>
        </div>
      </div>
    </BaseForm>
  </BaseCard>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { ref, inject, computed } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { SEND_FEEDBACK_RESPONSE, UPDATE_FEEDBACK } from '../../graphql';
import dayjs from '@/dayjs';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { FeedbackSource } from '@/types';

const { t } = useI18n();

const emit = defineEmits(['close']);

const message = ref('');
const selected = inject<any>('selected');

const isPublic = ref(
  selected.value.messagesPublished ||
    selected.value.source === FeedbackSource.Treatwell,
);
const loading = ref(false);

const allowPublish = computed(
  () =>
    selected.value.state === 'published' ||
    selected.value.state === 'allowed' ||
    selected.value.source === FeedbackSource.Treatwell,
);

const submit = () => {
  loading.value = true;

  const { mutate, onDone } = useMutation(SEND_FEEDBACK_RESPONSE, () => ({
    variables: {
      input: {
        message: message.value,
        feedbackId: selected.value.id,
      },
    },
  }));

  mutate();

  onDone(() => {
    selected.value.messages.push({
      createdAt: dayjs().format(),
      message: message.value,
    });

    if (allowPublish.value) {
      const { mutate, onDone } = useMutation(UPDATE_FEEDBACK, () => ({
        variables: {
          input: {
            id: selected.value.id,
            messagesPublished: isPublic.value,
          },
        },
      }));

      mutate();

      onDone(
        ({
          data: {
            updateFeedback: {
              feedback: { messagesPublished },
            },
          },
        }) => {
          selected.value.messagesPublished = messagesPublished;

          emit('close');
          flash(t('global.flash.message_sent'));
        },
      );
    } else {
      emit('close');
      flash(t('global.flash.message_sent'));
    }
  });
};
</script>

<style lang="scss" module>
.bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $spacing;
}

.buttons {
  display: flex;
  margin-left: $spacing;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}
</style>
