<template>
  <FormElement
    v-bind="{
      modelValue,
      label,
      required,
      info,
      maxLength
    }"
    :customError="error"
    @error="hasError = $event"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's',
          [$style.hasError]: hasError,
          [$style.emptyList]:
            modelValue && !multipleValues
              ? options.length <= 1
              : !options.length,
          [$style.small]: small,
          [$style.hasExtraLabels]: hasExtraLabels,
          [$style.searchable]: isSearchable,
          [$style.grouped]: !!groups.length,
          [$style.disabled]: disabled,
          [$style.noOverflow]: noOverflow,
          [$style.multipleValues]: multipleValues,
          [$style.inverted]: inverted
        }
      ]"
    >
      <div v-show="loading" :class="$style.loader">
        <BaseSpinner inline />
      </div>
      <div v-show="!loading" ref="inner" :class="$style.inner">
        <div
          :class="$style.select"
          tabindex="0"
          @click="toggleList"
          v-test="'_base-dropdown-select'"
        >
          <div :class="$style.selectContent">
            <ItemContent
              v-if="selectedItem"
              :data="selectedItem"
              :warning="warning"
              :showResource="resources"
              :hideText="hideText"
              :selected="true"
            />
            <div v-else-if="placeholderIcon" :class="$style.placeholderIcon">
              <BaseIcon
                :name="placeholderIcon"
                v-test="'_base-dropdown-placeholder-icon'"
              />
            </div>
            <template v-else>
              {{ placeholder || $t('global.actions.select')
              }}{{
                multipleValues && modelValue.length
                  ? ` (${modelValue.length})`
                  : ''
              }}
            </template>
          </div>
          <div :class="$style.selectIcons">
            <BaseIcon
              v-if="hasError"
              name="alert"
              color="error"
              v-test="'_base-dropdown-alert'"
            />
            <div :class="$style.arrow">
              <BaseIcon name="arrow-down" />
            </div>
          </div>
        </div>
        <div
          v-if="isExpanded"
          v-show="isListVisible"
          ref="list"
          :class="$style.list"
          :style="
            offsetX
              ? {
                  left: offsetX + 'px'
                }
              : null
          "
          v-test="'_base-dropdown-list'"
        >
          <div :class="$style.listInner">
            <div ref="listScroll" :class="$style.listScroll" @scroll="onScroll">
              <div
                v-if="isSearchable"
                :class="$style.search"
                v-test="'_base-dropdown-search'"
              >
                <BaseSearch
                  v-model="searchQuery"
                  :small="small"
                  :focus="focusSearch"
                />
              </div>
              <div>
                <div
                  v-if="
                    isSearchable &&
                    searchQuery &&
                    options.length &&
                    !listItems.length
                  "
                  :class="$style.noResults"
                  v-test="'_base-dropdown-noresults'"
                >
                  {{ $t('global.no_results') }}
                </div>
                <div v-else-if="listItems.length">
                  <div
                    v-if="
                      multipleValues &&
                      (!maxLength || maxLength >= options.length) &&
                      !disableSelectAll
                    "
                    :class="$style.selectAll"
                  >
                    <BaseText
                      size="s"
                      :link="!allSelected"
                      @click="allSelected ? undefined : selectAll()"
                      >{{ $t('global.all') }}</BaseText
                    >
                    <BaseText
                      size="s"
                      :link="!noneSelected"
                      @click="noneSelected ? undefined : selectNone()"
                      >{{ $t('global.none') }}</BaseText
                    >
                  </div>
                  <div
                    v-for="(group, index) in listItems"
                    :key="`group-${group.value}-${index}`"
                    :class="$style.group"
                  >
                    <div
                      v-if="group.label"
                      :class="$style.groupHeading"
                      v-test="'_base-dropdown-grouplabel'"
                    >
                      <div
                        v-if="multipleValues"
                        :class="$style.groupHeadingBefore"
                      >
                        <BaseCheckbox
                          :modelValue="isGroupSelected(group)"
                          @update:modelValue="toggleGroup(group)"
                          v-test="'_base-dropdown-checkbox'"
                        />
                      </div>
                      <BaseHeading size="s">
                        {{ group.label }}
                      </BaseHeading>
                    </div>
                    <div
                      v-for="item in group.items"
                      :key="`item-${item.value}`"
                      :class="$style.listItem"
                      @click="onSelect(item)"
                      v-test="'_base-dropdown-item'"
                    >
                      <div :class="$style.listItemInner">
                        <div
                          v-if="multipleValues"
                          :class="$style.listItemBefore"
                        >
                          <BaseCheckbox
                            :modelValue="modelValue.includes(item.value)"
                            v-test="'_base-dropdown-checkbox'"
                          />
                        </div>
                        <ItemContent
                          :data="item"
                          :breakLines="breakLines"
                          :showResource="resources"
                          @action="
                            $emit('action', {
                              action: $event,
                              value: item.value
                            })
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="action in actions"
                    :key="action.id"
                    :class="$style.listItem"
                    @click="
                      $emit('action', {
                        action: $event,
                        value: action.id
                      })
                    "
                  >
                    <div :class="$style.listItemInner">
                      <BaseIcon
                        v-if="action.icon"
                        :name="action.icon"
                        :color="action.color ? action.color : 'default'"
                        :mr="0.5"
                      />
                      {{ action.label }}
                    </div>
                  </div>
                </div>
                <div v-else :class="$style.noResults">
                  {{ $t('global.no_results') }}
                </div>
              </div>
            </div>
            <div v-if="multipleValues" :class="$style.listBottom">
              <BaseButton
                @click="isExpanded = false"
                v-test="'_base-dropdown-button-close'"
              >
                {{ $t('global.ok') }}
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FormElement>
</template>

<script lang="ts">
import ItemContent from './ItemContent.vue';
import FormElement from '@/components/_shared/form-element/index.vue';

import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'BaseDropdown',
  components: {
    FormElement,
    ItemContent,
  },
  inheritAttrs: false,
  props: {
    label: {},
    modelValue: {
      type: [String, Number, Array, null] as PropType<
        string | number | unknown[] | null
      >,
      validator: (value) =>
        !Array.isArray(value) ||
        !value.find(
          (item) => typeof item !== 'string' && typeof item !== 'number',
        ),
    },
    options: {
      type: Array,
      default: () => [],
      validator: (options) => {
        let validKeys = true;
        const allowedKeys = [
          'label',
          'value',
          'icon',
          'labelExtra',
          'groupValue',
          'color',
          'actions',
          'warnings',
          'avatar',
        ];
        options.forEach((option) => {
          if (Object.keys(option).find((key) => !allowedKeys.includes(key))) {
            validKeys = false;
          }
        });

        return (
          validKeys &&
          (!options.length ||
            !options.find((item) => !item.label || item.value === undefined))
        );
      },
    },
    actions: {
      type: Array,
      default: () => [],
      validator: (actions) => {
        let validKeys = true;
        const allowedKeys = ['label', 'icon', 'color', 'id'];
        actions.forEach((action) => {
          if (Object.keys(action).find((key) => !allowedKeys.includes(key))) {
            validKeys = false;
          }
        });

        return (
          validKeys && (!actions.length || !actions.find((item) => !item.label))
        );
      },
    },
    info: {
      type: String,
      default: '',
    },
    groups: {
      type: Array,
      default: () => [],
      validator: (value) =>
        !value.length ||
        !value.find((item) => !item.label || item.value === undefined),
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    resources: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchAutoFocus: {
      type: Boolean,
      default: false,
    },
    hideText: {
      type: Boolean,
      default: false,
    },
    breakLines: {
      type: Boolean,
      default: false,
    },
    scrollContainer: {
      type: HTMLElement as PropType<HTMLElement>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noOverflow: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxLength: Number,
    warning: Object,
    customSearch: Boolean,
    error: {
      type: String,
      default: '',
    },
    disableSelectAll: Boolean,
    inverted: Boolean,
  },
  emits: ['update:modelValue', 'action', 'scrolledToBottom', 'search'],
  data() {
    return {
      isListVisible: false,
      isExpanded: false,
      searchQuery: '',
      focusSearch: false,
      hasError: false,
      offsetX: 0,
      searchTimeout: 0,
    };
  },
  watch: {
    searchQuery(value) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(
        () => {
          this.$emit('search', value);
        },
        value ? 300 : 0,
      );
    },
    isListVisible(value) {
      if (value) {
        this.$refs.listScroll.scrollTop = 0;

        this.$nextTick(() => {
          this.setPosition();
        });
      } else {
        this.searchQuery = '';
        this.resetPosition();
      }

      if (this.searchAutoFocus) {
        // Nexttick needed to make sure the property gets set after mounting the list element
        this.$nextTick(() => {
          this.focusSearch = value;
        });
      }

      // Timeout is needed to prevent event bubbling, so that onDocumentClick doesn't get triggered on the initial click
      setTimeout(() => {
        value
          ? document.addEventListener('click', this.onDocumentClick)
          : document.removeEventListener('click', this.onDocumentClick);
      }, 0);
    },
    disabled(value) {
      // The dropdown could become disabled when selecting multiple items in the list.
      // In that case the list should close so that the user cannot select more items.

      if (value) {
        this.isExpanded = false;
      }
    },
  },
  computed: {
    multipleValues() {
      return Array.isArray(this.modelValue);
    },
    isSearchable() {
      return this.searchable || (this.resources && this.options.length > 6);
    },
    selectedItem() {
      return this.options.find((item) => item.value === this.modelValue);
    },
    listItems() {
      const items = this.options.filter(
        (item) =>
          item.value !== this.modelValue &&
          (!this.searchQuery ||
            this.customSearch ||
            item.label.toLowerCase().includes(this.searchQuery.toLowerCase())),
      );

      const groupedData = this.groups.length
        ? this.groups
            .map((group) => ({
              ...group,
              items: items.filter((item) => item.groupValue === group.value),
            }))
            .filter((group) => group.items.length)
        : items.map((item) => ({
            value: item.value,
            items: [item],
          }));

      return groupedData;
    },
    hasExtraLabels() {
      return !!this.options.find((option) => option.labelExtra);
    },
    noneSelected() {
      return !this.modelValue.length;
    },
    allSelected() {
      return this.modelValue.length === this.options.length;
    },
  },
  methods: {
    isGroupSelected(group) {
      return group.items.every((item) => this.modelValue.includes(item.value));
    },
    toggleGroup(group) {
      const modelValueWithoutGroup = this.modelValue.filter(
        (id) => !group.items.find((item) => item.value === id),
      );

      let newModelValue;

      if (this.isGroupSelected(group)) {
        newModelValue = modelValueWithoutGroup;
      } else {
        newModelValue = [
          ...modelValueWithoutGroup,
          ...group.items.map((item) => item.value),
        ];
      }

      this.$emit('update:modelValue', newModelValue);
    },
    selectAll() {
      this.$emit(
        'update:modelValue',
        this.options.map((option) => option.value),
      );
    },
    selectNone() {
      this.$emit('update:modelValue', []);
    },
    onScroll(e) {
      if (
        e.target.scrollTop + e.target.clientHeight + 40 >=
        e.target.scrollHeight
      ) {
        this.$emit('scrolledToBottom');
      }
    },
    showList() {
      this.isExpanded = true;
      this.$nextTick(() => {
        this.isListVisible = true;
      });
    },
    hideList() {
      this.isListVisible = false;
      this.$nextTick(() => {
        this.isExpanded = false;
      });
    },
    toggleList() {
      this.isListVisible ? this.hideList() : this.showList();
    },
    onSelect(item) {
      let newValue;

      if (this.multipleValues) {
        if (this.modelValue.includes(item.value)) {
          newValue = this.modelValue.filter((i) => i !== item.value);
        } else {
          newValue = [...this.modelValue, item.value];
        }
      } else {
        newValue = item.value;
        this.hideList();
      }

      this.$emit('update:modelValue', newValue);
    },
    onDocumentClick(e) {
      if (
        this.$refs.inner &&
        !e.target.isSameNode(this.$refs.inner) &&
        !this.$refs.inner.contains(e.target)
      ) {
        this.hideList();
      }
    },
    setPosition() {
      const rectEl = this.$refs.list.getBoundingClientRect();
      const rightPositionEl = rectEl.x + rectEl.width;

      let diff = 0;

      if (this.scrollContainer) {
        const rectContainer = this.scrollContainer.getBoundingClientRect();
        const rightPositionContainer = rectContainer.x + rectContainer.width;

        diff = Math.round(rightPositionEl - rightPositionContainer);
      } else {
        diff = Math.round(rightPositionEl - window.innerWidth);
      }

      const padding = 8; // 8 pixels for padding between the element and the container

      if (diff > 0) {
        let offsetX = 0;
        offsetX = diff * -1 - padding;

        if (offsetX * -1 > rectEl.x) {
          // If this is true, the popover will be placed outside of the viewport
          // Change the offset so it's placed inside of the viewport, with double padding (so it will also have padding inside modals, there is space between the modal and the viewport)
          offsetX = rectEl.x * -1 + padding * 2;
        }

        this.offsetX = offsetX;
      }
    },
    resetPosition() {
      this.offsetX = 0;
    },
  },
});
</script>

<style lang="scss" module>
$height: $input-height;
$heightSmall: 31px;

.base {
  text-align: left;

  &.small {
    font-size: 12px;
  }
}

.inner {
  position: relative;
}

.loader {
  height: $height;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid $color-border-input;
  border-radius: $radius;
  background-color: white;
  transition: border-color 0.1s ease-out;
  outline: none;

  .base:not(.emptyList) & {
    cursor: pointer;
  }

  .base.disabled & {
    pointer-events: none;
    opacity: 0.5;
  }

  .base:not(.small) & {
    height: $height;
  }

  .base.small & {
    height: $heightSmall;
  }

  .base.hasError & {
    border-color: $color-error;
  }

  &:focus {
    .base:not(.hasError):not(.emptyList) & {
      border-color: $color-primary;
    }
  }
}

.selectContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 5px 0 11px;
}

.placeholderIcon {
  display: flex;
  align-items: center;
}

.selectIcons {
  display: flex;
  align-items: center;
  padding-right: $spacing * 0.5;
  flex-shrink: 0;
}

.arrow {
  width: 20px $spacing;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .base.emptyList & {
    display: none;
  }
}

.list {
  position: absolute;
  left: 0;
  z-index: 10;
  max-width: 340px;

  .base:not(.hasExtraLabels) & {
    min-width: 100%;
  }

  .base.noOverflow & {
    max-width: 100%;
  }

  .base.hasExtraLabels & {
    min-width: 340px;
  }

  .base:not(.inverted) & {
    top: 100%;
    margin-top: $spacing * 0.5;
  }

  .base.inverted & {
    bottom: 100%;
    margin-bottom: $spacing * 0.5;
  }
}

.listInner {
  max-width: calc(100vw - #{$spacing * 2});
  border-radius: $radius;
  background: $white;
  box-shadow: $shadow;
  overflow: hidden;
}

.listScroll {
  overflow-y: auto;

  .base:not(.searchable) & {
    max-height: 180px;
  }

  .base.searchable & {
    max-height: 360px;
  }
}

.groupHeading {
  display: flex;
  align-items: center;
  padding: 0 $spacing $spacing * 0.5;
  margin-top: $spacing;
}

.search {
  padding: $spacing * 0.5;
}

.group {
  .base:not(.grouped) & {
    &:not(:first-child) {
      border-top: 1px solid $color-border;
    }
  }
}

.listItem {
  @include floating-list-item;
}

.listItemInner,
.noResults {
  @include floating-list-item-inner;

  .base:not(.small) & {
    min-height: $height;
  }

  .base.small & {
    min-height: $heightSmall;
  }
}

.listItemInner {
  .base.multipleValues.grouped & {
    padding-left: $spacing * 1.5;
  }
}

.listItemBefore,
.groupHeadingBefore {
  margin: 0 $spacing * 0.5 0 -5px;
}

.listItemBefore {
  pointer-events: none;
}

.listBottom {
  display: flex;
  justify-content: flex-end;
  padding: $spacing * 0.5;
  border-top: 1px solid $color-border;
}

.noResults {
  padding: $spacing;
}

.selectAll {
  display: flex;
  justify-content: flex-end;
  gap: $spacing * 0.5;
  padding: $spacing * 0.25 $spacing * 0.5;

  & > * {
    flex-shrink: 0;

    &:not(:first-child) {
      position: relative;
      margin-left: $spacing * 0.5;

      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        left: $spacing * -0.5;
        pointer-events: none;
        background-color: $color-border-input;
      }
    }
  }
}
</style>
