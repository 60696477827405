<template>
  <BaseModal
    :heading="$t('account.auth.two_factor_settings.confirmation_modal.heading')"
    small
  >
    <BaseText bold>{{
      $t('account.auth.two_factor_settings.confirmation_modal.important_info')
    }}</BaseText>
    <BaseText bold :mt="0.5">{{
      $t('account.auth.two_factor_settings.confirmation_modal.i_confirm')
    }}</BaseText>
    <BaseForm :mt="2">
      <BaseCheckbox
        v-model="confirmed"
        :label="
          $t('account.auth.two_factor_settings.confirmation_modal.im_aware')
        "
        v-test="'two-factor-confirm'"
      />
    </BaseForm>
    <template #footer>
      <BaseButton color="inverted" @click="$emit('close')">
        {{ $t('account.auth.two_factor_settings.confirmation_modal.cancel') }}
      </BaseButton>
      <BaseButton
        :disabled="!confirmed"
        @click="activateTwoFactor"
        v-test="'two-factor-activate'"
      >
        {{ $t('account.auth.two_factor_settings.activate') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['confirm', 'close'],
  data() {
    return {
      confirmed: false,
    };
  },
  methods: {
    activateTwoFactor() {
      if (this.confirmed) {
        this.$emit('confirm');
        this.$emit('close');
      }
    },
  },
});
</script>
