<template>
  <div
    v-if="
      unleash.isEnabled('SalonizedPay') &&
      adyenAppStateLoaded &&
      !adyenAppState.industryCode &&
      isExistingUser
    "
  >
    <BaseVisualBanner
      :heading="$t('dashboard.salonized_pay_banner.heading')"
      imagePath="/img/salonized-pay/1-percent.svg"
      variant="dark"
      :primaryAction="{
        text: $t('global.learn_more'),
        routerLink: { name: 'salonized-pay' }
      }"
      @primaryAction="
        () => {
          mixpanel.track('Salonized Pay ad', { step: '1%' });
        }
      "
      v-test="'salonized-pay-banner'"
    />
  </div>
</template>
<script lang="ts" setup>
import dayjs from '@/dayjs';
import { useAdyenStore } from '@/stores/adyen';
import { useCompanyStore } from '@/stores/company';
import unleash from '@/unleash';
const mixpanel = inject<any>('mixpanel');

const { adyenAppState, adyenAppStateLoaded } = useAdyenStore();
const { company } = useCompanyStore();
const isExistingUser = computed(
  () => dayjs('2024-06-25').diff(dayjs(company.trialEndAt), 'day') > 0,
);
</script>
