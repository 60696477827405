<template>
  <BaseAlert
    v-if="
      company.blockReason === BlockReason.PausedCollection &&
      company.toBeBlockedAt &&
      !hasUnpaused
    "
    :text="
      t('admin.cards.subscription_paused.message', {
        days: dayjs(company.toBeBlockedAt).diff(dayjs(), 'day')
      })
    "
    :primaryAction="
      t('admin.cards.subscription_paused.reactivate_subscription')
    "
    mb
    color="warning"
    @primaryAction="reactivatePausedAccount"
    v-test="'billing-subscription-paused-alert'"
  />
</template>

<script lang="ts" setup>
import { useMutation } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';

import { BlockReason } from '@/types';
import { flash, modal } from '@/helpers/ui';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';
import { UNPAUSE_SUBSCRIPTION_MUTATION } from './cancel-subscription/graphql';

const isLoadingMutation = ref(false);
const hasUnpaused = ref(false);
const { t } = useI18n();
const { company } = useCompanyStore();

const mixpanel = inject<any>('mixpanel');

const reactivatePausedAccount = () => {
  mixpanel.track('Reactivate subscription link clicked');

  if (isLoadingMutation.value) {
    return;
  }

  modal('confirmation', {
    message: t(
      'admin.cards.subscription_paused.reactivate_subscription_confirmation',
    ),
  }).then(() => {
    const { mutate } = useMutation(UNPAUSE_SUBSCRIPTION_MUTATION);
    isLoadingMutation.value = true;
    mutate({ input: {} })
      .then((data) => {
        if (!data?.errors) {
          flash(t('admin.cards.subscription_paused.reactivation_message'));
          const { getUser } = useUserStore();
          getUser();
          hasUnpaused.value = true;
        }
      })
      .finally(() => {
        isLoadingMutation.value = false;
      });
  });
};
</script>
