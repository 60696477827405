<template>
  <div
    :class="[
      $style.base,
      {
        [$style.breakLines]: breakLines,
        [$style.selected]: selected
      }
    ]"
    v-test="`item-content-${data.value}`"
  >
    <div v-if="resource || data.icon || data.color" :class="$style.beforeText">
      <BaseAvatar
        v-if="resource"
        :key="data.value"
        :resource="resource"
        size="s"
        v-test="'_base-dropdown-avatar'"
      />
      <BaseIcon
        v-else-if="data.icon"
        :name="data.icon"
        v-test="'_base-dropdown-icon'"
      />
      <BaseColorLabel
        v-else-if="data.color"
        :modelValue="data.color"
        small
        v-test="'_base-dropdown-color'"
      />
    </div>
    <BaseAvatar
      v-if="data.avatar"
      :picture="data.avatar.picture"
      :size="data.avatar.size"
      :name="data.avatar.name"
      :color="data.avatar.color"
      v-test="'_base-dropdown-picture-avatar'"
    />
    <div v-if="!hideText" :class="$style.label" v-test="'_base-dropdown-label'">
      {{ data.label }}
    </div>
    <div v-if="!selected && data.labelExtra" :class="$style.labelExtra">
      {{ data.labelExtra }}
    </div>
    <div v-if="warnings.length" :class="$style.warnings">
      <BaseIcon
        v-for="(warning, index) in warnings"
        :key="`warning-${index}`"
        :name="warning.icon"
        :tooltip="warning.tooltip"
        :color="warning.color"
        size="s"
        v-test="`_base-dropdown-warning-${warning.icon}`"
      />
    </div>
    <div
      v-if="!selected && data.actions && data.actions.length"
      :class="$style.actions"
    >
      <div
        v-for="(action, index) in data.actions"
        :key="`action-${index}`"
        @click.stop="$emit('action', action)"
        v-test="`_base-dropdown-item-${action}`"
      >
        <BaseIcon :name="action" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useResourcesStore } from '@/stores/resources';

import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: [
    'data',
    'showResource',
    'breakLines',
    'selected',
    'hideText',
    'warning',
  ],
  emits: ['action'],
  setup() {
    const { resourceById } = useResourcesStore();
    return { resourceById };
  },
  computed: {
    warnings() {
      return this.warning ? [this.warning] : this.data.warnings || [];
    },
    resource() {
      if (this.showResource && this.data.value) {
        return this.resourceById(this.data.value);
      } else {
        return null;
      }
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;

  & > * {
    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }
}

.beforeText {
  width: 20px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-left: -4px;
}

.label {
  width: 100%;
  min-width: 0; // Needed for correct "oneLine" width

  .base:not(.breakLines) & {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .base:not(.selected) & {
    // Pretty hacky, but this fixes an issue in Safari where it applies the ellipsis incorrectly when the list has a scrollbar.
    // This basically makes the content wider, which gets rid of the ellipsis.
    // Will add extra "padding" on the right side, but that's ok.
    &:after {
      content: '';
      display: inline-block;
      width: 8px;
    }
  }
}

.labelExtra {
  margin-left: $spacing;
  opacity: 0.5;
}

.actions {
  padding: $spacing * 0.5;
  margin: $spacing * -0.5 $spacing * -1 $spacing * -0.5 $spacing * -0.5;
}

.warnings {
  display: flex;
  align-items: center;

  .base:not(.selected) & {
    margin-right: $spacing * -0.5;
  }

  & > * {
    margin-left: $spacing * 0.25;
  }
}
</style>
