<template>
  <div>
    <Actions :date="date" :items="entries" @setDate="date = $event" />
    <EntriesList :items="entries" :date="date" :loading="isLoading" />
  </div>
</template>

<script lang="ts" setup>
import Actions from '@/modules/_shared/waiting-list/Actions.vue';
import EntriesList from '@/modules/_shared/waiting-list/EntriesList.vue';
import { useEntriesQuery } from '@/modules/_shared/waiting-list/helpers';
import { useRouteDate } from '@/helpers/routing';

const { date } = useRouteDate({
  updateRouteQuery: true,
});

const { entries, isLoading } = useEntriesQuery(date);
</script>
