<template>
  <div>
    <BaseDropdown
      v-model="resource_id"
      :options="
        employees.map((e) => ({
          value: e.id,
          label: e.name
        }))
      "
      :default="$t('global.items.employee', 1)"
      resources
      :label="$t('global.items.employee', 1)"
      @update:modelValue="emitValue"
      v-test="'treated-by'"
    />
  </div>
</template>

<script lang="ts">
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      resource_id: null,
    };
  },
  computed: {
    ...mapState(useResourcesStore, ['employees', 'resourceById']),
  },
  methods: {
    emitValue(selected) {
      let values;
      let employee;

      if (selected) {
        values = [this.resource_id];
        employee = this.resourceById(this.resource_id).name;
      }
      const obj = {
        filter_name: 'treated_by',
        employee,
        values,
      };
      this.$emit('selected', obj);
    },
  },
  created() {
    this.emitValue(null);
  },
});
</script>
