<template>
  <BaseModal
    :heading="$t('global.actions.update_stock')"
    small
    :parentRoute="parentRouteName"
    @close="$emit('close')"
  >
    <BaseGrid v-if="product" container>
      <BaseGrid :size="12">
        <BaseHeading mb>
          {{ product.name }}
        </BaseHeading>
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseHeading size="s" :mb="0.5">
          {{ $t('global.actions.update') }}
        </BaseHeading>
        <BaseRadio
          v-model="selectedValue"
          :options="options"
          v-test="'updateStockRadio'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseInput
          v-model="amount"
          :label="$t('products.amount_of_products')"
          type="number"
          :minValue="0"
          controls
          v-test="'updateStockAmount'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseDropdown
          v-model="selectedReason"
          :label="$t('products.reason_for_change')"
          :options="reasons"
          v-test="'update-stock-reason'"
        />
      </BaseGrid>
      <BaseGrid v-if="selectedReason === 'custom'" :size="6">
        <BaseInput
          v-model="customReason"
          focus
          :label="$t('products.custom_reason')"
          v-test="'updateStockCustomReason'"
        />
      </BaseGrid>
    </BaseGrid>
    <BaseSpinner v-if="$apollo.loading" />
    <template #footer>
      <BaseButton color="inverted" @click="routeSwitch">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="isLoading"
        @click="submit"
        v-test="'updateStockSubmit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import { productFragment } from '../graphql';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    parentRouteName: String,
  },
  emits: ['close'],
  data() {
    return {
      parentRoute: 'products',
      product: null,
      selectedValue: 'adding',
      selectedReason: 'no_reason',
      customReason: '',
      amount: 0,
      isLoading: false,
      options: [
        {
          label: this.$t('products.adding_products'),
          value: 'adding',
        },
        {
          label: this.$t('products.removing_products'),
          value: 'removing',
        },
      ],
      reasons: [
        {
          label: this.$t('products.reasons.no_reason'),
          value: 'no_reason',
        },
        {
          label: this.$t('products.reasons.salon_usage'),
          value: 'salon_usage',
        },
        {
          label: this.$t('products.reasons.gift'),
          value: 'gift',
        },
        {
          label: this.$t('products.reasons.damage'),
          value: 'damage',
        },
        {
          label: this.$t('products.reasons.new_stock'),
          value: 'new_stock',
        },
        {
          label: this.$t('global.custom'),
          value: 'custom',
        },
      ],
    };
  },
  apollo: {
    product: {
      query: gql`
        query getProduct($locationId: ID, $id: Int!) {
          product(locationId: $locationId, id: $id) {
            ...fullProduct
          }
        }
        ${productFragment}
      `,
      skip() {
        return !this.$route.params.id;
      },
      variables() {
        return {
          id: Number.parseInt(this.$route.params.id),
          locationId: this.locationId,
        };
      },
    },
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId']),
  },
  methods: {
    routeSwitch() {
      if (this.parentRouteName) {
        this.$router.push({ name: this.parentRouteName });
      } else {
        this.$emit('close');
      }
    },
    setReason(selected) {
      this.selectedReason = selected.id;
    },
    submit() {
      this.isLoading = true;

      const amount =
        this.selectedValue === 'removing' ? -this.amount : this.amount;
      let reason = this.selectedReason;

      if (this.selectedReason === 'custom') {
        reason = this.customReason;
      }

      const input = {
        id: this.product.id,
        locationId: this.locationId,
        stockMutation: {
          amount,
          reason,
        },
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateProduct($input: UpdateProductInput!) {
              updateProduct(input: $input) {
                product {
                  ...fullProduct
                }
              }
            }
            ${productFragment}
          `,
          variables: {
            input,
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'stockMutations' });
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'productCalculations' });
            cache.gc();
          },
        })
        .then(() => {
          this.routeSwitch();
          flash(this.$t('global.flash.product_updated'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>
