<template>
  <BasePopover
    :class="$style.base"
    :position="position"
    v-test="'calendar-popover-create'"
  >
    <BaseFloatingList
      :class="$style.list"
      selectKey="label"
      :data="
        (
          [
            {
              name: 'note',
              label: filters.capitalize($t('global.items.note', 1))
            },
            {
              name: 'absence',
              label: filters.capitalize($t('global.items.absence', 1))
            },
            {
              name: 'chore',
              label: filters.capitalize($t('global.items.chore', 1))
            },
            {
              name: 'waiting-list',
              label: $t('calendar.waiting_list'),
              hide:
                !hasFeatureFlag('waiting-list') ||
                !companySettings.bookings?.waitingListEnabled
            }
          ] as PopOverOptions[]
        ).filter((item) => !item.hide)
      "
      @select="onSelect"
    />
  </BasePopover>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { useCreateEventStore } from '@/stores/calendar-create-event';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';
import type { LocationQueryRaw } from 'vue-router';

interface PopOverOptions {
  name: string;
  label: string;
  hide?: boolean;
}

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    return {
      filters,
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    ...mapState(useUserStore, ['hasFeatureFlag']),
  },
  methods: {
    onSelect({ name }: PopOverOptions) {
      const { setEventData } = useCreateEventStore();
      const { resourceId } = useCalendarFiltersStore();

      const date = dayjs.tz(this.data.date);

      setEventData({
        startDate: date.format(),
        resourceId: this.data.resourceId,
        allDay: true,
      });

      if (name === 'waiting-list') {
        const query: LocationQueryRaw = {
          date: date.format('YYYY-MM-DD'),
        };

        const routeResourceId = this.data.resourceId || resourceId;
        if (routeResourceId) {
          query.resourceId = routeResourceId;
        }

        this.$router.push({
          name: 'calendar-waiting-list-new',
          query,
        });
      } else {
        this.$router.push({ name: `create-${name}` });
      }

      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" module>
.base {
  width: 120px !important;
}

.list {
  top: 0 !important;
}
</style>
