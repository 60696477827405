<template>
  <div :class="$style.base">
    <DiscountInfo v-if="showDiscountInfo" @close="showDiscountInfo = false" />
    <BaseGrid v-if="lastMinuteDiscounts && !$apollo.loading" container>
      <BaseGrid :size="8">
        <BaseHeading :mb="0.5">
          {{ $t('marketing.discounts.last_minute.title') }}
        </BaseHeading>
        <div v-if="!lastMinuteDiscounts.enabled">
          <BaseText>
            {{ $t('marketing.discounts.last_minute.body') }}
          </BaseText>
          <BaseText
            mt
            link
            @click="showDiscountInfo = true"
            v-test="'lastMinuteDiscountInfo'"
          >
            {{ $t('marketing.discounts.more_tips') }}
          </BaseText>
        </div>
        <div v-else>
          <BaseText mb>
            {{
              $t('marketing.discounts.last_minute.body_enabled', {
                percentage: lastMinuteDiscounts.discountPercentage
              })
            }}
            {{
              $t(
                `marketing.discounts.booking_range.${lastMinuteDiscounts.discountWindow.toLowerCase()}`
              ).toLowerCase()
            }}
          </BaseText>
          <router-link
            :class="$style.link"
            :to="{ name: 'disable-last-minute-discount' }"
            v-test="'disableLastMinuteDiscounts'"
          >
            {{ $t('global.actions.disable') }}
          </router-link>
          <router-link
            :class="$style.link"
            :to="{ name: 'enable-last-minute-discount' }"
            v-test="'editLastMinuteDiscounts'"
          >
            {{ $t('global.actions.edit') }}
          </router-link>
        </div>
      </BaseGrid>
      <BaseGrid :size="4" alignCenter alignRight>
        <BaseButton
          v-if="!lastMinuteDiscounts.enabled"
          color="inverted"
          @click="onEnableClick"
          v-test="'enableLastMinuteDiscounts'"
        >
          {{ $t('marketing.discounts.last_minute.enable') }}
        </BaseButton>
        <BaseAlert
          v-else-if="
            lastMinuteDiscounts.enabled && lastMinuteDiscounts.bookings.length
          "
          :text="`🎉 ${$t('marketing.discounts.last_minute.bookings', { amount: lastMinuteDiscounts.bookings }, lastMinuteDiscounts.bookings.length === 1 ? 1 : 2)}`"
          v-test="'discountAmount'"
        />
      </BaseGrid>
    </BaseGrid>
    <BaseSpinner v-else inline mb />
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import DiscountInfo from '@/modules/marketing/modals/DiscountInfo.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    DiscountInfo,
  },
  data() {
    return {
      showDiscountInfo: false,
    };
  },
  methods: {
    onEnableClick() {
      this.$router.push({ name: 'enable-last-minute-discount' });
    },
  },
  apollo: {
    lastMinuteDiscounts: {
      query: gql`
        query getLastMinuteDiscounts {
          lastMinuteDiscounts {
            enabled
            discountPercentage
            discountWindow
            bookings
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  },
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing 0;
  border-bottom: 1px solid $color-border;
}

.link {
  color: $color-primary !important;
  margin-right: $spacing;
}
</style>
