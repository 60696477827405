<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.bottomFavorites]: favorites.length > 6
      }
    ]"
    :style="
      $screen === 's' ? { 'padding-bottom': footerHeight + 'px' } : undefined
    "
  >
    <div :class="$style.header">
      <div :class="$style.search">
        <BaseSearch
          v-model="searchQuery"
          :placeholder="$t('register.search_items')"
          focus
          debounce
          v-test="'register-browser-search'"
        />
      </div>
      <BaseButton
        icon="barcode"
        color="inverted"
        @click="showBarcode = true"
        v-test="'register-browser-barcode'"
      />
    </div>
    <SubNav v-if="showSubNav" />
    <div :class="$style.scrollContainer">
      <SearchResults
        v-if="searchQuery"
        :searchQuery="searchQuery"
        @close="searchQuery = ''"
      />
      <Barcode v-else-if="showBarcode" @close="showBarcode = false" />
      <div v-else>
        <router-view />
        <Default v-if="!hasChildRoute" />
      </div>
    </div>
    <MobileControls v-if="$screen === 's'" ref="footer" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'RegisterBrowser',
};
</script>

<script setup lang="ts">
import { ref } from 'vue';
import Default from './pages/default/index.vue';
import SearchResults from './pages/SearchResults.vue';
import MobileControls from './MobileControls.vue';
import SubNav from './SubNav.vue';
import { useRoute } from 'vue-router';
import { useRegisterBrowserStore } from './store';
import { storeToRefs } from 'pinia';
import { useElementBounding } from '@vueuse/core';
import Barcode from './pages/Barcode.vue';

const searchQuery = ref('');
const showBarcode = ref(false);

const route = useRoute();
const hasChildRoute = computed(() => route.name !== 'register-builder');
const showSubNav = computed(
  () => hasChildRoute.value && !searchQuery.value && !showBarcode.value
);

const { favorites } = storeToRefs(useRegisterBrowserStore());

const footer = ref(null);
const { height: footerHeight } = useElementBounding(footer);
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding: $spacing $spacing 0;
  display: flex;
  gap: $spacing * 0.5;
}

.scrollContainer {
  height: 100%;
  overflow-y: auto;
  padding: $spacing * 1.5 $spacing $spacing;
}

.search {
  width: 100%;
}
</style>
