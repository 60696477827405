// Netherlands Regex
export const COMPANY_PHONE_VALIDATION_REGEX = /^[0-9+ ]{9,}$/;
export const RSIN_NUMBER_VALIDATION_REGEX = /^[0-9]{9}$/;
export const KVK_NUMBER_VALIDATION_REGEX = /^[0-9]{8}$/;
export const NL_VAT_NUMBER_VALIDATION_REGEX = /^NL\d{9}B\d{2}$/;

// Germany Regex
export const HRB_NUMBER_VALIDATION_REGEX =
  /^[A-Za-z]{2,3}\d{1,6}[A-Za-z]{0,5}(-[A-Za-z]\d{4})?$/;
export const DE_VAT_NUMBER_VALIDATION_REGEX = /^DE\s?[0-9]{9}$/;

// BE Regex
export const BE_REGISTRATION_NUMBER_REGEX = /^[0-9]{10}$/;
export const BE_VAT_NUMBER_REGEX = /^BE[0-9]{10}$/;
