<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.hasSelection]: !!selectedCustomers.length,
        [$style.fixedSubHeader]: fixedSubHeader
      }
    ]"
  >
    <ExportModal
      v-if="showExportModal"
      @submit="exportData"
      @close="showExportModal = false"
    />
    <TagModal
      v-if="showTagModal"
      :filters="_filters"
      :searchQuery="variables.query"
      @close="showTagModal = false"
    />
    <SendMessage
      v-if="showMessageModal"
      :filters="_filters"
      :searchQuery="variables.query"
      @close="showMessageModal = false"
    />
    <MessageType
      v-if="showMessageTypeModal"
      :filters="underscoredFilters"
      @openMessageModal="onSwitchModal"
      @close="showMessageTypeModal = false"
    />
    <MailChimpExport
      v-if="showMailChimpModal"
      :filters="_filters"
      :searchQuery="variables.query"
      @close="showMailChimpModal = false"
    />
    <div ref="headerEl" :class="$style.header">
      <div :class="$style.headerSection">
        <div :class="$style.querySection">
          <BaseSearch
            v-model="variables.query"
            debounce
            historyKey="customer-search-history"
            v-test="'customer-overview-search'"
          />
          <BaseButton
            ref="buttonElement"
            icon="options"
            color="inverted"
            :selected="showFiltersBar"
            :notification="underscoredFilters.length"
            :tooltip="$t('global.actions.add_filter')"
            @click="toggleFiltersBar"
            v-intercom="'customers-filter-button'"
            v-test="'toggleFiltersBar'"
          />
        </div>
      </div>
      <div :class="$style.headerSection">
        <BaseButton
          :disabled="!totalCount && !selectedCustomers.length"
          icon="more-horizontal"
          color="inverted"
          :options="
            [
              {
                value: 'import',
                label: $t('global.actions.import'),
                routerLink: { name: 'customers-imports' }
              },
              {
                value: 'export',
                label: $t('global.actions.export'),
                hide: !hasFeatureFlag('admin-export')
              },
              {
                value: 'export_mailchimp',
                label: $t('customers.export_mailchimp'),
                hide: !hasMailchimpIntegration
              }
            ].filter((option) => !option.hide)
          "
          @select="onAction"
          v-test="'customer-actions'"
        />
        <BaseButton
          :routerLink="{ name: 'customers-new' }"
          v-test="'customersAddCustomer'"
        >
          {{ $t('customers.create_customer') }}
        </BaseButton>
      </div>
    </div>
    <div
      :class="$style.subHeader"
      :style="fixedSubHeader ? { paddingLeft: navbarWidth + 'px' } : {}"
    >
      <div :class="$style.filterActions">
        <FilterActions
          :modelValue="underscoredFilters"
          :tableIsLoading="loading"
          :totalCount="totalCount"
          @update:modelValue="onFilterChange($event)"
        />
        <BaseButton
          v-if="!enableBatchAction"
          :disabled="disableMessageButton"
          color="inverted"
          icon="email"
          :tooltip="
            enableBatchAction
              ? $t('customers.selected_customers_actions.message')
              : $t('customers.send_message')
          "
          @click="
            hasFeatureFlag('module-marketing')
              ? (showMessageTypeModal = true)
              : (showMessageModal = true)
          "
          v-test="'messageCustomers'"
        />
      </div>
      <div :class="$style.selectedCustomers">
        <BaseAlert
          v-if="selectedCustomers.length"
          :text="
            $t(
              'customers.customers_selected',
              { amount: selectedCustomers.length },
              selectedCustomers.length
            )
          "
          :primaryAction="$t('customers.clear_selection')"
          @primaryAction="clearSelection"
        />
        <div :class="$style.filterActionsButtons">
          <BaseButton
            v-if="enableBatchAction"
            :disabled="disableMessageButton"
            color="inverted"
            icon="email"
            :tooltip="
              selectedCustomers.length
                ? $t('customers.customer_actions.message.selected')
                : $t('customers.customer_actions.message.all')
            "
            @click="
              hasFeatureFlag('module-marketing')
                ? (showMessageTypeModal = true)
                : (showMessageModal = true)
            "
            v-test="'messageCustomers'"
          />
          <BaseButton
            v-show="enableBatchAction"
            color="inverted"
            icon="pricetag"
            :tooltip="
              selectedCustomers.length
                ? $t('customers.customer_actions.tag.selected')
                : $t('customers.customer_actions.tag.all')
            "
            @click="onAction('tag')"
            v-test="'btn-customers-tag'"
          />
          <BaseButton
            v-show="!hasDeletedFilter && enableBatchAction"
            color="inverted"
            icon="delete"
            :tooltip="
              selectedCustomers.length
                ? $t('customers.customer_actions.delete.selected')
                : $t('customers.customer_actions.delete.all')
            "
            @click="onAction('delete')"
            v-test="'btn-customers-delete'"
          />
          <BaseButton
            v-show="hasDeletedFilter && enableBatchAction"
            color="inverted"
            icon="refresh"
            :tooltip="
              selectedCustomers.length
                ? $t('customers.customer_actions.restore.selected')
                : $t('customers.customer_actions.restore.all')
            "
            @click="onAction('restore')"
            v-test="'btn-customers-restore'"
          />
        </div>
      </div>
    </div>
    <div :class="$style.content">
      <FiltersBar
        v-show="showFiltersBar"
        ref="listElement"
        :savedFilters="false"
        :class="$style.negativeMargin"
        @setFilter="setFilter($event)"
        @close="showFiltersBar = false"
        @clearFilters="clearFilters"
      />
      <BaseSpinner v-if="loading && !firstFetchFinished" />
      <BaseTable
        v-if="customers.length"
        v-model="selectedCustomers"
        :headers="
          [
            $t('global.name'),
            showBirthDate ? $t('global.date_of_birth') : '',
            $t('global.mobile'),
            $t('global.email'),
            $t('global.newsletter'),
            $t('global.address')
          ].filter((header) => !!header)
        "
        :rows="
          customers.map((customer: any) => ({
            id: customer.id,
            routerLink:
              customer.state === 'DELETED'
                ? null
                : {
                    name: 'customer',
                    params: {
                      customerId: customer.id
                    }
                  },
            disabled: customer.state === 'DELETED',
            cells: [
              customer.fullName,
              showBirthDate ? filters.date(customer.dateOfBirth) : undefined,
              customer.mobilePhone,
              customer.email,
              !!customer.newsletterEnabled,
              customer.address
            ].filter((cell) => cell !== undefined),
            actions:
              customer.state === 'DELETED'
                ? ['restore']
                : [...(customer.mobilePhone ? ['call'] : []), 'edit', 'delete']
          }))
        "
        @select="setScrollPosition"
        @action="onActionClick"
        v-test="'customers-list'"
      />
      <EmptyPageContent
        v-show="!loading && allDataFetched && !customers.length"
        imageName="customers"
        :text="
          !variables.query && !variables.filter.length
            ? {
                title: $t('customers.empty_state.title'),
                description: $t('customers.empty_state.description'),
                btnPrimary: $t('customers.create_customer'),
                btnSecondary: $t('customers.import_customer_list')
              }
            : undefined
        "
        :moreLink="
          !variables.query && !variables.filter.length
            ? {
                text: $t('customers.empty_state.more_text'),
                url: $t('customers.empty_state.more_url')
              }
            : undefined
        "
        @click="onEmptyPageClick"
      />
      <LoadMore
        v-show="firstFetchFinished && !allDataFetched"
        @shown="fetchMore"
      />
      <BaseVisualBanner
        v-if="showTreatwellBanner"
        :heading="treatwellPromotionText"
        icon="treatwell"
        :bullets="[
          $t('customers.treatwell_banner.extra_bookings'),
          $t('customers.treatwell_banner.increase_awareness'),
          $t('customers.treatwell_banner.reach_customers')
        ]"
        :imagePath="treatwellImage"
        :mt="2"
        :primaryAction="$t('treatwell.landing.button_start')"
        @primaryAction="openTreatwellModal"
        v-test="'customers-treatwell-banner'"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CustomersOverview',
};
</script>

<script setup lang="ts">
import { ref, inject, computed, watch } from 'vue';
import axios from '@/axios';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import SendMessage from '@/modules/customers/SendMessage.vue';
import MessageType from './MessageType.vue';
import MailChimpExport from './MailChimpExport.vue';
import FilterActions from './FilterActions.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import FiltersBar from '@/components/customer-filters/FiltersBar.vue';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';
import { flash, modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useTreatwellStore } from '@/stores/treatwell';
import filters from '@/filters';
import { useCompanyStore } from '@/stores/company';
import {
  useSessionStorage,
  onClickOutside,
  useIntersectionObserver
} from '@vueuse/core';
import { useScrollCaching } from '@/helpers/scroll';
import type { CustomerStateEnum } from '@/types';
import eventBus from '@/event-bus';
import { addLabelIndex } from '@/helpers/formatting';
import ExportModal from './ExportModal.vue';
import {
  CREATE_CUSTOMERS_EXPORT,
  DELETE_CUSTOMERS,
  RESTORE_CUSTOMERS
} from './graphql';
import TagModal from './TagModal.vue';
import unleash from '@/unleash';
import dayjs from '@/dayjs';
import { usePageLayoutStore } from '@/stores/page-layout';
import { storeToRefs } from 'pinia';

const { setScrollPosition } = useScrollCaching();
const mixpanel = inject<any>('mixpanel');
const router = useRouter();
const { hasFeatureFlag } = useUserStore();

const showMailChimpModal = ref(false);
const showMessageModal = ref(false);
const showMessageTypeModal = ref(false);
const showExportModal = ref(false);
const showTagModal = ref(false);

const { result: currentUser } = useQuery(
  gql`
    query getCurrentUser {
      currentUser {
        company {
          integrations
        }
      }
    }
  `,
  null,
  {
    fetchPolicy: 'cache-first'
  }
);

const _searchQuery = useSessionStorage('customerOverviewSearchQuery', '');
const _filters = useSessionStorage('customerOverviewFilters', []);

const showFiltersBar = ref(false);
const toggleFiltersBar = () => {
  mixpanel.track('filters_opened');
  showFiltersBar.value = !showFiltersBar.value;
};

const {
  customersOverview,
  offset,
  variables,
  loading,
  allDataFetched,
  firstFetchFinished,
  refetch,
  fetchMore,
  onResult
} = usePagination({
  fieldName: 'customersOverview',
  query: gql`
    query customersOverview(
      $query: String
      $filter: [CustomersOverviewFilterAttributes!]
      $pagination: PaginationAttributes
    ) {
      customersOverview(
        query: $query
        filter: $filter
        pagination: $pagination
      ) {
        customers {
          address
          dateOfBirth
          email
          fullName
          id
          mobilePhone
          newsletterEnabled
          state
          totalDue
        }
        totalCount
      }
    }
  `,
  variables: {
    query: _searchQuery.value,
    filter: _filters.value
  },
  options: {
    offset: true,
    subFieldName: 'customers'
  }
});

eventBus.$on('customer-created', () => {
  refetch();
});

watch(
  () => variables.query,
  (query) => {
    _searchQuery.value = query;
    deletedCustomers.value = [];
  }
);

watch(
  () => variables.filter,
  (filters) => {
    _filters.value = filters;
    deletedCustomers.value = [];
  }
);

const deletedCustomers = ref<number[]>([]);

const customers = computed<
  {
    address: string;
    dateOfBirth: string;
    email: string;
    fullName: string;
    id: number;
    mobilePhone: string;
    newsletterEnabled: false;
    state: CustomerStateEnum;
  }[]
>(() => {
  const arr = customersOverview.value?.customers || [];
  if (deletedCustomers.value.length) {
    return arr.filter((c: any) => deletedCustomers.value.indexOf(c.id) === -1);
  } else {
    return arr;
  }
});

const totalCount = computed(() => customersOverview.value?.totalCount || 0);

const totalCustomers = ref(0);
const selectedCustomers = ref<number[]>([]);
provide('selectedCustomers', selectedCustomers);
provide('totalCount', totalCount);

const enableBatchAction = computed(
  () =>
    !!_filters.value.length || selectedCustomers.value.length || variables.query
);

onResult(() => {
  // When doing the first query without filters, the totalCount result will be the amount of customers in the account
  if (!totalCustomers.value && !variables.filter.length) {
    totalCustomers.value = totalCount.value;
  }
});

const showBirthDate = computed(
  () =>
    !!variables.filter.find(
      (filter: any) =>
        filter.filterName === 'BIRTHDAY_UPCOMING' ||
        filter.filterName === 'BIRTHDAY_BETWEEN'
    )
);

const showTreatwellBanner = computed(() => {
  const { showTreatwell, hasDashboard } = useTreatwellStore();
  const { isTreatwellUser } = useCompanyStore();
  return (
    firstFetchFinished.value &&
    showTreatwell &&
    !hasDashboard &&
    !isTreatwellUser &&
    totalCustomers.value < 20
  );
});

const openTreatwellModal = () => {
  mixpanel.track('Customers-TreatwellPromo');
  router.push({ name: 'treatwell-landing' });
};

const disableMessageButton = computed(() => {
  if (selectedCustomers.value.length) {
    // When a custom selection is made, always enable the button
    return false;
  }

  const { allowedToMassSend } = useCompanyStore();

  if (!allowedToMassSend) {
    // The company is not alled to mass send messages
    return true;
  } else if (
    !customers.value.find((customer: any) => customer.newsletterEnabled)
  ) {
    // None of the customers have the newsletter enabled
    return true;
  } else if (
    customers.value.find((customer: any) => customer.state === 'DELETED')
  ) {
    // There are one or more deleted customers in the list
    return true;
  }

  return false;
});

const setFilter = (filter: any) => {
  const filterToAdd = addLabelIndex(underscoredFilters.value, filter);
  variables.filter.push({
    filterName: filterToAdd.filter_name.toUpperCase(),
    values: filterToAdd.values,
    labelIndex: filterToAdd.labelIndex
  });
  underscoredFilters.value.push(filterToAdd);
};

const onFilterChange = (filters: any) => {
  variables.filter = filters.map((filter: any) => ({
    filterName: filter.filter_name.toUpperCase(),
    values: filter.values,
    labelIndex: filter.labelIndex
  }));

  underscoredFilters.value = filters;
};

const clearSelection = () => {
  mixpanel.track('selection_cleared');
  selectedCustomers.value = [];
};

const underscoredFilters = ref(
  _filters.value.map((filter: any) => ({
    filter_name: filter.filterName.toUpperCase(),
    values: filter.values,
    labelIndex: filter.labelIndex
  }))
);

provide('customerFilters', underscoredFilters);

const clearFilters = () => {
  variables.filters = [];
  underscoredFilters.value = [];
};

const hasMailchimpIntegration = computed(
  () => currentUser.value?.currentUser.company.integrations.mailchimp || false
);

const onAction = (option: string) => {
  mixpanel.track('batch_action_chosen', { action_name: option });
  switch (option) {
    case 'export_mailchimp':
      showMailChimpModal.value = true;
      mixpanel.track('Customers-ExportToMailchimp');
      break;
    case 'export':
      showExportModal.value = true;
      break;
    case 'import':
      mixpanel.track('Import - Customers');
      break;
    case 'tag':
      showTagModal.value = true;
      break;
    case 'delete':
    case 'restore':
      deleteRestoreCustomers();
      break;
  }
};

const hasDeletedFilter = computed(
  () =>
    !!underscoredFilters.value.find(
      (filter) =>
        filter.filter_name === 'deleted' || filter.filter_name === 'DELETED'
    )
);

const deleteRestoreCustomers = () => {
  const customerCount = selectedCustomers.value.length || totalCount.value;
  const action = hasDeletedFilter.value ? 'restore' : 'delete';

  modal('confirmation', {
    item: `${customerCount} ${t('global.items.customer', 2)}`,
    type: action === 'delete' ? 'reversibleDelete' : 'restore'
  }).then(() => {
    const { mutate } = useMutation(
      action === 'restore' ? RESTORE_CUSTOMERS : DELETE_CUSTOMERS
    );
    mutate({
      input: {
        customerIds: selectedCustomers.value,
        filters: _filters.value,
        query: variables.query
      }
    }).then(() => {
      selectedCustomers.value = [];
      refetch();
      flash(t(`global.flash.customers_${action}d`));
    });
  });
};

const lastExport = ref(dayjs.unix(0));
const lastExportType = ref('');

// Reset export timer
watch(
  () => [selectedCustomers, _filters, _searchQuery],
  () => {
    lastExport.value = dayjs.unix(0);
    lastExportType.value = '';
  },
  { deep: true }
);

const exportData = (type: string) => {
  if (unleash.isEnabled('AsyncExports')) {
    if (
      dayjs().diff(dayjs(lastExport.value), 'minute') < 5 &&
      lastExportType.value === type
    ) {
      mixpanel.track('export-requested-multiple-times');
      modal('warning', {
        message: t('customers.export_warning.message'),
        subMessage: t('customers.export_warning.subMessage', {
          email: company.notificationEmail
        })
      });
      return;
    }
    lastExport.value = dayjs();
    lastExportType.value = type;
    const { mutate } = useMutation(CREATE_CUSTOMERS_EXPORT);
    mutate({
      input: {
        customerIds: selectedCustomers.value,
        filters: _filters.value,
        query: variables.query,
        fileFormat: type.toUpperCase()
      }
    }).then(() => {
      flash(t('global.flash.customers_export_mailed'));
    });
  } else {
    axios({
      url: `vue/customers/filtered/download.${type}`,
      method: 'POST',
      responseType: 'blob',
      data: {
        customer_ids: selectedCustomers.value,
        filters: underscoredFilters.value,
        query: variables.query
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `customers.${type}`);
      document.body.appendChild(link);
      link.click();
      selectedCustomers.value = [];
    });
  }
};

const onSwitchModal = () => {
  showMessageTypeModal.value = false;
  showMessageModal.value = true;
};

const { t } = useI18n();

const onActionClick = ({ id, option }: { id: number; option: string }) => {
  const customer = customers.value.find((c: any) => c.id === id);

  switch (option) {
    case 'call':
      window.location.href = `tel:${customer.mobilePhone}`;
      break;
    case 'edit':
      router.push({ name: 'customers-edit', params: { id } });
      break;
    case 'delete':
      {
        modal('confirmation', {
          type: 'reversibleDelete',
          item: customer.fullName
        }).then(() => {
          const { mutate } = useMutation(
            gql`
              mutation deleteCustomer($input: DeleteCustomerInput!) {
                deleteCustomer(input: $input) {
                  customer {
                    id
                  }
                }
              }
            `,
            {
              variables: {
                input: {
                  id
                }
              }
            }
          );

          mutate().then(() => {
            offset.value--;
            deletedCustomers.value.push(id);
            flash(t('global.flash.customer_deleted'));
            selectedCustomers.value = selectedCustomers.value.filter(
              (customerId) => customerId !== id
            );
          });
        });
      }
      break;
    case 'restore':
      {
        const { mutate } = useMutation(
          gql`
            mutation restoreCustomer($input: RestoreCustomerInput!) {
              restoreCustomer(input: $input) {
                customer {
                  id
                }
              }
            }
          `,
          {
            variables: {
              input: {
                id
              }
            }
          }
        );

        mutate().then(() => {
          refetch();
          flash(t('global.flash.customer_restored'));
        });
      }
      break;
  }
};

const onEmptyPageClick = (button: string) => {
  if (button === 'primary') {
    router.push({ name: 'customers-new' });
  } else if (button === 'secondary') {
    router.push({ name: 'customers-imports' });
  }
};

const { company } = useCompanyStore();
const treatwellImage = computed(() => {
  switch (company.companyType) {
    case 'BARBERSHOP':
      return '/img/company-types/barbershop.svg';
    case 'NAIL_STUDIO':
    case 'MANI_PEDICURE':
      return '/img/company-types/nail_studio.svg';
    default:
      return '/img/treatwell/monitor.svg';
  }
});

const treatwellPromotionText = computed(() => {
  switch (company.companyType) {
    case 'BARBERSHOP':
      return t('customers.treatwell_banner.barbershop.heading');
    case 'NAIL_STUDIO':
    case 'MANI_PEDICURE':
      return t('customers.treatwell_banner.nail_studio.heading');
    default:
      return t('customers.treatwell_banner.heading');
  }
});

const listElement = ref();
const buttonElement = ref();

onClickOutside(
  listElement,
  () => {
    showFiltersBar.value = false;
  },
  { ignore: [buttonElement] }
);

const { navbarWidth } = storeToRefs(usePageLayoutStore());
const headerOutOfView = ref(false);
const headerEl = ref();
useIntersectionObserver(headerEl, ([entry]) => {
  headerOutOfView.value = !entry.isIntersecting;
});

const fixedSubHeader = computed(() => {
  const { screenSize } = usePageLayoutStore();
  return headerOutOfView.value && screenSize !== 's' && enableBatchAction.value;
});
</script>

<style lang="scss" module>
.header {
  justify-content: space-between;
  padding-bottom: $spacing * 0.5;

  .base.smallScreen & {
    flex-direction: column-reverse;
  }
}

.headerSection {
  margin-bottom: $spacing * 0.5;

  .base.smallScreen & {
    width: 100%;

    &:last-child {
      flex-direction: row-reverse;
      flex-wrap: nowrap;

      & > *:last-child {
        width: 100%;
        flex-shrink: 1;
      }
    }
  }
}

.querySection {
  display: flex;
  flex-direction: row;

  .base.smallScreen & {
    width: 100%;
  }

  & > *:first-child {
    flex-grow: 1;
    margin-right: $spacing * 0.5;
  }
}
.header,
.headerSection {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: $spacing * 0.5;
}

.content {
  position: relative;
}

.negativeMargin {
  .base:not(.smallScreen) & {
    margin-top: -$spacing;
    margin-left: -$spacing;
  }
}

.filterActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing * 0.5;
  width: 100%;

  .base.fixedSubHeader & {
    padding-left: $spacing;
  }
}

.filterActionsButtons {
  display: flex;
  gap: $spacing * 0.5;
}

.selectedCustomers {
  display: flex;
  align-items: center;
  gap: $spacing;

  & > *:first-child {
    width: 100%;
  }

  .base.hasSelection & {
    margin-top: $spacing;
  }

  .base.fixedSubHeader & {
    padding-left: $spacing;
  }

  .base.smallScreen & {
    flex-wrap: wrap;
  }
}

.subHeader {
  border-top: 1px solid $color-border;
  padding-top: $spacing;
  padding-bottom: $spacing;

  .base:not(.hasSelection) & {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .base.fixedSubHeader & {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding-right: $spacing;
    z-index: 10;
    background-color: white;
    border-bottom: 1px solid $color-border;
  }
}
</style>
