<template>
  <div v-if="timelineItems && timelineItems.length" v-test="'invoice-history'">
    <BaseHeading mb>
      {{ $t('global.history') }}
    </BaseHeading>
    <Timeline :data="timelineItems" />
  </div>
</template>

<script lang="ts">
import Timeline from '@/components/Timeline.vue';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Timeline,
  },
  apollo: {
    timelineItems: {
      query: gql`
        query getTimelineItems($recordId: ID!, $recordType: RecordType) {
          timelineItems(recordId: $recordId, recordType: $recordType) {
            changes
            createdAt
            customer {
              firstName
            }
            id
            key
            meta
            user {
              fullName
              id
              email
            }
          }
        }
      `,
      variables() {
        return {
          recordId: this.orderId,
          recordType: 'ORDER',
        };
      },
      skip() {
        return !this.orderId;
      },
    },
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
  },
});
</script>
