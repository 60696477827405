<template>
  <div>
    <div :class="$style.mainCard">
      <BaseCard>
        <BaseHeading size="xl" mb>
          {{ $t('treatwell.title') }}
        </BaseHeading>
        <BaseText size="l" :mb="2">
          {{ $t('treatwell.info') }}
        </BaseText>
        <div v-if="treatwellVenueTypes" :class="$style.infoTop">
          <div>
            <BaseHeading size="s" mb>
              {{ $t('treatwell.type.salonized') }}
            </BaseHeading>
            <BaseText v-test="'tw-salon-type'">
              {{ company.companyType }}
            </BaseText>
          </div>
          <div>
            <BaseHeading size="s" mb>
              {{ $t('treatwell.type.treatwell') }}
            </BaseHeading>
            <BaseDropdown
              v-model="venueType"
              :options="
                treatwellVenueTypes.map((t: string) => ({
                  value: t,
                  label: t
                }))
              "
              v-test="'tw-venue-types'"
            />
          </div>
          <div v-if="shouldEnable && !freeCommissionEndDate">
            <BaseHeading size="s" mb :mr="2">
              {{ $t('treatwell.free_period.heading') }}
            </BaseHeading>
            <BaseRadio
              v-model="freeCommissionPeriod"
              :options="
                [0, 1, 2, 3].map((value) => ({
                  label: `${value} ${$t('global.items.month', value)}`,
                  value
                }))
              "
            />
          </div>
          <div v-else>
            <BaseHeading size="s" mb :mr="2">
              {{ $t('treatwell.free_end_date.heading') }}
            </BaseHeading>
            <BaseText>
              <div v-if="freeCommissionEndDate">
                {{ filters.date(freeCommissionEndDate) }}
              </div>
              <div v-else>Not specified</div>
            </BaseText>
          </div>
        </div>
        <BaseGrid mt :size="gridSize" :mSize="12">
          <BaseHeading mb>
            {{ $t('treatwell.bank_details.header') }}
          </BaseHeading>
          <BaseCard>
            <BaseText mb>{{
              $t('treatwell.bank_details.description')
            }}</BaseText>
            <BaseGrid container>
              <BaseGrid :size="3">
                <BaseInput
                  v-model="accountHolder"
                  :label="$t('treatwell.bank_details.account_holder')"
                  size="s"
                  v-test="'treatwell-account-holder-input'"
                />
              </BaseGrid>
              <BaseGrid :size="3">
                <BaseInput
                  v-model="bankName"
                  :label="$t('treatwell.bank_details.bank_name')"
                  size="s"
                  v-test="'treatwell-bank-name-input'"
                />
              </BaseGrid>
              <BaseGrid :size="4">
                <BaseInput
                  v-model="accountNumber"
                  :label="$t('treatwell.bank_details.account_number')"
                  size="s"
                  v-test="'treatwell-account-number-input'"
                />
              </BaseGrid>
              <BaseGrid :size="2">
                <BaseInput
                  v-model="bankCode"
                  :label="$t('treatwell.bank_details.bank_code')"
                  size="s"
                  v-test="'treatwell-bank-code-input'"
                />
              </BaseGrid>
            </BaseGrid>
          </BaseCard>
        </BaseGrid>
        <BaseHeading size="s" :mt="2" mb>
          {{ $t('treatwell.images.title') }}
        </BaseHeading>
        <BaseText mb>
          {{ $t('treatwell.images.description') }}
        </BaseText>
        <BaseUpload
          preset="tw-venue-images"
          multiupload
          showSupport
          dropfield
          @uploadResults="onUpload"
        />
        <BaseImageGallery v-model="images" remove mb />
        <BaseInput
          v-model="description"
          :mt="2"
          :mb="2"
          :label="$t('treatwell.description')"
          type="textarea"
          size="l"
          :rows="20"
          v-test="'tw-description'"
        />
        <BaseCodeField :code="descriptionPrompt" mb buttonColor="inverted" />

        <BaseCard v-if="changeRequest" mt mb v-test="'change-request-card'">
          <BaseHeading bold> Change request </BaseHeading>
          <BaseTableRow>
            <BaseTableCell>Description Atmosphere</BaseTableCell>
            <BaseTableCell>{{
              changeRequest.descriptionAtmosphere
            }}</BaseTableCell>
          </BaseTableRow>
          <BaseTableRow>
            <BaseTableCell>Description Brands and Products</BaseTableCell>
            <BaseTableCell>{{
              changeRequest.descriptionBrandsProducts
            }}</BaseTableCell>
          </BaseTableRow>
          <BaseTableRow>
            <BaseTableCell>Description Experience</BaseTableCell>
            <BaseTableCell>{{
              changeRequest.descriptionExperience
            }}</BaseTableCell>
          </BaseTableRow>
          <BaseTableRow>
            <BaseTableCell>Description Specialities</BaseTableCell>
            <BaseTableCell>{{
              changeRequest.descriptionSpecialities
            }}</BaseTableCell>
          </BaseTableRow>
          <BaseTableRow>
            <BaseTableCell>Description Transports</BaseTableCell>
            <BaseTableCell>{{
              changeRequest.descriptionTransports
            }}</BaseTableCell>
          </BaseTableRow>
          <BaseTableRow>
            <BaseTableCell>Description Extras</BaseTableCell>
            <BaseTableCell>{{ changeRequest.descriptionExtras }}</BaseTableCell>
          </BaseTableRow>
        </BaseCard>
        <div :class="$style.footer">
          <BaseAlert
            v-if="showError"
            color="warning"
            :text="$t('treatwell.bank_details.error')"
            mb
          />
          <div>
            <BaseButton
              color="inverted"
              mr
              @click="$emit('backToIntegrations')"
            >
              {{ $t('global.back') }}
            </BaseButton>
            <BaseButton
              :loading="isLoading"
              @click="$emit('submit', submitParameters)"
              v-test="'tw-save'"
            >
              {{
                shouldEnable
                  ? $t('global.actions.enable')
                  : $t('global.actions.save')
              }}
            </BaseButton>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import type {
  VenueData,
  TreatwellBankingDetails,
  VenueFormData,
} from './types';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import filters from '@/filters';
import type { TreatwellChangeRequest } from '@/types';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { useQuery } from '@vue/apollo-composable';
import { getCategories } from './service-mapping/graphql';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell,
  },
  props: {
    showError: Boolean,
    formData: {
      type: Object as PropType<VenueData>,
      required: true,
    },
    shouldEnable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gridSize: {
      type: Number,
      required: false,
      default: 12,
    },
    freeCommissionEndDate: {
      type: String,
      required: false,
    },
    changeRequest: {
      type: Object as PropType<TreatwellChangeRequest>,
      required: false,
    },
  },
  emits: ['backToIntegrations', 'submit'],
  setup() {
    const { result: categories } = useQuery(getCategories);
    return { filters, categories };
  },
  data() {
    return {
      description: this.formData.description,
      images: this.formData.images,
      venueType: this.formData.venueType || this.bestGuessVenueType(),
      accountHolder: this.formData.accountHolder,
      bankName: this.formData.bankName,
      accountNumber: this.formData.accountNumber,
      bankCode: this.formData.bankCode,
      freeCommissionPeriod: this.formData.freeCommissionPeriod,
    };
  },
  apollo: {
    treatwellVenueTypes: {
      query: gql`
        query getTreatwellVenueTypes {
          treatwellVenueTypes
        }
      `,
    },
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    submitParameters(): VenueFormData {
      return {
        description: this.description,
        images: this.images,
        venueType: this.venueType,
        bankingDetails: this.bankingDetails(),
        freeCommissionPeriod: this.freeCommissionPeriod,
      };
    },
    serviceCategories(): string {
      return this.categories?.serviceCategories
        ? this.categories.serviceCategories
            .map((category) => category.name)
            .join('- ')
        : '';
    },
    selfDescription(): string {
      return this.changeRequest
        ? [
            this.changeRequest.descriptionAtmosphere,
            this.changeRequest.descriptionBrandsProducts,
            this.changeRequest.descriptionExperience,
            this.changeRequest.descriptionSpecialities,
            this.changeRequest.descriptionTransports,
            this.changeRequest.descriptionExtras,
          ].join(', ')
        : '';
    },
    promptExampleTemplate(): string {
      return '(naam en locatie van salon) is een (voeg hier salon type toe) waar zorg en comfort centraal staan, met als doel (voeg hier een pakkend doel). \n Dichtstbijzijnde openbaar vervoer: \n De salon is gelegen bij de halte here the name of the nearest stop \n Het team: \n De salon heeft een klein team van medewerkers die zorg dragen voor de klanten. Ze zijn professioneel, vriendelijk en streven ernaar om aan alle behoeften van hun klanten te voldoen.Here you don’t have to add anything \n Wat we leuk vinden aan de salon: \n Sfeer: vriendelijk & verzorgd you can change these words, for example: “schoon” (clean) , professioneel, comfortabel, “fijn” (nice), “gezellig” (only exists in Dutch but means something between cozy and fun) \n Gespecialiseerd in: schoonheidsbehandelingen Here the treatments , if you don’t know which, just leave it like this \n Gebruikte merken en producten: - \n De extra’s: - here I put something about language, opening hours or easy acces by public transport';
    },
    descriptionPrompt(): string {
      const companyType = this.$t(
        `company_type.types.${this.company.companyType.toLowerCase()}`,
      );
      return `schrijf een pakkende salonomschrijving in derde persoonsvorm voor de ${companyType} salon ${this.company.name}, locatie: ${this.company.city}, gespecialiseerd in: ${this.serviceCategories}. De salon beschrijft zichzelf als: ${this.selfDescription} en probeer het volgende template te volgen, maar pas wel alle keywords goed aan: ${this.promptExampleTemplate}`;
    },
  },
  methods: {
    bestGuessVenueType(): string {
      const { company } = useCompanyStore();
      switch (company.companyType) {
        case 'BARBERSHOP':
          return 'BARBERSHOP';
        case 'BEAUTY_SALON':
          return 'BEAUTY_SALON';
        case 'HAIR_SALON':
          return 'HAIR_SALON';
        case 'MANI_PEDICURE':
        case 'NAIL_STUDIO':
          return 'NAIL_SALON';
        case 'MASSAGE_SALON':
        case 'PHYSIOTHERAPY':
        case 'THERAPIST':
          return 'MASSAGE_AND_THERAPY_CENTRE';
        case 'OTHER':
          return 'DAY_SPA';
        default:
          return '';
      }
    },
    onUpload(file: any) {
      this.images.push(file.public_id);
    },

    bankingDetails(): TreatwellBankingDetails | null {
      if (
        this.accountHolder &&
        this.accountNumber &&
        this.bankName &&
        this.bankCode
      ) {
        return {
          accountHolderName: this.accountHolder,
          accountNumber: this.accountNumber,
          bankName: this.bankName,
          bankCode: this.bankCode,
        };
      } else {
        return null;
      }
    },
  },
});
</script>
<style lang="scss" module>
.infoTop {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing * 2;
}

.mainCard {
  margin: auto;
}
</style>
