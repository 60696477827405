import Store from '@/modules/store/index.vue';
import { redirectRoute } from '@/router/helpers';
import { useCompanyStore } from '@/stores/company';

export default {
  path: 'store',
  name: 'store',
  component: Store,
  meta: {
    moduleName: 'store',
    unleash: 'TreatwellStore',
  },
  children: [
    {
      name: 'store-child',
      path: ':pathMatch(.*)*',
      component: {
        template: '<template />',
      },
      beforeEnter: () => {
        const { companySettings } = useCompanyStore();

        if (!companySettings.store.interestedAt) {
          return redirectRoute({
            name: 'store',
          });
        }
      },
    },
  ],
};
