<template>
  <FormElement
    v-bind="{
      modelValue,
      label,
      info,
      uid,
      type,
      required,
      minLength,
      maxLength,
      newPassword,
      sameAs,
      regex
    }"
    :customError="error"
    @error="_hasError = $event"
  >
    <component
      :is="componentName"
      v-bind="{
        modelValue,
        type,
        uid,
        hasError,
        disabled,
        focus,
        placeholder,
        size,
        minValue,
        maxValue,
        decimals,
        unitLabel,
        controls,
        rows,
        inputModeText,
        prefix,
        defaultCountry
      }"
      @update:modelValue="$emit('update:modelValue', $event)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <div
      v-if="characterCount && maxLength && typeof modelValue === 'string'"
      :class="[
        $style.characterCount,
        {
          [$style.error]: maxLength - modelValue.length < 0
        }
      ]"
    >
      {{ $t('global.amount_left', { amount: maxLength - modelValue.length }) }}
    </div>
  </FormElement>
</template>

<script setup lang="ts">
import FormElement from '@/components/_shared/form-element/index.vue';
import { ref, computed } from 'vue';

interface ParentProps {
  error?: string;
  info?: string;
  inputModeText?: boolean;
  label?: string;
  modelValue: number | string | null;
  type?:
    | 'text'
    | 'email'
    | 'tel'
    | 'textarea'
    | 'number'
    | 'currency'
    | 'password'
    | 'date'
    | 'url'
    | 'postalcode';

  disabled?: boolean;
  focus?: boolean;
  placeholder?: string;
  rows?: number;
  size?: 's' | 'm' | 'l';

  characterCount?: boolean;
  controls?: boolean;
  defaultCountry?: string;
  decimals?: number;
  minValue?: number;
  maxValue?: number;
  minLength?: number;
  maxLength?: number;
  newPassword?: boolean;
  prefix?: string;
  required?: boolean;
  sameAs?: string | number;
  unitLabel?: string;
  regex?: RegExp;
}

defineEmits(['update:modelValue', 'focus', 'blur']);
const uid = `input-${Math.round(Math.random() * 100000).toString()}`;
const _hasError = ref(false);
const hasError = computed<boolean>(() => _hasError.value || !!props.error);

const props = withDefaults(defineProps<ParentProps>(), {
  decimals: 0,
  type: 'text',
});

const componentName = computed(() => {
  let componentName;
  switch (props.type) {
    case 'currency':
      componentName = 'CurrencyInput';
      break;
    case 'password':
      componentName = 'PasswordInput';
      break;
    case 'number':
      componentName = 'NumberInput';
      break;
    case 'textarea':
      componentName = 'TextareaInput';
      break;
    case 'date':
      componentName = 'DateInput';
      break;
    case 'tel':
      componentName = 'TelInput';
      break;
    default:
      componentName = 'TextInput';
      break;
  }

  return componentName;
});
</script>

<script lang="ts">
import TextInput from './Text.vue';
import CurrencyInput from './currency/index.vue';
import PasswordInput from './Password.vue';
import NumberInput from './Number.vue';
import TextareaInput from './Textarea.vue';
import DateInput from './Date.vue';
import TelInput from './Tel.vue';

export default {
  name: 'BaseInput',
  components: {
    TextInput,
    CurrencyInput,
    PasswordInput,
    NumberInput,
    TextareaInput,
    DateInput,
    TelInput
  },
  inheritAttrs: false
};
</script>

<style lang="scss" module>
.characterCount {
  margin-top: $spacing * 0.5;

  &.error {
    color: $color-error;
  }
}
</style>
