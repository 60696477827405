<template>
  <div>
    <div v-for="(item, index) in checkboxData" :key="index">
      <BaseCheckbox
        mb
        :label="item.name"
        :modelValue="
          groupBy
            ? selectedGroups.includes(item.id)
            : selected.includes(item.id)
        "
        @update:modelValue="
          groupBy ? setGroupedItems($event, item.id) : setItems($event, item.id)
        "
        v-test="`checkbox-item-${index}`"
      />
      <div v-if="groupBy" v-test="'nestedCheckboxes'">
        <div v-for="(nested, nestedIndex) in item.items" :key="nestedIndex" mb>
          <BaseCheckbox
            ml
            :mb="nested.labels && nested.labels.length ? 0.5 : 1"
            :label="nested.name"
            :modelValue="selected.includes(nested.id)"
            @update:modelValue="setItems($event, nested.id)"
          />
          <div v-if="nested.labels" :class="$style.inline" :ml="2">
            <BaseLabel
              v-for="(label, labelIndex) in nested.labels"
              :key="labelIndex"
              :item="label"
              deletable
              :mr="0.5"
              :mb="0.5"
              info
              @delete="$emit('onDelete', nested.id)"
              v-test="'checkbox-label-item'"
            >
              {{ label.group ? `${label.group.name} - ` : '' }}{{ label.name }}
            </BaseLabel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    groupBy: {
      type: String,
    },
    labels: {
      type: String,
    },
  },
  emits: ['update:modelValue', 'onDelete'],
  data() {
    return {
      selectedGroups: [],
      selected: [],
    };
  },
  watch: {
    modelValue: {
      handler(value) {
        if (!value.length) {
          this.selected = [];
          this.selectedGroups = [];
        }
      },
      deep: true,
    },
  },
  computed: {
    checkboxData() {
      return this.groupBy ? this.groupedData : this.items;
    },
    groupedData() {
      const groupedData = [];
      this.items.forEach((item) => {
        if (this.labels) {
          item.labels = item[this.labels];
        }
        const group = groupedData.find((g) => g.id === item[this.groupBy].id);
        if (group) {
          group.items.push(item);
        } else {
          const newGroup = {
            name: item[this.groupBy].name,
            id: item[this.groupBy].id,
            items: [item],
          };
          groupedData.push(newGroup);
        }
      });

      return groupedData;
    },
  },
  methods: {
    setGroupedItems(value, id) {
      if (!value) {
        this.selectedGroups = this.selectedGroups.filter(
          (groupId) => groupId !== id,
        );
        this.groupedData.forEach((item) => {
          if (item.id === id) {
            item.items.forEach((item) => {
              this.selected = this.selected.filter(
                (itemId) => itemId !== item.id,
              );
            });
          }
        });
      } else {
        this.selectedGroups.push(id);
        const group = this.groupedData.find((item) => item.id === id);
        const nestedIds = group.items.map((item) => item.id);
        this.selected = [...this.selected, ...nestedIds];
      }

      this.$emit('update:modelValue', this.selected);
    },
    setItems(value, id) {
      if (!value) {
        this.selected = this.selected.filter((itemId) => itemId !== id);
      } else {
        this.selected.push(id);
      }

      this.$emit('update:modelValue', this.selected);
    },
  },
});
</script>

<style lang="scss" module>
.inline {
  display: flex;
  flex-wrap: wrap;
  margin-left: $spacing * 2;
}
</style>
