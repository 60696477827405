import camelCase from 'lodash-es/camelCase';
import upperFirst from 'lodash-es/upperFirst';

const baseComponents = import.meta.globEager('../components/Base*.vue');
const componentFolders = import.meta.globEager(
  '../components/base-*/index.vue',
);
const layoutComponents = import.meta.globEager('../components/layout/*.vue');

const componentsData = [
  ...Object.entries(baseComponents),
  ...Object.entries(layoutComponents),
].map(([path, definition]) => ({
  name: path
    ?.split('/')
    ?.pop()
    ?.replace(/\.\w+$/, ''),
  config: definition.default,
}));

Object.entries(componentFolders).forEach(([path, definition]) => {
  componentsData.push({
    name: upperFirst(camelCase(path.split('/')[2])),
    config: definition.default,
  });
});

export default componentsData;
