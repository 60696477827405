import dayjs from '@/dayjs';
import { useCompanyStore } from '@/stores/company';
import type { Dayjs } from 'dayjs';

export const from = (firstDate: Dayjs, nextDate: Dayjs): string => {
  const { company } = useCompanyStore();
  const language = company.language || 'en';
  dayjs.locale(language);
  return nextDate.from(firstDate);
};
const leadTimeOptions = [0, 30, 60, 120, 240, 480, 720, 1440, 2880, 4320];

const onlineBookingIntervalOptions = [5, 10, 15, 20, 30, 45, 60, 75, 90, 120];

const getLeadTimeLabel = (minutes: number) => {
  const startDate = dayjs().hour(10).minute(0).second(0);
  const nextDate = startDate.add(minutes, 'minute');
  return from(startDate, nextDate);
};

const assistantMessages = [
  {
    step: 0,
    messages: [
      {
        value: false,
        text: 'admin.assistant.online_booking.option_1.assistant',
        vars: {},
      },
      {
        value: true,
        text: 'admin.assistant.online_booking.option_2.assistant',
        vars: {},
      },
    ],
  },
  {
    step: 1,
    messages: [
      {
        value: false,
        text: 'admin.assistant.existing_customers.option_1.assistant',
        vars: {},
      },
      {
        value: true,
        text: 'admin.assistant.existing_customers.option_2.assistant',
        vars: {},
      },
    ],
  },
  {
    step: 2,
    messages: [
      {
        value: 'MANUAL',
        text: 'admin.assistant.employee_preference.option_1.assistant',
        vars: {},
      },
      {
        value: 'AUTOMATIC',
        text: 'admin.assistant.employee_preference.option_2.assistant',
        vars: {},
      },
    ],
  },
  {
    step: 3,
    messages: onlineBookingIntervalOptions.map((value) => ({
      value,
      text: 'admin.assistant.booking_interval.assistant',
      vars: {},
    })),
  },
  {
    step: 4,
    messages: leadTimeOptions.map((value) => ({
      value,
      text: value
        ? 'admin.assistant.walkin.assistant'
        : 'admin.assistant.walkin.assistant_none',
      vars: { value: getLeadTimeLabel(value) },
    })),
  },
  {
    step: 5,
    messages: [
      {
        value: false,
        text: 'admin.assistant.waiting_list.option_1.assistant',
        vars: {},
      },
      {
        value: true,
        text: 'admin.assistant.waiting_list.option_2.assistant',
        vars: {},
      },
    ],
  },
];

export default assistantMessages;
