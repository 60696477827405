<template>
  <BaseModal
    :heading="$t('appointment.create.timeslots_modal.title')"
    @close="$emit('close')"
  >
    <BaseGrid container>
      <BaseGrid :size="5" :class="$style.calendar">
        <BaseDatePicker
          v-model="date"
          inline
          :availableDates="availableDates"
          @visibleRangeChanged="setRange"
          v-test="'timeslot-picker'"
        />
      </BaseGrid>
      <BaseGrid :size="7" :class="$style.timeslotsGrid">
        <div v-if="anyAvailableTimeSlots" v-test="'available-time-slots'">
          <div
            v-for="timeslotsGroup in availableTimeSlots"
            :key="timeslotsGroup.day_part"
            class="mb-15"
          >
            <BaseHeading>
              {{
                $t(
                  'appointment.create.timeslots_modal.' +
                    timeslotsGroup.day_part
                )
              }}
            </BaseHeading>
            <BaseButton
              v-for="timeslot in timeslotsGroup.timeslots"
              :key="timeslot"
              color="inverted"
              :mr="0.5"
              :mt="0.5"
              :selected="timeslot === selectedTimeslot"
              @click="selectTimeSlot(timeslot)"
              v-test="'time-slot'"
            >
              {{ timeslot }}
            </BaseButton>
          </div>
          <div :class="$style.bottom">
            <BaseText
              v-show="selectTimeError && !selectedTimeslot"
              color="error"
            >
              {{ $t('appointment.create.timeslots_modal.select_time') }}
            </BaseText>
            <div :class="$style.button">
              <BaseButton @click="apply" v-test="'availability-apply'">
                {{ $t('global.actions.apply') }}
              </BaseButton>
            </div>
          </div>
        </div>
        <div v-else v-test="'available-time-slots'">
          <BaseHeading>
            {{ $t('appointment.create.timeslots_modal.no_timeslots') }}
          </BaseHeading>
        </div>
      </BaseGrid>
    </BaseGrid>
  </BaseModal>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import gql from 'graphql-tag';
import { ref, defineComponent } from 'vue';
import { combineDateTime } from '@/helpers/dates';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';

export default defineComponent({
  emits: ['close'],
  setup() {
    const { formData } = useCreateAppointmentStore();
    const dateObj = dayjs(formData.startAt);

    return {
      date: ref(dateObj.format('YYYY-MM-DD')),
      selectedTimeslot: ref(dateObj.format('HH:mm')),
      formData,
    };
  },
  data() {
    return {
      timeslotList: [],
      selectTimeError: false,
      startDate: null,
      endDate: null,
    };
  },
  apollo: {
    timeslots: {
      query: gql`
        query getTimeslots(
          $startDate: DateTime!
          $locationId: ID
          $endDate: DateTime!
          $parts: [AppointmentPartAttributes!]
        ) {
          timeslots(
            start: $startDate
            locationId: $locationId
            end: $endDate
            parts: $parts
          )
        }
      `,
      variables() {
        const parts = this.formData.partsAttributes.filter(
          (part) => !!part.serviceId,
        );
        return {
          parts,
          startDate: this.startDate,
          endDate: this.endDate,
          locationId: this.formData.locationId,
        };
      },
      skip() {
        return !this.startDate || !this.endDate;
      },
      result({ data }) {
        this.timeslotList = data.timeslots;
      },
    },
  },
  watch: {
    date() {
      this.selectedTimeslot = null;
    },
  },
  computed: {
    availableTimeSlots() {
      return this.timeslotList.find((slot) => slot.date === this.date)
        ?.timeslots;
    },
    availableDates() {
      return this.timeslotList.map((slot) => {
        if (slot.timeslots?.length) {
          return slot.date;
        }
      });
    },
    anyAvailableTimeSlots() {
      return !!this.availableTimeSlots?.length > 0;
    },
  },
  methods: {
    setRange({ from, to }) {
      this.startDate = from;
      this.endDate = to;
    },
    selectTimeSlot(timeslot) {
      this.selectedTimeslot = timeslot;
    },
    apply() {
      if (this.selectedTimeslot) {
        this.formData.startAt = combineDateTime(
          this.date,
          this.selectedTimeslot,
        );
        this.$emit('close');
      } else {
        this.selectTimeError = true;
      }
    },
  },
});
</script>

<style lang="scss" module>
.calendar {
  border-right: 1px solid $color-border;
  display: flex;
  justify-content: center;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  margin-left: auto;
}

.timeslotsGrid {
  padding-left: 30px;
}
</style>
