<template>
  <BaseModal
    :heading="$t('account.auth.employee_list.header')"
    testId="show-employee-modal"
  >
    <BaseText v-test="'employee-list-info'">
      {{ $t('account.auth.employee_list.info') }}
    </BaseText>
    <BaseText
      inline
      :href="helpPageUrl"
      target="_blank"
      v-test="'employee-list-info-link'"
    >
      {{ $t('global.learn_more') }}
    </BaseText>
    <BaseTableRow head zebra mt emptyOptionsCell>
      <BaseTableCell :width="50">{{
        filters.capitalize($t('account.auth.employee_list.table_headers.name'))
      }}</BaseTableCell>
      <BaseTableCell :width="40">{{
        filters.capitalize(
          $t('account.auth.employee_list.table_headers.status')
        )
      }}</BaseTableCell>
    </BaseTableRow>
    <BaseTableRow
      v-for="(employee, index) in employees"
      :key="`row-${index}`"
      :options="getOptions(employee)"
      emptyOptionsCell
      zebra
      @optionClick="onSelectedAction(employee)"
      v-test="`single-employee-row-${index}`"
    >
      <BaseTableCell
        :width="50"
        :class="[$style.textNoOverfow]"
        v-test="'employee-name'"
      >
        {{ employee.name }}
      </BaseTableCell>
      <BaseTableCell :width="40" v-test="'employee-status'">
        {{
          employee.status
            ? $t('account.auth.employee_list.status_enabled')
            : $t('account.auth.employee_list.status_disabled')
        }}
      </BaseTableCell>
    </BaseTableRow>
    <BaseModalSmall
      v-if="showDisableConfirmation"
      @close="onDisableConfirmationClose"
    >
      <DisableTwoFactorAuthentication
        :passwordInvalid="invalidPassword"
        :otpCodeInvalid="invalidTwoFactorCode"
        :selectedEmployee="selectedEmployee"
        @disable="disableTwoFactor"
        v-test="'disable-confirmation-modal'"
      />
    </BaseModalSmall>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import DisableTwoFactorAuthentication from './DisableTwoFactorAuthentication.vue';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    DisableTwoFactorAuthentication,
    BaseTableRow,
    BaseTableCell,
  },
  props: {
    companyUsers: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      filters,
    };
  },
  data() {
    return {
      userList: this.companyUsers,
      showDisableConfirmation: false,
      selectedEmployee: null,
      invalidPassword: false,
      invalidTwoFactorCode: false,
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    employees() {
      return this.userList.map((employee) => ({
        name: employee.fullName,
        status: employee.twoFactorSettings?.status === 'ENABLED',
        mode: employee.twoFactorSettings?.mode,
        id: employee.id,
      }));
    },
    helpPageUrl() {
      const companyLanguage = this.company.language;
      const blogLanguage = ['en', 'nl'].includes(companyLanguage)
        ? companyLanguage
        : 'en';
      return `https://help.salonized.com/${blogLanguage}/articles/6213132`;
    },
  },
  methods: {
    getOptions(employee) {
      const option = {
        name: 'disable',
        label: this.$t('account.auth.employee_list.action_button'),
        icon: 'unlock',
      };
      if (employee.status) {
        return [option];
      } else {
        return [];
      }
    },
    onSelectedAction(employee) {
      this.selectedEmployee = employee;
      this.showDisableConfirmation = true;
    },
    onDisableConfirmationClose() {
      this.selectedEmployee = null;
      this.invalidPassoword = false;
      this.showDisableConfirmation = false;
    },
    disableTwoFactor(attributes) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateTwoFactorSettings(
              $input: UpdateTwoFactorSettingsInput!
            ) {
              updateTwoFactorSettings(input: $input) {
                twoFactorSettings {
                  status
                  mode
                }
                errors
              }
            }
          `,
          variables: {
            input: {
              twoFactorCode: attributes.otp,
              userPassword: attributes.password,
              employeeId: this.selectedEmployee.id,
              status: 'DISABLED',
            },
          },
        })
        .then((response) => {
          response = response.data.updateTwoFactorSettings;
          if (response.errors === null) {
            const userIndex = this.userList.findIndex(
              (employee) => employee.id === this.selectedEmployee.id,
            );
            this.userList[userIndex].twoFactorSettings.status =
              response.twoFactorSettings.status;
            this.onDisableConfirmationClose();
          } else {
            this.invalidPassword = response.errors.userPassword === 'Invalid';
            this.invalidTwoFactorCode =
              response.errors.twoFactorCode === 'Invalid';
          }
        });
    },
  },
  apollo: {
    companyUsers: {
      query: gql`
        query getCompanyUsers {
          companyUsers {
            id
            fullName
            twoFactorSettings {
              status
              mode
            }
          }
        }
      `,
      result(response) {
        this.userList = response.data.companyUsers;
      },
    },
  },
});
</script>
<style lang="scss" module>
.textNoOverfow {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
