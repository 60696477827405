<template>
  <BaseAlert
    v-if="showVatBanner"
    color="warning"
    :text="$t('company.vat_id.banner_text')"
    :primaryAction="$t('company.vat_id.set_vat_preference')"
    mb
    @primaryAction="showVatIdModal = true"
    v-test="'company-settings-vat-id-preference-not-set-banner'"
  />
  <BaseAlert
    v-if="showUnverifiedVatBanner"
    color="warning"
    :text="$t('company.vat_id.unverified_vat_id')"
    :primaryAction="$t('company.vat_id.set_vat_preference')"
    mb
    @primaryAction="showVatIdModal = true"
    v-test="'company-settings-vat-id-preference-unverified-banner'"
  />
  <VatIdModal v-if="showVatIdModal" @close="showVatIdModal = false" />
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import VatIdModal from '@/modules/admin/_shared/VatIdModal.vue';

const { company } = useCompanyStore();

const showVatIdModal = ref(false);
const showVatBanner = computed(
  () =>
    company.activeSubscription &&
    company.country === 'be' &&
    !company.billingInfo?.dontHaveVatNumber &&
    !company.billingInfo?.stripeTaxId?.value,
);

const showUnverifiedVatBanner = computed(
  () =>
    company.activeSubscription &&
    company.country === 'be' &&
    company.billingInfo?.stripeTaxId?.verification &&
    company.billingInfo.stripeTaxId.verification.status === 'unverified',
);
</script>
