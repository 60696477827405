<template>
  <Modal size="small" @clickBackground="close" @pressEscape="close">
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
      v-test="`modal-${testId}`"
    >
      <div :class="$style.close">
        <ModalClose @click="close" v-test="'_base-modal-close'" />
      </div>
      <div :class="$style.content">
        <div :class="$style.top">
          <slot />
        </div>
      </div>
      <div v-if="slots.center" :class="$style.center">
        <slot name="center" />
      </div>
      <div :class="$style.footer">
        <slot name="footer" />
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
export default {
  name: 'BaseModalSmall',
  inheritAttrs: false,
};
</script>
<script setup lang="ts">
import Modal from '@/components/_shared/Modal.vue';
import ModalClose from '@/components/_shared/ModalClose.vue';
import { useSlots } from 'vue';
const slots = useSlots();

defineProps<{ testId?: string; unclosable?: boolean }>();
const emit = defineEmits(['close']);

const close = () => {
  emit('close');
};
</script>

<style lang="scss" module>
.base {
  @include modal;

  &:not(.smallScreen) {
    width: 400px;
  }

  &.smallScreen {
    width: 100%;
    border-radius: $modal-radius $modal-radius 0 0;
  }
}

.close {
  position: absolute;
  right: 0;
  padding: $spacing * 0.5;

  .base:not(.smallScreen) & {
    top: 6px;
  }

  .base.smallScreen & {
    top: -2px;
  }
}

.content,
.center,
.footer {
  .base:not(.smallScreen) & {
    padding: $spacing * 1.5;
  }

  .base.smallScreen & {
    padding: $spacing;
  }
}

.center {
  .base:not(.smallScreen) & {
    border-top: 1px solid $color-border;
  }
}

.center,
.footer {
  &:empty {
    display: none;
  }
}

.top {
  padding-right: 64px;
}

.footer {
  display: flex;
  align-items: center;
  gap: $spacing;

  .base:not(.smallScreen) & {
    justify-content: flex-end;
    border-top: 1px solid $color-border;
  }

  .base.smallScreen & {
    & > * {
      width: 100%;
      flex-shrink: 1 !important;
    }
  }
}
</style>
