<template>
  <div>
    <Draggable
      v-show="listItems.length"
      v-model="listItems"
      :disabled="device.touch"
      item-key="id"
    >
      <template #item="{ element, index }">
        <ListItem
          :data="element"
          :index="index"
          :listLength="listItems.length"
          :expanded="activeId === element.id || listItems.length === 1"
          @toggle="onToggle(element.id, $event)"
          @updateSortOrder="sortByClick($event, element)"
          v-test="'service-variations-group-category-item'"
        />
      </template>
    </Draggable>
  </div>
</template>

<script lang="ts">
import ListItem from './item/index.vue';
import Draggable from 'vuedraggable';
import { sortByClick, sortByDrag } from '@/modules/admin/helpers';
import { useServicesStore } from '@/stores/services';
import { setScrollOnElement } from '@/helpers/scroll';
import { device } from '@/user-context';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ListItem,
    Draggable,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    activeId: {
      type: Number,
      default: null,
    },
  },
  emits: ['toggle'],
  setup() {
    const { sortCategories } = useServicesStore();
    return {
      sortCategories,
      device,
    };
  },
  computed: {
    listItems: {
      get() {
        return this.data;
      },
      set(newValue) {
        this.sortCategories(sortByDrag(newValue, this.listItems));
      },
    },
  },
  methods: {
    sortByClick(newIndex, category) {
      this.sortCategories(sortByClick(newIndex, category, this.listItems));
    },
    onToggle(id, element) {
      this.$emit('toggle', id);
      setScrollOnElement(element);
    },
  },
});
</script>
