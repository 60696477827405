<template>
  <BaseCard gray>
    <BaseHeading mb>
      {{ t('promote.booking_widget.snippet_code') }}
    </BaseHeading>
    <BaseText mb>
      {{ t('promote.booking_widget.snippet_explanation') }}
    </BaseText>
    <div class="mb-1">
      <BaseText
        :href="t('promote.booking_widget.snippet_instruction_link.url')"
      >
        {{ t('promote.booking_widget.snippet_instruction_link.label') }}
      </BaseText>
    </div>
    <BaseCodeField
      v-if="codeSnippet"
      :code="codeSnippet"
      multiline
      v-test="'booking-settings-snippet'"
    />
    <BaseButton
      v-else
      :loading="loading"
      @click="$emit('generate')"
      v-test="'custom-widget-generate'"
    >
      {{ $t('custom_booking_widget.generate_code') }}
    </BaseButton>
  </BaseCard>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCompanyStore } from '@/stores/company';

const props = defineProps<{
  custom?: boolean;
  loading?: boolean;
  widgetId?: string;
}>();

defineEmits(['generate']);

const bookingSettingsData = inject('bookingSettingsData') as any;
const { t } = useI18n();

const codeSnippet = computed(() => {
  if (props.custom && !props.widgetId) {
    return '';
  }

  const { company, isTreatwellUser } = useCompanyStore();
  const {
    settings: { bookingWidget },
    languagePublic,
  } = bookingSettingsData;

  let dataAttrs = `data-company="${company.publicId}" data-color="${bookingWidget.customColor}" data-language="${languagePublic.toLowerCase()}"`;
  if (bookingWidget.inline) {
    if (!bookingWidget.autoWidth) {
      dataAttrs = `${dataAttrs} data-width="${bookingWidget.width}"`;
    }

    dataAttrs = `${dataAttrs} data-height="${bookingWidget.height}"`;
  }

  if (bookingWidget.inline) {
    dataAttrs = `${dataAttrs} data-inline="true"`;
  } else {
    dataAttrs = `${dataAttrs} data-position="${bookingWidget.position.toLowerCase()}"`;
  }

  if (bookingWidget.outline !== 'NONE') {
    dataAttrs = `${dataAttrs} data-outline="${bookingWidget.outline.toLowerCase()}"`;
  }

  if (props.custom && props.widgetId) {
    dataAttrs = `${dataAttrs} data-widget_id="${props.widgetId}"`;
  }

  if (isTreatwellUser) {
    dataAttrs = `${dataAttrs} data-treatwell="true"`;
  }

  let script = `<div class="salonized-booking" ${dataAttrs}></div><script src="https://static-widget.salonized.com/loader.js"></`;
  // needed because it will be parsed as closing the script tag of this file regardless of being inside a string
  script += 'script>';

  return script;
});
</script>
