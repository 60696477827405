import { useCompanyStore } from '@/stores/company';
import { useFloatingBannerStore } from '@/stores/floating-banner';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import {
  inject,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  watchEffect,
} from 'vue';
import { useRoute } from 'vue-router';

export const useIntercom = () => {
  const route = useRoute();
  const intercom = inject<any>('intercom');
  const showIntercom = ref(false);

  const { user } = useUserStore();
  if (user) {
    const { company, isTrial, isSubscribed } = useCompanyStore();

    intercom.setContext({
      user_hash: user.intercomHash,
      user_id: user.id,
      email: user.email,
      name: user.fullName,
      phone: company.phone,
      language_override: company.locale.defaultLanguage,
      newsletter: user.settings?.notifications?.updates,
      trade_show_demo_user: user.settings?.notifications?.tradeShowDemoUser,
      company: {
        id: company.id,
        name: company.name,
        address: company.fullAddress,
        city: company.city,
        phone: company.phone,
        country: company.country,
        admin_link: `https://app.salonized.com/analyze/companies/${company.id}`,
        subscribed: isSubscribed,
        trial: isTrial,
      },
    });
  }

  watch(showIntercom, (showIntercom) => {
    showIntercom ? intercom.showLauncher() : intercom.hideLauncher();
  });

  watchEffect(() => {
    const { screenSize, isModalOpen, showMobileMenu } = storeToRefs(
      usePageLayoutStore(),
    );
    const { activeBanner } = storeToRefs(useFloatingBannerStore());

    let showButton = false;

    if (
      (activeBanner.value && screenSize.value === 's') ||
      showMobileMenu.value
    ) {
      showButton = false;
    } else {
      const hideButtonMeta: any = route.meta.hideIntercomButton;
      const showButtonMeta: any = route.meta.showIntercomButton;

      if (hideButtonMeta && typeof hideButtonMeta === 'object') {
        if (
          (hideButtonMeta.mobile && screenSize.value === 's') ||
          (hideButtonMeta.desktop && screenSize.value !== 's')
        ) {
          showButton = false;
        } else {
          showButton = true;
        }
      } else if (hideButtonMeta) {
        showButton = !hideButtonMeta;
      } else if (isModalOpen.value) {
        showButton = false;
      } else {
        showButton = true;
      }

      if (showButtonMeta) {
        showButton = true;
      }
    }

    showIntercom.value = showButton;
  });

  onMounted(() => {
    // The Intercom button shows by default, so we need to hide it if needed
    // This only works if Intercom has booted before the app gets mounted
    // Which is not a guarantee, because the Intercom script is an external source and gets loaded async

    if (!showIntercom.value) {
      intercom.hideLauncher();
    }
  });

  onBeforeUnmount(() => {
    intercom.hideLauncher();
  });
};
