<template>
  <component
    :is="$screen === 's' ? 'div' : 'BasePopover'"
    :class="[$style.base, { [$style.isMobile]: $screen === 's' }]"
    :position="position"
    :closeButton="true"
    @close="$emit('closeFilter')"
    v-test="'filterShell'"
  >
    <BaseText
      v-if="$screen === 's'"
      link
      mb
      iconBefore="arrow-left"
      @click="$emit('closeFilter')"
    >
      {{ $t('global.back') }}
    </BaseText>
    <BaseHeading mb>
      {{ $t('global.actions.filter_on') }}:
      {{
        company.language !== 'de' ? selected.name.toLowerCase() : selected.name
      }}
    </BaseHeading>

    <RangeFilter
      v-if="selected.value === 'age'"
      name="age"
      @selected="onSelect"
    />
    <RangeFilter
      v-if="selected.value === 'no_shows'"
      name="no_shows"
      @selected="onSelect"
    />

    <Appointments
      v-if="selected.value === 'appointments'"
      @selected="onSelect"
    />
    <LastAppointment
      v-if="selected.value === 'last_appointment'"
      @selected="onSelect"
    />
    <Birthdays v-if="selected.value === 'birthdays'" @selected="onSelect" />
    <Customers v-if="selected.value === 'customers'" @selected="onSelect" />
    <Gender v-if="selected.value === 'gender'" @selected="onSelect" />
    <LoyaltyPoints
      v-if="selected.value === 'loyalty_points'"
      @selected="onSelect"
    />
    <MoneySpent v-if="selected.value === 'money_spent'" @selected="onSelect" />
    <Products v-if="selected.value === 'products'" @selected="onSelect" />
    <Services v-if="selected.value === 'services'" @selected="onSelect" />
    <Tags v-if="selected.value === 'tags'" @selected="onSelect" />
    <TreatedBy v-if="selected.value === 'treated_by'" @selected="onSelect" />
    <SavedFilterGroups
      v-if="selected.value === 'saved_filter_groups'"
      @selected="onSelect"
    />
    <div v-if="$screen === 's'" :class="$style.footer">
      <BaseButton
        :disabled="disableAdd"
        @click="onAdd"
        v-test="'filterShellSubmit'"
      >
        {{ $t('global.actions.add') }}
      </BaseButton>
    </div>
    <template v-if="$screen !== 's'" #footer>
      <div />
      <BaseButton
        :disabled="disableAdd"
        @click="onAdd"
        v-test="'filterShellSubmit'"
      >
        {{ $t('global.actions.add') }}
      </BaseButton>
    </template>
  </component>
</template>

<script lang="ts">
import RangeFilter from './filters/RangeFilter.vue';
import Appointments from './filters/Appointments.vue';
import LastAppointment from './filters/LastAppointment.vue';
import Birthdays from './filters/Birthdays.vue';
import Customers from './filters/Customers.vue';
import Gender from './filters/Gender.vue';
import LoyaltyPoints from './filters/LoyaltyPoints.vue';
import MoneySpent from './filters/MoneySpent.vue';
import Products from './filters/Products.vue';
import Services from './filters/Services.vue';
import TreatedBy from './filters/TreatedBy.vue';
import Tags from './filters/Tags.vue';
import SavedFilterGroups from './filters/SavedFilterGroups.vue';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import { formatFilters } from '@/helpers/formatting';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    RangeFilter,
    Appointments,
    LastAppointment,
    Birthdays,
    Customers,
    Gender,
    LoyaltyPoints,
    MoneySpent,
    Products,
    Services,
    TreatedBy,
    Tags,
    SavedFilterGroups,
  },
  props: {
    selected: {
      type: Object,
      required: true,
    },
    shiftPosition: {
      type: Boolean,
      default: false,
    },
    switchOnClick: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeFilter', 'setFilter'],
  data() {
    return {
      filter: null,
      disableAdd: false,
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    position() {
      let position;

      if (this.switchOnClick) {
        position = {
          top: 8,
          left: 32,
        };
      } else if (this.shiftPosition && this.$screen === 's') {
        position = {
          top: 24,
          left: 0,
        };
      } else if (this.shiftPosition && this.$screen === 'l') {
        position = {
          top: 24,
          left: -308,
        };
      } else {
        position = {
          top: 24,
          left: 228,
        };
      }

      return position;
    },
  },
  methods: {
    onSelect(filter) {
      if (
        filter.filter_name === 'products' ||
        filter.filter_name === 'services' ||
        filter.filter_name === 'treated_by' ||
        filter.filter_name === 'tags'
      ) {
        if (!filter.values || !filter.values.length) {
          this.disableAdd = true;
          return;
        }
      }

      this.disableAdd = false;
      this.filter = filter;
    },
    setFilterGroup(group) {
      this.$store.commit('marketing/CLEAR_FILTERS');
      this.$store.commit(
        'marketing/SET_ALL_FILTERS',
        formatFilters(group.filters),
      );
      this.filter = null;
    },
    onAdd() {
      if (this.filter.filter_name === 'filter_group') {
        this.setFilterGroup(this.filter.group);
      } else {
        this.$emit('setFilter', this.filter);
      }

      this.$emit('closeFilter');
    },
  },
});
</script>

<style lang="scss" module>
.base {
  cursor: default;
  color: $color-text;
  max-width: initial !important;

  &.isMobile {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: $spacing;
    background-color: white;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: $spacing;
}
</style>
