import config from '@/config';
import { devWarning } from '@/helpers/dev';
import { datadogRum } from '@datadog/browser-rum';

const isEnabled = !!config.datadogApplicationId;

export const captureException = (e: any, options: any) => {
  // When disabled, rethrow exception so that tests will pick it up
  isEnabled
    ? datadogRum.addError(e, options)
    : () => {
        throw e;
      };
};

export const handledException = (e: any, options: any) => {
  // Eventually these should not got to an error tracker
  // or be identified as handled as they are expected and can happen
  isEnabled ? datadogRum.addError(e, options) : devWarning(e);
};

export const logValidationError = (
  operationName: string,
  errors: any,
  input?: any,
) => {
  handledException(new Error('Backend validation error'), {
    operationName,
    errors,
    input,
  });
};
