import apolloClient from '@/apollo/client';
import gql from 'graphql-tag';
import { ref } from 'vue';
import type { Ref } from 'vue';

import type {
  AppointmentPartAttributes,
  Requirement as RequirementBackend,
  Resource,
  Service,
} from '@/types';

const query = gql`
  query getService($id: Int!) {
    service(id: $id) {
      id
      requirements {
        id
        primary
        resources {
          id
        }
        type
      }
    }
  }
`;

export type Requirement = {
  id: number;
  primary: boolean;
  resourceIds: number[];
  serviceId: number;
  type: string;
};

export const useRequirements = () => {
  const requirements: Ref<Requirement[]> = ref([]);
  const isFetchingRequirements = ref(false);

  const fetchServiceRequirements = (id: Service['id']) =>
    new Promise<void>((resolve) => {
      if (requirements.value.find((req) => req.serviceId === id)) {
        resolve();
        return;
      }

      isFetchingRequirements.value = true;

      apolloClient
        .query({
          query,
          variables: {
            id,
          },
          fetchPolicy: 'cache-first',
        })
        .then(({ data: { service } }) => {
          service.requirements.forEach((req: RequirementBackend) => {
            requirements.value.push({
              id: req.id,
              primary: req.primary,
              resourceIds: req.resources.map(
                (resource: Resource) => resource.id,
              ),
              serviceId: service.id,
              type: req.type,
            });
          });

          isFetchingRequirements.value = false;

          resolve();
        });
    });

  const loadAllocations = async (part: AppointmentPartAttributes) => {
    if (!part.serviceId) {
      return;
    }

    await fetchServiceRequirements(part.serviceId);

    // When the user has already selected a resource, but changes the service, the resourceId should not change
    // This should only happen for the first allocation. The other allocations should set their resourceId to null
    // Except when the requirementId has not changed, which happens when changing the order of the parts

    const serviceRequirements = requirements.value.filter(
      (req) => req.serviceId === part.serviceId,
    );

    if (serviceRequirements.length) {
      // The primary allocation should always be first, because the first resource dropdown should be linked to the primary allocation
      // For some reason the backend doesn't always sort the service requirements correctly, so we sort them here
      serviceRequirements.sort((a, b) => Number(b.primary) - Number(a.primary));

      part.allocationsAttributes = serviceRequirements.map((req, index) => {
        const existingAllocation = part.allocationsAttributes?.[index];

        if (existingAllocation?.requirementId === req.id) {
          // Requirement did not change, keep the allocation the same
          return existingAllocation;
        }

        // Reset the part id when creating new allocations
        part.id = null;

        let newResourceId;

        if (index === 0) {
          // Don't change the resource of the first allocation
          newResourceId = existingAllocation?.resourceId;
        } else {
          // Requirement has changed, reset the resource for non-primary allocations
          newResourceId = null;
        }

        return {
          requirementId: req.id,
          resourceId: newResourceId || null,
        };
      });
    }
  };

  const clearRequirements = () => {
    // Clear ref
    requirements.value = [];

    // Clear Apollo cache
    apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'service' });
    apolloClient.cache.gc();
  };

  return {
    requirements,
    isFetchingRequirements,
    clearRequirements,
    loadAllocations,
  };
};
