<template>
  <div>
    <CreateBackOrder
      v-if="showCreateBackOrderModal"
      @close="showCreateBackOrderModal = false"
      @submit="onBackOrderSubmit"
    />
    <BaseButton
      :routerLink="{ name: 'stock-order' }"
      mr
      color="inverted"
      v-test="'processActionsCancel'"
    >
      {{ $t('global.actions.cancel') }}
    </BaseButton>
    <BaseButton
      :loading="isLoading"
      @click="checkStatus"
      v-test="'processActionsProcess'"
    >
      {{ $t('global.actions.process') }}
    </BaseButton>
  </div>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import CreateBackOrder from '../modals/CreateBackOrder.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CreateBackOrder,
  },
  props: {
    processItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      showCreateBackOrderModal: false,
    };
  },
  methods: {
    onBackOrderSubmit(data) {
      this.onProcess(data);
    },
    checkStatus() {
      const hasReceivedLess = !!this.processItems.find(
        (item) => item.quantityReceived < item.quantity,
      );

      if (hasReceivedLess) {
        this.showCreateBackOrderModal = true;
      } else {
        this.onProcess();
      }
    },
    onProcess(status) {
      this.isLoading = true;
      const orderStatus = status ? status : 'complete';
      const stockOrderId = Number.parseInt(this.$route.params.stockOrderId);
      const mappedForPayload = this.processItems.map((item) => {
        const mappedItem = {
          id: item.id,
          productId: item.product.id,
          costPrice: item.costPrice,
          quantity: item.quantity,
          quantityReceived: item.quantityReceived,
          stockOrderId,
        };
        return mappedItem;
      });

      const input = {
        id: stockOrderId,
        itemsAttributes: mappedForPayload,
        status: orderStatus,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateStockOrder($input: UpdateStockOrderInput!) {
              updateStockOrder(input: $input) {
                stockOrder {
                  id
                }
              }
            }
          `,
          variables: {
            input,
          },
          update(cache) {
            const cacheId = `StockOrder:${stockOrderId}`;
            cache.evict({ id: cacheId, broadcast: false });
            cache.gc();
          },
        })
        .then(() => {
          this.$router.push({ name: 'stock-order' });
          flash(this.$t('global.flash.stock_order_processed'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>
