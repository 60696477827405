<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm',
        [$style.rightAlign]: rightAlign,
        [$style.empty]: isEmpty,
        [$style.grey]: grey,
        [$style.noBorder]: noBorder,
        [$style.previewMode]: previewMode,
        [$style.unclosable]: unclosable
      }
    ]"
  >
    <div :class="$style.heading" @click="handleBackButton">
      <BaseHeading
        v-if="headingText"
        :icon="hasBackButton ? 'arrow-left' : undefined"
        :size="$screen !== 's' ? 'l' : 'm'"
      >
        {{ filters.capitalize(headingText) }}
      </BaseHeading>
      <transition>
        <div v-if="labels.length" :class="$style.labels">
          <BaseLabel
            v-for="(label, index) in labels"
            :key="`label-${index}`"
            :state="label.toLowerCase()"
            v-test="`label-${label.toLowerCase()}`"
          />
        </div>
      </transition>
      <slot />
    </div>
    <div v-if="hasNav || !unclosable" :class="$style.controls">
      <div v-if="hasNav" :class="$style.nav">
        <router-link
          v-for="(route, index) in routes"
          :key="index"
          :to="{ name: route.name }"
          :class="$style.navItem"
          :exact-active-class="$style.isActive"
          v-test="`tab-${route.name}`"
        >
          {{ filters.capitalize(route.label) }}
        </router-link>
      </div>
    </div>
    <div :class="$style.right">
      <div :class="$style.rightInner">
        <slot name="right" />
      </div>
      <ModalClose
        v-if="!unclosable"
        :class="$style.close"
        @click="$emit('close')"
        v-test="'_base-modal-close'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import ModalClose from '@/components/_shared/ModalClose.vue';
import type { PropType } from 'vue';

type Route = {
  heading: string;
  label: string;
  name: string;
};

export default {
  components: {
    ModalClose,
  },
  props: {
    heading: String,
    unclosable: Boolean,
    hasBackButton: Boolean,
    rightAlign: Boolean,
    grey: Boolean,
    noBorder: Boolean,
    label: [String, Array],
    routes: Array as PropType<Route[]>,
    previewMode: Boolean,
  },
  emits: ['close', 'back'],
  setup() {
    return {
      filters,
    };
  },
  computed: {
    labels(): any[] {
      return this.label
        ? typeof this.label === 'string'
          ? [this.label]
          : this.label
        : [];
    },
    activeRoute() {
      return this.routes?.find((route) => route.name === this.$route.name);
    },
    hasNav() {
      return this.routes?.length && this.routes?.length > 1;
    },
    headingText() {
      return this.heading || this.activeRoute?.heading;
    },
    isEmpty() {
      return !this.headingText && !this.labels.length && !this.hasNav;
    },
  },
  methods: {
    handleBackButton() {
      if (this.hasBackButton) {
        this.$emit('back');
      }
    },
  },
};
</script>

<style lang="scss" module>
.base {
  background-color: white;
  border-radius: $modal-radius $modal-radius 0 0;

  &.grey {
    background-color: $grey-light;
    border-bottom: 1px solid $color-border;
  }

  &:not(.smallScreen):not(.previewMode) {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  &:not(.empty) {
    border-bottom: 1px solid $color-border;
  }

  &.noBorder {
    border-bottom: none;
  }
}

.heading {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 24px;
  gap: $spacing * 0.5 $spacing;

  .base.smallScreen:not(.rightAlign) & {
    &:not(.unclosable) {
      padding-right: 3 * $spacing;
    }
  }

  &:empty {
    display: none;
  }
}

.labels {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing * 0.5;

  &:global(.v-enter-active),
  &:global(.v-leave-active) {
    transition:
      transform 0.2s $easeOutBack,
      opacity 0.1s linear;
  }

  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    transform: scale(0.5);
    opacity: 0;
  }

  &:empty {
    display: none;
  }
}

.controls {
  display: flex;
  align-items: flex-end;
}

.nav {
  display: flex;
  position: relative;
  top: 1px;

  .base.smallScreen & {
    width: 100%;
    justify-content: center;
  }

  .base.previewMode & {
    margin-left: $spacing;
  }

  &:empty {
    display: none;
  }
}

.navItem {
  padding: 12px $spacing;
  border: 1px solid transparent;
  border-bottom: none;

  &.isActive {
    background-color: $grey-light;
    border-radius: $modal-radius $modal-radius 0 0;
    border: 1px solid $color-border;
    border-bottom: none;
  }

  .base.smallScreen &,
  .base.mediumScreen.previewMode & {
    font-size: 12px;
  }
}

.right {
  display: flex;
  padding: $spacing * 0.5;

  &:empty {
    display: none;
  }

  .base.smallScreen &,
  .base.previewMode & {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.rightInner {
  &:empty {
    display: none;
  }
}

.close {
  flex-shrink: 0;

  .base.empty & {
    position: absolute;
    right: $spacing * 0.5;
    top: $spacing * 0.5;
  }
}
</style>
