<template>
  <div :class="$style.base">
    <div v-if="inputs" v-test="'baseRangeInputs'">
      <BaseGrid container :class="$style.inputs">
        <BaseGrid :size="2">
          {{ $t('global.from') }}
        </BaseGrid>
        <BaseGrid :size="3" v-test="'baseRangeInputFrom'">
          <BaseInput v-model="fromValue" type="number" />
        </BaseGrid>
        <BaseGrid :size="2">
          {{ $t('global.to') }}
        </BaseGrid>
        <BaseGrid :size="3" v-test="'baseRangeInputTo'">
          <BaseInput v-model="toValue" type="number" />
        </BaseGrid>
      </BaseGrid>
    </div>
    <div :class="$style.slider">
      <Slider
        v-model="sliderValues"
        :class="$style.sliderOverrides"
        :min="min"
        :max="max"
        :tooltips="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Slider from '@vueform/slider';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Slider,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    inputs: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    sliderValues: {
      get() {
        return this.modelValue.map((value) =>
          value > this.max ? this.max : value,
        );
      },
      set(value) {
        this.emitValue(value);
      },
    },
    fromValue: {
      get() {
        return this.modelValue[0];
      },
      set(value) {
        this.emitValue([value || 0, this.modelValue[1]]);
      },
    },
    toValue: {
      get() {
        return this.modelValue[1];
      },
      set(value) {
        this.emitValue([this.modelValue[0], value || 0]);
      },
    },
  },
  methods: {
    emitValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
});
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss" module>
.inputs {
  align-items: center;
  margin-bottom: $spacing * 0.25;
}

.slider {
  padding: 0 12px;
}

.sliderOverrides {
  --slider-connect-bg: #4c49c5;
  --slider-handle-ring-width: 0px;
  --slider-handle-width: 22px;
  --slider-handle-height: 22px;
}
</style>
