<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-register')"
    :text="{
      title: $t('upgrade_subscription_page.register.gift_cards.title'),
      description: $t(
        'upgrade_subscription_page.register.gift_cards.description'
      ),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    :moreLink="{
      text: $t('upgrade_subscription_page.register.gift_cards.learn_more'),
      url: $t('upgrade_subscription_page.register.gift_cards.learn_more_url')
    }"
    imageName="gift-cards"
  />

  <div v-else>
    <CreateGiftcard
      v-if="showCreateModal"
      @submit="onCreateGiftcard"
      @close="showCreateModal = false"
    />

    <router-view />

    <PageHeader>
      <template #left>
        <BaseDropdown
          v-model="variables.filterScope"
          :options="[
            {
              value: null,
              label: $t('global.all')
            },
            {
              value: 'ACTIVE',
              label: $t('global.valid')
            },
            {
              value: 'EMPTY',
              label: $t('global.empty')
            },
            {
              value: 'EXPIRED',
              label: $t('global.expired')
            },
            {
              value: 'ONLINE',
              label: $t('global.online')
            }
          ]"
          :label="$t('global.status')"
          v-test="'giftcards-state'"
        />
        <BaseSearch
          v-model="variables.search"
          debounce
          v-test="'giftcards-search'"
        />
      </template>
      <template #right>
        <BaseDropdown
          :options="[
            {
              label: '.csv',
              value: 'csv'
            },
            {
              label: '.xls',
              value: 'xls'
            }
          ]"
          :placeholder="$t('global.actions.export')"
          @update:modelValue="exportData"
          v-test="'giftcards-export'"
        />
        <BaseButton
          @click="showCreateModal = true"
          v-test="'giftcards-btn-create'"
        >
          {{
            filters.capitalize(
              $t('global.actions.import_item', {
                item: $t('global.items.giftcard', 1)
              })
            )
          }}
        </BaseButton>
      </template>
    </PageHeader>

    <BaseTable
      v-if="giftcards && giftcards.length"
      :sortOrder="{
        sortBy: variables.sortBy,
        direction: variables.direction
      }"
      :headers="[
        {
          value: $t('global.date'),
          sort: 'created_at'
        },
        {
          value: $t('global.number'),
          sort: 'code'
        },
        {
          value: $t('global.amount_currency'),
          alignRight: true,
          sort: 'total_amount'
        },
        {
          value: $t('global.used'),
          alignRight: true
        },
        {
          value: $t('global.remaining'),
          alignRight: true
        },
        {
          value: $t('global.expires'),
          sort: 'expires_at'
        },
        $t('global.state')
      ]"
      :rows="
        giftcards.map((card) => ({
          routerLink: {
            name: 'gift-card',
            params: {
              giftcardId: card.id
            }
          },
          cells: [
            filters.date(card.createdAt),
            filters.giftCardCode(card.code),
            {
              value: filters.currency(card.totalAmount),
              alignRight: true
            },
            {
              value: filters.currency(card.usedAmount),
              alignRight: true
            },
            {
              value: filters.currency(card.remainingAmount),
              alignRight: true
            },
            filters.date(card.expiresAt),
            {
              label: card.status.toLowerCase()
            }
          ]
        }))
      "
      @sort="
        ($event) => {
          variables.sortBy = $event.sortBy;
          variables.direction = $event.direction;
        }
      "
      v-test="'register-giftcards'"
    />

    <EmptyPageContent
      v-show="!loading && !giftcards.length"
      imageName="gift-cards"
      :text="
        showEmptyPageContent
          ? {
              title: $t('giftcards.empty_state.title'),
              description: $t('giftcards.empty_state.description'),
              btnPrimary: filters.capitalize(
                $t('global.actions.create_item', {
                  item: $t('global.items.giftcard', 1)
                })
              ),
              btnSecondary: filters.capitalize(
                $t('global.actions.import_item', {
                  item: $t('global.items.giftcard', 1)
                })
              )
            }
          : null
      "
      :moreLink="{
        text: $t('giftcards.empty_state.more_text'),
        url: $t('giftcards.empty_state.more_url')
      }"
      @click="onEmptyPageClick"
      v-test="'register-giftcards-noresults'"
    />

    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>
<script lang="ts">
import filters from '@/filters';
import { flash } from '@/helpers/ui';
import PageHeader from '@/modules/PageHeader.vue';
import gql from 'graphql-tag';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';
import CreateGiftcard from './CreateGiftcard.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import config from '@/config';
import { defineComponent } from 'vue';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';

export default defineComponent({
  name: 'GiftCards',
  components: {
    PageHeader,
    LoadMore,
    CreateGiftcard,
    EmptyPageContent,
    EmptySubscriptionPage,
  },
  setup() {
    const { hasFeatureFlag } = useUserStore();
    const { giftcards, loading, variables, allDataFetched, fetchMore } =
      usePagination({
        fieldName: 'giftcards',
        query: gql`
          query getGiftcards(
            $search: String
            $pagination: PaginationAttributes
            $filterScope: GiftcardFilterScope
            $sortBy: String
            $direction: SortDirectionEnum
          ) {
            giftcards(
              search: $search
              pagination: $pagination
              filterScope: $filterScope
              sortBy: $sortBy
              direction: $direction
            ) {
              code
              createdAt
              expiresAt
              id
              remainingAmount
              status
              totalAmount
              usedAmount
            }
          }
        `,
        variables: {
          search: '',
          filterScope: null,
          sortBy: 'created_at',
          direction: 'DESC',
        },
      });

    return {
      giftcards,
      loading,
      variables,
      fetchMore,
      allDataFetched,
      filters,
      hasFeatureFlag,
    };
  },
  data() {
    return {
      showCreateModal: false,
    };
  },
  computed: {
    showEmptyPageContent() {
      return (
        !this.variables.filterScope &&
        !this.variables.search &&
        !this.giftcards.length
      );
    },
  },
  methods: {
    exportData(type) {
      window.open(
        `${config.backendUrl}/api/giftcards/download.${type}`,
        '_blank',
      );
    },
    onCreateGiftcard({ giftcard }) {
      const input = {
        code: giftcard.code,
        expiresAt: giftcard.expires_at || giftcard.expiresAt || null,
        totalAmount: giftcard.total_amount || giftcard.totalAmount,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createGiftcard($input: CreateGiftcardInput!) {
              createGiftcard(input: $input) {
                giftcard {
                  id
                }
              }
            }
          `,
          variables: {
            input,
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'giftcards' });
            cache.gc();
          },
        })
        .then(() => {
          flash(this.$t('global.flash.giftcard_created'));
          this.showCreateModal = false;
        });
    },
    onEmptyPageClick(button) {
      if (button === 'primary') {
        this.$router.push({ name: 'register-builder-giftcard' });
      } else if (button === 'secondary') {
        this.showCreateModal = true;
      }
    },
  },
});
</script>
