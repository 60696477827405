<template>
  <div :class="$style.base">
    <BaseHeading :mb="0.5" ml>
      {{ $t('products.select_your_supplier') }}
    </BaseHeading>
    <BaseSpinner v-if="loading" />
    <div v-else-if="suppliers?.length" :class="$style.cards">
      <Card
        v-for="supplier in suppliers"
        :key="supplier.id"
        :supplier="supplier"
        :loading="selectedSupplier?.id === supplier.id"
        :disabled="selectedSupplier && selectedSupplier.id !== supplier.id"
        @selectSupplier="onClick(supplier)"
        v-test="'chooseSupplierCard'"
      />
    </div>
    <EmptyPageContent
      v-else
      imageName="products"
      :text="{
        title: $t('products.empty_state.stock_orders_suppliers.title'),
        description: $t(
          'products.empty_state.stock_orders_suppliers.description'
        ),
        btnPrimary: $t('global.actions.create_supplier')
      }"
      @click="$router.push({ name: 'create-supplier' })"
    />
  </div>
</template>

<script lang="ts" setup>
import Card from './Card.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useLocationsStore } from '@/stores/locations';
import type { Supplier } from '@/types';
import { ref } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { CREATE_STOCK_ORDER } from './graphql';
import { useRouter } from 'vue-router';

withDefaults(
  defineProps<{
    suppliers: Supplier[];
    loading: boolean;
  }>(),
  {
    loading: true,
  },
);

const router = useRouter();

const selectedSupplier = ref<Supplier | null>(null);

const onClick = (supplier: Supplier) => {
  selectedSupplier.value = supplier;

  const { locationId } = useLocationsStore();
  const { mutate } = useMutation(CREATE_STOCK_ORDER, {
    variables: {
      input: {
        supplierId: supplier.id,
        locationId,
      },
    },
  });

  mutate()
    .then((response: any) => {
      const stockOrderId = response.data.createStockOrder.stockOrder.id;
      router.push({ name: 'products-order-build', params: { stockOrderId } });
    })
    .finally(() => {
      selectedSupplier.value = null;
    });
};
</script>

<style lang="scss" module>
.base {
  padding-top: $spacing;
}

.cards {
  padding: $spacing * 0.5;
  display: flex;
  flex-wrap: wrap;
}
</style>
