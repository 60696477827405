<template>
  <BaseModalSmall @close="backToAccount">
    <DisableTwoFactorAuthentication
      :passwordInvalid="passwordFailed"
      :otpCodeInvalid="optCodeFailed"
      @disable="disableTwoFactor"
    />
  </BaseModalSmall>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import DisableTwoFactorAuthentication from '../DisableTwoFactorAuthentication.vue';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    DisableTwoFactorAuthentication,
  },
  inject: ['mixpanel'],
  data() {
    return {
      optCodeFailed: false,
      passwordFailed: false,
    };
  },
  methods: {
    backToAccount() {
      this.$router.push({ name: 'account' });
    },
    disableTwoFactor(attributes) {
      this.optCodeFailed = false;
      this.passwordFailed = false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateTwoFactorSettings(
              $input: UpdateTwoFactorSettingsInput!
            ) {
              updateTwoFactorSettings(input: $input) {
                twoFactorSettings {
                  status
                  mode
                }
                errors
              }
            }
          `,
          variables: {
            input: {
              twoFactorCode: attributes.otp,
              userPassword: attributes.password,
              status: 'DISABLED',
            },
          },
        })
        .then((response) => {
          response = response.data.updateTwoFactorSettings;
          this.twoFactorCode = '';
          this.userPassword = '';
          if (response.errors?.twoFactorCode) {
            this.optCodeFailed = true;
          } else if (response.errors?.userPassword) {
            this.passwordFailed = true;
          } else {
            const { getUser } = useUserStore();
            getUser().then(() => {
              this.$router.push({ name: 'account' });
            });

            flash(
              this.$t(
                'account.auth.two_factor_settings.notifications.disabled',
              ),
            );
            this.mixpanel.track('2FA disabled', {
              method: response.twoFactorSettings?.mode,
            });
          }
        });
    },
  },
});
</script>
