import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useQuery } from '@vue/apollo-composable';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { GET_CUSTOMER } from './graphql';

export const setCustomerData = () => {
  const loading = ref(false);
  const route = useRoute();
  const router = useRouter();

  const loadData = () => {
    const { onResult } = useQuery(GET_CUSTOMER, {
      id: Number(route.query.customerId),
    });
    const { order, reset } = useRegisterOrderStore();
    const { selectedCustomer } = storeToRefs(useRegisterOrderStore());

    loading.value = true;

    onResult((result) => {
      const customer = result.data.customer;

      reset();
      selectedCustomer.value = customer;
      order.customerId = customer.id;

      router.replace({ query: {} });
      loading.value = false;
    });
  };

  return {
    loadData,
    loading,
  };
};
