<template>
  <BaseVisualBanner
    v-if="hasResourcePriceBanner"
    :heading="small ? '' : $t(`${type}.adding_extra`)"
    :description="innerText"
    :primaryAction="
      learnMoreLink
        ? {
            text: $t('global.learn_more'),
            href: link
          }
        : undefined
    "
    imagePath="/img/onboarding/employee_info.svg"
    variant="small"
    color="blue"
    :mt="mt"
    :mb="mb"
    v-test="'resource-price'"
  />
</template>

<script lang="ts">
import filters from '@/filters';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { defineComponent, computed } from 'vue';

const employeePricesPerPlan = {
  pro: 750,
  basic: 750,
  appointments: 500,
  register: 500,
};

export default defineComponent({
  props: {
    type: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    learnMoreLink: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isTreatwellUser, company } = useCompanyStore();
    const hasResourcePriceBanner = computed(
      () =>
        !(
          props.type === 'resource' &&
          company.activeSubscription?.plan === 'pro'
        ) && !isTreatwellUser,
    );

    return {
      filters,
      hasResourcePriceBanner,
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['company', 'isTrial']),
    innerText() {
      let translationKey;
      if (this.learnMoreLink) {
        translationKey = `${this.type}.${this.isTrial ? 'trial_' : ''}extra`;
      } else {
        translationKey = `${this.type}.${this.isTrial ? 'trial_' : ''}price_info`;
      }

      if (
        this.company.activeSubscription?.plan &&
        employeePricesPerPlan[this.company.activeSubscription.plan]
      ) {
        return this.$t(translationKey, {
          price: this.filters.currency(
            employeePricesPerPlan[this.company.activeSubscription.plan],
          ),
        });
      }

      return this.$t(`${this.type}.trial_charge_info`);
    },
    link() {
      const supportedLanguages = ['nl', 'en', 'es', 'de', 'pt'];
      const companyLanguage = this.company.language;
      let linkLanguage;
      if (supportedLanguages.includes(companyLanguage)) {
        linkLanguage = companyLanguage === 'pt' ? 'pt-BR' : companyLanguage;
      } else {
        linkLanguage = 'en';
      }
      return `https://help.salonized.com/${linkLanguage}/articles/572113-pricing`;
    },
  },
});
</script>

<style lang="scss" module>
.image {
  margin-top: $spacing * -2;

  &:not(.small) {
    width: 150px;
  }

  &.small {
    width: 100px;
  }
}
</style>
