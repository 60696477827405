<template>
  <BaseCard :heading="$t('account.auth.two_factor_heading')" mb>
    <template #header>
      <BaseLabel
        v-if="is2faActive"
        state="active"
        v-test="'two-factor-active-label'"
      />
    </template>
    <EmployeeList
      v-if="showEmployeeList"
      :companyUsers="employees"
      @close="showEmployeeList = false"
    />
    <BaseText mb>
      {{ $t('account.auth.two_factor_description') }}
    </BaseText>
    <BaseButton
      v-if="!is2faActive"
      :routerLink="{ name: 'enable-two-factor-auth-settings' }"
      mr
      v-test="'two-factor-enable'"
    >
      {{ $t('account.auth.two_factor_enable') }}
    </BaseButton>
    <BaseButton
      v-if="is2faActive"
      :routerLink="{ name: 'disable-two-factor-auth-settings' }"
      mr
      color="inverted"
      v-test="'two-factor-disable'"
    >
      {{ $t('account.auth.two_factor_disable') }}
    </BaseButton>
    <BaseText
      v-if="!is2faActive"
      link
      :href="helpPageUrl"
      target="_blank"
      v-test="'two-factor-info'"
    >
      {{ $t('account.auth.two_factor_settings.help') }}
    </BaseText>
    <BaseButton
      v-if="canSeeEmployeeList"
      mt
      color="inverted"
      @click="showEmployeeList = true"
      v-test="'show-employee-list'"
    >
      {{ $t('account.auth.employee_list.header') }}
    </BaseButton>
  </BaseCard>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import EmployeeList from './EmployeeList.vue';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    EmployeeList,
  },
  props: {
    is2faActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      showEmployeeList: false,
      userCount: 0,
    };
  },
  computed: {
    ...mapState(useUserStore, ['user']),
    ...mapState(useCompanyStore, ['company']),
    canSeeEmployeeList() {
      return this.is2faActive && this.user.admin && this.userCount > 0;
    },
    helpPageUrl() {
      const companyLanguage = this.company.language;
      const blogLanguage = ['en', 'nl'].includes(companyLanguage)
        ? companyLanguage
        : 'en';
      return `https://help.salonized.com/${blogLanguage}/articles/6115892`;
    },
  },
  apollo: {
    employees: {
      query: gql`
        query getCompanyUsers {
          companyUsers {
            id
            firstName
            twoFactorSettings {
              status
              mode
            }
          }
        }
      `,
      result() {
        this.userCount = this.employees.length;
      },
      update(data) {
        return data.companyUsers;
      },
    },
  },
});
</script>
