<template>
  <NavItem :selected="activeView === 'about'" @click="$emit('nav', 'about')">
    <BaseText v-if="$screen === 's'">{{
      $t('service.nav.about.title')
    }}</BaseText>
    <General v-else />
  </NavItem>

  <NavItem
    :selected="activeView === 'resources'"
    @click="$emit('nav', 'resources')"
  >
    <BaseText v-if="$screen === 's'">{{
      $t('service.nav.resources.title')
    }}</BaseText>
    <Resources v-else />
  </NavItem>

  <NavItem
    :selected="activeView === 'advanced'"
    @click="$emit('nav', 'advanced')"
  >
    <BaseText v-if="$screen === 's'">{{
      $t('service.nav.advanced.title')
    }}</BaseText>
    <Advanced v-else />
  </NavItem>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ServiceVariationsNav',
});
</script>

<script setup lang="ts">
import NavItem from './NavItem.vue';
import General from '../service-details/General.vue';
import Resources from '../service-details/Resources.vue';
import Advanced from '../service-details/Advanced.vue';

defineProps<{
  activeView: null | 'about' | 'resources' | 'advanced';
}>();

defineEmits(['nav']);
</script>
