<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-register')"
    :text="{
      title: $t('upgrade_subscription_page.register.checkout.title'),
      description: $t(
        'upgrade_subscription_page.register.checkout.description'
      ),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    :moreLink="{
      text: $t('upgrade_subscription_page.register.checkout.learn_more'),
      url: $t('upgrade_subscription_page.register.checkout.learn_more_url')
    }"
    imageName="register"
    v-test="'empty-page'"
  />

  <BaseSpinner v-else-if="loadingData" />

  <ResizableContainer v-else>
    <template #left>
      <Browser
        v-if="showMobileBrowser || $screen !== 's'"
        @hideMobileBrowser="showMobileBrowser = false"
        @createNote="showCreateNoteModal = true"
        v-test="'register-browser'"
      />
    </template>
    <template #right>
      <Order
        v-if="!showMobileBrowser || $screen !== 's'"
        @showMobileBrowser="showMobileBrowser = true"
        @editNote="showCreateNoteModal = true"
        v-test="'register-order'"
      />
    </template>
  </ResizableContainer>
</template>

<script lang="ts">
export default {
  name: 'RegisterBuilder',
};
</script>

<script setup lang="ts">
import Browser from './browser/index.vue';
import Order from './order/index.vue';

import { ref, computed } from 'vue';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';
import { useRoute, useRouter } from 'vue-router';
import { useRegisterBrowserStore } from '@/modules/register/builder/browser/store';
import { storeToRefs } from 'pinia';
import { useRegisterOrderStore } from '../stores/order';
import { setAppointmentData } from './actions/appointment';
import { setOrderData } from './actions/order';
import { setCustomerData } from './actions/customer';
import { TransactionType } from '@/types';
import ResizableContainer from './ResizableContainer.vue';

const showCreateNoteModal = ref(false);
const { hasFeatureFlag } = useUserStore();
const { showMobileBrowser } = storeToRefs(useRegisterBrowserStore());
const { order, reset } = useRegisterOrderStore();
const route = useRoute();
const router = useRouter();

const { loadData: loadAppointment, loading: loadingAppointment } =
  setAppointmentData();
const { loadData: loadOrder, loading: loadingOrder } = setOrderData();
const { loadData: loadCustomer, loading: loadingCustomer } = setCustomerData();
const loadingData = computed(
  () => loadingAppointment.value || loadingOrder.value || loadingCustomer.value
);

const orderIsNotEditable = computed(() => {
  const hasPosTransaction = !!order.transactions.find(
    (t) => t.type === TransactionType.Pos
  );

  return (
    !order.permissions?.editOrderItems ||
    (hasPosTransaction && route.query.action !== 'modify')
  );
});

if (route.query.direct) {
  reset();
}

const checkRedirect = () => {
  if (route.query.direct) {
    router.replace({
      name: 'register-payment',
      query: {
        direct: true
      }
    });
  }
};

if (route.query.orderId) {
  loadOrder().then(() => {
    checkRedirect();
  });
} else if (route.query.appointmentId) {
  loadAppointment().then(() => {
    checkRedirect();
  });
} else if (route.query.customerId) {
  loadCustomer();
} else if (order.id && orderIsNotEditable.value) {
  reset();
}

if (!route.query.direct && Object.keys(route.query).length) {
  router.replace({ query: {} });
}

showMobileBrowser.value = false;

watch(
  route,
  (value) => {
    if (
      value.name !== 'register-builder' &&
      value.name !== 'register-payment'
    ) {
      showMobileBrowser.value = true;
    }
  },
  {
    immediate: true
  }
);

const { fetchFavorites } = useRegisterBrowserStore();
fetchFavorites();
</script>
