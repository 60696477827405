<template>
  <div :class="$style.base">
    <div>
      <BaseTableRow head>
        <BaseTableCell>{{ $t('global.name') }}</BaseTableCell>
        <div :class="$style.showMore">
          <BaseTableCell />
        </div>
      </BaseTableRow>

      <BaseTableRow
        v-for="(item, index) in categories"
        :key="`row-${index}`"
        :options="
          hasFeatureFlag('product-delete') ? ['edit', 'delete'] : ['edit']
        "
        zebra
        @optionClick="onOptionClick(item, $event)"
        v-test="'baseTableRow'"
      >
        <BaseTableCell>{{ item.name }}</BaseTableCell>
      </BaseTableRow>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

interface CategoryItem {
  id: number;
  name: string;
  amountProducts: number;
}

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell,
  },
  props: {
    categories: {
      type: Array as PropType<CategoryItem[]>,
      required: true,
    },
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
  },
  methods: {
    onOptionClick(item: CategoryItem, option: string) {
      if (option === 'edit') {
        this.$router.push({
          name: 'products-edit-category',
          params: { id: item.id },
        });
      }
      if (option === 'delete') {
        this.$router.push({
          name: 'products-delete-category',
          params: {
            id: item.id,
          },
          query: {
            products: item.amountProducts,
          },
        });
      }
    },
  },
});
</script>

<style lang="scss" module>
.base {
  overflow-x: auto;
  min-height: 70vh;
}

.showMore {
  width: 52px !important;
  flex: none !important;
}
</style>
