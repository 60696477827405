import { useUserStore } from '@/stores/user';
import { defineStore } from 'pinia';
import { computed } from 'vue';

export const useRegisterStore = defineStore('register', () => {
  const { hasFeatureFlag } = useUserStore();
  const cashupEnabled = computed(() => !!hasFeatureFlag('beta-cashup'));

  return {
    cashupEnabled,
  };
});
