<template>
  <div :class="$style.base">
    <div
      v-if="serviceSuggestions?.length"
      v-show="!$apollo.loading"
      :class="$style.inner"
    >
      <div :class="$style.label">
        <BaseText v-if="!selectedServiceIds[0]" v-test="'popular-text'">
          {{ $t('appointment.service_suggestions.popular') }}
        </BaseText>
        <BaseText v-else v-test="'complementary-text'">
          {{ $t('appointment.service_suggestions.complementary') }}
        </BaseText>
      </div>
      <div :class="$style.buttons">
        <BaseChip
          v-for="(service, index) in serviceSuggestions"
          :key="index"
          :text="service.name"
          icon="plus"
          clickable
          :color="service.category.color"
          @click="$emit('select', service.id)"
          v-test="'service-suggestion'"
        />
      </div>
    </div>
    <BaseSpinner v-show="$apollo.loading" inline :mb="0.5" />
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { computed, defineComponent } from 'vue';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';

export default defineComponent({
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  emits: ['select'],
  setup() {
    const { formData } = useCreateAppointmentStore();

    const selectedServiceIds = computed(() => [
      ...new Set(
        formData.partsAttributes.map((p) => p.serviceId).filter((id) => !!id),
      ),
    ]);

    return {
      formData,
      selectedServiceIds,
    };
  },
  apollo: {
    serviceSuggestions: {
      query: gql`
        query getServiceSuggestions(
          $serviceIds: [Int!]
          $customerId: Int
          $limit: Int
        ) {
          serviceSuggestions(
            serviceIds: $serviceIds
            customerId: $customerId
            limit: $limit
          ) {
            id
            name
            category {
              color
            }
          }
        }
      `,
      variables() {
        return {
          serviceIds: this.selectedServiceIds,
          customerId: this.formData.customerId,
          limit: 3,
        };
      },
      fetchPolicy: 'cache-first',
    },
  },
});
</script>

<style lang="scss" module>
.inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $spacing * -0.5;
}

.label {
  margin-right: $spacing * 0.5;
  margin-bottom: $spacing * 0.5;
}

.buttons {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: $spacing * 0.5;

    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }
}
</style>
