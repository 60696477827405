<template>
  <div
    :class="[
      $style.base,
      {
        [$style.multiline]: multiline || $screen === 's'
      }
    ]"
  >
    <input ref="input" :class="$style.invisibleInput" :value="code" />
    <div :class="[$style.inner]">
      <div :class="$style.codeField">
        <BaseText v-if="code" color="secondary" v-test="'code-snippet'">
          {{ code }}
        </BaseText>
        <div v-else :class="$style.center">
          <BaseButton
            :loading="loading"
            @click="$emit('generate')"
            v-test="'generateCode'"
          >
            {{ $t('custom_booking_widget.generate_code') }}
          </BaseButton>
        </div>
      </div>
      <div :class="$style.buttons">
        <BaseButton
          :disabled="!code || showCopiedMessage"
          :color="buttonColor"
          @click="copyCode"
          v-test="'test'"
        >
          {{
            showCopiedMessage
              ? $t('custom_booking_widget.copied')
              : $t('custom_booking_widget.copy_to_clipboard')
          }}
        </BaseButton>
        <BaseButton
          v-if="isUrl"
          :disabled="!code || showCopiedMessage"
          :color="buttonColor"
          icon="external_link"
          :href="code"
          v-test="'test'"
        >
          {{ $t('global.actions.open') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

interface CodeFieldProps {
  code?: string;
  loading?: boolean;
  multiline?: boolean;
  buttonColor?: 'primary' | 'inverted';
  isUrl?: boolean;
}

withDefaults(defineProps<CodeFieldProps>(), {
  buttonColor: 'primary',
});

const emit = defineEmits(['generate', 'copy']);

const showCopiedMessage = ref(false);
const input = ref<HTMLInputElement>();

const copyCode = () => {
  if (input.value) {
    showCopiedMessage.value = true;

    input.value.select();
    input.value.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setTimeout(() => {
      showCopiedMessage.value = false;
    }, 3000);

    emit('copy');
  }
};
</script>

<style lang="scss" module>
.base {
  position: relative;
}

.inner {
  display: flex;
  align-items: center;
  padding: 0;

  .base.multiline & {
    flex-direction: column;
    align-items: stretch;
  }
}

.codeField {
  display: flex;
  align-items: center;
  background: $grey-light;
  padding: $spacing;
  margin-right: $spacing;
  border-radius: $radius;
  border: 1px dashed #00000050;
  overflow-x: auto;
  white-space: pre;

  .base.multiline & {
    word-break: break-all;
    white-space: initial;
    margin-bottom: $spacing;
    margin-right: 0;
  }
}

.center {
  align-self: center;
}

.invisibleInput {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.buttons {
  display: flex;
  align-items: center;
  gap: $spacing;
  flex-wrap: wrap;
}
</style>
