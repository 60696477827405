<template>
  <div
    v-if="appointment && appointment.parts && appointment.parts.length"
    v-test="'service-list'"
  >
    <Parts :data="partData" />

    <div v-if="appointment.treatwell">
      <BaseAlert
        icon="treatwell"
        size="s"
        inline
        mt
        :color="appointment.paidThroughTreatwell ? 'success' : 'warning'"
        :text="twIconTooltip"
        v-test="'treatwell-logo'"
      />
    </div>
    <AppointmentDiscountCode
      :discountCode="appointment.discountCode"
      mt
      size="s"
      inline
      v-test="'appointment-discount-code'"
    />

    <BaseAlert
      v-if="!appointment.discountCode && appointment.priceAdjustment"
      :text="
        $t(
          appointment.priceAdjustment.adjustmentType === 'PERCENTAGE_INCREASE'
            ? 'dynamic_pricing.notification_increase'
            : 'dynamic_pricing.notification_discount',
          { amount: appointment.priceAdjustment.amount + '%' }
        )
      "
      icon="percentage"
      size="s"
      mt
      inline
      v-test="'appointment-price-adjustment'"
    />
  </div>
</template>

<script setup lang="ts">
import AppointmentDiscountCode from '@/components/AppointmentDiscountCode.vue';
import { useI18n } from 'vue-i18n';
import Parts from './Parts.vue';

const props = defineProps<{
  appointment: any;
}>();

const { t } = useI18n();

const partData = computed(() => {
  if (props.appointment.endBuffer) {
    const bufferPart = [
      {
        name: t('appointment.features.end_buffer'),
        duration: props.appointment.endBuffer,
      },
    ];
    return [...props.appointment.parts, ...bufferPart];
  } else {
    return props.appointment.parts;
  }
});

const twIconTooltip = computed(() => {
  const reference = t('appointment.tw_order_reference', {
    number: props.appointment.treatwellOrder?.reference,
  });

  if (props.appointment.paidThroughTreatwell) {
    return `${t('appointment.paid_online')} - ${reference}`;
  } else {
    return `${t('appointment.outstanding_payment')} - ${reference}`;
  }
});
</script>
