<template>
  <BasePopover
    :position="position"
    closeButton
    @close="$emit('close')"
    v-test="'calendar-popover-more'"
  >
    <div :class="$style.header">
      {{
        filters.date(date, {
          format: 'long'
        })
      }}
    </div>
    <div :class="$style.list">
      <div
        v-for="(item, index) in data.items"
        :key="index"
        :class="$style.item"
        @click="onItemClick($event, item)"
        v-html="createItem(item.event._def)"
      />
    </div>
  </BasePopover>
</template>

<script lang="ts">
import filters from '@/filters';
import createItem from '../calendar-content/CreateCalendarItem';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'eventClick'],
  setup() {
    return {
      filters,
    };
  },
  computed: {
    date() {
      return this.data.items ? this.data.items[0].start : null;
    },
  },
  methods: {
    onItemClick($event, item) {
      if (item.event.extendedProps.type === 'waiting_list') {
        this.$router.push({
          name: 'calendar-waiting-list',
          query: { date: item.event.startStr },
        });
      } else {
        this.$emit('eventClick', {
          event: item.event,
          el: $event.target,
          jsEvent: $event,
        });
      }
    },
    createItem(event) {
      return createItem({ event });
    },
  },
});
</script>

<style lang="scss" module>
.item {
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.25;
  }
}

.header {
  margin-bottom: $spacing;
}

.list {
  overflow-y: auto;
  max-height: 340px;
}
</style>
