import { useI18n } from 'vue-i18n';

enum Category {
  Before = 'BEFORE_APPOINTMENT',
  Change = 'APPOINTMENT_CHANGE',
  After = 'AFTER_APPOINTMENT',
  Other = 'OTHER',
}

export const useTemplateData = () => {
  const { t } = useI18n();

  const templates = [
    {
      id: 'appointment_confirmation_email',
      category: Category.Before,
    },
    {
      id: 'recurring_appointment_confirmation_email',
      category: Category.Before,
    },
    {
      id: 'appointment_reminder_email',
      category: Category.Before,
    },
    {
      id: 'appointment_pending_confirmation_email',
      category: Category.Before,
    },
    {
      id: 'appointment_rejection_email',
      category: Category.Before,
    },
    {
      id: 'appointment_moved_email',
      category: Category.Change,
    },
    {
      id: 'recurring_appointment_moved_email',
      category: Category.Change,
    },
    {
      id: 'appointment_cancelled_email',
      category: Category.Change,
    },
    {
      id: 'appointment_no_show_email',
      category: Category.Change,
    },
    {
      id: 'digital_invoice',
      category: Category.After,
    },
    {
      id: 'feedback_request_email',
      category: Category.After,
    },
    {
      id: 'feedback_publish_email',
      category: Category.After,
    },
    {
      id: 'birthday_message',
      category: Category.Other,
    },
  ].map((template) => ({
    ...template,
    heading: t(`email_templates.templates.${template.id}.heading`),
    description: t(`email_templates.templates.${template.id}.description`),
  }));

  return {
    templates,
  };
};
