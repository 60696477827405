<template>
  <div>
    <div
      v-if="showNoResults && !loading && items && !items.length"
      :class="$style.noResults"
      v-test="'noResults'"
    >
      <BaseText>
        {{ $t('global.no_results') }}
      </BaseText>
    </div>
    <LoadMore v-if="showLoadMore" @visibilityChanged="visibilityChanged" />
    <BaseSpinner v-else v-show="loading" />
  </div>
</template>

<script lang="ts">
import apolloClient from '@/apollo/client';
import LoadMore from '../LoadMore.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LoadMore,
  },
  props: {
    items: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fieldName: {
      type: String,
      required: true,
    },
    apolloQuery: {
      type: Object,
    },
    search: {
      type: String,
      required: false,
    },
    extraVariables: {
      type: Object,
      required: false,
    },
    showNoResults: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      required: false,
      default: 25,
    },
  },
  data() {
    return {
      currentPage: 1,
      allDataLoaded: false,
      isRefetching: false,
    };
  },
  watch: {
    'items.length'(newVal, oldVal) {
      if (newVal !== oldVal && newVal === this.perPage) {
        this.reset();
      }
    },
    search() {
      this.refetchQuery();
    },
    'extraVariables.locationId'(newValue, oldValue) {
      if (newValue && oldValue && newValue !== oldValue) {
        this.clearCache();
        this.refetchQuery();
      }
    },
  },
  computed: {
    showLoadMore() {
      return (
        !!this.items?.length &&
        !this.allDataLoaded &&
        this.items.length >= this.perPage
      );
    },
  },
  methods: {
    reset() {
      this.allDataLoaded = false;
      this.currentPage = 1;
    },
    clearCache() {
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: this.fieldName });
      apolloClient.cache.gc();
    },
    refetchQuery() {
      this.isRefetching = true;

      this.reset();

      this.apolloQuery
        .refetch({
          search: this.search,
          pagination: {
            currentPage: this.currentPage,
            perPage: this.perPage,
          },
          ...this.extraVariables,
        })
        .finally(() => {
          this.isRefetching = false;
        });
    },
    visibilityChanged(isVisible) {
      if (
        this.items &&
        this.items.length >= this.perPage &&
        isVisible &&
        !this.allDataLoaded
      ) {
        this.showMore();
      }
    },
    showMore() {
      this.currentPage++;
      this.apolloQuery
        .fetchMore({
          variables: {
            search: this.search,
            pagination: {
              currentPage: this.currentPage,
              perPage: this.perPage,
            },
            ...this.extraVariables,
          },
        })
        .then((response) => {
          const newEntries = response.data[this.fieldName];
          this.allDataLoaded = newEntries.length < this.perPage;
        });
    },
  },
  created() {
    this.clearCache();
  },
});
</script>

<style lang="scss" module>
.noResults {
  text-align: center;
  margin: $spacing * 3 0;
}
</style>
