<template>
  <BaseModal
    :heading="$t('customers.ipl_treatment')"
    small
    useForm
    @submit="submit"
    @close="close"
  >
    <BaseInput
      v-model="description"
      :label="$t('global.description')"
      required
      focus
      mb
      v-test="'ipl-treatment-description'"
    />
    <BaseInput
      v-model="amount"
      :label="$t('global.amount')"
      type="number"
      :maxValue="40"
      required
      v-test="'ipl-treatment-amount'"
    />
    <template #footer>
      <BaseButton submitForm :loading="saving" v-test="'ipl-treatment-submit'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { useMutation } from '@vue/apollo-composable';
import { ref, inject } from 'vue';
import { CREATE_IPL_TREATMENT, UPDATE_IPL_TREATMENT } from './graphql';
import type { IplTreatment } from './types';

const props = defineProps<{
  modelValue: IplTreatment | null;
}>();

const emit = defineEmits(['close', 'submitted', 'update:modelValue']);

const customer = inject<any>('customer');

const description = ref(props.modelValue?.description || '');
const amount = ref(props.modelValue?.treatments || 8);

const { mutate: createTreatment } = useMutation(CREATE_IPL_TREATMENT);
const { mutate: updateTreatment } = useMutation(UPDATE_IPL_TREATMENT);

const saving = ref(false);

const close = () => {
  emit('update:modelValue', null);
  emit('close');
};

const submit = () => {
  saving.value = true;

  if (props.modelValue) {
    updateTreatment({
      input: {
        description: description.value,
        id: props.modelValue.id,
        treatments: amount.value,
      },
    }).then(
      ({
        data: {
          updateIplTreatment: { iplTreatment },
        },
      }) => {
        afterSave(iplTreatment);
      },
    );
  } else {
    createTreatment({
      input: {
        customerId: customer.value.id,
        description: description.value,
        treatments: amount.value,
      },
    }).then(
      ({
        data: {
          createIplTreatment: { iplTreatment },
        },
      }) => {
        afterSave(iplTreatment);
      },
    );
  }
};

const afterSave = (iplTreatment: IplTreatment) => {
  emit('submitted', iplTreatment);
  saving.value = false;
  close();
};
</script>
