<template>
  <div>
    <Flex align="center" justify="between" wrap mb>
      <div :class="$style.alertWrap">
        <BaseAlert :text="$t('imports.info_mapcolumns')" />
      </div>
      <div :class="$style.buttonWrap">
        <BaseButton :loading="saving" @click="submit" v-test="'import-save'">{{
          $t('global.actions.submit')
        }}</BaseButton>
      </div>
    </Flex>

    <div :class="$style.table">
      <div :class="$style.row">
        <div
          v-for="(field, index) in mappedFields"
          :key="index"
          :class="$style.fieldName"
        >
          <BaseDropdown
            v-model="field.value"
            :options="[
              {
                label: $t('imports.ignore'),
                value: 'ignore'
              },
              ...fields.map((f) => ({
                label: f.includes('_stock')
                  ? filters.capitalize(f.replace('_', ' '))
                  : $t(`imports.${type}.${f}`),
                value: f
              }))
            ]"
            v-test="'import-map'"
          />
        </div>
      </div>
      <div
        v-for="(row, index) in rows"
        :key="index"
        :class="$style.row"
        v-test="'import-row'"
      >
        <div v-for="(cell, index) in row" :key="index" :class="$style.cell">
          {{ cell }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from '@/axios';
import filters from '@/filters';

const props = defineProps<{
  type: 'customers' | 'products';
  uploadId: number;
}>();

const emit = defineEmits(['success']);

const rows = ref<any[]>([]);
const fields = ref<string[]>([]);
const mappedFields = ref<any[]>([]);

axios.get(`/imports/${props.uploadId}`).then(({ data }) => {
  rows.value = data.import.importer.rows;
  mappedFields.value = rows.value[0].map(() => ({
    value: 'ignore',
  }));
});

axios.get(`/imports/${props.uploadId}/fields`).then(({ data }) => {
  fields.value = data;
});

const saving = ref(false);

const submit = () => {
  saving.value = true;

  axios
    .put(`/imports/${props.uploadId}`, {
      import: {
        fields: mappedFields.value.map((field) => field.value),
        resource: props.type,
      },
    })
    .then(() => {
      emit('success');
    })
    .finally(() => {
      saving.value = false;
    });
};
</script>

<style lang="scss" module>
.table {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  min-height: 260px; // To make sure the dropdowns fit the container when importing only a few rows
}

.row {
  display: flex;
}

.cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-top: 1px solid $color-border;
}

.fieldName,
.cell {
  width: 120px;
  flex-shrink: 0;
  padding: $spacing * 0.5;
}

.alertWrap {
  max-width: 650px;
}

.buttonWrap {
  margin-left: auto;
}
</style>
