<template>
  <BaseModal
    :heading="currentStepData.heading"
    useForm
    small
    testId="cancel-subscription"
    @submit="handleAction(currentStepData.actions?.primary.value)"
    @close="emit('close', currentStep)"
  >
    <BaseAlert
      v-if="currentStep === 'password' && hasCancelValidationError"
      color="error"
      :text="t('subscription.cancelation_flow.password_error_message')"
      mb
    />
    <CancelSubscription
      v-if="currentStep === 'password'"
      :password="cancelationConfirmationData.password"
      :otherSolution="cancelationConfirmationData.otherSolution"
      :openFeedback="cancelationConfirmationData.openFeedback"
      @update:password="cancelationConfirmationData.password = $event"
      @update:otherSolution="cancelationConfirmationData.otherSolution = $event"
      @update:openFeedback="cancelationConfirmationData.openFeedback = $event"
    />
    <component
      :is="currentStepData.component"
      v-else
      v-model="formFlowData[currentStepData.key]"
      :data="currentStepData"
      @update:modelValue="onSelect"
    />
    <template v-if="currentStepData.actions?.tertiary" #footerSub>
      <BaseText
        link
        color="error"
        :disabled="isLoading"
        @click="handleAction(currentStepData.actions.tertiary.value)"
        v-test="'cancel-subscription-tertiary'"
      >
        {{ currentStepData.actions?.tertiary.label }}
      </BaseText>
    </template>
    <template v-if="currentStepData.actions?.primary" #footer>
      <BaseButton
        v-if="currentStepData.actions?.secondary"
        color="inverted"
        :disabled="isLoading"
        @click="handleAction(currentStepData.actions.secondary.value)"
        v-test="'cancel-subscription-secondary'"
      >
        {{ currentStepData.actions.secondary.label }}
      </BaseButton>
      <BaseButton
        :disabled="!mainActionEnabled"
        :loading="isLoading"
        submitForm
        v-test="'cancel-subscription-submit'"
      >
        {{ currentStepData.actions.primary.label }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import CancelSubscription from './CancelSubscription.vue';
import type { Action } from './actions';
import { useCancelSubscription } from './actions';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const isLoading = ref(false);
const hasCancelValidationError = ref(false);

const emit = defineEmits(['close']);
const { t } = useI18n();

const mixpanel = inject<any>('mixpanel');

const {
  currentStep,
  currentStepData,
  formFlowData,
  cancelationConfirmationData,
  moveToPrevStep,
  moveToNextStep,
  moveToStep,
  updateSubscriptionCancelReasons,
  cancelSubscription,
  pauseSubscription,
} = useCancelSubscription();

const onSelect = (event: string) => {
  if (currentStepData.value.key) {
    formFlowData[currentStepData.value.key] = event;
  }
};

const mainActionEnabled = computed(
  () =>
    currentStep.value === 'we_dont_want_to_see_you_go' ||
    currentStep.value === 'password' ||
    currentStep.value === 'schedule_a_demo' ||
    !!formFlowData[currentStepData.value.key],
);

const onSubmit = () => {
  isLoading.value = true;
  updateSubscriptionCancelReasons()
    .then(() => {
      moveToNextStep();
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const handleAction = (action?: Action) => {
  if (isLoading.value) {
    return;
  }

  switch (action) {
    case 'next':
      if (currentStep.value === 'temporary_pause') {
        formFlowData.i_have_life_business_change = 'cancel_subscription';
      }
      mixpanel.track('Cancelation flow: next button clicked', {
        step: currentStep.value,
        reason:
          typeof currentStepData.value.key !== 'undefined'
            ? formFlowData[currentStepData.value.key]
            : null,
      });
      onSubmit();
      break;
    case 'back':
      mixpanel.track('Cancelation flow: back button clicked', {
        step: currentStep.value,
      });
      moveToPrevStep();
      break;
    case 'schedule_call':
      mixpanel.track('Cancelation flow: schedule call button clicked');
      window.open(t('subscription.cancelation_flow.demodesk_url'), '_blank');
      emit('close');
      break;
    case 'cancel_subscription':
      mixpanel.track('Cancelation flow: cancel subscription button clicked');
      hasCancelValidationError.value = false;
      moveToStep('password');
      break;
    case 'confirm_cancel_subscription':
      isLoading.value = true;
      mixpanel.track('Cancelation flow: confirm cancelation button clicked');
      cancelSubscription()
        .then(
          ({
            data: {
              cancelSubscription: { errors },
            },
          }) => {
            if (errors?.length > 0) {
              hasCancelValidationError.value = true;
            } else {
              mixpanel.track('Cancelation flow: cancelation flow completed');
              flash(t('subscription.cancelation_flow.success'));
              emit('close');
            }
          },
        )
        .finally(() => {
          isLoading.value = false;
        });
      break;
    case 'pause_subscription':
      isLoading.value = true;
      mixpanel.track('Cancelation flow: pause subscription button clicked');
      Promise.all([updateSubscriptionCancelReasons(), pauseSubscription()])
        .then(() => {
          mixpanel.track('Cancelation flow: pause subscription completed');
          flash(t('subscription.cancelation_flow.pause_success'));
          emit('close');
        })
        .finally(() => {
          isLoading.value = false;
        });
      break;
    default:
      break;
  }
};
</script>
