<template>
  <div>
    <PaymentMethodsModal
      v-if="selectedMethod"
      v-bind="{ selectedMethod, startDate, endDate }"
      @close="selectedMethodId = undefined"
    />
    <BaseCard :heading="$t('reports.totals.payment_methods')" mt>
      <BaseTable
        v-if="paymentMethods.length"
        :rows="
          paymentMethods.map((method: any) => ({
            id: method.key,
            cells: [
              $t(`reports.totals.${method.key}`),
              {
                value: filters.currency(method.value),
                alignRight: true
              }
            ],
            clickable: method.key !== 'outstanding'
          }))
        "
        @select="onSelect($event)"
        v-test="'payment-methods'"
      />
    </BaseCard>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import PaymentMethodsModal from './PaymentMethodsModal.vue';
import type { PaymentMethod } from './types';

const mixpanel = inject<any>('mixpanel');

const props = defineProps<{
  paymentMethods: PaymentMethod[];
  startDate: string;
  endDate: string;
}>();

const selectedMethodId = ref<PaymentMethod['key']>();
const selectedMethod = computed(() =>
  selectedMethodId.value
    ? props.paymentMethods.find(
        (method) => method.key === selectedMethodId.value,
      )
    : undefined,
);

const onSelect = (key: PaymentMethod['key']) => {
  if (key === 'outstanding') {
    return;
  }

  selectedMethodId.value = key;

  mixpanel.track('Reports - Select payment method', { choice: key });
};
</script>
