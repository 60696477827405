<template>
  <div ref="rootElement">
    <BaseInputLabel
      v-if="label"
      :text="label"
      :required="required"
      :uniqueLabelId="uid"
      :info="info"
      v-test="'_form-element-label'"
    />
    <slot />
    <ValidationPassword
      v-if="props.newPassword"
      v-show="!!modelValue || v$.$errors.length"
      :errors="v$.$errors"
      :silentErrors="v$.$silentErrors"
    />
    <div v-else>
      <div
        v-for="error in v$.$errors"
        :key="error.$uid"
        class="mt-05"
        v-test="'_form-element-error'"
      >
        <BaseText color="error" size="s">
          *{{
            $t(`global.validations.${error.$validator}`, {
              value: label,
              min: props.minLength,
              max: props.maxLength
            })
          }}
        </BaseText>
      </div>
      <div v-if="customError" class="mt-05" v-test="'_form-element-error'">
        <BaseText color="error" size="s"> *{{ customError }} </BaseText>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FormElement',
};
</script>

<script setup lang="ts">
import * as validators from '@vuelidate/validators';
import { computed, watch, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import ValidationPassword from './ValidationPassword.vue';
import eventBus from '@/event-bus';
import { useCompanyStore } from '@/stores/company';

const emit = defineEmits(['error']);
const props = defineProps<{
  modelValue?: number | string | boolean | any[];
  label?: string;
  info?: string;
  uid?: string;
  customError?: string;
  minValue?: number;
  maxValue?: number;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  newPassword?: boolean;
  sameAs?: string | number;
  type?: string;
  regex?: RegExp;
}>();

const rules = computed(() => {
  const validation: any = {};

  if (props.required) {
    if (typeof props.modelValue === 'boolean') {
      validation.required = validators.helpers.withParams(
        { type: 'required' },
        (val: boolean) => val === true
      );
    } else if (typeof props.modelValue === 'number') {
      validation.required = validators.helpers.withParams(
        { type: 'required' },
        (val: number) => val !== 0
      );
    } else {
      validation.required = validators.required;
    }
  }

  if (props.minValue) {
    validation.minValue = validators.minValue(props.minValue);
  }

  if (props.maxValue) {
    validation.maxValue = validators.maxLength(props.maxValue);
  }

  if (props.minLength) {
    validation.minLength = validators.minLength(props.minLength);
  }

  if (props.maxLength) {
    if (Array.isArray(props.modelValue)) {
      validation.maxLengthArray = validators.maxLength(props.maxLength);
    } else {
      validation.maxLength = validators.maxLength(props.maxLength);
    }
  }

  if (props.type === 'email') {
    validation.email = validators.email;
  }

  if (props.type === 'url') {
    const isValidHttpUrl = (value: string) => {
      let url;
      try {
        url = new URL(value);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:';
    };

    validation.url = (value: string) => !value || isValidHttpUrl(value);
  }

  if (props.sameAs) {
    validation.sameAs = validators.sameAs(props.sameAs);
  }

  if (props.regex) {
    validation.regex = validators.helpers.regex(props.regex);
  }

  if (props.newPassword) {
    validation.required = validators.required;
    validation.minLength = validators.minLength(8);
    validation.containsUppercase = (value: string) => /[A-Z]/.test(value);
    validation.containsLowercase = (value: string) => /[a-z]/.test(value);
    validation.containsNumber = (value: string) => /[0-9]/.test(value);
  }

  if (props.type === 'postalcode') {
    const { company } = useCompanyStore();

    if (company.country === 'nl') {
      validation.invalid = validators.helpers.regex(
        new RegExp('^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$', 'i')
      );
    } else if (company.country === 'de') {
      validation.invalid = validators.helpers.regex(/^\d{5}$/);
    } else if (company.country === 'be') {
      validation.invalid = validators.helpers.regex(/^[0-9]{4}$/);
    }
  }

  return {
    props: {
      modelValue: validation
    }
  };
});

const v$ = useVuelidate(rules, { props });

const rootElement = ref(null);

// Emit wether or not there is an error, to update the input field state
const hasError = computed(() => !!v$.value.$errors.length);
watch(
  hasError,
  (value) => {
    emit('error', value);
    if (value) {
      eventBus.$emit('form-error', rootElement.value);
    }
  },
  {
    immediate: true
  }
);
</script>
