const Products = () => import('@/modules/products/index.vue');
const ProductsAll = () => import('@/modules/products/all/index.vue');
const ProductsCategories = () =>
  import('@/modules/products/categories/index.vue');
const ProductsSuppliers = () =>
  import('@/modules/products/suppliers/index.vue');
const ProductsStockOrders = () =>
  import('@/modules/products/stock-orders/index.vue');
const ProductImports = () =>
  import('@/modules/products/product-imports/index.vue');

const CreateProduct = () =>
  import('@/modules/products/modals/CreateProduct.vue');
const UpdateStock = () => import('@/modules/products/modals/UpdateStock.vue');
const CreateCategory = () =>
  import('@/modules/products/modals/CreateCategory.vue');
const DeleteCategory = () =>
  import('@/modules/products/modals/DeleteCategory.vue');
const CreateSupplier = () =>
  import('@/modules/products/modals/CreateSupplier.vue');
const DeleteSupplier = () =>
  import('@/modules/products/modals/DeleteSupplier.vue');

const ProductDetails = () => import('@/modules/products/zoom/Product.vue');
const ProductStockOrderDetails = () =>
  import('@/modules/products/stock-zoom/index.vue');
const ProductOrder = () => import('@/modules/products/order/index.vue');

const UpdatePricesSupplier = () =>
  import('@/modules/products/update-prices/choose-supplier/index.vue');
const UpdatePricesProducts = () =>
  import('@/modules/products/update-prices/update-products/index.vue');

const Imports = () => import('@/modules/products/Imports.vue');

export default {
  path: 'products',
  name: 'products',
  component: Products,
  redirect: {
    name: 'products-all',
  },
  meta: {
    moduleName: 'products',
    role: 'basic',
  },
  children: [
    {
      path: 'categories',
      name: 'products-categories',
      component: ProductsCategories,
      meta: {
        mixpanelName: 'Products - categories',
      },
      children: [
        {
          path: ':id/edit',
          name: 'products-edit-category',
          component: CreateCategory,
        },
        {
          path: ':id/delete',
          name: 'products-delete-category',
          component: DeleteCategory,
        },
        {
          path: 'new',
          name: 'products-create-category',
          component: CreateCategory,
        },
      ],
    },
    {
      path: 'suppliers',
      name: 'products-suppliers',
      component: ProductsSuppliers,
      meta: {
        mixpanelName: 'Products - suppliers',
      },
      children: [
        {
          path: ':id/edit',
          name: 'edit-supplier',
          component: CreateSupplier,
        },
        {
          path: ':id/delete',
          name: 'delete-supplier',
          component: DeleteSupplier,
        },
        {
          path: 'new',
          name: 'create-supplier',
          component: CreateSupplier,
        },
      ],
    },
    {
      path: 'stock-orders',
      name: 'products-stock-orders',
      component: ProductsStockOrders,
      meta: {
        mixpanelName: 'Products - stock orders',
      },
      children: [
        {
          path: 'orders',
          name: 'products-order',
          redirect: { name: 'products-order-new' },
          component: ProductOrder,
          children: [
            {
              path: 'new',
              name: 'products-order-new',
              component: {
                template: '<template />',
              },
            },
            {
              path: ':stockOrderId/build',
              name: 'products-order-build',
              component: {
                template: '<template />',
              },
              meta: {
                hideIntercomButton: true,
              },
            },
          ],
        },
        {
          path: ':stockOrderId',
          name: 'stock-order',
          redirect: { name: 'stock-details' },
          component: ProductStockOrderDetails,
          children: [
            {
              path: 'details',
              name: 'stock-details',
              component: {
                template: '<template />',
              },
            },
            {
              path: 'history',
              name: 'stock-history',
              component: {
                template: '<template />',
              },
            },
            {
              path: 'process',
              name: 'stock-process',
              component: {
                template: '<template />',
              },
              meta: {
                hideIntercomButton: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: '',
      name: 'products-all',
      component: ProductsAll,
      meta: {
        mixpanelName: 'Products - products',
      },
      children: [
        {
          path: ':id/edit',
          name: 'edit-product',
          component: CreateProduct,
          props: {
            parentRouteName: 'products',
          },
        },
        {
          path: ':id/update-stock',
          name: 'update-stock',
          component: UpdateStock,
          props: {
            parentRouteName: 'products',
          },
        },
        {
          path: 'new',
          name: 'create-product',
          component: CreateProduct,
          props: {
            parentRouteName: 'products',
          },
        },
        {
          path: 'update-prices',
          name: 'update-product-prices',
          component: UpdatePricesSupplier,
          props: {
            parentRouteName: 'products',
          },
        },
        {
          path: 'update-prices/:supplierId',
          name: 'update-supplier-product-prices',
          component: UpdatePricesProducts,
          props: {
            parentRouteName: 'products',
          },
        },
        {
          path: ':id',
          name: 'product',
          redirect: { name: 'product-overview' },
          component: ProductDetails,
          children: [
            {
              path: 'overview',
              name: 'product-overview',
              component: {
                template: '<template />',
              },
            },
            {
              path: 'sales',
              name: 'product-sales',
              component: {
                template: '<template />',
              },
            },
            {
              path: 'history',
              name: 'product-history',
              component: {
                template: '<template />',
              },
            },
          ],
        },
      ],
    },
    {
      path: 'product-imports',
      name: 'product-imports',
      component: ProductImports,
    },
    {
      name: 'import-products',
      path: 'import',
      component: Imports,
    },
  ],
};
