<template>
  <div :class="$style.base">
    <BaseText mb bold>
      {{ $t(`marketing.insights.tips.${tip}.title`) }}
    </BaseText>
    <BaseText mb>
      {{ $t(`marketing.insights.tips.${tip}.description`) }}
    </BaseText>
    <div :class="$style.linkSpace">
      <BaseText :href="$t(`marketing.insights.tips.${tip}.link`)">
        {{ $t('global.learn_more') }}
      </BaseText>
      <BaseText
        v-if="videoId && isTrial && company.language === 'nl'"
        ml
        iconBefore="play-circle"
        :href="`https://vimeo.com/${videoId}`"
        target="_blank"
      >
        {{ $t('global.watch_video') }}
      </BaseText>
    </div>
    <img :class="$style.image" :src="`/img/insights/tips/${tip}.svg`" />
  </div>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';

const { isTrial, company } = useCompanyStore();

interface TipProps {
  tip: string;
  videoId?: string;
}

defineProps<TipProps>();
</script>

<style lang="scss" module>
.base {
  position: relative;
  background: #f1f8fc;
  padding: $spacing;
  border-radius: $radius;
  overflow: hidden;
}

.linkSpace {
  display: flex;
  align-items: center;
  margin-top: $spacing * 6;
}

.image {
  width: 130px;
  position: absolute;
  bottom: -14px;
  right: -4px;
}
</style>
