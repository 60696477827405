const getDefaultState = () => ({
  email_type: null,
  enabled: null,
  show_toggle: false,
  title: '',
  description: '',
  show_filters: false,
  sent_description: '',
  receive_description: '',
  filtered_customers_count: null,
  loading_customers_count: false,
  show_rebook_settings: false,
  show_reset: false,
  show_send_test: false,
  show_schedule: false,
  hide_discount: false,
  hide_button_toggle: false,
  hide_button_options: false,
  selected_view: 'DESKTOP',
  allow_image_upload: false,
});

const store = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    emailType: (state) => state.email_type,
    enabled: (state) => state.enabled,
    showToggle: (state) => state.show_toggle,
    showReset: (state) => state.show_reset,
    title: (state) => state.title,
    description: (state) => state.description,
    sentDescription: (state) => state.sent_description,
    receiveDescription: (state) => state.receive_description,
    showFilters: (state) => state.show_filters,
    showSendTest: (state) => state.show_send_test,
    showSchedule: (state) => state.show_schedule,
    showRebookSettings: (state) => state.show_rebook_settings,
    filteredCustomersCount: (state) => state.filtered_customers_count,
    loadingCustomersCount: (state) => state.loading_customers_count,
    hideDiscount: (state) => state.hide_discount,
    hideButtonToggle: (state) => state.hide_button_toggle,
    hideButtonOptions: (state) => state.hide_button_options,
    selectedView: (state) => state.selected_view,
    allowImageUpload: (state) => state.allow_image_upload,
  },
  mutations: {
    SET_SETTINGS(state, payload) {
      const {
        emailType,
        title,
        description,
        sentDescription,
        receiveDescription,
        showToggle,
        showSendTest,
        showSchedule,
        showFilters,
        showRebookSettings,
        showReset,
        hideDiscount,
        hideButtonToggle,
        hideButtonOptions,
        allowImageUpload,
      } = payload;

      state.email_type = emailType;
      state.title = title;
      state.description = description;
      state.sent_description = sentDescription;
      state.receive_description = receiveDescription;

      if (showRebookSettings) {
        state.show_rebook_settings = showRebookSettings;
      }

      if (showSendTest) {
        state.show_send_test = showSendTest;
      }

      if (showToggle) {
        state.show_toggle = showToggle;
      }

      if (showFilters) {
        state.show_filters = showFilters;
      }

      if (allowImageUpload) {
        state.allow_image_upload = allowImageUpload;
      }

      if (showSchedule) {
        state.show_schedule = showSchedule;
      }

      if (showReset) {
        state.show_reset = showReset;
      }

      if (hideDiscount) {
        state.hide_discount = hideDiscount;
      }

      if (hideButtonOptions) {
        state.hide_button_options = hideButtonOptions;
      }

      if (hideButtonToggle) {
        state.hide_button_toggle = hideButtonToggle;
      }
    },
    SET_ENABLED(state, payload) {
      state.enabled = payload;
    },
    SET_FILTERED_CUSTOMERS_COUNT(state, payload) {
      state.filtered_customers_count = payload;
    },
    SET_LOADING_CUSTOMERS_COUNT(state, payload) {
      state.loading_customers_count = payload;
    },
    SET_SHOW_RESET(state, payload) {
      state.show_reset = payload;
    },
    SET_SELECTED_VIEW(state, payload) {
      state.selected_view = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
};

export default store;
