<template>
  <div>
    <BaseRadio
      v-model="selectedValue"
      :options="filters"
      mb
      @update:modelValue="emitValue"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      selectedValue: 'm',
      filters: [
        {
          label: this.$t('customers.filters.m'),
          value: 'm',
        },
        {
          label: this.$t('customers.filters.f'),
          value: 'f',
        },
        {
          label: this.$t('customers.filters.n'),
          value: 'n',
        },
        {
          label: this.$t('customers.filters.u'),
          value: 'u',
        },
      ],
    };
  },
  methods: {
    emitValue(selectedValue) {
      const values = selectedValue === 'u' ? null : selectedValue;

      const obj = {
        filter_name: 'gender',
        values,
      };
      this.$emit('selected', obj);
    },
  },
  created() {
    this.emitValue(this.selectedValue);
  },
});
</script>
