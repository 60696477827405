import config from '@/config';
import filters from '@/filters';
import i18n from '@/i18n';
import { getCalendarIcons } from '@/modules/calendar/helpers';
import hexRgb from 'hex-rgb';

const Item = ({ event }) => {
  const isWaitingList = event.extendedProps.type === 'waiting_list';
  const isDynamicPrice = event.extendedProps.type === 'dynamic_price';
  const isPreview = !!event.extendedProps.preview;
  const isRequest = !!event.extendedProps.request;

  const customerMemberName = (customerMember) => {
    if (!customerMember) {
      return;
    }
    return `${customerMember.firstName} ${customerMember.lastName}`;
  };

  const item = {
    allDay: event.allDay,
    title: event.title,
    customerName:
      event.extendedProps.type === 'appointment'
        ? customerMemberName(event.extendedProps.customerMember) ||
          event.extendedProps.customerName ||
          i18n.t('calendar.walk_in')
        : '',
    color:
      event.extendedProps.type === 'absence' && !event.extendedProps.chore
        ? '#aaaaaa'
        : event.extendedProps.color,
    showInHeader:
      event.extendedProps.type === 'note' ||
      event.extendedProps.type === 'holiday' ||
      (event.extendedProps.type === 'absence' && event.allDay) ||
      isWaitingList,
    isSmall: event.isSmall,
    isMedium: event.isMedium,
    isLarge: !event.isSmall && !event.isMedium,
  };

  const startTime = event.startStr ? filters.time(event.startStr) : '';
  const endTime = event.endStr ? filters.time(event.endStr) : '';
  const timeText =
    event.timeText ||
    `${startTime}${startTime && endTime ? ' - ' : ''}${endTime}`;

  item.timeText = item.isLarge ? timeText : timeText?.split(' - ')[0] || '';

  const icons = item.showInHeader
    ? [
        {
          name: 'message-circle',
          show: event.extendedProps.type === 'note',
        },
        {
          name: 'home',
          show: event.extendedProps.type === 'holiday',
        },
        {
          name: 'checkmark-square',
          show:
            event.extendedProps.type === 'absence' && event.extendedProps.chore,
        },
        {
          name: 'slash',
          show:
            event.extendedProps.type === 'absence' &&
            !event.extendedProps.chore,
        },
      ].filter((icon) => icon.show)
    : getCalendarIcons({
        features: event.features,
      });

  let rgbString = 'rgb(242, 243, 251)';
  if (item.color) {
    const rgbArray = hexRgb(item.color, { format: 'array' }).map((val) =>
      Math.round(val + (255 - val) * (1 - (event.darkerColors ? 0.5 : 0.3))),
    );
    rgbString = `rgb(${rgbArray[0]},${rgbArray[1]},${rgbArray[2]})`;
  } else if (isWaitingList || isDynamicPrice) {
    item.color = '#4C49C5';
  }

  if (isRequest) {
    rgbString = 'rgb(255, 255, 255)';
  }

  return `
    <div class="calendar-item calendar-item--${event.extendedProps.type} ${item.showInHeader && icons.length ? 'calendar-item--has--icon' : ''} ${item.isSmall ? 'calendar-item--is-small' : ''} ${item.showInHeader ? 'calendar-item--in-header' : ''} ${isPreview ? 'calendar-item--preview' : ''} ${isRequest ? 'calendar-item--request' : ''}"
      style="border-color: ${item.color}; background-color: ${rgbString};"
      ${config.isTest || config.isDevelopment ? `data-test="${isPreview ? 'calendar-preview-event' : item.showInHeader ? `calendar-${event.extendedProps.type}-header` : `calendar-${event.extendedProps.type}`}"` : ''}
    >
      ${item.allDay ? '' : `${item.isLarge ? `${item.timeText}<br>` : `<span class="calendar-item__time-small">${item.timeText}</span>`}`}
      ${!item.allDay && (item.customerName || event.extendedProps.name) ? `<strong>${item.customerName || event.extendedProps.name}</strong>${item.isSmall ? '' : '<br>'}` : ''}
      ${item.allDay && event.extendedProps.name ? event.extendedProps.name : item.title}
      ${isWaitingList && event.extendedProps.amount ? `<div class="js-calendar-icon calendar-item__amount" data-tooltip-text="${i18n.t('calendar.waiting_list_amount', { amount: event.extendedProps.amount }, event.extendedProps.amount)}">${event.extendedProps.amount}</div>` : ''}

      ${
        icons.length
          ? `
        <div class="calendar-item__icons" style="background-color: ${rgbString}; box-shadow: 0 0 9px 6px ${rgbString};" ${config.isTest || config.isDevelopment ? 'data-test="calendar-event-icons"' : ''}>
          ${icons
            .map((icon) =>
              icon.name === 'smiley_happy'
                ? `
            <svg class="js-calendar-icon" data-tooltip-name="${icon.tooltip?.name || ''}" viewBox="0 0 24 24"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8m0 18C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10" /><path fill-rule="evenodd" clip-rule="evenodd" d="M15 12a1 1 0 00-1 1c0 1.103-.897 2-2 2s-2-.897-2-2a1 1 0 10-2 0c0 2.206 1.794 4 4 4s4-1.794 4-4a1 1 0 00-1-1M15 8a1 1 0 100 2 1 1 0 000-2M9 8a1 1 0 100 2 1 1 0 000-2" /></g></svg>
            `
                : icon.name === 'smiley_sad'
                  ? `
            <svg class="js-calendar-icon" data-tooltip-name="${icon.tooltip?.name || ''}" viewBox="0 0 24 24"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8m0 18C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10" /><path fill-rule="evenodd" clip-rule="evenodd" d="M15 17a1 1 0 01-1-1c0-1.103-.897-2-2-2s-2 .897-2 2a1 1 0 11-2 0c0-2.206 1.794-4 4-4s4 1.794 4 4a1 1 0 01-1 1M15 8a1 1 0 100 2 1 1 0 000-2M9 8a1 1 0 100 2 1 1 0 000-2" /></g></svg>
            `
                  : icon.name === 'treatwell'
                    ? `
            <svg class="js-calendar-icon" data-tooltip-name="${icon.tooltip?.name || ''}" viewBox="0 0 24 24"><g><path d="m5.66259,15.27988c0,1.67739 0.90968,2.68839 2.41074,2.68839c0.47755,0 0.95521,-0.0919 1.47828,-0.27576l0,0.16082c-0.86425,1.35569 -2.09235,2.11394 -3.63884,2.11394c-2.02412,0 -3.50234,-1.28673 -3.50234,-3.72232l0,-6.43354l-1.95587,0l0,-0.25278l4.93509,-4.52648l0.27295,0l0,10.24776l0,-0.00001z" /><path d="m14.6797,19.89832l1.66018,-3.69935l-3.43414,-7.92711l-6.44717,0l0,1.53944l3.5589,0l4.43481,10.08701l0.22742,0l0,0.00001z" /><path d="m15.72585,8.27188l3.54782,0l3.43415,7.9271l-1.66025,3.69935l-0.22737,0l-5.09436,-11.62644l0.00001,0z" /><path d="m23.86766,12.70647c0,-1.10289 -0.50027,-3.30868 -1.06888,-4.43458l3.45688,0l-2.22878,4.43458l-0.15921,0z" /></g></svg>
            `
                    : `<i class="eva eva-${icon.name}-outline js-calendar-icon" data-tooltip-name="${icon.tooltip?.name || ''}" data-tooltip-text="${icon.tooltip?.text || ''}" ${config.isTest || config.isDevelopment ? `data-test="calendar-event-icon-${icon.name}"` : ''}></i>`,
            )
            .join('')}
        </div>
      `
          : ''
      }
    </div>
  `;
};

export default (data) => Item(data);
