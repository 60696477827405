<template>
  <BaseGrid container>
    <BaseGrid>
      <BaseButton
        :loading="isLoading"
        fullWidth
        color="inverted"
        mr
        @click="onSaveModal"
        v-test="'newsletterSave'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </BaseGrid>
    <BaseGrid>
      <BaseButton
        :loading="isLoading"
        fullWidth
        @click="onSendModal"
        v-test="'sendNewsletter'"
      >
        {{ $t('global.actions.send') }}
      </BaseButton>
    </BaseGrid>
  </BaseGrid>
  <SaveTemplate
    v-if="showSaveTemplateModal"
    @close="showSaveTemplateModal = false"
  />
  <SendTemplate
    v-if="showSendTemplateModal"
    @close="showSendTemplateModal = false"
  />
  <SendTemplateWarning
    v-if="showSendTemplateWarningModal"
    @close="showSendTemplateWarningModal = false"
    @save="
      showSendTemplateWarningModal = false;
      showSaveTemplateModal = true;
    "
  />
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import SaveTemplate from '@/modules/marketing/modals/SaveTemplate.vue';
import SendTemplate from '@/modules/marketing/modals/SendTemplate.vue';
import SendTemplateWarning from '@/modules/marketing/modals/SendTemplateWarning.vue';

import { useCompanyStore } from '@/stores/company';
import { computed, ref } from 'vue';

const { allowedToMassSend } = useCompanyStore();
const store = useStore();
const isLoading = ref(false);
const showSaveTemplateModal = ref(false);
const showSendTemplateModal = ref(false);
const showSendTemplateWarningModal = ref(false);

const isInvalid = computed(() => store.getters['marketing/isInvalid']);

const hasDeliverySchedule = computed(
  () => store.getters['marketing/hasDeliverySchedule'],
);

const sendingIsDisabled = computed(() => !allowedToMassSend);

const onSendModal = () => {
  store.commit('marketing/SET_SUBMITTED', true);

  if (isInvalid.value) {
    return;
  }

  if (sendingIsDisabled.value) {
    showSendTemplateWarningModal.value = true;
  } else {
    showSendTemplateModal.value = true;
  }
};

const onSaveModal = () => {
  if (hasDeliverySchedule.value) {
    store.commit('marketing/SET_SUBMITTED', true);
    if (isInvalid.value) {
      return;
    }
  }

  store.commit('marketing/SET_SUBMITTED', false);
  showSaveTemplateModal.value = true;
};
</script>
