<template>
  <BaseCard gray mb v-test="'salonized-to-google-calendar-sync'">
    <BaseHeading mb> Salonized → GCal </BaseHeading>

    <BaseRadio
      v-model="salonizedToGoogleSyncMode"
      :label="$t('integrations.google_calendar.settings.sync_mode')"
      :options="[
        {
          label: $t('integrations.google_calendar.settings.sync_mode_all'),
          value: 'simple'
        },
        {
          label: $t('integrations.google_calendar.settings.sync_mode_separate'),
          value: 'advanced'
        }
      ]"
      :mb="2"
      v-test="'calendar-mode'"
    />
    <BaseInputLabel
      :text="$t('integrations.google_calendar.settings.target_calendars')"
      :mb="0.5"
    />
    <div
      v-if="salonizedToGoogleSyncMode === 'simple'"
      :class="$style.dropdownWrap"
    >
      <BaseText bold>
        {{ $t('integrations.google_calendar.settings.target_calendars_all') }}
      </BaseText>
      <BaseDropdown
        v-model="companyCalendar"
        :placeholder="
          $t('integrations.google_calendar.settings.select_calendar')
        "
        :options="[
          {
            value: 'create',
            label:
              $t('integrations.google_calendar.settings.create_calendar') +
              ': Salonized - ' +
              company.name
          },
          ...writableLists
        ]"
        required
        v-test="'calendar-lists'"
      />
    </div>
    <div v-else>
      <div
        v-for="(calendar, index) in calendars"
        :key="index"
        :class="$style.dropdownWrap"
      >
        <div :class="$style.employee">
          <BaseAvatar :resource="employees[index]" />
          <BaseText bold :ml="0.5">
            {{ employees[index].name }}
          </BaseText>
        </div>
        <BaseDropdown
          v-model="calendar.calendarId"
          :placeholder="
            $t('integrations.google_calendar.settings.select_calendar')
          "
          :options="[
            {
              value: null,
              label: '-- ' + $t('global.disabled') + ' --'
            },
            {
              value: 'create',
              label:
                $t('integrations.google_calendar.settings.create_calendar') +
                ': Salonized - ' +
                employees[index].name
            },
            ...writableLists
          ]"
          v-test="'calendar-employee-lists'"
        />
      </div>
    </div>
    <BaseAlert
      v-if="unwritableLists.length"
      :mt="1.5"
      :text="
        $t('integrations.google_calendar.settings.unselectable', {
          amount: unwritableLists.length
        })
      "
    />
  </BaseCard>
</template>

<script setup lang="ts">
import type { SalonizedGoogleCalendarData } from './types';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';

const { employees } = useResourcesStore();
const { company } = useCompanyStore();

const {
  salonizedToGoogleSyncMode,
  calendars,
  companyCalendar,
  writableLists,
  unwritableLists,
} = inject<SalonizedGoogleCalendarData>('salonized-google-calendar')!;
</script>

<style lang="scss" module>
.dropdownWrap {
  display: flex;
  gap: $spacing;
  margin-bottom: $spacing;

  & > * {
    width: calc(50% - #{$spacing / 2});
  }
}

.employee {
  display: flex;
  align-items: center;
}
</style>
