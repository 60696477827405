<template>
  <div
    v-show="isVisible"
    :class="[
      $style.base,
      $style[data.position],
      {
        [$style.alignLeft]: alignLeft,
        [$style.alignRight]: alignRight
      }
    ]"
    :style="style"
    v-test="'the-tooltip'"
  >
    {{ data.text }}
  </div>
</template>

<script lang="ts">
import eventBus from '@/event-bus';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      data: {},
      isVisible: false,
      alignLeft: false,
      alignRight: false,
    };
  },
  computed: {
    style() {
      const style = {
        top: `${this.data.top}px`,
      };
      if (this.data.right) {
        style.right = `${this.data.right}px`;
        style.left = 'auto';
      } else {
        style.left = `${this.data.left}px`;
      }

      return style;
    },
  },
  methods: {
    onEnter() {
      if (this.data.position === 'top' || this.data.position === 'bottom') {
        this.$nextTick(() => {
          const rect = this.$el?.getBoundingClientRect();
          if (!rect) {
            return;
          }

          if (rect.x < 0) {
            this.alignLeft = true;
          } else if (rect.x + rect.width > window.innerWidth) {
            this.alignRight = true;
          }
        });
      }
    },
    onAfterLeave() {
      this.alignLeft = false;
      this.alignRight = false;
    },
    show(data) {
      this.data = data;
      this.isVisible = true;

      this.onEnter();
    },
    hide() {
      this.isVisible = false;

      this.onAfterLeave();
    },
  },
  created() {
    eventBus.$on('show-tooltip', this.show);
    eventBus.$on('hide-tooltip', this.hide);
  },
  beforeUnmount() {
    eventBus.$off('show-tooltip', this.show);
    eventBus.$off('hide-tooltip', this.hide);
  },
});
</script>

<style lang="scss" module>
$color: #333;
$arrowSize: 5px;

.base {
  position: fixed;
  left: 0;
  top: 0;
  max-width: 200px;
  margin-right: -200px;
  padding: 7px 8px 8px;
  background-color: $color;
  color: $color-text-inverted;
  border-radius: $radius;
  font-size: 12px;
  z-index: 9999;
  pointer-events: none;
  text-align: center;
  line-height: 1;

  &:after {
    content: '';
    position: absolute;
  }

  &.top,
  &.bottom {
    &:after {
      border-left: $arrowSize solid transparent;
      border-right: $arrowSize solid transparent;
    }

    &:not(.alignRight) {
      &:after {
        margin-left: $arrowSize * -1;
      }
    }

    &:not(.alignLeft):not(.alignRight) {
      &:after {
        left: 50%;
      }
    }

    &.alignLeft {
      &:after {
        left: $spacing;
      }
    }

    &.alignRight {
      &:after {
        right: $spacing;
        margin-right: $arrowSize * -1;
      }
    }
  }

  &.top {
    margin-top: $arrowSize * -2;

    &:not(.alignLeft):not(.alignRight) {
      transform: translateX(-50%) translateY(-100%);
    }

    &.alignLeft {
      transform: translateX($spacing * -1) translateY(-100%);
    }

    &.alignRight {
      transform: translateX(calc(-100% + #{$spacing})) translateY(-100%);
    }

    &:after {
      bottom: $arrowSize * -1;
      border-top: $arrowSize solid $color;
    }
  }

  &.bottom {
    margin-top: $arrowSize * 2;

    &:not(.alignLeft) {
      transform: translateX(-50%);
    }

    &.alignLeft {
      transform: translateX($spacing * -1);
    }

    &:after {
      top: $arrowSize * -1;
      border-bottom: $arrowSize solid $color;
    }
  }

  &.left,
  &.right {
    &:after {
      top: 50%;
      margin-top: $arrowSize * -1;
      border-top: $arrowSize solid transparent;
      border-bottom: $arrowSize solid transparent;
    }
  }

  &.left {
    margin-right: $arrowSize * 2;
    transform: translateY(-50%);

    &:after {
      right: $arrowSize * -1;
      border-left: $arrowSize solid $color;
    }
  }

  &.right {
    margin-left: $arrowSize * 2;
    transform: translateY(-50%);

    &:after {
      left: $arrowSize * -1;
      border-right: $arrowSize solid $color;
    }
  }

  &:global(.v-enter-active),
  &:global(.v-leave-active) {
    transition: opacity 0.1s;
  }

  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
  }
}
</style>
