import { usePreCreateStore } from '@/stores/calendar-pre-create';
import type { Customer } from '@/types';
import { storeToRefs } from 'pinia';
import { reactive, watch } from 'vue';
import type { FormData } from './index';

export const useCustomerData = (formData: FormData) => {
  type CustomerData = {
    alertNotes: string;
    blocked: boolean;
    email: string;
    firstName: string;
    fullName: string;
    id: number | null;
    lastName: string;
    mobilePhone: string;
    phone: string;
  };

  const customerData = reactive<CustomerData>({
    alertNotes: '',
    blocked: false,
    email: '',
    firstName: '',
    fullName: '',
    id: null,
    lastName: '',
    mobilePhone: '',
    phone: '',
  });

  const setCustomerData = (data?: CustomerData | Customer) => {
    const {
      alertNotes,
      blocked,
      id,
      firstName,
      fullName,
      lastName,
      email,
      mobilePhone,
      phone,
    } = data || {};
    customerData.alertNotes = alertNotes || '';
    customerData.blocked = !!blocked;
    customerData.id = id || null;
    customerData.firstName = firstName || '';
    customerData.fullName = fullName || '';
    customerData.lastName = lastName || '';
    customerData.email = email || '';
    customerData.mobilePhone = mobilePhone || '';
    customerData.phone = phone || '';
  };

  const { customer } = storeToRefs(usePreCreateStore());
  watch(
    customer,
    (customer) => {
      if (customer) {
        setCustomerData(customer);
      }
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch(
    () => formData.customerId,
    (customerId) => {
      if (!customerId) {
        setCustomerData();
      }
    },
  );

  watch(
    () => customerData.id,
    (id) => {
      formData.customerId = id || null;
    },
  );

  return {
    customerData,
    setCustomerData,
  };
};
