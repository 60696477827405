const LoggedIn = () => import('@/app/logged-in/index.vue');

import account from './account';
import admin from './admin';
import calendar from './calendar';
import customers from './customers';
import dashboard from './dashboard';
import feedback from './feedback';
import marketing from './marketing';
import messages from './messages';
import products from './products';
import register from './register';
import reports from './reports';
import store from './store';
import support from './support';
import treatwell from './treatwell';

export default [
  {
    path: '/c/:companyId/l/:locationId',
    component: LoggedIn,
    redirect: {
      name: 'dashboard',
    },
    children: [
      account,
      dashboard,
      calendar,
      customers,
      register,
      products,
      messages,
      feedback,
      reports,
      marketing,
      admin,
      support,
      store,
      ...treatwell,
    ],
  },
];
