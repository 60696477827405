import apolloClient from '@/apollo/client';
import config from '@/config';
import gql from 'graphql-tag';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

class Order {
  id: number | null = null;
  number: string | null = null;
}

export const useFiscalizationStore = defineStore(
  'register/fiscalization',
  () => {
    const orderIds = ref<number[]>([]);

    const addOrderToQueue = (id: number) => {
      if (!orderIds.value.includes(id)) {
        orderIds.value.push(id);
      }

      if (!currentSubscription) {
        startSubscription();
      }
    };

    const removeOrderId = (id: number) => {
      const filteredOrders = orderIds.value.filter((orderId) => orderId !== id);
      orderIds.value = filteredOrders;
    };

    const printableOrder = ref<Order | null>(null);

    const printUrl = computed(
      () => `${config.backendUrl}/orders/${printableOrder.value?.id}`,
    );

    let currentSubscription: any;

    const startSubscription = () => {
      const subscription = apolloClient
        .subscribe({
          query: gql`
            subscription fiscalizedOrder {
              fiscalizedOrder {
                order {
                  id
                  number
                }
              }
            }
          `,
        })
        .subscribe({
          next: (response) => {
            printableOrder.value = response.data.fiscalizedOrder.order;
          },
        });

      currentSubscription = subscription;
    };

    const handlePrintableOrder = () => {
      if (printableOrder.value?.id) {
        removeOrderId(printableOrder.value.id);
        printableOrder.value = null;
      }

      if (!orderIds.value.length && currentSubscription) {
        currentSubscription.unsubscribe();
        currentSubscription = null;
      }
    };

    return {
      addOrderToQueue,
      printableOrder,
      printUrl,
      handlePrintableOrder,
    };
  },
);
