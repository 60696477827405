<template>
  <div>
    <PublishConfirmation
      v-if="showPublishConfirmation"
      @close="showPublishConfirmation = false"
    />
    <BaseButton
      v-if="selected.state === 'allowed' || selected.state === 'published'"
      color="inverted"
      @click="showPublishConfirmation = true"
      v-test="'btn-publish-options'"
    >
      {{ $t('feedback.publish_options.button') }}
    </BaseButton>
    <BaseButton
      v-else
      color="inverted"
      :disabled="selected.state === 'requested'"
      :loading="loading"
      @click="requestPublication"
      v-test="'btn-publish-request'"
    >
      {{ $t('feedback.request_publish') }}
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import PublishConfirmation from './PublishConfirmation.vue';
import { ref, inject } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { REQUEST_FEEDBACK_PUBLICATION } from '../../graphql';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const showPublishConfirmation = ref(false);
const selected = inject<any>('selected');

const { t } = useI18n();

const {
  mutate: requestPublication,
  loading,
  onDone,
} = useMutation(REQUEST_FEEDBACK_PUBLICATION, () => ({
  variables: {
    input: {
      id: selected.value.id,
    },
  },
}));

onDone(() => {
  selected.value.state = 'requested';
  flash(t('global.flash.requested'));
});
</script>
