import { inject } from 'vue';
import { useRouter } from 'vue-router';

export default () => {
  const mixpanel = inject<any>('mixpanel');
  const router = useRouter();

  const handleUpgradeButtonClick = () => {
    mixpanel.track('Upgrade subscription button clicked');
    router.push({ name: 'subscription' });
  };

  return {
    handleUpgradeButtonClick,
  };
};
