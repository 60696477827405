<template>
  <transition>
    <div :class="[$style.base, { [$style.isSmall]: $screen === 's' }]">
      <div :class="$style.heading">
        <div :class="$style.headingSection">
          <BaseText bold>
            {{ $t('marketing.email.sections.filter_your_customers') }}
          </BaseText>
          <BaseIcon
            clickable
            name="close"
            @click="onClose"
            v-test="'customer-filters-close'"
          />
        </div>
        <div
          v-if="$screen === 's' && filters.length"
          :class="$style.headingSection"
        >
          <div>
            {{
              $t(
                'customers.filters.filters_selected',
                { amount: filters.length },
                filters.length
              )
            }}
          </div>
          <BaseText link @click="$emit('clearFilters')">{{
            $t('global.actions.clear')
          }}</BaseText>
        </div>
      </div>
      <AddFilter
        :switchOnClick="mediumSmallDevice"
        showAsList
        :savedFilters="savedFilters"
        @setFilter="setFilter"
      />
    </div>
  </transition>
</template>

<script lang="ts">
import AddFilter from '@/components/customer-filters/AddFilter.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    AddFilter,
  },
  props: {
    savedFilters: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['setFilter', 'close', 'clearFilters'],
  setup() {
    const filters = inject('customerFilters');
    return {
      filters,
    };
  },
  data() {
    return {
      mediumSmallDevice: document.documentElement.clientWidth <= 768,
    };
  },
  methods: {
    setFilter(filter) {
      this.$emit('setFilter', filter);
    },
    onClose() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" module>
.base {
  position: absolute;
  box-shadow: $shadow;
  z-index: 1;
  height: 100%;
  background: $white;
  border-right: 1px solid $color-border;

  &:global(.v-leave-active),
  &:global(.v-enter-active) {
    transition: transform 0.3s ease;
  }

  &:global(.v-enter-from) {
    transform: translateX(-100%);
  }

  &:global(.v-leave-to) {
    transform: translateX(calc(-100% - #{$nav-width}));
  }

  &.isSmall {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 20;
  }
}

.heading {
  border-bottom: 1px solid $color-border;
}

.headingSection {
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;

  &:not(:first-child) {
    padding-top: 0;
  }
}
</style>
