<template>
  <div
    :class="$style.base"
    :style="
      color
        ? {
            'border-color': color
          }
        : undefined
    "
  >
    <div :class="$style.treatment">
      {{ service.name }}
    </div>
    <div :class="$style.treatwellTreatment">
      <BaseDropdown
        v-model="selectedTreatment"
        :options="
          treatwellTreatments.map((t) => ({
            value: t.id,
            label: t.name,
            groupValue: t.group.id
          }))
        "
        :groups="twGroups"
        :loading="!props.treatwellTreatments.length"
        searchable
        searchAutoFocus
        v-test="'tw-sm-service-treatment-dropdown'"
      />
    </div>
    <div :class="$style.actions">
      <BaseIcon
        ml
        :name="isPopular ? 'star-filled' : 'star'"
        :disabled="props.service.twTreatmentIds.length === 0"
        @click="togglePopular"
        v-test="'tw-sm-popular-toggle'"
      />
      <BaseIcon
        ml
        name="close"
        @click="remove"
        v-test="'tw-sm-service-treatment-remove'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { flash } from '@/helpers/ui';
import i18n from '@/i18n';

const props = defineProps({
  service: {
    type: Object,
    required: true,
  },
  color: String,
  treatwellTreatments: {
    type: Array,
    required: true,
  },
  twGroups: {
    type: Array,
    required: true,
  },
  popularTreatmentCount: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['service:updated']);
watch(props.service, () => emit('service:updated'), { deep: true });

const selectedTreatment = computed({
  get: () =>
    props.service.twTreatmentIds.length
      ? props.service.twTreatmentIds[0]
      : undefined,
  set: (value) => (props.service.twTreatmentIds = [value]),
});

const remove = () => {
  props.service.twTreatmentIds = [];
  props.service.popular = false;
};

const isPopular = computed(() => props.service.popular);
const togglePopular = () => {
  if (props.service.twTreatmentIds.length === 0) {
    return;
  }

  if (!props.service.popular && props.popularTreatmentCount >= 5) {
    flash(i18n.t('treatwell.services.popular_count_warning'));
    return;
  }
  props.service.popular = !props.service.popular;
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid;
  border-right: 2px solid;
  padding: $spacing;
  padding-left: 2 * $spacing;
  background: white;

  &:last-child {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

.treatment {
  width: 40%;
}

.treatwellTreatment {
  flex-grow: 1;
}

.actions {
  display: flex;
}
</style>
