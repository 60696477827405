import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import rrulePlugin from '@fullcalendar/rrule';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import dayjs from '@/dayjs';

import calendarInterface from '@/modules/calendar/calendar-interface';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { useCompanyStore } from '@/stores/company';

const getFullCalendarViewType = (viewType: string) => {
  const viewTypes = [
    {
      name: 'WEEK',
      fullCalendarName: 'timeGridWeek',
    },
    {
      name: 'DAY',
      fullCalendarName: 'resourceTimeGridDay',
    },
  ];

  return (
    viewTypes.find((type) => type.name === viewType)?.fullCalendarName ||
    'timeGridWeek'
  );
};

export const useCalendarDefaults = () => {
  const { company, companySettings } = useCompanyStore();
  const { t } = useI18n();

  const _interval = companySettings.agenda.slotInterval.toString() || '15';
  const interval = `00:${_interval.length === 1 ? `0${_interval}` : _interval}:00`;
  const slotMinTime = dayjs
    .max(
      dayjs('2020-01-01T00:00'),
      dayjs(`2020-01-01T${companySettings.agenda.startTime}`)
        .subtract(2, 'hour')
        .subtract(companySettings.agenda.slotInterval === 25 ? 5 : 0, 'minute'),
    )

    .format('HH:mm');
  const minEndTime = dayjs('2020-01-01T21:00');
  const maxEndTime = dayjs('2020-01-01T23:59');
  const minDate = dayjs.min(
    maxEndTime,
    dayjs(`2020-01-01T${companySettings.agenda.endTime}`).add(3, 'hour'),
  );
  const slotMaxTime = dayjs.max(minEndTime, minDate).format('HH:mm');

  const { date, viewType } = useCalendarFiltersStore();

  const dayMinWidth = computed(() => {
    const { mobileFullView } = useCalendarFiltersStore();
    const { previewLayoutActive } = useCalendarPreviewStore();
    if (mobileFullView) {
      return 0;
    } else if (previewLayoutActive) {
      return 70;
    } else {
      return 120;
    }
  });

  watch(
    () => dayMinWidth.value,
    () => {
      calendarInterface.api?.setOption('dayMinWidth', dayMinWidth.value);
    },
  );

  return {
    dayMaxEvents: 2,
    dayMinWidth: dayMinWidth.value,
    displayEventTime: false,
    editable: true,
    eventAllow: (_dropInfo: any, draggedEvent: any) => {
      const { viewType } = useCalendarFiltersStore();
      return !(
        draggedEvent.allDay &&
        !draggedEvent._def.resourceEditable &&
        viewType === 'DAY'
      );
    },
    eventColor: 'transparent',
    eventResizableFromStart: true,
    eventMinHeight: 1,
    firstDay: companySettings.locale.firstDayOfWeek === 'SUNDAY' ? 0 : 1,
    headerToolbar: false,
    height: '100%',
    initialView: getFullCalendarViewType(viewType),
    initialDate: date,
    lazyFetching: true,
    locale: 'nl',
    navLinks: true,
    nowIndicator: true,
    plugins: [
      scrollGridPlugin,
      resourceTimeGridPlugin,
      timeGridPlugin,
      dayGridPlugin,
      interactionPlugin,
      rrulePlugin,
      luxonPlugin,
    ],
    schedulerLicenseKey: '0321884710-fcs-1478894211',
    scrollTime: companySettings.agenda.startTime,
    selectable: true,
    selectMirror: true,
    selectOverlap: (event: any) =>
      event.extendedProps?.type !== 'dynamic_price',
    slotEventOverlap: false,
    slotDuration: interval,
    slotLabelInterval: interval,
    slotMaxTime,
    slotMinTime,
    timeZone: company.timeZoneName,
    weekNumbers: true,
    weekText: t('calendar.week_prefix'),
    businessHours: [],
    scrollTimeReset: false,
    defaultTimedEventDuration: 0,
  };
};
