<template>
  <div :class="$style.base">
    <BaseCard gray>
      <BaseDropdown
        v-model="formData.languagePublic"
        :label="$t('giftcard_widget.settings.language')"
        :options="publicLanguages"
        v-test="'giftcard-setting-languagePublic'"
      />
    </BaseCard>
    <BaseCard gray>
      <BaseCheckbox
        v-model="formData.settings.sales.giftcardExpiration"
        :label="$t('giftcard_widget.settings.expiration_date')"
        v-test="'giftcard-setting-giftcardExpiration'"
      />
      <BaseDropdown
        v-if="formData.settings.sales.giftcardExpiration"
        v-model="formData.settings.sales.giftcardExpirationValue"
        :label="$t('giftcard_widget.settings.expires_in')"
        :options="
          [2, 3, 4, 5].map((value) => ({
            value,
            label: `${value} ${$t('global.items.year', value)}`
          }))
        "
        mt
        v-test="'giftcard-setting-giftcardExpirationValue'"
      />
    </BaseCard>
    <Amounts />
    <BaseCard gray>
      <BaseInput
        v-model="formData.giftcardPersonalMessage"
        type="textarea"
        :label="$t('giftcard_widget.settings.personal_message.heading')"
        :info="$t('giftcard_widget.settings.personal_message.info')"
        :maxLength="140"
        characterCount
        v-test="'giftcard-setting-giftcardPersonalMessage'"
      />
    </BaseCard>
    <IdealPayments />
  </div>
</template>

<script lang="ts">
export default {
  name: 'MainSettings',
};
</script>

<script setup lang="ts">
import IdealPayments from './IdealPayments.vue';
import Amounts from './Amounts.vue';
import { useBookingSettingsOptions } from '@/modules/admin/online-bookings/input-options';

const formData = inject<any>('giftcardSettingsData');
const { publicLanguages } = useBookingSettingsOptions();
</script>

<style lang="scss" module>
.base {
  & > *:not(:last-child) {
    margin-bottom: $spacing * 2;
  }
}
</style>
