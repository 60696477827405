import { modal } from '@/helpers/ui';
import { useUserStore } from '@/stores/user';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useI18n } from 'vue-i18n';

export default () => {
  const { t } = useI18n();
  const mixpanel = inject<any>('mixpanel');

  const { mutate: requestTrialMutation } = useMutation(
    gql`
      mutation activateTrial($input: ActivateTrialInput!) {
        activateTrial(input: $input) {
          trialEndsAt
          errors
        }
      }
    `,
    {
      variables: {
        input: {
          moduleName: 'marketing',
        },
      },
    },
  );

  const requestTrial = () =>
    new Promise<void>((resolve, reject) => {
      modal('confirmation', {
        message: t('upgrade_subscription_page.start_trial_modal.title'),
        subMessage: t(
          'upgrade_subscription_page.start_trial_modal.description',
        ),
      }).then(() => {
        mixpanel.track('Start marketing trial confirmed');
        requestTrialMutation()
          .then(() => {
            const { getUser } = useUserStore();
            getUser().finally(() => {
              resolve();
            });
          })
          .catch(() => {
            reject();
          });
      });
    });

  return {
    requestTrial,
  };
};
