<template>
  <BaseModal
    :heading="
      $t('global.actions.email_item', { item: $t('global.items.invoice') })
    "
    :parentRoute="{
      name: parentRouteName
    }"
    small
    :loading="$apollo.loading"
  >
    <BaseInput
      v-model="emailAddress"
      :label="$t('global.email_address')"
      type="email"
      required
      mb
      v-test="'invoice-email-email'"
    />
    <BaseInput
      v-model="emailSubject"
      :label="$t('global.email_subject')"
      type="text"
      mb
    />
    <BaseText mb color="secondary" size="s">
      <span v-pre>{{ company_name }}</span>
      {{ $t('register.optional_subject') }}
    </BaseText>
    <BaseEditor
      v-model="editorContent"
      :cloudinaryName="UploadedImageUsage.NewsletterContent"
      mb
      v-test="'invoice-email-editor'"
    />
    <BaseText mb color="secondary" size="s">
      {{ $t('register.optional_message') }}
    </BaseText>
    <BaseButton :loading="isSaving" @click="send" v-test="'invoice-email-save'">
      {{ $t('global.actions.send') }}
    </BaseButton>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import useVuelidate from '@vuelidate/core';
import gql from 'graphql-tag';
import { UploadedImageUsage } from '@/types';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    parentRouteName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate(), UploadedImageUsage };
  },
  data() {
    return {
      emailAddress: '',
      emailSubject: '',
      editorContent: '',
      isSaving: false,
    };
  },
  apollo: {
    order: {
      query: gql`
        query getOrder($id: ID!) {
          order(id: $id) {
            id
            invoiceEmailSubjectTemplate
            customer {
              email
            }
          }
        }
      `,
      variables() {
        return {
          id: this.orderId,
        };
      },
      skip() {
        return !this.orderId;
      },
      result({ data: { order } }) {
        this.emailAddress = order.customer?.email || '';
        this.emailSubject = order.invoiceEmailSubjectTemplate;
      },
    },
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
  },
  methods: {
    send() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isSaving = true;

      const input = {
        destination: this.emailAddress,
        emailSubject: this.emailSubject,
        message: this.editorContent,
        messageType: 'ORDER_INVOICE',
        orderId: this.order.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createMessage($input: CreateMessageInput!) {
              createMessage(input: $input) {
                errors
              }
            }
          `,
          variables: {
            input,
          },
        })
        .then(() => {
          if (this.parentRouteName === 'customer-invoices') {
            this.$router.push({
              name: 'customer-invoice',
              params: { id: this.order.id },
            });
          } else {
            this.$router.push({
              name: 'invoice',
              params: { id: this.order.id },
            });
          }
          flash(this.$t('global.flash.email_sent'));
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
});
</script>
