<template>
  <BaseTableRow zebra v-test="'zoomOrderItems'">
    <BaseTableCell :width="35">
      {{ product && product.name ? product.name : '-' }}
    </BaseTableCell>
    <BaseTableCell>
      {{ product && product.partNumber ? product.partNumber : '-' }}
    </BaseTableCell>
    <BaseTableCell>
      <BaseInput
        v-model="received"
        :minValue="0"
        type="number"
        controls
        v-test="'processItemInput'"
      />
    </BaseTableCell>
    <BaseTableCell>
      {{ quantity }}
    </BaseTableCell>
    <BaseTableCell>
      <BaseText v-if="difference >= 0" v-test="'processItemPlus'">
        {{ `${difference !== 0 ? '+' : ''}${difference}` }}
      </BaseText>
      <BaseLabel v-else color="error" v-test="'processItemMinus'">
        {{ difference }}
      </BaseLabel>
    </BaseTableCell>
  </BaseTableRow>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell,
  },
  props: {
    item: {
      type: Object,
    },
  },
  emits: ['updateQuantityReceived'],
  data() {
    return {
      id: null,
      quantityReceived: 0,
      quantity: 0,
      product: {
        name: null,
        partNumber: null,
      },
    };
  },
  computed: {
    received: {
      set(value: number) {
        this.quantityReceived = value;

        if (this.id) {
          this.$emit('updateQuantityReceived', {
            id: this.id,
            quantityReceived: value,
          });
        }
      },
      get() {
        return this.quantityReceived;
      },
    },
    difference() {
      return this.received - this.quantity;
    },
  },
  created() {
    Object.assign(this.$data, this.item);
  },
});
</script>
