<template>
  <div :class="$style.base">
    <div :class="$style.flex">
      <BaseText bold>
        {{ $t('marketing.email.sections.main_button') }}
      </BaseText>
      <BaseToggle
        v-if="!hideButtonToggle"
        v-model="hasButton"
        v-test="'toggleButton'"
      />
    </div>
    <div v-show="hasButton" v-test="'main-button-actions'">
      <BaseInput
        v-model="buttonTitle"
        :required="hasButton"
        mt
        :label="$t('marketing.email.sections.button_title')"
        v-test="'buttonMessage'"
      />
      <BaseRadio
        v-if="!hideButtonOptions"
        v-model="linkOption"
        mt
        :label="$t('marketing.email.sections.button_link')"
        :options="linkOptions"
        @update:modelValue="onLinkOptionSelect"
        v-test="'linkOptions'"
      />
      <BaseInput
        v-if="!hideButtonOptions"
        v-show="linkOption === 'other'"
        v-model="buttonUrl"
        :required="linkOption === 'other'"
        mt
        :label="$t('global.link')"
        v-test="'buttonLink'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import useVuelidate from '@vuelidate/core';

import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      linkOption: 'booking_widget',
      defaultLink: '',
    };
  },
  watch: {
    'v$.$invalid': {
      handler() {
        this.$store.commit('marketing/SET_BUTTON_VALIDATION', this.v$.$invalid);
      },
    },
    isSubmitted() {
      this.v$.$touch();
    },
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    ...mapGetters('marketing', ['id', 'isSubmitted']),
    ...mapGetters('marketing/builder', [
      'hideButtonToggle',
      'hideButtonOptions',
    ]),
    buttonTitle: {
      get() {
        return this.$store.getters['marketing/buttonTitle'];
      },
      set(value: string | null) {
        this.$store.commit('marketing/SET_BUTTON_TITLE', value);
      },
    },
    buttonUrl: {
      get() {
        const value = this.$store.getters['marketing/buttonUrl'];
        this.updateLinkOption(value);

        return value;
      },
      set(value: string | null) {
        this.$store.commit('marketing/SET_BUTTON_URL', value);
      },
    },
    hasButton: {
      get() {
        return this.$store.getters['marketing/hasButton'];
      },
      set() {
        this.$store.commit('marketing/TOGGLE_BUTTON');
      },
    },
    linkOptions() {
      return [
        {
          label: this.$t('global.booking_widget'),
          value: 'booking_widget',
        },
        {
          label: this.$t('global.another_link'),
          value: 'other',
        },
      ];
    },
  },
  methods: {
    onLinkOptionSelect(value) {
      this.linkOption = value;
      const link = this.linkOption === 'booking_widget' ? this.defaultLink : '';
      this.$store.commit('marketing/SET_BUTTON_URL', link);
    },
    updateLinkOption(value: string | null) {
      const usingDefaultLink =
        value === null || value.endsWith('/widget_bookings/new');
      this.linkOption = !usingDefaultLink ? 'other' : 'booking_widget';
    },
  },
  apollo: {
    microSite: {
      query: gql`
        query getMicroSite {
          microSite {
            id
            url
          }
        }
      `,
      result() {
        this.defaultLink = `${this.microSite.url}/widget_bookings/new`;

        if (!this.id) {
          this.$store.commit('marketing/SET_BUTTON_URL', this.defaultLink);
        }
      },
    },
  },
});
</script>

<style lang="scss" module>
.base {
  border-top: 1px solid $color-border;
  padding: $spacing 0;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
