<template>
  <div :class="$style.base">
    <MarketingArea />
  </div>
</template>

<script lang="ts">
import MarketingArea from './marketing-area/index.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    MarketingArea,
  },
});
</script>

<style lang="scss" module>
.base {
  width: 100%;
}
</style>
