<template>
  <DefaultModal
    v-if="
      (company.address && company.postalcode && company.city) || isTreatwellUser
    "
    @close="$emit('close')"
  />
  <AddressModal v-else @close="$emit('close')" />
</template>

<script setup lang="ts">
import DefaultModal from './Default.vue';
import AddressModal from './Address.vue';

import { onMounted, onBeforeUnmount, watch, defineEmits } from 'vue';
import { updateDataLayer } from '@/helpers/gtm';
import { useCompanyStore } from '@/stores/company';
import { useGtm } from '@gtm-support/vue-gtm';
import { storeToRefs } from 'pinia';
import { useTreatwellStore } from '@/stores/treatwell';

const { company } = useCompanyStore();
const { isSubscribed } = storeToRefs(useCompanyStore());
const gtm = useGtm();
const mixpanel = inject<any>('mixpanel');
const { isTreatwellUser } = storeToRefs(useCompanyStore());

const { getTreatwellStatus } = useTreatwellStore();

defineEmits(['close']);

watch(
  isSubscribed,
  (newVal) => {
    if (newVal) {
      // IMPORTANT! Track subscription conversion
      updateDataLayer().then(() => {
        if (gtm) {
          gtm.trackEvent({ event: 'subscription' });
        }
      });
    }
  },
  { immediate: true },
);
onMounted(() => {
  mixpanel.track('Welcome modal - Shown');
});

onBeforeUnmount(() => {
  mixpanel.track('Welcome modal - Closed');
  getTreatwellStatus();
});
</script>
