<template>
  <BaseModalLarge
    :heading="$t('waiting_list.modal_title')"
    :parentRoute="parentRouteName"
  >
    <WaitingList v-if="waitingListEnabled" />
    <EmptyPageContent
      v-if="!waitingListEnabled"
      imageName="marketing-waitinglist"
      :text="{
        title: $t('waiting_list.disabled.heading'),
        description: $t('waiting_list.disabled.info'),
        btnPrimary: $t('waiting_list.enable_waiting_list')
      }"
      @click="enable"
    />
  </BaseModalLarge>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted } from 'vue';
import WaitingList from '@/modals/waiting-list/WaitingList.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useCompanyStore } from '@/stores/company';
import { useI18n } from 'vue-i18n';
import { flash } from '@/helpers/ui';
import eventBus from '@/event-bus';
import { useOnboardingStore } from '@/stores/onboarding';
import { storeToRefs } from 'pinia';

defineProps<{
  parentRouteName: string;
}>();

const { t } = useI18n();

const companyStore = useCompanyStore();
const { currentOnboardingFlow } = storeToRefs(useOnboardingStore());
const waitingListEnabled = computed(
  () => companyStore.company.settings.bookings.waitingListEnabled,
);

const mixpanel = inject<any>('mixpanel');

onMounted(() => {
  mixpanel.track('Waiting list page in modal loaded');
});

const enable = () => {
  const input = {
    settings: {
      bookings: {
        waitingListEnabled: true,
      },
    },
  };

  companyStore.updateCompany(input).then(() => {
    mixpanel.track('Waiting list enabled');
    flash(t('global.flash.waiting_list_enabled'));
    if (currentOnboardingFlow.value === 'marketing') {
      eventBus.$emit('open-task-menu');
      currentOnboardingFlow.value = '';
    }
  });
};
</script>
