<template>
  <div
    v-if="supplier"
    :class="[
      $style.base,
      { [$style.isSmall]: $screen === 's' || $screen === 'm' }
    ]"
  >
    <div v-if="$screen === 's' || $screen === 'm'" :class="$style.largeButton">
      <BaseButton
        size="l"
        fullWidth
        @click="addingProducts = true"
        v-test="'mobileAddProducts'"
      >
        {{
          filters.capitalize(
            $t('global.actions.add_item', {
              item: $t('global.items.product', 1)
            })
          )
        }}
      </BaseButton>
    </div>
    <AddProducts
      v-show="addingProducts || $screen === 'l'"
      :supplier="supplier"
      :selectedProducts="selectedProducts"
      @addSelectedProduct="addSelectedProduct"
    />
    <Order
      :supplier="supplier"
      :hasDeletedItems="hasDeletedItems"
      :selectedProducts="selectedProducts"
      :deletedProducts="deletedProducts"
      @addSelectedProducts="addSelectedProducts"
      @updateSelectedProduct="updateSelectedProduct"
      @deleteSelectedProduct="deleteSelectedProduct"
    />
    <MobileControls
      v-if="addingProducts && ($screen === 's' || $screen === 'm')"
      :selectedProducts="selectedProducts"
      @process="addingProducts = false"
    />
  </div>
  <BaseSpinner v-else />
</template>

<script lang="ts" setup>
import filters from '@/filters';
import AddProducts from './AddProducts.vue';
import Order from './Order.vue';
import MobileControls from './MobileControls.vue';
import { GET_STOCK_ORDER } from './graphql';
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import type { SelectedProduct } from './types';
import type { Supplier } from '@/types';
import { ref } from 'vue';

const route = useRoute();

const { onResult } = useQuery(GET_STOCK_ORDER, {
  id: Number.parseInt(route.params.stockOrderId as string),
});

const supplier = ref<Supplier>();
const hasDeletedItems = ref(false);
const addingProducts = ref(false);

const selectedProducts = ref<SelectedProduct[]>([]);
const deletedProducts = ref<SelectedProduct[]>([]);

const addSelectedProducts = (products: SelectedProduct[]) => {
  products.forEach((product) => {
    addSelectedProduct(product);
  });
};

const addSelectedProduct = (product: SelectedProduct) => {
  selectedProducts.value.push({
    id: product.id,
    name: product.name,
    productId: product.productId,
    costPrice: product.costPrice,
    quantity: product.quantity || 1,
  });
};

const updateSelectedProduct = ({
  productId,
  quantity,
  costPrice,
}: {
  productId: number;
  quantity?: number;
  costPrice?: number;
}) => {
  const product = selectedProducts.value.find((p) => p.productId === productId);
  if (product) {
    if (typeof quantity === 'number') {
      product.quantity = quantity;
    }

    if (typeof costPrice === 'number') {
      product.costPrice = costPrice;
    }
  }
};

const deleteSelectedProduct = (product: SelectedProduct) => {
  const containsDeletedProduct = !!deletedProducts.value.find(
    (item) => item.productId === product.productId,
  );
  if (!containsDeletedProduct) {
    deletedProducts.value = [
      ...deletedProducts.value,
      { ...product, _destroy: true },
    ];
  }
  selectedProducts.value = selectedProducts.value.filter(
    (item) => item.productId !== product.productId,
  );
};

onResult(({ data }) => {
  supplier.value = data.stockOrder.supplier;

  const orderItems = [...data.stockOrder.orderItems];
  if (orderItems?.length) {
    hasDeletedItems.value = !orderItems.every((item) => item.product);

    if (!hasDeletedItems.value) {
      const mappedItems = orderItems.map((item) => {
        const mappedItem = {
          id: item.id,
          productId: item.product.id,
          name: item.product.name,
          costPrice: item.costPrice,
          quantity: item.quantity,
        };

        return mappedItem;
      });

      mappedItems.forEach((item) => {
        addSelectedProduct(item);
      });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  height: 100%;

  &.isSmall {
    flex-direction: column;
  }
}

.largeButton {
  padding: $spacing;
}
</style>
