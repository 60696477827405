<template>
  <BaseModal
    :heading="
      replacePinapparaat(
        $t('register.salonized_pay.landing.terminals.modal.heading'),
        company.country
      )
    "
    :loading="gettingTerminals"
    testId="terminals"
    @close="emit('close')"
  >
    <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
      <div :class="$style.terminals">
        <div
          v-for="(terminal, index) in terminals"
          :key="`terminal-${index}`"
          :class="[
            $style.terminal,
            { [$style.selected]: terminal.id === selectedTerminalId }
          ]"
          @click="selectedTerminalId = terminal.id"
          v-test="'terminal'"
        >
          <div :class="$style.terminalInner">
            <div :class="$style.terminalTop">
              <div :class="$style.terminalName">
                <div :class="$style.terminalCheck">
                  <BaseIcon name="check" color="inverted" size="s" />
                </div>
                <div :class="$style.terminalNameInner">
                  {{ terminal.name }}
                </div>
              </div>
              <div>
                {{ filters.currency(terminal.price) }}
              </div>
            </div>
            <img
              :src="`/img/salonized-pay/terminal${index + 1}.png`"
              :class="$style.visual"
            />
            <div :class="$style.terminalBottom">
              <div v-for="info in terminal.info" :key="info">
                {{
                  $t(
                    `register.salonized_pay.landing.terminals.modal.info.${info}`
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <BaseButton
        :loading="savingTerminal"
        @click="
          () => {
            saveSelectedTerminal();
            if ($gtm) {
              $gtm.trackEvent({ event: 'sp-terminal-save' });
            }
          }
        "
        v-test="'salonized-pay-terminal-modal-save'"
      >
        {{ $t('register.salonized_pay.landing.terminals.modal.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import {
  SAVE_ADYEN_SELECTED_TERMINAL_MODEL,
  GET_ADYEN_APP_STATE_TERMINAL,
} from './graphql';
import { ref, computed, inject } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { terminals } from './terminals';
import filters from '@/filters';
import { TerminalModel } from '@/types';
import { useAdyenStore } from '@/stores/adyen';
import { useCompanyStore } from '@/stores/company';
import { replacePinapparaat } from '@/helpers/formatting';

const { company } = useCompanyStore();
const mixpanel = inject<any>('mixpanel');
mixpanel.track('Salonized Pay - Choose terminal clicked');

const props = defineProps<{
  terminalId?: number;
}>();

const emit = defineEmits(['close', 'save']);

const selectedTerminalId = ref(props.terminalId);
const selectedTerminal = computed(() =>
  terminals.find((terminal) => terminal.id === selectedTerminalId.value),
);

const { mutate, loading: savingTerminal } = useMutation(
  SAVE_ADYEN_SELECTED_TERMINAL_MODEL,
);

const { onResult, loading: gettingTerminals } = useQuery(
  GET_ADYEN_APP_STATE_TERMINAL,
);

onResult(({ data: { adyenAppState } }) => {
  if (adyenAppState) {
    selectedTerminalId.value =
      adyenAppState.terminalModel === TerminalModel.Ams1 ? 1 : 2;
  } else {
    selectedTerminalId.value = props.terminalId || 1;
  }
});

const { refetchAdyenAppState } = useAdyenStore();

const saveSelectedTerminal = () => {
  mutate({
    input: {
      terminalModel: selectedTerminal.value?.key,
    },
  }).then(() => {
    mixpanel.track('Salonized Pay - Terminal saved', {
      choice: selectedTerminal.value?.name,
    });

    refetchAdyenAppState();
    emit('save');
    emit('close');
  });
};

mixpanel.track('Salonized Pay - Choose terminal modal opened');
</script>

<style lang="scss" module>
.terminals {
  .base:not(.smallScreen) & {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.terminal {
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  box-shadow: 0 0 0 0 $color-primary;
  transition: box-shadow 0.2s $easeOutBack;
  overflow: hidden;

  .base:not(.smallScreen) & {
    width: calc(50% - #{$spacing});
  }

  .base.smallScreen & {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: $spacing;
    }
  }

  &.selected {
    font-weight: bold;
    box-shadow: 0 0 0 2px $color-primary;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition:
      opacity 0.2s ease-out,
      transform 0.2s ease-out;
  }

  &:before {
    background: linear-gradient(
      45deg,
      transparent 0%,
      transparent 50%,
      rgb(222, 246, 241) 100%
    );
  }

  &:after {
    background: linear-gradient(
      45deg,
      rgb(195, 194, 232) 0%,
      transparent 50%,
      rgb(180, 236, 223) 100%
    );
  }

  &.selected {
    &:before {
      opacity: 0;
      transform: scale(2);
    }
  }

  &:not(.selected) {
    &:after {
      opacity: 0;
      transform: scale(2);
    }
  }
}

.terminalInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing;
  position: relative;
  z-index: 1;
}

.terminalTop {
  display: flex;
  justify-content: space-between;
  gap: $spacing;
  width: 100%;
}

.terminalName {
  position: relative;
  display: flex;
  height: 20px;
  align-items: center;
}

.terminalCheck {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: $color-primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    opacity 0.2s $easeOutBack,
    transform 0.2s $easeOutBack;

  .terminal:not(.selected) & {
    opacity: 0;
    transform: scale(0.5);
  }
}

.terminalNameInner {
  transition: transform 0.2s $easeOutBack;

  .terminal.selected & {
    transform: translateX(25px);
  }
}

.terminalBottom {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}

.visual {
  height: 175px;
  width: auto;
  margin: $spacing * 2;
  transition: transform 0.2s $easeOutBack;

  .terminal:not(.selected) & {
    transform: scale(0.9);
  }
}
</style>
