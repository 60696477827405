<template>
  <RangedReports
    :reportData="reportData"
    :reportTotals="reportTotals"
    :chartData="chartData"
    :currentRange="year"
    :loading="$apollo.loading"
    :startDate="startDate"
    month
    @change="onYearSelect"
  />
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import gql from 'graphql-tag';
import RangedReports from '../components/RangedReports.vue';
import i18n from '@/i18n';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    RangedReports,
  },
  data() {
    return {
      startDate: dayjs().format(),
      monthlyReport: {},
      reportTotals: {},
      reportData: [],
      reportChartData: {},
    };
  },
  apollo: {
    monthlyReport: {
      query: gql`
        query monthlyReport(
          $startDate: DateTime
          $locationId: ID
          $dataScope: DataScope
        ) {
          monthlyReport(
            startDate: $startDate
            locationId: $locationId
            dataScope: $dataScope
          ) {
            data {
              date
              bank
              cash
              coupon
              creditcard
              date
              discount
              giftcard
              online
              orders
              pin
              prepaidCard
              total
              totalInc
              transactions
              treatwellOnlinePayment
              vat
              pos
              other
            }
            chartData {
              categories
              orders
              totals
            }
            totals {
              total
              totalBank
              totalCash
              totalCoupon
              totalCreditcard
              totalGiftcard
              totalInc
              totalOnline
              totalOrders
              totalPin
              totalPrepaidCard
              totalTransactions
              totalTreatwellOnlinePayment
              totalPos
              totalOther
              totalVat
            }
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          locationId: this.locationId,
          dataScope: this.dataScope,
        };
      },
      result() {
        this.reportTotals = this.monthlyReport.totals;
        this.reportData = this.monthlyReport.data;
        this.reportChartData = this.monthlyReport.chartData;
      },
    },
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    year() {
      return dayjs(this.startDate).year();
    },
    chartData() {
      return {
        labels: this.reportChartData.categories,
        datasets: [
          {
            type: 'line',
            fill: false,
            label: i18n.t('reports.ranges.orders'),
            backgroundColor: '#10B796',
            borderColor: '#10B796',
            data: this.reportChartData.orders,
            xAxisID: 'x0',
            yAxisID: 'y0',
          },
          {
            type: 'bar',
            label: i18n.t('reports.ranges.turnover'),
            backgroundColor: '#262973',
            data: this.reportChartData.totals,
            xAxisID: 'x0',
            yAxisID: 'y1',
            barPercentage: 0.5,
          },
        ],
      };
    },
  },
  methods: {
    onYearSelect(date) {
      this.startDate = date.format();
    },
  },
});
</script>
