<template>
  <BaseModalLarge
    :parentRoute="
      parentRouteName
        ? {
            name: parentRouteName
          }
        : undefined
    "
    :heading="$t('integrations.settings_heading')"
  >
    <div :class="[$style.base, { [$style.isSmall]: $screen === 's' }]">
      <BaseSpinner v-if="formDataLoading" />
      <BaseCard v-else :tabs="tabs" fullWidth :class="$style.container">
        <AdminForm
          v-show="activeTab === 'settings'"
          :loading="isLoading"
          :formData="formData"
          :shouldEnable="shouldEnable"
          :changeRequest="changeRequest"
          :freeCommissionEndDate="
            currentIntegrationRequest?.freeCommissionEndDate
          "
          :showError="showUpdateVenueError"
          mb
          @submit="enableOrUpdate"
          @backToIntegrations="backToIntegrations"
        />
        <AdminInfo
          v-show="activeTab === 'stats'"
          :logs="logs"
          :companyApp="activeCompanyApp"
          :changeRequest="changeRequest"
        />
        <div v-show="activeTab === 'settings' || activeTab === 'services'">
          <ServiceMapping />
        </div>
      </BaseCard>
    </div>
  </BaseModalLarge>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import AdminForm from './AdminForm.vue';
import AdminInfo from './AdminInfo.vue';
import ServiceMapping from './service-mapping/index.vue';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useQuery } from '@vue/apollo-composable';
import { computed, defineComponent, ref } from 'vue';
import {
  integrationRequestQuery,
  venueQuery,
  companyAppQuery,
} from './graphql';
import type { VenueData, VenueFormData } from './types';
import type {
  TreatwellIntegrationRequest,
  TreatwellVenue,
  CompanyApp,
} from '@/types';
import { useTreatwellStore } from '@/stores/treatwell';

export default defineComponent({
  name: 'TreatwellMarketplace',
  components: {
    AdminForm,
    AdminInfo,
    ServiceMapping,
  },
  props: {
    parentRouteName: String,
  },
  setup() {
    const loadingRequest = ref(true);
    const currentIntegrationRequest = ref<TreatwellIntegrationRequest | null>(
      null,
    );
    useQuery(integrationRequestQuery).onResult(
      ({ data: { treatwellCurrentIntegrationRequest } }) => {
        currentIntegrationRequest.value = treatwellCurrentIntegrationRequest;
        loadingRequest.value = false;
      },
    );

    const loadingVenue = ref(true);
    const venue = ref<TreatwellVenue | null>(null);
    useQuery(venueQuery).onResult(({ data: { treatwellVenue } }) => {
      venue.value = treatwellVenue;
      loadingVenue.value = false;
    });

    const activeCompanyApp = ref<CompanyApp | null>(null);
    useQuery(companyAppQuery, { key: 'treatwell_marketplace' }).onResult(
      ({ data: { companyApp } }) => {
        activeCompanyApp.value = companyApp;
      },
    );

    const formDataLoading = computed(
      () => loadingRequest.value || loadingVenue.value,
    );

    const formData = computed<VenueData>(() => ({
      description:
        venue.value?.description ||
        currentIntegrationRequest.value?.salonDescription ||
        '',
      images: venue.value?.images || [],
      venueType: venue.value?.venueType || '',
      accountHolder:
        venue.value?.bankAccountHolder ||
        currentIntegrationRequest.value?.accountHolderName ||
        '',
      bankName:
        venue.value?.bankName ||
        currentIntegrationRequest.value?.bankName ||
        '',
      accountNumber:
        venue.value?.bankAccountNumber ||
        currentIntegrationRequest.value?.accountNumber ||
        '',
      bankCode:
        venue.value?.bankCode ||
        currentIntegrationRequest.value?.bankCode ||
        '',
      freeCommissionPeriod: 0,
    }));

    const changeRequest = computed(
      () => currentIntegrationRequest.value?.changeRequest || {},
    );

    const showUpdateVenueError = ref(false);

    return {
      formDataLoading,
      formData,
      changeRequest,
      activeCompanyApp,
      currentIntegrationRequest,
      showUpdateVenueError,
    };
  },
  data() {
    return {
      isLoading: false,
      tabs: [
        {
          id: 'settings',
          label: 'Settings',
          active: true,
        },
        {
          id: 'services',
          label: 'Service Mapping',
          active: false,
        },
        {
          id: 'stats',
          label: 'Stats',
          active: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    activeTab() {
      return this.tabs.find((tab) => tab.active).id;
    },
    logs() {
      return this.activeCompanyApp?.settings.logs || null;
    },
    shouldEnable() {
      const { hasActiveVenue } = useTreatwellStore();
      return !hasActiveVenue;
    },
  },
  methods: {
    backToIntegrations() {
      this.$router.push({ name: 'integrations' });
    },

    enableOrUpdate(parameters: VenueFormData) {
      this.shouldEnable
        ? this.enableIntegration(parameters)
        : this.updateIntegration(parameters);
    },
    enableIntegration(parameters: VenueFormData) {
      this.isLoading = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation treatwellIntegrationRequestAccept(
              $input: IntegrationRequestAcceptInput!
            ) {
              treatwellIntegrationRequestAccept(input: $input) {
                treatwellIntegrationRequest {
                  state
                }
                errors
              }
            }
          `,
          variables: {
            input: {
              salonDescription: parameters.description,
              images: parameters.images,
              venueType: parameters.venueType,
              bankingDetails: parameters.bankingDetails,
              freeCommissionPeriod: parameters.freeCommissionPeriod,
            },
          },
        })
        .then(
          ({
            data: {
              treatwellIntegrationRequestAccept: {
                treatwellIntegrationRequest,
                errors,
              },
            },
          }) => {
            if (errors) {
              this.showUpdateVenueError = true;
              return;
            } else if (treatwellIntegrationRequest?.state === 'PROCESSING') {
              flash(this.$t('global.flash.treatwell_marketplace_enabled'));
              this.backToIntegrations();
            }
          },
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateIntegration(parameters: VenueFormData) {
      this.isLoading = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateTreatwellVenue($input: UpdateTreatwellVenueInput!) {
              updateTreatwellVenue(input: $input) {
                treatwellVenue {
                  id
                  description
                  images
                  venueType
                }
                errors
              }
            }
          `,
          variables: {
            input: {
              description: parameters.description,
              images: parameters.images,
              venueType: parameters.venueType,
              bankingDetails: parameters.bankingDetails,
            },
          },
        })
        .then(
          ({
            data: {
              updateTreatwellVenue: { treatwellVenue, errors },
            },
          }) => {
            if (errors) {
              this.showUpdateVenueError = true;
              return;
            } else if (treatwellVenue) {
              flash(
                this.$t('global.flash.treatwell_marketplace_settings_updated'),
              );
              this.backToIntegrations();
            }
          },
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const { user } = useUserStore();
      if (!user.salonizedAdmin && !user.treatwellAdmin) {
        vm.$router.replace({ name: 'integrations' });
      }
    });
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: center;

  &.isSmall {
    padding: $spacing * 0.5;
  }
}

.container {
  .base:not(.isSmall) & {
    display: flex;
    gap: $spacing;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $color-border;
  padding: $spacing $spacing 0;

  .base:not(.isSmall) & {
    margin: -$spacing * 2;
    margin-top: 0;
  }
}
</style>
