<template>
  <div v-if="resources.length" :class="$style.base">
    <BaseHeading
      v-if="header"
      size="s"
      :mb="0.5"
      v-test="'resource-list-header'"
    >
      {{ header }}
    </BaseHeading>
    <Draggable
      v-model="listItems"
      :disabled="device.touch"
      :class="$style.list"
      item-key="id"
    >
      <template #item="{ element, index }">
        <ResourceItem
          :resource="element"
          :listLength="resources.length"
          :index="index"
          @updateSortOrder="sortByClick($event, element)"
          v-test="'visible-resources'"
        />
      </template>
    </Draggable>
  </div>
</template>

<script lang="ts">
import Draggable from 'vuedraggable';
import { sortByClick, sortByDrag } from '@/modules/admin/helpers';
import ResourceItem from './ResourceItem.vue';
import { useResourcesStore } from '@/stores/resources';
import { device } from '@/user-context';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ResourceItem,
    Draggable,
  },
  props: {
    resources: {
      type: Array,
    },
    header: {
      type: String,
    },
  },
  setup() {
    const { sortResources } = useResourcesStore();
    return {
      sortResources,
      device,
    };
  },
  computed: {
    listItems: {
      get() {
        return this.resources;
      },
      set(newValue) {
        this.sortResources(sortByDrag(newValue, this.listItems));
      },
    },
  },
  methods: {
    sortByClick(newIndex, resource) {
      this.sortResources(sortByClick(newIndex, resource, this.listItems));
    },
  },
});
</script>

<style lang="scss" module>
.base {
  margin-top: $spacing;
}

.list {
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.25;
  }
}
</style>
