<template>
  <div v-show="order.transactions.length" :class="$style.base">
    <div :class="$style.transactions">
      <TransitionGroup name="list">
        <Transaction
          v-for="(transaction, index) in transactions"
          :key="transaction._uid || index"
          :transaction="transaction"
          :class="$style.transaction"
          v-bind="{ editable }"
        />
      </TransitionGroup>
    </div>

    <div v-if="transactions.length > 1" :class="$style.total">
      <BaseHeading size="s">
        {{ $t('global.paid') }}
      </BaseHeading>
      <BaseText v-test="'register-transactions-total'">
        {{ filters.currency(totalPaid) }}
      </BaseText>
    </div>
    <div v-if="outstandingAmount < 0" :class="$style.change">
      <BaseHeading size="s">
        {{ $t('register.change_money') }}
      </BaseHeading>
      <BaseText v-test="'register-transactions-change'">
        {{ filters.currency(Math.abs(outstandingAmount)) }}
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import {
  useRegisterOrderStore,
  type OrderTransaction,
} from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
import Transaction from './Transaction.vue';
import { computed } from 'vue';
import { useRegisterStore } from '@/modules/register/stores/register';

defineProps<{
  editable?: boolean;
}>();

const { order } = useRegisterOrderStore();
const { totalPaid, outstandingAmount } = storeToRefs(useRegisterOrderStore());

const transactions = computed(() => {
  const transactions = order.transactions.filter(
    (transaction: OrderTransaction) => !transaction.destroy,
  );
  const { cashupEnabled } = useRegisterStore();
  if (cashupEnabled) {
    return transactions;
  } else {
    return mergeObjects(transactions);
  }
});

const mergeObjects = (arr: any[]) => {
  const result: any = {};
  arr.forEach((item) => {
    if (result[item.type]) {
      result[item.type].amount += item.amount;
    } else {
      result[item.type] = { type: item.type, amount: item.amount };
    }
  });
  return Object.values(result);
};
</script>

<style lang="scss" module>
.base {
  border-top: 1px solid $color-border;
}

.transactions {
  position: relative; // Needed for the transition of the child elements, which sets position: absolute;
}

.total {
  display: flex;
  justify-content: space-between;
  padding: $spacing;
  background-color: $grey-light;
}

.change {
  display: flex;
  justify-content: space-between;
  padding: $spacing;
  border-top: 1px solid $color-border;
}

.transaction {
  transition: background-color 0.5s ease;
}
</style>

<style lang="scss" scoped>
.list-enter-to {
  background-color: rgb(3, 207, 165, 0.3);
}

.list-move,
.list-leave-active {
  transition:
    opacity 0.2s ease,
    transform 0.2s ease;
}

.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
  width: 100%;
}
</style>
