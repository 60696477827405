<template>
  <BaseHeading size="s" mb>
    {{ $t('treatwell.services.title') }}
  </BaseHeading>
  <BaseText :mb="2">
    {{ $t('treatwell.services.description') }}
  </BaseText>
  <div v-if="!isLoading" v-test="'service-mapping'">
    <Category
      v-for="(item, index) in formData.servicesData"
      :key="index"
      :data="item"
      :popularTreatmentCount="popularTreatmentCount"
      mb
      :twTreatments="twTreatments"
      :twGroups="twGroups"
      @category:updated="categoryUpdated"
      v-test="`tw-service-mapping-category`"
    />
  </div>
  <BaseSpinner v-else />
  <div :class="$style.footer">
    <div>
      <BaseText v-test="'tw-sm-mapped-services-count'">
        {{
          $t('treatwell.services.ready_services', {
            servicesCount: servicesWithTwTreatments
          })
        }}
      </BaseText>
      <BaseText v-test="'tw-sm-mapped-popular-count'">
        {{
          $t('treatwell.services.popular_services', {
            popularTreatmentCount: popularTreatmentCount
          })
        }}
      </BaseText>
    </div>
    <BaseButton
      :loading="isSavingServices"
      @click="saveServiceMapping"
      v-test="'tw-sm-save'"
    >
      {{ $t('treatwell.services.save_changes') }}
    </BaseButton>
  </div>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
export default {
  name: 'ServiceMapping',
};
</script>

<script setup lang="ts">
import { reactive, ref, computed } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { useLeaveConfirmation } from '@/helpers/routing';

import {
  getCategories,
  getTreatwellTreatments,
  updateServices
} from './graphql';
import Category from './Category.vue';

import i18n from '@/i18n';
import filters from '@/filters';

import { uniqueArray } from '@/helpers/formatting';

interface ServicesData {
  category: {
    color: string;
    id: number;
    name: string;
  };
  services: {
    id: number;
    name: string;
    popular: boolean;
    twTreatmentIds: number[];
  }[];
}

const formData = reactive<{ servicesData: ServicesData[] }>({
  servicesData: []
});

// Bit of a hack to do it this way, but because servicesData is async
// it updates after the page has loaded, causing useLeaveConfirmation
// to think a change has been made
const serviceMappingUpdated = reactive({ updated: 0 });
const categoryUpdated = () => (serviceMappingUpdated.updated += 1);
const { resetConfirmation } = useLeaveConfirmation(serviceMappingUpdated);

const { onResult, loading: isLoading } = useQuery(getCategories);
onResult(({ data: { serviceCategories } }) => {
  formData.servicesData = serviceCategories.map(
    (category: {
      color: string;
      id: number;
      name: string;
      services: any[];
    }) => ({
      category: {
        color: category.color,
        id: category.id,
        name: category.name
      },
      services: category.services
        .filter((service) => service.bookable)
        .map(
          (service: {
            id: number;
            name: string;
            popular: boolean;
            price: number;
            twTreatments: any[];
          }) => ({
            id: service.id,
            name: `${service.name} ${filters.currency(service.price)}`,
            popular: service.popular,
            twTreatmentIds:
              service.twTreatments?.map(
                (treatment: { id: number }) => treatment.id
              ) || []
          })
        )
    })
  );
});

const { result } = useQuery(getTreatwellTreatments);
const twTreatments = computed(() => result.value?.treatwellTreatments || []);

const twGroups = computed(() => {
  if (!(twTreatments.value.length > 0)) {
    return [];
  }
  const groupPerTreatment = twTreatments.value.map((t) => ({
    value: t.group.id,
    label: t.group.name
  }));
  const uniqueGroups = uniqueArray(groupPerTreatment, 'value');
  return uniqueGroups;
});

const servicesWithTwTreatments = computed(() =>
  formData.servicesData.reduce(
    (count, current) =>
      count +
      current.services.filter((service) => service.twTreatmentIds.length > 0)
        .length,
    0
  )
);
const popularTreatmentCount = computed(() =>
  formData.servicesData.reduce(
    (count, current) =>
      count + current.services.filter((service) => service.popular).length,
    0
  )
);

const isSavingServices = ref(false);
const saveServiceMapping = () => {
  const bulkUpdateServiceAttributes: any = formData.servicesData
    .map((category) =>
      category.services.map((service) => ({
        id: service.id,
        popular: service.popular,
        twTreatmentIds: service.twTreatmentIds
      }))
    )
    .flat();

  const { mutate, onDone } = useMutation(updateServices, {
    variables: {
      input: {
        bulkUpdateServiceAttributes
      }
    }
  });
  mutate();
  resetConfirmation();
  isSavingServices.value = true;
  onDone(() => {
    flash(i18n.t('global.flash.services_updated'));
    isSavingServices.value = false;
  });
};
</script>

<style lang="scss" module>
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing;
}
</style>
