<template>
  <BaseCard
    :heading="!feedbackId ? treatwellPublishTitle : ''"
    v-test="'treatwell-batch-publish-card'"
  >
    <BaseSpinner v-if="loading" inline />
    <div
      v-else
      :class="[
        $style.cardContent,
        { [$style.smallCard]: !!feedbackId || $screen === 's' }
      ]"
    >
      <img
        v-if="$screen !== 's'"
        :class="$style.cardImage"
        src="/img/treatwell/request-trial.svg"
      />
      <div>
        <div v-if="feedbackId && $screen !== 's'">
          <BaseHeading size="l" :mb="0.5">{{
            treatwellPublishTitle
          }}</BaseHeading>
          <BaseText :mb="0.5">{{ treatwellPublishExplanation }}</BaseText>
        </div>
        <BaseHeading v-else-if="$screen !== 's'" size="l" mb mt>
          {{ treatwellPublishExplanation }}
        </BaseHeading>
        <BaseText v-else mb>
          {{ treatwellPublishExplanation }}
        </BaseText>
        <BaseButton
          v-if="!treatwellLastFeedbackPublished"
          mt
          :routerLink="{ name: 'feedback-publish' }"
          v-test="'treatwell-batch-publish-button'"
        >
          {{ $t('feedback.publication.start_publish_batch.start') }}
        </BaseButton>
        <BaseText v-else :routerLink="{ name: 'feedback-publish' }">
          {{ $t('feedback.publication.start_publish_batch.publish_more') }}
        </BaseText>
      </div>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import { TREATWELL_LAST_FEEDBACK_PUBLISHED } from './graphql';
import { useQuery } from '@vue/apollo-composable';
import { useLocationsStore } from '@/stores/locations';
import { computed } from 'vue';
import dayjs from '@/dayjs';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n();

const route = useRoute();
const feedbackId = computed(() =>
  typeof route.params.feedbackId === 'string'
    ? Number.parseInt(route.params.feedbackId)
    : 0,
);

const { locationId, dataScope } = useLocationsStore();

const { result, loading } = useQuery(TREATWELL_LAST_FEEDBACK_PUBLISHED, {
  locationId,
  dataScope,
});

const treatwellLastFeedbackPublished = computed(
  () => result.value?.treatwellLastFeedbackPublished?.twPublishedAt || null,
);
const daysSinceLastTWPublish = computed(() =>
  treatwellLastFeedbackPublished.value
    ? dayjs().diff(treatwellLastFeedbackPublished.value, 'day')
    : null,
);
const treatwellPublishTitle = computed(() => {
  if (daysSinceLastTWPublish.value && daysSinceLastTWPublish.value > 0) {
    return t(
      'feedback.publication.start_publish_batch.title_after_publish_more',
      { days: daysSinceLastTWPublish.value },
    );
  } else if (daysSinceLastTWPublish.value === 0) {
    return t(
      'feedback.publication.start_publish_batch.title_after_publish_today',
    );
  } else {
    return t('feedback.publication.start_publish_batch.title_before_publish');
  }
});

const treatwellPublishExplanation = computed(() =>
  daysSinceLastTWPublish.value
    ? t('feedback.publication.start_publish_batch.explanation_after_publish')
    : t('feedback.publication.start_publish_batch.explanation_before_publish'),
);
</script>

<style lang="scss" module>
.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.smallCard {
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}

.cardImage {
  .smallCard & {
    height: 124px;
    margin-right: $spacing;
  }
}
</style>
