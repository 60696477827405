<template>
  <BaseModal
    :heading="
      modalData && modalData.id
        ? $t('global.actions.edit_customer')
        : $t('global.actions.new_customer')
    "
    small
    useForm
    testId="create-customer"
    @submit="submit"
  >
    <BaseGrid container>
      <BaseGrid :size="6">
        <BaseInput
          v-model="firstName"
          :label="$t('global.first_name')"
          required
          v-test="'customer-firstname'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseInput
          v-model="lastName"
          :label="$t('global.last_name')"
          required
          v-test="'customer-lastname'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseInput
          v-model="email"
          :label="$t('global.email_address')"
          type="email"
          v-test="'customer-email'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseInput
          v-model="phone"
          :label="$t('global.mobile_number')"
          type="tel"
          v-test="'customer-phone'"
        />
      </BaseGrid>
    </BaseGrid>
    <template #footer>
      <BaseButton :loading="isLoading" submitForm v-test="'customer-submit'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import { customerFragment } from '@/graphql-fragments';

import { defineComponent } from 'vue';
import gql from 'graphql-tag';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    modalData: {
      type: Object,
    },
  },
  emits: ['submit'],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      isLoading: false,
    };
  },
  methods: {
    submit() {
      this.isLoading = true;

      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        mobilePhone: this.phone,
      };

      if (this.modalData.id) {
        payload.id = this.modalData.id;
      }

      (this.modalData.id ? this.updateCustomer : this.createCustomer)(payload)
        .then((response) => {
          this.$emit('submit', response);
          flash(
            this.$t(
              `global.flash.${this.modalData.id ? 'customer_updated' : 'customer_created'}`,
            ),
          );
          if (!this.modalData.id) {
            this.mixpanel.track('Customer created');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createCustomer(input: any) {
      return new Promise((resolve) => {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createCustomer($input: CreateCustomerInput!) {
                createCustomer(input: $input) {
                  customer {
                    ...customer
                  }
                  errors
                }
              }
              ${customerFragment}
            `,
            variables: {
              input,
            },
          })
          .then((response) => {
            resolve(response.data.createCustomer.customer);
          });
      });
    },
    updateCustomer(input: any) {
      return new Promise((resolve) => {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateCustomer($input: UpdateCustomerInput!) {
                updateCustomer(input: $input) {
                  customer {
                    ...customer
                  }
                }
              }
              ${customerFragment}
            `,
            variables: {
              input,
            },
          })
          .then((response) => {
            resolve(response.data.updateCustomer.customer);
          });
      });
    },
  },
  created() {
    if (this.modalData) {
      if (this.modalData.firstName) {
        this.firstName = this.modalData.firstName;
      }
      if (this.modalData.lastName) {
        this.lastName = this.modalData.lastName;
      }
      if (this.modalData.email) {
        this.email = this.modalData.email;
      }
      if (this.modalData.mobilePhone) {
        this.phone = this.modalData.mobilePhone;
      }
    }
  },
});
</script>
