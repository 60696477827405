import apolloClient from '@/apollo/client';
import { modal } from '@/helpers/ui';
import i18n from '@/i18n';
import { useLocationsStore } from '@/stores/locations';
import gql from 'graphql-tag';

export const checkAvailability = (variables: any) => {
  const { locationId } = useLocationsStore();

  return new Promise((resolve) => {
    apolloClient
      .query({
        query: gql`
          query checkAvailability(
            $appointmentId: Int
            $locationId: ID
            $parts: [AppointmentPartAttributes!]
            $startAt: DateTime!
            $action: String
          ) {
            availability(
              appointmentId: $appointmentId
              locationId: $locationId
              parts: $parts
              startAt: $startAt
              action: $action
            ) {
              available
              appointmentPartAvailabilities {
                partId
                availableResourceIds
              }
            }
          }
        `,
        variables: {
          ...variables,
          locationId: variables.locationId || locationId,
        },
      })
      .then(({ data: { availability } }) => {
        if (availability.available) {
          resolve(true);
        } else {
          modal('confirmation', {
            message: i18n.t('appointment.not_available'),
            catch: true,
          })
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              resolve(false);
            });
        }
      });
  });
};
