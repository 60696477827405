<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm'
      }
    ]"
  >
    <BaseAccordion v-model="activeKeys" :titles="titles" mb>
      <template #1>
        {{
          replacePinapparaat(
            $t('register.salonized_pay.landing.faq_section.asnwer_1'),
            company.country
          )
        }}
      </template>
      <template #2>
        {{ $t('register.salonized_pay.landing.faq_section.asnwer_2') }}
      </template>
      <template #3>
        {{ $t('register.salonized_pay.landing.faq_section.asnwer_3') }}
      </template>
      <template #4>
        {{
          replacePinapparaat(
            $t('register.salonized_pay.landing.faq_section.asnwer_4'),
            company.country
          )
        }}
      </template>
      <template #5>
        {{ $t('register.salonized_pay.landing.faq_section.asnwer_5') }}
      </template>
    </BaseAccordion>
    <div :class="$style.moreLink">
      <BaseText :href="helpPage" v-test="'sp-faq-learn-more'">
        {{ $t('global.learn_more') }}
      </BaseText>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCompanyStore } from '@/stores/company';
import { replacePinapparaat } from '@/helpers/formatting';

const { company } = useCompanyStore();
const { t } = useI18n();

const activeKeys = ref([]);
const titles = [
  t('register.salonized_pay.landing.faq_section.question_1'),
  t('register.salonized_pay.landing.faq_section.question_2'),
  t('register.salonized_pay.landing.faq_section.question_3'),
  t('register.salonized_pay.landing.faq_section.question_4'),
  t('register.salonized_pay.landing.faq_section.question_5'),
];

const helpPage =
  'https://help.salonized.com/nl/collections/6856279-salonized-pay';
</script>

<style lang="scss" module>
.base {
  max-width: 950px;
  width: 100%;
}

.moreLink {
  display: flex;
  justify-content: center;
  padding: $spacing;
}
</style>
