<template>
  <div>
    <router-view />
    <div :class="$style.header">
      <BaseButton
        @click="onCreateInvoiceClick"
        v-test="'customer-create-invoice'"
      >
        {{ $t('global.actions.create_invoice') }}
      </BaseButton>
    </div>
    <BaseTable
      v-if="orders.length"
      :headers="
        [
          $t('global.number'),
          $t('global.date_time'),
          hasSingleEmployee ? '' : $t('global.items.employee', 2),
          $t('global.total'),
          $t('global.status')
        ].filter((header) => !!header)
      "
      :rows="
        orders.map((order) => ({
          id: order.id,
          routerLink: {
            name: 'customer-invoice',
            params: {
              id: order.id
            }
          },
          cells: [
            order.number,
            {
              value: filters.date(order.invoicedAt),
              type: 'date'
            },
            hasSingleEmployee
              ? undefined
              : [
                  ...new Set(
                    order.items
                      ?.filter((item) => item.employee)
                      .map((item) => item.employee.name)
                  )
                ].join(', '),
            filters.currency(order.total),
            {
              label: order.state.toLowerCase()
            }
          ].filter((cell) => cell !== undefined)
        }))
      "
      v-test="'customer-orders'"
    />
    <LoadMore
      v-show="!allDataFetched && firstFetchFinished"
      @shown="fetchMore"
    />
    <BaseSpinner v-show="!firstFetchFinished" inline mt />
    <EmptyPageContent
      v-if="!loading && !orders.length"
      imageName="invoices"
      :text="{
        title: $t('customers.empty_state.invoices.title'),
        description: $t('customers.empty_state.invoices.description'),
        btnPrimary: $t('global.actions.create_invoice')
      }"
      :moreLink="{
        text: $t('invoices.empty_state.more_text'),
        url: $t('invoices.empty_state.more_url')
      }"
      @click="onCreateInvoiceClick"
    />
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import LoadMore from '@/components/LoadMore.vue';
import { usePagination } from '@/apollo/pagination';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';
import { inject } from 'vue';
import { useResourcesStore } from '@/stores/resources';

const router = useRouter();
const customer = inject<any>('customer');

const { hasSingleEmployee } = useResourcesStore();

const GET_ORDERS = gql`
  query getOrders(
    $pagination: PaginationAttributes!
    $customerId: ID
    $direction: SortDirectionEnum = ASC
  ) {
    orders(
      pagination: $pagination
      customerId: $customerId
      direction: $direction
    ) {
      id
      invoicedAt
      items {
        employee {
          name
        }
      }
      number
      state
      total
    }
  }
`;
const { orders, allDataFetched, loading, firstFetchFinished, fetchMore } =
  usePagination({
    fieldName: 'orders',
    query: GET_ORDERS,
    variables: {
      customerId: customer.value.id,
      direction: 'DESC',
    },
  });

const onCreateInvoiceClick = () => {
  router.push({ name: 'register', query: { customerId: customer.value.id } });
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing;
}
</style>
