<template>
  <div :class="$style.base" :style="baseStyle">
    <BaseText :mr="2" v-test="'previewDiscountMessage'">
      {{
        discountMessage
          ? discountMessage
          : $t('marketing.email.preview.discount_message_default')
      }}
    </BaseText>
    <div
      :class="$style.code"
      :style="opacityStyle"
      v-test="'previewDiscountCode'"
    >
      {{
        discount && discount.code
          ? discount.code
          : $t('marketing.email.preview.discount_code_default')
      }}
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    ...mapGetters('marketing', ['discountMessage', 'discount', 'color']),
    baseStyle() {
      return `border-color: ${this.color}; color: ${this.color};`;
    },
    opacityStyle() {
      return `background-color: ${this.color}10;`;
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $spacing * 1.5;
  border: 1px dashed $color-warning;
  color: $color-warning;
}

.code {
  background: $color-warning-light;
  padding: $spacing;
}
</style>
