<template>
  <BaseModal
    :heading="t('service.availability.heading')"
    @close="emit('close')"
  >
    <BaseText :mb="0.5">
      {{ t('service.availability.description') }}
    </BaseText>
    <BaseText link mb :href="t('service.availability.help_link')">
      {{ $t('global.learn_more') }}
    </BaseText>
    <BaseHeading>
      {{ t('service.availability.heading_settings') }}
    </BaseHeading>
    <Day
      v-for="(option, index) in clonedOptions"
      :key="index"
      :option="option"
      :selected="activeCardIndex === index"
      @click="activeCardIndex = activeCardIndex === index ? null : index"
      @deselect="activeCardIndex = null"
      @apply="applyToAll($event)"
    />
    <BaseText
      v-if="nothingSelected"
      mt
      color="error"
      v-test="'service-availability-unbookable-warning'"
    >
      {{ t('service.availability.unbookable_warning') }}
    </BaseText>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="emit('close')"
        v-test="'service-availability-cancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        @click="emit('submit', clonedOptions)"
        v-test="'service-availability-submit'"
      >
        {{ $t('global.actions.submit') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useCloned } from '@vueuse/core';
import Day from './Day.vue';
import type { DayOptions } from '../index';

const { t } = useI18n();

const props = defineProps<{
  options: DayOptions[];
}>();

const { cloned: clonedOptions } = useCloned(props.options);

const emit = defineEmits(['close', 'submit']);

const nothingSelected = computed(
  () => !clonedOptions.value.find((o) => o.available),
);

const activeCardIndex = ref<number | null>(null);

const applyToAll = (option: DayOptions) => {
  clonedOptions.value.forEach((o) => {
    o.available = option.available;
    o.endTime = option.endTime;
    o.max = option.max;
    o.startTime = option.startTime;
  });
};
</script>
