const FormTemplates = () => import('@/modules/admin/form-templates/index.vue');
const FormTemplate = () =>
  import('@/modules/admin/form-templates/form-template/index.vue');

export default [
  {
    path: 'forms',
    name: 'admin-forms',
    component: FormTemplates,
    meta: {
      featureFlag: 'admin-forms',
    },
    children: [
      {
        path: 'new-consultation',
        name: 'admin-forms-new',
        component: FormTemplate,
      },
      {
        path: 'new-contract',
        name: 'admin-forms-new-contract',
        component: FormTemplate,
        props: {
          contract: true,
        },
      },
      {
        path: ':id',
        name: 'admin-forms-form',
        component: FormTemplate,
      },
    ],
  },
];
