<template>
  <div
    :class="[
      $style.base,
      {
        [$style.isSmall]: $screen === 's',
        [$style.gray]: gray,
        [$style.noPadding]: noPadding,
        [$style.hasSubnav]: !!navItems.length,
        [$style.noBorder]: noBorder,
        [$style.fullHeight]: fullHeight,
        [$style.disableIntercomPadding]: disableIntercomPadding
      }
    ]"
  >
    <BaseSubNav v-if="navItems.length" :links="navItems" />
    <div :class="$style.content">
      <div :class="$style.contentInner">
        <template v-if="!loading">
          <router-view v-if="keepAliveComponentName" v-slot="{ Component }">
            <KeepAlive :include="keepAliveComponentName">
              <component :is="Component" />
            </KeepAlive>
          </router-view>
          <router-view v-else />
          <slot />
        </template>
        <BaseSpinner v-else />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { scrollPage } from '@/helpers/scroll';

export default defineComponent({
  props: {
    gray: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    navItems: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    disableIntercomPadding: {
      type: Boolean,
      default: false,
    },
    keepAliveComponentName: String,
  },
  created() {
    scrollPage({
      position: 'top',
      instant: true,
    });
  },
});
</script>

<style lang="scss" module>
$subnav-height: 50px;

.base {
  position: relative;
  z-index: 1;
  height: 100%;

  &:not(.isSmall):not(.hasSubnav):not(.noBorder):not(.gray) {
    border-top: 1px solid $color-border;
  }
}

.content {
  position: relative;
  min-height: 100%;

  .base.hasSubnav:not(.isSmall) & {
    min-height: calc(100% - #{$subnav-height});
  }

  .base.hasSubnav.isSmall & {
    min-height: calc(100% - #{$subnav-height + 1px});
  }

  .base.fullHeight:not(.hasSubnav) & {
    height: 100%;
  }

  .base.fullHeight.hasSubnav:not(.isSmall) & {
    height: calc(100% - #{$subnav-height});
  }

  .base.fullHeight.hasSubnav.isSmall & {
    height: calc(100% - #{$subnav-height + 1px});
  }

  .base:not(.gray) & {
    background-color: $white;
  }

  .base.gray & {
    background: $grey-light;
  }

  .base:not(.noPadding) & {
    padding: $spacing;
  }
}

.contentInner {
  height: 100%;

  .base:not(.fullHeight):not(.disableIntercomPadding) & {
    padding-bottom: 80px; // To prevent the intercom button from showing on top of the content
  }
}
</style>
