<template>
  <BaseSpinner v-if="adyenDashboardDataLoading" />
  <div
    v-else
    :class="[
      $style.base,
      { [$style.smallScreen]: $screen === 's' || $screen === 'm' }
    ]"
    v-test="'sp-dashboard'"
  >
    <BaseModal
      v-if="showFAQ"
      :heading="t('register.salonized_pay.landing.faq')"
      @close="showFAQ = false"
    >
      <FAQ v-test="'sp-faq-modal'" />
    </BaseModal>

    <PayoutSchedule
      v-if="showPayoutSchedule"
      @close="showPayoutSchedule = false"
    />

    <BaseHeading :mb="2" :mt="2">
      {{
        $t('register.salonized_pay.dashboard.welcome', {
          company: user?.firstName
        })
      }}
    </BaseHeading>

    <BaseVisualBanner
      v-if="
        adyenDashboardDataLength > 0 && !adyenDashboardData.hasPosTransaction
      "
      :heading="$t('register.salonized_pay.dashboard.complete_checkout')"
      imagePath="/img/salonized-pay/register.svg"
      mb
      :secondaryAction="{
        text: $t('register.salonized_pay.dashboard.register'),
        routerLink: { name: 'register' }
      }"
      variant="dark"
      color="green"
      @secondaryAction="
        mixpanel.track('Salonized Pay - Go to register clicked')
      "
      v-test="'sp-new-transaction-banner'"
    />

    <BaseVisualBanner
      v-if="showTerminalOrderConfirmedBanner"
      :heading="$t('register.salonized_pay.dashboard.order_complete')"
      :description="
        $t('register.salonized_pay.dashboard.order_complete_description')
      "
      imagePath="/img/salonized-pay/order.svg"
      color="green"
      mb
      v-test="'sp-new-terminal-order-banner'"
    />

    <div :class="$style.container">
      <div :class="$style.containerLeft">
        <BaseCard>
          <div :class="$style.headerContainer">
            <div :class="$style.header">
              <img
                v-if="company.logo"
                :src="company.logo?.small!"
                :class="$style.logo"
              />
              <img
                v-else
                src="/img/salonized-pay/onboarding/NoLogo.png"
                :class="$style.logo"
              />
              <div :class="$style.companyName">
                <BaseText bold size="l" :mt="0.5" center>{{
                  company.name
                }}</BaseText>
              </div>
              <BaseText>
                {{ $t('register.salonized_pay.verification.org') }}
              </BaseText>
            </div>
            <div :class="$style.optionContainer">
              <div
                :class="$style.option"
                @click="generateAdyenOnboardingLink"
                v-test="'sp-dashboard-edit-button'"
              >
                <BaseText iconBefore="external_link" bold>
                  {{ $t('register.salonized_pay.dashboard.edit') }}
                </BaseText>
              </div>
              <div
                :class="$style.option"
                @click="showFAQ = true"
                v-test="'sp-dashboard-faq-button'"
              >
                <BaseText iconBefore="question-mark" bold>
                  {{ $t('register.salonized_pay.verification.view_faq') }}
                </BaseText>
              </div>
            </div>
          </div>
        </BaseCard>
      </div>
      <div :class="$style.containerRight">
        <BaseTask
          v-if="adyenVerficationStatus.state !== 'completed'"
          :text="adyenVerficationStatus.text as string"
          :heading="adyenVerficationStatus.heading"
          :state="adyenVerficationStatus.state"
          :buttonIcon="adyenVerficationStatus.buttonIcon"
          :loading="loading"
          @click="generateAdyenOnboardingLink"
          @buttonClick="generateAdyenOnboardingLink"
          v-test="`sp-verification-${adyenVerficationStatus.state}`"
        />

        <BaseButton
          v-if="
            adyenAppState.verificationStatus !== 'VALID' &&
            (company.country === 'nl' || company.country === 'de')
          "
          :href="
            company.country === 'de'
              ? 'https://vimeo.com/980575413'
              : 'https://www.youtube.com/watch?v=CbYhuSSuRKk'
          "
          color="inverted"
          @click="
            mixpanel.track('Salonized Pay - Watch KYC video', {
              step: 'Dashboard'
            })
          "
          v-test="'sp-button-youtube'"
        >
          {{ $t('register.salonized_pay.landing.watch_video') }}
        </BaseButton>

        <BaseTask
          v-if="!hasTerminal && !unleash.isEnabled('SalonizedPayTerminals')"
          :text="$t('register.salonized_pay.verification.terminal.description')"
          :heading="$t('register.salonized_pay.verification.terminal.heading')"
          state="blocked"
          v-test="'sp-dashboard-terminal-card'"
        />

        <BaseTask
          v-if="showTerminalOrderTask && !hasTerminal"
          :text="$t('register.salonized_pay.dashboard.order.description')"
          :heading="$t('register.salonized_pay.dashboard.order.title')"
          state="required"
          @click="handleCheckoutModal"
          v-test="'sp-dashboard-order-task'"
        />

        <BaseAlert
          v-if="showFailureAlert"
          color="warning"
          :text="$t('register.salonized_pay.profile.failure_alert.text')"
          v-test="'sp-order-failure-alert'"
        />
        <div
          v-if="adyenDashboardDataLength > 0 && showBalance"
          :class="$style.statsContainer"
          v-test="'sp-dashboard-stats'"
        >
          <div :class="$style.payoutsSection">
            <BaseCard fullWidth>
              <div :class="$style.payout">
                <div>
                  <BaseHeading size="s" mb>
                    {{ $t('register.salonized_pay.dashboard.next_payout') }}
                  </BaseHeading>
                  <BaseText
                    size="l"
                    bold
                    v-test="'sp-dashboard-available-balance'"
                  >
                    {{ filters.currency(adyenDashboardData.availableBalance) }}
                  </BaseText>
                </div>

                <img
                  :class="$style.payoutSvg"
                  src="/img/salonized-pay/onboarding/payout.svg"
                />
              </div>
            </BaseCard>
            <BaseCard fullWidth>
              <div :class="$style.payout">
                <div>
                  <BaseHeading size="s" mb>
                    {{ $t('register.salonized_pay.dashboard.processing') }}
                  </BaseHeading>
                  <BaseText
                    size="l"
                    bold
                    v-test="'sp-dashboard-available-balance'"
                  >
                    {{ filters.currency(adyenDashboardData.processingBalance) }}
                  </BaseText>
                </div>
                <BaseText
                  link
                  @click="showPayoutSchedule = true"
                  v-test="'sp-payout-schedule-link'"
                >
                  {{ $t('register.salonized_pay.dashboard.schedule.link') }}
                </BaseText>
              </div>
            </BaseCard>
          </div>
        </div>

        <BaseCard
          v-if="!adyenDashboardDataLoading && adyenDashboardDataLength > 0"
          :heading="$t('global.pages.pos_terminals')"
        >
          <Terminals :terminals="adyenDashboardData.terminals" />
          <div
            v-if="showTerminalOrderTask && hasTerminal"
            :class="$style.addTerminal"
            @click="handleCheckoutModal"
            v-test="'sp-dashboard-order-task'"
          >
            <BaseIcon name="plus" color="success" />
            {{ $t('register.salonized_pay.dashboard.order.title_next') }}
          </div>
        </BaseCard>

        <OnlinePayments v-if="unleash.isEnabled('SalonizedPayOnline')" />

        <BaseCard
          v-if="adyenDashboardDataLength < 1"
          gray
          v-test="'sp-dashboard-start'"
        >
          <div :class="$style.verifying">
            <img src="/img/salonized-pay/onboarding/verifying.svg" />
            <BaseText ml>
              {{ $t('register.salonized_pay.dashboard.verifying') }}
            </BaseText>
          </div>
        </BaseCard>
      </div>
    </div>
    <OrderTerminalModal
      v-if="showOrderTerminalModal"
      @close="showOrderTerminalModal = false"
    />

    <SPSuccessModal v-if="showSuccessModal" @close="showSuccessModal = false" />
  </div>
</template>

<script lang="ts" setup>
import FAQ from '../FAQ.vue';
import Terminals from './Terminals.vue';
import SPSuccessModal from './order-terminal-modal/SPSuccessModal.vue';
import { useCompanyStore } from '@/stores/company';
import { computed, ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import { generateOnboardingLink } from '../helpers';
import {
  GET_ADYEN_DASHBOARD_DATA,
  GET_ADYEN_DASHBOARD_DATA_WITHOUT_BALANCE,
} from './graphql';
import { useUserStore } from '@/stores/user';
import filters from '@/filters';
import PayoutSchedule from './PayoutSchedule.vue';
import { useAdyenStore } from '@/stores/adyen';
import { useRoute, useRouter } from 'vue-router';
import OrderTerminalModal from './order-terminal-modal/index.vue';
import unleash from '@/unleash';
import dayjs from '@/dayjs';
import OnlinePayments from './OnlinePayments.vue';

const { t } = useI18n();
const loading = ref(false);
const showFAQ = ref(false);
const showPayoutSchedule = ref(false);
const showSuccessModal = ref(false);
const showFailureAlert = ref(false);

const router = useRouter();
const route = useRoute();

const mixpanel = inject<any>('mixpanel');
const showOrderTerminalModal = ref(false);

const { company } = useCompanyStore();
const { user, hasFeatureFlag } = useUserStore();
const { adyenAppState } = useAdyenStore();

const handleCheckoutModal = () => {
  mixpanel.track('Salonized Pay Checkout - Start checkout clicked');
  showOrderTerminalModal.value = true;
  showFailureAlert.value = false;
};

const showBalance = computed(() => hasFeatureFlag('module-reports'));

const { result: dashboardDataResult, loading: adyenDashboardDataLoading } =
  useQuery(
    showBalance.value
      ? GET_ADYEN_DASHBOARD_DATA
      : GET_ADYEN_DASHBOARD_DATA_WITHOUT_BALANCE,
  );

const adyenDashboardData = computed(
  () => dashboardDataResult.value?.adyenDashboardData,
);
const adyenDashboardDataLength = computed(
  () =>
    adyenDashboardDataLoading &&
    adyenDashboardData.value &&
    adyenDashboardData.value.terminals.length,
);
const hasTerminal = computed(
  () =>
    adyenDashboardData.value?.terminals?.length ||
    adyenDashboardData.value?.lastTerminalOrderAt,
);

const generateAdyenOnboardingLink = () => {
  loading.value = true;
  mixpanel.track('KYC - Verification step edited');

  generateOnboardingLink().then(() => {
    loading.value = false;
  });
};

const adyenVerficationStatus = computed(() => {
  switch (adyenAppState.verificationStatus) {
    case 'VALID':
      return {
        heading: t('register.salonized_pay.verification.pending.heading'),
        text: t('register.salonized_pay.verification.valid.description'),
        buttonIcon: 'edit',
        state: 'completed',
      };
    case 'PENDING':
      return {
        heading: t('register.salonized_pay.verification.pending.heading'),
        text: t('register.salonized_pay.verification.pending.description'),
        buttonIcon: 'edit',
        state: 'pending',
      };
    case 'INVALID':
      return {
        heading: t('register.salonized_pay.verification.pending.heading'),
        text: t('register.salonized_pay.verification.invalid.description'),
        state: 'attention',
      };
    default:
      return {
        heading: t('register.salonized_pay.verification.pending.heading'),
        text: t('register.salonized_pay.verification.pending.description'),
        buttonIcon: 'edit',
        state: 'pending',
      };
  }
});

if (route.query.payment === 'success') {
  showSuccessModal.value = true;
  setTimeout(() => {
    showSuccessModal.value = false;
  }, 10000);
  router.replace({ name: 'salonized-pay' });
}

if (route.query.payment === 'failure') {
  showFailureAlert.value = true;
  router.replace({ name: 'salonized-pay' });
}

const showTerminalOrderTask = computed(
  () => adyenAppState.verificationStatus === 'VALID',
);

const showTerminalOrderConfirmedBanner = computed(
  () =>
    adyenDashboardData.value?.lastTerminalOrderAt &&
    dayjs(adyenDashboardData.value.lastTerminalOrderAt).isBetween(
      dayjs().subtract(4, 'days'),
      dayjs(),
    ),
);
</script>

<style lang="scss" module>
.statsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: multi-lines;

  position: relative;

  .smallScreen & {
    flex-direction: column;
  }
}

.payout {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.payoutSvg {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 82px;
}

.base {
  width: 100%;
  max-width: 910px;
}

.logo {
  border-radius: 100%;
  border: 1px solid $color-border;
  max-width: 65px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
  padding-top: $spacing * 0.5;

  :not(.smallScreen) & {
    margin-right: $spacing;
  }
}

.companyName {
  width: 100%;
}

.option {
  display: flex;
  cursor: pointer;

  padding: $spacing 0;
  border-bottom: 1px solid $color-border;
}

.optionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: $spacing * 1.5;
  flex: 1;
}

.verifying {
  display: flex;
  align-items: center;
}

.scheduleWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: $spacing;
}

.payoutsSection {
  display: flex;

  .smallScreen & {
    flex-direction: column;
    & > *:first-child {
      margin-bottom: $spacing;
    }
  }

  .base:not(.smallScreen) & {
    & > * {
      width: 50%;
    }

    & > *:first-child {
      margin-right: $spacing;
    }
  }
}

.addTerminal {
  padding: $spacing;
  margin-top: $spacing;
  border: 1px dashed $color-success;
  border-radius: $radius;
  font-weight: bold;
  display: flex;
  gap: $spacing * 0.5;
  align-items: center;
  cursor: pointer;

  @include hover {
    background-color: $color-highlight;
  }
}

.container {
  display: flex;
  gap: $spacing;

  .base.smallScreen & {
    flex-direction: column;
  }
}

.containerLeft {
  .base:not(.smallScreen) & {
    width: 234px;
    flex-shrink: 0;
  }

  .base:not(.smallScreen) & {
    & > * {
      height: 100%;
    }
  }
}

.containerRight {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing;
}
</style>
