<template>
  <BaseCard
    :closable="isExpanded && !onlyResource"
    noPadding
    @close="$emit('delete')"
  >
    <EditableContent
      v-show="isExpanded"
      :part="part"
      :partIndex="partIndex"
      :onlyResource="onlyResource"
      v-test="'appointment-part-expanded'"
    />
    <CollapsedContent
      v-show="!isExpanded"
      :part="part"
      :partIndex="partIndex"
      @click="onCollapsedClick"
      v-test="'appointment-part-collapsed'"
    />
  </BaseCard>
</template>

<script lang="ts">
import EditableContent from './content/index.vue';
import CollapsedContent from './CollapsedContent.vue';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppointmentPart',
  components: {
    EditableContent,
    CollapsedContent,
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    partIndex: Number,
  },
  emits: ['expand', 'delete'],
  setup() {
    const { existingAppointmentData } = useCreateAppointmentStore();

    return {
      existingAppointmentData,
    };
  },
  computed: {
    onlyResource() {
      return this.existingAppointmentData.treatwell;
    },
  },
  methods: {
    onCollapsedClick() {
      this.$emit('expand');
    },
  },
});
</script>
