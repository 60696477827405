<template>
  <div :class="$style.base">
    <BaseText
      iconBefore="arrow-left"
      :mb="1.5"
      link
      @click="navBack"
      v-test="'backToEmails'"
    >
      {{ $t('global.back') }}
    </BaseText>

    <BaseHeading :mb="0.5">
      {{ title }}
    </BaseHeading>
    <BaseText mb>
      {{ description }}
    </BaseText>
    <BaseToggle
      v-if="showToggle && $route.name !== 'rebook-reminder-template'"
      v-model="enabled"
      :label="enabled ? $t('global.enabled') : $t('global.disabled')"
      v-test="'intro-toggle'"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';
import { useNavBack } from '../useNavBack';

export default defineComponent({
  setup() {
    const { navBack } = useNavBack();
    return { navBack };
  },
  computed: {
    ...mapGetters('marketing/builder', [
      'title',
      'showToggle',
      'description',
      'emailType',
    ]),
    enabled: {
      get() {
        return this.$store.getters['marketing/builder/enabled'];
      },
      set() {
        this.$store.commit('marketing/builder/SET_ENABLED', !this.enabled);
      },
    },
  },
});
</script>

<style lang="scss" module>
.base {
  margin-right: auto;
}
</style>
