<template>
  <div :class="$style.base">
    <BaseGrid container>
      <BaseGrid :size="6" alignBottom>
        <BaseDropdown
          v-model="selectedReactionFilter"
          :options="filterReactionOptions"
          :label="$t('feedback.reaction')"
          v-test="'feedback-filter-reaction'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseDropdown
          v-model="selectedPublishingStatusFilter"
          :options="filterPublishingStatusOptions"
          :label="$t('feedback.publishing_status')"
          v-test="'feedback-filter-publishing-status'"
        />
      </BaseGrid>
    </BaseGrid>
    <div :class="$style.border" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    reactionFilter: {
      type: String,
      required: false,
    },
    publishingStatusFilter: {
      type: String,
      required: false,
    },
  },
  emits: ['update:reactionFilter', 'update:publishingStatusFilter'],
  computed: {
    selectedReactionFilter: {
      get() {
        return this.reactionFilter;
      },
      set(value) {
        this.$emit('update:reactionFilter', value);
      },
    },
    selectedPublishingStatusFilter: {
      get() {
        return this.publishingStatusFilter;
      },
      set(value) {
        this.$emit('update:publishingStatusFilter', value);
      },
    },
    filterReactionOptions() {
      return [
        {
          value: null,
          label: this.$t('global.all'),
        },
        {
          value: 'POSITIVE',
          label: this.$t('global.positive'),
        },
        {
          value: 'NEGATIVE',
          label: this.$t('global.negative'),
        },
      ];
    },
    filterPublishingStatusOptions() {
      return [
        {
          value: null,
          label: this.$t('global.all'),
        },
        {
          value: 'PUBLISHED',
          label: this.$t('feedback.publishing_status_filter.published'),
        },
        {
          value: 'ALLOWED',
          label: this.$t('feedback.publishing_status_filter.allowed'),
        },
        {
          value: 'DISALLOWED',
          label: this.$t('feedback.publishing_status_filter.not_allowed'),
        },
        {
          value: 'REQUESTED',
          label: this.$t('feedback.publishing_status_filter.requested'),
        },
      ];
    },
  },
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing $spacing 0;
}

.border {
  padding-bottom: $spacing;
  border-bottom: 1px solid $color-border;
}
</style>
