<template>
  <BaseCard
    :heading="$t('dashboard.notes.heading')"
    :headerMargin="!notes.length"
    :maxContentHeight="notes.length ? 204 : null"
  >
    <CreateNoteModal
      v-if="showCreateModal"
      @created="onNoteCreated"
      @close="showCreateModal = false"
    />
    <template #header>
      <BaseText
        link
        @click="showCreateModal = true"
        v-test="'dashboard-create-note'"
      >
        {{ $t('global.actions.create_note') }}
      </BaseText>
    </template>
    <div v-if="notes.length">
      <NotesItem
        v-for="item in notes"
        :key="item.id"
        :data="item"
        v-test="'dashboard-note'"
      />
    </div>
    <div v-else v-test="'empty-notes'">
      <BaseText>
        {{ $t('dashboard.notes.empty') }}
      </BaseText>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import NotesItem from './NotesItem.vue';
import CreateNoteModal from '@/modules/dashboard/CreateNote.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    NotesItem,
    CreateNoteModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showCreateModal: false,
      notes: [],
    };
  },
  watch: {
    data: {
      handler() {
        this.notes = [...this.data];
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onNoteCreated(note) {
      this.notes.unshift(note);
      this.showCreateModal = false;
    },
  },
});
</script>
