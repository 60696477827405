<template>
  <div>
    <BaseTable
      :headers="
        [
          $t('global.name'),
          $t('global.category'),
          $t('global.price'),
          !filter ? $t('global.vat') : null,
          filteredOnStockWarning ? $t('products.min_stock') : null,
          $t('global.stock'),
          filteredOnInventory ? $t('products.stock_value') : null
        ].filter((header) => !!header)
      "
      :rows="
        products.map((product) => ({
          id: product.id,
          routerLink: {
            name: 'product',
            params: {
              id: product.id
            }
          },
          cells: [
            product.name,
            product.category ? product.category.name : null,
            {
              value: filters.currency(product.price),
              type: 'currency'
            },
            !filter
              ? product.vatRate.percentage !== null
                ? `${product.vatRate.percentage}% ${product.vatRate.name}`
                : product.vatRate.name
              : undefined,
            filteredOnStockWarning ? product.stockMinimum : undefined,
            {
              value: product.stockEnabled ? product.stockAmount : null,
              color: product.outOfStock ? 'error' : undefined
            },
            filteredOnInventory
              ? product.stockEnabled
                ? filters.currency(product.stockValue)
                : null
              : undefined
          ].filter((cell) => cell !== undefined),
          actions: getActions(product)
        }))
      "
      @action="onAction"
      v-test="'products'"
    />
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { modal } from '@/helpers/ui';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const props = defineProps<{
  filter: string;
  products: any[];
}>();

const emit = defineEmits(['delete', 'edit']);

const { t } = useI18n();
const { hasFeatureFlag } = useUserStore();

const filteredOnStockWarning = computed(() => props.filter === 'out_of_stock');
const filteredOnInventory = computed(() => props.filter === 'stock_enabled');

const getActions = (product: any) => {
  const actions = [
    {
      name: 'edit',
      icon: 'edit',
      label: t('global.actions.edit'),
    },
  ];

  if (product.stockEnabled) {
    actions.push({
      name: 'update',
      icon: 'stock',
      label: t('global.actions.update_stock'),
    });
  }

  if (hasFeatureFlag('product-delete')) {
    actions.push({
      name: 'delete',
      icon: 'delete',
      label: t('global.actions.delete'),
    });
  }

  return actions;
};

const router = useRouter();

const onAction = ({ id, option }) => {
  if (option === 'edit') {
    router.push({ name: 'edit-product', params: { id } });
  }

  if (option === 'delete') {
    modal('confirmation', {
      type: 'delete',
      item: t('global.items.product'),
    }).then(() => {
      emit('delete', id);
    });
  }

  if (option === 'update') {
    router.push({ name: 'update-stock', params: { id } });
  }
};
</script>
