<template>
  <div :class="$style.base">
    <BaseHeading center size="xl" mr>
      {{ value }}
    </BaseHeading>
    <BaseText>
      {{ $t(`marketing.insights.stats.${text}`) }}
    </BaseText>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
}
</style>
