<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-register')"
    :text="{
      title: $t('upgrade_subscription_page.register.cashups.title'),
      description: $t('upgrade_subscription_page.register.cashups.description'),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    :moreLink="{
      text: $t('upgrade_subscription_page.register.cashups.learn_more'),
      url: $t('upgrade_subscription_page.register.cashups.learn_more_url')
    }"
    imageName="cashups"
  />

  <div v-else>
    <Flex :mb="0.5">
      <BaseDatePicker v-model="date" onlyPastDates arrows />
      <BaseButton
        color="inverted"
        :disabled="showingToday"
        @click="setCurrentDate"
      >
        {{ $t('global.today') }}
      </BaseButton>
    </Flex>
    <BaseTable
      v-if="cashups && cashups.length"
      :headers="[
        $t('global.items.location', 1),
        $t('cashups.opened_on'),
        $t('cashups.closed_on'),
        $t('cashups.closed_by')
      ]"
      :rows="
        cashups.map((c) => ({
          id: c.id,
          routerLink: { name: 'cashup', params: { id: c.id } },
          cells: [
            c.location?.name,
            filters.dateTime(c.openedOn),
            filters.dateTime(c.closedOn),
            c.closedBy?.fullName
          ]
        }))
      "
      v-test="'register-cashups'"
    />
    <EmptyPageContent
      v-show="showEmptyPageContent"
      imageName="cashups"
      :text="
        hasUsedCashups
          ? {
              title: $t('cashups.empty_state.title'),
              description: $t('cashups.empty_state.description')
            }
          : null
      "
      :moreLink="
        hasUsedCashups
          ? {
              text: $t('cashups.empty_state.more_text'),
              url: $t('cashups.empty_state.more_url')
            }
          : null
      "
      videoId="760209366"
    />
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import gql from 'graphql-tag';
import filters from '@/filters';
import { usePagination } from '@/apollo/pagination';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import LoadMore from '@/components/LoadMore.vue';
import { useUserStore } from '@/stores/user';
import dayjs from '@/dayjs';
import EmptySubscriptionPage from '../../_shared/subscription/EmptySubscriptionPage.vue';
import { useQuery } from '@vue/apollo-composable';

const { hasFeatureFlag } = useUserStore();
const date = ref(dayjs().tz().format('YYYY-MM-DD'));
const { cashups, loading, allDataFetched, fetchMore } = usePagination({
  fieldName: 'cashups',
  query: gql`
    query getCashups(
      $pagination: PaginationAttributes
      $locationId: ID
      $dataScope: DataScope
      $date: ISO8601Date
    ) {
      cashups(
        pagination: $pagination
        locationId: $locationId
        dataScope: $dataScope
        date: $date
      ) {
        id
        location {
          name
        }
        openedOn
        closedOn
        closedBy {
          fullName
        }
      }
    }
  `,
  options: {
    multiLocation: true,
  },
  variables: {
    date,
  },
});

const { result, loading: cashupsLoading } = useQuery(
  gql`
    query getCashups($limit: Int) {
      cashups(limit: $limit) {
        id
      }
    }
  `,
  { limit: 1 },
);

const showEmptyPageContent = computed(
  () => !loading.value && !cashups.value.length,
);
const showingToday = computed(() => dayjs(date.value).isToday());

const hasUsedCashups = computed(
  () => cashupsLoading && result.value?.cashups.length === 0,
);

const setCurrentDate = () => {
  date.value = dayjs().tz().format('YYYY-MM-DD');
};
</script>
