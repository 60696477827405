<template>
  <div>
    <BaseToggle
      v-model="dynamicPricingMode"
      :label="$t('dynamic_pricing.mode.title')"
      :description="$t('dynamic_pricing.mode.description')"
      @update:modelValue="track($event)"
      v-test="'enable-dynamic-pricing-mode'"
    />
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  setup() {
    const { dynamicPricingMode } = storeToRefs(useCalendarFiltersStore());
    return {
      dynamicPricingMode,
    };
  },
  methods: {
    track(value) {
      this.mixpanel.track(`Dynamic pricing toggle: ${value} from calendar`);
    },
  },
});
</script>
