<template>
  <BaseModal
    :heading="$t('register.attach_note')"
    small
    @close="$emit('close')"
  >
    <BaseGrid container>
      <BaseGrid :size="12">
        <BaseInput
          v-model="note"
          type="textarea"
          focus
          v-test="'createNoteInput'"
        />
      </BaseGrid>
    </BaseGrid>
    <template #footer>
      <BaseButton @click="submit" v-test="'createNoteSave'">{{
        $t('global.actions.save')
      }}</BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    currentNote: {
      type: String,
      default: '',
    },
  },
  emits: ['submit', 'close'],
  data() {
    return {
      note: '',
    };
  },
  methods: {
    submit() {
      this.mixpanel.track('Register - Add note clicked');
      this.$emit('submit', this.note);
      this.$emit('close');
    },
  },
  created() {
    this.note = this.currentNote;
  },
});
</script>
