<template>
  <div
    :class="[
      $style.base,
      {
        [$style.completed]: completed,
        [$style.inline]: !showVisual && !buttonText && !showIcon,
        [$style.hasIcon]: !!showIcon
      }
    ]"
    @click="!completed || showIcon ? $emit('click') : null"
  >
    <div :class="$style.content">
      <div v-if="label" :class="$style.label">
        <BaseLabel :color="labelColor">
          {{ label }}
        </BaseLabel>
      </div>
      <div :class="$style.main">
        <BaseCheck
          :checked="completed"
          :mr="0.5"
          v-test="completed ? '_task-check-checked' : '_task-check-unchecked'"
        />
        <BaseText>
          {{ text }}
        </BaseText>
      </div>
    </div>
    <div v-if="showVisual" :class="$style.visual">
      <img :src="visual" :class="$style.visualImage" />
    </div>
    <BaseButton
      v-else-if="completed && buttonText"
      :loading="loading"
      color="inverted"
      @click="$emit('buttonClick')"
    >
      {{ buttonText }}
    </BaseButton>
    <div v-else-if="showIcon" :class="$style.icon">
      <BaseIcon :name="icon" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import { computed } from 'vue';
import type { IconName } from '@/components/base-icon/types';

const props = withDefaults(
  defineProps<{
    buttonText?: string;
    completed?: boolean;
    icon?: IconName;
    label?: string;
    labelColor?:
      | 'info'
      | 'success'
      | 'warning'
      | 'error'
      | 'default'
      | 'primary';
    loading?: boolean;
    text: string;
    visual?: string;
  }>(),
  {
    completed: false,
    labelColor: 'primary',
    loading: false
  }
);

defineEmits(['click', 'buttonClick']);

const showVisual = computed(() => !!props.visual && !props.completed);
const showIcon = computed(() => props.completed && !!props.icon);
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing $spacing * 0.5;
  border-radius: $radius;
  background-color: white;

  &:not(.completed) {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.1s;

    @include hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
    }
  }

  &:not(.completed),
  &.hasIcon {
    cursor: pointer;
  }

  &.completed {
    border: 1px solid $color-border;
  }
}

.label {
  padding-left: 2px;
  flex-shrink: 0;

  .base.inline & {
    margin-left: $spacing * 0.5;
  }

  .base:not(.inline) & {
    margin-bottom: 5px;
  }
}

.content {
  .base.inline & {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
  }
}

.main {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  padding: $spacing * 0.5;
}

.visual {
  position: relative;
  width: 80px;
  height: 70px;
  margin: $spacing * -0.5 0 $spacing * -0.5 $spacing;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 10px);
    height: 100%;
    background-color: #f5f6fa;
    border-radius: 8px;
  }
}

.visualImage {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  z-index: 1;
}
</style>
