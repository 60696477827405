<template>
  <div>
    <BaseGrid container>
      <CreateFilterGroup
        v-if="showCreateModal"
        @close="showCreateModal = false"
      />
      <BaseGrid alignRight>
        <BaseButton
          @click="showCreateModal = true"
          v-test="'createFilterGroup'"
        >
          {{ $t('customers.create_filter_group') }}
        </BaseButton>
      </BaseGrid>
    </BaseGrid>
    <FilterList
      v-if="customerFilterGroups && customerFilterGroups.length"
      :items="customerFilterGroups"
    />
    <BaseSpinner v-else-if="$apollo.loading && !customerFilterGroups" />
    <EmptyPageContent
      v-else
      imageName="marketing-filters"
      :text="{
        title: $t('marketing.filter_groups.empty_state.title'),
        description: $t('marketing.filter_groups.empty_state.description'),
        btnPrimary: $t('customers.create_filter_group')
      }"
      :moreLink="{
        text: $t('marketing.filter_groups.empty_state.more_text'),
        url: $t('marketing.filter_groups.empty_state.more_url')
      }"
      @click="showCreateModal = true"
    />
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import FilterList from './FilterList.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import CreateFilterGroup from '@/modules/marketing/modals/CreateFilterGroup.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    FilterList,
    EmptyPageContent,
    CreateFilterGroup,
  },
  data() {
    return {
      showCreateModal: false,
    };
  },
  apollo: {
    customerFilterGroups: {
      query: gql`
        query getCustomerFilterGroups {
          customerFilterGroups {
            name
            id
            filters {
              filterName
              values
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  },
});
</script>
