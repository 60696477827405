<template>
  <div v-if="!isLoading">
    <BaseForm @submit="submit">
      <BaseInput
        v-model="formData.name"
        required
        :maxLength="255"
        :label="$t('global.description')"
        :focus="!isEditing && !device.touch"
        mb
        v-test="'absence-description'"
      />
      <BaseDateTime
        v-model="formData.startAt"
        v-model:to="formData.endAt"
        v-model:allDay="formData.allDay"
        showAllDay
        :label="$t('global.date_time')"
        mb
        v-test="'absence-date'"
      />
      <AppliesTo
        v-model="appliesTo"
        v-model:id="appliesToId"
        :options="
          isChore
            ? ['company', 'employee', 'location']
            : ['company', 'employee', 'room', 'equipment', 'location']
        "
        mb
      />
      <div v-if="showRecurring">
        <div>
          <BaseCheckbox
            v-model="enableRrule"
            :label="
              $t(
                `appointment.recurring.repeat_${isChore ? 'chore' : 'absence'}`
              )
            "
            v-test="'absence-recurring'"
          />
        </div>
        <RecurringRules
          v-if="enableRrule"
          v-model="formData.rrule"
          :date="formData.startAt"
          mt
        />
      </div>
      <Footer :mt="2">
        <BaseButton submitForm :loading="isSaving" v-test="'absence-submit'">
          {{ $t('global.actions.save') }}
        </BaseButton>
      </Footer>
    </BaseForm>
  </div>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import dayjs from '@/dayjs';
import { absenceFragment } from '@/graphql-fragments';
import { defineComponent, ref } from 'vue';
import gql from 'graphql-tag';
import { useRoute } from 'vue-router';
import { useCreateEventStore } from '@/stores/calendar-create-event';
import AppliesTo from './_shared/AppliesTo.vue';
import Footer from './_shared/Footer.vue';
import RecurringRules from '@/modules/calendar/RecurringRules.vue';
import { useResourcesStore } from '@/stores/resources';
import { combineDateTime } from '@/helpers/dates';
import { createAbsence, updateAbsence } from '../actions/absences';
import { device } from '@/user-context';
import { useQuery } from '@vue/apollo-composable';

export default defineComponent({
  components: {
    AppliesTo,
    RecurringRules,
    Footer,
  },
  props: {
    isChore: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const id =
      typeof route.params.id === 'string'
        ? Number.parseInt(route.params.id)
        : null;

    const isEditing = ref(!!id);
    const isLoading = ref(false);
    const isSaving = ref(false);

    const { absenceData: formData } = useCreateEventStore();
    formData.chore = !!props.isChore;

    if (id) {
      isLoading.value = true;
      const { onResult } = useQuery(
        gql`
          query getAbsence($id: Int!) {
            absence(id: $id) {
              ...absence
            }
          }
          ${absenceFragment}
        `,
        { id },
      );

      onResult(({ data: { absence } }) => {
        Object.keys(formData).forEach((key) => {
          formData[key] = absence[key];
        });
        formData.id = absence.id;
        isLoading.value = false;
      });
    }

    const appliesTo = ref('company');
    const { resourceById } = useResourcesStore();
    if (formData.resourceId) {
      const resource = resourceById(formData.resourceId);
      if (resource) {
        appliesTo.value = resource.type;
      }
    }

    return {
      formData,
      isEditing,
      isLoading,
      isSaving,
      appliesTo,
      enableRrule: ref(false),
      isRecurringCopy: ref(false),
      createNewRrule: ref(true),
      routeDate: route.query.date,
      device,
    };
  },
  watch: {
    showRecurring() {
      // Nexttick added because endAt may update automatically if startAt changes, and we need both properties to be updated before doing this check
      this.$nextTick(() => {
        if (!this.showRecurring) {
          this.enableRrule = false;
        }
      });
    },
    isLoading(value) {
      if (value) {
        return;
      }
      // This code will run onle once, when the absence data is fetched

      if (this.formData.rrule) {
        // Set the rrule toggle to true
        this.enableRrule = true;

        // When editing an absence that has an rrule, we need to tell the store to not create a new rrule
        this.createNewRrule = false;

        if (
          this.routeDate &&
          !dayjs(this.formData.startAt).isSame(this.routeDate, 'day')
        ) {
          // When the route has a date param, and that date is not the same as the startAt of the absence, that means we're editing a recurring "copy"
          this.isRecurringCopy = true;

          // When editing a recurring copy, we need to set the startAt and endAt values to equal the date in the route
          // However, we still need to use the hour and minute values from the absence data, so we need to combine the two values

          this.formData.startAt = combineDateTime(
            this.routeDate,
            dayjs(this.formData.startAt).tz().format('HH:mm'),
          );
          this.formData.endAt = combineDateTime(
            this.routeDate,
            dayjs(this.formData.endAt).tz().format('HH:mm'),
          );

          // Add the routeDate to the formData because it needs to be sent to the mutation
          this.formData.recurrenceStartAt = this.routeDate;
        }
      }

      if (!value) {
        if (this.formData.resourceId) {
          // When editing an absence that has a resourceId, we need to check the type of the resource to set the radio input correctly
          const { resourceById } = useResourcesStore();
          const resource = resourceById(this.formData.resourceId);
          if (resource?.type) {
            this.appliesTo = resource.type;
          }
        } else if (this.formData.locationId) {
          this.appliesTo = 'location';
        }
      }
    },
  },
  computed: {
    showRecurring(): boolean {
      return (
        !this.formData.allDay &&
        dayjs(this.formData.startAt).isSame(this.formData.endAt, 'day')
      );
    },
    useResourceId(): boolean {
      return (
        this.appliesTo === 'employee' ||
        this.appliesTo === 'room' ||
        this.appliesTo === 'equipment'
      );
    },
    useLocationId(): boolean {
      return this.appliesTo === 'location';
    },
    appliesToId: {
      get(): number | null {
        return this.useResourceId
          ? this.formData.resourceId
          : this.useLocationId
            ? this.formData.locationId
            : null;
      },
      set(value) {
        this.formData.resourceId = this.useResourceId ? value : null;
        this.formData.locationId = this.useLocationId ? value : null;
      },
    },
  },
  methods: {
    submit() {
      if (!this.enableRrule) {
        this.formData.rrule = null;
      }

      this.isSaving = true;

      (this.isEditing ? this.update : this.create)()
        .then(() => {
          this.afterMutationFinish();
        })
        .catch(() => {
          this.isSaving = false;
        });
    },
    update() {
      return updateAbsence({
        input: this.formData,
        original: !this.isRecurringCopy,
        createNewRrule: this.createNewRrule,
        disableRecurringOriginalOption:
          this.isRecurringCopy &&
          !dayjs(this.formData.startAt).isSame(this.routeDate, 'day'),
      });
    },
    create() {
      return createAbsence(this.formData);
    },
    afterMutationFinish() {
      flash(
        this.$t(
          `global.flash.absence_${this.isEditing ? 'updated' : 'created'}`,
        ),
      );
      this.$router.push({ name: 'calendar' });
    },
  },
});
</script>
