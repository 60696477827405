import { type Mutation, type Query, StripeCollectionMethod } from '@/types';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ref } from 'vue';

const GET_STRIPE_PORTAL_SESSION_QUERY = gql`
  query getStripePortalSession {
    stripePortalSessions {
      url
    }
  }
`;

const PAY_DISPUTE_MUTATION = gql`
  mutation payDispute($input: PayDisputeInput!) {
    payDispute(input: $input) {
      sessionUrl
    }
  }
`;

const CREATE_STRIPE_DISPUTE_CHECKOUT_SESSION = gql`
  mutation createStripeDisputeCheckoutSession(
    $input: CreateStripeDisputeCheckoutSessionInput!
  ) {
    createStripeDisputeCheckoutSession(input: $input) {
      url
    }
  }
`;

const UPDATE_SUBSCRIPTION_COLLECTION_METHOD = gql`
  mutation updateSubscriptionCollectionMethod(
    $input: UpdateSubscriptionCollectionMethodInput!
  ) {
    updateSubscriptionCollectionMethod(input: $input) {
      errors {
        type
      }
    }
  }
`;

export const useStripe = () => {
  const loadingStripePortalUrl = ref(false);

  const goToStripePortal = () => {
    loadingStripePortalUrl.value = true;

    const { onResult } = useQuery<Query>(
      GET_STRIPE_PORTAL_SESSION_QUERY,
      null,
      {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    );

    onResult(
      ({
        data: {
          stripePortalSessions: { url },
        },
      }: {
        data: { stripePortalSessions: { url: string } };
      }) => {
        loadingStripePortalUrl.value = false;
        if (url === null) {
          return;
        }

        window.location.href = url;
      },
    );
  };

  const { mutate: payDisputeMutation, loading: payDisputeLoading } =
    useMutation<Mutation>(PAY_DISPUTE_MUTATION, () => ({
      variables: {
        input: {},
      },
    }));

  const payDispute = () =>
    payDisputeMutation().then(
      ({
        data: {
          payDispute: { sessionUrl },
        },
      }) => {
        window.location.href = sessionUrl;
      },
    );

  const { mutate, loading: disputeCheckoutSessionLoading } =
    useMutation<Mutation>(CREATE_STRIPE_DISPUTE_CHECKOUT_SESSION);

  const goToDisputeCheckoutSession = (invoiceId: string) => {
    mutate({ input: { invoiceId } }).then(
      ({
        data: {
          createStripeDisputeCheckoutSession: { url },
        },
      }) => {
        if (url) {
          window.location.href = url;
        }
      },
    );
  };

  const {
    mutate: updateSubscriptionCollectionMethod,
    loading: setAutomaticPaymentLoading,
  } = useMutation<Mutation>(UPDATE_SUBSCRIPTION_COLLECTION_METHOD);

  const setAutomaticPayment = () =>
    updateSubscriptionCollectionMethod({
      input: { collectionMethod: StripeCollectionMethod.ChargeAutomatically },
    });

  return {
    payDispute,
    goToStripePortal,
    loadingStripePortalUrl,
    payDisputeLoading,
    goToDisputeCheckoutSession,
    disputeCheckoutSessionLoading,
    setAutomaticPayment,
    setAutomaticPaymentLoading,
  };
};
