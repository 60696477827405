<template>
  <div
    :class="[
      $style.base,
      {
        [$style.hasOptions]: options && options.length,
        [$style.isSmall]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.left" @click="onClick">
      <div :class="$style.customerAvatar">
        <BaseAvatar :name="name" />
      </div>
      <div :class="$style.inner">
        <div :class="$style.content">
          <BaseText bold>
            {{ name }}
          </BaseText>
          <BaseText v-if="customer.email" oneLine>
            {{ customer.email }}
          </BaseText>
          <BaseText v-if="phoneNumber" :mt="device.mobile" oneLine>
            {{ phoneNumber }}
          </BaseText>
          <BaseText
            v-if="phoneNumber && device.mobile"
            :href="`tel:${phoneNumber}`"
          >
            {{ $t('global.actions.call_customer') }}
          </BaseText>
          <BaseText
            v-if="!phoneNumber"
            color="warning"
            v-test="'noPhoneNotification'"
          >
            {{ $t('customer_details.no_phone') }}
          </BaseText>
          <BaseText
            v-if="!customer.email"
            color="warning"
            v-test="'noEmailNotification'"
          >
            {{ $t('customer_details.no_email') }}
          </BaseText>
          <BaseText
            v-if="customer.totalDue"
            color="warning"
            iconBefore="shopping-cart"
            v-test="'amountDueNotification'"
          >
            {{
              $t('customer_details.money_outstanding', {
                amount: filters.currency(customer.totalDue)
              })
            }}
          </BaseText>
        </div>
      </div>
    </div>
    <div v-if="options && options.length" :class="$style.options">
      <BaseMore
        :options="options"
        fullHeight
        @select="$emit('select', $event)"
        v-test="'customer-more'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';
import { device } from '@/user-context';

export default defineComponent({
  props: {
    customer: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
    },
  },
  emits: ['select'],
  setup() {
    return {
      device,
      filters,
    };
  },
  computed: {
    name() {
      return (
        this.customer.fullName ||
        this.customer.name ||
        `${this.customer.firstName || this.customer.first_name} ${this.customer.lastName || this.customer.last_name}`
      );
    },
    inAppointmentSidebar() {
      return (
        this.$route.name === 'create-appointment' ||
        this.$route.name === 'edit-appointment'
      );
    },
    phoneNumber() {
      return this.customer.mobilePhone || this.customer.phone;
    },
  },
  methods: {
    onClick() {
      if (this.inAppointmentSidebar) {
        this.$emit('select', 'edit');
      } else {
        this.$router.push({
          name: 'customer-overview',
          params: { customerId: this.customer.id },
        });
      }
    },
  },
});
</script>

<style lang="scss" module>
$optionsWidth: 52px;

.base {
  display: flex;
  position: relative;
}

.left,
.options {
  cursor: pointer;

  @include hover {
    background-color: $color-highlight;
  }
}

.left {
  padding: $spacing * 0.5 $spacing $spacing * 0.5 (24px + $spacing * 1.5);
  width: calc(100% - #{$spacing});
  margin-left: $spacing * 0.5;
  border-radius: $radius;

  .base.hasOptions & {
    width: calc(100% - #{$optionsWidth + $spacing});
  }
}

.options {
  width: $optionsWidth !important;
  height: auto !important;
  border-radius: $radius;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  .base.isSmall & {
    padding-top: $spacing * 0.5;
  }

  .base.hasOptions & {
    width: 100%;
  }

  .base:not(.hasOptions) & {
    width: 100%;
  }
}

.customerAvatar {
  position: absolute;
  left: $spacing * 1.5;
  top: $spacing;

  .base.isSmall & {
    left: $spacing;
  }
}

.customerInfo {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
