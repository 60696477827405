<template>
  <div :class="$style.card">
    <BaseCard>
      <div :class="$style.flex">
        <BaseHeading size="s" :icon="iconName" :mb="0.5" v-test="'card-title'">
          {{ $t(`reports.totals.${title}`) }}
        </BaseHeading>
      </div>
      <BaseHeading v-if="value !== undefined" size="xl" v-test="'card-value'">
        {{ value }}
      </BaseHeading>
      <BaseText v-else v-test="'overview-no-results'">
        {{ $t('global.no_results') }}
      </BaseText>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" module>
.flex {
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: $spacing * 0.25;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  flex: 1;
  margin: $spacing * 0 $spacing * 0.5 $spacing;
}
</style>
