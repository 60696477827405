import router from '@/router';
import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';
import { useSessionStore } from '@/stores/session';
import { useUserStore } from '@/stores/user';
import unleash from '@/unleash';
import type {
  RouteLocation,
  RouteLocationNormalized,
  RouteRecordName,
} from 'vue-router';

const checkIfLoggedInRoute = (route: RouteLocationNormalized): boolean =>
  route.matched[0]?.name !== 'outside-app';

const isAllowedUser = (route: RouteLocationNormalized): boolean => {
  // Check if the route is allowed based on the user

  const { user } = useUserStore();
  const isLoggedInRoute = checkIfLoggedInRoute(route);

  if (!user && isLoggedInRoute) {
    // User is not logged in, but the route is only accessable when logged in
    return false;
  }

  if (user && !isLoggedInRoute && route.name !== 'logged-out') {
    // User is logged in, but the route is only accessable when logged out
    return false;
  }

  return true;
};

const isAllowedFeatureFlags = (route: RouteLocationNormalized): boolean => {
  // Check if the route is allowed based on feature flags

  const { hasFeatureFlag } = useUserStore();
  const requiredFeatureFlags = route.matched
    .map((route) => <string>route.meta.featureFlag)
    .filter((flag) => flag);
  return (
    !requiredFeatureFlags.length ||
    !requiredFeatureFlags.find((flag) => !hasFeatureFlag(flag))
  );
};

const isAllowedUnleash = (route: RouteLocationNormalized): boolean => {
  // Check if the route is allowed based on unleash flags

  const requiredUnleash = route.matched
    .map((route) => <string>route.meta.unleash)
    .filter((toggle) => toggle);
  return (
    !requiredUnleash.length ||
    !requiredUnleash.find((toggle) => !unleash.isEnabled(toggle))
  );
};

const userRoles = ['basic', 'manager', 'reports', 'admin'] as const;
type UserRole = (typeof userRoles)[number];

const isAllowedRole = (route: RouteLocationNormalized) => {
  // Check if the route is allowed based on the user role
  const routeRequiredRole = route.matched
    .map((route) => <UserRole>route.meta.role)
    .find((role) => role);

  if (routeRequiredRole && userRoles.includes(routeRequiredRole)) {
    const { user } = useUserStore();
    return !!user?.[routeRequiredRole];
  }

  return true;
};

export const isRouteAllowed = (
  route: RouteLocationNormalized | string,
): boolean => {
  if (typeof route === 'string') {
    route = router.resolve({
      name: route,
    });
  }

  return (
    isAllowedUser(route) &&
    isAllowedFeatureFlags(route) &&
    isAllowedUnleash(route) &&
    isAllowedRole(route)
  );
};

export const redirectRoute = ({
  name,
  params = {},
  query = {},
}: {
  params?: any;
  query?: any;
  name?: RouteRecordName;
} = {}) => {
  // Add the correct companyId and locationId to the route

  const { companyId: _companyId } = useCompanyStore();
  const { locationId: _locationId } = useLocationsStore();

  const companyId = _companyId?.toString() || '';
  const locationId = _locationId?.toString() || '';

  params = {
    ...params,
    companyId,
    locationId,
  };

  if (!name) {
    // Set the first visited route as the default redirect route
    // But only if this is a "logged in" route

    const { initialRoute } = useSessionStore();
    if (initialRoute?.name) {
      const isLoggedInRoute = checkIfLoggedInRoute(
        router.resolve(initialRoute),
      );

      name = isLoggedInRoute ? initialRoute.name : 'dashboard';
      params = {
        ...params,
        ...initialRoute.params,
        companyId,
        locationId,
      };

      // Reset the route in the store to ensure the redirect only happens once
      const sessionStore = useSessionStore();
      sessionStore.initialRoute = null;
    } else {
      name = 'dashboard';
    }
  }

  return {
    name,
    params,
    query,
  };
};

export const redirectRouteWithoutParams = (
  paths: string[],
  params: { companyId: number; locationId: number },
): RouteLocation | undefined => {
  for (let i = 1; i <= paths.length; i++) {
    const routeObj = router.resolve({
      path: `/c/${params.companyId}/l/${params.locationId}/${paths.slice(-i).join('/')}`,
    });

    if (routeObj?.name && routeObj?.name !== '404') {
      return routeObj;
    }
  }
};
