<template>
  <div ref="el" :class="$style.base" v-test="'new-feature'">
    <div :class="$style.heading">
      <BaseLabel
        v-if="data.category"
        :color="data.category === 'new' ? 'success' : 'default'"
      >
        {{ $t(`change_logs.${data.category}`) }}
      </BaseLabel>
      <BaseHeading v-if="data.date" size="s">
        {{ filters.date(data.date) }}
      </BaseHeading>
    </div>
    <BaseHeading v-if="data.title" size="xl" :mb="2" inline>
      {{ data.title }}
    </BaseHeading>
    <div :class="$style.description" v-html="data.description" />
    <div :class="$style.actions">
      <BaseButton
        v-if="data.helpPageUrl"
        :href="data.helpPageUrl"
        color="inverted"
        @click="
          mixpanel.track('NewFeatures-ReadMore', {
            item: data.id,
            title: data.title
          })
        "
      >
        {{ $t('new_features.button') }}
      </BaseButton>
      <BaseButton
        v-if="route && isRouteAllowed(route)"
        :routerLink="route"
        @click="
          mixpanel.track('NewFeatures-TryNow', {
            item: data.id,
            title: data.title
          })
        "
      >
        {{ $t('new_features.try_out_now') }}
      </BaseButton>
    </div>
    <BaseImage
      v-if="data.imagePublicId"
      :cloudinaryId="data.imagePublicId"
      mt
    />
  </div>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { useIntersectionObserver } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { isRouteAllowed } from '@/router/helpers';

const mixpanel = inject<any>('mixpanel');
const props = defineProps<{
  data: any;
}>();

const el = ref(null);
const isVisible = ref(false);

useIntersectionObserver(el, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting;
});

const urlHash = computed(() =>
  props.data.title
    ? `#${props.data.title
        .toLowerCase() // Convert to lowercase
        .replace(/[^\p{L}0-9]+/gu, '-') // Replace non-alphanumeric characters (including emojis) with dashes
        .replace(/^-+|-+$/g, '') // Remove leading and trailing dashes
        .replace(/-{2,}/g, '-')}` // Replace multiple dashes with a single dash
    : '',
);

const router = useRouter();
const enableUpdate = ref(false);
setTimeout(() => {
  enableUpdate.value = true;
}, 500);

const route = props.data.tryOutNowUrl
  ? router.resolve({ path: props.data.tryOutNowUrl })
  : null; // example path = '/register/salonized-pay'

onMounted(() => {
  setTimeout(() => {
    if (router.currentRoute.value.hash === urlHash.value) {
      el.value.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, 300);

  watch(isVisible, (value) => {
    if (value && enableUpdate.value) {
      router.replace({
        path: router.currentRoute.value.path,
        hash: urlHash.value,
      });
    }
  });
});
</script>

<style lang="scss" module>
.description {
  a {
    color: $color-primary;

    @include hover {
      color: $color-primary;
    }
  }
}

.heading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5 * $spacing;
  margin-bottom: $spacing;
  max-width: 100%;
}

.base {
  padding-top: $spacing * 2;

  &:not(:last-child) {
    padding-bottom: $spacing * 2;
    border-bottom: 1px solid $color-border;
  }
}

.actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $spacing;
  margin-top: $spacing;
}
</style>
