import { modal } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { ReturnData, TrialTask } from '../types';

export default (): ReturnData => {
  const { t } = useI18n();
  const mixpanel = inject<any>('mixpanel');
  const { isInsideTestBucket, company, isTreatwellUser } = useCompanyStore();

  const claimable =
    !isTreatwellUser &&
    (company.country === 'be' || isInsideTestBucket('trial_rewards_enabled'));

  const data = computed(() => {
    const { trialDaysRemaining } = useCompanyStore();

    return {
      heading: isTreatwellUser
        ? t('onboarding.intro.title_treatwell')
        : t('onboarding.intro.title'),
      icon: {
        dark: '/img/onboarding/icon-dark.svg',
        light: '/img/onboarding/icon-light.svg',
      },
      link: {
        routeName: 'subscription',
        text: t('onboarding.new_onboarding.tasks.upgrade'),
      },
      remainingDays: trialDaysRemaining,
    };
  });

  const tasks = ref<TrialTask[]>(
    [
      'create_appointment',
      'try_booking_widget',
      'setup_roster',
      'import_customers',
      'setup_company_details',
    ].map((name) => ({
      claimed: false,
      claimable,
      completed: false,
      label: claimable ? `+1 ${t('onboarding.new_onboarding.days_free')}` : '',
      labelColor: 'primary',
      name,
      text: t(`onboarding.new_onboarding.tasks.${name}`),
      visual: `/img/tasks/${name}.svg`,
    })),
  );

  const { onResult, loading } = useQuery(gql`
    query getOnboardingTasks {
      onboardingTasks {
        all {
          claimedAt
          completedAt
          id
          task
        }
      }
    }
  `);

  onResult(
    ({
      data: {
        onboardingTasks: { all },
      },
    }) => {
      all.map((taskData: any) => {
        const task = tasks.value.find((task) => task.name === taskData.task);
        if (task) {
          task.claimed = !!taskData.claimedAt;
          task.completed = !!taskData.completedAt;
          task.label = claimable
            ? taskData.claimedAt
              ? t('onboarding.new_onboarding.days_claimed')
              : `+1 ${t('onboarding.new_onboarding.days_free')}`
            : '';
          task.labelColor = taskData.completedAt ? 'success' : 'primary';
        }
      });
    },
  );

  const claimTask = (taskName: string) => {
    const task = tasks.value.find((task) => task.name === taskName);
    if (!task) {
      return;
    }

    mixpanel.track('Onboarding tasks - Reward claimed', { task });

    const { mutate: updateTask } = useMutation(gql`
      mutation updateOnboardingTask($input: UpdateOnboardingTaskInput!) {
        updateOnboardingTask(input: $input) {
          onboardingTask {
            id
            task
            completedAt
            claimedAt
          }
        }
      }
    `);

    updateTask({
      input: {
        task: task.name.toUpperCase(),
        taskStatus: 'CLAIM',
      },
    }).then(() => {
      const { onResult } = useQuery(gql`
        query getCurrentUser {
          currentUser {
            company {
              trialEndAt
            }
          }
        }
      `);

      onResult(
        ({
          data: {
            currentUser: {
              company: { trialEndAt },
            },
          },
        }) => {
          task.claimed = true;

          if (typeof task.label === 'object') {
            task.label = t('onboarding.new_onboarding.days_claimed');
          }

          const { company } = useCompanyStore();
          company.trialEndAt = trialEndAt;
        },
      );
    });
  };

  const onTaskClick = (taskName: string) => {
    switch (taskName) {
      case 'try_booking_widget':
        mixpanel.track('Onboarding tasks - Clicked', { task: 'Widget' });
        modal('try-booking-widget');
        break;
      case 'create_appointment':
        mixpanel.track('Onboarding tasks - Clicked', { task: 'Appointment' });
        modal('new-onboarding');
        break;
      case 'setup_roster':
        mixpanel.track('Onboarding tasks - Clicked', { task: 'Roster' });
        modal('create-roster');
        break;
      case 'import_customers':
        mixpanel.track('Onboarding tasks - Clicked', {
          task: 'Import customers',
        });
        modal('import-customers');
        break;
      case 'setup_company_details':
        mixpanel.track('Onboarding tasks - Clicked', {
          task: 'Setup company info',
        });
        modal('setup-company-info');
        break;
    }
  };

  return {
    data,
    tasks,
    loading,
    claimTask,
    onTaskClick,
  };
};
