<template>
  <FooterLayout>
    <template #right>
      <BaseButton
        v-if="canModify"
        :disabled="!order.items.length"
        color="inverted"
        @click="handleModifyClick"
        v-test="'order-modify'"
      >
        {{ $t('global.actions.modify_invoice') }}
      </BaseButton>
      <BaseButton
        v-if="outstandingAmount > 0"
        submitForm
        :disabled="!order.items.length"
        :loading="savingOrder"
        color="warning"
        v-test="'order-save-unpaid'"
      >
        {{ t('register.save_as_unpaid') }}
      </BaseButton>
      <BaseButton
        v-else
        :disabled="disableCheckout"
        :loading="savingOrder"
        submitForm
        v-test="'order-complete-invoice'"
      >
        {{ $t('global.actions.complete_invoice') }}
      </BaseButton>
    </template>
  </FooterLayout>
</template>

<script setup lang="ts">
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { useRegisterStore } from '@/modules/register/stores/register';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { modal } from '@/helpers/ui';
import { computed } from 'vue';
import FooterLayout from '../../FooterLayout.vue';

const { order } = useRegisterOrderStore();
const { totalPrice, outstandingAmount } = storeToRefs(useRegisterOrderStore());
const { isOpen: isDrawerOpen } = useRegisterDrawerStore();
const router = useRouter();
const { t } = useI18n();
const { cashupEnabled } = storeToRefs(useRegisterStore());
const mixpanel = inject<any>('mixpanel');

defineProps<{
  savingOrder: boolean;
}>();

const canModify = computed(
  () =>
    !cashupEnabled.value ||
    (cashupEnabled.value && isDrawerOpen && order.permissions?.editOrderItems),
);

const disableCheckout = computed(() => {
  const change = outstandingAmount.value < 0 ? outstandingAmount.value * -1 : 0;

  return totalPrice.value < 0 && change > 0;
});

const handleModifyClick = () => {
  const editingExisting =
    order.state === 'PAID' || order.state === 'OUTSTANDING';
  mixpanel.track('Register - Modify order clicked');

  if (editingExisting) {
    modal('confirmation', {
      message: t('register.edit_warning'),
    }).then(() => {
      router.push({ name: 'register', query: { action: 'modify' } });
    });
  } else {
    router.push({ name: 'register', query: { action: 'modify' } });
  }
};
</script>
