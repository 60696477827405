<template>
  <BaseModal
    :heading="$t('global.message')"
    small
    useForm
    @submit="submit"
    @close="$emit('close')"
  >
    <BaseAlert
      v-if="selectedValue === 'email' && !customerId"
      color="warning"
      :text="$t('customers.bulk_warning')"
      mb
      v-test="'emailBaseAlert'"
    />
    <BaseRadio
      v-model="selectedValue"
      inline
      label="type"
      :options="[
        {
          label: $t('global.email'),
          value: 'email'
        },
        {
          label: $t('global.sms_message'),
          value: 'sms'
        }
      ]"
      mb
      v-test="'customer-message-type'"
    />
    <BaseText v-if="noMobile" size="s" color="error" mb>
      {{ $t('customers.no_mobile_phone', { name: customerName }) }}
    </BaseText>
    <BaseText v-if="noEmail" size="s" color="error" mb>
      {{ $t('customers.no_email', { name: customerName }) }}
    </BaseText>
    <div v-if="selectedValue === 'email'">
      <BaseInput
        v-model="description"
        :label="$t('global.description')"
        :required="selectedValue === 'email'"
        mb
        v-test="'customersSendEmailSubject'"
      />
      <BaseEditor
        v-model="emailMessage"
        :label="$t('global.message')"
        :required="selectedValue === 'email'"
        :cloudinaryName="UploadedImageUsage.NewsletterContent"
        mb
        v-test="'customersSendEmailMessage'"
      />
    </div>
    <div v-else>
      <BaseInput
        v-model="smsMessage"
        :label="$t('global.message')"
        type="textarea"
        :rows="6"
        :maxLength="hasUnicode ? 320 : 640"
        :required="selectedValue === 'sms'"
        mb
        v-test="'customersSendSmsMessage'"
      />
      <BaseGrid container>
        <BaseGrid>
          <BaseText inline> {{ characters }} / </BaseText>
          <BaseText inline bold>
            {{ nextMessageCharacters }} {{ maxCharacters }}
          </BaseText>
        </BaseGrid>
        <BaseGrid alignRight v-test="'customerSendSmsCredits'">
          <BaseLabel color="success">
            {{ credits }} {{ $t('global.credits') }}
          </BaseLabel>
        </BaseGrid>
      </BaseGrid>
      <BaseText
        v-if="hasUnicode"
        color="secondary"
        size="s"
        v-test="'sendMessageEmoji'"
      >
        * {{ $t('message_modal.emojis') }}
      </BaseText>
    </div>
    <template #footer>
      <BaseButton color="inverted" @click="cancel">{{
        $t('global.actions.cancel')
      }}</BaseButton>
      <BaseButton
        :disabled="noMobile || noEmail"
        :loading="isLoading"
        submitForm
        v-test="'customersSendMessageSave'"
      >
        {{ $t('global.actions.send') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash, modal } from '@/helpers/ui';
import { UploadedImageUsage } from '@/types';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    customerData: {
      type: Object,
      required: false,
    },
    searchQuery: {
      type: String,
      required: false,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    const selectedCustomers = inject<number[]>('selectedCustomers');
    const totalCount = inject<number>('totalCount');

    return { selectedCustomers, totalCount, UploadedImageUsage };
  },
  data() {
    return {
      selectedValue: 'email',
      description: '',
      emailMessage: '',
      smsMessage: '',
      messageCharacters: 160,
      customerId: null,
      query: '',
      mobilePhone: null,
      customerName: null,
      email: null,
      isLoading: false,
    };
  },
  computed: {
    noEmail() {
      return this.customerId && this.selectedValue === 'email' && !this.email;
    },
    noMobile() {
      return (
        this.customerId && this.selectedValue === 'sms' && !this.mobilePhone
      );
    },
    characters() {
      return this.smsMessage.length || 0;
    },
    credits() {
      return Math.max(1, Math.ceil(this.characters / this.messageCharacters));
    },
    nextMessageCharacters() {
      return this.messageCharacters * this.credits;
    },
    hasUnicode() {
      for (let i = 0; i < this.smsMessage.length; i++) {
        if (this.smsMessage.charCodeAt(i) > 255) {
          return true;
        }
      }
      return false;
    },
    maxCharacters() {
      if (this.nextMessageCharacters > 160 && this.hasUnicode) {
        return '(max)';
      }
      return (this.nextMessageCharacters > 480 && '(max)') || '';
    },
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    submit() {
      const message =
        this.selectedValue === 'email' ? this.emailMessage : this.smsMessage;

      const { mutate } = useMutation(gql`
        mutation createCustomMessage($input: CreateCustomMessageInput!) {
          createCustomMessage(input: $input) {
            customMessage
          }
        }
      `);

      const send = () => {
        this.isLoading = true;
        this.mixpanel.track('message_sent', {
          message_type: this.selectedValue,
        });

        mutate({
          input: {
            customerId: this.customerId,
            customerIds: this.selectedCustomers,
            filters: this.filters,
            gateway: this.selectedValue.toUpperCase(),
            message,
            query: this.query,
            subject: this.description || null,
          },
        })
          .then(() => {
            flash(this.$t('global.flash.message_sent'));
            this.$emit('close');
          })
          .finally(() => {
            this.isLoading = false;
          });
      };

      if (this.customerId) {
        send();
      } else {
        const totalCustomers =
          this.selectedCustomers?.length || this.totalCount;

        modal('confirmation', {
          message: this.$t(
            `global.confirmation.confirm_${this.selectedValue}_amount`,
            {
              item: `${totalCustomers} ${this.$t('global.items.customer', totalCustomers)}`,
            },
          ),
        }).then(() => {
          send();
        });
      }
    },
  },
  created() {
    this.mixpanel.track('batch_action_chosen', { action_name: 'message' });
    if (this.customerData?.customerId) {
      this.customerId = this.customerData.customerId;
    }
    if (this.customerData?.customerMobile) {
      this.mobilePhone = this.customerData.customerMobile;
    }
    if (this.customerData?.customerEmail) {
      this.email = this.customerData.customerEmail;
    }
    if (this.customerData?.customerName) {
      this.customerName = this.customerData.customerName;
    }
    if (this.searchQuery) {
      this.query = this.searchQuery;
    }
  },
});
</script>
