<template>
  <div :class="$style.base" :style="opacityStyle">
    <div
      v-show="hasFooter"
      :class="$style.upper"
      :style="borderOpacityStyle"
      v-test="'previewFooter'"
    >
      <div>
        <BaseText bold :mb="0.25">
          {{ company.name }}
        </BaseText>
        <BaseText size="s"> {{ company.address }} {{ company.city }} </BaseText>
      </div>
      <div :class="$style.social">
        <a
          v-if="company.facebookId"
          :href="`https://www.facebook.com/${company.facebookId}`"
          target="_blank"
          :class="$style.socialButton"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
              <circle cx="256" cy="256" fill="#3B5998" r="256" />
              <path
                d="M301.6 151.2h36.3V97h-51.4c-29.6 0-68.1 19-68.1 74.2v43h-49.1v56.1h49.1v146H277v-146h48.8l8.1-56.1H277v-35.7c0-18.4 9-27.3 24.6-27.3z"
                fill="#FFF"
              />
            </g>
          </svg>
        </a>
        <a
          v-if="company.twitterId"
          :href="`https://www.twitter.com/${company.twitterId}`"
          target="_blank"
          :class="$style.socialButton"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
              <path
                d="M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256z"
                fill="#1DA1F2"
              />
              <path
                d="M358.5 202.9c3.3 73.9-51.8 156.2-149.3 156.2-29.7 0-57.3-8.7-80.5-23.6 27.9 3.3 55.7-4.4 77.8-21.7-23-.4-42.4-15.6-49.1-36.5 8.2 1.6 16.3 1.1 23.7-.9-25.3-5.1-42.7-27.8-42.1-52.2 7.1 3.9 15.2 6.3 23.8 6.6-23.4-15.6-30-46.5-16.3-70.1 25.9 31.8 64.6 52.7 108.3 54.9-7.6-32.9 17.3-64.5 51.2-64.5 15.1 0 28.7 6.4 38.3 16.6 12-2.4 23.2-6.7 33.4-12.7-3.9 12.3-12.3 22.6-23.1 29.1 10.6-1.3 20.7-4.1 30.2-8.3-7.2 10.5-16.1 19.7-26.3 27.1z"
                fill="#FFF"
              />
            </g>
          </svg>
        </a>
        <a
          v-if="company.instagramId"
          :href="`https://www.instagram.com/${company.instagramId}`"
          target="_blank"
          :class="$style.socialButton"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <radialGradient
              id="a"
              cx="51.992"
              cy="13.509"
              gradientTransform="matrix(.0566 -1.08 .918 .04811 -5.988 520.557)"
              gradientUnits="userSpaceOnUse"
              r="684.445"
            >
              <stop offset="0" stop-color="#fed576" />
              <stop offset=".263" stop-color="#f47133" />
              <stop offset=".609" stop-color="#bc3081" />
              <stop offset="1" stop-color="#4c63d2" />
            </radialGradient>
            <path
              d="M0 249.5v13.8C3.6 395.7 110.2 502 237.1 512h39C403.7 501.9 508.5 394.7 512 263.1v-13.6C508.5 115.6 400.1 7 269.4.3 128.8-6.8 3.9 106 0 249.5z"
              fill="url(#a)"
            />
            <g>
              <g fill="#FFF">
                <path
                  d="M257.2 128.6c40.8 0 45.6.1 61.8.9 14.9.6 22.9 3.2 28.3 5.3 7.1 2.8 12.2 6.2 17.5 11.6 5.3 5.4 8.7 10.6 11.5 17.8 2.1 5.5 4.5 13.7 5.3 28.9.8 16.4.9 21.3.9 62.8s-.1 46.4-.9 62.8c-.6 15.1-3.1 23.4-5.3 28.9-2.8 7.2-6.1 12.4-11.5 17.8-5.3 5.4-10.4 8.8-17.5 11.6-5.4 2.2-13.5 4.7-28.3 5.3-16.1.8-21 .9-61.8.9s-45.6-.1-61.8-.9c-14.9-.6-22.9-3.2-28.3-5.3-7.1-2.8-12.2-6.2-17.5-11.6s-8.7-10.6-11.5-17.8c-2.1-5.5-4.5-13.7-5.3-28.9-.8-16.4-.9-21.3-.9-62.8s.1-46.4.9-62.8c.6-15.1 3.1-23.4 5.3-28.9 2.8-7.2 6.1-12.4 11.5-17.8 5.3-5.4 10.4-8.8 17.5-11.6 5.4-2.2 13.5-4.7 28.3-5.3 16.1-.7 21-.9 61.8-.9m0-28c-41.5 0-46.7.2-62.9 1s-27.4 3.4-37.1 7.2c-10.1 4-18.6 9.3-27 18-8.5 8.7-13.7 17.3-17.6 27.6-3.8 9.8-6.4 21.2-7.1 37.7-.8 16.5-.9 21.8-.9 64s.2 47.5.9 64c.8 16.5 3.4 27.8 7.1 37.7 3.9 10.2 9.1 18.9 17.6 27.6s17 14 27 18c9.7 3.9 20.9 6.5 37.1 7.2 16.3.8 21.5 1 62.9 1s46.7-.2 62.9-1c16.2-.8 27.4-3.4 37.1-7.2 10.1-4 18.6-9.3 27-18 8.5-8.7 13.7-17.3 17.6-27.6 3.8-9.8 6.4-21.2 7.1-37.7.8-16.5.9-21.8.9-64s-.2-47.5-.9-64c-.8-16.5-3.4-27.8-7.1-37.7-3.9-10.2-9.1-18.9-17.6-27.6s-17-14-27-18c-9.7-3.9-20.9-6.5-37.1-7.2-16.2-.8-21.5-1-62.9-1z"
                />
                <path
                  d="M257.2 176.2c-43.4 0-78.4 35.7-78.4 79.8s35.1 79.8 78.4 79.8 78.4-35.7 78.4-79.8-35.1-79.8-78.4-79.8zm0 131.6c-28.1 0-50.9-23.1-50.9-51.8s22.8-51.8 50.9-51.8 50.9 23.1 50.9 51.8c0 28.7-22.8 51.8-50.9 51.8z"
                />
                <ellipse cx="338.7" cy="173" rx="18.3" ry="18.6" />
              </g>
            </g>
          </svg>
        </a>
      </div>
    </div>
    <div :class="$style.bottom">
      <BaseText color="secondary" size="s">
        <a
          :class="$style.link"
          href="https://www.salonized.com"
          target="_blank"
        >
          {{ $t('marketing.email.preview.powered_by_salonized') }}
        </a>
      </BaseText>
      <BaseText color="secondary" size="s">
        {{ $t('global.unsubscribe') }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      const color = this.$store.getters['marketing/color'];
      return color ? color : '#FD832D';
    },
    opacityStyle() {
      return `background-color: ${this.color}10;`;
    },
    borderOpacityStyle() {
      return `border-color: ${this.color}30;`;
    },
    hasFooter() {
      return this.$store.getters['marketing/hasFooter'];
    },
  },
});
</script>

<style lang="scss" module>
.base {
  padding: $spacing * 2 $spacing * 3;
  width: 100%;
  background: $color-warning-light;
}

.upper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ffe8d7;
  padding-bottom: $spacing;
  margin-bottom: $spacing;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

.social {
  display: flex;
  justify-content: space-evenly;
  width: 80px;
}

.socialButton {
  width: 20px;
  height: 20px;
}

.link {
  color: $color-text !important;
  opacity: 0.5;
}
</style>
