<template>
  <InputElement
    v-model="value"
    :inputModeText="inputModeText"
    :disabled="disabled"
    :size="size"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    @change="onChange"
  />
</template>

<script lang="ts">
import InputElement from './Input.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CurrencyInput',
  components: {
    InputElement,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    inputModeText: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    minValue: Number,
    maxValue: Number,
    size: String,
  },
  emits: ['update:modelValue', 'blur', 'focus'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (value === null) {
          // This happens when the user clears the input field
          return;
        }

        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    onChange(value) {
      if (value === null) {
        // This happens when the user clears the input field, and then loses focus on the element
        this.value = 0;
      }

      this.applyMinMax();
    },
    applyMinMax() {
      let newValue = this.value;

      // Set value to minValue when the value is lower than the minValue
      if (typeof this.minValue === 'number') {
        newValue = Math.max(this.minValue, newValue);
      }

      // Set value to maxValue when the value is higher than the maxValue
      if (typeof this.maxValue === 'number') {
        newValue = Math.min(this.maxValue, newValue);
      }

      if (newValue !== this.value) {
        this.value = newValue;
      }
    },
  },
});
</script>
