<template>
  <BaseCard v-if="!hide">
    <BaseHeading mb :icon="icon" border>
      {{ heading }}
    </BaseHeading>
    <BaseText v-if="html">
      <span v-html="content" />
    </BaseText>
    <BaseText v-else>
      {{ content }}
    </BaseText>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    heading: {
      type: String,
    },
    content: {
      type: [String, Number],
    },
    icon: {
      type: String,
    },
    html: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: String,
    },
  },
});
</script>
