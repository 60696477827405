<template>
  <BaseCard>
    <div :class="$style.header">
      <div :class="$style.headerInner">
        <Rating
          :score="selected.score"
          :mr="0.5"
          :tooltip="
            selected.source === FeedbackSource.Treatwell
              ? {
                  position: 'top',
                  text: $t('feedback.x_stars', { stars: selected.score })
                }
              : null
          "
        />
        <div>
          <BaseHeading v-test="'feedback-customer'">{{
            selected.customer ? selected.customer.fullName : '-'
          }}</BaseHeading>
          <BaseText size="s" v-test="'feedback-date'">{{
            filters.date(selected.submittedAt)
          }}</BaseText>
        </div>
      </div>
      <div :class="$style.icons">
        <BaseIcon
          v-if="selected.messagesPublished"
          :tooltip="{
            position: 'left',
            text: $t('feedback.published_replies')
          }"
          name="arrow-corner-up-left"
          v-test="'feedback-item-icon-replies'"
        />
        <BaseIcon
          v-if="selected.state"
          :tooltip="{
            position: 'left',
            text: iconInfo.text
          }"
          :name="iconInfo.name"
        />
        <BaseIcon
          v-if="
            selected.twPublishedAt &&
            selected.source !== FeedbackSource.Treatwell
          "
          :tooltip="{
            position: 'left',
            text: $t('feedback.published_treatwell')
          }"
          name="treatwell"
          v-test="'feedback-item-icon-treatwell'"
        />
      </div>
    </div>
    <div :class="$style.message" v-test="'feedback-comment'">
      <BaseText>
        {{ selected.comment }}
      </BaseText>
    </div>
    <div v-if="!showReplyInput" :class="$style.bottom">
      <BaseButton
        v-if="selected.customer"
        color="inverted"
        :routerLink="{
          name: 'customer',
          params: { customerId: selected.customer.id }
        }"
        icon="person"
        v-test="'feedback-btn-customer'"
      />
      <div :class="$style.buttons">
        <PublishOptions />
        <BaseButton
          v-if="!selected.messages.length"
          @click="showReplyInput = true"
          v-test="'feedback-btn-reply'"
        >
          {{ $t('global.actions.reply') }}
        </BaseButton>
      </div>
    </div>
    <Replies v-if="selected.messages.length" mt />
    <AddReply v-if="showReplyInput" mt @close="showReplyInput = false" />
  </BaseCard>
</template>

<script lang="ts">
export default {
  name: 'FeedbackContent',
};
</script>

<script setup lang="ts">
import filters from '@/filters';
import Rating from '@/components/Rating.vue';
import { inject, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AddReply from './AddReply.vue';
import Replies from './replies/index.vue';
import PublishOptions from './PublishOptions.vue';
import type { IconName } from '@/components/base-icon/types';
import { FeedbackSource } from '@/types';

const { t } = useI18n();

const selected = inject<any>('selected');
const iconInfo = computed(() => {
  const info: { name: IconName; text: string } = {
    name: 'info',
    text: ''
  };
  if (selected.value.source === FeedbackSource.Treatwell) {
    (info.name = 'treatwell'), (info.text = t('feedback.received_treatwell'));
    return info;
  }

  switch (selected.value.state) {
    case 'published':
      info.name = 'globe';
      info.text = t('feedback.published');
      break;
    case 'allowed':
      info.name = 'unlock';
      info.text = t('feedback.allowed');
      break;
    case 'disallowed':
      info.name = 'slash';
      info.text = t('feedback.disallowed');
      break;
    case 'personal':
      info.name = 'lock';
      info.text = t('feedback.personal');
      break;
    case 'requested':
      info.name = 'clock';
      info.text = t('feedback.requested');
      break;
  }

  return info;
});

const showReplyInput = ref(false);
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing;
}

.headerInner {
  display: flex;
  align-items: center;
}

.icons {
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: $spacing * 0.25;
  }
}

.message {
  white-space: pre-wrap;
  margin-bottom: $spacing;
}

.bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $spacing;
}

.buttons {
  display: flex;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}
</style>
