<template>
  <BaseModal
    :parentRoute="parentRoute"
    :heading="$t('messages_sms.templates.appointment_reminder_sms.heading')"
    useForm
    :loading="loading"
    small
    @submit="submitForm"
  >
    <BaseText mb>
      {{ $t('messages_sms.templates.appointment_reminder_sms.description') }}
    </BaseText>
    <SmsReminderSettings
      v-if="templateId === 'appointment_reminder_sms'"
      :formData="companySettingsData"
    />
    <BaseInput
      v-model="content"
      type="textarea"
      :label="$t('email_templates.message')"
      :mb="1.5"
      required
      :maxLength="140"
      characterCount
      :disabled="disableFields"
      v-test="'sms-template-message'"
    />
    <BaseText
      link
      mt
      :iconAfter="showVariables ? 'chevron-up' : 'chevron-down'"
      @click="showVariables = !showVariables"
    >
      {{
        showVariables
          ? $t('email_templates.footer.hide_variables')
          : $t('email_templates.footer.show_variables')
      }}
    </BaseText>
    <Variables v-if="showVariables" :variables="variables" mt />
    <template #footer>
      <BaseButton color="inverted" @click="close">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        submitForm
        :loading="saving || savingCompany"
        v-test="'sms-template-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import Variables from '@/modules/_shared/Variables.vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import SmsReminderSettings from './settings/SmsReminder.vue';
import { useSettingsData } from '../settings';

const { t } = useI18n();

const content = ref('');
const variables = ref<string[]>([]);
const showVariables = ref(false);

const router = useRouter();
const parentRoute = ref('messages-sms');
const route = useRoute();
const templateId = computed(() => route.params.id);

const { onResult, loading } = useQuery(
  gql`
    query getCompanySmsTemplate($id: ID!) {
      companySmsTemplate(id: $id) {
        content
        id
        variables
      }
    }
  `,
  {
    id: templateId.value,
  },
);

onResult(({ data: { companySmsTemplate } }) => {
  content.value = companySmsTemplate.content;
  variables.value = companySmsTemplate.variables;
});

const close = () => {
  router.push({
    name: parentRoute.value,
  });
};

const { companySettingsData, updateCompany, savingCompany, dataChanged } =
  useSettingsData();

const disableFields = computed(
  () =>
    templateId.value === 'appointment_reminder_sms' &&
    !companySettingsData.settings.reminders.smsReminderEnabled,
);

const { mutate, loading: saving } = useMutation(gql`
  mutation updateCompanySmsTemplate($input: UpdateCompanySmsTemplateInput!) {
    updateCompanySmsTemplate(input: $input) {
      companySmsTemplate {
        content
        id
      }
    }
  }
`);

const submitForm = () => {
  mutate({
    input: {
      id: templateId.value,
      content: content.value,
    },
  }).then(() => {
    if (dataChanged.value) {
      updateCompany().then(() => {
        afterMutation();
      });
    } else {
      afterMutation();
    }
  });
};

const afterMutation = () => {
  close();
  flash(t('global.flash.template_saved'));
};
</script>
