<template>
  <BaseModal
    :heading="$t('global.actions.edit_note')"
    parentRoute="calendar"
    small
  >
    <NoteForm />
  </BaseModal>
</template>

<script lang="ts">
import NoteForm from '../forms/NoteForm.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    NoteForm,
  },
});
</script>
