import gql from 'graphql-tag';
import {
  orderCustomerFragment,
  orderItemFragment,
  transactionFragment,
} from '../../actions/graphql';

export const GET_GIFTCARDS = gql`
  query getGiftcards($search: String, $filterScope: GiftcardFilterScope) {
    giftcards(search: $search, filterScope: $filterScope) {
      id
      code
      status
      remainingAmount
    }
  }
`;

export const GET_PREPAID_CARDS = gql`
  query getPrepaidCards($customerId: ID, $filterScope: PrepaidCardFilterScope) {
    prepaidCards(customerId: $customerId, filterScope: $filterScope) {
      id
      description
      quantity
      used
      remaining
      price
      status
      pricePerUse
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods($register: Boolean) {
    paymentMethods(register: $register)
  }
`;

export const LISTEN_TO_POS_TERMINAL_PAYMENT = gql`
  subscription listenToPosTerminalPayment {
    posTerminalPayment {
      status
      order {
        id
        invoicedAt
        pending
        fiscalizationFinished
        transactions {
          ...transaction
        }
        items {
          ...orderItem
        }
        customer {
          ...orderCustomer
        }
      }
      refusalReason
    }
  }
  ${transactionFragment}
  ${orderItemFragment}
  ${orderCustomerFragment}
`;

export const GENERATE_POS_PAYMENT = gql`
  mutation generatePosPayment($input: GeneratePosPaymentInput!) {
    generatePosPayment(input: $input) {
      error {
        message
      }
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      invoicedAt
      transactions {
        ...transaction
      }
      items {
        ...orderItem
      }
      customer {
        ...orderCustomer
      }
    }
  }
  ${transactionFragment}
  ${orderItemFragment}
  ${orderCustomerFragment}
`;
