<template>
  <Modal v-bind="{ parentRouteName, saving }" @submit="submit">
    {{ $t('integrations.mailchimp.settings.description') }}
    <BaseDropdown
      v-model="listId"
      :options="
        lists.map((list) => ({
          value: list.id,
          label: `${list.name} (${list.memberCount})`
        }))
      "
      mt
      required
      v-test="'mailchimp-lists'"
    />
  </Modal>
</template>

<script setup lang="ts">
import Modal from './shared/Modal.vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  parentRouteName: string;
}>();

const listId = ref<string>();

const { result, onResult } = useQuery(gql`
  query getMailchimp {
    mailchimp {
      app {
        id
      }
      listId
      lists {
        id
        memberCount
        name
      }
    }
  }
`);

onResult(({ data: { mailchimp } }) => {
  listId.value = mailchimp.listId;
});

const appId = computed(() => result.value?.mailchimp?.app.id);
const lists = computed(() => result.value?.mailchimp?.lists || []);

const router = useRouter();
const { t } = useI18n();

const { mutate, loading: saving } = useMutation(gql`
  mutation updateMailchimpApp($input: UpdateMailchimpAppInput!) {
    updateMailchimpApp(input: $input) {
      mailchimpApp {
        id
      }
    }
  }
`);

const submit = () => {
  mutate({
    input: {
      id: appId.value,
      listId: listId.value,
    },
  }).then(() => {
    flash(t('global.flash.integration_updated'));

    router.push({
      name: props.parentRouteName,
    });
  });
};
</script>
