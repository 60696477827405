<template>
  <div>
    <BaseSpinner v-if="$apollo.loading" />
    <div v-else-if="cashup" :class="$style.base">
      <CountCashModal
        v-if="showCountCashModal"
        :cashCount="formData.cashCount"
        @submit="submitCashCount"
        @close="showCountCashModal = false"
      />
      <div :class="$style.inner">
        <BaseCard>
          <BaseGrid container :class="$style.border">
            <BaseGrid :size="2">
              <BaseHeading size="s" :mb="0.5">
                {{ $t('global.register') }}
              </BaseHeading>
              <BaseText bold>
                {{ cashup.location.internalName }}
              </BaseText>
            </BaseGrid>
            <BaseGrid>
              <BaseHeading size="s" :mb="0.5">
                {{ $t('drawer.opened_on') }}
              </BaseHeading>
              <BaseText bold>
                {{ filters.dateTime(cashup.openedOn) }} {{ $t('global.by') }}
                {{ cashup.openedBy.firstName }}
              </BaseText>
            </BaseGrid>
          </BaseGrid>
          <div :class="$style.table">
            <div :class="$style.tableInner">
              <BaseTableRow head mt>
                <BaseTableCell :width="20">
                  {{ filters.capitalize($t('global.items.payment_type', 1)) }}
                </BaseTableCell>
                <BaseTableCell currency :width="20">
                  {{ $t('global.expected') }}
                </BaseTableCell>
                <BaseTableCell :width="45">
                  {{ $t('global.counted') }}
                </BaseTableCell>
                <BaseTableCell currency :width="15">
                  {{ $t('global.difference') }}
                </BaseTableCell>
              </BaseTableRow>
              <BaseTableRow
                v-for="(payment, index) in cashup.payments"
                :key="index"
                v-test="'cashup-payment-row'"
              >
                <BaseTableCell :width="20">
                  {{ $t(`global.payments.${payment.type.toLowerCase()}`) }}
                </BaseTableCell>
                <BaseTableCell
                  currency
                  :width="20"
                  v-test="`expected-${payment.type.toLowerCase()}`"
                >
                  {{ filters.currency(payment.expected) }}
                </BaseTableCell>
                <BaseTableCell
                  :noWrap="$screen === 'l'"
                  :width="45"
                  :mb="payment.type === 'CASH' ? -1 : false"
                >
                  <BaseInput
                    v-if="payment.type === 'CASH'"
                    v-model="formData.countedCash"
                    type="currency"
                    mr
                    mb
                    v-test="'counted-cash'"
                  />
                  <BaseButton
                    v-if="payment.type === 'CASH' && company.currency === 'EUR'"
                    mb
                    @click="showCountCashModal = true"
                    v-test="'btn-count-cash'"
                  >
                    {{ $t('drawer.count_cash') }}
                  </BaseButton>

                  <BaseInput
                    v-if="payment.type === 'PIN'"
                    v-model="formData.countedPin"
                    type="currency"
                    v-test="'counted-pin'"
                  />

                  <BaseInput
                    v-if="payment.type === 'CREDITCARD'"
                    v-model="formData.countedCreditcard"
                    type="currency"
                    v-test="'counted-credit'"
                  />
                </BaseTableCell>
                <BaseTableCell currency :width="15">
                  <span v-if="payment.countable">
                    <BaseText
                      :color="
                        payment.counted - payment.expected < 0
                          ? 'error'
                          : 'success'
                      "
                    >
                      {{ filters.currency(payment.counted - payment.expected) }}
                    </BaseText>
                  </span>
                </BaseTableCell>
              </BaseTableRow>
              <BaseTableRow footer>
                <BaseTableCell :width="20">
                  {{ $t('global.total') }}:
                </BaseTableCell>
                <BaseTableCell
                  currency
                  :width="20"
                  v-test="'drawer-expected-total'"
                >
                  {{ filters.currency(cashup.expectedTotal) }}
                </BaseTableCell>
                <BaseTableCell :width="45" v-test="'drawer-counted-total'">
                  {{ filters.currency(countedTotal) }}
                </BaseTableCell>
                <BaseTableCell
                  currency
                  :width="15"
                  v-test="'drawer-total-difference'"
                >
                  {{ filters.currency(totalDifference) }}
                </BaseTableCell>
              </BaseTableRow>
            </div>
          </div>
          <BaseGrid container mt mb alignRight>
            <BaseGrid :size="6">
              <BaseInput
                v-model="formData.bankDeposit"
                :label="$t('drawer.bank_deposit')"
                type="currency"
                inputModeText
                mb
                v-test="'bank-deposit'"
              />
              <BaseAlert
                v-if="cashFloatAmount > 0"
                :text="`${$t('drawer.change_amount')}: ${filters.currency(cashFloatAmount)}`"
              />
              <BaseAlert
                v-if="cashFloatAmount < 0"
                :text="$t('drawer.change_amount_error')"
              />
              <BaseInput
                v-model="formData.comment"
                :label="$t('global.items.note', 1)"
                type="textarea"
                mb
                mt
                v-test="'cashup-comment'"
              />
            </BaseGrid>
          </BaseGrid>
          <div :class="$style.footer">
            <BaseButton
              :loading="isLoading"
              @click="submit"
              v-test="'btn-close-drawer-submit'"
            >
              {{ $t('global.actions.submit') }}
            </BaseButton>
          </div>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal } from '@/helpers/ui';
import CountCashModal from './CountCashModal.vue';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import gql from 'graphql-tag';
import { mapState } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CountCashModal,
    BaseTableRow,
    BaseTableCell,
  },
  setup() {
    const { closeCashup, isOpen, getDrawerStatus } = useRegisterDrawerStore();
    return {
      getDrawerStatus,
      closeCashup,
      isOpen,
      filters,
    };
  },
  data() {
    return {
      formData: {
        bankDeposit: 0,
        cashCount: null,
        comment: '',
        countedCash: 0,
        countedCreditcard: 0,
        countedPin: 0,
      },
      isLoading: false,
      showCountCashModal: false,
    };
  },
  watch: {
    'formData.countedCash'(value) {
      this.updateCashupPayment('CASH', value);
    },
    'formData.countedCreditcard'(value) {
      this.updateCashupPayment('CREDITCARD', value);
    },
    'formData.countedPin'(value) {
      this.updateCashupPayment('PIN', value);
    },
  },
  apollo: {
    cashup: {
      query: gql`
        query getLocation($id: Int!) {
          location(id: $id) {
            openCashup {
              cashCount {
                amount
                moneyType
                value
              }
              expectedTotal
              id
              location {
                internalName
              }
              openedBy {
                firstName
              }
              openedOn
              payments {
                countable
                counted
                expected
                type
              }
            }
          }
        }
      `,
      update(data) {
        return data.location.openCashup;
      },
      variables() {
        return {
          id: this.locationId,
        };
      },
      result({
        data: {
          location: { openCashup },
        },
      }) {
        if (openCashup) {
          this.formData.cashCount = openCashup.cashCount.map((item) => ({
            amount: item.amount || 0,
            moneyType: item.moneyType,
            value: item.value,
          }));
        } else {
          this.getDrawerStatus();
          this.$router.replace({ name: 'drawer' });
        }
      },
    },
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    ...mapState(useLocationsStore, ['locationId']),
    countedTotal() {
      const counted =
        this.formData.countedCash +
        this.formData.countedCreditcard +
        this.formData.countedPin;
      const uncountable = this.cashup.payments
        .filter((payment) => !payment.countable)
        .reduce((total, item) => total + item.expected, 0);
      return counted + uncountable;
    },
    totalDifference() {
      return this.countedTotal - this.cashup.expectedTotal;
    },
    cashFloatAmount() {
      return this.formData.countedCash - this.formData.bankDeposit;
    },
  },
  methods: {
    updateCashupPayment(type, value) {
      const payment = this.cashup.payments.find(
        (payment) => payment.type === type,
      );
      if (payment) {
        payment.counted = value;
      }
    },
    submitCashCount({ cashCount, total }) {
      this.formData.cashCount = cashCount;
      this.formData.countedCash = total;
    },
    submit() {
      const showWarning = !!this.cashup.payments.find(
        (payment) =>
          payment.countable && !payment.counted && payment.expected !== 0,
      );

      modal('confirmation', {
        message: this.$t('drawer.close_confirm'),
        warning: showWarning ? this.$t('drawer.close_confirm_warning') : null,
      }).then(() => {
        this.isLoading = true;

        // moneyType prop is not allowed in the mutation input
        this.formData.cashCount = this.formData.cashCount.map((item) => ({
          amount: item.amount,
          value: item.value,
        }));

        this.closeCashup(this.formData)
          .then(() => {
            this.$router.push({
              name: 'cashup',
              params: { id: this.cashup.id },
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.isOpen) {
        vm.$router.replace({ name: 'drawer' });
      }
    });
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: center;
}

.inner {
  max-width: 850px;
  width: 100%;
  margin-top: $spacing;
}

.border {
  border-bottom: 1px solid $color-border;
  margin-bottom: $spacing;
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $color-border;
  margin: -$spacing;
  padding: $spacing;
}

.table {
  overflow-x: auto;
}

.tableInner {
  min-width: 540px;
}
</style>
