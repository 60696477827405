<template>
  <BaseModal :heading="$t('global.message')" small @close="$emit('close')">
    <div>
      <BaseInput
        v-model="email"
        type="email"
        :label="$t('global.email')"
        required
        mb
        v-test="'sendToSupplierEmail'"
      />
      <BaseEditor
        v-model="message"
        :label="$t('global.message')"
        mb
        v-test="'sendToSupplierMessage'"
      />
    </div>
    <template #footer>
      <BaseButton color="inverted" @click="$emit('close')">{{
        $t('global.actions.cancel')
      }}</BaseButton>
      <BaseButton
        :loading="isLoading"
        @click="submit"
        v-test="'sendToSupplierSave'"
      >
        {{ $t('global.actions.send') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import useVuelidate from '@vuelidate/core';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    supplierEmail: {
      type: String,
      required: false,
    },
  },
  emits: ['submit', 'close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: '',
      message: '',
      feedbackId: null,
      isLoading: false,
    };
  },
  methods: {
    callback() {
      this.isLoading = false;
      this.$emit('submit');
      this.$emit('close');
      flash(this.$t('global.flash.message_sent'));
    },
    submit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;

      const input = {
        destination: this.email,
        message: this.message,
        gateway: 'EMAIL',
        messageType: 'STOCK_ORDER',
        stockOrderId: Number.parseInt(this.$route.params.stockOrderId),
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createMessage($input: CreateMessageInput!) {
              createMessage(input: $input) {
                errors
              }
            }
          `,
          variables: {
            input,
          },
        })
        .then(() => {
          this.callback();
        });
    },
  },
  created() {
    if (this.supplierEmail) {
      this.email = this.supplierEmail;
    }
  },
});
</script>
