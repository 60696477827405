<template>
  <div v-if="treatwellSignup" :class="$style.treatwell" />
  <div
    v-else
    :class="[
      $style.base,
      {
        [$style.hasExperimentalBg]: showExperimentalBg,
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm'
      }
    ]"
  >
    <transition>
      <div v-if="showExperimentalBg" :class="$style.experimentalBg" />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import { useNativeAppStore } from '@/stores/native-app';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
const { treatwellSignup } = storeToRefs(useCompanyStore());

const variant = inject<string>('variant');
const route = useRoute();
const { isNativeAppIOS } = useNativeAppStore();

const showExperimentalBg = computed(
  () => !isNativeAppIOS && route.name === 'signup' && variant === 'experiment',
);
</script>

<style lang="scss" module>
.base {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #aeaee9;
  transition: background-color 0.3s ease;

  &.hasExperimentalBg {
    background-color: black;
  }
}

.experimentalBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: blur(5px);

  .base:not(.smallScreen):not(.mediumScreen) & {
    background-image: url('/img/signup/bg-desktop.jpg');
    background-size: 100% auto;
    background-position: center top;
  }

  .base.mediumScreen & {
    background-image: url('/img/signup/bg-tablet.jpg');
    background-size: cover;
    background-position: center center;
  }

  .base.smallScreen & {
    background-image: url('/img/signup/bg-mobile.jpg');
    background-size: cover;
    background-position: center center;
  }

  &:global(.v-enter-active),
  &:global(.v-leave-active) {
    transition: opacity 0.3s ease;
  }

  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
  }
}

.treatwell {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: url('/img/treatwell/bg.png') center center no-repeat;
  background-size: cover;
}
</style>
