<template>
  <div>
    <UploadImages />
    <SelectColor />
    <Message />
    <Discount v-if="showDiscount" v-test="'discountSection'" />
    <MainButton />
    <Footer />
  </div>
</template>

<script setup lang="ts">
import MainButton from './MainButton.vue';
import Footer from './Footer.vue';
import UploadImages from './UploadImages.vue';
import Message from './Message.vue';
import SelectColor from './SelectColor.vue';
import Discount from './Discount.vue';

import { useUserStore } from '@/stores/user';
import { useStore } from 'vuex';

const { hasFeatureFlag } = useUserStore();
const store = useStore();

const showDiscount = computed(
  () =>
    !store.getters['marketing/builder/hideDiscount'] &&
    hasFeatureFlag('module-marketing'),
);
</script>
