import { useRouter } from 'vue-router';

// The communication from web app to the native app

export enum NativeAppMessage {
  AppLoaded = 'appLoaded',
  EnablePushNotification = 'enablePushNotification',
  UserLogin = 'userLogin',
  UserLogout = 'userLogout',
}

type AppLoadedPayload = {
  hasPushNotificationEnabled: boolean;
  isUserAuthenticated: boolean;
};

type Options =
  | {
      message: NativeAppMessage.AppLoaded;
      payload: AppLoadedPayload;
    }
  | {
      message: NativeAppMessage.EnablePushNotification;
      payload?: undefined;
    }
  | {
      message: NativeAppMessage.UserLogin;
      payload?: undefined;
    }
  | {
      message: NativeAppMessage.UserLogout;
      payload?: undefined;
    };

export type CallHandler = (
  message: NativeAppMessage,
  payload?: AppLoadedPayload,
) => void;

export const sendNativeAppMessage = ({ message, payload }: Options) => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(message, payload);
  }
};

// The communication from native app to web app

export enum MessageFromNative {
  NewAppointment = 'newAppointment',
  NewOnlineAppointment = 'new_online_appointment',
}

export type NativeMessageData = {
  appointmentId?: string;
  appointment_id?: string;
};

export const useNativeAppMessage = () => {
  const router = useRouter();

  const handleNativeAppMessage = (
    message: MessageFromNative,
    payload: NativeMessageData,
  ) => {
    switch (message) {
      case MessageFromNative.NewAppointment:
        if (payload.appointmentId) {
          router.push({
            name: 'appointment',
            params: { appointmentId: payload.appointmentId },
          });
        }
        break;
      case MessageFromNative.NewOnlineAppointment:
        if (payload.appointment_id) {
          router.push({
            name: 'appointment',
            params: { appointmentId: payload.appointment_id },
          });
        }
        break;
    }
  };

  const appendMessageHandlerToWindow = () => {
    window.handleNativeAppMessage = handleNativeAppMessage;
  };

  return { appendMessageHandlerToWindow };
};
