import apolloClient from '@/apollo/client';
import { categoryFragment } from '@/graphql-fragments';
import { flash } from '@/helpers/ui';
import i18n from '@/i18n';
import type {
  CreateServiceCategoryInput,
  Service,
  ServiceCategory,
} from '@/types';
import gql from 'graphql-tag';
import { defineStore } from 'pinia';

interface State {
  services: Service[];
  serviceCategories: ServiceCategory[];
  fetchingServices: boolean;
}

interface SortOrder {
  id: Service['id'] | ServiceCategory['id'];
  sortOrder: number;
}

type SortOrders = SortOrder[];

export const useServicesStore = defineStore('services', {
  state: (): State => ({
    services: [],
    serviceCategories: [],
    fetchingServices: false,
  }),
  getters: {
    serviceById:
      (state) =>
      (id: Service['id']): Service | undefined =>
        state.services.find((s) => s.id === id),
    categoriesWithServices(): any {
      return this.serviceCategories.map((category: ServiceCategory) => ({
        ...category,
        services: this.services.filter(
          (service) => service.category?.id === category.id,
        ),
      }));
    },
    rebookEnabledServicesCount(): number {
      return this.services.filter((item) => item.rebookEnabled).length;
    },
  },
  actions: {
    _updateService(payload: Service | SortOrder) {
      if (!payload.id) {
        return;
      }

      const service = this.serviceById(payload.id);

      if (!service) {
        return;
      }

      Object.keys(payload).forEach((key) => {
        if (service[key as keyof SortOrder] !== undefined) {
          service[key as keyof SortOrder] = payload[key as keyof SortOrder];
        }
      });
    },

    getServices() {
      this.fetchingServices = true;

      apolloClient
        .query({
          query: gql`
            query getServices {
              services {
                bookable
                buffer
                category {
                  ...category
                }
                deleted
                duration
                durationFinish
                durationProcessing
                durationSetup
                id
                medical
                favorite
                name
                offeringEnabled
                price
                rebookEnabled
                rebookPeriod
                requiresProcessingTime
                resourceAdjustments {
                  id
                  price
                  resourceId
                }
                sortOrder
              }
            }
            ${categoryFragment}
          `,
        })
        .then(({ data: { services } }) => {
          this.services = services;
          this.fetchingServices = false;
        });
    },

    sortServices(payload: SortOrders) {
      const sortServices = () =>
        this.services.sort((a, b) => a.sortOrder - b.sortOrder);

      payload.forEach((item) => {
        this._updateService(item);
      });

      sortServices();

      apolloClient
        .mutate({
          mutation: gql`
            mutation sortServices($input: SortServicesInput!) {
              sortServices(input: $input) {
                services {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              sortableAttributes: payload,
            },
          },
        })
        .then(() => {
          flash(i18n.t('global.flash.services_updated'));
        });
    },

    getServiceCategories() {
      apolloClient
        .query({
          query: gql`
            query getServiceCategories {
              serviceCategories {
                ...category
              }
            }
            ${categoryFragment}
          `,
        })
        .then(({ data: { serviceCategories } }) => {
          this.serviceCategories = serviceCategories;
        });
    },

    deleteCategory(id: ServiceCategory['id']) {
      return new Promise<void>((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation deleteServiceCategory(
                $input: DeleteServiceCategoryInput!
              ) {
                deleteServiceCategory(input: $input) {
                  serviceCategory {
                    id
                  }
                  errors {
                    message
                    type
                  }
                }
              }
            `,
            variables: {
              input: {
                id,
              },
            },
          })
          .then((response) => {
            if (response.data.deleteServiceCategory.errors?.length) {
              reject();
            } else {
              resolve();
              this.serviceCategories = this.serviceCategories.filter(
                (category) => category.id !== id,
              );
              flash(i18n.t('global.flash.category_deleted'));
            }
          });
      });
    },

    createCategory(input: CreateServiceCategoryInput, options = {}) {
      return new Promise((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation createCategory($input: CreateServiceCategoryInput!) {
                createServiceCategory(input: $input) {
                  serviceCategory {
                    ...category
                  }
                  errors
                }
              }
              ${categoryFragment}
            `,
            variables: { input },
          })
          .then(
            ({
              data: {
                createServiceCategory: { serviceCategory },
              },
            }) => {
              this.serviceCategories.push(serviceCategory);
              if (!options?.hideFlashMessage) {
                flash(i18n.t('global.flash.category_created'));
              }
              resolve(serviceCategory.id);
            },
          );
      });
    },

    updateCategory(input: ServiceCategory) {
      return new Promise<void>((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation updateCategory($input: UpdateServiceCategoryInput!) {
                updateServiceCategory(input: $input) {
                  serviceCategory {
                    ...category
                  }
                  errors
                }
              }
              ${categoryFragment}
            `,
            variables: { input },
          })
          .then(
            ({
              data: {
                updateServiceCategory: { serviceCategory },
              },
            }) => {
              this.serviceCategories = this.serviceCategories.map((category) =>
                category.id === serviceCategory.id ? serviceCategory : category,
              );
              flash(i18n.t('global.flash.category_updated'));
              resolve();
            },
          );
      });
    },

    sortCategories(payload: SortOrder[]) {
      const sortCategories = (payload: SortOrder[]) => {
        payload.forEach((item) => {
          if (item.id) {
            const category = this.serviceCategories.find(
              (r) => r.id === item.id,
            );
            if (category) {
              category.sortOrder = item.sortOrder;
            }
          }
        });

        this.serviceCategories.sort((a, b) => a.sortOrder - b.sortOrder);
      };

      sortCategories(payload);

      apolloClient
        .mutate({
          mutation: gql`
            mutation sortServiceCategories(
              $input: SortServiceCategoriesInput!
            ) {
              sortServiceCategories(input: $input) {
                serviceCategories {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              sortableAttributes: payload,
            },
          },
        })
        .then(() => {
          flash(i18n.t('global.flash.categories_updated'));
        });
    },
  },
});
