<template>
  <BaseCard>
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's',
          [$style.isDefault]: isDefault
        }
      ]"
    >
      <div :class="$style.top">
        <BaseInput
          v-model="name"
          :label="$t('vat_rates.name')"
          required
          v-test="'vat-rate-name'"
        />
        <BaseInput
          v-if="!disablePercentage"
          v-model="percentage"
          type="number"
          unitLabel="percentage"
          :decimals="2"
          :label="$t('global.percentage')"
          :minValue="0"
          :maxValue="100"
          v-test="'vat-rate-percentage'"
        />
      </div>
      <div :class="$style.bottom">
        <div :class="$style.bottomLeft">
          <BaseRadio
            v-model="isDefault"
            :options="[
              {
                label: $t('vat_rates.use_as_default'),
                value: true
              }
            ]"
            v-test="'vat-rate-default'"
          />
          <BaseCheckbox
            v-model="disablePercentage"
            :label="$t('vat_rates.disable_percentage')"
            ml
            v-test="'vat-rate-disable-percentage'"
          />
        </div>
        <div :class="$style.delete">
          <BaseIcon
            name="delete"
            clickable
            @click="$emit('delete')"
            v-test="'vat-rate-delete'"
          />
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    vatRate: {
      type: Object,
      required: true,
      validator: (value) =>
        'name' in value && 'percentage' in value && 'default' in value,
    },
  },
  emits: ['delete', 'update'],
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    name: {
      get() {
        return this.vatRate.name;
      },
      set(value: string) {
        this.$emit('update', {
          key: 'name',
          value,
        });
      },
    },
    percentage: {
      get() {
        return this.vatRate.percentage;
      },
      set(value: number) {
        this.$emit('update', {
          key: 'percentage',
          value,
        });
      },
    },
    isDefault: {
      get() {
        return this.vatRate.default;
      },
      set(value: boolean) {
        this.$emit('update', {
          key: 'default',
          value,
        });
      },
    },
    disablePercentage: {
      get() {
        return this.vatRate.percentage === null;
      },
      set(value: boolean) {
        this.$emit('update', {
          key: 'percentage',
          value: value ? null : 0,
        });
      },
    },
  },
});
</script>

<style lang="scss" module>
.top {
  .base:not(.smallScreen) & {
    display: flex;
    justify-content: space-between;

    & > * {
      width: calc(50% - #{$spacing * 0.5});
    }
  }

  .base.smallScreen & {
    & > *:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing;
}

.bottomLeft {
  display: flex;
}

.delete {
  .base.isDefault & {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
