<template>
  <div :class="$style.base">
    <BaseInput
      v-model="amount"
      :label="$t('global.amount')"
      size="l"
      type="currency"
      v-test="'register-payment-amount'"
    />
    <div>
      <div v-if="fetchingPaymentMethods" :class="$style.loading">
        <BaseSpinner inline />
      </div>
      <div v-else>
        <div :class="$style.options">
          <PaymentMethod
            v-for="(payment, index) in paymentMethods"
            :key="index"
            :payment="payment.toLowerCase()"
            :amount="amount"
            :prepaidCards="prepaidCards"
            :posTerminals="connectedTerminals"
            :posConnected="selectPosTerminal"
            @addPayment="addPayment"
            @openModal="openModal"
          />
        </div>
        <SalonizedPayOption v-if="$screen !== 's'" mt step="register" />
      </div>
    </div>
    <GiftcardModal
      v-if="selectGiftcard"
      :paymentAmount="amount"
      @submit="addPayment"
      @close="selectGiftcard = false"
    />
    <PrepaidCardModal
      v-if="selectPrepaidCard"
      :prepaidCards="prepaidCards"
      @submit="addPayment"
      @close="selectPrepaidCard = false"
    />
    <PosTerminalModal
      v-if="selectPosTerminal && locationTerminals.length"
      :amount="amount"
      :posTerminals="locationTerminals"
      @close="selectPosTerminal = false"
    />
    <OpenDrawerModal
      v-if="showOpenDrawerModal"
      @close="showOpenDrawerModal = false"
      @success="openModal('pos')"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
import GiftcardModal from './GiftcardModal.vue';
import PrepaidCardModal from './PrepaidCardModal.vue';
import PaymentMethod from './PaymentMethod.vue';
import PosTerminalModal from './pos/index.vue';
import { useQuery } from '@vue/apollo-composable';
import SalonizedPayOption from './SalonizedPayOption.vue';
import { GET_PREPAID_CARDS, GET_PAYMENT_METHODS } from './graphql';
import { useRoute } from 'vue-router';
import OpenDrawerModal from '@/modules/register/modals/open-drawer/index.vue';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { useRegisterStore } from '@/modules/register/stores/register';
import { useAdyenStore } from '@/stores/adyen';

const { addTransaction, order } = useRegisterOrderStore();
const { outstandingAmount } = storeToRefs(useRegisterOrderStore());

const { result: prepaidCardsResult } = useQuery(
  GET_PREPAID_CARDS,
  () => ({
    customerId: order.customerId,
    filterScope: 'USABLE',
  }),
  () => ({ enabled: !!order.customerId }),
);

const prepaidCards = computed(
  () => prepaidCardsResult.value?.prepaidCards || [],
);
const { result: paymentMethodsResult, loading: fetchingPaymentMethods } =
  useQuery(GET_PAYMENT_METHODS, { register: true });
const paymentMethods = computed(
  () => paymentMethodsResult.value?.paymentMethods || [],
);

const amount = ref(outstandingAmount.value);
const selectPrepaidCard = ref(false);
const selectGiftcard = ref(false);
const selectPosTerminal = ref(false);
const showOpenDrawerModal = ref(false);

const { terminals: posTerminals } = storeToRefs(useAdyenStore());
const connectedTerminals = computed(() =>
  posTerminals.value.filter((terminal: any) => terminal.status === 'CONNECTED'),
);
const locationTerminals = computed(() =>
  connectedTerminals.value.filter(
    (terminal) => terminal.location?.id === order.locationId,
  ),
);

const addPayment = (payment: {
  type: string;
  amount: number;
  id?: string;
  name?: string;
  card?: any;
}) => {
  addTransaction({
    type: payment.type.toUpperCase(),
    amount: payment.amount,
    ...(payment.type === 'giftcard'
      ? { giftcardId: payment.id, name: payment.name }
      : null),
    ...(payment.type === 'prepaid_card'
      ? {
          prepaidCardId: payment.id,
          name: payment.name,
          prepaidCard: payment.card,
        }
      : null),
  });
  selectGiftcard.value = false;
  selectPrepaidCard.value = false;
};

const openModal = (type: string) => {
  const { isOpen } = useRegisterDrawerStore();
  const { cashupEnabled } = useRegisterStore();

  if (cashupEnabled && !isOpen) {
    showOpenDrawerModal.value = true;
    return;
  }

  switch (type) {
    case 'giftcard':
      selectGiftcard.value = true;
      break;
    case 'prepaid_card':
      selectPrepaidCard.value = true;
      break;
    case 'pos':
      selectPosTerminal.value = true;
      break;
  }
};

const route = useRoute();
if (route.query.direct) {
  openModal('pos');
}

watch(outstandingAmount, () => {
  // amount should be updated when a new payment is added but also when a transaction is deleted (which happens in the store)
  amount.value = outstandingAmount.value;
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: $spacing;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.loading {
  display: flex;
  justify-content: center;
  padding: $spacing;
}
</style>
