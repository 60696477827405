import { watch } from 'vue';

import dayjs from '@/dayjs';
import type { Resource } from '@/types';

import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';

import calendarInterface from '@/modules/calendar/calendar-interface';
import { cachedBusinessHours } from './calendar-business-hours';
import { cachedAppointments } from './calendar-events';

type BusinessHours = {
  daysOfWeek: number[];
  startTime?: string;
  endTime?: string;
};

type CalendarResource = {
  id: number;
  title: string;
  businessHours: BusinessHours[];
  sortOrder: number;
};

const calendarResources = () => {
  const { resourceId, viewType, resourceType } = useCalendarFiltersStore();

  let resources: Resource[] = [];

  if (viewType === 'DAY' && resourceId) {
    const { resourceById } = useResourcesStore();
    const resource = resourceById(resourceId);
    if (resource) {
      resources = [resource];
    }
  } else {
    const { resourcesByLocation } = useResourcesStore();
    resources = resourcesByLocation.filter(
      (resource) => resource.type === resourceType,
    );
  }

  const calendarResources: CalendarResource[] = resources.map((r) => {
    const resourceHours: BusinessHours[] = cachedBusinessHours.value
      .filter((hours) => hours.resource === r.id)
      .map((hours) => ({
        daysOfWeek: hours.daysOfWeek,
        startTime: hours.startTime,
        endTime: hours.endTime,
      }));

    // If there are no resource hours for the resource, add an empty object
    // Otherwise Fullcalendar will mark the resource has being available all day, whereas it should be unavailable

    if (!resourceHours.length) {
      resourceHours.push({ daysOfWeek: [] });
    }

    return {
      id: r.id,
      title: r.name,
      businessHours: resourceHours,
      sortOrder: r.sortOrder,
    };
  });

  const { companySettings } = useCompanyStore();
  const hideClosedResources = companySettings.agenda.hideClosedResources;

  if (hideClosedResources && viewType === 'DAY') {
    // When hideClosedResources is true, and the calendar is on day view, we need to hide resources that don't have any business hours.
    // But if they have any appointments for that day, we do need to show them.

    const filteredResources = calendarResources.filter((r) => {
      let showResource = true;
      if (!cachedBusinessHours.value.find((b) => b.resource === r.id)) {
        // The resource has no business hours
        showResource = false;
      }
      if (!showResource) {
        const { date } = useCalendarFiltersStore();

        // Filter appointments to only the appointments of the current day
        // This shouldn't be needed, but for recurring appointments the backend returns them if the current day falls within the rrule range
        const currentDayAppointments =
          cachedAppointments.value.filter(
            (event) =>
              event.extendedProps?.type === 'appointment' &&
              dayjs(event.start).isSame(date, 'date'),
          ) || [];

        // Show the resource if it has an appointment on the current day
        showResource = !!currentDayAppointments.find(
          (a) =>
            a.extendedProps.type === 'appointment' &&
            a.resourceIds?.includes(r.id),
        );
      }
      return showResource;
    });
    return filteredResources;
  } else {
    return calendarResources;
  }
};

export const useWatchers = () => {
  watch(
    () => cachedBusinessHours.value,
    () => {
      calendarInterface.api?.refetchResources();
    },
    { deep: true },
  );
};

export const calendarOptions = {
  resources: (info: any, successCallback: any) => {
    const resources = calendarResources();
    if (!resources.length) {
      // If we send an empty array, Fullcalendar won't show the business hours correctly.
      // When no resources are available in day view, it should mark the entire day as unavailable.
      // Sending an empty resource object will do that, although it's a bit hacky.
      successCallback([{}]);
    } else {
      successCallback(resources);
    }
  },
  resourceOrder: 'sortOrder',
};
