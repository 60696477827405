import { useLocationsStore } from '@/stores/locations';
import { useResourcesStore } from '@/stores/resources';
import { useServicesStore } from '@/stores/services';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const selectedLocationId = ref<number | null>(null);
const locationOptions = computed(() => {
  const { t } = useI18n();
  const { locations } = useLocationsStore();

  return [
    { value: null, label: t('custom_booking_widget.all_locations') },
    ...locations.map((location) => ({
      value: location.id,
      label: location.internalName,
    })),
  ];
});

const selectedServiceIds = ref<number[]>([]);
const serviceOptions = computed(() => {
  const { categoriesWithServices } = useServicesStore();
  const _categoriesWithServices = categoriesWithServices.filter(
    (category) => category.services.length,
  );
  return _categoriesWithServices.length
    ? _categoriesWithServices.map((category) => ({
        label: category.name,
        color: category.color,
        items: category.services.map((service) => ({
          label: service.name,
          id: service.id,
        })),
      }))
    : [];
});

const selectedEmployeeIds = ref<number[]>([]);
const employeeOptions = computed(() => {
  const { employees } = useResourcesStore();
  return employees.map((employee) => ({
    label: employee.name,
    color: employee.color,
    id: employee.id,
  }));
});

const selectedShareOption = ref('link');

export default {
  selectedLocationId,
  locationOptions,
  selectedServiceIds,
  serviceOptions,
  selectedEmployeeIds,
  employeeOptions,
  selectedShareOption,
};
