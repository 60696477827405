<template>
  <div>
    <InputElement
      v-model="value"
      :type="inputType"
      v-bind="{ uid, hasError, placeholder, disabled, focus, size, prefix }"
      :inputMode="inputMode"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script lang="ts">
import InputElement from './_shared/InputElement.vue';
import props from './_shared/props';

import { defineComponent } from 'vue';

const inputTypes = ['text', 'email', 'tel', 'url'];
const inputModes = ['text', 'email', 'tel', 'url', 'search'];

export default defineComponent({
  components: {
    InputElement,
  },
  inheritAttrs: false,
  props: {
    modelValue: String,
    type: {
      type: String,
      required: true,
      validator: (value: string) => inputModes.indexOf(value) !== -1,
    },
    ...props(),
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        if (this.type === 'email') {
          this.$emit('update:modelValue', value.toLowerCase());
        } else {
          this.$emit('update:modelValue', value);
        }
      },
    },
    inputType() {
      return inputTypes.includes(this.type) ? this.type : 'text';
    },
    inputMode() {
      return inputModes.includes(this.type) ? this.type : 'text';
    },
  },
});
</script>
