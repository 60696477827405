<template>
  <BaseModal
    :heading="t('treatwell.kyc_ultimatum.header')"
    useForm
    testId="treatwell-kyc-ultimatum"
    small
    grey
    @close="close"
    @submit="submit"
  >
    <BaseCard noPadding>
      <div :class="$style.content">
        <img
          src="/img/treatwell/verification-process.svg"
          :class="$style.visual"
        />
        <BaseHeading>
          {{ t('treatwell.kyc_ultimatum.disclaimer.header') }}
        </BaseHeading>
        <BaseText>
          {{ t('treatwell.kyc_ultimatum.disclaimer.text') }}
        </BaseText>
      </div>
    </BaseCard>
    <template #footerSub>
      <BaseText
        :href="`https://help.salonized.com/${company.language === 'nl' || company.language === 'de' ? company.language : 'en'}/articles/9863280`"
      >
        {{ t('global.learn_more') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton submitForm v-test="'kyc-ultimatum-start'">
        {{ t('banner.kyc_button') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useTreatwellStore } from '@/stores/treatwell';
import { useCompanyStore } from '@/stores/company';

const { company } = useCompanyStore();

const { dismissKYCModal } = useTreatwellStore();
const { t } = useI18n();
const router = useRouter();
const emit = defineEmits(['close', 'submit']);

const close = () => {
  emit('close');
  dismissKYCModal();
};

const submit = () => {
  close();
  router.push({
    name: 'treatwell-kyc-start',
  });
};
</script>

<style lang="scss" module>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5 * $spacing;
  margin: 1.5 * $spacing;
}

.visual {
  width: 176px;
  max-width: 50%;
  max-height: 150px;
  padding-bottom: $spacing;
}
</style>
