<template>
  <div>
    <BaseRadio
      v-model="selectedValue"
      :options="filters"
      mb
      @update:modelValue="emitValue"
    />
    <div v-if="selectedValue === 'birthday_between'" v-test="'filterDates'">
      <BaseDatePicker
        v-model="dateFrom"
        :label="$t('customers.filters.from')"
        mb
      />
      <BaseDatePicker v-model="dateTo" :label="$t('customers.filters.to')" />
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      selectedValue: 'birthday_upcoming',
      filters: [
        {
          label: this.$t('customers.filters.birthday_upcoming'),
          value: 'birthday_upcoming',
        },
        {
          label: this.$t('customers.filters.select_time'),
          value: 'birthday_between',
        },
      ],
      dateFrom: dayjs().format(),
      dateTo: dayjs().format(),
    };
  },
  watch: {
    dateFrom() {
      this.emitValue(this.selectedValue);
    },
    dateTo() {
      this.emitValue(this.selectedValue);
    },
  },
  methods: {
    emitValue(selectedValue) {
      const dates = {
        from: this.dateFrom,
        to: this.dateTo,
      };
      const values = selectedValue === 'birthday_between' ? dates : null;
      const obj = {
        filter_name: selectedValue,
        values,
      };
      this.$emit('selected', obj);
    },
  },
  created() {
    this.emitValue(this.selectedValue);
  },
});
</script>
