<template>
  <div :class="$style.base">
    <AccordionItem
      v-for="(slot, key) in $slots"
      :key="key"
      :isOpen="!!activeKeys.find((activeKey) => activeKey === key)"
      :title="titles[key - 1]"
      :label="labels?.[key - 1] ? labels?.[key - 1] : undefined"
      :cardStyle="cardStyle"
      @toggle="onToggle(key, $event)"
      v-test="'accordion-item'"
      v-intercom="
        routeQueries.length
          ? `accordion-${routeQueries[parseInt(key) - 1]}`
          : null
      "
    >
      <slot :name="key" />
    </AccordionItem>
  </div>
</template>

<script lang="ts">
export default {
  name: 'BaseAccordion',
};
</script>

<script lang="ts" setup>
import AccordionItem from './AccordionItem.vue';
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = withDefaults(
  defineProps<{
    modelValue: string[];
    titles?: string[];
    labels?: (string | null)[];
    stayOpen?: boolean;
    cardStyle?: boolean;
    routeQueries?: string[];
  }>(),
  {
    titles: () => [],
    stayOpen: false,
    cardStyle: false,
    routeQueries: () => []
  }
);

const emit = defineEmits(['update:modelValue', 'toggle']);

const activeKeys = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const onToggle = (key: string, element: HTMLElement) => {
  const isOpen = !!activeKeys.value.find((activeKey) => activeKey === key);

  if (props.stayOpen) {
    if (isOpen) {
      activeKeys.value = activeKeys.value.filter(
        (activeKey) => activeKey !== key
      );
    } else {
      activeKeys.value = [...activeKeys.value, key];
    }
  } else {
    activeKeys.value = isOpen ? [] : [key];
  }

  emit('toggle', element);
};

if (props.routeQueries.length) {
  const router = useRouter();
  const initialQuery = router.currentRoute.value.query.section;
  if (initialQuery && typeof initialQuery === 'string') {
    const index = props.routeQueries.indexOf(initialQuery);
    activeKeys.value = [String(index + 1)];
  }

  watch(
    activeKeys,
    (activeKeys) => {
      if (activeKeys.length === 1) {
        const sectionQuery = props.routeQueries[parseInt(activeKeys[0]) - 1];
        if (sectionQuery) {
          router.replace({
            path: router.currentRoute.value.path,
            query: {
              section: sectionQuery
            }
          });
        }
      }
    },
    {
      immediate: true
    }
  );
}
</script>

<style lang="scss" module>
.base {
  // do not remove, fixes overflow bug
  position: relative;
}
</style>
