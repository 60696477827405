<template>
  <SubscriptionCard
    v-if="
      quote &&
      quote.plan !== SubscriptionPlan.Pro &&
      !(activeSubscription && activeSubscription.cancelled)
    "
    :title="t('admin.cards.upgrade.title')"
    :description="t('admin.cards.upgrade.description')"
    :features="features"
    :buttonLabel="t('admin.cards.upgrade.upgrade_button')"
    :difference="
      difference && basePrice && quote
        ? {
            value: difference,
            currency: basePrice.currency,
            period: quote.period
          }
        : undefined
    "
  />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useCurrentPlanQuote } from './store';
import { storeToRefs } from 'pinia';
import { SubscriptionPlan } from '@/types';
import SubscriptionCard from './SubscriptionCard.vue';
import getCompanySubscription from './get-company-subscription';

const { t } = useI18n();
const { difference, basePrice, quote } = storeToRefs(useCurrentPlanQuote());
const { activeSubscription } = getCompanySubscription();
const features = [
  'online_discount_codes',
  'marketing_newsletters',
  'last_minute_discounts',
  'waiting_list',
];
</script>
