<template>
  <ResourceForm
    type="employee"
    @close="$emit('close')"
    @created="$emit('created', $event)"
  />
</template>

<script lang="ts">
import ResourceForm from './resource-form/index.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ResourceForm,
  },
  emits: ['close', 'created'],
});
</script>
