<template>
  <div>
    <TerminalsModal
      v-if="showTerminalsModal"
      @close="showTerminalsModal = false"
    />
    <OnboardingDetails
      v-if="showOnboarding"
      @close="showOnboarding = false"
      @refetch="refetchAdyenAppState"
    />
    <BaseTask
      v-for="(data, idx) in _DATA"
      :key="data.heading"
      :heading="data.heading"
      :state="data.state"
      :text="data.text"
      :buttonIcon="data.buttonIcon"
      mt
      @click="
        () => {
          data.click();
          if ($gtm && idx === 2) {
            $gtm.trackEvent({ event: 'sp-verification-card' });
          }
        }
      "
      @buttonClick="data.buttonClick"
      v-test="`sp-onboarding-task-${idx + 1}`"
    />
    <div
      v-if="
        step === 2 && (company.country === 'nl' || company.country === 'de')
      "
      :class="$style.buttons"
    >
      <BaseButton
        color="inverted"
        href="https://demodesk.com/book/salonized-team/salonized-pay?utm_source=email&utm_medium=email&utm_campaign=salonized_pay"
        @click="mixpanel.track('Salonized Pay - Book a demo')"
        v-test="'sp-button-demodesk'"
      >
        {{ $t('onboarding.book_demo') }}
      </BaseButton>
      <BaseButton
        :href="
          company.country === 'de'
            ? 'https://vimeo.com/980575413'
            : 'https://www.youtube.com/watch?v=CbYhuSSuRKk'
        "
        @click="
          mixpanel.track('Salonized Pay - Watch KYC video', { step: 'Page' })
        "
        v-test="'sp-button-youtube'"
      >
        {{ $t('register.salonized_pay.landing.watch_video') }}
      </BaseButton>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, inject } from 'vue';
import OnboardingDetails from './OnboardingDetails.vue';
import { useI18n } from 'vue-i18n';
import TerminalsModal from '../TerminalsModal.vue';
import { useUserStore } from '@/stores/user';
import config from '@/config';
import { useCompanyStore } from '@/stores/company';
import { useAdyenStore } from '@/stores/adyen';

const emit = defineEmits(['generateLink']);

const { company } = useCompanyStore();

const showOnboarding = ref(false);
const { t } = useI18n();
const step = ref(0);
const showTerminalsModal = ref(false);
const { user } = useUserStore();

const mixpanel = inject<any>('mixpanel');
const { adyenAppState } = useAdyenStore();
const { refetchAdyenAppState } = useAdyenStore();

const disableSalonizers = computed(() =>
  config.isProduction || config.isTest || config.isStaging
    ? user?.salonizedAdmin || user?.impersonated
    : false,
);

watchEffect(() => {
  if (adyenAppState) {
    if (adyenAppState.industryCode) {
      step.value = 2;
    } else if (adyenAppState.terminalModel) {
      step.value = 1;
    }
  }
});

const _DATA = computed(() => [
  {
    heading: t('register.salonized_pay.new_onboarding.step_0.heading'),
    text: t('register.salonized_pay.new_onboarding.step_0.text'),
    state: step.value === 0 ? 'required' : 'completed',
    click: () => {
      showTerminalsModal.value = true;
    },
    buttonIcon: step.value > 0 ? 'edit' : null,
    buttonClick: () => {
      showTerminalsModal.value = true;
    },
  },
  {
    heading: t('register.salonized_pay.new_onboarding.step_1.heading'),
    state:
      step.value === 0
        ? 'blocked'
        : step.value === 1
          ? 'required'
          : 'completed',
    text:
      step.value === 1
        ? t('register.salonized_pay.new_onboarding.step_1.text')
        : '',
    click: () => {
      showOnboarding.value = true;
    },
  },
  {
    heading: t('register.salonized_pay.new_onboarding.step_2.heading'),
    state: disableSalonizers.value
      ? 'blocked'
      : step.value < 2
        ? 'blocked'
        : step.value === 2
          ? 'required'
          : 'completed',
    text:
      step.value === 2
        ? t('register.salonized_pay.new_onboarding.step_2.text')
        : '',
    click:
      step.value === 2
        ? () => {
            emit('generateLink');
            step.value = 2;
            mixpanel.track('KYC - Verification step clicked');
          }
        : () => {},
  },
]);
</script>

<style lang="scss" module>
.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing;
  margin-top: $spacing;
}
</style>
