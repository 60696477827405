<template>
  <component
    :is="isAppointment ? 'BaseCard' : 'div'"
    :class="$style.base"
    :horizontalContent="isAppointment ? true : null"
  >
    <BaseDropdown
      v-model="freq"
      :options="frequencyOptions"
      :label="$t('appointment.recurring.frequency_label')"
      v-test="'recurring-freq'"
    />
    <BaseDropdown
      v-model="interval"
      :options="intervalOptions"
      :label="$t('appointment.recurring.interval_label')"
      v-test="'recurring-interval'"
    />
    <BaseButtonList
      v-if="freq === 'weekly'"
      v-model="byday"
      :options="dayOptions"
      :label="$t('appointment.recurring.weekly_label')"
      v-test="'recurring-byday'"
    />
    <BaseDropdown
      v-if="freq === 'monthly'"
      v-model="typeMonthly"
      :options="monthlyOptions"
      :label="$t('appointment.recurring.monthly_label')"
      v-test="'recurring-type-monthly'"
    />
    <BaseDropdown
      v-model="endAt"
      :options="endAtOptions"
      :label="$t('appointment.recurring.ends_label')"
      :scrollContainer="scrollContainer"
      v-test="'recurring-end-at'"
    />
    <BaseDatePicker
      v-if="endAt === 'date'"
      v-model="until"
      :label="$t('appointment.recurring.date_label')"
      :minValue="date"
      :scrollContainer="scrollContainer"
      v-test="'recurring-date'"
    />
    <div v-if="endAt === 'times'" :class="$style.inputTimes">
      <BaseInput
        v-model="count"
        type="number"
        :label="$t('appointment.recurring.times_label')"
        v-test="'recurring-count'"
      />
    </div>
  </component>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
const defaultRrule = {
  freq: 'weekly', // 'daily', 'weekly', 'monthly'
  until: null, // Date string, format YYYY-MM-DD
  count: null, // Number
  byday: null, // [1, 2, 3, 4, 5, 6, 7]
  interval: 1, // Number
  bymonthday: null, // [1, 2, ... , 30, 31]
  monthlyByday: null, // [[1,2]]
};

import { setRruleBymonthday, setRruleMonthlyByday } from './helpers';

import { defineComponent, inject } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
    },
    date: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const scrollContainer: HTMLElement | undefined = inject('scrollContainer');

    return {
      filters,
      scrollContainer,
    };
  },
  data() {
    return {
      initialDataSet: false,
      typeMonthly: 'day',
      endAt: 'never',
      defaultCount: 5,
      rrule: { ...defaultRrule },
    };
  },

  watch: {
    modelValue: {
      handler(value) {
        this.rrule = value ? { ...value } : { ...defaultRrule };
      },
      immediate: true,
      deep: true,
    },

    freq(value) {
      if (!this.initialDataSet) {
        return;
      }

      if (value === 'weekly') {
        this.byday = [this.getDayNumber()];
        this.bymonthday = null;
      } else if (value === 'monthly') {
        this.setMonthlyValue();
      } else {
        this.byday = null;
        this.bymonthday = null;
      }
    },
    endAt(value) {
      if (!this.initialDataSet) {
        return;
      }

      this.until = value === 'date' ? this.date : null;
      this.count = value === 'times' ? this.defaultCount : null;
    },
    typeMonthly() {
      if (!this.initialDataSet) {
        return;
      }

      this.setMonthlyValue();
    },
    date(newValue, oldValue) {
      if (!this.initialDataSet) {
        return;
      }

      if (this.freq === 'weekly') {
        const oldDayIndex = this.byday.indexOf(this.getDayNumber(oldValue));
        if (oldDayIndex > -1) {
          this.byday[oldDayIndex] = this.getDayNumber();
          this.byday = [...this.byday]; // Reassign to trigger child component watchers
        }
      }
    },
  },

  computed: {
    dateObj() {
      return dayjs.tz(this.date);
    },
    isAppointment() {
      return this.$route.name === 'create-appointment';
    },
    freq: {
      get() {
        return this.rrule.freq;
      },
      set(freq) {
        this.updateRrule({ freq });
      },
    },
    interval: {
      get() {
        return this.rrule.interval;
      },
      set(interval) {
        this.updateRrule({ interval });
      },
    },
    until: {
      get() {
        return this.rrule.until;
      },
      set(until) {
        this.updateRrule({ until });
      },
    },
    count: {
      get() {
        return this.rrule.count;
      },
      set(count) {
        this.updateRrule({ count });
      },
    },
    byday: {
      get() {
        return this.rrule.byday;
      },
      set(byday) {
        this.updateRrule({ byday });
      },
    },
    bymonthday: {
      get() {
        return this.rrule.bymonthday;
      },
      set(bymonthday) {
        this.updateRrule({ bymonthday });
      },
    },
    monthlyByday: {
      get() {
        return this.rrule.monthlyByday;
      },
      set(monthlyByday) {
        this.updateRrule({ monthlyByday });
      },
    },
    frequencyOptions() {
      return [
        {
          value: 'daily',
          label: this.$t('appointment.recurring.frequency_options.daily'),
        },
        {
          value: 'weekly',
          label: this.$t('appointment.recurring.frequency_options.weekly'),
        },
        {
          value: 'monthly',
          label: this.$t('appointment.recurring.frequency_options.monthly'),
        },
      ];
    },
    endAtOptions() {
      return [
        {
          value: 'never',
          label: this.$t('appointment.recurring.ends_options.never'),
        },
        {
          value: 'date',
          label: this.$t('appointment.recurring.ends_options.date'),
        },
        {
          value: 'times',
          label: this.$t('appointment.recurring.ends_options.times'),
        },
      ];
    },
    intervalOptions() {
      const translationKeys = {
        daily: 'day',
        weekly: 'week',
        monthly: 'month',
        yearly: 'year',
      };

      return Array(12)
        .fill()
        .map((value, index) => ({
          value: index + 1,
          label: `${index + 1} ${this.$t(`global.items.${translationKeys[this.freq]}`, index + 1)}`,
        }));
    },
    monthlyOptions() {
      const dateNumber = this.dateObj.date();

      return [
        {
          value: 'day',
          label: this.filters.ordinalDate(dateNumber),
        },
        {
          value: 'week',
          label: `${this.filters.ordinalDate(Math.ceil(dateNumber / 7))} ${this.filters.weekdayLong(this.getDayNumber())}`,
        },
      ];
    },
    dayOptions() {
      return Array(7)
        .fill()
        .map((value, index) => ({
          value: index + 1,
          label: this.filters.weekdayShort(index + 1),
          disable: this.isSelectedDate(index + 1),
          tooltip: this.isSelectedDate(index + 1)
            ? this.$t('appointment.recurring.byday_tooltip')
            : null,
        }));
    },
  },

  methods: {
    isSelectedDate(dayNumber) {
      return this.getDayNumber(this.date) === dayNumber;
    },
    getDayNumber(date) {
      const dateObj = date ? dayjs.tz(date) : this.dateObj;
      return dateObj.day() || 7;
    },
    setInitialValues() {
      if (this.rrule.count) {
        this.endAt = 'times';
      } else if (this.rrule.until) {
        this.endAt = 'date';
      }

      if (this.freq === 'weekly' && this.date) {
        this.byday = this.rrule.byday || [this.getDayNumber()];
      }

      if (this.freq === 'monthly' && this.monthlyByday) {
        this.typeMonthly = 'week';
        this.bymonthday = null;
      }

      this.$nextTick(() => {
        this.initialDataSet = true;
      });
    },
    setMonthlyValue() {
      if (this.typeMonthly === 'day') {
        this.bymonthday = setRruleBymonthday(this.date);
        this.byday = null;
        this.monthlyByday = null;
      } else if (this.typeMonthly === 'week') {
        this.monthlyByday = setRruleMonthlyByday(this.date);
        this.bymonthday = null;
        this.byday = null;
      }
    },
    updateRrule(payload) {
      Object.keys(payload).forEach((key) => {
        this.rrule[key] = payload[key];
      });

      this.$emit('update:modelValue', this.rrule);
    },
  },

  created() {
    this.setInitialValues();
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: $spacing * -1;

  & > * {
    margin-bottom: $spacing;

    &:not(:last-child) {
      margin-right: $spacing;
    }
  }
}

.inputTimes {
  width: 50px;
}
</style>
