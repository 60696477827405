<template>
  <div :class="[$style.base, { [$style.isSmall]: $screen === 's' }]">
    <RevertPricesModal
      v-if="showConfirmationModal"
      @close="showConfirmationModal = false"
      @confirm="revertPriceChanges"
      v-test="'revertModal'"
    />
    <BaseCard :heading="supplier.name">
      <BaseGrid container>
        <div :class="$style.pillContainer">
          <BaseText
            :color="
              !supplier.productChangeScheduledAt ? 'secondary' : 'default'
            "
          >
            {{
              supplier.productChangeScheduledAt
                ? $t('products.scheduled_update')
                : $t('products.no_schedule')
            }}
          </BaseText>
          <div v-if="supplier.productChangeScheduledAt" :class="$style.pill">
            <BaseIcon name="clock" color="inverted" size="s" />
            <BaseText color="inverted" size="s">
              {{ formatDateTime(supplier.productChangeScheduledAt) }}
            </BaseText>
          </div>
        </div>
        <BaseGrid :size="5">
          <BaseText
            v-if="
              useTotalProducts
                ? supplier.amountProducts === 1
                : supplier.amountProductsWithStock === 1
            "
          >
            {{
              `${
                useTotalProducts
                  ? supplier.amountProducts
                  : supplier.amountProductsWithStock
              } ${$t('global.items.product', 1)}`
            }}
          </BaseText>
          <BaseText v-else>
            {{
              $t('products.products_amount', {
                amount: useTotalProducts
                  ? supplier.amountProducts
                  : supplier.amountProductsWithStock
              })
            }}
          </BaseText>
          <div :class="$style.text">
            <BaseText> {{ $t('global.total_inventory') }}: </BaseText>
            <BaseText :class="$style.items">
              {{ filters.currency(supplier.totalInventory) }}
            </BaseText>
          </div>
        </BaseGrid>
        <BaseGrid :size="7" alignBottom alignRight>
          <BaseButton
            v-if="supplier.productChangeScheduledAt"
            color="inverted"
            @click="showCancelModal"
            v-test="'cancelSupplierUpdate'"
          >
            {{ $t('products.cancel_schedule') }}
          </BaseButton>
          <BaseButton
            v-if="!supplier.productChangeScheduledAt"
            :loading="isLoading"
            :disabled="isDisabled"
            color="inverted"
            @click="$emit('selectSupplier')"
            v-test="'chooseSupplierButton'"
          >
            {{ $t('global.actions.select') }}
          </BaseButton>
        </BaseGrid>
      </BaseGrid>
      <BaseAlert
        v-if="supplier.productChangeAppliedAt"
        :text="`${$t('products.last_update')}: ${filters.date(supplier.productChangeAppliedAt)}`"
        :primaryAction="$t('products.revert_changes')"
        mt
        @primaryAction="showConfirmationModal = true"
        v-test="'revertChanges'"
      />
    </BaseCard>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import RevertPricesModal from './RevertPricesModal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    RevertPricesModal,
  },
  inject: ['mixpanel'],
  props: {
    supplier: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    useTotalProducts: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectSupplier', 'schedulingCancelled'],
  setup() {
    return {
      filters,
      flash,
    };
  },
  data() {
    return {
      showConfirmationModal: false,
    };
  },
  methods: {
    formatDateTime(date: any) {
      return this.filters.fromNow(date);
    },
    revertPriceChanges() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation revertProductChangeSchedule(
              $input: RevertProductChangeScheduleInput!
            ) {
              revertProductChangeSchedule(input: $input) {
                productChangeSchedule {
                  id
                  changeAppliedAt
                  changeRevertedAt
                  scheduledAt
                }
              }
            }
          `,
          variables: {
            input: {
              productChangeScheduleId: this.supplier.productChangeScheduleId,
            },
          },
        })
        .then(() => {
          this.flash(this.$t('products.price_reverted'));
          this.showConfirmationModal = false;
          this.$emit('schedulingCancelled');
        });
    },
    showCancelModal() {
      modal('confirmation').then(() =>
        this.$apollo
          .mutate({
            mutation: gql`
              mutation cancelProductChangeSchedule(
                $input: CancelProductChangeScheduleInput!
              ) {
                cancelProductChangeSchedule(input: $input) {
                  errors
                }
              }
            `,
            variables: {
              input: {
                supplierId: this.supplier.id,
              },
            },
          })
          .then(() => {
            this.mixpanel.track('Click - Product Prices Cancel Schedule');
            flash(this.$t('global.flash.scheduling_cancelled'));
            this.$emit('schedulingCancelled');
          }),
      );
    },
  },
});
</script>

<style lang="scss" module>
.base {
  flex: 1;
  margin: $spacing * 0.5;
  min-width: 300px;
  max-width: 338px;

  &.isSmall {
    min-width: calc(100% - #{$spacing * 0.5});
  }
}

.text {
  width: max-content;
  margin-top: 5px;
}

.alert {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.pillContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: $radius;
}
.pill {
  display: flex;
  align-items: center;

  border-radius: $radius;
  background-color: $color-success;
  padding: 2px 4px 2px 4px;
  text-transform: uppercase;

  > div {
    margin-right: 6px;
  }
}
</style>
