<template>
  <BaseModal
    :heading="
      $route.params.id
        ? $t('global.actions.edit_supplier')
        : $t('global.actions.create_supplier')
    "
    small
    parentRoute="products-suppliers"
  >
    <BaseForm @submit="submit">
      <BaseGrid container>
        <BaseGrid :size="12">
          <BaseInput
            v-model="name"
            :label="$t('global.name')"
            required
            focus
            v-test="'createSupplierName'"
          />
        </BaseGrid>
        <BaseGrid :size="12">
          <BaseInput
            v-model="email"
            :label="$t('global.email')"
            v-test="'createSupplierEmail'"
          />
        </BaseGrid>
        <BaseGrid :size="5">
          <BaseInput
            v-model="address"
            :label="$t('global.address')"
            v-test="'createSupplierAddress'"
          />
        </BaseGrid>
        <BaseGrid :size="3">
          <BaseInput
            v-model="postalcode"
            :label="$t('global.postal_code')"
            v-test="'createSupplierPostalCode'"
          />
        </BaseGrid>
        <BaseGrid :size="4">
          <BaseInput
            v-model="city"
            :label="$t('global.city')"
            v-test="'createSupplierCity'"
          />
        </BaseGrid>
      </BaseGrid>
    </BaseForm>
    <template #footer>
      <BaseButton
        :loading="isLoading"
        @click="submit"
        v-test="'createSupplierSave'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import { supplierFragment } from '../graphql';

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      name: '',
      email: '',
      address: '',
      postalcode: '',
      city: '',
      isLoading: false,
    };
  },
  apollo: {
    supplier: {
      query: gql`
        query getSupplier($id: Int!) {
          supplier(id: $id) {
            ...supplier
          }
        }
        ${supplierFragment}
      `,
      skip() {
        return !this.$route.params.id;
      },
      variables() {
        return {
          id: Number.parseInt(this.$route.params.id),
        };
      },
      fetchPolicy: 'cache-and-network',
      result() {
        this.name = this.supplier.name;
        this.email = this.supplier.email;
        this.address = this.supplier.address;
        this.postalcode = this.supplier.postalcode;
        this.city = this.supplier.city;
      },
    },
  },
  methods: {
    callback() {
      this.$router.push({ name: 'products-suppliers' });
      flash(
        this.$t(
          `global.flash.${this.$route.params.id ? 'supplier_updated' : 'supplier_created'}`,
        ),
      );
    },
    submit() {
      this.isLoading = true;

      const input = {
        name: this.name,
        email: this.email,
        address: this.address,
        postalcode: this.postalcode,
        city: this.city,
      };

      if (this.$route.params.id) {
        input.id = Number.parseInt(this.$route.params.id);
      }

      if (this.$route.params.id) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateSupplier($input: UpdateSupplierInput!) {
                updateSupplier(input: $input) {
                  supplier {
                    ...supplier
                  }
                }
              }
              ${supplierFragment}
            `,
            variables: {
              input,
            },
          })
          .then(() => {
            this.callback();
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createSupplier($input: CreateSupplierInput!) {
                createSupplier(input: $input) {
                  errors
                }
              }
            `,
            variables: {
              input,
            },
            update(cache) {
              cache.evict({ id: 'ROOT_QUERY', fieldName: 'suppliers' });
              cache.gc();
            },
          })
          .then(() => {
            this.callback();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
});
</script>
