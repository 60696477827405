<template>
  <div :class="$style.settingCategory">
    <BaseCard v-if="unleash.isEnabled('SalonizedPayOnline')" gray>
      <div :class="$style.salonizedPayEnableBanner">
        <div>
          <BaseCheckbox
            v-model="onlinePaymentEnabled"
            :label="
              $t('register.salonized_pay.dashboard.online_payments.banner_text')
            "
            mb
            v-test="'booking-settings-salonizedPayOnlineToggle'"
          />
          <BaseText
            :href="
              $t('register.salonized_pay.dashboard.online_payments.banner_link')
            "
          >
            {{ $t('global.learn_more') }}
          </BaseText>
        </div>
        <img src="/img/salonized-pay/logo.svg" :class="$style.bannerImage" />
      </div>
    </BaseCard>
    <BaseCheckbox
      v-else
      v-model="onlinePaymentEnabled"
      :disabled="!onlinePaymentEnabled && !canEnablePayment"
      :label="$t('admin.booking.online_payment.label')"
      v-test="'booking-settings-onlinePaymentToggle'"
    />
    <BaseAlert
      v-if="company.paymentGatewayStatus === PaymentGatewayStatus.MollieError"
      color="warning"
      :text="$t('admin.booking.online_payment.mollie_error.alert')"
      mt
      v-test="'reenable-mollie-alert'"
    />
    <BaseAlert
      v-if="showRequestsDisabledByOnlinePayment"
      color="warning"
      :text="$t('admin.booking.online_payment.appointment_requests_alert')"
      :primaryAction="$t('global.actions.click_here')"
      mt
      @primaryAction="cancelOnlinePayments"
      v-test="'appointment-requests-disabled-alert'"
    />
    <BaseRadio
      v-if="onlinePaymentEnabled && canEnablePayment"
      v-model="settings.onlinePayment"
      :options="onlinePaymentOptions"
      mt
      v-test="'booking-settings-onlinePaymentOptions'"
    />
    <EnablePayment
      v-model="canEnablePayment"
      :description="$t('admin.booking.online_payment_warning')"
      mt
    />
    <div
      v-if="
        canEnablePayment &&
        (settings.onlinePayment === 'PERCENTAGE' ||
          settings.onlinePayment === 'REQUIRED')
      "
      class="mt-1"
    >
      <BaseRadio
        v-model="settings.prepaymentsForNewCustomersOnly"
        :label="$t('admin.booking.online_payment_customers.label')"
        :options="[
          {
            value: false,
            label: $t('admin.booking.online_payment_customers.options.all')
          },
          {
            value: true,
            label: $t('admin.booking.online_payment_customers.options.new')
          }
        ]"
        mb
        v-test="'booking-settings-onlinePaymentCustomers'"
      />
      <div
        v-if="canEnableOnlinePayments"
        v-test="'booking-settings-onlinePaymentRequirements'"
      >
        <BaseRadio
          v-model="appliesTo"
          :label="$t('admin.booking.online_payment.applies_to.heading')"
          :options="[
            {
              value: AppliesToKeys.All,
              label: $t('admin.booking.online_payment.applies_to.options.all')
            },
            {
              value: AppliesToKeys.Price,
              label: $t(
                'admin.booking.online_payment.applies_to.options.price.label'
              )
            },
            {
              value: AppliesToKeys.Duration,
              label: $t(
                'admin.booking.online_payment.applies_to.options.duration.label'
              )
            }
          ]"
          v-test="'booking-settings-onlinePaymentRequirement'"
        />
      </div>
      <BaseInput
        v-if="settings.onlinePayment === 'PERCENTAGE'"
        v-model="settings.onlinePaymentPercentage"
        :label="$t('admin.booking.online_payment_percentage.label')"
        type="number"
        unitLabel="percentage"
        :decimals="2"
        :minValue="1"
        :maxValue="100"
        mt
        v-test="'booking-settings-onlinePaymentPercentage'"
      />
      <div
        v-if="canEnableOnlinePayments"
        v-test="'booking-settings-onlinePaymentRequirements'"
      >
        <BaseInput
          v-show="appliesTo === AppliesToKeys.Price"
          v-model="props.settings.onlinePaymentMinimumPriceValue"
          :minValue="0"
          type="currency"
          :label="
            $t(
              'admin.booking.online_payment.applies_to.options.price.input_label'
            )
          "
          mt
          v-test="'booking-settings-onlinePaymentPrice'"
        />
        <BaseInput
          v-show="appliesTo === AppliesToKeys.Duration"
          v-model="props.settings.onlinePaymentMinimumDurationValue"
          :minValue="0"
          type="number"
          unitLabel="minute"
          :label="
            $t(
              'admin.booking.online_payment.applies_to.options.duration.input_label'
            )
          "
          mt
          v-test="'booking-settings-onlinePaymentDuration'"
        />
      </div>
    </div>
    <BaseInput
      v-if="onlinePaymentEnabled"
      v-model="settings.onlinePaymentCharge"
      :label="$t('admin.booking.online_payment_charge.label')"
      type="currency"
      mt
      v-test="'booking-settings-onlinePaymentCharge'"
    />
    <BaseAlert
      v-if="onlinePaymentEnabled"
      :text="
        $t('admin.booking.online_payment.alert', {
          amount: filters.currency(50)
        })
      "
      mt
      v-test="'online-payment-alert'"
    />
    <BaseCheckbox
      v-if="onlinePaymentEnabled && unleash.isEnabled('PayWithGiftcard')"
      v-model="settings.giftcardPaymentsAllowed"
      :label="$t('admin.booking.giftcard_payment_allowed.label')"
      mt
      v-test="'booking-settings-giftcardPaymentsAllowed'"
    />
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { computed, ref, watch } from 'vue';
import { useBookingSettingsOptions } from './input-options';
import { PaymentGatewayStatus, OnlinePayment } from '@/types';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';
import EnablePayment from '../_shared/EnablePayment.vue';
import unleash from '@/unleash';
import { useRouter } from 'vue-router';
import { useAdyenStore } from '@/stores/adyen';

const props = defineProps<{
  settings: any;
}>();

const { hasFeatureFlag } = useUserStore();
const { company } = useCompanyStore();

const { onlinePaymentOptions } = useBookingSettingsOptions();

const canEnablePayment = ref(false);

const onlinePaymentEnabled = computed({
  get() {
    return (
      canEnablePayment.value &&
      props.settings.onlinePayment !== OnlinePayment.Disabled
    );
  },
  set(enabled) {
    if (
      enabled &&
      unleash.isEnabled('SalonizedPay') &&
      unleash.isEnabled('SalonizedPayOnline')
    ) {
      redirectToSalonizedPay();
    }
    props.settings.onlinePayment = enabled
      ? OnlinePayment.Optional
      : OnlinePayment.Disabled;
  },
});

const canEnableOnlinePayments = computed(
  () => hasFeatureFlag('module-marketing') || hasFeatureFlag('module-register'),
);

enum AppliesToKeys {
  All = 'onlinePaymentAll',
  Duration = 'onlinePaymentMinimumDuration',
  Price = 'onlinePaymentMinimumPrice',
}

const appliesTo = computed({
  get() {
    return Object.values(AppliesToKeys).find((key) => props.settings[key]);
  },
  set(value) {
    Object.values(AppliesToKeys).forEach((key) => {
      props.settings[key] = key === value;
    });
  },
});

const { adyenAppState } = useAdyenStore();
const router = useRouter();
const redirectToSalonizedPay = () => {
  if (
    !adyenAppState.verificationStatus ||
    adyenAppState.verificationStatus !== 'VALID'
  ) {
    router.push({
      name: 'salonized-pay',
      query: { redirect: 'settings' },
    });
  }
};

const showRequestsDisabledByOnlinePayment = ref(false);
watch(
  () => props.settings.onlinePayment,
  (newVal, oldVal) => {
    showRequestsDisabledByOnlinePayment.value = false;
    if (
      oldVal === OnlinePayment.Disabled &&
      newVal !== OnlinePayment.Disabled &&
      props.settings.appointmentRequiresConfirmation
    ) {
      props.settings.appointmentRequiresConfirmation = false;
      showRequestsDisabledByOnlinePayment.value = true;
    }
  },
);

const cancelOnlinePayments = () => {
  showRequestsDisabledByOnlinePayment.value = false;
  props.settings.onlinePayment = OnlinePayment.Disabled;
  props.settings.appointmentRequiresConfirmation = true;
};

watch(
  () => props.settings.onlinePayment,
  (value) => {
    // When the user enables online payment with percentage, and no percentage has been set, set it to 10
    // This is to discourage users from using very low values
    if (
      value === OnlinePayment.Percentage &&
      !props.settings.onlinePaymentPercentage
    ) {
      props.settings.onlinePaymentPercentage = 10;
    }
  },
);
</script>

<style lang="scss" module>
.salonizedPayEnableBanner {
  display: flex;
  gap: 0.5 * $spacing;
}

.bannerImage {
  width: 47.081px;
  height: 54px;
}

.settingCategory {
  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
    padding-bottom: $spacing;
    margin-bottom: $spacing;
  }
}
</style>
