<template>
  <BaseModal
    small
    :heading="$t('marketing.email.save-template-modal.heading')"
    @close="$emit('close')"
  >
    <BaseAlert
      v-if="hasDeliverySchedule"
      color="warning"
      :text="`${$t('marketing.email.save-template-modal.scheduled_on')} ${filters.date(deliverAt)}`"
      mb
      v-test="'saveWarningAlert'"
    />

    <BaseInput
      v-model="name"
      :label="$t('global.name')"
      required
      v-test="'templateName'"
    />
    <template #footer>
      <BaseButton
        color="inverted"
        :disabled="isSaving"
        @click="$emit('close')"
        v-test="'saveTemplateCancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton :loading="isSaving" @click="onSubmit" v-test="'saveButton'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import eventBus from '@/event-bus';
import filters from '@/filters';
import { flash } from '@/helpers/ui';
import { useOnboardingStore } from '@/stores/onboarding';
import useVuelidate from '@vuelidate/core';
import { storeToRefs } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  emits: ['close'],
  setup() {
    const { currentOnboardingFlow } = storeToRefs(useOnboardingStore());

    return { v$: useVuelidate(), filters, currentOnboardingFlow };
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    name: {
      get() {
        return this.$store.getters['marketing/name'];
      },
      set(value) {
        this.$store.commit('marketing/SET_NAME', value);
      },
    },
    deliverAt() {
      return this.$store.getters['marketing/deliverAt'];
    },
    hasDeliverySchedule() {
      return this.$store.getters['marketing/hasDeliverySchedule'];
    },
  },
  methods: {
    onSubmit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isSaving = true;

      this.mixpanel.track('Newsletter saved');

      const actionName = this.$route.params.id
        ? 'updateEmailTemplate'
        : 'createEmailTemplate';
      this.$store
        .dispatch(`marketing/${actionName}`, 'MARKETING_CAMPAIGN')
        .then(() => {
          this.$router.push({ name: 'marketing-email' });
          if (this.currentOnboardingFlow === 'marketing') {
            eventBus.$emit('open-task-menu');
            this.currentOnboardingFlow = '';
          }
          flash(this.$t('global.flash.template_saved'));
        });
    },
  },
});
</script>
