import appointmentRoutes from './appointment';

const Calendar = () => import('@/modules/calendar/index.vue');
const CreateEvent = () => import('@/modules/calendar/create-event/index.vue');
const AbsenceModal = () => import('@/modules/calendar/modals/Absence.vue');
const WaitingList = () => import('@/modules/calendar/modals/WaitingList.vue');
const CreateWaitingListEntry = () =>
  import('@/modules/_shared/waiting-list/CreateEntry.vue');
const WaitingListEntry = () =>
  import('@/modules/_shared/waiting-list/Entry.vue');
const NoteModal = () => import('@/modules/calendar/modals/Note.vue');
const CreateAbsence = () =>
  import('@/modules/calendar/create-event/Absence.vue');
const CreateChore = () => import('@/modules/calendar/create-event/Chore.vue');
const CreateNote = () => import('@/modules/calendar/create-event/Note.vue');
const Appointment = () => import('@/modules/calendar/appointment/index.vue');
const DynamicPrice = () => import('@/modules/calendar/modals/DynamicPrice.vue');
const AppointmentRequests = () =>
  import('@/modules/calendar/modals/AppointmentRequests.vue');

export default {
  path: 'calendar',
  name: 'calendar',
  component: Calendar,
  meta: {
    featureFlag: 'module-appointments',
    moduleName: 'calendar',
    hideIntercomButton: {
      mobile: true,
    },
  },
  children: [
    {
      ...appointmentRoutes('appointment'),
      name: 'appointment',
      path: 'appointment/:appointmentId',
      props: {
        parentRouteName: 'calendar',
      },
    },
    {
      path: 'create-event',
      component: CreateEvent,
      children: [
        {
          path: 'appointment',
          name: 'create-appointment',
          component: Appointment,
        },
        {
          path: 'appointment/:appointmentId',
          name: 'edit-appointment',
          component: Appointment,
        },
        {
          path: 'absence',
          name: 'create-absence',
          component: CreateAbsence,
        },
        {
          path: 'chore',
          name: 'create-chore',
          component: CreateChore,
        },
        {
          path: 'note',
          name: 'create-note',
          component: CreateNote,
        },
      ],
    },
    {
      path: 'dynamic-price/:dynamicPriceId?',
      name: 'dynamic-price',
      component: DynamicPrice,
      meta: {
        featureFlag: 'dynamic-pricing',
      },
    },
    {
      path: 'absence/:id',
      name: 'absence',
      component: AbsenceModal,
    },
    {
      path: 'chore/:id',
      name: 'chore',
      component: AbsenceModal,
    },
    {
      path: 'note/:id',
      name: 'note',
      component: NoteModal,
    },
    {
      path: 'waiting-list',
      name: 'calendar-waiting-list',
      component: WaitingList,
      children: [
        {
          path: 'new',
          name: 'calendar-waiting-list-new',
          component: CreateWaitingListEntry,
          props: {
            parentRouteName: 'calendar-waiting-list',
          },
        },
        {
          path: ':waitingListEntryId',
          component: WaitingListEntry,
          props: {
            parentRouteName: 'calendar-waiting-list',
          },
        },
      ],
    },
    {
      path: 'requests',
      name: 'appointment-requests',
      component: AppointmentRequests,
    },
  ],
};
