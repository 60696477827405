<template>
  <div>
    <BaseGroupedCheckboxList
      v-if="listData.length"
      v-model="listValue"
      :data="listData"
      :label="$t('global.all_locations')"
      :gray="gray"
    />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useLocationsStore } from '@/stores/locations';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    gray: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapState(useLocationsStore, ['locations']),
    listValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    listData() {
      return this.locations.map((location) => ({
        label: location.internalName,
        id: location.id,
      }));
    },
  },
});
</script>
