<template>
  <BaseModal
    :heading="heading"
    testId="form-template"
    parentRoute="admin-forms"
    :loading="isFetching"
  >
    <div v-if="!isFetching">
      <BaseAlert
        v-if="isContract"
        :text="$t('form_templates.contract.warning_edit')"
        color="warning"
        mb
      />

      <BaseForm>
        <BaseInput
          v-model="formData.name"
          :label="$t('global.name')"
          required
          :disabled="disableEdit"
          mb
          v-test="'form-name'"
        />

        <div v-if="isEditingIntroText">
          <div :class="$style.closeButtonContainer">
            <BaseIcon name="close" clickable @click="toggleEditIntroText" />
          </div>

          <BaseInput
            v-model="formData.introText"
            :label="$t('form_templates.intro_text')"
            :disabled="disableEdit"
            type="textarea"
            :minLength="1"
            :maxLength="450"
            mb
            v-test="'form-intro-text'"
          />
        </div>

        <BaseButton
          v-else
          color="inverted"
          :mb="2"
          :disabled="disableEdit"
          @click="toggleEditIntroText"
        >
          {{ $t('form_templates.add_intro_text') }}
        </BaseButton>

        <BaseCheckbox
          v-if="hasFeatureFlag('module-marketing')"
          v-model="formData.sendAutomatically"
          :label="$t('form_templates.send_automatically')"
          mb
          v-test="'form-automatic-toggle'"
          v-intercom="'admin-forms-automatic-checkbox'"
        />

        <BaseGroupedCheckboxList
          v-if="
            hasFeatureFlag('module-marketing') && formData.sendAutomatically
          "
          v-model="formData.serviceIds"
          :data="servicesData"
          :label="$t('resource.all_treatments')"
          gray
          mb
          v-test="'form-automatic-services'"
        />

        <div v-if="isContract">
          <BaseEditor
            v-model="formData.text"
            :label="$t('form_templates.contract.text')"
            :disabled="disableEdit"
            mb
            v-test="'form-contract-text'"
          />
          <BaseText mb>
            {{ $t('form_templates.contract.extra_info') }}
          </BaseText>
          <BaseText :href="$t('form_templates.contract.learn_more_link')">
            {{ $t('global.learn_more') }}
          </BaseText>
        </div>
        <div v-else :class="$style.questions">
          <Question
            v-for="(question, index) in visibleQuestions"
            :key="index"
            :data="question"
            :deletable="visibleQuestions.length > 1"
            @update="updateQuestion(question, $event)"
            @delete="removeQuestion(question)"
            v-test="'form-question'"
          >
            <BaseCounter
              :modelValue="question.sortIndex"
              :minValue="1"
              :maxValue="visibleQuestions.length"
              reverse
              mb
              @update:modelValue="updateSortIndex($event, question.sortIndex)"
            />
          </Question>
          <BaseText
            link
            inline
            @click="addQuestion"
            v-test="'form-add-question'"
          >
            {{ $t('form_templates.consultation.add_question') }}
          </BaseText>
        </div>
      </BaseForm>
    </div>

    <template #footer>
      <BaseButton color="inverted" @click="close">
        {{ $t('global.actions.close') }}
      </BaseButton>
      <BaseButton
        :loading="isSaving"
        @click="submitForm"
        v-test="'form-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import gql from 'graphql-tag';
import Question from './Question.vue';
import { useFormTemplatesStore } from '@/stores/form-templates';
import { useServicesStore } from '@/stores/services';
import { useUserStore } from '@/stores/user';

const createQuestion = (data = {}) => ({
  destroy: false,
  id: data.id || null,
  options: data.options ? [...data.options] : [''],
  question: data.question || '',
  questionType: data.questionType ? data.questionType.toUpperCase() : 'TEXT',
  sortIndex: data.sortIndex || 1,
});

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormTemplate',
  components: {
    Question,
  },
  inject: ['mixpanel'],
  props: {
    contract: Boolean,
  },
  setup() {
    const { hasFeatureFlag } = useUserStore();
    return { v$: useVuelidate(), hasFeatureFlag };
  },
  data() {
    return {
      isSaving: false,
      isEditingIntroText: false,
      formData: {
        name: '',
        introText: '',
        questionsAttributes: this.contract ? [] : [createQuestion()],
        text: '',
        sendAutomatically: false,
        serviceIds: [],
      },
    };
  },
  apollo: {
    formTemplate: {
      query: gql`
        query getFormTemplate($id: Int!) {
          formTemplate(id: $id) {
            formType
            id
            name
            introText
            questions {
              id
              options
              question
              questionType
              sortIndex
            }
            sendAutomatically
            serviceIds
            submissionsCount
            text
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
      result({ data: { formTemplate } }) {
        this.formData.name = formTemplate.name;
        this.formData.introText = formTemplate.introText;
        this.formData.submissionsCount = formTemplate.submissionsCount;
        this.formData.sendAutomatically = formTemplate.sendAutomatically;
        this.formData.serviceIds = formTemplate.serviceIds;

        if (this.isContract) {
          this.formData.text = formTemplate.text;
          this.formData.questionsAttributes = [];
        } else {
          this.formData.questionsAttributes = formTemplate.questions.map(
            (q, index) =>
              createQuestion({
                ...q,
                sortIndex: index + 1, // Set sortIndex to 1, 2, 3 etc to get rid of incorrect data from the backend
              }),
          );
        }

        this.isEditingIntroText = !!this.formData.introText;
        this.sortQuestions();
      },
    },
  },
  computed: {
    id() {
      return Number.parseInt(this.$route.params.id) || null;
    },
    isCreating() {
      return !this.id;
    },
    isContract() {
      return (
        this.$route.name === 'admin-forms-new-contract' ||
        this.formTemplate?.formType === 'contract'
      );
    },
    isFetching() {
      return !this.isCreating && !this.formTemplate;
    },
    disableEdit() {
      return this.isContract && this.formData.submissionsCount > 0;
    },
    heading() {
      let heading = '';
      if (this.isCreating) {
        if (this.isContract) {
          heading = this.$t('form_templates.new_contract');
        } else {
          heading = this.$t('form_templates.new_consultation');
        }
      } else if (this.formTemplate) {
        heading = this.formTemplate.name;
      }
      return heading;
    },
    visibleQuestions() {
      return this.formData.questionsAttributes.filter((q) => !q.destroy);
    },
    servicesData() {
      const { categoriesWithServices } = useServicesStore();
      const _categoriesWithServices = categoriesWithServices.filter(
        (category) => category.services.length,
      );
      return _categoriesWithServices.length
        ? _categoriesWithServices.map((category) => ({
            label: category.name,
            color: category.color,
            items: category.services.map((service) => ({
              label: service.name,
              id: service.id,
            })),
          }))
        : [];
    },
  },
  methods: {
    close() {
      this.$router.push({
        name: 'admin-forms',
      });
    },
    addQuestion() {
      this.formData.questionsAttributes.push(
        createQuestion({
          sortIndex: this.formData.questionsAttributes.length + 1,
        }),
      );
    },
    updateQuestion(question, newValue) {
      question[newValue.key] = newValue.value;
    },
    updateSortIndex(newIndex, oldIndex) {
      const arr = this.formData.questionsAttributes;

      // currentQuestion = The question where the user changed the order
      // otherQuestion = The question that it needs to replace the index width

      const currentQuestion = arr.find((q) => q.sortIndex === oldIndex);
      const otherQuestion = arr.find((q) => q.sortIndex === newIndex);

      currentQuestion.sortIndex = newIndex;
      otherQuestion.sortIndex = oldIndex;

      this.sortQuestions();
    },
    sortQuestions() {
      this.formData.questionsAttributes.sort(
        (a, b) => a.sortIndex - b.sortIndex,
      );
    },
    removeQuestion(question) {
      question.destroy = true;
    },
    submitForm() {
      this.v$.$touch();

      if (!this.v$.$invalid) {
        if (!this.formData.serviceIds.length) {
          this.formData.sendAutomatically = false;
        }

        const input = {
          name: this.formData.name,
          introText: this.formData.introText,
          sendAutomatically: this.formData.sendAutomatically,
          serviceIds: this.formData.serviceIds,
        };

        if (this.isContract) {
          input.text = this.formData.text;
        } else {
          input.questionsAttributes = this.formData.questionsAttributes.map(
            (question) => ({
              ...question,
              options: question.options.filter((option) => !!option), // Remove empty options
            }),
          );
        }

        if (!this.isCreating) {
          input.id = this.id;
        } else {
          input.formType = this.isContract ? 'CONTRACT' : 'CONSULTATION';
        }

        this.isSaving = true;

        const { create, update } = useFormTemplatesStore();

        (this.isCreating ? create : update)(input).then(() => {
          this.close();

          if (this.isCreating) {
            if (this.isContract) {
              this.mixpanel.track('Contract form created');
            } else {
              this.mixpanel.track('Consultation form created');
            }
          }
        });
      }
    },
    toggleEditIntroText() {
      if (this.isEditingIntroText) {
        this.formData.introText = '';
        this.isEditingIntroText = false;
      } else {
        this.isEditingIntroText = true;
      }
    },
  },
});
</script>

<style lang="scss" module>
.questions {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.closeButtonContainer {
  float: right;
}
</style>
