<template>
  <div :class="$style.base">
    <iframe
      ref="iframeRef"
      :src="config.treatwellStoreUrl"
      :onLoad="onLoad"
      :class="$style.iframe"
      v-test="'treatwell-store-store'"
    />
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';

import { useCompanyStore } from '@/stores/company';
import { StoreIframeTransporter } from './iframe';
import axios from '@/axios';
import { useRoute, useRouter } from 'vue-router';
import { useTreatwellStoreStore } from '../store';
import config from '@/config';
import { MessageType } from './transporter';

const mixpanel = inject<any>('mixpanel');
const intercom = inject<any>('intercom');

const { company } = useCompanyStore();

const iframeRef = ref();

const router = useRouter();
const route = useRoute();

const initialRoutePath = Array.isArray(route.params.pathMatch)
  ? route.params.pathMatch.join('/')
  : route.params.pathMatch || '';

const queryParams = new URLSearchParams(
  route.query as Record<string, string>,
).toString();

const fullInitialPath = queryParams
  ? `${initialRoutePath}?${queryParams}`
  : initialRoutePath;

const getQueryParams = (url) => {
  const parseValue = (value) => {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value;
  };

  const queryString = url.split('?')[1] || '';
  const params = new URLSearchParams(queryString);

  return Object.fromEntries(
    Array.from(params.entries()).map(([key, value]) => [
      key,
      parseValue(value),
    ]),
  );
};

const onLoad = () => {
  mixpanel.track('Store page - loaded');
  const transporter = new StoreIframeTransporter(iframeRef.value);
  transporter.setDelegate({
    onRemoteEvent: (event, params: any) => {
      if (event === 'location' && params?.pathname) {
        mixpanel.track('Store page - location changed', {
          path: params.pathname,
        });
        const pathMatch = params.pathname
          .split('?')[0]
          .split('/')
          .filter((path: string) => !!path);
        if (pathMatch[0] === 'store') {
          pathMatch.shift();
        }
        router.replace({
          name: 'store-child',
          params: {
            pathMatch,
          },
          query: getQueryParams(params.pathname),
        });
      }

      if (event === 'updateCartItemCount') {
        intercom.trackEvent('store_cart_updated', {
          count: params,
        });
        const treatwellStoreStore = useTreatwellStoreStore();
        treatwellStoreStore.storeCartItemCount = params as number;
        mixpanel.track('Store page - cart updated', {
          count: params,
        });
      }
    },
    onRemoteCall: (name) =>
      new Promise((resolve) => {
        if (name === 'getDelegateData') {
          axios.post('/auth-tokens').then(({ data }) => {
            resolve({
              externalID: company.id,
              token: data.token,
            });
          });
        }
      }),
  });
  iframeRef.value.contentWindow.postMessage(
    {
      type: 'TWStoreGlueInit',
      data: {
        source: 'salonized',
        platform: 'web',
        version: 3,
        initialData: {
          basePath: '/store',
          path: `/store/${fullInitialPath}`,
          locale: company.localeCode,
        },
      },
    },
    '*',
  );

  watch(route, () => {
    if (route.name === 'store') {
      // When the user navigates back to the store home page by using the main navigation, we need to route the store to the homepage
      transporter._send({
        type: MessageType.EVENT,
        name: 'navigate',
        data: {
          to: '/',
        },
      });
    }
  });
};
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.iframe {
  width: 100%;
  height: calc(100% - 2px);
  border: 0;
}
</style>
