<template>
  <div :class="$style.base">
    <div :class="$style.inner">
      <BaseTableRow head>
        <BaseTableCell>{{ $t('global.name') }}</BaseTableCell>
        <BaseTableCell>{{ $t('global.email') }}</BaseTableCell>
        <BaseTableCell>{{ $t('global.address') }}</BaseTableCell>
        <BaseTableCell>{{ $t('global.postal_code') }}</BaseTableCell>
        <BaseTableCell>{{ $t('global.total_inventory') }}</BaseTableCell>
        <div :class="$style.showMore">
          <BaseTableCell />
        </div>
      </BaseTableRow>

      <BaseTableRow
        v-for="(item, index) in suppliers"
        :key="`row-${index}`"
        :options="
          hasFeatureFlag('product-delete') ? ['edit', 'delete'] : ['edit']
        "
        zebra
        @optionClick="onOptionClick(item, $event)"
        v-test="'baseTableRow'"
      >
        <BaseTableCell>{{ item.name }}</BaseTableCell>
        <BaseTableCell>{{ item.email ? item.email : '-' }}</BaseTableCell>
        <BaseTableCell>{{ item.address ? item.address : '-' }}</BaseTableCell>
        <BaseTableCell>{{
          item.postalcode ? item.postalcode : '-'
        }}</BaseTableCell>
        <BaseTableCell v-if="item.totalInventory">{{
          filters.currency(item.totalInventory)
        }}</BaseTableCell>
        <BaseTableCell v-else>-</BaseTableCell>
      </BaseTableRow>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell,
  },
  props: {
    suppliers: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      filters,
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
  },
  methods: {
    onOptionClick(item, option) {
      if (option === 'edit') {
        this.$router.push({ name: 'edit-supplier', params: { id: item.id } });
      }
      if (option === 'delete') {
        this.$router.push({
          name: 'delete-supplier',
          params: {
            id: item.id,
          },
          query: {
            products: item.amountProducts,
          },
        });
      }
    },
  },
});
</script>

<style lang="scss" module>
.base {
  overflow-x: auto;
  min-height: 70vh;
}

.inner {
  min-width: 900px;
}

.showMore {
  width: 52px !important;
  flex: none !important;
}
</style>
