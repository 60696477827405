<template>
  <div
    :class="[
      $style.base,
      {
        [$style.hasControls]: controls
      }
    ]"
  >
    <div
      v-if="controls"
      :class="$style.btnSubtract"
      @click="subtract"
      v-test="'_base-input-subtract'"
    />
    <InputElement
      :modelValue="inputValue"
      type="text"
      v-bind="{ uid, hasError, inputMode, disabled, focus, size }"
      :squareCorners="!!controls"
      :centerText="!!controls"
      :labelText="labelText"
      @focus="$emit('focus')"
      @blur="onBlur"
      @input="onInput"
    />
    <div
      v-if="controls"
      :class="$style.btnAdd"
      @click="add"
      v-test="'_base-input-add'"
    />
  </div>
</template>

<script lang="ts">
import InputElement from './_shared/InputElement.vue';
import props from './_shared/props';
import { browser } from '@/user-context';

const setDecimals = (value, decimals) => {
  const multiplier = 10 ** decimals;
  return Math.round(value * multiplier) / multiplier;
};

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    InputElement,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    ...props('number'),
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  data() {
    return {
      isFocused: false,
    };
  },
  watch: {
    minValue: 'applyMinMax',
    maxValue: 'applyMinMax',
    value() {
      if (this.controls) {
        this.applyMinMax();
      }
    },
  },
  computed: {
    value: {
      get() {
        if (this.modelValue && this.decimals) {
          const value = setDecimals(this.modelValue, this.decimals);

          return value.toString().replace('.', ',');
        } else {
          return this.modelValue;
        }
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    inputValue() {
      if (this.modelValue && this.decimals) {
        const value = setDecimals(this.modelValue, this.decimals);

        return value.toString().replace('.', ',');
      } else {
        return this.modelValue;
      }
    },
    inputMode() {
      return browser?.isAndroid ? 'text' : 'decimal';
    },
    labelText() {
      let text = '';

      if (this.modelValue === null || this.modelValue === undefined) {
        return text;
      }

      if (this.unitLabel) {
        switch (this.unitLabel) {
          case 'minute':
            text = ` ${this.$t('global.minute_short')}`;
            break;
          case 'percentage':
            text = '%';
            break;
        }
      }

      return text;
    },
  },
  methods: {
    subtract() {
      this.value--;
    },
    add() {
      this.value++;
    },
    onInput(value) {
      if (value) {
        if (this.decimals && value.charAt(value.length - 1) === ',') {
          // Don't update when the user is typing and the last character is a comma
          return;
        }

        if (value.length === 1 && value.charAt(0) === '-') {
          // Don't update when the user starts typing with a '-'
          return;
        }

        // Remove leading zero, so the user can start typing without removing the "0"
        if (value.charAt(0) === '0') {
          value = value.slice(1);
        }

        // Convert to number
        if (this.decimals) {
          value = Number.parseFloat(value.replace(',', '.'));
        } else {
          value = Number.parseInt(value);
        }

        // If NaN, set value to 0
        this.value = value || 0;
      }
    },
    onBlur(value) {
      if (!value) {
        // Set value to 0 when the user leaves the field empty and blurs it
        this.value = this.getMinMax(0);
      } else if (this.decimals) {
        // Set maximum amount of decimals
        this.value = this.getMinMax(
          setDecimals(this.modelValue, this.decimals),
        );
      } else {
        this.applyMinMax();
      }

      this.$emit('blur');
    },
    getMinMax(inputValue?: string | number) {
      let newValue = inputValue !== undefined ? inputValue : this.value;

      newValue =
        typeof newValue === 'number'
          ? newValue
          : Number.parseFloat(newValue.replace(',', '.'));

      // Set value to minValue when the value is lower than the minValue
      if (typeof this.minValue === 'number') {
        newValue = Math.max(this.minValue, newValue);
      }

      // Set value to maxValue when the value is higher than the maxValue
      if (typeof this.maxValue === 'number') {
        newValue = Math.min(this.maxValue, newValue);
      }

      return newValue;
    },
    applyMinMax() {
      const newValue = this.getMinMax();

      if (newValue !== this.value) {
        this.value = newValue;
      }
    },
  },
});
</script>

<style lang="scss" module>
$buttonWidth: 18px;

.base {
  position: relative;

  &.hasControls {
    padding-left: $buttonWidth;
    padding-right: $buttonWidth;
  }
}

.btnSubtract,
.btnAdd {
  position: absolute;
  height: 100%;
  top: 0;
  width: $buttonWidth;
  cursor: pointer;
  border: 1px solid $color-border-input;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #ccc;
  }

  &:before {
    width: 8px;
    height: 2px;
  }

  &:after {
    width: 2px;
    height: 8px;
  }

  @include hover {
    background-color: $color-highlight;
  }
}

.btnSubtract {
  left: 0;
  border-radius: $radius 0 0 $radius;
  border-right: none;

  &:after {
    display: none;
  }
}

.btnAdd {
  right: 0;
  border-radius: 0 $radius $radius 0;
  border-left: none;
}
</style>
