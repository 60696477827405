<template>
  <BaseCard :class="$style.base" :mb="1.5" :mr="$screen !== 's' ? 0.5 : null">
    <SendMessage
      v-if="showMessageModal"
      :customerData="customerData"
      @close="showMessageModal = false"
    />
    <div :class="$style.list">
      <div
        v-if="hasFeatureFlag('module-appointments')"
        :class="$style.listItem"
        @click="onCreateAppointment"
        v-test="'create-appointment'"
      >
        <BaseIcon name="calendar" :mr="0.5" />
        <BaseText bold>
          {{ $t('global.actions.create_appointment') }}
        </BaseText>
      </div>
      <div
        v-if="hasFeatureFlag('module-register')"
        :class="$style.listItem"
        @click="onCreateInvoice"
        v-test="'create-invoice'"
      >
        <BaseIcon name="email" :mr="0.5" />
        <BaseText bold>
          {{ $t('global.actions.create_invoice') }}
        </BaseText>
      </div>
      <div
        v-if="hasFeatureFlag('customer-forms') && hasFillableForms"
        :class="$style.listItem"
        @click="onFillInForm"
        v-test="'customerFillInForm'"
      >
        <BaseIcon name="file" :mr="0.5" />
        <BaseText bold>
          {{ $t('global.actions.fill_in_form') }}
        </BaseText>
      </div>
      <div
        v-if="hasFeatureFlag('appointment-reports')"
        :class="$style.listItem"
        @click="onCreateReport"
        v-test="'customerCreateReport'"
      >
        <BaseIcon name="check-square" :mr="0.5" />
        <BaseText bold>
          {{ $t('global.actions.create_report') }}
        </BaseText>
      </div>
      <div
        :class="$style.listItem"
        @click="showMessageModal = true"
        v-test="'customerSendMessage'"
      >
        <BaseIcon name="email" :mr="0.5" />
        <BaseText bold>
          {{ $t('customers.send_message') }}
        </BaseText>
      </div>
      <div
        v-if="canSendAppointmentOverview"
        :class="$style.listItem"
        @click="showAppointmentRequestConfirmation"
        v-test="'customerSendAppointmentOverview'"
      >
        <BaseIcon name="email" :mr="0.5" />
        <BaseText bold>
          {{ $t('customers.send_upcoming_appointments') }}
        </BaseText>
      </div>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import SendMessage from '@/modules/customers/SendMessage.vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import { useQuery } from '@vue/apollo-composable';
import { defineComponent, computed, inject } from 'vue';
import unleash from '@/unleash';
import gql from 'graphql-tag';
import { useAppointmentOverview } from '../appointmentOverview';

export default defineComponent({
  components: {
    SendMessage,
  },
  inject: ['mixpanel'],
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const customer = inject<any>('customer');

    const { result } = useQuery(
      gql`
        query getCurrentUser {
          currentUser {
            company {
              hasFillableForms
            }
          }
        }
      `,
      null,
      {
        fetchPolicy: 'cache-first',
      },
    );

    const appointmentsCount = computed(
      () => props.summary?.appointmentCount || 0,
    );

    const { showModal: showAppointmentRequestConfirmation } =
      useAppointmentOverview({
        appointmentsCount,
        pageName: 'customer details page',
      });

    const hasFillableForms = computed(
      () => !!result.value?.currentUser.company.hasFillableForms,
    );

    return {
      hasFillableForms,
      customer,
      showAppointmentRequestConfirmation,
    };
  },
  data() {
    return {
      showMessageModal: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    customerData() {
      return {
        customerId: this.customer.id,
        customerName: this.customer.firstName,
        customerMobile: this.customer.mobilePhone,
        customerEmail: this.customer.email,
      };
    },
    canSendAppointmentOverview() {
      return !!this.summary && unleash.isEnabled('AppointmentOverviewRequest');
    },
  },
  methods: {
    onCreateAppointment() {
      this.$router.push({ name: 'calendar' });
      this.mixpanel.track('Customer - create appointment button clicked');

      const { setState } = usePreCreateStore();
      setState({
        action: 'CREATE',
        customer: {
          firstName: this.customer.firstName,
          lastName: this.customer.lastName,
          email: this.customer.email,
          mobilePhone: this.customer.mobilePhone,
          id: this.customer.id,
        },
      });
    },
    onCreateInvoice() {
      const query = { customerId: this.customer.id };
      this.$router.push({ name: 'register', query });
    },
    onFillInForm() {
      this.$router.push({ name: 'customer-forms-select' });
    },
    onCreateReport() {
      this.$router.push({ name: 'customer-report-create' });
    },
  },
});
</script>

<style lang="scss" module>
.base {
  overflow: hidden;
}

.list {
  display: flex;
  flex-direction: column;
  margin: -$spacing;
}

.listItem {
  display: flex;
  align-items: center;
  padding: $spacing;
  text-decoration: none;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid $color-border;
  }

  &:focus {
    background: $color-highlight;
  }

  @include hover {
    background: $color-highlight;
  }
}
</style>
