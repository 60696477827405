<template>
  <div>
    <BaseCard :heading="$t('global.history')">
      <Timeline v-if="mock && mock.length" :data="mock" mt />
      <BaseText v-else-if="!mock.length" mt>
        {{ $t('global.no_results') }}
      </BaseText>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import Timeline from '@/components/Timeline.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Timeline,
  },
  data() {
    return {
      mock: [
        {
          id: 1,
          key: 'custom',
          created_at: '2021-01-25T13:52:39.397+01:00',
          meta: {
            gateway: 'email',
          },
        },
        {
          id: 2,
          key: 'note_created',
          created_at: '2021-01-25T13:52:39.397+01:00',
          meta: {
            gateway: 'email',
          },
        },
      ],
    };
  },
});
</script>
