<template>
  <div :class="$style.base">
    <BaseDropdown
      v-model="selected"
      :label="label"
      :options="
        dateOptions.map((option) => ({
          label: option.label,
          value: option.value
        }))
      "
      v-test="'reports-date-dropdown'"
    />
    <BaseDatePicker
      v-if="hasCustomDates"
      v-model="dateFrom"
      :label="$t('customers.filters.from')"
      v-test="'reports-date-from'"
    />
    <BaseDatePicker
      v-if="hasCustomDates"
      v-model="dateTo"
      :label="$t('customers.filters.to')"
      :error="hasError ? $t('global.validations.after_date') : null"
      v-test="'reports-date-to'"
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import i18n from '@/i18n';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
    },
    startDate: String,
    endDate: String,
  },
  emits: ['select'],
  setup(props) {
    const dateFrom = ref(props.startDate || dayjs().format());
    const dateTo = ref(props.endDate || dayjs().format());

    return {
      filters,
      dateFrom,
      dateTo,
    };
  },
  data() {
    return {
      selected: 'today',
      isExpanded: false,
      hasError: false,
    };
  },
  watch: {
    selected: {
      handler() {
        this.$nextTick(() => {
          const dates = this.selectedDateOption.dates;

          if (dates) {
            this.dateFrom = dates.startDate;
            this.dateTo = dates.endDate;
          }
        });
      },
      immediate: true,
    },
    dateFrom: 'emitDates',
    dateTo: 'emitDates',
  },
  computed: {
    hasCustomDates() {
      return this.selected === 'custom';
    },
    selectedDateOption() {
      return this.dateOptions.find((option) => option.value === this.selected);
    },
    dateOptions: () => {
      const dayStart = dayjs().startOf('day');
      const dayEnd = dayjs().endOf('day');

      return [
        {
          label: i18n.t('reports.today'),
          value: 'today',
          dates: {
            startDate: dayStart.format(),
            endDate: dayEnd.format(),
          },
        },
        {
          label: i18n.t('reports.yesterday'),
          value: 'yesterday',
          dates: {
            startDate: dayStart.subtract(1, 'day').format(),
            endDate: dayEnd.subtract(1, 'day').format(),
          },
        },
        {
          label: i18n.t('reports.this_week'),
          value: 'this-week',
          dates: {
            startDate: dayStart.startOf('isoWeek').format(),
            endDate: dayEnd.format(),
          },
        },
        {
          label: i18n.t('reports.last_week'),
          value: 'last-week',
          dates: {
            startDate: dayStart.subtract(1, 'week').startOf('isoWeek').format(),
            endDate: dayEnd.subtract(1, 'week').endOf('isoWeek').format(),
          },
        },
        {
          label: i18n.t('reports.this_month'),
          value: 'this-month',
          dates: {
            startDate: dayStart.startOf('month').format(),
            endDate: dayEnd.format(),
          },
        },
        {
          label: i18n.t('reports.last_month'),
          value: 'last-month',
          dates: {
            startDate: dayStart.subtract(1, 'month').startOf('month').format(),
            endDate: dayEnd.subtract(1, 'month').endOf('month').format(),
          },
        },
        {
          label: i18n.t('reports.year'),
          value: 'year',
          dates: {
            startDate: dayStart.startOf('year').format(),
            endDate: dayEnd.format(),
          },
        },
        {
          label: i18n.t('reports.custom'),
          value: 'custom',
        },
      ];
    },
  },
  methods: {
    emitDates() {
      if (dayjs(this.dateFrom).isAfter(dayjs(this.dateTo))) {
        this.hasError = true;
        return;
      }

      this.$emit('select', {
        startDate: this.dateFrom,
        endDate: this.dateTo,
      });
    },
  },
  created() {
    if (this.startDate && this.endDate) {
      const dateOption = this.dateOptions.find(
        (option) =>
          option.dates?.startDate === this.startDate &&
          option.dates?.endDate === this.endDate,
      );
      if (dateOption) {
        this.selected = dateOption.value;
      } else {
        this.selected = 'custom';
      }
    }
  },
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: $spacing * 0.5;

    &:not(:last-child) {
      margin-right: $spacing;
    }
  }
}
</style>
