import { useCompanyStore } from '@/stores/company';
import type { CompanyPublicLanguage } from '@/types';
import { reactive } from 'vue';

export const useWidgetData = () => {
  const { company, companySettings } = useCompanyStore();

  const getDefaultColor = (primaryColor: string) => {
    switch (primaryColor) {
      case 'main':
        return '#FF6575';
      case 'dark':
        return '#404043';
      default:
        return companySettings.bookingWidget.customColor;
    }
  };

  const formData = reactive({
    giftcardPersonalMessage: company.giftcardPersonalMessage,
    languagePublic: company.languagePublic as CompanyPublicLanguage,
    settings: {
      bookings: {
        stripeIdeal: companySettings.bookings.stripeIdeal,
      },
      bookingWidget: {
        autoWidth: companySettings.bookingWidget.autoWidth,
        customColor: getDefaultColor(
          companySettings.bookingWidget.primaryColor,
        ),
        height: companySettings.bookingWidget.height,
        inline: companySettings.bookingWidget.inline,
        outline: companySettings.bookingWidget.outline,
        position: companySettings.bookingWidget.position,
        primaryColor: 'custom',
        width: companySettings.bookingWidget.width,
      },
      sales: {
        giftcardCustomAmount: companySettings.sales.giftcardCustomAmount,
        giftcardCustomAmountMax: companySettings.sales.giftcardCustomAmountMax,
        giftcardCustomAmountMin: companySettings.sales.giftcardCustomAmountMin,
        giftcardExpiration: companySettings.sales.giftcardExpiration,
        giftcardExpirationValue:
          companySettings.sales.giftcardExpirationValue > 1
            ? companySettings.sales.giftcardExpirationValue
            : 2,
        giftcardPicture: companySettings.sales.giftcardPicture,
        giftcardValues: companySettings.sales.giftcardValues,
      },
    },
  });

  provide('giftcardSettingsData', formData);

  return {
    formData,
  };
};
