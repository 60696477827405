<template>
  <BaseModalSmall testId="recurring-options" @close="onCancelClick">
    <BaseText size="l" mb>
      {{
        $t(
          `global.actions.${data.action === 'update' ? 'edit' : data.action}_${data.type}`
        )
      }}
    </BaseText>
    <BaseRadio
      v-model="value"
      :options="options"
      v-test="'recurring-edit-options'"
    />
    <template #footer>
      <BaseButton
        color="inverted"
        @click="onCancelClick"
        v-test="'recurring-cancel'"
        >{{ $t('global.actions.cancel') }}</BaseButton
      >
      <BaseButton
        class="ml"
        @click="onSubmitClick"
        v-test="'recurring-submit'"
        >{{ $t('global.actions.submit') }}</BaseButton
      >
    </template>
  </BaseModalSmall>
</template>

<script lang="ts">
import eventBus from '@/event-bus';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: 'single',
    };
  },
  computed: {
    options() {
      const options = [
        {
          label: this.$t(
            `calendar.recurrence_update_state_options.${this.data.type}.single`,
          ),
          value: 'single',
        },
        {
          label: this.$t(
            `calendar.recurrence_update_state_options.${this.data.type}.future`,
          ),
          value: 'future',
        },
      ];
      if (
        !this.data.disableRecurringOriginalOption &&
        (!this.data.rrule || this.data.rrule.freq === 'daily') &&
        this.data.type !== 'appointment'
      ) {
        options.push({
          label: this.$t(
            `calendar.recurrence_update_state_options.${this.data.type}.original`,
          ),
          value: 'original',
        });
      }
      return options;
    },
  },
  methods: {
    onCancelClick() {
      eventBus.$emit('submit-confirmation-modal', false);
    },
    onSubmitClick() {
      eventBus.$emit('submit-confirmation-modal', this.value);
    },
  },
});
</script>
