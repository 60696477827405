import {
  type OrderTransaction,
  useRegisterOrderStore,
} from '@/modules/register/stores/order';
import type { Customer } from '@/types';
import { storeToRefs } from 'pinia';

export const setTwTransaction = (id?: string) => {
  const { totalTwPayments } = storeToRefs(useRegisterOrderStore());
  const { order, addTransaction } = useRegisterOrderStore();

  const existingTwTransaction = order.transactions.find(
    (transaction: OrderTransaction) =>
      transaction.type === 'TREATWELL_ONLINE_PAYMENT',
  );

  let amount = totalTwPayments.value;
  if (existingTwTransaction) {
    amount = totalTwPayments.value - existingTwTransaction.amount;
  }

  if (amount > 0) {
    addTransaction({
      type: 'TREATWELL_ONLINE_PAYMENT',
      amount,
      ...(id && existingTwTransaction?.id !== id ? { id } : null),
    });
  }
};

export const setCustomer = (customer?: Customer) => {
  const { selectedCustomer } = storeToRefs(useRegisterOrderStore());
  const { order } = useRegisterOrderStore();

  if ((!order.customerId || !order.items.length) && customer) {
    selectedCustomer.value = customer;
    order.customerId = customer?.id;
  }
};

export const setSelectedResource = (resourceIds: number[]) => {
  const { selectedEmployeeId } = storeToRefs(useRegisterOrderStore());
  const uniqueArray = resourceIds.filter(
    (value, index, array) => value && array.indexOf(value) === index,
  );

  if (uniqueArray.length === 1) {
    selectedEmployeeId.value = resourceIds[0];
  } else {
    selectedEmployeeId.value = 'multiple';
  }
};
