<template>
  <BaseModal
    small
    :loading="isLoading"
    :heading="$t('marketing.email.test-email-modal.heading')"
    @close="$emit('close')"
  >
    <BaseText mb>
      {{ $t('marketing.email.test-email-modal.info') }}
    </BaseText>
    <BaseInput
      v-model="email"
      :label="$t('global.email')"
      required
      mb
      v-test="'send-test-email'"
    />
    <template #footer>
      <BaseButton color="inverted" @click="$emit('close')">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="isLoading"
        @click="submit"
        v-test="'send-test-submit'"
      >
        {{ $t('global.actions.send') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('marketing', ['isInvalid']),
  },
  methods: {
    submit() {
      this.v$.$touch();

      // besides checking for the modal validation it has to check the email validation
      this.$store.commit('marketing/SET_SUBMITTED', true);
      if (this.isInvalid || this.v$.$invalid) {
        return;
      }

      this.isLoading = true;

      if (this.$route.params.id) {
        this.$store
          .dispatch('marketing/updateEmailTemplate', 'MARKETING_CAMPAIGN')
          .then(() => this.sendMarketingEmail());
      } else {
        this.sendMarketingEmail();
      }
    },
    sendMarketingEmail() {
      this.$store
        .dispatch('marketing/sendMarketingEmail', { testEmail: this.email })
        .finally(() => {
          this.$emit('close');
          flash(this.$t('global.flash.email_sent'));
          this.$store.commit('marketing/SET_SUBMITTED', false);
          this.isLoading = false;
        });
    },
  },
});
</script>
