<template>
  <ModuleBase v-if="!hasFeatureFlag('module-reports')" fullHeight>
    <EmptySubscriptionPage
      :text="{
        title: $t('upgrade_subscription_page.reports.main.title'),
        description: $t('upgrade_subscription_page.reports.main.description'),
        btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
      }"
      :moreLink="{
        text: $t('upgrade_subscription_page.reports.main.learn_more'),
        url: $t('upgrade_subscription_page.reports.main.learn_more_url')
      }"
      newRoute="reports-totals"
      imageName="reports-empty"
    />
  </ModuleBase>
  <ModuleBase
    v-else
    gray
    :navItems="
      [
        {
          text: $t('global.pages.reports_totals'),
          name: 'reports-totals'
        },
        {
          text: $t('global.pages.reports_daily'),
          name: 'reports-daily'
        },
        {
          text: $t('global.pages.reports_monthly'),
          name: 'reports-monthly'
        },
        {
          text: $t('global.pages.reports_products'),
          name: 'reports-products'
        },
        {
          text: $t('global.pages.reports_services'),
          name: 'reports-services'
        },
        {
          text: $t('global.pages.reports_employees'),
          name: 'reports-employees'
        },
        {
          text: $t('global.pages.reports_vat_overview'),
          name: 'reports-vat-overview'
        },
        {
          text: $t('global.pages.reports_insights'),
          name: 'reports-insights',
          hide: !isRouteAllowed('reports-insights')
        }
      ].filter((item) => !item.hide)
    "
  />
</template>

<script lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';
import { isRouteAllowed } from '@/router/helpers';

import { defineComponent } from 'vue';
import EmptySubscriptionPage from '../_shared/subscription/EmptySubscriptionPage.vue';

export default defineComponent({
  name: 'Reports',
  components: {
    ModuleBase,
    EmptySubscriptionPage,
  },
  setup() {
    return {
      isRouteAllowed,
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
  },
});
</script>
